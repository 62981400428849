import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { EventsCalendarModule } from '@app/events-calendar/events-calendar.module';
import { MyCalendarComponent } from '@app/events-calendar/my-calendar/my-calendar.component';
import { UniversalSidebarComponent } from '@app/library/sidebars/universal-sidebar/universal-sidebar.component';
import { RegularScheduleFormComponent } from '@app/schedule-page/regular-schedule-form/regular-schedule-form.component';
import { RegularScheduleFormModule } from '@app/schedule-page/regular-schedule-form/regular-schedule-form.module';
import { SchedulePageComponent } from '@app/schedule-page/schedule-page.component';
import { VacationFormComponent } from '@app/schedule-page/vacation-form/vacation-form.component';
import { VacationFormModule } from '@app/schedule-page/vacation-form/vacation-form.module';
import { NotFoundComponent } from '@shared/content-not-found/content-not-found.component';

const routes: Routes = [
  {
    path: '',
    component: SchedulePageComponent,
    children: [
      {
        path: 'working-hours',
        component: RegularScheduleFormComponent,
      },
      {
        path: 'calendar',
        component: MyCalendarComponent,
      },
      {
        path: 'vacation',
        component: VacationFormComponent,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'calendar',
      },
      {
        path: '**',
        component: NotFoundComponent,
      },
    ],
  },
  {
    path: '',
    outlet: 'right-sidebar-top',
    component: UniversalSidebarComponent,
  },
];

@NgModule({
  imports: [VacationFormModule, EventsCalendarModule, RegularScheduleFormModule, RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ScheduleRoutingModule {}
