import { Component, OnInit } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';

@Component({
  selector: 'app-digital-content',
  templateUrl: './digital-content.component.html',
  styleUrls: ['./digital-content.component.scss'],
})
export class DigitalContentComponent extends DestroyableComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
