import { CommonModule } from '@angular/common';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ImageUploaderModule } from '@app/shared';
import { VocabularyService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { FormFieldModule } from 'lingo2-forms';
import { VocabularyList } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonModule, OnUiButtonState, OnUiInputModule } from 'onclass-ui';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-list-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    OnUiButtonModule,
    TranslateModule,
    FormFieldModule,
    OnUiInputModule,
    ImageUploaderModule,
  ],
  templateUrl: './list-form.component.html',
  styleUrls: ['./list-form.component.scss'],
})
export class ListFormComponent extends DestroyableComponent implements OnInit {
  @Input()
  list: Partial<VocabularyList>;

  @Output()
  saved = new EventEmitter();

  /** Form */
  public form: UntypedFormGroup;
  public validationVisible = false;

  /** Action buttons */
  public saveState: OnUiButtonState = 'default';

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private fb: UntypedFormBuilder,
    private vocabularyService: VocabularyService,
  ) {
    super();
  }

  ngOnInit(): void {
    this.createForm();
  }

  public get isNew(): boolean {
    return this.list ? !this.list.id : true;
  }

  private createForm(): void {
    this.form = this.fb.group({
      // eslint-disable-next-line @typescript-eslint/unbound-method
      title: ['', [Validators.required, Validators.maxLength(90)]],
      image_id: [null],
    });

    if (this.list) {
      this.form.patchValue(this.list);
    }
  }

  public isInvalid(formControlName: string): boolean {
    return this.validationVisible && this.form?.get(formControlName)?.invalid;
  }

  public save() {
    this.validationVisible = true;
    if (this.form.invalid) {
      this.form.get('title').markAsTouched();
      return;
    }

    const values = this.form.value;

    this.saveState = 'progress';
    const observable: Observable<VocabularyList> = this.isNew
      ? this.vocabularyService.createVocabularyList(values)
      : this.vocabularyService.updateVocabularyList(this.list.id, values);

    observable.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (list) => {
        this.saveState = 'done';
        this.list = list;
        this.saved.emit();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'SAVE-PROBLEM');
        this.saveState = 'fail';
        this.saved.emit();
      },
    });
  }
}
