import { HttpClient } from '@angular/common/http';
import { Directive } from '@angular/core';
import { logger } from '@app/core/helpers/logger';
import { environment } from '@env/environment';
import { DestroyableComponent } from '@models/destroyable.component';
import { TranslateLoader } from '@ngx-translate/core';
import { BehaviorSubject, catchError, map, Observable, of, zip } from 'rxjs';
import * as enMock from '../../../assets/global/assets/i18n/en.json';
import * as ruMock from '../../../assets/global/assets/i18n/ru.json';
import { PlatformService } from './platform.service';
import { TranslationCacheService } from './translation-cache.service';

@Directive()
class TranslationHttpLoader extends DestroyableComponent implements TranslateLoader {
  public constructor(
    private http: HttpClient,
    protected readonly platform: PlatformService,
    private translationCacheService: TranslationCacheService,
  ) {
    super(platform);
  }

  private getSubdomain(): string {
    if (!this.isBrowser) {
      return '';
    }
    const hostname = window.location.hostname;
    const parts = hostname.split('.');
    return parts.length > 2 ? parts[0] : '';
  }

  getTranslation(lang: string): Observable<object> {
    if (this.translationCacheService.getLastLoadedLang() !== lang) {
      this.translationCacheService.clearCache();
    }

    this.translationCacheService.setLastLoadedLang(lang);

    const subdomain = this.getSubdomain();
    const mockData = subdomain === 'ru' ? ruMock : enMock;

    // Создаем или получаем существующий BehaviorSubject для текущего языка
    let translationSubject = this.translationCacheService.getTranslationSubject(lang);
    if (!translationSubject) {
      translationSubject = new BehaviorSubject<object>(mockData);
      this.translationCacheService.setTranslationSubject(lang, translationSubject);
    }

    const isInContext = !!localStorage.getItem('APP__INCNTX');
    const currentLang = isInContext ? 'fj-FJ' : lang;

    let urlPrefix = `${environment.i18n_url}/${currentLang}`;
    let urlPostfix = `?r=${environment.versions.gitCommitHash}`;

    if (environment.i18n_dev && currentLang === 'en') {
      urlPrefix = environment.i18n_en_url;
      urlPostfix = `?r=${Math.random()}`;
    }

    const files = ['web.json', ...(this.isBrowser ? ['forms.json'] : [])];

    const observables = files.map((file) => {
      const url = `${urlPrefix}/${file}${urlPostfix}`;
      return this.http.get<object>(url).pipe(
        catchError((err) => {
          logger.error(`Error loading resource '${url}'`, err);
          return of({});
        }),
      );
    });

    // Загружаем переводы с бэкенда
    zip(...observables)
      .pipe(map((translations) => translations.reduce((acc, curr) => ({ ...acc, ...curr }), {})))
      .subscribe({
        next: (translations) => {
          if (Object.keys(translations).length > 0) {
            // Обновляем переводы только если они не пустые
            translationSubject?.next(translations);
          }
        },
        error: (error) => {
          logger.error('Error loading translations', error);
          // В случае ошибки оставляем моковые данные
        },
      });

    // Возвращаем Observable с текущими переводами
    return translationSubject.asObservable();
  }
}

export function HttpLoaderFactory(
  http: HttpClient,
  platform: PlatformService,
  translationCacheService: TranslationCacheService,
) {
  return new TranslationHttpLoader(http, platform, translationCacheService);
}
