<section class="howgetnew">

  <div class="howgetnew-container">

    <div class="howgetnew-title">{{'job-landing.howgetnew.title' | translate}}</div>

    <div class="howgetnew-content-wrap">
      <div class="howgetnew-content-steps">
        <div class="howgetnew-content-steps-step">
          <div class="howgetnew-content-step-number">1</div>
          <div class="howgetnew-content-step-title">
            {{'job-landing.howgetnew.step1title' | translate}}
          </div>
          <div class="howgetnew-content-step-desc">
            {{'job-landing.howgetnew.step1text' | translate}}
          </div>
        </div>
        <div class="howgetnew-content-steps-step">
          <div class="howgetnew-content-step-number">2</div>
          <div class="howgetnew-content-step-title">
            {{'job-landing.howgetnew.step2title' | translate}}
          </div>
          <div class="howgetnew-content-step-desc">
            {{'job-landing.howgetnew.step2text' | translate}}
          </div>
        </div>
        <div class="howgetnew-content-steps-step">
          <div class="howgetnew-content-step-number">3</div>
          <div class="howgetnew-content-step-title">
            {{'job-landing.howgetnew.step3title' | translate}}
          </div>
          <div class="howgetnew-content-step-desc">
            {{'job-landing.howgetnew.step3text' | translate}}
          </div>
        </div>
        <div class="howgetnew-content-steps-step">
          <div class="howgetnew-content-step-number">4</div>
          <div class="howgetnew-content-step-title">
            {{'job-landing.howgetnew.step4title' | translate}}
          </div>
          <div class="howgetnew-content-step-desc">
            {{'job-landing.howgetnew.step4text' | translate}}
          </div>
        </div>

      </div>

      <div class="howgetnew-content-background">
        <div class="howgetnew-content-background-img">
          <img class="mobile" src="{{assetsUrl('/assets/images/landings/job/howgetnew-bg-mob.png')}}" alt=""/>
          <img class="tablet" src="{{assetsUrl('/assets/images/landings/job/howgetnew-bg-tab.png')}}" alt=""/>
          <img class="desktop" src="{{assetsUrl('/assets/images/landings/job/howgetnew-bg-desk.png')}}" alt=""/>

          <div class="howgetnew-content-background-placeholder">
            <span>
              {{'job-landing.howgetnew.imgtext' | translate}}
            </span>
          </div>
        </div>

      </div>
    </div>

  </div>
</section>
