import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { BillingService, CrispChatService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { CSchool, IBillingCard } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';

type closeFormReasons = 'close' | 'added';

@Component({
  selector: 'app-school-settings-card',
  templateUrl: './school-settings-card.component.html',
  styleUrls: ['./school-settings-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolSettingsCardComponent extends ChangableComponent implements OnInit {
  @Input() school: CSchool;
  public billingCards: IBillingCard[] = [];
  public addBankCardModal: boolean;
  public cardRemoveAlert = false;
  public needSetDefault = false;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
    private billingService: BillingService,
    private crispChat: CrispChatService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.loadBankCards();
  }

  private loadBankCards(): void {
    this.billingService
      .getCardsForSchool(this.school.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (cards) => {
          this.billingCards = cards;
          if (this.needSetDefault && this.billingCards.length === 1) {
            this.billingService.updateCard(this.billingCards[0].id, { is_default: true });
          }
          this.markForCheck();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public onToggleAddBankCardModal(type?: closeFormReasons) {
    this.addBankCardModal = !this.addBankCardModal;
    if (type === 'added') {
      this.needSetDefault = true;
    }
    this.loadBankCards();
  }

  public onCardRemoved(result: boolean) {
    this.loadBankCards();
  }

  public onCardChanged() {
    this.loadBankCards();
  }

  public openCrispChat() {
    this.crispChat.openChat();
  }

  public trackByFn(index) {
    return index;
  }
}
