<div class="header-wrap" [class.mobile]="deviceService.isMobile()">
  <app-main-header></app-main-header>
</div>

<div class="header-wrap" [class.mobile]="deviceService.isMobile()">
  <app-schedule-nav></app-schedule-nav>
</div>

<div class="simple-title" [class.mobile]="deviceService.isMobile()" *ngIf="showTitle()">
  {{ pageTitle() | translate }}
</div>
<router-outlet></router-outlet>
