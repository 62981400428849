import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  AbstractControl,
  ReactiveFormsModule,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { ImageUploaderModule } from '@app/shared';
import { NoContentStubComponent } from '@core/components/no-content-stub/no-content-stub.component';
import { FilesService, VocabularyService } from '@core/services';
import { OpenAiService } from '@core/services/lingo2-content/open-ai.service';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { FilePurposeEnum, ImageSizeEnum, VocabularyTerm } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiCheckboxModule } from 'onclass-ui';
import { Subject } from 'rxjs';
import { debounceTime, delay, distinctUntilChanged, filter, map, mergeMap, switchMap, takeUntil } from 'rxjs/operators';
import { IconComponent } from '../../../shared/icon/icon.component';
import { SharedModule } from '../../shared.module';
import { pageTypes } from '../vocabulary-card/vocabulary-card.component';
import { VocabularyFormAddedComponent } from '../vocabulary-form-added/vocabulary-form-added.component';
import { VocabularyMediaPlayerComponent } from '../vocabulary-media-player/vocabulary-media-player.component';
import { VocabularySurfaceComponent } from '../vocabulary-surface/vocabulary-surface.component';

@Component({
  selector: 'app-vocabulary-content',
  standalone: true,
  imports: [
    SharedModule,
    ReactiveFormsModule,
    ImageUploaderModule,
    OnUiCheckboxModule,
    VocabularyFormAddedComponent,
    VocabularyMediaPlayerComponent,
    NoContentStubComponent,
    IconComponent,
  ],
  templateUrl: './vocabulary-content.component.html',
  styleUrls: ['./vocabulary-content.component.scss'],
})
export class VocabularyContentComponent extends ChangableComponent implements OnInit {
  @Input() page: pageTypes;
  @Input() vocabulary_list_id: number;
  @Input() student_id: string;
  @Input() isSearchTerm: boolean;
  @Output() selected_items = new EventEmitter<VocabularyTerm[]>();
  @Output() showLoader = new EventEmitter<boolean>();
  @Output() changed = new EventEmitter();
  public selected: VocabularyTerm[] = [];
  public form: UntypedFormGroup;
  public isFocusLeft: boolean;
  public isFocusRight: boolean;
  public activeRow: number;

  public terms$ = new Subject<{ event: any; id: string }>();
  public definition$ = new Subject<{ event: any; id: string }>();

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public vocabularyService: VocabularyService,
    public openAiService: OpenAiService,
    public filesService: FilesService,
    protected fb: UntypedFormBuilder,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  @Input()
  public set selectedItemsId(data: boolean) {
    if (!data) {
      this.selected = [];
      this.selected_items.emit(this.selected);
    }
  }

  private _terms: VocabularyTerm[];

  public get terms() {
    return this._terms;
  }

  @Input()
  public set terms(data: VocabularyTerm[]) {
    this._terms = data;
    this.init();
    this.detectChanges();
  }

  private _allSelected: boolean;

  public get allSelected() {
    return this._allSelected;
  }

  @Input()
  public set allSelected(data: boolean) {
    this._allSelected = data;
    if (data) {
      this.selected = this.terms;
    } else {
      this.selected = [];
    }
    this.selected_items.emit(this.selected);
    this.init();
  }

  public get vocabularyItems(): UntypedFormArray {
    return this.form.get('vocabulary_items') as UntypedFormArray;
  }

  public ngOnInit(): void {
    this.terms$.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((term) => {
      this.leftWordChanged(term.event, term.id);
    });

    this.definition$.pipe(debounceTime(400), distinctUntilChanged(), takeUntil(this.destroyed$)).subscribe((term) => {
      this.rightWordChanged(term.event, term.id);
    });
  }

  public init() {
    this.form = this.fb.group({
      vocabulary_items: this.fb.array(this.vocabularyItemsArray(this.terms)),
    });
  }

  private vocabularyItemsArray(values): UntypedFormGroup[] {
    if (!values.length) {
      return [this.vocabularyItemsForm()];
    } else {
      return values.map((value) => this.vocabularyItemsForm(value));
    }
  }

  public get editable(): boolean {
    return this.page === 'main' || this.page === 'student';
  }

  public get selectable(): boolean {
    return this.page === 'main' || this.page === 'list';
  }

  private vocabularyItemsForm(value?: VocabularyTerm): UntypedFormGroup {
    const group = this.fb.group({
      id: [value?.id || ''],
      term: [value?.term || ''],
      definition: [value?.definition || ''],
      image_id: [value?.image_id || null],
      definition_voice_id: [value?.definition_voice_id || null],
      checked: [this.selected.map((el) => el.id).indexOf(value?.id) !== -1],
    });

    const id = group.get('id').value;

    // group
    //   .get('term')
    //   .valueChanges.pipe(
    //     debounceTime(500),
    //     mergeMap((term) => {

    //       console.log('TERM CHANGED');
    //       this.updateTerm(id, { term });
    //       return this.openAiService.translateWord(
    //         term,
    //         this.vocabularyService.term_language_name,
    //         this.vocabularyService.definition_language_name,
    //       );
    //     }),
    //     takeUntil(this.destroyed$),
    //   )
    //   .subscribe({
    //     next: (definition) => {
    //       group.get('definition').setValue(definition);
    //       this.detectChanges();
    //     },
    //     error: (error) => {
    //       this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
    //     },
    //   });

    // group
    //   .get('definition')
    //   .valueChanges.pipe(
    //     debounceTime(500),
    //     switchMap((definition) => {
    //       this.updateTerm(id, { definition });
    //       this.showLoader.emit(true);
    //       group.get('definition_voice_id').setValue(null);
    //       return this.openAiService.generateSpeech(definition, this.vocabularyService.definition_language_id).pipe(
    //         delay(definition.length > 10 ? definition.length * 200 : 2000),
    //         switchMap((audioFile) => this.filesService.uploadFileByUrl(audioFile, FilePurposeEnum.audio)),
    //       );
    //     }),
    //     takeUntil(this.destroyed$),
    //   )
    //   .subscribe({
    //     next: (res) => {
    //       group.get('definition_voice_id').setValue(res.id);
    //       this.detectChanges();
    //     },
    //     error: (error) => {
    //       this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
    //     },
    //   });

    // group
    //   .get('definition_voice_id')
    //   .valueChanges.pipe(takeUntil(this.destroyed$))
    //   .subscribe({
    //     next: (definition_voice_id) => {
    //       this.updateTerm(id, { definition_voice_id });
    //     },
    //     error: (error) => {
    //       this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
    //     },
    //   });

    // group
    //   .get('image_id')
    //   .valueChanges.pipe(takeUntil(this.destroyed$))
    //   .subscribe({
    //     next: (image_id) => {
    //       this.updateTerm(id, { image_id });
    //     },
    //     error: (error) => {
    //       this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
    //     },
    //   });

    return group;
  }

  public updateTerm(id: string, res, updateList = false): void {
    this.showLoader.emit(true);
    this.vocabularyService
      .updateVocabularyTerm(id, res)
      .pipe(debounceTime(500), takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.showLoader.emit(false);
          this.changed.emit(true);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SAVE-PROBLEM');
          this.showLoader.emit(false);
          this.detectChanges();
        },
      });
  }

  public mark(item: VocabularyTerm, checked: boolean) {
    const id = item.id;
    if (checked && !this.selected.find((sel) => sel.id === id)) {
      this.selected.push(item);
    } else {
      this.selected = this.selected.filter((el) => el.id !== id);
    }
    this.selected_items.emit(this.selected);
  }

  public trackByFn(index, item) {
    return item.id;
  }

  public onAdd(e) {
    this.changed.emit();
    this.generateSpeech(e);
    this.generateImage(e);
  }

  private generateSpeech(e) {
    this.openAiService
      .generateSpeech(e.definition, this.vocabularyService.definition_language_id)
      .pipe(
        filter((res) => !!res),
        delay(e.definition.length > 10 ? e.definition.length * 200 : 2000),
        switchMap((audioFile) =>
          this.filesService
            .uploadFileByUrl(audioFile, FilePurposeEnum.audio)
            .pipe(
              switchMap((audio) =>
                this.vocabularyService.updateVocabularyTerm(e.id, { definition_voice_id: audio.id }),
              ),
            ),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: () => {
          this.changed.emit();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
          this.detectChanges();
        },
      });
  }

  private generateImage(e) {
    this.openAiService
      .translateWord(e.definition, this.vocabularyService.term_language_name, 'English')
      .pipe(
        switchMap((query) => this.filesService.getOnlineFiles(query)),
        map((resImages) => ({
          url: resImages.results[0],
          extension: new URLSearchParams(resImages.results[0]).get('fm'),
        })),
        filter((res) => !!res),
        switchMap((urlObject) => {
          const extension = urlObject.extension;
          const clearUrl = encodeURI(urlObject.url);
          if (clearUrl) {
            return this.filesService
              .uploadFileByUrl(clearUrl, FilePurposeEnum.content, extension)
              .pipe(switchMap((image) => this.vocabularyService.updateVocabularyTerm(e.id, { image_id: image.id })));
          }
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: () => {
          this.changed.emit();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-FILE');
          this.detectChanges();
        },
      });
  }

  public onFocusLeft(focus: boolean, idx: number) {
    this.activeRow = idx;
    this.isFocusLeft = focus;
  }

  public onFocusRight(focus: boolean, idx: number) {
    this.activeRow = idx;
    this.isFocusRight = focus;
  }

  public getImageUrl(id: string) {
    return FilesService.getFileUrlBySize(id, ImageSizeEnum.sm);
  }

  public leftWordChanged(event, id) {
    const term = event.target.textContent.trim();
    this.updateTerm(id, { term });
    this.openAiService
      .translateWord(term, this.vocabularyService.term_language_name, this.vocabularyService.definition_language_name)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (definition) => {
          this.updateTerm(id, { definition }, true);
          this.generateSpeech({ definition });
          this.generateImage({ definition });
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });
  }

  public rightWordChanged(event, id) {
    const ttt = event;

    const definition = event.target.textContent.trim();

    if (id === '' && definition === '') {
      return;
    }

    this.updateTerm(id, { definition });
    this.terms.find((item) => item.id === id).definition_voice_id = null;
    this.showLoader.emit(true);
    return this.openAiService
      .generateSpeech(definition, this.vocabularyService.definition_language_id)
      .pipe(
        delay(definition.length > 10 ? definition.length * 200 : 2000),
        switchMap((audioFile) => this.filesService.uploadFileByUrl(audioFile, FilePurposeEnum.audio)),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (res) => {
          this.terms.find((item) => item.id === id).definition_voice_id = res.id;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });
  }
}
