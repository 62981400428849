import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ChangableComponent } from '@models/changable.component';
import { VocabularyTerm } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ICard, mainCards } from '../../vocabulary.variables';

@Component({
  selector: 'app-vocabulary-main',
  templateUrl: './vocabulary-main.component.html',
  styleUrls: ['./vocabulary-main.component.scss'],
})
export class VocabularyMainComponent extends ChangableComponent implements OnInit {
  public widgets: ICard[] = mainCards;
  public vocabulary: Partial<VocabularyTerm>;

  constructor(protected readonly cdr: ChangeDetectorRef, public deviceService: DeviceDetectorService) {
    super(cdr);
  }

  ngOnInit(): void {}

  public onVocabularyChange(e: Partial<VocabularyTerm>) {
    this.vocabulary = e;
  }
}
