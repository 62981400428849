import { ChangeDetectionStrategy, Component, Injector, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IPagination } from '@app/content-editor/store/library/library.selectors';
import { BaseCalendarComponent, IScheduleProps } from '@app/events-calendar/base-calendar/base-calendar.component';
import { CalendarContext } from '@app/events-calendar/user-service-multi-selector/user-service-multi-selector.component';
import { FeaturesService, fullUserServiceDetails, IFindSchoolScheduleFilter } from '@core/services';
import { getMyScheduleCurrentDay } from '@store/reducers/profile.reducer';
import { endOfWeek, startOfWeek } from 'date-fns';
import {
  CSchool,
  ESchoolParticipantRole,
  FindUserServiceStrategyEnum,
  IFindUserServicesFilter,
  SingleEvent,
  User,
  UserService,
} from 'lingo2-models';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-school-teacher-calendar',
  templateUrl: '../base-calendar/base-calendar.component.html',
  styleUrls: ['../base-calendar/base-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolTeacherCalendarComponent extends BaseCalendarComponent implements OnInit, OnChanges {
  @Input() school: CSchool;
  @Input() teacher: Partial<User>;

  public constructor(protected inject: Injector, public features: FeaturesService) {
    super(inject, features);
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.log('SchoolTeacherCalendarComponent.ngOnInit', {
      school_id: this.school?.id,
      teacher_id: this.teacher?.id,
    });

    this.reloadSchedule();
    this.loadUserServices();
    this.startWatchingSchedule(this.teacher?.id);
  }

  public ngOnChanges(changes: SimpleChanges): void {
    this.log('SchoolTeacherCalendarComponent.ngOnChanges', {
      school_id: this.school?.id,
      teacher_id: this.teacher?.id,
    });

    this.reloadSchedule();
    this.loadUserServices();
    this.startWatchingSchedule(this.teacher?.id);
  }

  public get canScheduleMeeting(): boolean {
    return false;
  }

  public get canScheduleServices(): boolean {
    return this.isSchoolOwner;
  }

  public get canMarkAvailableHour(): boolean {
    return false;
  }

  public get scheduleServiceTeacher(): Partial<User> {
    return this.teacher;
  }

  protected get isSchoolOwner(): boolean {
    return (this.school?.participants || []).some(
      (p) => p.account_id === this._me?.id && p.role === ESchoolParticipantRole.owner,
    );
  }

  protected findUserServices$(): Observable<UserService[]> {
    const filter: Partial<IFindUserServicesFilter> = {
      use: FindUserServiceStrategyEnum.school_teaching,
      school_id: this.school?.id,
      teacher_id: this.teacher?.id,
    };
    const pagination: IPagination = {
      page: 1,
      pageSize: 100,
    };

    return this.userServicesService
      .getServices(filter, pagination, fullUserServiceDetails)
      .pipe(map((response) => response.results));
  }

  protected loadSchedule$(props: IScheduleProps): Observable<SingleEvent[]> {
    return this.store.select(getMyScheduleCurrentDay).pipe(
      switchMap((currentDay) => {
        const date = props.date || currentDay || new Date();
        const _filter: IFindSchoolScheduleFilter = {
          account_id: [this.teacher?.id],
          date_from: startOfWeek(date, { locale: this.dateConfig.locale() }),
          date_to: endOfWeek(date, { locale: this.dateConfig.locale() }),
        };
        // this.log(this.constructor.name + '.loadSchedule$ ', JSON.stringify(props), ' -> ' + JSON.stringify(_filter));

        return this.scheduleService
          .findSchoolSchedule(this.school?.id, _filter)
          .pipe(
            switchMap((events) => this.eventExtenderService.extend$(events, ['account', 'meeting', 'user_service'])),
          );
      }),
    );
  }

  public get context(): CalendarContext {
    return {
      context: 'school',
      data: this.school,
    };
  }
}
