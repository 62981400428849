import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NotificationsService } from '@app/notifications/notifications.service';
import { ContextService } from '@core/services';
import { WebsocketService } from '@core/websocket';
import { ChangableComponent } from '@models/changable.component';
import { IPagination, GenericNotice } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-notifications-page',
  templateUrl: './notifications-page.component.html',
  styleUrls: ['./notifications-page.component.scss'],
})
export class NotificationsPageComponent extends ChangableComponent implements OnInit {
  public notices: Array<GenericNotice<any>>;
  public pagination: IPagination = {
    page: 1,
    pageSize: 25,
    total: null,
    totalPages: null,
  };
  public debug$ = this.contextService.debug$;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private websocket: WebsocketService,
    private notificationsService: NotificationsService,
    private contextService: ContextService,
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr);
  }

  ngOnInit() {
    // Get notices page
    this.websocket.onNotices.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (pagedNotices) => {
        this.notices = pagedNotices.results.map((v) => v.notice);
        if (pagedNotices.unread_count > 0) {
          this.websocket.send('notice-read', {
            lastId: this.notices.filter((element) => typeof element !== undefined).shift().id,
          });
          // this.websocket.send('notice-read', { lastId: notice.id || this.lastId });
        }
        this.pagination = pagedNotices.pagination;
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
      }
    });

    /** Ask for notices when auth complete */
    this.websocket.onAuthComplete.pipe(debounceTime(1000), takeUntil(this.destroyed$)).subscribe({
      next: (user) => {
        this.loadFromStart();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
      }
    });

    /** Ask for notices when connect/reconnect */
    this.websocket.status$
      .pipe(
        filter((status) => status),
        debounceTime(1000),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (status) => {
          this.loadFromStart();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        }
      });

    /** Ask for notices now */
    this.loadFromStart();
  }

  protected loadNotices() {
    this.websocket.send('notices', this.pagination);
    // @see this.websocket.onNotices
  }

  public nextPage() {
    this.pagination.page = this.pagination.page + 1;
    this.loadNotices();
  }

  public loadFromStart() {
    this.pagination.page = 1;
    this.loadNotices();
  }

  public trackByFn(notice: GenericNotice<any>): number {
    return notice?.id;
  }
}
