<div class="vacation-form"
     [class.tablet]="deviceService.isTablet()"
     [class.mobile]="deviceService.isMobile()">
  <div class="content" [formGroup]="form" *ngIf="form">
    <h2 class="title">{{ 'vacation-form.title' | translate }}</h2>
    <p class="text">{{ 'vacation-form.text' | translate }}</p>

    <div class="period-wrap">
      <ng-container *ngIf="!deviceService.isMobile()">
        <div class="actions">
        <label class="input-wrap">
          <input type="checkbox" formControlName="is_vacation" class="input-checkbox">
          <i class="checkbox"></i>
          <span class="label">{{ (isActive ? 'vacation-form.label' : 'vacation-form.label-off') | translate }}</span>
        </label>
        </div>

        <div class="period">
          <span class="label-text">{{ 'vacation-form.from' | translate }}</span>
          <div class="date-input-wrap">
            <on-ui-date formControlName="vacation_begin_at" [placeholder]="placeholder" [min]="minDateFrom"></on-ui-date>
          </div>
          <span class="label-text">{{ 'vacation-form.to' | translate }}</span>
          <div class="date-input-wrap">
            <on-ui-date formControlName="vacation_end_at" [placeholder]="placeholder" [min]="minDateTo"></on-ui-date>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="deviceService.isMobile()">
        <div class="actions">
          <label class="input-wrap">
            <input type="checkbox" formControlName="is_vacation" class="input-checkbox">
            <i class="checkbox"></i>
            <span class="label">{{ (isActive ? 'vacation-form.label' : 'vacation-form.label-off') | translate }}</span>
          </label>
        </div>
        <span class="label-text">{{ 'vacation-form.from' | translate }}</span>
        <div class="label-mobile-wrap">
        <div class="date-input-wrap">
          <on-ui-date formControlName="vacation_begin_at" [placeholder]="placeholder" [min]="minDateFrom"></on-ui-date>
        </div>
        <span class="label-text">{{ 'vacation-form.to' | translate }}</span>
        <div class="date-input-wrap">
          <on-ui-date formControlName="vacation_end_at" [placeholder]="placeholder" [min]="minDateTo"></on-ui-date>
        </div>
        </div>
      </ng-container>
    </div>

    <div class="actions-wrap" [class.content-between]="saved || error">
      <div class="saved-info" *ngIf="saved">{{ 'vacation-form.saved' | translate }}</div>
      <div class="error" *ngIf="error">{{ error }}</div>
      <on-ui-button class="primary"
                    [state]="saveState"
                    [disabled]="saveState !=='default' || isFormUnchanged() || form.invalid"
                    label="vacation-form.save"
                    data-test="vacation-save"
                    (onclick)="save()">
      </on-ui-button>
    </div>
  </div>
</div>
