import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ConstructorSidebarComponent } from './constructor-sidebar/constructor-sidebar.component';
import { ContentEditorComponent } from './content-editor.component';

const routes: Routes = [
  {
    path: '',
    component: ContentEditorComponent,
    children: [
      {
        path: 'edit/:lesson_id',
        loadChildren: () => import('./content-form-page/content-form-page.module').then((m) => m.ContentFormPageModule),
        data: { preload: true },
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'edit/new',
      },
    ],
  },
  {
    path: '',
    outlet: 'right-sidebar-top',
    component: ConstructorSidebarComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContentEditorRoutingModule {}
