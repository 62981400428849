import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  BillingService,
  FilesService,
  PlatformService,
  SchoolsService,
  ServicesBlockType,
  UserServicesService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import {
  CSchool,
  ESchoolParticipantRole,
  ESchoolStatus,
  IBillingCard,
  IFindUserServicesFilter,
  FindUserServiceStrategyEnum,
} from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiCover } from 'onclass-ui';
import { first, takeUntil } from 'rxjs/operators';

export interface IStatusInfo {
  text: string;
  icon: string;
}

@Component({
  selector: 'app-school-settings-status',
  templateUrl: './school-settings-status.component.html',
  styleUrls: ['./school-settings-status.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolSettingsStatusComponent extends ChangableComponent implements OnInit {
  @Input() school: CSchool;
  public title: string;
  public status: ESchoolStatus;
  public billingCards: IBillingCard[] = [];
  public cover: OnUiCover;
  public services: ServicesBlockType = {
    items: [],
    loading: false,
    loaded: false,
    filter: {},
    pagination: {
      page: 1,
      pageSize: 10,
    },
  };

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private userServicesService: UserServicesService,
    private billingService: BillingService,
    private schoolService: SchoolsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.prepareCard();
    this.loadServices();
    this.loadBankCards();
  }

  private prepareCard() {
    this.cover = {
      img: FilesService.getFileUrlBySize(this.school?.cover_id),
      title: this.title,
    };
    this.status = this.school?.status;
  }

  private loadServices() {
    this.services.loading = true;
    this.detectChanges();

    const filter: Partial<IFindUserServicesFilter> = {
      use: FindUserServiceStrategyEnum.school_owned,
      school_id: this.school?.id,
    };

    this.userServicesService
      .getServices(filter, this.services.pagination)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.services.items = response.results;
          this.services.pagination = response.pagination;
          this.services.loaded = true;
          this.services.loading = false;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.services.loading = false;
          this.detectChanges();
        },
      });
  }

  private loadBankCards() {
    if (!this.school?.id) {
      return;
    }

    this.billingService
      .getCardsForSchool(this.school?.id)
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (cardList) => {
          this.billingCards = cardList;
          this.markForCheck();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public isActive(badge: string): boolean {
    switch (badge) {
      case 'verification':
        return true;
      case 'tutors':
        return !!this.school?.participants?.filter((_t) => _t.role === ESchoolParticipantRole.teacher)?.length;
      case 'services':
        return !!this.services?.items?.length;
      case 'bank_card':
        return !!this.billingCards?.length;
    }
  }

  public get canSendToModeration(): boolean {
    return (
      !!this.school?.participants?.filter((_t) => _t.role === ESchoolParticipantRole.teacher)?.length &&
      !!this.services?.items?.length &&
      !!this.billingCards?.length &&
      this.school.status === ESchoolStatus.draft
    );
  }

  public statusInfo(status: ESchoolStatus): IStatusInfo {
    switch (status) {
      case ESchoolStatus.removed:
        return { text: 'notifications.school-status-changed.status.removed', icon: 'status-not-verified' };
      case ESchoolStatus.draft:
        return { text: 'notifications.school-status-changed.status.not-published', icon: 'status-not-verified' };
      case ESchoolStatus.verification_declined:
        return {
          text: 'notifications.school-status-changed.status.verification_declined',
          icon: 'status-not-verified',
        };
      case ESchoolStatus.under_verification:
        return { text: 'notifications.school-status-changed.status.on-moderation', icon: 'status-on-moderation' };
      case ESchoolStatus.verified:
      case ESchoolStatus.published:
      case ESchoolStatus.working:
        return { text: 'notifications.school-status-changed.status.published', icon: 'status-verified' };
      default:
        return { text: '', icon: '' };
    }
  }

  public onPublish() {
    this.schoolService
      .publish(this.school.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (school) => {
          this.school = school;
          this.prepareCard();
          this.markForCheck();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }
}
