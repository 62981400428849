import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tabs } from '@app/library/panes/my-library-services/constants';
import { IServicesTab } from '@app/library/panes/my-library-services/models';
import {
  ConfigService,
  fullUserServiceDetails,
  PlatformService,
  ServicesBlockType,
  UserServicesService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { LibraryRouter } from '@models/library.router';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadSubjects } from '@store/actions/data.actions';
import {
  CSchool,
  EContentOrder,
  EUserServiceType,
  FindUserServiceStrategyEnum,
  IFindUserServicesFilter,
  ITeachingSubject,
  MediaFormatEnum,
  PublishStatusEnum,
  Subject,
  User,
  UserService,
  UserServiceDetailsType,
} from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiTab } from 'onclass-ui';
import { Subscription, takeUntil } from 'rxjs';

export interface FromPageData {
  type: string;
  ownerId: string;
}

@Component({
  selector: 'app-school-settings-classes',
  templateUrl: './school-settings-classes.component.html',
  styleUrls: [
    '../../library/panes/filter-content/filter-content.component.scss',
    './school-settings-classes.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolSettingsClassesComponent extends ChangableComponent implements OnInit {
  @Input() school: CSchool;
  @Input() me: User;
  public schoolData: FromPageData;
  public tabs = tabs;
  public newService: UserService;
  public editService: UserService;
  private _onChange: Subscription;
  private _subjects: Subject[];

  public activeTab: IServicesTab;

  public serviceSubjectsFilters: OnUiTab[] = [];
  public serviceSubjectsSelectedFilter: OnUiTab = null;

  public serviceCategoryFilters: OnUiTab[] = [];
  public serviceCategorySelectedFilter: OnUiTab = null;

  public teachingSubjects: ITeachingSubject[] = [];
  public teachingSubjectsFilters: OnUiTab[] = [];
  public teachingSubjectSelectedFilter: OnUiTab = null;

  public openedUserService: UserService;
  public userServiceCardFullInModal: boolean;
  public surfaceBlockDisplay: boolean;

  public originalUrl: string;

  public services: ServicesBlockType = {
    items: [],
    loading: false,
    loaded: false,
    filter: {
      use: FindUserServiceStrategyEnum.school_owned,
      order: EContentOrder.date_desc,
    },
    pagination: {
      page: 1,
      pageSize: 10,
    },
  };

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private store: Store,
    private location: Location,
    private userServicesService: UserServicesService,
    private configService: ConfigService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.teachingSubjects = this.setTeachingSubjects();
    this.activeTab = this.tabs[0];
    this.loadServices();

    this.configService.subjects$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (subjects) => {
        this._subjects = subjects;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.store.dispatch(loadSubjects({ locale: this.me.ui_language }));
      },
    });
  }

  private setTeachingSubjects(): ITeachingSubject[] {
    return this.school?.subjects.map((_s) => ({
      subject_id: _s.id,
      subject: _s,
      subject_other_name: null,
      subject_other_id: null,
      description: '',
      video_url: '',
      video_id: '',
      video: {
        format: MediaFormatEnum.original,
        url: '',
      },
    }));
  }

  private loadServices() {
    this.services.loading = true;
    this.detectChanges();

    const filter: Partial<IFindUserServicesFilter> = {
      ...this.services.filter,
      publish_status: PublishStatusEnum[this.activeTab.code],
      school_id: this.school?.id,
    };

    const details: UserServiceDetailsType[] = [
      ...fullUserServiceDetails,
      'school',
      'school:cover',
      'school:participants:stats',
      'teacher_ids',
      'teachers',
    ];

    this.userServicesService
      .getServices(filter, this.services.pagination, details)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.services.items = response.results;
          this.services.pagination = response.pagination;
          this.services.loaded = true;
          this.services.loading = false;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.services.loading = false;
          this.detectChanges();
        },
      });
  }

  public onCreateSchoolService() {
    this.newService = new UserService({
      author_id: this.me.id,
      school_id: this.school.id,
      school: this.school,
      subject_id: this.teachingSubjects[0].subject_id,
      participants_limit: 1,
      type: EUserServiceType.single,
    });
  }

  public get subjects(): Subject[] {
    return this._subjects;
  }

  public trackUserServiceByFn(index, userService: UserService): string {
    return userService.id;
  }

  public activateTab(tab: IServicesTab, force = false) {
    if (this.activeTab?.code !== tab.code || force) {
      this.activeTab = tab;
      this.loadServices();
      this.detectChanges();
    }
  }

  public onRemove(e: boolean) {
    if (e) {
      this.onChangeUserService();
    }
  }

  public onSelect(service: UserService) {
    this.editService = service;
    this.editService.school = this.school;
    this.detectChanges();
  }

  public onCancelSchoolService() {
    this.editService = null;
    this.newService = null;
  }

  public onChangeUserService() {
    this.editService = null;
    this.newService = null;
    this.detectChanges();

    this.clearTimeout(this._onChange);
    this._onChange = this.setTimeout(() => {
      this.loadServices();
    }, 1000);
  }

  public onToggleUserServiceInModal(userService: UserService = null) {
    this.openedUserService = userService;
    this.userServiceCardFullInModal = !this.userServiceCardFullInModal;
    this.setAndResetUrl();
    this.detectChanges();
  }

  private setAndResetUrl() {
    const url = LibraryRouter.userServiceRouteUniversal(this.openedUserService);
    const currentUrl = this.location.path(false);
    if (this.userServiceCardFullInModal) {
      if (currentUrl !== url) {
        if (!this.originalUrl) {
          this.originalUrl = currentUrl;
        }
        this.location.replaceState(url);
      }
    } else {
      if (this.originalUrl) {
        this.location.replaceState(this.originalUrl);
        this.originalUrl = null;
      }
    }
  }

  public onIinViewportChange(e) {
    this.surfaceBlockDisplay = e;
    this.detectChanges();
  }

  public trackByFn(index) {
    return index;
  }
}
