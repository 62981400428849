<section class="schools-settings-right-sidebar">
  <p class="title">{{ 'help-widget.title' | translate }}</p>
  <p class="description">{{ 'help-widget.description' | translate }}</p>
  <p class="action" (click)="openCrispChat()">
    <app-icon
      class="dialog-icon"
      [width]="22"
      [height]="22"
      [icon]="'dialog-icon'">
    </app-icon>
    <span>{{ 'help-widget.action' | translate }}</span>
  </p>
</section>
