import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { LibrarySidebarService } from '@app/library/sidebars/library-sidebar/library-sidebar.service';
import { loadMyMeetings } from '@app/store/actions/profile.actions';
import { AccountService, ContextService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { getLibraryRouteData } from '@store/reducers/library.reducer';
import { getMyMeetings } from '@store/reducers/profile.reducer';
import { User, UserSegmentEnum, EContentPanelRoute, Meeting } from 'lingo2-models';
import { mergeMap, Observable, filter, takeUntil } from 'rxjs';

@Component({
  selector: 'app-meetings-sidebar',
  templateUrl: './meetings-sidebar.component.html',
  styleUrls: ['./meetings-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingsSidebarComponent extends ChangableComponent implements OnInit {
  public me: User;
  public user: User;
  meetings: Observable<Meeting[]>;

  public isStudy = true;
  public showWidget = false;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private store: Store,
    private contextService: ContextService,
    private readonly sidebarService: LibrarySidebarService,
    private readonly accountService: AccountService,
    @Inject(PLATFORM_ID) private platformId: object,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.sidebarService.user$
      .pipe(
        filter((user) => !!user),
        mergeMap((user) => this.accountService.getUserById(user.id, 'LOAD_TIME')),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (_user) => {
          this.user = _user;
          this.markForCheck();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });

    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (me) => (this.me = me),
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    this.store
      .select(getLibraryRouteData)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (libraryRouteData) => {
          this.showWidget = libraryRouteData?.subjectCodes.length <= 0;
          this.isStudy = libraryRouteData?.baseRoute === EContentPanelRoute.classes && !libraryRouteData?.item;
          this.markForCheck();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
    this.store.dispatch(loadMyMeetings({}));
    this.meetings = this.store.select(getMyMeetings);
  }

  public get isAsIfTeacher(): boolean {
    return (
      this.me && !AccountService.isTeacher(this.me) && AccountService.hasSegment(this.me, UserSegmentEnum.as_if_teacher)
    );
  }
}
