import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { AccountService, BillingService, FeaturesService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadBillingSetting } from '@store/actions/data.actions';
import { loadMe } from '@store/actions/profile.actions';
import { loadUsers } from '@store/actions/users.actions';
import { getBillingSettings } from '@store/reducers/data.reducer';
import {
  AccountBalance,
  currencies,
  CurrencyEnum,
  FeatureEnum,
  IBillingSettings,
  TenantEnum,
  User,
} from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { getMe } from 'src/app/store/reducers/profile.reducer';
import { getCurrentUser } from 'src/app/store/reducers/users.reducer';

@Component({
  selector: 'app-account-sidebar',
  templateUrl: './account-sidebar.component.html',
  styleUrls: ['./account-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AccountSidebarComponent extends ChangableComponent implements OnInit, OnDestroy {
  public me: User;
  public user: User;
  private billingSettings: Partial<IBillingSettings>;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  public accountRoute = AccountService.accountRoute;
  // eslint-disable-next-line @typescript-eslint/unbound-method
  private _creditsBalance: AccountBalance;
  public user$: Observable<User>;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    private features: FeaturesService,
    private store: Store,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.store
      .select(getCurrentUser)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (user) => {
          this.user = user;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          if (this.me.id) {
            this.store.dispatch(loadUsers({ user_ids: [this.me.id] }));
          } else {
            this.store.dispatch(loadMe());
          }
        },
      });

    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (me) => {
          this.me = me;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadMe());
        },
      });

    this.store
      .select(getBillingSettings)
      .pipe(
        filter((settings) => !!settings.balance.length),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (settings) => {
          if (!settings) {
            return;
          }
          this.billingSettings = settings;
          this._creditsBalance = this.billingSettings.balance.find((b) => b.currency.id === CurrencyEnum.credits);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadBillingSetting({ force: true }));
        },
      });
  }

  public get loaded(): boolean {
    return !!this.me?.id && !!this.user?.id;
  }

  public get isUserTeacher(): boolean {
    return this.loaded && AccountService.isAsIfTeacher(this.user);
  }

  public get isAsIfTeacher(): boolean {
    return this.loaded && AccountService.isAsIfTeacher(this.me);
  }

  public get isMe(): boolean {
    return this.loaded && this.me && this.user && this.user.id === this.me.id;
  }

  public get isAvailableProfileFinance() {
    return this.features.isAvailable(FeatureEnum.profile_finance, this.me);
  }

  public isMeVersion() {
    return this.features.tenant === TenantEnum.onclass_me;
  }

  public get creditsBalance(): AccountBalance {
    return this._creditsBalance;
  }

  public formatAmount(amount: number, currency?: CurrencyEnum): string {
    if (!amount) {
      return '--.00';
    }
    const _currency = currency || CurrencyEnum.usd;
    return currencies[_currency].format(amount);
  }
}
