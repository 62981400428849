import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router, UrlSegment } from '@angular/router';
import { AccountService, ContextService, PlatformService, ScreenService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { IContentFilterBlock, Subject as BaseSubject, User } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';
import { UsersFormService } from './users-form.service';

const menuItems: IContentFilterBlock[] = [
  {
    order: 0,
    id: 0,
    title: 'users.tabs-subscriptions',
    code: 'subscriptions',
    type: 'subject',
    item: new BaseSubject(),
    total_count: 0,
  },
  {
    order: 1,
    id: 1,
    title: 'users.tabs-subscribers',
    code: 'subscribers',
    type: 'subject',
    item: new BaseSubject(),
    total_count: 0,
  },
  {
    order: 3,
    id: 3,
    title: 'users.tabs-other-users',
    code: 'all',
    type: 'subject',
    item: new BaseSubject(),
    total_count: 0,
  },
];

const menuItemsForTeacher: IContentFilterBlock[] = [
  ...menuItems,
  {
    order: 2,
    id: 2,
    title: 'users.tabs-students',
    code: 'students',
    type: 'subject',
    item: new BaseSubject(),
    total_count: 0,
  },
];

@Component({
  selector: 'app-users-page',
  templateUrl: './users-page.component.html',
  styleUrls: ['./users-page.component.scss'],
})
export class UsersPageComponent extends ChangableComponent implements OnInit {
  public tab = 'subscriptions';
  public term = '';
  public popup_open = false;
  public expandSearch = false;
  public menuItems: IContentFilterBlock[] = menuItems;
  public menuItemsForTeacher: IContentFilterBlock[] = menuItemsForTeacher;
  private me: User;

  private width = 0;

  constructor(
    private usersForm: UsersFormService,
    private contextService: ContextService,
    private router: Router,
    private screenService: ScreenService,
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {
    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (width) => {
        this.width = width;
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.width = window.innerWidth;
      },
    });
    this.term = this.usersForm.term;

    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (me) => {
        this.me = me;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });
  }

  popupOpen() {
    this.popup_open = true;
  }

  popupState(arg) {
    this.popup_open = arg;
  }

  public get menuList(): IContentFilterBlock[] {
    return this.isTeacher ? this.menuItemsForTeacher : this.menuItems;
  }

  public link(item: IContentFilterBlock): UrlSegment {
    return new UrlSegment(item.code, {});
  }

  public onTermChange() {
    this.usersForm.term = this.term;
  }

  public showSearchForm() {
    if (this.width <= 870) {
      this.expandSearch = true;
    }
  }

  public clearSearchForm() {
    this.term = '';
    this.onTermChange();
    this.expandSearch = false;
  }

  public get isTeacher(): boolean {
    return AccountService.isAsIfTeacher(this.me);
  }

  public get currentUrl() {
    return this.router.url;
  }

  /** Если понадобится выключать заголовок по каким либо улосвиям */
  public get showTitle() {
    const urlArray = this.currentUrl.split('/');
    const code = urlArray[urlArray.length - 1];
    return true;
  }

  public get pageTitle() {
    const urlArray = this.currentUrl.split('/');
    return urlArray[urlArray.length - 1];
  }

  public trackByFn(index) {
    return index;
  }
}
