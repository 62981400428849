import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChangableComponent } from '@app/models/changable.component';
import { loadMe } from '@app/store/actions/profile.actions';
import { AccountService, BillingService, ContextService, FeaturesService, PlatformService } from '@core/services';
import {
  AccountByMerchant,
  BillingV2Service,
  IFinanceCardCreateNewOptions,
  SuppliersByMerchant,
} from '@core/services/lingo2-account/billing-v2.service';
import {
  BillingApiAccountResponseDto,
  BillingApiMerchantResponseDto,
  BillingApiSupplierDto,
  BillingApiSupplierResponseDto,
  BillingApiTransactionFindQueryDto,
  BillingApiTransactionResponseDto,
} from '@lingo2-billing-sdk/models';
import { BillingCoreSupplierStatusEnum } from '@lingo2-billing-sdk/models/src/billing-core/billing-core-supplier/types/enums/billing-core-supplier-status.enum';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { FeatureEnum, IContentBlock, TenantEnum, User } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-profile-payment',
  templateUrl: './profile-payment.component.html',
  styleUrls: ['./profile-payment.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePaymentComponent extends ChangableComponent implements OnInit, OnDestroy {
  @Input() type: 'payout' | 'payment' = 'payout';

  public supplierModalOptions: IFinanceCardCreateNewOptions = {
    where: {
      providerId: null,
      countryCode: null,
    },
    supplierId: null,
  };

  public activeSuppliers: SuppliersByMerchant[] = [];
  public nonActiveSuppliers: SuppliersByMerchant[] = [];
  public merchants: BillingApiMerchantResponseDto[];
  public activeAccounts: AccountByMerchant[] = [];
  public inactiveAccounts: AccountByMerchant[] = [];
  public transactions: Partial<IContentBlock<BillingApiTransactionResponseDto, BillingApiTransactionFindQueryDto>> = {
    loading: false,
    loaded: false,
    items: [],
    filter: {},
  };

  public newPaymentFormOpened = false;
  public paymentHistoryOpened = false;
  public showWarningAlert = false;
  private addPaymentMerchantId = null;

  public showNonActiveSuppliers = false;

  public showEditAlert = false;
  public selectedProviderId = null;
  protected _me: User;

  public withdrawMoneySupplier: BillingApiSupplierResponseDto;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    protected contextService: ContextService,
    protected features: FeaturesService,
    protected store: Store,
    protected billingService: BillingService,
    protected accountService: AccountService,
    private router: Router,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
    protected readonly billingV2Service: BillingV2Service,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.updateData();

    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (me) => {
        this._me = me;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.store.dispatch(loadMe());
      },
    });
  }

  public updateData() {
    if (this.type === 'payout') {
      this.getMerchants();
      this.loadSuppliers();
    } else {
      this.getMerchants();
    }
    this.detectChanges();
  }

  public isMeVersion() {
    return this.features.tenant === TenantEnum.onclass_me;
  }

  public navigateTo(to: string): void {
    if (to === 'back') {
      this.router.navigate([this.type === 'payout' ? '/profile/payment' : '/profile/teaching']);
    } else {
      this.router.navigate([this.isMeVersion() ? '/' : '/profile/services']);
    }
  }

  public closeNewCardForm() {
    this.newPaymentFormOpened = false;
    this.clearModalOptionsData();
    this.updateData();
  }

  public openPaymentHistory(providerId: string): void {
    this.loadTransactions(providerId);
    this.paymentHistoryOpened = true;
  }

  public closePaymentHistory() {
    this.paymentHistoryOpened = false;
  }

  public getMoney(event: BillingApiSupplierResponseDto): void {
    this.withdrawMoneySupplier = event;
  }

  public getMerchants() {
    this.billingV2Service
      .getMerchants()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (merchants) => {
          this.merchants = merchants;
          merchants.forEach((merchant) => {
            this.loadAccounts(merchant.id);
          });
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public isForNotRussia(merchant: BillingApiMerchantResponseDto) {
    if (merchant.unSupportCountries) {
      return (merchant.unSupportCountries as any[]).includes('RU');
    } else {
      if (merchant.supportedCountries) {
        return !(merchant.supportedCountries as any[]).includes('RU');
      } else {
        return true;
      }
    }
  }

  public updateSupplierList() {
    this.loadSuppliers();
  }

  public updateAccountList(merchantId) {
    this.loadAccounts(merchantId);
  }

  public editMethod(supplier: BillingApiSupplierResponseDto) {
    this.supplierModalOptions.supplierId = supplier?.id;
    this.supplierModalOptions.where.providerId = supplier?.providerId;
    this.supplierModalOptions.where.countryCode = supplier?.countryCode;

    if (supplier.status === BillingCoreSupplierStatusEnum.CONFIRMED) {
      this.showEditAlert = true;
      return;
    }

    this.newPaymentFormOpened = true;
  }

  public addAccount(merchantId: string): void {
    this.selectedProviderId = merchantId;
  }

  public onCloseAddAccount() {
    this.updateAccountList(this.selectedProviderId);
    this.selectedProviderId = null;
  }


  public showAddPaymentAlert(merchantId: string) {
    if (!!this.activeSuppliers[merchantId].length) {
      this.addPaymentMerchantId = merchantId;
      this.showWarningAlert = true;
    } else {
      this.addPaymentMerchantId = merchantId;
      this.addPayment();
    }
  }

  public closeAddPaymentAlert() {
    this.showWarningAlert = false;
    this.newPaymentFormOpened = false;
    this.addPaymentMerchantId = null;
  }

  public addPayment(): void {
    if (this.addPaymentMerchantId) {
      this.showWarningAlert = false;
      this.supplierModalOptions.where.providerId = this.addPaymentMerchantId;
      this.supplierModalOptions.supplierId = null;
      this.newPaymentFormOpened = true;
    }
  }

  public setSupplierDefault(supplier: BillingApiSupplierResponseDto) {
    const payload = new BillingApiSupplierDto();
    payload.isActive = true;
    this.billingV2Service
      .patchSupplier(supplier.id, payload)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.updateSupplierList();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SAVE-PROBLEM');
          this.detectChanges();
        },
      });
  }

  private loadSuppliers() {
    this.billingV2Service
      .getSuppliers()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (suppliers) => {
          if (this.merchants) {
            this.merchants.forEach((merchant) => {
              this.activeSuppliers[merchant.id] = suppliers.filter(
                (item) => item.providerId === merchant.id && item.isActive,
              );
              this.nonActiveSuppliers[merchant.id] = suppliers.filter(
                (item) => item.providerId === merchant.id && !item.isActive,
              );
            });
          }
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  private loadAccounts(providerId: string) {
    this.billingV2Service
      .getAccounts(providerId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.merchants.forEach((merchant) => {
            this.activeAccounts[merchant.id] = res.filter((item) => item.providerId === merchant.id && item.isDefault);
            this.inactiveAccounts[merchant.id] = res.filter(
              (item) => item.providerId === merchant.id && !item.isDefault,
            );
          });
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public loadTransactions(providerId: string) {
    const request = new BillingApiTransactionFindQueryDto();
    request.page = 1;
    request.pageSize = 50;
    request.accountProviderId = providerId;

    this.billingV2Service
      .findTransactions(request)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.transactions.items = res.results;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public toggleNonActiveSupplier() {
    this.showNonActiveSuppliers = !this.showNonActiveSuppliers;
  }

  private clearModalOptionsData() {
    this.supplierModalOptions = {
      where: {
        providerId: null,
        countryCode: null,
      },
      supplierId: null,
    };
  }

  public closeWithdrawMoneyForm() {
    this.withdrawMoneySupplier = null;
  }

  public isTestProvider(provider: BillingApiMerchantResponseDto) {
    return provider.testMode;
  }

  public isTestBeneficiary(beneficiary: BillingApiSupplierResponseDto) {
    return this.merchants.find((provider) => provider.id === beneficiary.providerId).testMode;
  }

  public isTestCard(account: BillingApiAccountResponseDto) {
    return this.merchants.find((provider) => provider.id === account.providerId).testMode;
  }

  public trackByFn(index) {
    return index;
  }
}
