<section class="promo-footer">
  <div class="promo-footer-container">
    <div class="promo-footer-data">
      <div class="promo-footer-title">
        {{'landings.home.promo-footer-title' | translate}}
      </div>
      <div class="promo-footer-text">
        {{'landings.home.promo-footer-text' | translate}}
      </div>

      <div class="promo-footer-action-btn">
        <button class="action-btn with-arrow text-center" (click)="isAuth ? navigateToMain() : onBecomeTeacher($event, mainRoute, false)">
          {{'landings.home.promo-footer-link' | translate}}
        </button>
      </div>
    </div>
  </div>
</section>
