<div class="card-section">
  <div class="card-header">
    <div>
      <h2 class="title">{{ 'notifications.billing-card.bank-cards' | translate }}</h2>
      <p class="sub-title">{{ 'notifications.billing-card.bank-card-description' | translate}}</p>
    </div>
    <p class="link" (click)="onToggleAddBankCardModal()">{{ 'finance.card-page.add-card' | translate }}</p>
  </div>

  <ng-template [ngIf]="!billingCards.length">
    <div class="widget-block">
      <app-icon
        [width]="155"
        [height]="113"
        [icon]="'school-steps-placeholder-bank_card'">
      </app-icon>
    </div>
  </ng-template>
</div>

<div class="footer-action" *ngIf="!deviceService.isMobile()">
  <div class="support">
    <div class="badge">
      <app-icon
        class="badge__icon"
        [width]="20"
        [height]="16"
        [icon]="'badge-bank_card'">
      </app-icon>
    </div>
    <p class="support-info">{{ 'notifications.billing-card.bank-card-support' | translate}}</p>
  </div>
  <p class="support-link" (click)="openCrispChat()">{{ 'notifications.billing-card.support' | translate}}</p>
</div>

