import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MyClassesTutorsModule } from '@app/library/panes/my-classes-tutors/my-classes-tutors.component';
import { VocabularyCardComponent } from '@app/vocabulary/components/vocabulary-card/vocabulary-card.component';
import { SharedModule } from '@app/vocabulary/shared.module';
import { NoContentStubComponent } from '@core/components/no-content-stub/no-content-stub.component';
import { AccountService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { FindAccountStrategyEnum, IContentBlock, IFindAccountsFilter, User } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';

type UsersBlockType = IContentBlock<User, IFindAccountsFilter>;

@Component({
  selector: 'app-vocabulary-students',
  standalone: true,
  imports: [SharedModule, MyClassesTutorsModule, NoContentStubComponent, VocabularyCardComponent],
  templateUrl: './vocabulary-students.component.html',
  styleUrls: ['./vocabulary-students.component.scss'],
})
export class VocabularyStudentsComponent extends ChangableComponent implements OnInit {
  public users: UsersBlockType = {
    items: [],
    loading: false,
    loaded: false,
    filter: { use: FindAccountStrategyEnum.my_students },
    pagination: {
      page: 1,
      pageSize: 100,
      total: 0,
      totalPages: 0,
    },
  };
  public vocabularyModalOpen: boolean;
  public selectedUser: User;
  public userFullName: string;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    protected readonly cdr: ChangeDetectorRef,
    private accountService: AccountService,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.accountService
      .findAccounts(this.users.filter, this.users.pagination)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.users.items = res.results;
          this.users.loaded = true;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.users.loaded = true;
          this.detectChanges();
        },
      });
  }

  public openUserVocabulary(user: User) {
    this.selectedUser = user;
    this.userFullName = AccountService.getUserFullName(user as Partial<User>);
    this.vocabularyModalOpen = true;
  }

  public onCancel() {
    this.vocabularyModalOpen = false;
  }

  public trackByFn(index) {
    return index;
  }
}
