import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-promoblue',
  templateUrl: './promoblue.component.html',
  styleUrls: ['./promoblue.component.scss'],
})
export class PromoblueComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
