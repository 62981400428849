import { Location } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { RemoteConfigType } from '@app/core/services/remote-config/constants';
import { AnalyticsService, AuthModalModeType, BillingService, ContextService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { filter, takeUntil, take, of } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-auth-page',
  templateUrl: './auth-page.component.html',
})
export class AuthPageComponent extends ChangableComponent implements OnInit {
  private _mode: AuthModalModeType = 'social';
  private queryParamMap: ParamMap;
  private remoteConfig: RemoteConfigType;
  private postAuthEnabled = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private location: Location,
    private contextService: ContextService,
    private billingService: BillingService,
    private analytics: AnalyticsService,
    public errorNotificationService: ErrorNotificationService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    this.queryParamMap = this.route.snapshot.queryParamMap;
    this.mode = (this.route.snapshot.url.toString() || 'social') as AuthModalModeType;

    this.contextService.me$
      .pipe(
        filter(() => this.postAuthEnabled),
        take(1),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: () => this.postAuth(),
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-USER');
        }
      });
  }

  public getParam(key: string): string {
    return this.queryParamMap.get(key);
  }

  public get mode(): AuthModalModeType {
    return this._mode;
  }

  public set mode(mode: AuthModalModeType) {
    this._mode = mode;
    const url = this.router.createUrlTree(['/auth/' + this._mode]).toString();
    this.location.replaceState(url);
  }

  public onAuthSucceed() {
    console.log('AuthPageComponent onAuthSucceed');
    this.postAuthEnabled = true;
  }

  public onAuthCanceled() {
    console.log('AuthPageComponent onAuthCanceled');
    this.goMain();
  }

  // ****

  protected postAuth() {
    console.log('AuthPageComponent postAuth');
    switch (this.getParam('return')) {
      case 'meeting': {
        const session_id = this.getParam('session_id');
        if (!session_id) {
          this.onGoQuickMeeting();
        } else {
          this.goMeeting(session_id);
        }
        break;
      }

      case 'constructor': {
        const lesson_id = this.getParam('lesson_id');
        if (!lesson_id) {
          this.onCreateLesson();
        } else {
          this.goLessonConstructor(lesson_id);
        }
        break;
      }

      default:
        this.goMain();
        break;
    }
  }

  /** Создание быстрого митинга */
  protected onGoQuickMeeting() {
    const options = {
      caller: this.getParam('caller') || 'app-auth-page',
      reason: this.getParam('reason') || 'create quick meeting',
    };

    this.analytics.event(EventActionEnum.meeting_quick_creating, EventCategoryEnum.service, options.caller);

    if (this.remoteConfig?.quickMeeting__paywall) {
      // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
      this.billingService.showPaywall(() => this.goDraftMeeting(), options);
    } else {
      // сразу отправить на целевую страницу
      this.goDraftMeeting();
    }
  }

  protected goMeeting(session_id: string) {
    // TODO
  }

  protected goDraftMeeting() {
    this.router.navigateByUrl('/go/class');
  }

  // ****

  public onCreateLesson() {
    const options = {
      caller: this.getParam('caller') || 'app-auth-page',
      reason: this.getParam('reason') || 'create lesson',
    };

    this.analytics.event(EventActionEnum.lesson_creating, EventCategoryEnum.content, options.caller);

    if (this.remoteConfig?.createLesson__paywall) {
      // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
      this.billingService.showPaywall(() => this.goConstructor(), options);
    } else {
      // сразу отправить на целевую страницу
      this.goConstructor();
    }
  }

  protected goConstructor() {
    void this.router.navigate(['/constructor']);
  }

  protected goLessonConstructor(lesson_id: string) {}

  // ****

  protected goMain() {
    this.router.navigate(['/']).then(() => {});
  }
}
