import { Component, OnInit } from '@angular/core';
import { EContentPanelRoute } from 'lingo2-models';

export interface ItemList {
  image_class: string;
  title: string;
  text: string;
  linktext: string;
  link: string;
}

@Component({
  selector: 'app-onclass-it',
  templateUrl: './onclass-it.component.html',
  styleUrls: ['./onclass-it.component.scss'],
})
export class OnclassItComponent implements OnInit {
  public itemList: ItemList[];

  constructor() {}

  ngOnInit(): void {
    this.itemList = [
      {
        image_class: 'onclass-it1',
        title: 'landings.home.onclass-it-slide1-title',
        text: 'landings.home.onclass-it-slide1-text',
        linktext: 'landings.home.onclass-it-slide1-link',
        link: EContentPanelRoute.tutors,
      },
      {
        image_class: 'onclass-it2',
        title: 'landings.home.onclass-it-slide2-title',
        text: 'landings.home.onclass-it-slide2-text',
        linktext: 'landings.home.onclass-it-slide2-link',
        link: EContentPanelRoute.lessons,
      },
      {
        image_class: 'onclass-it3',
        title: 'landings.home.onclass-it-slide3-title',
        text: 'landings.home.onclass-it-slide3-text',
        linktext: 'landings.home.onclass-it-slide3-link',
        link: EContentPanelRoute.classes,
      },
    ];
  }

  public trackByFn(index) {
    return index;
  }
}
