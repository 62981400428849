<section class="opportunities">
  <div class="opportunities-container">
    <div class="opportunities-title">
      {{'job-landing.opportunities.title' | translate}}
    </div>


    <div class="diagrams-wrap">
      <div class="diagram-item people">
        <div class="diagram-title">{{'job-landing.opportunities.step1title' | translate}}</div>
        <div class="diagram-text">{{'job-landing.opportunities.step1text' | translate}}</div>
        <div class="diagram-bg">
          <div class="diagram-icon"></div>
        </div>
      </div>
      <div class="diagram-item man">
        <div class="diagram-title">{{'job-landing.opportunities.step2title' | translate}}</div>
        <div class="diagram-text">{{'job-landing.opportunities.step2text' | translate}}</div>
        <div class="diagram-bg">
          <div class="diagram-icon"></div>
        </div>
      </div>
      <div class="diagram-item study">
        <div class="diagram-title">{{'job-landing.opportunities.step3title' | translate}}</div>
        <div class="diagram-text">{{'job-landing.opportunities.step3text' | translate}}</div>
        <div class="diagram-bg">
          <div class="diagram-icon"></div>
        </div>
      </div>
      <div class="diagram-item camera">
        <div class="diagram-title">{{'job-landing.opportunities.step4title' | translate}}</div>
        <div class="diagram-text">{{'job-landing.opportunities.step4text' | translate}}</div>
        <div class="diagram-bg">
          <div class="diagram-icon"></div>
        </div>
      </div>
      <div class="diagram-item briefcase">
        <div class="diagram-title">{{'job-landing.opportunities.step5title' | translate}}</div>
        <div class="diagram-text">{{'job-landing.opportunities.step5text' | translate}}</div>
        <div class="diagram-bg">
          <div class="diagram-icon"></div>
        </div>
      </div>
    </div>

    <div class="steps-wrap">
      <div class="step-item">
        <div class="step-number">1</div>
        <div class="step-text">{{'job-landing.opportunities.opporttext1' | translate}}</div>
      </div>
      <div class="step-item">
        <div class="step-number">2</div>
        <div class="step-text">{{'job-landing.opportunities.opporttext2' | translate}}</div>
      </div>
      <div class="step-item">
        <div class="step-number">3</div>
        <div class="step-text">{{'job-landing.opportunities.opporttext3' | translate}}</div>
      </div>
    </div>
  </div>
</section>
