<div class="header-action">
  <span
    class="action-label normal"
    (click)="onCreateSchoolService()">
    {{ 'school-steps.step3.action' | translate }}
  </span>
</div>

<div class="filter-block">
  <div class="filter-scroll">
    <ul class="filter-list">
      <li *ngFor="let tab of tabs; let i = index; trackBy: trackByFn"
          class="filter-item"
          [class.selected]="tab.code === activeTab?.code"
          (click)="activateTab(tab)">
        <span class="filter-label">{{ tab.title | translate }}</span>
      </li>
    </ul>
  </div>
</div>

<ng-template [ngIf]="!services.items.length">
  <div class="widget-block">
    <app-icon
      [width]="271"
      [height]="80"
      [icon]="'school-steps-placeholder-services'">
    </app-icon>
  </div>
</ng-template>

<ng-template [ngIf]="services.items.length">
  <div class="services-block">
    <div class="services">
      <ng-template [ngIf]="services.loading">
        <div class="user-service-wrap">
          <loading-line height="76px" width="100%"></loading-line>
        </div>
      </ng-template>

      <div *ngFor="let userService of this.services.items; trackBy: trackUserServiceByFn"
           class="user-service-wrap">
        <app-library-service-mini
          [service]="userService"
          [isSchoolClasses]="true"
          (changed)="onChangeUserService()"
          (selected)="onSelect(userService)"
          (openUserServiceInModal)="onToggleUserServiceInModal($event)">
        </app-library-service-mini>
      </div>
    </div>
  </div>
</ng-template>

<app-modal *ngIf="newService"
           [canClose]="false"
           (closed)="onCancelSchoolService()">
  <app-modal-body>
    <app-user-service-form-3 [userService]="newService" (changed)="onChangeUserService()"></app-user-service-form-3>
    <pre>{{ newService.teachers | json }}</pre>
  </app-modal-body>
</app-modal>

<app-modal *ngIf="editService"
           [canClose]="false"
           (closed)="onCancelSchoolService()">
  <app-modal-body>
    <app-user-service-form-3 [userService]="editService" (changed)="onChangeUserService()"></app-user-service-form-3>
  </app-modal-body>
</app-modal>

<app-modal *ngIf="deviceService.isDesktop() && userServiceCardFullInModal"
           [canClose]="false"
           (closed)="onToggleUserServiceInModal()">
  <app-modal-body>
    <app-user-service-card-full [userService]="openedUserService"
                                [modal]="true"
                                (inViewportChange)="onIinViewportChange($event)">
    </app-user-service-card-full>
  </app-modal-body>
  <app-modal-ad>
    <ng-template [ngIf]="!surfaceBlockDisplay">
      <app-user-service-surface-block [userService]="openedUserService"></app-user-service-surface-block>
    </ng-template>
  </app-modal-ad>
</app-modal>

<app-mobile-modal *ngIf="!deviceService.isDesktop() && userServiceCardFullInModal"
                  [titleVisible]="true"
                  (closed)="onToggleUserServiceInModal()">
  <app-mobile-modal-body>
    <app-user-service-card-full [userService]="openedUserService" [modal]="true"></app-user-service-card-full>
  </app-mobile-modal-body>
</app-mobile-modal>
