import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, Injectable, NgModule } from '@angular/core';
import { MatNativeDateModule, MatDateFormats, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AuthModalModule } from '@core/components/auth-modal/auth-modal.module';
import { CookieNotifierModule } from '@core/components/cookie-notifier/cookie-notifier.module';
import { DebugDrawerModule } from '@core/components/debug-drawer/debug-drawer.component';
import { SeoModule } from '@core/components/seo/seo.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { AuthGuard, GuestGuard, BrowserGuard, TenantOverrideGuard } from '@core/guards';
import { httpInterceptorProviders } from '@core/interceptors';
import {
  LanguageService,
  AccountService,
  ProfileService,
  BillingService,
  ConfigService,
  MeetingsService,
  ContextService,
  DropzoneConfig,
  FilesService,
  FeaturesService,
  PlatformService,
  ClassroomsService,
  AuthService,
} from '@core/services';
import { CurrenciesService } from '@core/services/currencies.service';
import { HttpLoaderFactory } from '@core/services/i18n';
import { BillingV2Service } from '@core/services/lingo2-account/billing-v2.service';
import { remoteConfigModules } from '@core/services/remote-config/modules';
import { remoteConfigProviders } from '@core/services/remote-config/providers';
import { RemoteConfigService } from '@core/services/remote-config/remote-config.service';
import { WebsocketService } from '@core/websocket';
import { NearestMeetWidgetModule } from '@core/widgets';
import { environment } from '@env/environment';
import { MetrikaModule } from '@le2xx/ng-yandex-metrika';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { ApplicationDialogsModule } from '@shared/application-dialogs/application-dialogs.component';
import { ErrorNotificationComponent } from '@shared/error-notification/error-notification.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import {
  Lingo2FormsModule,
  AbstractFilesService,
  AbstractScreenService,
  AbstractAccountService,
  AbstractBillingService,
  AbstractFeaturesService,
  AbstractMeetingsService,
  AbstractClassroomsService,
  AbstractDropzoneConfig,
  AbstractUserService,
  AbstractLanguageService,
  AbstractConfigService,
  AbstractProfileService,
  AbstractWebsocketService,
  AbstractBillingV2Service,
  AbstractAuthService,
  AbstractCurrenciesService,
} from 'lingo2-forms';
import { NgEventBus } from 'ng-event-bus';
import { InViewportModule } from 'ng-in-viewport';
import { CookieModule } from 'ngx-cookie';
import { OnUiSpriteModule } from 'onclass-ui';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { RequestCache, RequestCacheWithMap, ScreenService } from './core/services';
import { ModalService } from './core/services/modal.services';
import { ROOT_REDUCERS, metaReducers } from './store';
import { UserEffects, ProfileEffects, ContentEffects, FeaturingEffects, DataEffects } from './store/effects';
import { DateFnsModule } from 'lingo2-ngx-date-fns';
import { TranslationCacheService } from '@core/services/translation-cache.service';

const APP_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: { day: 'numeric', month: 'numeric', year: 'numeric' },
  },
  display: {
    dateInput: { day: 'numeric', month: 'long', year: 'numeric' },
    monthYearLabel: { year: 'numeric', month: 'short' },
    dateA11yLabel: { year: 'numeric', month: 'long', day: 'numeric' },
    monthYearA11yLabel: { year: 'numeric', month: 'long' },
  },
};

export function SameServiceFactory(service: Injectable) {
  return service;
}

export function loadTranslations(translate: TranslateService) {
  return () => translate.use('en').toPromise();
}

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule.withServerTransition({ appId: 'lingo2-web' }),
    CookieModule.forRoot(),
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient, PlatformService, TranslationCacheService],
      },
    }),
    DateFnsModule.forRoot(),
    InViewportModule,
    SpinnersModule,
    StoreModule.forRoot(ROOT_REDUCERS, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: false,
        strictActionImmutability: false,
      },
    }),
    StoreDevtoolsModule.instrument({
      maxAge: 20,
      logOnly: environment.production,
    }),
    EffectsModule.forRoot([UserEffects, ProfileEffects, ContentEffects, FeaturingEffects, DataEffects]),
    AppRoutingModule,
    MetrikaModule.forRoot([
      {
        id: environment.metrika_id,
        clickmap: true,
        trackLinks: true,
        accurateTrackBounce: true,
        webvisor: environment.metrika_webvisor,
      },
    ]),
    AuthModalModule,
    MatNativeDateModule,
    OverlayModule,
    SeoModule,
    Lingo2FormsModule.forRoot({
      accountService: { provide: AbstractAccountService, useFactory: SameServiceFactory, deps: [AccountService] },
      billingService: { provide: AbstractBillingService, useFactory: SameServiceFactory, deps: [BillingService] },
      billingV2Service: { provide: AbstractBillingV2Service, useFactory: SameServiceFactory, deps: [BillingV2Service] },
      currencyService: {
        provide: AbstractCurrenciesService,
        useFactory: SameServiceFactory,
        deps: [CurrenciesService],
      },
      classroomsService: {
        provide: AbstractClassroomsService,
        useFactory: SameServiceFactory,
        deps: [ClassroomsService],
      },
      userService: { provide: AbstractUserService, useFactory: SameServiceFactory, deps: [ContextService] },
      featuresService: {
        provide: AbstractFeaturesService,
        useFactory: SameServiceFactory,
        deps: [FeaturesService],
      },
      authService: { provide: AbstractAuthService, useFactory: SameServiceFactory, deps: [AuthService] },
      filesService: { provide: AbstractFilesService, useFactory: SameServiceFactory, deps: [FilesService] },
      meetingsService: {
        provide: AbstractMeetingsService,
        useFactory: SameServiceFactory,
        deps: [MeetingsService],
      },
      screenService: { provide: AbstractScreenService, useFactory: SameServiceFactory, deps: [ScreenService] },
      dropzoneConfig: { provide: AbstractDropzoneConfig, useFactory: SameServiceFactory, deps: [DropzoneConfig] },
      languageService: {
        provide: AbstractLanguageService,
        useFactory: SameServiceFactory,
        deps: [LanguageService],
      },
      configService: { provide: AbstractConfigService, useFactory: SameServiceFactory, deps: [ConfigService] },
      profileService: { provide: AbstractProfileService, useFactory: SameServiceFactory, deps: [ProfileService] },
      websocketService: {
        provide: AbstractWebsocketService,
        useFactory: SameServiceFactory,
        deps: [WebsocketService],
      },
    }),
    BrowserAnimationsModule,
    CookieNotifierModule,
    NearestMeetWidgetModule,
    OnUiSpriteModule,
    ApplicationDialogsModule,
    DebugDrawerModule,
    ErrorNotificationComponent,
    ...remoteConfigModules,
  ],
  providers: [
    AuthGuard,
    GuestGuard,
    NgEventBus,
    BrowserGuard,
    TenantOverrideGuard,
    ErrorNotificationService,
    ModalService,
    { provide: MAT_DATE_LOCALE, useValue: 'en-GB' },
    { provide: MAT_DATE_FORMATS, useValue: APP_DATE_FORMATS },
    { provide: RequestCache, useClass: RequestCacheWithMap },
    {
      provide: APP_INITIALIZER,
      useFactory: loadTranslations,
      deps: [TranslateService],
      multi: true,
    },
    ...httpInterceptorProviders,
    ...remoteConfigProviders,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(private remoteConfigService: RemoteConfigService) {
    void remoteConfigService.init();
  }
}
