<section class="onclass-it">
  <div class="container">
    <div class="title">
      {{'landings.home.onclass-it-title' | translate}}
    </div>
    <div class="content-wrap">
      <div class="content-item item{{i + 1}}" *ngFor="let item of itemList; let i = index; trackBy: trackByFn">
        <div class="image {{item.image_class}}"></div>
        <div class="content">
          <div class="subtitle" [innerHTML]="item.title | translate">
          </div>
          <div class="text" [innerHTML]="item.text | translate">
          </div>
          <div class="link">
            <a [routerLink]="item.link">{{item.linktext | translate}}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
