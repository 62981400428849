<div class="actions-surface-block" [class.mobile]="deviceService.isMobile()">
  <div class="left" *ngIf="!deviceService.isMobile()">
    <div class="cover-wrap" *ngIf="item?.image_id">
      <on-ui-cover [cover]="cover"></on-ui-cover>
    </div>
    <div class="info">
    <p class="title">{{item?.title ? item.title : 'my-vocabulary.page-header-title' | translate}}</p>
    <p class="count">{{'my-vocabulary.selected-words' | translate}}: {{count}}</p>
    </div>
  </div>
  <div class="right">
    <ng-container *ngIf="withFullActions; else otherActions">
      <p class="link" (click)="onAction('select-all')">{{(allSelected ? 'my-vocabulary.action.clear-all' : 'my-vocabulary.action.select-all') | translate}}</p>
      <p class="link delete" (click)="onAction('delete')">{{'my-vocabulary.action.delete' | translate}}</p>
      <on-ui-button *ngIf="!deviceService.isMobile()"
                    class="primary outline"
                    (click)="onAction('move')"
                    [label]="'my-vocabulary.action.move' | translate">
      </on-ui-button>
      <div class="move-action" *ngIf="deviceService.isMobile()" (click)="onAction('move')">
        <app-icon width="18" height="12" file="arrow" icon="right"></app-icon>
      </div>
    </ng-container>
    <ng-template #otherActions>
      <on-ui-button class="primary outline"
                    (click)="onAction('add-word')"
                    [label]="'my-vocabulary.action.add-word-to-list' | translate">
      </on-ui-button>
    </ng-template>
  </div>
</div>
