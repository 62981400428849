import { DOCUMENT } from '@angular/common';
import { ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { remoteConfigDefaults, RemoteConfigType } from '@app/core/services/remote-config/constants';
import { RemoteConfigService } from '@app/core/services/remote-config/remote-config.service';
import { ChangableComponent } from '@app/models/changable.component';
import { takeUntil } from 'rxjs/operators';
import {
  AnalyticsService,
  AuthService,
  BillingService,
  ContextService,
  MeetingsService,
  PlatformService,
} from '../../core/services';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-meeting-fast-start-page',
  templateUrl: './meeting-fast-start-page.component.html',
  styleUrls: ['./meeting-fast-start-page.component.scss'],
})
export class MeetingFastStartPageComponent extends ChangableComponent implements OnInit {
  public creating = true;
  public error = '';
  public conferenceUrl: string;
  public openingFreezed = false;
  private remoteConfig: RemoteConfigType;
  private debug: boolean;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private authService: AuthService,
    private billingService: BillingService,
    private contextService: ContextService,
    private meetingsService: MeetingsService,
    private router: Router,
    private remoteConfigService: RemoteConfigService,
    @Inject(DOCUMENT) private document: any,
    protected analytics: AnalyticsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.onBrowserOnly(() => {
      this.remoteConfigService.config$.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (config) => {
          this.remoteConfig = config;
          this.onQuickMeeting();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.remoteConfig = remoteConfigDefaults;
          this.onQuickMeeting();
        }
      });
      this.contextService.debug$.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (debug) => (this.debug = debug),
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });
    });
  }

  /** Создание быстрого митинга */
  public onQuickMeeting() {
    const options = {
      caller: 'app-meeting-fast-start-page',
      reason: 'create quick meeting',
    };

    this.analytics.event(EventActionEnum.meeting_quick_creating, EventCategoryEnum.service, options.caller);

    const _continue = () => {
      if (this.remoteConfig?.quickMeeting__paywall) {
        // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
        this.billingService.showPaywall(() => this.createMeetingQuickSession(), options);
      } else {
        // сразу отправить на целевую страницу
        this.createMeetingQuickSession();
      }
    };

    if (this.remoteConfig?.quickMeeting__auth) {
      // показать диалог авторизации, а после авторизации продолжить
      this.authService.showAuthModal(() => _continue(), options);
    } else {
      // продолжить даже если не авторизован
      _continue();
    }
  }

  private createMeetingQuickSession() {
    this.meetingsService
      .createMeetingQuickSession({})
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (session) => {
          if (session && session.participant && session.conference_url) {
            this.analytics.event(EventActionEnum.meeting_quick_created, EventCategoryEnum.service);
            this.setTimeout(() => {
              // таймаут для передачи аналитики
              if (this.debug) {
                session.conference_url = session.conference_url + '&debug=true';
              }
              this.conferenceUrl = session.conference_url;
              this.openConference();
            }, 250);
          } else {
            this.error = 'No session or access denied';
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.error = error.message || error.toString();
        }
      });
  }

  /** Открыть класс */
  private openConference() {
    this.analytics.event(EventActionEnum.conference_opening, EventCategoryEnum.service);
    this.setTimeout(() => {
      // таймаут для передачи аналитики
      if (!this.debug) {
        this.document.location.href = this.conferenceUrl;
      }

      this.setTimeout(() => {
        // показать большую красивую кнопку с надписью 'Перейти в конференцию ->'
        this.openingFreezed = true;
      }, 2000);
    }, 250);
  }
}
