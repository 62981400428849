import { Location } from '@angular/common';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { AccountService, ContextService, AnalyticsService, PlatformService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { takeUntil } from 'rxjs/operators';
import { BecomeTeacherEvent } from './models';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-job-page',
  templateUrl: './job-page.component.html',
  styleUrls: ['./job-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class JobPageComponent extends DestroyableComponent implements OnInit {
  private onAfterMeUpdated: () => void;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private accountService: AccountService,
    private contextService: ContextService,
    private analytics: AnalyticsService,
    private router: Router,
    private location: Location,
    protected readonly platform: PlatformService,
  ) {
    super(platform);
  }

  ngOnInit(): void {
    this.analytics.event(EventActionEnum.view_landing, EventCategoryEnum.engagement, [
      'app-job-page',
      this.location.path(true),
    ]);

    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (me) => {
        if (this.onAfterMeUpdated) {
          this.onAfterMeUpdated();
        }
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        if (this.onAfterMeUpdated) {
          this.onAfterMeUpdated();
        }
      },
    });
  }

  public onBecomeTeacher(e: BecomeTeacherEvent) {
    const _route = () => void this.router.navigate(e.route).then(() => true);

    if (e.markAsTeacher) {
      // пометить меня как 'хочу стать преподом'
      this.analytics.event(EventActionEnum.become_teacher, EventCategoryEnum.engagement, ['app-job-page', e.block]);
      this.accountService
        .markAsTeacher()
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (account) => {
            // logger.debug('JobPageComponent::onBecomeTeacher() -> this.accountService.markAsTeacher()');

            this.contextService.updateMe(account); // обновить мой аккаунт и потом выполнить this.onAfterMeUpdated

            _route();
          },
          error: (error: any) => {
            this.errorNotificationService.captureError(error, 'PROFILE-PROBLEM');
            _route();
          },
        });
    } else {
      // пометить меня как 'хочу стать учеником'
      this.analytics.event(EventActionEnum.become_student, EventCategoryEnum.engagement, ['app-job-page', e.block]);
      this.accountService
        .markAsStudent()
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (account) => {
            this.contextService.updateMe(account); // обновить мой аккаунт и потом выполнить this.onAfterMeUpdated
            _route();
          },
          error: (error: any) => {
            this.errorNotificationService.captureError(error, 'PROFILE-PROBLEM');
            _route();
          },
        });
    }
    return false;
  }
}
