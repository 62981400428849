import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-teach-with-onclass',
  templateUrl: './teach-with-onclass.component.html',
  styleUrls: ['./teach-with-onclass.component.scss'],
})
export class TeachWithOnclassComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
