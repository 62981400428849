import { ChangeDetectionStrategy, Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { ContentService } from '@core/services';
import { ContentTypeEnum } from 'lingo2-models';

interface IContentType {
  contentType: ContentTypeEnum;
  title: string;
}

const contentTypes: IContentType[] = [
  // {
  //   contentType: ContentTypeEnum.lesson,
  //   title: ContentService.contentTypeTitle[ContentTypeEnum.lesson],
  // },
  {
    contentType: ContentTypeEnum.audio_lesson,
    title: ContentService.contentTypeTitle[ContentTypeEnum.audio_lesson],
  },
  {
    contentType: ContentTypeEnum.video_lesson,
    title: ContentService.contentTypeTitle[ContentTypeEnum.video_lesson],
  },
  {
    contentType: ContentTypeEnum.reading_lesson,
    title: ContentService.contentTypeTitle[ContentTypeEnum.reading_lesson],
  },
  {
    contentType: ContentTypeEnum.theory,
    title: ContentService.contentTypeTitle[ContentTypeEnum.theory],
  },
  {
    contentType: ContentTypeEnum.theory_exercise,
    title: ContentService.contentTypeTitle[ContentTypeEnum.theory_exercise],
  },
  {
    contentType: ContentTypeEnum.exercise,
    title: ContentService.contentTypeTitle[ContentTypeEnum.exercise],
  },
  // {
  //   contentType: ContentTypeEnum.test,
  //   title: ContentService.contentTypeTitle[ContentTypeEnum.test],
  // },
  {
    contentType: ContentTypeEnum.vocabulary,
    title: ContentService.contentTypeTitle[ContentTypeEnum.vocabulary],
  },
  {
    contentType: ContentTypeEnum.video,
    title: ContentService.contentTypeTitle[ContentTypeEnum.video],
  },
  {
    contentType: ContentTypeEnum.audio,
    title: ContentService.contentTypeTitle[ContentTypeEnum.audio],
  },
  {
    contentType: ContentTypeEnum.image,
    title: ContentService.contentTypeTitle[ContentTypeEnum.image],
  },
  // {
  //   contentType: ContentTypeEnum.document,
  //   title: ContentService.contentTypeTitle[ContentTypeEnum.document],
  // }
  {
    contentType: ContentTypeEnum.article,
    title: ContentService.contentTypeTitle[ContentTypeEnum.article],
  },
  {
    contentType: ContentTypeEnum.news,
    title: ContentService.contentTypeTitle[ContentTypeEnum.news],
  },
];

@Component({
  selector: 'app-content-type-filter',
  templateUrl: './content-type-filter.component.html',
  styleUrls: ['./content-type-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentTypeFilterComponent implements OnInit {
  @Input() isExpanded = true;
  @Input() set checked(checked: number[]) {
    this.checkedContentTypes = [...checked];
  }

  @Output() changed = new EventEmitter<number[]>();
  @Output() expanded = new EventEmitter<boolean>();

  private checkedContentTypes: number[] = [];

  public contentTypes = contentTypes;

  constructor() {}

  ngOnInit() {}

  public toggleExpand() {
    this.isExpanded = !this.isExpanded;
    this.expanded.emit(this.isExpanded);
  }

  public isCheckedContentType(contentType): boolean {
    return this.checkedContentTypes.includes(contentType);
  }

  public toggleContentType(contentType) {
    if (this.checkedContentTypes.includes(contentType)) {
      this.checkedContentTypes = this.checkedContentTypes.filter((type) => type !== contentType);
    } else {
      this.checkedContentTypes.push(contentType);
    }
    this.changed.emit(this.checkedContentTypes);
  }

  public isCheckedAllContentTypes(): boolean {
    return this.checkedContentTypes.length === this.contentTypes.length;
  }

  public toggleAllContentTypes() {
    if (this.isCheckedAllContentTypes()) {
      this.checkedContentTypes = [];
    } else {
      this.checkedContentTypes = this.contentTypes.map((i) => i.contentType);
    }
    this.changed.emit(this.checkedContentTypes);
  }

  public trackByFn(item: IContentType): number {
    return item.contentType;
  }
}
