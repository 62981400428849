import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { remoteConfigDefaults, RemoteConfigType } from '@app/core/services/remote-config/constants';
import { RemoteConfigService } from '@app/core/services/remote-config/remote-config.service';
import { IControlOption, PaginatorModule } from '@app/shared';
import { NoContentStubComponent } from '@core/components/no-content-stub/no-content-stub.component';
import { AuthService, BillingService, ConfigService, LanguageService, VocabularyService } from '@core/services';
import { IContentBlock } from '@core/services/interfaces';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { IFindVocabularyList, Subject, VocabularyList } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiCardMenuModule } from 'onclass-ui';
import { takeUntil } from 'rxjs/operators';
import { VocabularyCardComponent } from '../../components/vocabulary-card/vocabulary-card.component';
import { VocabularyContentComponent } from '../../components/vocabulary-content/vocabulary-content.component';
import { VocabularySurfaceComponent } from '../../components/vocabulary-surface/vocabulary-surface.component';
import { SharedModule } from '../../shared.module';
import { ListCardDetailsComponent } from './card-details/card-details.component';
import { ActionTypes, ListCardMiniComponent } from './card-mini/card-mini.component';
import { ListFormComponent } from './list-form/list-form.component';

@Component({
  selector: 'app-vocabulary-lists',
  standalone: true,
  imports: [
    SharedModule,
    ReactiveFormsModule,
    ListCardMiniComponent,
    ListFormComponent,
    OnUiCardMenuModule,
    VocabularyCardComponent,
    VocabularySurfaceComponent,
    ListCardDetailsComponent,
    NoContentStubComponent,
    PaginatorModule,
  ],
  templateUrl: './vocabulary-lists.component.html',
  styleUrls: ['./vocabulary-lists.component.scss'],
})
export class VocabularyListsComponent extends ChangableComponent implements OnInit {
  public listFormModalOpened = false;
  public listModalOpened: boolean;
  public showSurface: boolean;
  public isListSelected: boolean;
  public selectedList: VocabularyList;
  public itemsForMoving: string[];
  public form: UntypedFormGroup;
  public lists: IContentBlock<VocabularyList, IFindVocabularyList> = {
    loading: false,
    loaded: false,
    items: [],
    filter: {},
    pagination: {
      page: 1,
      total: 0,
      totalPages: 0,
      pageSize: 5,
    },
  };
  private remoteConfig: RemoteConfigType;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private authService: AuthService,
    private billingService: BillingService,
    private vocabularyService: VocabularyService,
    private remoteConfigService: RemoteConfigService,
    protected readonly cdr: ChangeDetectorRef,
    protected fb: UntypedFormBuilder,
  ) {
    super(cdr);
  }

  public ngOnInit(): void {
    this.loadLists();

    this.remoteConfigService.config$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (config) => {
        this.remoteConfig = config;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.remoteConfig = remoteConfigDefaults;
      },
    });
  }

  public loadLists(): void {
    this.vocabularyService
      .findVocabularyLists({}, this.lists.pagination)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (result) => {
          this.lists.items = result.results;
          this.lists.pagination = result.pagination;
          this.lists.loaded = true;
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.lists.loaded = false;
        },
      });
  }

  public loadPage(e: number) {
    this.lists.pagination.page = e;
    this.loadLists();
  }

  public onChange(e: ActionTypes, item: VocabularyList) {
    this.selectedList = item;
    switch (e) {
      case 'edit':
        this.openModal(e);
        break;
      case 'add-word':
        this.openList();
        break;
      case 'create-lesson':
        this.getVocabularies();
        break;
      case 'delete':
        this.deleteList();
        break;
      case 'duplicate':
        this.duplicateList();
        break;
    }
  }

  public onCreateList() {
    const options = {
      caller: 'app-vocabulary-lists',
      reason: 'create vocabulary list',
    };

    // TODO this.analytics.event(EventActionEnum.vocabulary_creating, EventCategoryEnum.content, options.caller);

    const _continue = () => {
      if (this.remoteConfig?.createVocabulary__paywall) {
        // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
        this.billingService.showPaywall(() => this.openModal('add'), options);
      } else {
        // сразу отправить на целевую страницу
        this.openModal('add');
      }
    };

    if (this.remoteConfig?.createVocabulary__auth) {
      // показать диалог авторизации, а после авторизации продолжить
      if (!this.authService.isAuthenticated) {
        this.authService.showAuthModal(() => {
          this.loadLists();
          _continue();
        }, options);
      } else {
        _continue();
      }
    } else {
      // продолжить даже если не авторизован
      _continue();
    }
  }

  public openModal(e) {
    if (e === 'add') {
      this.selectedList = null;
    }
    this.listFormModalOpened = true;
    this.detectChanges();
  }

  public openList() {
    this.listModalOpened = true;
    this.detectChanges();
  }

  public onItemsSelect(e) {
    this.itemsForMoving = e.map((el) => el.id);
    this.showSurface = e.length;
  }

  public setActiveItem(e) {
    this.selectedList = e;
  }

  public onListSelect(e: VocabularyList) {
    this.isListSelected = true;
    this.selectedList = e;
  }

  public onListSave() {
    this.listFormModalOpened = false;
    this.selectedList = null;
    this.loadLists();
  }

  public onCancel() {
    this.listFormModalOpened = false;
    this.listModalOpened = false;
    this.selectedList = null;
  }

  public goBack() {
    this.isListSelected = false;
    this.selectedList = null;
    this.loadLists();
  }

  public getVocabularies() {}

  public deleteList() {
    this.vocabularyService
      .deleteVocabularyList(this.selectedList.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.loadLists();
          this.selectedList = null;
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.loadLists();
          this.selectedList = null;
        },
      });
  }

  public duplicateList() {
    this.vocabularyService
      .duplicateVocabularyList(this.selectedList.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.loadLists();
          this.selectedList = null;
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.loadLists();
          this.selectedList = null;
        },
      });
  }

  public onAction(action: ActionTypes) {
    const arrayOfTerms = this.itemsForMoving.map((item) => ({
      id: item,
      vocabulary_list_id: this.selectedList.id,
    }));

    this.vocabularyService
      .bulkUpdateVocabularyTerms(arrayOfTerms)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.loadLists();
          this.onCancel();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.loadLists();
          this.onCancel();
        },
      });
  }

  public trackByFn(index) {
    return index;
  }
}
