import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { loadMe } from '@app/store/actions/profile.actions';
import { ConfigService, FilesService, VocabularyService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadSubjectsV2 } from '@store/actions/data.actions';
import { getMe } from '@store/reducers/profile.reducer';
import { ImageSizeEnum, Subject, User, VocabularyList } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiCardMenuModule, OnUiCover, OnUiCoverModule } from 'onclass-ui';
import { takeUntil } from 'rxjs/operators';

export type ActionTypes = 'add-word' | 'edit' | 'delete' | 'duplicate' | 'create-lesson' | 'move' | 'select-all';

@Component({
  selector: 'app-vocabulary-list-card-mini',
  standalone: true,
  imports: [CommonModule, OnUiCoverModule, OnUiCardMenuModule, TranslateModule],
  templateUrl: './card-mini.component.html',
  styleUrls: ['./card-mini.component.scss', '../../../components/vocabulary-filter/vocabulary-filter.component.scss'],
})
export class ListCardMiniComponent extends ChangableComponent implements OnInit {
  @Input() item: VocabularyList;
  @Input() selectedList: VocabularyList;
  @Input() withActions: boolean;
  @Output() action = new EventEmitter();
  @Output() open = new EventEmitter<boolean>();

  public vocabularies: Array<Partial<VocabularyList>>;
  public languagesList: Subject[];
  public popup_open = false;
  public me: User;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private vocabularyService: VocabularyService,
    private configService: ConfigService,
    private store: Store,
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr);
  }

  onAction(type: ActionTypes) {
    if (type === 'create-lesson') {
      this.loadVocabularies();
    } else {
      this.action.emit(type);
    }
  }

  openDetails() {
    this.open.emit(true);
  }

  ngOnInit(): void {
    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (me) => {
          this.me = me;
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadMe());
        },
      });

    this.configService.subjectsV2$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (res) => {
        this.languagesList = res.foreign;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.store.dispatch(loadSubjectsV2({ locale: this.me.ui_language }));
      },
    });
  }

  public cover(): OnUiCover {
    return {
      img: FilesService.getFileUrlBySize(this.item?.image_id, ImageSizeEnum.sm),
      form: 'base',
      type: 'cover',
    };
  }

  public loadVocabularies() {
    this.vocabularyService
      .getVocabularies({ vocabulary_list_id: [this.item.id] })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.vocabularies = res;
          this.popup_open = true;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.popup_open = false;
        },
      });
  }

  public getLanguageName(id: number) {
    return this.languagesList.find((el) => el.language_id === id).title;
  }

  public navigateToConstructor(e: Partial<VocabularyList>) {}

  public trackByFn(index) {
    return index;
  }
}
