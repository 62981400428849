import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AccountService, FilesService, PlatformService, SchoolsService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import {
  CSchool,
  ESchoolParticipantRole,
  FindAccountStrategyEnum,
  IContentBlock,
  IFindAccountsFilter,
  ImageSizeEnum,
  User,
} from 'lingo2-models';
import { OnUiCover } from 'onclass-ui';
import { takeUntil } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

export type UsersBlockType = IContentBlock<User, IFindAccountsFilter>;

@Component({
  selector: 'app-school-participants-widgets',
  templateUrl: './school-participants-widgets.component.html',
  styleUrls: ['./school-participants-widgets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolParticipantsWidgetsComponent extends ChangableComponent implements OnInit {
  @Input() set school(school: CSchool) {
    this._school = school;
    if (school) {
      this.init();
    }
  }

  public get school() {
    return this._school;
  }

  public schoolUrl: string;
  public copied = false;
  private _onCopied: any;
  private _school: CSchool;
  public users: UsersBlockType = {
    items: [],
    loading: false,
    loaded: false,
    filter: {
      use: FindAccountStrategyEnum.school,
      school_roles: [ESchoolParticipantRole.student],
    },
    pagination: {
      page: 1,
      pageSize: 20,
    },
  };

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private accountService: AccountService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {}

  public init() {
    this.schoolUrl = SchoolsService.schoolRoute(this.school).join('/');
    this.users.filter.school_id = [this._school?.id];
    this.accountService
      .findAccounts(this.users.filter, this.users.pagination)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          this.users.items = data.results;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });
  }

  public cover(user: User): OnUiCover {
    return {
      img: FilesService.getFileUrlBySize(user?.userpic_id, ImageSizeEnum.sm),
      title: AccountService.getUserFullName(user),
      form: 'circle',
      type: 'avatar',
    };
  }

  public fullName(user: User): string {
    return AccountService.getUserFullName(user);
  }

  public schoolParticipantTrackBy(user: User): string {
    return user.id;
  }

  public onCopyLink() {
    this.copied = true;
    this.detectChanges();

    this.clearTimeout(this._onCopied);
    this._onCopied = this.setTimeout(() => {
      this.copied = false;
      this.detectChanges();
    }, 2000);
  }
}
