import { transition, trigger, useAnimation } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Router, RouterModule } from '@angular/router';
import { slideDown } from '@core/animations';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { AccountService, CommentService, MeetingsService, UserServicesService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { TranslateModule } from '@ngx-translate/core';
import { ComplaintMeetingFormComponent } from '@shared/complaint-meeting-form/complaint-meeting-form.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { IconComponent } from '@shared/icon/icon.component';
import { Meeting, otherSubjectId, ReviewEntryType, ReviewListItem, UserService } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonModule, OnUiButtonState, OnUiTextareaModule } from 'onclass-ui';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-meeting-review-widget',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    OnUiTextareaModule,
    OnUiButtonModule,
    TranslateModule,
    FormsModule,
    ModalModule,
    ComplaintMeetingFormComponent,
    MobileModalModule,
    IconComponent,
  ],
  templateUrl: './meeting-review-widget.component.html',
  styleUrls: ['./meeting-review-widget.component.scss'],
  animations: [trigger('expandableState', [transition(':enter', useAnimation(slideDown))])],
})
export class MeetingReviewWidgetComponent extends ChangableComponent implements OnInit {
  public authorName: string;
  public accountRoute: string;
  public meeting: Meeting | UserService;
  public meetingSubject: string;
  public review: ReviewListItem;
  public showForm: boolean;
  public complaintFormOpened: boolean;
  public submitState: OnUiButtonState = 'default';

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private accountService: AccountService,
    private meetingService: MeetingsService,
    private userServicesService: UserServicesService,
    private commentService: CommentService,
    public deviceService: DeviceDetectorService,
    private router: Router,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.loadLastReviewWithoutRating();
  }

  private loadLastReviewWithoutRating() {
    this.commentService
      .findLastReviewWithoutRating()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (review) => {
          this.review = review;
          if (review && this.ifSixHoursPast) {
            this.showForm = true;
            this.prepareCard();
          }
          this.detectChanges();
        },
        error: (error) => {
          this.showForm = true;
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  private prepareCard() {
    if (this.review.entryType === ReviewEntryType.meeting) {
      this.meetingService
        .getMeetingById(this.review?.entryId)
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe({
          next: (meeting) => {
            this.getMeetingInfo(meeting);
            this.detectChanges();
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          },
        });
    } else if (this.review.entryType === ReviewEntryType.userService) {
      this.userServicesService
        .getById(this.review.entryId, ['author', 'subject', 'subject_other_name'])
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe({
          next: (userService) => {
            this.getMeetingInfo(userService);
            this.detectChanges();
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          },
        });
    }
  }

  public getMeetingInfo(entry: Meeting | UserService) {
    this.meeting = entry;
    this.meetingSubject = this.meeting.subject
      ? this.meeting.subject.title
      : null;
    if (this.meeting.author) {
      this.authorName = AccountService.getUserFullName(this.meeting.author);
      this.accountRoute = AccountService.accountRoute(this.meeting.author).join('/');
    } else {
      this.loadAuthorDetails(this.meeting.author_id);
    }
  }

  private loadAuthorDetails(id: string) {
    if (!id) {
      return;
    }
    this.accountService
      .getUserById(id, 'LOAD_TIME')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (user) => {
          this.meeting.author = user;
          this.authorName = AccountService.getUserFullName(this.meeting.author);
          this.accountRoute = AccountService.accountRoute(this.meeting.author).join('/');
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public get ifSixHoursPast() {
    const SIX_HOUR = 6 * 60 * 60 * 1000;
    return new Date().getTime() - new Date(this.review.updatedAt).getTime() < SIX_HOUR;
  }

  public setRating(value: number) {
    this.review.rating = value;
    this.detectChanges();
  }

  public navigateToMeeting() {
    this.router.navigate(['/classes/join/' + this.review.entrySlug]).then();
  }

  public openComplainModal() {
    this.complaintFormOpened = true;
    this.detectChanges();
  }

  public closeComplaintForm() {
    this.complaintFormOpened = false;
    this.detectChanges();
  }

  public submit() {
    if (this.submitState !== 'default') {
      return;
    }

    this.submitState = 'progress';
    this.commentService
      .updateReview(this.review)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.showForm = false;
          this.submitState = 'default';
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.submitState = 'fail';
          this.detectChanges();
        },
      });
  }

  public trackByFn(index) {
    return index;
  }
}
