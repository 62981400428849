<div class="card-section" [class.mobile]="deviceService.isMobile()">
  <div class="card-header">
    <div>
      <h2 class="title">Счета для выплат</h2>
      <p class="sub-title">Тут отображаются счета для выплат вознаграждений школе</p>
    </div>
  </div>

  <div class="type-payments" *ngFor="let merchant of merchants; trackBy: trackByFn">
    <div class="title bold">
      {{isForNotRussia(merchant) ? ('payout.all_world' | translate)
      : ('payout.russia' | translate)}}
      {{isTestProvider(merchant) ? '(' + merchant.note + ')' : null}}
    </div>
    <div class="payments">
      <div class="payment-wrap suppliers" *ngFor="let supplier of activeSuppliers[merchant.id]; trackBy: trackByFn">
        <app-payout-card
          [supplier]="supplier" [is_test]="isTestBeneficiary(supplier)"
          (getMoney)="getMoney($event)" (onChange)="updateMethodList()"
          (onEdit)="editMethod($event)"
          (openHistory)="openPaymentHistory($event)"></app-payout-card>
      </div>

      <!-- Добавление нового метода -->
      <div class="payment add" (click)="showAddPaymentAlert(merchant.id)">
        <div class="add-link">
              <span class="plus">
                <app-icon width="12" height="12" icon="plus"></app-icon>
              </span> <span class="text">{{'payout.add_payment_method' | translate}}</span>
        </div>
      </div>
    </div>

    <div class="non-active-suppliers" *ngIf="inactiveSuppliers[merchant.id]?.length">
      <div class="toggler" [class.show]="showNonActive" (click)="toggleNonActive()">
        <span class="name">{{'payout.inactive' | translate}} {{isForNotRussia(merchant) ? ('payout.all_world' | translate)
          : ('payout.russia' | translate)}}</span>
        <app-icon class="btn-arrow-ico" width="11" height="6" icon="angle-arrow"></app-icon>
      </div>
      <div class="payments non-active" [class.show]="showNonActive">
        <div class="payment-wrap suppliers" *ngFor="let supplier of inactiveSuppliers[merchant.id]; trackBy: trackByFn">
          <app-payout-card
            [supplier]="supplier" [is_test]="isTestBeneficiary(supplier)"
            (getMoney)="getMoney($event)" (onChange)="updateMethodList()" (onSetDefault)="setSupplierDefault($event)"
            (onEdit)="editMethod($event)"></app-payout-card>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="footer-action" *ngIf="!deviceService.isMobile()">
  <div class="support">
    <div class="badge">
      <app-icon
        class="badge__icon"
        [width]="20"
        [height]="16"
        [icon]="'badge-bank_card'">
      </app-icon>
    </div>
    <p class="support-info">{{ 'notifications.billing-card.bank-card-support' | translate}}</p>
  </div>
  <p class="support-link" (click)="openCrispChat()">{{ 'notifications.billing-card.support' | translate}}</p>
</div>


<app-modal *ngIf="newPaymentFormOpened && !deviceService.isMobile()" [canClose]="false"
           (closed)="closeNewCardForm()">
  <app-modal-body>
    <app-finance-card-create-new [options]="supplierModalOptions" [school]="school"
                                 (finished)="closeNewCardForm()"></app-finance-card-create-new>
  </app-modal-body>
</app-modal>
<app-mobile-modal *ngIf="newPaymentFormOpened && deviceService.isMobile()"
                  (closed)="closeNewCardForm()">
  <app-mobile-modal-body class="finance-mobile">
    <app-finance-card-create-new [options]="supplierModalOptions" [school]="school"
                                 (finished)="closeNewCardForm()"></app-finance-card-create-new>
  </app-mobile-modal-body>
</app-mobile-modal>

<app-modal *ngIf="withdrawMoneySupplier && !deviceService.isMobile()" [canClose]="false"
           (closed)="closeWithdrawMoneyForm()">
  <app-modal-body>
    <app-withdraw-money-form [supplier]="withdrawMoneySupplier"
                             (success)="updateMethodList()"
                             (fail)="updateMethodList()" (close)="closeWithdrawMoneyForm()"></app-withdraw-money-form>
  </app-modal-body>
</app-modal>
<app-mobile-modal *ngIf="withdrawMoneySupplier && deviceService.isMobile()"
                  [titleVisible]="false"  [withCloseButton]="true"
                  [customTitle]="'finance.tabs.payout' | translate"
                  (closed)="closeWithdrawMoneyForm()">
  <app-mobile-modal-body class="finance-mobile">
    <app-withdraw-money-form [supplier]="withdrawMoneySupplier"
                             (success)="updateMethodList()"
                             (fail)="updateMethodList()" (close)="closeWithdrawMoneyForm()"></app-withdraw-money-form>
  </app-mobile-modal-body>
</app-mobile-modal>

<app-alert-payout *ngIf="showEditAlert" (cancel)="showEditAlert = null" (change)="showEditAlert = false; newPaymentFormOpened = true"></app-alert-payout>

<app-alert *ngIf="showWarningAlert" type="warning" [btnPosition]="'center'">
  <ng-container alert-icon>
    <app-icon width="36" height="36" file="emoji" icon="think"></app-icon>
  </ng-container>
  <ng-container alert-title>{{'payout.add-alert-title' | translate}}</ng-container>
  <ng-container alert-body>{{'payout.add-alert-text' | translate}} </ng-container>
  <ng-container alert-buttons>
    <on-ui-button label="{{'payout.dontadd' | translate}}" style="margin-right: 15px;"
                  class="primary outline transparent center"
                  (onclick)="closeAddPaymentAlert()">
    </on-ui-button>
    <on-ui-button label="{{'payout.add' | translate}}"
                  class="primary center"
                  (onclick)="addPayment()">
    </on-ui-button>
  </ng-container>
</app-alert>
