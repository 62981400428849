<article class="profile-sidebar-widget fixed" #fixed>
  <div class="video-wrap" *ngIf="isMediaPrepared">
      <ng-container *ngIf="isVideoFromYoutube; else recordedVideo">
        <iframe [src]="safeMediaUrl"
                allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                class="video">
        </iframe>
      </ng-container>

      <ng-template #recordedVideo>
        <video class="video" controls playsinline height="100%" width="100%">
          <source [src]="safeMediaUrl" type='video/mp4'>
        </video>
      </ng-template>
  </div>

  <ng-template [ngIf]="!withoutPrice">
    <div class="price-block">
      <div *ngIf="minPriceTier" class="prices">
        <span class="price-title">{{ 'teacher-card.from' | translate }}</span>
        <span class="price-value">{{ minPriceTier ? (getFormattedAmount() | async) : 0 }}</span>
      </div>

      <ng-template [ngIf]="canSignup$ | async" [ngIfElse]="cantSignupRef">
        <on-ui-button [attr.data-test]="'sign-up-button'"
                      class="secondary"
                      label="profile-sidebar-widget-20210625.sign-up"
                      (onclick)="openCheckoutModal()">
        </on-ui-button>
        <span class="back-info">{{ 'user-profile.guarantee-text' | translate }}</span>
      </ng-template>
      <ng-template #cantSignupRef>
        <on-ui-button class="status"
                      label="teacher-card.actions.no-available-hours">
        </on-ui-button>
        <div class="popover-action" [appPopover]="popoverContent" position="bottom" [popoverOnly]="true"></div>
      </ng-template>
    </div>
  </ng-template>

  <div class="stats-block">
    <ng-container *ngTemplateOutlet="statsWrap"></ng-container>
  </div>

</article>


<ng-template #statsWrap>
  <p class="stat-title">{{ 'user-profile.teacher-stat.title-stat' | translate }}</p>
  <ng-container *ngFor="let stats of statsList; trackBy: trackByFn">
    <ng-container *ngIf="stats?.value || stats?.date || stats?.text || stats?.country">
      <div  class="stat-wrap">
        <ng-template [ngIf]="stats.type === eTypeStats.Separator" [ngIfElse]="others">
          <hr class="separator">
        </ng-template>
        <ng-template #others>
          <div class="label-wrap">
            <span class="label">{{ stats.label | translate }}</span>
          </div>
          <div class="value-wrap">
            <ng-container [ngSwitch]="stats.type">
              <div *ngSwitchCase="eTypeStats.Rating" class="rating-block">
                <app-icon class="rating-icon" [height]="16" [width]="16" [icon]="'rating'"></app-icon>
                <span class="value medium rating">{{ stats.value | numberRound }}</span>
              </div>
              <span *ngSwitchCase="eTypeStats.Value" class="value medium">{{ stats.value | numberRound }}</span>

              <span *ngSwitchCase="eTypeStats.Text" class="value medium">{{ stats.text }}</span>

              <span *ngSwitchCase="eTypeStats.Date" class="value medium">{{ stats.date | dfnsFormat: 'LLL yyyy' }}</span>

              <span *ngSwitchCase="eTypeStats.TimeDistance"
                    class="value medium">{{ stats.date | dfnsFormatDistanceToNow }}</span>

              <div *ngSwitchCase="eTypeStats.Country" class="country">
                <img *ngIf="stats?.country?.flag_url" [src]="stats?.country?.flag_url" [alt]="stats?.country?.title_native" class="flag">
                <span class="value medium">{{ stats?.country?.title_native }}</span>
              </div>

            </ng-container>

          </div>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template #popoverContent>
  <div class="popover">
    <p [innerHTML]="'profile-sidebar-widget-20210625.popover-text' | translate" class="popover-text"></p>
    <a routerLink="/my-library/services" class="popover-link">{{ 'profile-sidebar-widget-20210625.popover-link' | translate }}</a>

    <svg class="popover-icon" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M11 21C16.5228 21 21 16.5228 21 11C21 5.47715 16.5228 1 11 1C5.47715 1 1 5.47715 1 11C1 16.5228 5.47715 21 11 21Z" stroke="#FFA500" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M8.09009 8.00001C8.32519 7.33167 8.78924 6.76811 9.40004 6.40914C10.0108 6.05016 10.729 5.91894 11.4273 6.03872C12.1255 6.15849 12.7589 6.52153 13.2152 7.06353C13.6714 7.60554 13.9211 8.29153 13.9201 9.00001C13.9201 11 10.9201 12 10.9201 12" stroke="#FFA500" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M11 16H11.01" stroke="#FFA500" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  </div>
</ng-template>

<app-modal *ngIf="deviceService.isDesktop() && userServiceCardFullInModal"
           [canClose]="false"
           (closed)="onToggleUserServiceInModal()">
  <app-modal-body>
    <app-user-service-card-full [userService]="openedUserService"
                                [modal]="true"
                                (inViewportChange)="onIinViewportChange($event)">
    </app-user-service-card-full>
  </app-modal-body>
  <app-modal-ad>
    <ng-template [ngIf]="!surfaceBlockDisplay">
      <app-user-service-surface-block [userService]="openedUserService"></app-user-service-surface-block>
    </ng-template>
  </app-modal-ad>
</app-modal>

<app-mobile-modal *ngIf="!deviceService.isDesktop() && userServiceCardFullInModal"
                  [titleVisible]="true"
                  (closed)="onToggleUserServiceInModal()">
  <app-mobile-modal-body>
    <app-user-service-card-full [userService]="openedUserService" [modal]="true"></app-user-service-card-full>
  </app-mobile-modal-body>
</app-mobile-modal>
