import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProfileFormValueResolver } from '@app/account/profile-form-page/_resolvers';
import { ProfileFormService } from '@app/account/profile-form-page/_services/profile-form.service';
import { AboutFormPageModule } from '@app/account/profile-form-page/about-form-page/about-form-page.module';
import { AboutFormReferencesResolver } from '@app/account/profile-form-page/about-form-page/resolvers';
import { ContactsFormPageModule } from '@app/account/profile-form-page/contacts-form-page/contacts-form-page.module';
import { EducationFormPageModule } from '@app/account/profile-form-page/education-form-page/education-form-page.module';
import { ExperienceFormPageModule } from '@app/account/profile-form-page/experience-form-page/experience-form-page.module';
import { TeachingFormPageModule } from '@app/account/profile-form-page/teaching-form-page/teaching-form-page.module';
import { AllStepsModule } from '@app/landings/profile/all-steps/all-steps.module';
import { ProfileApplicationModule } from '@app/landings/profile/application/profile-application.module';
import { OnModerationModule } from '@app/landings/profile/on-moderation/on-moderation.module';
import { ProfilePaymentModule } from '@app/landings/profile/payment/profile-payment.module';
import { ProfileServicesModule } from '@app/landings/profile/services/profile-services.module';
import { RegularScheduleFormModule } from '@app/schedule-page/regular-schedule-form/regular-schedule-form.module';
import { ProfilePageComponent } from './profile-page.component';

const routes: Routes = [
  {
    path: '',
    component: ProfilePageComponent,
    resolve: {
      aboutReferences: AboutFormReferencesResolver,
      profileInfo: ProfileFormValueResolver,
    },
  },
];

@NgModule({
  declarations: [ProfilePageComponent],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    TeachingFormPageModule,
    AboutFormPageModule,
    ContactsFormPageModule,
    EducationFormPageModule,
    ExperienceFormPageModule,
    ProfilePaymentModule,
    ProfileApplicationModule,
    ProfileServicesModule,
    AllStepsModule,
    OnModerationModule,
    RegularScheduleFormModule,
  ],
  providers: [ProfileFormService, AboutFormReferencesResolver, ProfileFormValueResolver],
})
export class ProfilePageModule {}
