import { ChangeDetectionStrategy, Component, Injector, Input, OnInit } from '@angular/core';
import { IPagination } from '@app/content-editor/store/library/library.selectors';
import { BaseCalendarComponent, IScheduleProps } from '@app/events-calendar/base-calendar/base-calendar.component';
import { CalendarContext } from '@app/events-calendar/user-service-multi-selector/user-service-multi-selector.component';
import { FeaturesService, fullUserServiceDetails, IFindSchoolScheduleFilter } from '@core/services';
import { getMyScheduleCurrentDay } from '@store/reducers/profile.reducer';
import { endOfWeek, startOfWeek } from 'date-fns';
import {
  CSchool,
  FindUserServiceStrategyEnum,
  IFindUserServicesFilter,
  SingleEvent,
  User,
  UserService,
} from 'lingo2-models';
import { Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

@Component({
  selector: 'app-school-calendar',
  templateUrl: '../base-calendar/base-calendar.component.html',
  styleUrls: ['../base-calendar/base-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolCalendarComponent extends BaseCalendarComponent implements OnInit {
  @Input() school: CSchool;
  @Input() teachers: Array<Partial<User>>;

  public constructor(protected inject: Injector, public features: FeaturesService) {
    super(inject, features);
  }

  public ngOnInit() {
    if (!this.isBrowser) {
      return;
    }

    super.ngOnInit();
    const teacher_ids = (this.teachers || []).map((teacher) => teacher.id);
    this.log('SchoolCalendarComponent.ngOnInit', {
      school_id: this.school?.id,
      teacher_ids,
    });

    this.reloadSchedule();
    this.loadUserServices();
    teacher_ids.map((userId) => this.startWatchingSchedule(userId));
  }

  public get canScheduleMeeting(): boolean {
    return false;
  }

  public get canScheduleServices(): boolean {
    return false;
  }

  public get canMarkAvailableHour(): boolean {
    return false;
  }

  protected findUserServices$(): Observable<UserService[]> {
    const filter: Partial<IFindUserServicesFilter> = {
      use: FindUserServiceStrategyEnum.school_teaching,
      school_id: this.school.id,
    };
    const pagination: IPagination = {
      page: 1,
      pageSize: 100,
    };

    return this.userServicesService
      .getServices(filter, pagination, fullUserServiceDetails)
      .pipe(map((response) => response.results));
  }

  protected loadSchedule$(props: IScheduleProps): Observable<SingleEvent[]> {
    return this.store.select(getMyScheduleCurrentDay).pipe(
      switchMap((currentDay) => {
        const date = props.date || currentDay || new Date();
        const _filter: IFindSchoolScheduleFilter = {
          account_id: this.teachers.map((t) => t.id),
          date_from: startOfWeek(date, { locale: this.dateConfig.locale() }),
          date_to: endOfWeek(date, { locale: this.dateConfig.locale() }),
        };
        // this.log(this.constructor.name + '.loadSchedule$ ', JSON.stringify(props), ' -> ' + JSON.stringify(_filter));

        return this.scheduleService
          .findSchoolSchedule(this.school?.id, _filter)
          .pipe(
            switchMap((events) => this.eventExtenderService.extend$(events, ['account', 'meeting', 'user_service'])),
          );
      }),
    );
  }

  public get context(): CalendarContext {
    return {
      context: 'school',
      data: this.school,
    };
  }
}
