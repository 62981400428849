<section class="teacher">
  <div class="teacher-container">
    <div class="teacher-columns-wrap">
      <div class="teacher-first-column">
        <div class="text-wrap">
          <h2 class="title bold" [innerHTML]="'job-landing.teacher.title' | translate"></h2>
          <p class="text normal"
             [innerHTML]="'job-landing.teacher.subtitle' | translate"></p>
        </div>
        <div class="action-btn-wrap">
          <div class="action-btn with-arrow text-center" (click)="onBecomeTeacher(mainRoute, true)">
            {{'job-landing.teacher.button-text' | translate}}
          </div>
        </div>
        <div class="variants-wrap desktop">
          <ul class="variants-list">
            <li>{{'job-landing.teacher.variant1' | translate}}</li>
            <li>{{'job-landing.teacher.variant2' | translate}}</li>
            <li>{{'job-landing.teacher.variant3' | translate}}</li>
          </ul>
          <div class="video-link-desktop">
            <a class="video-link">{{'job-landing.teacher.video-link' | translate}}</a>
          </div>
        </div>
        <div class="video-link-tablet">
          <a class="video-link">{{'job-landing.teacher.video-link' | translate}}</a>
        </div>
      </div>
      <div class="teacher-second-column">

        <div class="special-arrow-for-slider">
          <span class="special-arrow-for-slider-text">{{'job-landing.teacher.subimage-text' | translate}}</span>
        </div>

        <div class="slider-wrap">
          <img src="{{assetsUrl('/assets/images/landings/job/slider.svg')}}"/>
          <div class="video-wrap">
            <video class="video" autoplay="autoplay" loop playsinline>
              <source src="{{assetsUrl('/assets/images/landings/common/intro-video.mp4')}}" type="video/mp4" />
            </video>
          </div>
        </div>

      </div>
    </div>
    <div class="variants-wrap mobile tablet">
      <ul class="variants-list">
        <li>{{'job-landing.teacher.variant1' | translate}}</li>
        <li>{{'job-landing.teacher.variant2' | translate}}</li>
        <li>{{'job-landing.teacher.variant3' | translate}}</li>
      </ul>
      <div class="video-link-mobile">
        <a class="video-link">{{'job-landing.teacher.video-link' | translate}}</a>
      </div>
    </div>
  </div>
</section>
