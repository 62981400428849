import { UntypedFormGroup, ValidationErrors, ValidatorFn } from '@angular/forms';

export const beginHourAtAndEndHourAtValidate =
  (): ValidatorFn =>
  (formGroup: UntypedFormGroup): ValidationErrors => {
    const begin_hour_at = formGroup.controls['begin_hour_at'];
    const end_hour_at = formGroup.controls['end_hour_at'];

    if (begin_hour_at.value < end_hour_at.value) {
      formGroup.setErrors(null);
      return null;
    } else {
      formGroup.setErrors({ error: 'begin_hour_at >= end_hour_at' });
      return { error: 'begin_hour_at >= end_hour_at' };
    }
  };
