<div class="header" [class.mobile]="deviceService.isMobile()">
  <div class="back-action" (click)="goBack()">
    <app-icon width="8" height="12" file="chevron" icon="left"></app-icon>
  </div>
  <div class="details-title-wrap">
  <app-title [title]="item.title">
    <ng-container side>
      <app-vocabulary-filter [vocabulary_list_id]="item.id" (onChange)="onVocabularyChange($event)"></app-vocabulary-filter>
    </ng-container>
  </app-title>
  </div>
</div>
<div class="list-details">
  <app-vocabulary-card [page]="'main'" [list]="item" [vocabulary]="vocabulary"></app-vocabulary-card>
</div>
