<section class="who-can">
  <div class="container">
    <div class="tab-wrap">
      <div class="image {{slidesList[selectedIndex].class}}"></div>
      <div class="data">
        <div class="title">
          {{'landings.home.who-can-title' | translate}}
        </div>

        <div class="slider">
          <div class="scroll">
            <ul class="slide-menu-list">
              <li *ngFor="let listItem of slidesList; let i = index; trackBy: trackByFn"
                  class="list-item medium"
                  [class.selected]="selectedIndex === i"
                  (click)="onSelectItem(i)">
                {{ listItem.title | translate}}
              </li>
            </ul>
          </div>
          <div class="carousel-wrap">
            <div class="carousel-list slide">
              <div class="text">
                {{slidesList[selectedIndex].text | translate}}
              </div>

              <div class="link-wrap" *ngIf="slidesList[selectedIndex].link">
                <a [routerLink]="slidesList[selectedIndex].link" class="link">
                  {{'landings.home.who-can-detail' | translate}}
                </a>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>
