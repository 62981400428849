<div class="common-landing">
  <ng-container *ngIf="!isMeVersion()" #app_header></ng-container>
  <ng-container *ngIf="!isMeVersion(); else landingForOnclassMe">
    <main class="main">
      <app-left-sidebar *ngIf="isSmallScreen" [floating]="isSmallScreen"></app-left-sidebar>
      <app-top-promo (becomeTeacher)="onBecomeTeacher($event)"></app-top-promo>
      <app-advantages-common></app-advantages-common>
      <app-who-can></app-who-can>
      <app-how-study></app-how-study>
      <app-onclass-it></app-onclass-it>
      <app-teach-with-onclass></app-teach-with-onclass>
      <app-mobile-app-common></app-mobile-app-common>
      <app-reviews-common></app-reviews-common>
      <app-promo-footer-common (becomeTeacher)="onBecomeTeacher($event)"></app-promo-footer-common>
    </main>
    <app-footer-common></app-footer-common>
    <app-mobile-footer *ngIf="isFooterVisible"></app-mobile-footer>
  </ng-container>
  <ng-template #landingForOnclassMe>
    <main class="main">
      <app-left-sidebar *ngIf="isSmallScreen" [floating]="isSmallScreen"></app-left-sidebar>
      <app-promo-me></app-promo-me>
    </main>
    <app-footer-common></app-footer-common>
  </ng-template>
</div>
