<section class="advantages">
  <div class="advantages-container">
    <div class="advant">
      <div class="advant-image">
        <img src="{{assetsUrl('/assets/images/landings/job/advantages-1.png')}}"/>
      </div>
      <div class="advant-text">
        {{'job-landing.advantages.text1' | translate}}
      </div>
    </div>
    <div class="advant">
      <div class="advant-image">
        <img src="{{assetsUrl('/assets/images/landings/job/advantages-2.png')}}"/>
      </div>
      <div class="advant-text">
        {{'job-landing.advantages.text2' | translate}}
      </div>
    </div>
    <div class="advant">
      <div class="advant-image">
        <img src="{{assetsUrl('/assets/images/landings/job/advantages-3.png')}}"/>
      </div>
      <div class="advant-text">
        {{'job-landing.advantages.text3' | translate}}
      </div>
    </div>
  </div>
</section>
