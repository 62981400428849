<app-modal *ngIf="!deviceService.isMobile()"
           [middle]="true"
           [closeInside]="true"
           (closed)="onClose()">
    <app-modal-body>
        <ng-container *ngTemplateOutlet="selectorRef"></ng-container>
    </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="deviceService.isMobile()" (closed)="onClose()">
<app-mobile-modal-body>
    <ng-container *ngTemplateOutlet="selectorRef"></ng-container>
</app-mobile-modal-body>
</app-mobile-modal>

<ng-template #selectorRef>
<div class="items" [class.mobile]="deviceService.isMobile()">
  <div *ngFor="let role of roles; trackBy: trackByFn" class="item" [class.active]="selectedRole===role" (click)="setRole(role)">
    {{'my-classes.no-schools.filter.' + role | translate}}
  </div>
</div>
</ng-template>
