import { ChangeDetectorRef, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { FilesService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { IconComponent } from '@shared/icon/icon.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil, tap } from 'rxjs';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-vocabulary-media-player',
  standalone: true,
  imports: [SharedModule, IconComponent],
  templateUrl: './vocabulary-media-player.component.html',
  styleUrls: ['./vocabulary-media-player.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VocabularyMediaPlayerComponent),
      multi: true,
    },
  ],
})
export class VocabularyMediaPlayerComponent extends ChangableComponent {
  @Input() set value(id: string) {
    this._value = id;
    this.writeValue(id);
  }

  public fileUrl: string;
  public _value: string;
  private propagateChange: any = () => {};
  private propagateTouched: any = () => {};

  constructor(
    public deviceService: DeviceDetectorService,
    public filesService: FilesService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  writeValue(value: string): void {
    this.filesService
      .getFileById(value)
      .pipe(
        tap((file) => {
          this.fileUrl = file.storage_file_url;
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  public play() {
    const audio = new Audio();
    audio.src = this.fileUrl;
    audio.load();
    void audio.play();
  }
}
