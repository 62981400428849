<div class="vocabulary" [class.mobile]="deviceService.isMobile()">
  <div class="header-wrap">
    <app-main-header></app-main-header>
  </div>

  <div [class.scroll]="deviceService.isMobile()" class="vocabulary-tabs">
    <div *ngFor="let item of tabs; trackBy: trackByFn" class="categories-item">
      <app-categories-item [displayMode]="'simple'"
                           [link]="routeLinkByItem(item)"
                           [panel]="item">
      </app-categories-item>
    </div>
  </div>

  <div class="simple-title" *ngIf="showTitle()">
    {{'vocabulary-page.title.' + pageTitle() | translate}}
  </div>
  <router-outlet></router-outlet>
</div>








