<div class="card" [class.card_mobile]="deviceService.isMobile()">

  <div class="card__content">
    <form [class.hide]="step==='about'" [formGroup]="form" novalidate autocomplete="off">
      <!-- userpic_id -->

      <app-form-field class="field" [control]="form.controls.userpic_id">
        <div class="main">
          <div class="left">
            <p class="label-title">{{ (isTeacher ? 'profile-form.about.upload-photo-cover' : 'profile-form.about.upload-photo') | translate }}</p>
            <p class="hint-title">{{ 'profile-form.about.photo-hint' | translate }}</p>
          </div>
          <div class="right">
            <div class="img-thumb">
              <div class="images">
                <img src="{{ assetsUrl('/assets/images/profile/img_1.png') }}" alt="">
                <img src="{{ assetsUrl('/assets/images/profile/img_2.png') }}" alt="">
                <img src="{{ assetsUrl('/assets/images/profile/img_3.png') }}" alt="">
              </div>

              <ul>
                <li>{{ 'profile-form.user-pic.use-background' | translate }}</li>
                <li>{{ 'profile-form.user-pic.smile' | translate }}</li>
                <li>{{ 'profile-form.user-pic.look-camera' | translate }}</li>
              </ul>

            </div>
          </div>
        </div>
        <app-image-uploader
          *ngIf="isTeacher"
          formControlName="cover_id"
          [autoCrop]="true"
          [display]="'high'"
          [textPosition]="deviceService.isMobile() ? 'top' : 'center'"
          [cropRatio]="'wide-banner'"
          [canRemove]="true">
        </app-image-uploader>
        <app-userpic formControlName="userpic_id"
                     class="col userpic"
                     [isTeacher]="isTeacher"
                     (changed)="onUserPicChange()"></app-userpic>
      </app-form-field>

      <!-- Group: first_name + last_name -->

      <app-form-field class="field">
        <p class="label-title">{{ 'profile-form.about.your-name' | translate }}</p>
        <p class="hint-title">{{ 'profile-form.about.your-name-hint' | translate }}</p>
        <div class="row">
          <app-form-field class="field" [control]="form.controls.first_name">
            <on-ui-input formControlName="first_name"
                         data-test="firstname"
                         class="col col_md"
                         [maxlength]="100"
                         [placeholder]="'profile-form.about.first_name' | translate">
            </on-ui-input>
          </app-form-field>

          <app-form-field class="field" [control]="form.controls.last_name">
            <on-ui-input formControlName="last_name"
                         data-test="lastname"
                         class="col col_md"
                         [maxlength]="100"
                         [placeholder]="'profile-form.about.last_name' | translate">
            </on-ui-input>
          </app-form-field>
        </div>
      </app-form-field>

      <!-- slug -->

<!--      <app-form-field class="field margin-top-30" [control]="form.controls.slug">-->
<!--        <p class="label-title">{{ 'profile-form.about.nickname' | translate }}</p>-->
<!--        <p class="hint-title">{{ 'profile-form.about.nickname-hint' | translate }}</p>-->
<!--        <app-form-field class="field" [control]="form.controls.slug">-->
<!--          <on-ui-input formControlName="slug"-->
<!--                       data-test="slug"-->
<!--                       class="col col_md"-->
<!--                       [maxlength]="100"-->
<!--                       [placeholder]="'profile-form.about.nickname' | translate">-->
<!--          </on-ui-input>-->
<!--        </app-form-field>-->
<!--      </app-form-field>-->

      <!-- country_id -->

      <app-form-field class="field margin-top-30" [control]="form.controls.country_id">
        <p class="label-title">{{ 'profile-form.about.address-question' | translate }}</p>
        <p class="hint-title">{{ 'profile-form.about.address-hint' | translate }}</p>
        <on-ui-select formControlName="country_id"
                      data-test="country"
                      [error]="isInvalidMain('country_id')"
                      class="col col_lg"
                      [placeholder]="'profile-form.select-country' | translate"
                      [options]="countriesOptions">
        </on-ui-select>
      </app-form-field>

      <!-- timezone -->

      <app-form-field class="field margin-top-30" [control]="form.controls.timezone">
        <p class="label-title">{{ 'settings-page.timezone.title' | translate }}</p>
        <p class="hint-title">{{ 'settings-page.timezone.description' | translate }}</p>
        <on-ui-select-search [placeholder]="'regular-schedule-form.timezone-placeholder' | translate"
                            formControlName="timezone"
                            data-test="timezone"
                            [options]="timezonesOptions"
                            [error]="isInvalidMain('timezone')">
        </on-ui-select-search>
      </app-form-field>
    </form>

    <form [class.hide]="!step || step==='main'" [formGroup]="formAbout" novalidate autocomplete="off">

      <!-- birth_date -->

      <app-form-field class="field" [control]="formAbout.controls.birth_date">
        <p class="label-title">{{'profile-form.about.dob' | translate }}</p>
        <p class="hint-title"></p>
        <on-ui-date formControlName="birth_date"
                    data-test="birthdate"
                    class="col col_md" startView="multi-year" [max]="maxDate" [min]="minDate"></on-ui-date>
      </app-form-field>

      <!-- FormArray: spoken_languages -->

      <app-form-field class="field margin-top-30" [control]="formAbout.controls.spoken_languages">
        <p class="label-title">{{'profile-form.about.language-spoken' | translate }}</p>
        <p class="hint-title"></p>
        <div formArrayName="spoken_languages">
          <div *ngFor="let group of spokenLanguages.controls; let idx = index; trackBy: trackByFn" [formGroupName]="idx"
               class="profile-form-group" [class.form-group_mobile]="deviceService.isMobile()">

            <div class="profile-form-group__content">
              <div class="row">

                <app-form-field class="field" [control]="group.controls.language_id">
                  <on-ui-select class="col col_md"
                                data-test="language"
                                formControlName="language_id"
                                [error]="isInvalidGroup(group, 'language_id')"
                                [options]="languagesOptions"
                                placeholder="{{ 'profile-form.select-language' | translate }}">
                  </on-ui-select>
                </app-form-field>

                <app-form-field class="field" [control]="group.controls.level_id" *ngIf="isSpokenLanguageSelected(idx)">
                  <on-ui-select class="col col_md"
                                [class.select_disabled]="idx===0"
                                [class.no-click]="idx===0"
                                formControlName="level_id"
                                data-test="level"
                                [error]="isInvalidGroup(group, 'level_id')"
                                [options]="languageLevelsOptions"
                                placeholder="{{ 'profile-form.select' | translate }}">
                  </on-ui-select>
                </app-form-field>

              </div>
            </div>

            <div *ngIf="idx!==0" class="profile-form-group__actions">
              <app-icon
                class="trash-icon"
                data-test="remove-language"
                file="basket"
                icon="basket-3"
                [width]="30"
                [height]="30"
                (click)="removeSpokenLanguage(idx)"
                [class.visibility-hidden]="spokenLanguages.controls.length === 1">
              </app-icon>
            </div>

            <span class="native-level-hint" *ngIf="group.controls.level_id.value == levels.native && isSpokenLanguageSelected(idx)">
              {{ 'profile-form.native-level-hint' | translate }}
            </span>
          </div>

          <button class="add-input" *ngIf="!hasEmptySpokenLanguage()" [disabled]="form.disabled"
                  data-test="add-language"
                  (click)="addSpokenLanguage()">{{ 'profile-form.add-more-language' | translate }}
          </button>
        </div>
      </app-form-field>

      <!-- about -->
      <app-form-field class="field margin-top-30" [control]="formAbout.controls.about">
        <p class="label-title">{{'profile-form.about.about-yourself' | translate }}</p>
        <p class="hint-title"></p>
        <on-ui-textarea formControlName="about"
                        data-test="about"
                        [error]="isInvalid('about')"
                        (changed)="onChangedField()"
                        [placeholder]="'profile-form.about.about-yourself' | translate"></on-ui-textarea>
      </app-form-field>
    </form>
  </div>

  <div class="card__actions">
    <on-ui-button *ngIf="step==='main' || !step"
                  [disabled]="form.invalid"
                  data-test="profile-next"
                  [state]="saveState"
                  class="primary"
                  label="profile-form.next"
                  (onclick)="navigateToNextPage('about')">
    </on-ui-button>
    <on-ui-button *ngIf="step==='about'"
                  [state]="'default'"
                  data-test="profile-back"
                  class="primary outline margin-right-20"
                  label="profile-form.back"
                  (click)="navigateBack()">
    </on-ui-button>
    <on-ui-button *ngIf="step==='about'"
                  [disabled]="formAbout.invalid"
                  [state]="saveState"
                  class="primary"
                  label="profile-form.next"
                  data-test="profile-next"
                  (onclick)="navigateToNextPage('contacts')">
    </on-ui-button>
  </div>

  <!-- <pre>{{ form.value | json }}</pre> -->

</div>
