import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// компоненты
import { AuthPageComponent } from '@app/auth/auth-page/auth-page.component';
import { GuestGuard } from '@core/guards';
import { AuthEmergencyPageComponent } from './auth-emergency-page/auth-emergency-page.component';
import { AuthSignoutPageComponent } from './auth-signout-page/auth-signout-page.component';
import { AuthModule } from './auth.module';

/**
 * Этот роут срабатывает после ленивой загрузки модуля по роуту /auth/
 *
 * @see AppRoutingModule
 */
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: AuthPageComponent,
        canActivate: [GuestGuard],
      },
      {
        path: 'social',
        component: AuthPageComponent,
        canActivate: [GuestGuard],
      },
      {
        path: 'signout',
        component: AuthSignoutPageComponent,
      },
      {
        path: 'emergency',
        component: AuthEmergencyPageComponent,
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: '/',
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), AuthModule],
  declarations: [],
})
export class AuthRoutingModule {}
