import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { RulesComponent } from '@app/landings/article/rules/rules.component';
import { RecordVideoModule } from '@app/shared/record-video/record-video.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';

import { FormFieldModule, FormControlsModule } from '@shared/form-controls';
import { VideoUploaderComponent } from '@shared/form-controls/video-uploader/video-uploader.component';
import { IconComponent } from '@shared/icon/icon.component';
import { SubjectSelectorModule } from 'lingo2-forms';

import {
  OnUiBulletListModule,
  OnUiButtonModule,
  OnUiButtonTabModule,
  OnUiInputModule,
  OnUiRadioModule,
  OnUiTextareaModule,
} from 'onclass-ui';
import { AlertModule } from '../../../core/components/alert/alert.module';
import { EmbedVideoComponent } from './embed-video/embed-video.component';
import { TeachingFormMapperService } from './mapper/teaching-form-mapper.service';
import { TeachingFormPageComponent } from './teaching-form-page.component';

@NgModule({
  declarations: [TeachingFormPageComponent, EmbedVideoComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    FormFieldModule,
    OnUiInputModule,
    SubjectSelectorModule,
    SpinnersModule,
    ModalModule,
    MobileModalModule,
    FormControlsModule,
    FormsModule,
    RecordVideoModule,
    OnUiBulletListModule,
    OnUiTextareaModule,
    OnUiButtonModule,
    OnUiButtonTabModule,
    RulesComponent,
    PipesModule,
    IconComponent,
    OnUiRadioModule,
    VideoUploaderComponent,
    AlertModule,
  ],
  exports: [TeachingFormPageComponent],
  providers: [TeachingFormMapperService],
})
export class TeachingFormPageModule {}
