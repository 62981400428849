import { Injectable } from '@angular/core';
import { ClassroomsService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';

import { getClassroomsListDetails, getClassroomsListFilter, getClassroomsListPagination } from './classrooms.selectors';
import { loadClassrooms, loadClassroomsSuccess, loadNextPageClassrooms, requestFail } from './classrooms.actions';

@Injectable()
export class ClassroomsListEffects {
  constructor(private actions$: Actions, private classroomsService: ClassroomsService, private store: Store) {}

  loadClassroomsList$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadClassrooms),
      withLatestFrom(this.store.select(getClassroomsListDetails)),
      withLatestFrom(this.store.select(getClassroomsListFilter)),
      withLatestFrom(this.store.select(getClassroomsListPagination)),
      switchMap(([[[, _details], _filter], _pagination]) =>
        this.classroomsService.getClassrooms(_filter, _pagination, _details).pipe(
          map((response) => loadClassroomsSuccess({ response })),
          catchError((error: Error) => of(requestFail({ error: error.message }))),
        ),
      ),
    ),
  );

  loadNextPage$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadNextPageClassrooms),
      switchMap(() => [loadClassrooms()]),
    ),
  );
}
