import {
  CalendarView,
  DAYS_OF_WEEK,
  CalendarEventTimesChangedEvent as ACCalendarEventTimesChangedEvent,
} from 'angular-calendar';
import { CalendarEvent as ACCalendarEvent, WeekViewTimeEvent as CUWeekViewTimeEvent } from 'calendar-utils';
import { AnyType, SingleEvent, UserService, UserServiceContract } from 'lingo2-models';
import { isObject, sortBy } from 'lodash-es';

export type UserServiceItem = UserService & {
  checked?: boolean;
  event?: CalendarEvent;
  preview?: {
    loading: boolean;
    loaded?: boolean;
    contract?: UserServiceContract;
    error?: string;
  };
  overlap?: {
    loading: boolean;
    loaded?: boolean;
    overlapping?: boolean;
    overlappingEvents?: OverlapEvent[];
    error?: string;
  };
};

export interface OverlapEvent {
  begin_at: Date;
  title: string;
}

export type ScheduleEventVisualState =
  | 'passed'
  | 'off'
  | 'completed'
  | 'upcoming'
  | 'free'
  | 'unconfirmed'
  | 'canceled'
  | 'vacation'
  | 'ongoing';

export function roundTime(date: Date, granulation = 5) {
  const minutes = date.getMinutes();
  const roundedMinutes = Math.round(minutes / granulation) * granulation;
  date.setMinutes(roundedMinutes);
  date.setSeconds(0);
  date.setMilliseconds(0);
  return date;
}

export interface ICalendarConfig {
  view: CalendarView;
  locale: string;
  weekStartsOn: DAYS_OF_WEEK;
  minCellHeight: number;
}

export function localeWeekFirstDayToCalendar(firstDay: number): DAYS_OF_WEEK {
  return typeof firstDay === 'number'
    ? {
        1: DAYS_OF_WEEK.MONDAY,
        2: DAYS_OF_WEEK.TUESDAY,
        3: DAYS_OF_WEEK.WEDNESDAY,
        4: DAYS_OF_WEEK.THURSDAY,
        5: DAYS_OF_WEEK.FRIDAY,
        6: DAYS_OF_WEEK.SATURDAY,
        7: DAYS_OF_WEEK.SUNDAY,
      }[firstDay]
    : null;
}

export type CalendarEvent = ACCalendarEvent<SingleEvent & { generation?: number }>;

export type CalendarEventTimesChangedEvent = ACCalendarEventTimesChangedEvent<SingleEvent>;

export interface WeekViewTimeEvent extends CUWeekViewTimeEvent {
  event: CalendarEvent;
}

export type ConfirmationActionType = 'cancel' | 'leave';

export function objectToList(obj: AnyType): Array<[string, AnyType]> {
  const list: Array<[string, AnyType]> = [];
  Object.keys(obj).map((key) => {
    let value = obj[key];
    if (value instanceof Date) {
      value = value.getTime().toString();
    } else if (isObject(value)) {
      value = objectToList(value);
    }
    list.push([key, value]);
  });
  return sortBy(list, [0]);
}

export function valueHash(value: AnyType): string {
  if (value instanceof Date) {
    return value.getTime().toString();
  }
  if (isObject(value)) {
    return JSON.stringify(objectToList(value));
  }
  return JSON.stringify(value);
}

export function eventGeneratedId(event: SingleEvent): string {
  return [event.account_id, event.begin_at.getTime(), event.end_at.getTime(), event.status].join(':');
}
