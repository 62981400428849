<div class="container">
  <div class="header">
    <div routerLink="/" class="logo"></div>
  </div>
  <div class="content">
    <ng-template [ngIf]="!opened">
      <div class="btn btn-secondary" (click)="openCrispChat()">Talk to support</div>
      <a class="btn btn-primary" routerLink="/">Continue to OnClass</a>
    </ng-template>
  </div>
</div>
