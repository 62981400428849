<spinner2 *ngIf="loading"></spinner2>

<ng-template [ngIf]="!loading && !loaded">
  Content not found
  <!--
  <div *ngIf="related.loaded && related.items.length" class="related-content">
    <spinner2 *ngIf="related.loading"></spinner2>

    <app-title [params]="relatedTitle"></app-title>

    <app-content-card-mini-list
      [items]="related.items"
    ></app-content-card-mini-list>

    <button *ngIf="related.pagination.page < related.pagination.totalPages" class="load-more-btn">{{ 'library.pagination-more' | translate }}</button>
  </div>
  -->
</ng-template>

<ng-template [ngIf]="!loading && error">
  <p>{{ error }}</p>
</ng-template>
