import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// страницы
import { SupportPageComponent } from './support-page.component';

/**
 * Этот роут срабатывает после ленивой загрузки модуля по роуту /support
 *
 * @see AppRoutingModule
 */
const routes: Routes = [
  {
    // ! base-path = ''
    path: '',
    component: SupportPageComponent,
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes)],
  declarations: [SupportPageComponent],
})
export class SupportModule {}
