<section class="reviews">
  <div class="reviews-container">
    <div class="reviews-title">
      {{'job-landing.reviews.title' | translate}}
    </div>
    <div class="carousel-wrap">
      <span (click)="moveLeft()" class="move-left-btn"></span>
      <span (click)="moveRight()" class="move-right-btn"></span>
      <ng-template [ngIf]="isBrowser" [ngIfElse]="scroll">
        <cdk-virtual-scroll-viewport #scroller [itemSize]="itemSize" class="scroll-viewport" orientation="horizontal">
          <article class="cursor-pointer" *cdkVirtualFor="let slide of slidesList ; let i = index">
            <ng-container *ngTemplateOutlet="slideTemplate; context: { slide }"></ng-container>
          </article>
        </cdk-virtual-scroll-viewport>
      </ng-template>

      <ng-template #scroll>
        <div class="responsive-scroll-viewport">
          <article class="cursor-pointer" *ngFor="let slide of slidesList ; let i = index; trackBy: trackByFn">
            <ng-container *ngTemplateOutlet="slideTemplate; context: { slide }"></ng-container>
          </article>
        </div>
      </ng-template>
    </div>

    <div class="reviews-bottom"></div>
  </div>
</section>

<ng-template #slideTemplate let-slide="slide">
  <div class="slide">
    <div class="carousel-one-review">
      <div class="ratings">
        <span class="star" *ngFor="let i of [].constructor(slide.rating); trackBy: trackByFn"></span>
      </div>
      <div class="text" [innerHTML]="slide.text | translate"></div>
      <div class="user-data">
        <div class="user-avatar">
          <img [width]="42" [height]="42" [src]="slide.avatar" [alt]="slide.name | translate" />
        </div>
        <div class="user-info">
          <div class="user-name">{{slide.name | translate}}</div>
          <div class="user-lang">{{slide.language | translate}}</div>
        </div>
      </div>
    </div>
  </div>
</ng-template>
