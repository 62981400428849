<section class="teach-with">
  <div class="container">
    <div class="title">
      {{'landings.home.teach-with-title' | translate}}
    </div>
    <div class="flex-wrap">
      <div class="content-wrap">
        <div class="image"></div>
        <div class="content">
          <div class="subtitle">
            {{'landings.home.teach-with-subtitle' | translate}}
          </div>
          <div class="text">
            {{'landings.home.teach-with-text' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
