<ng-container *ngIf="vocabularies.length">
  <div class="vocabulary-filter">
    <div class="vocabulary-filter__btn" (click)="popup_open = true">
      <span>{{selectedVocabulary}}</span>
      <app-icon class="vocabulary-filter__btn-arrow-ico" width="11" height="6" icon="angle-arrow"></app-icon>
    </div>

    <div class="vocabulary-filter-popup" *ngIf="popup_open">
      <div class="shade" (click)="popupState(false)"></div>
      <div class="window" [ngClass]="position">
        <p class="text" *ngFor="let item of vocabularies; trackBy: trackByFn" (click)="setVocabularyLanguage(item, true)">
          {{getLanguageName(item.term_language_id)}}
          -
          {{getLanguageName(item.definition_language_id)}}</p>
      </div>
    </div>
  </div>
</ng-container>
