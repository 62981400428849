import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DestroyableComponent } from '@models/destroyable.component';
import { Store } from '@ngrx/store';
import { IFindContentFilter } from 'lingo2-models';
import { Observable } from 'rxjs';
import { loadLibraryContent, setLibraryFilter } from '../store/library';
import { getLibraryFilter } from '../store/library/library.selectors';
import { loadUIFilters, saveUIFilters } from '../store/sidebar';
import { SidebarState } from '../store/sidebar/sidebar.reducer';
import { getSidebarState } from '../store/sidebar/sidebar.selectors';

@Component({
  selector: 'app-constructor-sidebar',
  templateUrl: './constructor-sidebar.component.html',
  styleUrls: ['./constructor-sidebar.component.scss'],
})
export class ConstructorSidebarComponent extends DestroyableComponent implements OnInit {
  public state$: Observable<SidebarState>;
  public filter$: Observable<Partial<IFindContentFilter>>;

  constructor(private router: Router, private route: ActivatedRoute, private store: Store) {
    super();
  }

  ngOnInit(): void {
    this.state$ = this.store.select(getSidebarState);
    this.filter$ = this.store.select(getLibraryFilter);
    this.store.dispatch(loadUIFilters());
  }

  public get isUserLibraryRoute(): boolean {
    const userLibraryUrls = ['/published', 'draft', 'archive'];
    return !!userLibraryUrls.find((url) => this.router.url.includes(url));
  }

  public onContentFilterChanged(checked: number[]): void {
    this.store.dispatch(setLibraryFilter({ filter: { content_type: [...checked] } }));
    this.store.dispatch(loadLibraryContent());
  }

  public onContentFilterExpanded(isExpanded: boolean): void {
    this.store.dispatch(saveUIFilters({ sidebar: { typeOpen: isExpanded } }));
  }
}
