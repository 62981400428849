<div class="sidebar-block filter-block" *ngIf="isUserLibraryRoute">
  <span class="title bold">{{ 'constructor2.filter.title' | translate }}</span>

  <!-- <app-sort-order-filter></app-sort-order-filter> -->

  <app-content-type-filter 
    [isExpanded]="(state$ | async)?.typeOpen" 
    [checked]="(filter$ | async)?.content_type"
    (changed)="onContentFilterChanged($event)"
    (expanded)="onContentFilterExpanded($event)">
  </app-content-type-filter>

</div>
