<section class="school-onboarding-widget">
  <div class="state-wrap">
    <div class="status-text">
      <span class="status-label">{{ 'school-onboarding.status' | translate }}</span>
      <span class="status-value">{{ statusValue() | translate }}</span>
    </div>
    <div class="status-action">
      <app-icon class="status-icon" [class.expand]="expandBlock" (click)="toggleExpand()" icon="onboarding-arrow"></app-icon>
    </div>
  </div>

  <div *ngIf="expandBlock" class="expandable">
    <app-icon width="36" height="36" icon="onboarding-school"></app-icon>

    <span class="expandable-title">{{ 'school-onboarding.instruction' | translate }}</span>

    <ul class="check-list">
      <li *ngFor="let check of checkList; trackBy: trackByFn" class="check-item">
        <ng-template [ngIf]="!check.state" [ngIfElse]="checked">
          <div class="checkbox"></div>
          <span class="checkbox-label">{{ check.label | translate }}</span>
        </ng-template>
        <ng-template #checked>
          <app-icon width="20" height="20" icon="onboarding-check"></app-icon>
          <span class="checkbox-label checked">{{ check.label | translate }}</span>
        </ng-template>
      </li>
    </ul>
  </div>
</section>
