import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { TitleModule } from '@core/components/title/title.module';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiButtonModule, OnUiInputModule, OnUiSelectModule } from 'onclass-ui';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    MobileModalModule,
    OnUiSelectModule,
    OnUiButtonModule,
    TitleModule,
  ],
  exports: [
    CommonModule,
    TranslateModule,
    ModalModule,
    MobileModalModule,
    OnUiSelectModule,
    OnUiButtonModule,
    OnUiInputModule,
    TitleModule,
  ],
})
export class SharedModule {}
