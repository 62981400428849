import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { IHorizontalScrollItem } from '@core/components/horizontal-scroll-menu/horizontal-scroll-menu.component';
import {
  AccountService,
  CheckContractScheduleRequest,
  CheckContractScheduleResponse,
  ContextService,
  PlatformService,
  ScheduleService,
  SchoolsService,
  SingleEventExtenderService,
  UserServiceContractsService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { normalizeZonedDate } from '@shared/utils';
import { CalendarEvent } from 'angular-calendar';
import { WeekViewHourSegment, WeekViewTimeEvent } from 'calendar-utils';
import {
  AnyType,
  SingleEvent,
  UserService,
  EventStatusEnum,
  User,
  ScheduleUserServiceContractRequest,
  RegularSchedule,
  UserServiceContractIntervalEnum,
  CancelUserServiceContractRequest,
  CSchool,
  CreateUserServiceContractResponse,
} from 'lingo2-models';
import { DateFnsConfigurationService, FormatPipe } from 'lingo2-ngx-date-fns';
import { uniqBy } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonState } from 'onclass-ui';
import { takeUntil, Observable, of, zip, mergeAll } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { OverlapEvent, UserServiceItem } from '../models';

type FilterModeType = 'default' | 'other';

type FilterTabItem = IHorizontalScrollItem & {
  value: FilterModeType;
};

type _CalendarEvent = CalendarEvent<SingleEvent>;

export interface CalendarContext {
  context: 'school' | 'teacher';
  data: CSchool | User;
}

/** Возвращает true если услуга оказывается с привязкой к календарю в указанный день недели */
function providesInWeekday(us: UserService, wd: number): boolean {
  return us.is.schedulable && (us.options?.weekdays || []).includes(wd);
}

@Component({
  selector: 'app-user-service-multi-selector',
  templateUrl: './user-service-multi-selector.component.html',
  styleUrls: ['./user-service-multi-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserServiceMultiSelectorComponent extends ChangableComponent implements OnInit, AfterViewInit {
  @Input() public currentSegmentOrEvent: WeekViewTimeEvent | WeekViewHourSegment;
  @Input() public popoverMode: boolean;
  @Input() public context: CalendarContext;
  @Input() public readonly: boolean;
  @Input() public set userServices(userServices: UserService[]) {
    this._userServices = userServices.map((us) => new UserService(us));
  }
  @Input() public set events(events: _CalendarEvent[]) {
    this._events = events;
  }
  @Input() public teacher: Partial<User>;
  @Input() public set currentDayOfWeek(currentDayOfWeek: Date) {
    this._currentDayOfWeek = currentDayOfWeek;
  }
  public get currentDayOfWeek() {
    return this._currentDayOfWeek;
  }
  @Output() public saved = new EventEmitter<boolean>();

  public debug$ = this.contextService.debug$;
  public userFullName = AccountService.getUserFullName;
  public tabsList: FilterTabItem[] = [];
  public stateOkAction: OnUiButtonState = 'default';

  private _dateFormat = new FormatPipe(this.dateConfig, null);
  private _userServices: UserServiceItem[];
  private _events: _CalendarEvent[];
  private _currentDayOfWeek: Date;
  private _me: User;
  private regularSchedule: RegularSchedule;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private readonly dateConfig: DateFnsConfigurationService,
    private readonly scheduleService: ScheduleService,
    private readonly userServiceContractsService: UserServiceContractsService,
    private readonly store: Store,
    private readonly contextService: ContextService,
    private readonly eventExtenderService: SingleEventExtenderService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    this.tabsList = [
      {
        value: 'default',
        title: this._dateFormat.transform(this._currentDayOfWeek, 'iii, d MMMM, HH:mm'),
      },
    ];

    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (me) => (this._me = me),
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    this.scheduleService
      .getRegular()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (regularSchedule) => (this.regularSchedule = regularSchedule),
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public ngAfterViewInit() {
    this.initUserServices();
  }

  public get isOverlapping(): boolean {
    return this.filteredUserServices.some((us) => !!us.checked && !!us.overlap?.overlapping);
  }

  public get isProcessing(): boolean {
    return this.filteredUserServices.some((us) => !!us.checked && (!!us.preview?.loading || !!us.overlap?.loading));
  }

  public get filteredUserServices(): UserServiceItem[] {
    // услуги, которые ранее уже были назначены на это время
    const selectedServices = this._userServices.filter((us) => !!us.event);

    // услуги, которые можно назначить на это день недели
    const dayOfWeek = this._currentDayOfWeek?.getDay();
    const filteredServices = this._userServices.filter((us) => providesInWeekday(us, dayOfWeek));

    return uniqBy([...selectedServices, ...filteredServices], (us) => us.id);
  }

  public onUserServiceSelectionChanged(user_service_id: string, selected: boolean) {
    const userService = this._userServices.find((item) => item.id === user_service_id);
    if (userService) {
      if (selected) {
        userService.checked = true;
        this.loadContractToCheckOverlap(userService);
      } else {
        if (this.canDeleteReservation(userService)) {
          userService.checked = false;
        }
      }
    }
    this.detectChanges();
  }

  protected canDeleteReservation(userService: UserServiceItem) {
    if (this.readonly) {
      return false;
    }

    const event = userService.event;
    if (event) {
      return event.meta?.status === EventStatusEnum.course_reservations;
    }
    return true;
  }

  public isUserServiceSelected(userService: UserServiceItem): boolean {
    return !!userService.checked;
  }

  public isUserServiceProcessing(userService: UserServiceItem): boolean {
    return !!userService.checked && (userService?.preview?.loading || userService?.overlap?.loading);
  }

  public trackByFnUserServices(index: number, item: UserService): string {
    return item.id;
  }

  public get hasChanges(): boolean {
    return this.createReservationItems.length > 0 || this.deleteReservationItems.length > 0;
  }

  public get actionLabel(): string {
    if (this.createReservationItems.length && this.deleteReservationItems.length) {
      return 'event-calendar.multi-selector.change';
    } else if (this.createReservationItems.length) {
      return 'event-calendar.multi-selector.add';
    } else if (this.deleteReservationItems.length) {
      return 'event-calendar.multi-selector.remove';
    }
    return 'event-calendar.multi-selector.add';
  }

  public applyChanges() {
    if (this.isProcessing || this.isOverlapping) {
      return;
    }

    this.cancelContracts$()
      .pipe(
        switchMap(() => this.createContracts$()),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: () => {
          this.saved.emit(true);
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public closeSelector() {
    this.saved.emit(true);
  }

  private initUserServices() {
    if (this.currentSegmentOrEvent && 'event' in this.currentSegmentOrEvent) {
      const filteredEvents = this._events.filter((_e) => new Date(_e.start).getTime() === this.begin_at.getTime());
      filteredEvents
        .filter((event) => !!event.meta?.user_service)
        .forEach((event) => {
          this.setUserServiceChecked(event.meta?.user_service?.id, true, event);
        });
      this.detectChanges();
    }
  }

  protected setUserServiceChecked(user_service_id: string, checked: boolean, event: _CalendarEvent) {
    const userService = this._userServices.find((item) => item.id === user_service_id);
    if (userService) {
      userService.checked = checked;
      userService.event = event;
      // TODO ? надо ли запускать this.loadContractToCheckOverlap()
    }
  }

  protected get begin_at(): Date {
    if (this.currentSegmentOrEvent) {
      if ('event' in this.currentSegmentOrEvent) {
        return normalizeZonedDate(new Date(this.currentSegmentOrEvent['event'].meta?.begin_at), this._me?.timezone);
      }
      if ('date' in this.currentSegmentOrEvent) {
        return normalizeZonedDate(new Date(this.currentSegmentOrEvent['date']), this._me?.timezone);
      }
    }
    return null;
  }

  protected get end_at(): Date {
    if (this.currentSegmentOrEvent && 'event' in this.currentSegmentOrEvent) {
      return normalizeZonedDate(new Date(this.currentSegmentOrEvent['event'].meta?.end_at), this._me?.timezone);
    }
    return null;
  }

  protected get createReservationItems(): UserServiceItem[] {
    return this.readonly ? [] : this._userServices.filter((us) => !us.event && us.checked);
  }

  protected get deleteReservationItems(): UserServiceItem[] {
    return this.readonly ? [] : this._userServices.filter((us) => !!us.event && !us.checked);
  }

  protected get timezone(): string {
    return this.regularSchedule?.timezone || this._me?.timezone || this.clientTimezone;
  }

  protected get clientTimezone(): string {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
      return 'UTC';
    }
  }

  protected cancelContracts$(): Observable<AnyType> {
    const observables = this.deleteReservationItems
      .map((item) => {
        const request = new CancelUserServiceContractRequest({
          user_service_contract_id: item.event.meta.user_service_contract_id,
        });
        return this.userServiceContractsService.cancelContract(request);
      })
      .filter((value) => !!value);

    if (observables.length === 0) {
      return of(null);
    }

    return zip(...observables).pipe(mergeAll());
  }

  protected loadContractToCheckOverlap(userService: UserServiceItem) {
    if (userService.preview?.loaded) {
      this.checkContractForOverlap(userService);
      return;
    }

    if (userService.preview?.loading) {
      return;
    }

    userService.preview = {
      loading: true,
      loaded: false,
    };
    this.detectChanges();

    const request = this.buildContractRequest(userService);
    this.userServiceContractsService
      .previewContract(request)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          userService.preview.loading = false;
          userService.preview.loaded = true;
          userService.preview.contract = response.contract;
          this.detectChanges();
          this.checkContractForOverlap(userService);
        },
        error: (err) => {
          userService.preview.loading = false;
          userService.preview.error = err.message;
          this.detectChanges();
        },
      });
  }

  protected checkContractForOverlap(userService: UserServiceItem) {
    if (userService.overlap?.loaded || userService.overlap?.loading) {
      return;
    }

    userService.overlap = {
      loading: true,
      loaded: false,
    };
    this.detectChanges();

    const request = new CheckContractScheduleRequest({
      contract: userService.preview.contract,
    });
    this.scheduleService
      .checkContractSchedule(request)
      .pipe(
        // дополнить список событий данными про митинги и услуги
        switchMap((response) =>
          this.extendEvents$(response.overlappingEvents || []).pipe(
            map((overlappingEvents) => ({
              ...response,
              overlappingEvents,
            })),
          ),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (response: CheckContractScheduleResponse) => {
          userService.overlap.loading = false;
          userService.overlap.loaded = true;
          userService.overlap.overlapping = response.overlapping;
          userService.overlap.overlappingEvents = this.formatOverlappingEvents(response.overlappingEvents);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          userService.overlap.loading = false;
          userService.overlap.error = error.message;
          this.detectChanges();
        },
      });
  }

  protected createContracts$(): Observable<AnyType> {
    const createObservables$: Array<Observable<CreateUserServiceContractResponse>> = [];

    this.createReservationItems.map((item) => {
      const request = this.buildContractRequest(item);
      if (request) {
        if (item.overlap?.overlapping === false) {
          createObservables$.push(this.userServiceContractsService.schedule(request));
        } else {
          throw new Error('Overlapping (!)');
        }
      }
    });

    if (createObservables$.length === 0) {
      return of(null);
    }

    return zip(...createObservables$).pipe(mergeAll());
  }

  public get linkToCreate() {
    if (this.context.context === 'school') {
      const schoolUrl = SchoolsService.schoolRoute(this.context.data as CSchool, false).join('/');
      return schoolUrl + '/settings/classes';
    } else {
      return '/my-library/services';
    }
  }

  protected buildContractRequest(userService: UserServiceItem): ScheduleUserServiceContractRequest {
    const teacher_id = this.teacher?.id || this._me?.id;
    if (!!userService.school_id) {
      if (!userService.teacher_ids.includes(teacher_id)) {
        return;
      }
    } else {
      if (userService.author_id !== teacher_id) {
        return;
      }
    }

    if (!userService.is.schedulable) {
      throw new Error('Not supported service type');
    }

    return new ScheduleUserServiceContractRequest({
      teacher_id,
      user_service_id: userService.id,
      schedule: {
        interval: UserServiceContractIntervalEnum.none,
        begin_at: this.begin_at,
        events: [
          {
            begin_at: this.begin_at,
            end_at: this.end_at,
          },
        ],
      },
      timezone: this.timezone,
    });
  }

  protected extendEvents$(events: SingleEvent[]): Observable<SingleEvent[]> {
    return this.eventExtenderService.extend$(events, ['meeting', 'user_service'], {
      meeting: { details: ['title' /* 'slug' если надо формировать ссылки на митинги */] },
      user_service: { details: ['title'] },
    });
  }

  protected formatOverlappingEvents(events: SingleEvent[]): OverlapEvent[] {
    return (events || []).map((e) => {
      const sequence_total = e.details?.sequence_total || 1;
      const sequence_number = e.details?.sequence_number || 1;
      const sequence_index = sequence_total > 1 ? `[#${sequence_number} of ${sequence_total}]` : '';
      const title = e.meeting?.title || e.user_service?.title || '(undefined)';
      return {
        begin_at: e.begin_at,
        title: [sequence_index, title].filter((v) => v.length).join(' '),
      };
    });
  }
}
