import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AccountModule } from '@app/account/account.module';
import { UserWidgetComponent } from '@app/account/components/user-widget/user-widget.component';
import { CollectionsSidebarService } from '@app/collections/components/collections-sidebar/collections-sidebar.service';
import { BestEntityWidgetModule } from '@app/core/components/ad/best-entity-widget/best-entity-widget.module';
import { NearestMeetWidgetModule, OnboardingWidgetModule } from '@app/core/widgets';
import { MeetingReviewWidgetComponent } from '@app/library/panes/meeting-review-widget/meeting-review-widget.component';
import { TeachersSidebarComponent } from '@app/library/sidebars/teachers-sidebar/teachers-sidebar.component';
import { MeetingJoinWidgetComponent } from '@app/meetings/meeting-join-widget/meeting-join-widget.component';
import { MeetingsModule } from '@app/meetings/meetings.module';
import { LibraryFilterWidgetModule } from '../library-filter-widget/library-filter-widget.module';
import { LibraryPanesModule } from '../panes/library-panes.module';
import { LibrarySidebarComponent } from './library-sidebar/library-sidebar.component';
import { MeetingJoinSidebarComponent } from './meeting-join-sidebar/meeting-join-sidebar.component';
import { NewsSidebarComponent } from './news-sidebar/news-sidebar.component';
import { DefailtSidebarComponent } from './universal-sidebar/defailt-sidebar/defailt-sidebar.component';
import { UniversalSidebarComponent } from './universal-sidebar/universal-sidebar.component';

@NgModule({
  declarations: [
    LibrarySidebarComponent,
    UniversalSidebarComponent,
    DefailtSidebarComponent,
    TeachersSidebarComponent,
    NewsSidebarComponent,
    MeetingJoinSidebarComponent,
  ],
  imports: [
    CommonModule,
    LibraryPanesModule,
    /** Filter */
    LibraryFilterWidgetModule,
    /** Widgets */
    AccountModule,
    /** Ad widgets */
    BestEntityWidgetModule,
    /** Meetings sidebar */
    MeetingsModule,
    /** Onboarding widget */
    OnboardingWidgetModule,
    NearestMeetWidgetModule,
    MeetingJoinWidgetComponent,
    MeetingReviewWidgetComponent,
    UserWidgetComponent,
  ],
  exports: [UniversalSidebarComponent, LibrarySidebarComponent, NewsSidebarComponent],
  providers: [CollectionsSidebarService],
})
export class SidebarsModule {}
