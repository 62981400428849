<div class="content">
  <spinner2 *ngIf="creating"></spinner2>

  <div class="opening-info" *ngIf="openingFreezed">
    <a class="container" [href]="conferenceUrl">
      <span>
        <span class="title strong">{{ 'meetings.join.opening' | translate }}</span>
        <span class="subtitle">{{ 'meetings.join.opening-timeout-title' | translate }}</span>
      </span>
      <svg class="open-icon" viewBox="0 0 101 100" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="1" width="100" height="100" rx="20" fill="#41A1D9"/>
        <path d="M81.6042 45.6821C84.9096 47.6119 84.9096 52.3882 81.6042 54.318L29.771 84.5801C26.4377 86.5262 22.25 84.122 22.25 80.2622L22.25 19.7378C22.25 15.878 26.4377 13.4738 29.771 15.4199L81.6042 45.6821Z" fill="white"/>
      </svg>
    </a>
  </div>

  <ng-template [ngIf]="error">
    <p>{{ error | json }}</p>
  </ng-template>
</div>
