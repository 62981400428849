import { Injectable } from '@angular/core';

import { IControlOption } from '@shared/form-controls/form-controls.models';
import { Subject } from 'lingo2-models';

@Injectable()
export class TeachingFormMapperService {
  constructor() {}

  public subjectsToOptions(subjects: Subject[]): IControlOption[] {
    return subjects.map((subject) => ({
      title: subject.title,
      value: subject.id,
    }));
  }
}
