import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// модули
import { AuthGuard } from '@core/guards';
import { AccountModule } from './account.module';

/**
 * Этот роут срабатывает после ленивой загрузки модуля по роуту /me/
 *
 * @see AppRoutingModule
 */
const routes: Routes = [
  {
    // ! base-path = /me
    path: '',
    children: [
      {
        path: 'settings',
        loadChildren: () => import('./settings-page/settings-page.module').then((m) => m.SettingsPageModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'finance',
        loadChildren: () => import('./finance-page/finance-page.module').then((m) => m.FinancePageModule),
        canActivate: [AuthGuard],
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), AccountModule],
  exports: [],
  declarations: [],
  providers: [],
})
export class AccountMeRoutingModule {}
