import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { logger } from '@core/helpers/logger';
import { AccountService, ContextService, FilesService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { openMeetingRescheduleWindow } from '@store/actions/profile.actions';
import { loadUserById } from '@store/actions/users.actions';
import { getMe } from '@store/reducers/profile.reducer';
import { getUserById } from '@store/reducers/users.reducer';
import {
  EContentPanelRoute,
  EMeetingUserAction,
  EMeetingUserState,
  EUserServiceType,
  ImageSizeEnum,
  Meeting,
  MeetingTypeEnum,
  Router as ContentRouter,
  SingleEvent,
  User,
} from 'lingo2-models';
import { EventStatusEnum } from 'lingo2-models/dist/account/enums/event-status-enum';
import { OnUiCover } from 'onclass-ui';
import { takeUntil } from 'rxjs/operators';

interface EventParticipant {
  id: string;
  avatar: OnUiCover;
  name: string;
  link: string;
}

@Component({
  selector: 'app-event-popover',
  templateUrl: './event-popover.component.html',
  styleUrls: ['./event-popover.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventPopoverComponent extends ChangableComponent {
  @Input() set event(event: SingleEvent) {
    this._event = event;
    this.init();
    logger.log(event);
  }
  @Output() editMeetingId = new EventEmitter<string>();
  @Output() inviteMeeting = new EventEmitter<Meeting>();
  @Output() leaveMeeting = new EventEmitter<Meeting>();
  @Output() cancelMeeting = new EventEmitter<Meeting>();
  @Output() closePopover = new EventEmitter<boolean>();

  public title: string;
  public cover: OnUiCover;
  public begin_at: Date;
  public end_at: Date;
  public duration: number;
  public meetingJoinRoute: string[];
  public meetingRoute: string[];
  public debug$ = this.contextService.debug$;
  public EUserServiceType = EUserServiceType;
  public userServiceType: EUserServiceType;
  public sequenceNumber: number;
  public sequenceTotal: number;
  public me: User;
  public students: EventParticipant[] = [];
  public teacher: EventParticipant;

  private _event: SingleEvent;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    private contextService: ContextService,
    private store: Store,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);

    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (me) => {
          this.me = me;
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public get meIsAuthor() {
    if (this.user_service) {
      return this.me.id === this.user_service.author_id;
    } else {
      return this.me.id === this.meeting.author_id;
    }
  }

  public get meeting() {
    return this._event?.meeting;
  }

  public get user_service() {
    return this._event?.user_service;
  }

  public get user_service_contract() {
    return this._event?.user_service_contract;
  }

  public can(action: EMeetingUserAction | string): boolean {
    if (!this.meeting?.can) {
      return false;
    }
    return this.meeting.can[action];
  }

  public is(action: EMeetingUserState | string): boolean {
    if (!this.meeting?.is) {
      return false;
    }
    return this.meeting.is[action];
  }

  public printMeetingInfo() {
    logger.log({
      meeting: this.meeting,
      isTimePassed: this.isTimePassed,
    });
  }

  public get isTimePassed(): boolean {
    return this.meeting?.begin_at < new Date();
  }

  public editRoute(item: Meeting): any {
    return [`/${EContentPanelRoute.classes}/id`, item.id];
  }

  public onRescheduleMeeting() {
    this.store.dispatch(openMeetingRescheduleWindow({ meeting_id: this.meeting.id }));
    this.closePopover.emit(true);
  }

  public onToggleInviteModal() {
    this.inviteMeeting.emit(this.meeting);
    this.detectChanges();
  }

  public onLeaveMeeting() {
    this.leaveMeeting.emit(this.meeting);
  }

  public onCancelMeeting() {
    this.cancelMeeting.emit(this.meeting);
  }

  public onEditMeeting() {
    this.editMeetingId.emit(this._event?.meeting?.id);
  }

  public get isUserService(): boolean {
    return !!this._event?.user_service_id;
  }

  public get isShadowedEvent() {
    return (
      this._event.status === EventStatusEnum.ongoing_reservations ||
      this._event.status === EventStatusEnum.course_reservations
    );
  }

  public eventType(): string {
    if (this._event?.user_service?.type) {
      switch (this._event?.user_service?.type) {
        case EUserServiceType.single:
          return 'single';
        case EUserServiceType.regular:
          return 'regular';
        case EUserServiceType.mini:
          return 'mini';
        case EUserServiceType.course:
          return 'course';
      }
    } else if (this._event?.meeting?.type) {
      switch (this._event?.meeting?.type) {
        case MeetingTypeEnum.single:
          return 'individual';
        case MeetingTypeEnum.group:
          return 'group';
        case MeetingTypeEnum.webinar:
          return 'webinar';
        case MeetingTypeEnum.stream:
          return 'stream';
        case MeetingTypeEnum.talk:
          return 'talk';
      }
    } else {
      return '-';
    }
  }

  private init() {
    this.teacher = null;
    this.students = [];
    this.title = this._event?.meeting?.title || this._event?.user_service?.title;
    this.begin_at = new Date(this._event?.meeting?.begin_at || this._event?.begin_at);
    if (this.user_service) {
      this.duration = this.user_service?.duration;
    } else if (this.meeting) {
      this.duration = this.meeting?.duration;
    }

    if (this.meIsAuthor) {
      this.students = [];
    } else {
      if (this.meeting) {
        if (this.meeting.author) {
          this.teacher = {
            id: this.meeting.author.id,
            avatar: {
              type: 'avatar',
              img: FilesService.getFileUrlBySize(this.meeting.author.userpic_id, ImageSizeEnum.md),
              title: AccountService.getUserFullName(this.meeting.author),
              link: AccountService.accountRoute(this.meeting.author).join('/'),
              form: 'circle',
            },
            name: AccountService.getUserName(this.meeting.author),
            link: AccountService.accountRoute(this.meeting.author).join('/'),
          };
        }
      } else if (this.user_service) {
        this.store.dispatch(loadUserById({ user_id: this.user_service.author_id }));
        this.store
          .select(getUserById, { user_id: this.user_service.author_id })
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: (user) => {
              if (user) {
                this.teacher = {
                  id: user.id,
                  avatar: {
                    type: 'avatar',
                    img: FilesService.getFileUrlBySize(user.userpic_id, ImageSizeEnum.md),
                    title: AccountService.getUserFullName(user),
                    link: AccountService.accountRoute(user).join('/'),
                    form: 'circle',
                  },
                  name: AccountService.getUserName(user),
                  link: AccountService.accountRoute(user).join('/'),
                };
                this.detectChanges();
              }
            },
            error: (error) => {
              this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
            },
          });
      } else {
        this.teacher = null;
      }
    }

    this.end_at = new Date(this._event?.meeting?.end_at || this._event?.end_at);
    this.cover = {
      img: FilesService.getFileUrlBySize(this._event?.meeting?.cover_id, ImageSizeEnum.sm),
    };
    this.meetingJoinRoute = ContentRouter.meetingJoinRoute(this.meeting);
    this.meetingRoute = this.meeting?.is?.canceled ? null : ContentRouter.meetingRoute(this.meeting);
    this.sequenceNumber = this._event.details?.sequence_number;
    this.sequenceTotal = this._event.details?.sequence_total;
    this.userServiceType = this._event?.meeting?.user_service?.type;
    if (this.user_service) {
      this.cover = {
        img: FilesService.getFileUrlBySize(this._event.user_service?.cover_id, ImageSizeEnum.sm),
      };
    }
    this.detectChanges();
  }

  public trackByFn(index) {
    return index;
  }
}
