<div class="user-service-multi-selector"
     [class.popover-mode]="popoverMode"
     [class.mobile]="!deviceService.isDesktop()">
  <div class="tob-block">
    <div class="close" (click)="closeSelector()">
      <app-icon width="20" height="20" icon="close"></app-icon>
    </div>

    <div *ngIf="teacher">{{ userFullName(teacher) }}</div>

    <div class="tabs-list">
      <app-horizontal-scroll-menu type="text" [scrollList]="tabsList"></app-horizontal-scroll-menu>
    </div>

    <span class="hint-text">
      {{ 'event-calendar.multi-selector.hint-text' | translate: { day: currentDayOfWeek | dfnsFormat: 'EEEE' } }}
    </span>
  </div>

  <div class="scroll">
    <div *ngFor="let userService of filteredUserServices; let i = index; trackBy: trackByFnUserServices"
         class="checkbox-event-card-wrap">
      <app-checkbox-event-card [userService]="userService"
                               [checked]="isUserServiceSelected(userService)"
                               [processing]="isUserServiceProcessing(userService)"
                               [readonly]="readonly"
                               (selectionChanged)="onUserServiceSelectionChanged(userService.id, $event)">
      </app-checkbox-event-card>
    </div>

    <ng-template [ngIf]="!filteredUserServices.length">
      <ng-content *ngTemplateOutlet="placeholder"></ng-content>
    </ng-template>
  </div>

  <div *ngIf="filteredUserServices.length"
       class="bottom-block"
       [class.many]="filteredUserServices.length > 6">
    <div class="text-wrap">
      <ng-template [ngIf]="isOverlapping">
        <p class="intersection">{{ 'event-calendar.multi-selector.intersection-text' | translate }}</p>
      </ng-template>
    </div>
    <div class="actions">
      <on-ui-button class="primary"
                    [state]="stateOkAction"
                    [disabled]="!hasChanges || readonly || isOverlapping || isProcessing"
                    [label]="actionLabel"
                    data-test="add-to-calendar"
                    (click)="applyChanges()">
      </on-ui-button>
    </div>
  </div>
</div>

<ng-template #placeholder>
  <div class="placeholder">
    <span class="placeholder-text">{{ 'event-calendar.multi-selector.placeholder-text' | translate }}</span>
    <a routerLink="{{linkToCreate}}" class="placeholder-link">{{ 'event-calendar.multi-selector.placeholder-link' | translate }}</a>
  </div>
</ng-template>
