import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { WithdrawMoneyFormModule } from '@app/account/components/withdraw-money-form/withdraw-money-form.module';
import { FinanceMainPageModule } from '@app/account/finance-page/finance-main-page/finance-main-page.module';
import { AlertPayoutComponent } from '@core/components/alert-payout/alert-payout.component';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { AddCardWizardDialogModule } from '@shared/checkout-wizards/add-card-wizard-dialog';
import { IconComponent } from '@shared/icon/icon.component';
import { PaymentCardModule } from '@shared/payment-card/payment-card.module';
import { PaymentHistoryModule } from '@shared/payment-history/payment-history.module';
import { PayoutCardModule } from '@shared/payout-card/payout-card.module';
import { OnUiButtonModule, OnUiCardMenuModule, OnUiPopoverModule } from 'onclass-ui';
import { ProfilePaymentComponent } from './profile-payment.component';
import { AlertModule } from "../../../core/components/alert/alert.module";
import { FinanceCardCreateNewComponent } from "../../../account/finance-page/components/finance-card-create-new/finance-card-create-new.component";
// import { ProfilePaymentService } from './profile-payment.service';

@NgModule({
  declarations: [ProfilePaymentComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    IconComponent,
    FinanceMainPageModule,
    ModalModule,
    MobileModalModule,
    OnUiButtonModule,
    OnUiCardMenuModule,
    OnUiPopoverModule,
    PaymentCardModule,
    PaymentHistoryModule,
    PayoutCardModule,
    AddCardWizardDialogModule,
    WithdrawMoneyFormModule,
    AlertPayoutComponent,
    AlertModule,
    FinanceCardCreateNewComponent
],
  providers: [
    // ProfilePaymentService,
  ],
  exports: [ProfilePaymentComponent],
})
export class ProfilePaymentModule {}
