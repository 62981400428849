<div class="steps-widget">
  <p class="title">{{ 'profile-form.services.title' | translate }}</p>
  <p class="hint">{{ 'profile-form.services.hint' | translate }}</p>
  <p class="action" (click)="onCreate()"
     data-test="create-class"
     [innerHTML]="'profile-form.services.action' | translate | striptags | nl2br"></p>
  <div class="widget-block" [class.mobile]="deviceService.isMobile()">
    <app-filter-content [tabs]="tabs" [activeTab]="activeTab" (onSelect)="activateTab($event)"></app-filter-content>
    <ng-template [ngIf]="services.items.length===0" [ngIfElse]="servicesList">
        <app-no-content-stub
          [position]="'center'"
          [icon]="'stat-class-2'"
          [link]="'my-library.stub.stub4'"
          (onClick)="onCreate()">
        </app-no-content-stub>
    </ng-template>

    <ng-template #servicesList>
      <div class="service-cards">
        <ng-template ngFor let-userService [ngForOf]="services.items" let-idx="index"
                     [ngForTrackBy]="trackUserServiceByFn">
          <div class="card-wrap" [class.mobile]="deviceService.isMobile()">
            <app-library-service-mini
              [service]="userService"
              (changed)="onChange()"
              (selected)="onSelect(userService)"
              (openUserServiceInModal)="onToggleUserServiceInModal($event)">
            </app-library-service-mini>
          </div>
        </ng-template>
      </div>

      <loading-line *ngIf="services.loading" height="40px" width="100%"></loading-line>
    </ng-template>
  </div>

  <div class="widget-actions" [class.mobile]="deviceService.isMobile()">
    <on-ui-button state="default"
                  data-test="profile-back"
                  class="primary outline margin-right-20 back"
                  label="profile-form.back"
                  (click)="navigateTo('payout')">
    </on-ui-button>
    <on-ui-button state="default"
                  class="primary next"
                  data-test="profile-next"
                  label="profile-form.next"
                  (click)="navigateTo('schedule')">
    </on-ui-button>
    <on-ui-button state="default"
                  *ngIf="!hasServiceOrSkip"
                  class="transparent skip"
                  label="profile-form.skip"
                  data-test="profile-skip"
                  (click)="skipStep()" >
    </on-ui-button>
  </div>
</div>

<app-modal *ngIf="!deviceService.isMobile() && newService"
           [canClose]="false"
           (closed)="onCancel()">
  <app-modal-body>
    <app-user-service-form-3 (changed)="onChange()"></app-user-service-form-3>
  </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="deviceService.isMobile() && newService"
                  [titleVisible]="false"
                  [withCloseButton]="true"
                  [customTitle]="'teacher-profile.new-service' | translate"
                  (closed)="onCancel()">
  <app-mobile-modal-body>
    <app-user-service-form-3 (changed)="onChange()"></app-user-service-form-3>
  </app-mobile-modal-body>
</app-mobile-modal>

<app-modal *ngIf="!deviceService.isMobile() && editService"
           (closed)="onCancel()">
  <app-modal-body>
    <app-user-service-form-3 [userService]="editService" (changed)="onChange()"></app-user-service-form-3>
  </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="deviceService.isMobile() && editService"
                  [titleVisible]="false"
                  [withCloseButton]="true"
                  [customTitle]="'teacher-profile.edit-service' | translate"
                  (closed)="onCancel()">
  <app-mobile-modal-body>
    <app-user-service-form-3 [userService]="editService" (changed)="onChange()"></app-user-service-form-3>
  </app-mobile-modal-body>
</app-mobile-modal>

<app-modal *ngIf="deviceService.isDesktop() && userServiceCardFullInModal"
           [canClose]="false"
           (closed)="onToggleUserServiceInModal()">
  <app-modal-body>
    <app-user-service-card-full [userService]="openedUserService"
                                [modal]="true"
                                (inViewportChange)="onIinViewportChange($event)">
    </app-user-service-card-full>
  </app-modal-body>
  <app-modal-ad>
    <ng-template [ngIf]="!surfaceBlockDisplay">
      <app-user-service-surface-block [userService]="openedUserService"></app-user-service-surface-block>
    </ng-template>
  </app-modal-ad>
</app-modal>

<app-mobile-modal *ngIf="!deviceService.isDesktop() && userServiceCardFullInModal"
                  [titleVisible]="true"
                  (closed)="onToggleUserServiceInModal()">
  <app-mobile-modal-body>
    <app-user-service-card-full [userService]="openedUserService" [modal]="true"></app-user-service-card-full>
  </app-mobile-modal-body>
</app-mobile-modal>
