import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ChangableComponent } from '@app/models/changable.component';
import { ContextService, PlatformService } from '@core/services';
import { UserProfileVerificationEnum } from 'lingo2-models';
import { filter, map, takeUntil } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-all-steps',
  templateUrl: './all-steps.component.html',
  styleUrls: ['./all-steps.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllStepsComponent extends ChangableComponent implements OnInit, OnDestroy {
  constructor(
    public errorNotificationService: ErrorNotificationService,
    private router: Router,
    private contextService: ContextService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.contextService.profile$
      .pipe(
        filter((x) => !!x),
        map((x) => x.verification_status),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (verification_status) => {
          if (
            !(
              verification_status === UserProfileVerificationEnum.sent ||
              verification_status === UserProfileVerificationEnum.resent
            )
          ) {
            this.navigateTo();
          }
          this.markForCheck();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });
  }

  public navigateTo(): void {
    this.router.navigate(['/main']);
  }
}
