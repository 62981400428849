import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { Router } from '@angular/router';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { ChangableComponent } from '@app/models/changable.component';
import { HeaderComponent } from '@core/header/header.component';
import {
  AccountService,
  ContextService,
  AnalyticsService,
  FeaturesService,
  PlatformService,
  ScreenService,
} from '@core/services';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { TenantEnum } from 'lingo2-models';
import { takeUntil } from 'rxjs/operators';
import { BecomeTeacherEvent } from '../models';

@Component({
  selector: 'app-common-landing',
  templateUrl: './common-landing.component.html',
  styleUrls: ['./common-landing.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonLandingComponent extends ChangableComponent implements OnInit, AfterViewInit, OnDestroy {
  public isSmallScreen = this.screenService.isSmallScreen;
  public isFooterVisible: boolean;

  @ViewChild('app_header', { read: ViewContainerRef }) private headerContainerRef: ViewContainerRef;
  private headerComponentRef: ComponentRef<HeaderComponent>;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private screenService: ScreenService,
    private accountService: AccountService,
    private features: FeaturesService,
    private router: Router,
    private contextService: ContextService,
    private analytics: AnalyticsService,
    private location: Location,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.analytics.event(EventActionEnum.view_landing, EventCategoryEnum.engagement, [
      'app-common-landing',
      this.location.path(true),
    ]);

    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (width) => {
        this.isFooterVisible = width < 870;
        this.detectChanges();
      },
      error: (error) => {
        this.isFooterVisible = false;
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
    });
  }

  ngAfterViewInit() {
    void this.loadEmptyComponent().catch();
  }

  public isMeVersion() {
    return this.features.tenant === TenantEnum.onclass_me;
  }

  // TODO ленивая загрузка компонента
  private async loadEmptyComponent(): Promise<void> {
    if (!this.headerContainerRef) {
      return;
    }
    const { HeaderComponent } = await import('../../../core/header/header.component');
    this.headerContainerRef?.clear();
    this.headerComponentRef = this.headerContainerRef?.createComponent(HeaderComponent);
    this.headerComponentRef.changeDetectorRef.detectChanges();
  }

  public onBecomeTeacher(e: BecomeTeacherEvent) {
    const _route = () => void this.router.navigate(e.route).then(() => true);

    if (e.markAsTeacher) {
      // пометить меня как 'хочу стать преподом'
      this.analytics.event(EventActionEnum.become_teacher, EventCategoryEnum.engagement, [
        'app-common-landing',
        e.block,
      ]);
      this.accountService
        .markAsTeacher()
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (account) => {
            // logger.debug('CommonLandingComponent::onBecomeTeacher() -> this.accountService.markAsTeacher()');

            this.contextService.updateMe(account); // обновить мой аккаунт

            _route();
          },
          error: (error: any) => {
            this.errorNotificationService.captureError(error, 'PROFILE-PROBLEM');
            _route();
          },
        });
    } else {
      // пометить меня как 'хочу стать учеником'
      this.analytics.event(EventActionEnum.become_student, EventCategoryEnum.engagement, [
        'app-common-landing',
        e.block,
      ]);
      this.accountService
        .markAsStudent()
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (account) => {
            // logger.debug('CommonLandingComponent::onBecomeTeacher() -> this.accountService.markAsStudent()');

            this.contextService.updateMe(account); // обновить мой аккаунт

            _route();
          },
          error: (error: any) => {
            this.errorNotificationService.captureError(error, 'PROFILE-PROBLEM');
            _route();
          },
        });
    }
  }
}
