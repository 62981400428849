import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ContextService, CrispChatService, PlatformService, ProfileService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';

@Component({
  selector: 'app-school-settings-right-sidebar',
  templateUrl: './school-settings-right-sidebar.component.html',
  styleUrls: ['./school-settings-right-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolSettingsRightSidebarComponent extends ChangableComponent implements OnInit {
  constructor(
    public crispChat: CrispChatService,
    public contextService: ContextService,
    public profileService: ProfileService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {}

  public openCrispChat() {
    this.crispChat.openChat();
  }
}
