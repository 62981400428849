import { Component } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { MetaService, PlatformService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-schedule-page',
  templateUrl: './schedule-page.component.html',
  styleUrls: ['./schedule-page.component.scss'],
})
export class SchedulePageComponent extends DestroyableComponent {
  constructor(
    public deviceService: DeviceDetectorService,
    private router: Router,
    private meta: MetaService,
    protected platform: PlatformService,
  ) {
    super(platform);
  }

  ngOnInit() {
    this.updateMeta();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.updateMeta();
      });
  }

  public currentUrl() {
    return this.router.url;
  }

  /** Если понадобится выключать заголовок по каким либо улосвиям */
  public showTitle() {
    const urlArray = this.currentUrl().split('/');
    const code = urlArray[urlArray.length - 1];
    return code !== 'vacation';
  }

  public updateMeta() {
    const path = this.currentUrl().substring(1);
    this.meta.setTitle([path + '.meta-title']);
  }

  public pageTitle() {
    const urlArray = this.currentUrl().split('/');
    return 'schedule-calendar.title.' + urlArray[urlArray.length - 1];
  }
}
