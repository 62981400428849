import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { UserServiceForm3Module } from '@app/account/teacher-page/user-service-form-3/user-service-form-3.module';
import { FilterContentComponent } from '@app/library/panes/filter-content/filter-content.component';
import { LibraryServiceMiniComponent } from '@app/library/panes/my-library-services/library-service-mini/library-service-mini.component';
import { UserServiceCardFullModule } from '@app/meetings/user-service-card-full/user-service-card-full.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { NoContentStubComponent } from '@core/components/no-content-stub/no-content-stub.component';
import { UserServiceCard2Module } from '@core/components/service-card/user-service-card-2.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { OnUiButtonModule } from 'onclass-ui';
import { ProfileServicesComponent } from './profile-services.component';

@NgModule({
  declarations: [ProfileServicesComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    IconComponent,
    UserServiceCard2Module,
    UserServiceForm3Module,
    UserServiceCardFullModule,
    ModalModule,
    MobileModalModule,
    SpinnersModule,
    LibraryServiceMiniComponent,
    OnUiButtonModule,
    PipesModule,
    FilterContentComponent,
    NoContentStubComponent,
  ],
  exports: [ProfileServicesComponent],
})
export class ProfileServicesModule {}
