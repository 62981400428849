<div #playerContainer [attr.subject]="subjectId" [class.video_mobile]="deviceService.isMobile()">
  <div [id]="'player' + videoId"></div>
</div>

<div class="video-errors">
  <div *ngIf="isLessVideo" class="v-error">
    {{ 'profile-form.teaching.short-video' | translate }}
  </div>
  <div *ngIf="isBigVideo" class="v-error">
    {{ 'profile-form.teaching.long-video' | translate }}
  </div>
  <div *ngIf="isSameVideo" class="v-error">
    {{ 'profile-form.teaching.same-video' | translate }}
  </div>
</div>
