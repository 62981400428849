<ng-template [ngIf]="isStudy" [ngIfElse]="content">
  <div class="unit">
    <app-library-filter-widget></app-library-filter-widget>
  </div>

  <div class="unit">
    <app-best-entity-widget [displayMode]="'content'"></app-best-entity-widget>
  </div>

  <div class="unit">
    <app-best-entity-widget [displayMode]="'teachers'"></app-best-entity-widget>
  </div>
</ng-template>

<ng-template #content>
  <div class="unit">
    <app-user-widget [user]="user"></app-user-widget>
  </div>
</ng-template>


