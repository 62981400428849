import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { VacationFormComponent } from '@app/schedule-page/vacation-form/vacation-form.component';
import { VacationInformerComponent } from '@core/components/vacation-informer/vacation-informer.component';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiButtonModule, OnUiDateModule } from 'onclass-ui';

@NgModule({
  declarations: [VacationFormComponent],
  imports: [CommonModule, OnUiDateModule, OnUiButtonModule, TranslateModule, ReactiveFormsModule],
  exports: [VacationFormComponent],
})
export class VacationFormModule {}
