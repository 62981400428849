<section class="top-promo">
  <div class="container" id="container">

    <div class="data-wrap">
      <div class="text-block" id="text">
        <div class="title">
          {{'landings.home.top-promo-title' | translate}}
        </div>
        <div class="text">
          {{'landings.home.top-promo-text' | translate}}
        </div>

        <div class="btn-wrap">
          <a class="link"
             (click)="isAuth ? navigateToMain() : onBecomeTeacher($event, mainRoute, false)">
            {{'landings.home.top-promo-btn' | translate}}
          </a>
        </div>

        <div class="list">
          <ul>
            <li><span>{{'landings.home.top-promo-list1' | translate}}</span></li>
            <li><span>{{'landings.home.top-promo-list2' | translate}}</span></li>
            <li><span>{{'landings.home.top-promo-list3' | translate}}</span></li>
          </ul>
        </div>
      </div>


      <div class="image-block" id="video">
        <div class="image">
          <div class="video-wrap" id="video-holder">
            <video class="video" autoplay muted loop playsinline>
              <source src="/assets/images/landings/common/intro-video.mp4"
                      type="video/mp4"/>
            </video>

            <div *ngIf="showPlayBtn" class="play-btn" (click)="playVideo()">
              <app-icon width="100" height="100" icon="play-holder-invert"></app-icon>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
