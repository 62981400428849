import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { PlatformService } from '@app/core/services';
import { DestroyableComponent } from '@app/models/destroyable.component';
import { EContentPanelRoute } from 'lingo2-models';
import { BecomeTeacherEvent } from '../../../models';

@Component({
  selector: 'app-teacher',
  templateUrl: './teacher.component.html',
  styleUrls: ['./teacher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeacherComponent extends DestroyableComponent {
  @Output() becomeTeacher = new EventEmitter<BecomeTeacherEvent>();

  public constructor(protected readonly platform: PlatformService) {
    super(platform);
  }

  public mainRoute: string = EContentPanelRoute.main;

  public onBecomeTeacher(route = '', markAsTeacher = false) {
    /** @see JobPageComponent.onBecomeTeacher */
    this.becomeTeacher.emit({
      block: 'app-teacher',
      route: [route],
      markAsTeacher,
    });
  }
}
