<div class="card" [class.card_mobile]="deviceService.isMobile()">
  <div class="card__content">
    <form [formGroup]="form" novalidate autocomplete="off">
      <p class="label-title">{{ 'profile-form.education.title' | translate }}</p>
      <p class="hint-title"></p>
      <!-- FormArray: education_schools -->
      <div formArrayName="education_schools">
        <div *ngFor="let group of educationSchools.controls; let idx = index; trackBy: trackByFn" [formGroupName]="idx">

          <app-form-field class="field" [control]="form.controls.education_schools">

            <div class="profile-form-group" [class.form-group_mobile]="deviceService.isMobile()">
              <div class="profile-form-group__content">

                <div class="row">
                  <app-form-field class="field" [control]="group.controls.school_name">
                    <on-ui-input formControlName="school_name"
                                 data-test="school-name"
                                 class="col col_lg"
                                 [placeholder]="'profile-form.education.institution-name' | translate"
                                 [maxlength]="200">
                    </on-ui-input>
                  </app-form-field>

                  <app-form-field class="field" [control]="group.controls.end_year">
                    <on-ui-select class="col col_sm"
                                  formControlName="end_year"
                                  data-test="end-year"
                                  [error]="isInvalid(group, 'end_year')"
                                  [options]="yearsOptions"
                                  [placeholder]="'profile-form.education.graduation-year' | translate">
                    </on-ui-select>
                  </app-form-field>
                </div>

                <div class="row">
                  <app-form-field class="field" [control]="group.controls.department_name">
                    <on-ui-input formControlName="department_name"
                                 data-test="department-name"
                                 class="col col_lg"
                                 [placeholder]="'profile-form.education.faculty' | translate"
                                 [maxlength]="200">
                    </on-ui-input>
                  </app-form-field>
                </div>

              </div>

              <div class="profile-form-group__actions">
                <app-icon
                  class="trash-icon"
                  file="basket"
                  icon="basket-3"
                  [width]="30"
                  [height]="30"
                  (click)="removeEducationSchool(idx)"
                  [class.visibility-hidden]="educationSchools.controls.length === 1">
                </app-icon>
              </div>
            </div>
          </app-form-field>

        </div>

        <button class="add-input"
                data-test="add-education"
                (click)="addEducationSchool()" [disabled]="form.disabled">
          {{ 'profile-form.add-more' | translate }}
        </button>

      </div>

      <div formArrayName="education_docs" class="docs-form">
        <p class="label-title">{{ 'profile-form.education.certificates' | translate }}</p>
        <p class="hint-title">{{ 'profile-form.education.certificates-hint' | translate }}</p>
        <p class="hint-certificate">{{ 'profile-form.education.format-hint' | translate }}</p>
        <div class="attachment">
          <div class="attachment-scroll">
            <div class="attachment-list">
              <div *ngFor="let media of educationDocs.controls; let idx = index;"
                   class="attachment-item">
                <app-certificate-uploader
                  [formControlName]="idx"
                  (changed)="onChangedMedia($event, idx)">
                </app-certificate-uploader>
              </div>
            </div>
          </div>
        </div>
      </div>


    </form>
  </div>

  <div class="card__actions">
    <on-ui-button [state]="'default'"
                  class="primary outline margin-right-20"
                  label="profile-form.back"
                  data-test="profile-back"
                  (click)="navigateBack()">
    </on-ui-button>

    <on-ui-button [disabled]="form.invalid"
                  [state]="saveState"
                  data-test="profile-next"
                  class="primary"
                  label="profile-form.next"
                  (click)="navigateToNextStep()">
    </on-ui-button>
  </div>

  <!-- <pre>{{ form.value | json }}</pre> -->

</div>
