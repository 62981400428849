import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WithdrawMoneyFormModule } from '@app/account/components/withdraw-money-form/withdraw-money-form.module';
import { FinanceHistoryPageModule } from '@app/account/finance-page/finance-history-page/finance-history-page.module';
import { UserServiceForm3Module } from '@app/account/teacher-page/user-service-form-3/user-service-form-3.module';
import { EventsCalendarModule } from '@app/events-calendar/events-calendar.module';
import { LibraryServiceMiniComponent } from '@app/library/panes/my-library-services/library-service-mini/library-service-mini.component';
import { UserServiceCardFullModule } from '@app/meetings/user-service-card-full/user-service-card-full.module';
import { InviteToSchoolModule } from '@app/schools/invite-to-school/invite-to-school.module';
import { UserCardMiniModule } from '@app/users/user-card-mini/user-card-mini.module';
import { AlertPayoutComponent } from '@core/components/alert-payout/alert-payout.component';
import { ButtonFabModule } from '@core/components/button-fab/button-fab.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { UserServiceCard2Module } from '@core/components/service-card';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { PayoutCardModule } from '@shared/payout-card/payout-card.module';
import { OnUiButtonModule, OnUiCoverModule, OnUiTabsModule } from 'onclass-ui';
import { SchoolCalcTeacherCardComponent } from './school-calc-teacher-card/school-calc-teacher-card.component';
import { SchoolCalculationsComponent } from './school-calculations/school-calculations.component';
import { SchoolFormModule } from './school-form/school-form.module';
import { SchoolParticipantsComponent } from './school-participants/school-participants.component';
import { SchoolScheduleComponent } from './school-schedule/school-schedule.component';
import { SchoolSettingsAccountsComponent } from './school-settings-accounts/school-settings-accounts.component';
import { SchoolSettingsCardComponent } from './school-settings-card/school-settings-card.component';
import { SchoolSettingsClassesComponent } from './school-settings-classes/school-settings-classes.component';
import { SchoolSettingsMainComponent } from './school-settings-main/school-settings-main.component';
import { SchoolSettingsPageComponent } from './school-settings-page.component';
import { SchoolSettingsStatusComponent } from './school-settings-status/school-settings-status.component';
import { SchoolSettingsLeftSidebarComponent } from './sidebar/school-settings-left-sidebar.component';
import { SchoolSettingsRightSidebarComponent } from './sidebar/school-settings-right-sidebar.component';
import { AlertModule } from "../core/components/alert/alert.module";
import { FinanceCardCreateNewComponent } from "../account/finance-page/components/finance-card-create-new/finance-card-create-new.component";

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild([
        {
            path: '',
            pathMatch: 'full',
            redirectTo: 'main',
        },
        {
            path: ':step',
            component: SchoolSettingsPageComponent,
        },
    ]),
    InviteToSchoolModule,
    SchoolFormModule,
    ButtonFabModule,
    TranslateModule,
    FormsModule,
    FinanceHistoryPageModule,
    OnUiTabsModule,
    ModalModule,
    MobileModalModule,
    WithdrawMoneyFormModule,
    OnUiButtonModule,
    IconComponent,
    PayoutCardModule,
    SpinnersModule,
    UserServiceCard2Module,
    UserServiceForm3Module,
    UserServiceCardFullModule,
    OnUiCoverModule,
    UserCardMiniModule,
    LibraryServiceMiniComponent,
    EventsCalendarModule,
    AlertPayoutComponent,
    AlertModule,
    FinanceCardCreateNewComponent,
],
  declarations: [
    SchoolSettingsPageComponent,
    SchoolSettingsAccountsComponent,
    SchoolSettingsCardComponent,
    SchoolSettingsClassesComponent,
    SchoolSettingsStatusComponent,
    SchoolSettingsLeftSidebarComponent,
    SchoolSettingsRightSidebarComponent,
    SchoolSettingsMainComponent,
    SchoolParticipantsComponent,
    SchoolCalculationsComponent,
    SchoolScheduleComponent,
    SchoolCalcTeacherCardComponent,
    SchoolSettingsPageComponent,
  ],
})
export class SchoolSettingsModule {}
