<ng-template [ngIf]="!videoUrl">
<div class="image-uploader">
  <div class="dropzone dropzone-container"
       [dropzone]="dropzoneConfig"
       (uploadProgress)="onUploadProgress($event)"
       (sending)="onUploadStart()"
       (error)="onUploadError()"
       (success)="onUploadSuccess($event)">
    <div class="dz-default dz-message">
      <ng-template [ngIf]="!video_uploading">
        <div class="placeholder">
          <app-icon class="placeholder-icon" file="upload" icon="video-placeholder"></app-icon>
          <div class="placeholder-text-wrap">
            <p class="placeholder-text">
              {{ 'meetings.dz-placeholder-text' | translate }}
              <span class="action">
              {{ 'meetings.dz-placeholder-link1' | translate }}
            </span>
            </p>&ensp;
          </div>
        </div>
      </ng-template>

      <ng-template [ngIf]="video_uploading">
        <spinner1></spinner1>
      </ng-template>
    </div>
  </div>
</div>
</ng-template>

<ng-template [ngIf]="videoUrl">
  <div class="video-wrap">
    <video class="video" controls playsinline height="100%" width="100%">
      <source [src]="videoUrl" type='video/mp4'>
    </video>

    <p class="delete-link" (click)="removeVideo($event)">{{'profile-form.teaching.delete' | translate}}</p>
  </div>
</ng-template>

<on-ui-progress-bar [value]="percent"></on-ui-progress-bar>

