import { Component, OnInit } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';

@Component({
  selector: 'app-howgetnew',
  templateUrl: './howgetnew.component.html',
  styleUrls: ['./howgetnew.component.scss'],
})
export class HowgetnewComponent extends DestroyableComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
