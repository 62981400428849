import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy } from '@angular/core';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { IBlockOptions } from '../../../models';

@Component({
  selector: 'app-whybetter',
  templateUrl: './whybetter.component.html',
  styleUrls: ['./whybetter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WhybetterComponent extends ChangableComponent implements AfterViewInit, OnDestroy {
  @Input() options: IBlockOptions;
  public slidesList = [
    {
      title: 'job-landing.whybetter.tabtitle1',
      data: [
        {
          icon: this.assetsUrl('/assets/images/landings/job/teacher-icon-1.png'),
          title: 'job-landing.whybetter.slide1title1',
          text: 'job-landing.whybetter.slide1text1',
        },
        {
          icon: this.assetsUrl('/assets/images/landings/job/teacher-icon-2.png'),
          title: 'job-landing.whybetter.slide1title2',
          text: 'job-landing.whybetter.slide1text2',
        },
        {
          icon: this.assetsUrl('/assets/images/landings/job/teacher-icon-3.png'),
          title: 'job-landing.whybetter.slide1title3',
          text: 'job-landing.whybetter.slide1text3',
        },
      ],
    },
    {
      title: 'job-landing.whybetter.tabtitle2',
      data: [
        {
          icon: this.assetsUrl('/assets/images/landings/job/methodist-icon-1.png'),
          title: 'job-landing.whybetter.slide2title1',
          text: 'job-landing.whybetter.slide2text1',
        },
        {
          icon: this.assetsUrl('/assets/images/landings/job/methodist-icon-2.png'),
          title: 'job-landing.whybetter.slide2title2',
          text: 'job-landing.whybetter.slide2text2',
        },
        {
          icon: this.assetsUrl('/assets/images/landings/job/methodist-icon-3.png'),
          title: 'job-landing.whybetter.slide2title3',
          text: 'job-landing.whybetter.slide2text3',
        },
      ],
    },
    {
      title: 'job-landing.whybetter.tabtitle3',
      data: [
        {
          icon: this.assetsUrl('/assets/images/landings/job/school-icon-1.png'),
          title: 'job-landing.whybetter.slide3title1',
          text: 'job-landing.whybetter.slide3text1',
        },
        {
          icon: this.assetsUrl('/assets/images/landings/job/school-icon-2.png'),
          title: 'job-landing.whybetter.slide3title2',
          text: 'job-landing.whybetter.slide3text2',
        },
        {
          icon: this.assetsUrl('/assets/images/landings/job/school-icon-3.png'),
          title: 'job-landing.whybetter.slide3title3',
          text: 'job-landing.whybetter.slide3text3',
        },
      ],
    },
  ];
  public selectedIndex = 0;

  private timerId;
  private deadHandTimerId;

  constructor(protected readonly cdr: ChangeDetectorRef, protected readonly platform: PlatformService) {
    super(cdr, platform);
  }

  ngAfterViewInit() {}

  public onSelectItem(index: number) {
    this.selectedIndex = index;
    this.markForCheck();
  }

  public onMoveLeft() {
    if (this.selectedIndex <= 0) {
      this.selectedIndex = this.slidesList.length - 1;
      this.markForCheck();
      return;
    }
    this.selectedIndex -= 1;
    this.markForCheck();
  }

  public onMoveRight() {
    if (this.selectedIndex >= this.slidesList.length - 1) {
      this.selectedIndex = 0;
      this.markForCheck();
      return;
    }
    this.selectedIndex += 1;
    this.markForCheck();
  }

  public trackByFn(index) {
    return index;
  }
}
