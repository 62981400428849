import { Injectable } from '@angular/core';
import { PlatformService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class LibraryFormService extends DestroyableComponent {
  private termSubject = this.register(new BehaviorSubject<string>(null));
  public term$ = this.termSubject.asObservable();
  private _term: string;

  constructor(protected readonly platform: PlatformService) {
    super(platform);
    this.loadTerm();
  }

  public get term(): string {
    return this._term;
  }

  public set term(term: string) {
    this._term = term;
    this.termSubject.next(this._term);
    // if (this.isBrowser && (localStorage||false)) {
    //   localStorage.setItem('APP__LIBRARY_TERM', this._term);
    // }
  }

  private loadTerm() {
    // if (this.isBrowser && (localStorage||false)) {
    //   this._term = localStorage.getItem('APP__LIBRARY_TERM');
    //   this.termSubject.next(this._term);
    // }
    this._term = '';
    this.termSubject.next(this._term);
  }
}
