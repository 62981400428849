import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SchoolSidebarWidgetComponent } from '@app/account/components/school-sidebar-widget/school-sidebar-widget.component';
import { PaymentCheckoutWizardModule } from '@app/account/shared/payment-checkout-wizard/payment-checkout-wizard.module';
import { UserServiceCardFullModule } from '@app/meetings/user-service-card-full/user-service-card-full.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { DateFnsModule } from 'lingo2-ngx-date-fns';
import { OnUiButtonModule, OnUiCoverModule, OnUiPopoverModule } from 'onclass-ui';
import { AccountSidebarComponent } from './components/account-sidebar/account-sidebar.component';
import { ProfileSidebarWidgetComponent } from './components/profile-sidebar-widget/profile-sidebar-widget.component';
import { UserWidgetComponent } from './components/user-widget/user-widget.component';

@NgModule({
  imports: [
    CommonModule,
    DateFnsModule,
    IconComponent,
    MobileModalModule,
    ModalModule,
    OnUiButtonModule,
    OnUiCoverModule,
    OnUiPopoverModule,
    PaymentCheckoutWizardModule,
    PipesModule,
    TranslateModule,
    UserServiceCardFullModule,
    UserWidgetComponent,
  ],
  exports: [AccountSidebarComponent, ProfileSidebarWidgetComponent, SchoolSidebarWidgetComponent],
  declarations: [AccountSidebarComponent, ProfileSidebarWidgetComponent, SchoolSidebarWidgetComponent],
  providers: [],
})
export class AccountModule {}
