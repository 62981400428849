import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AccountService, FilesService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { CSchool, ESchoolParticipantRole, FindAccountStrategyEnum, ImageSizeEnum, User } from 'lingo2-models';
import { OnUiCover } from 'onclass-ui';
import { takeUntil } from 'rxjs/operators';
import { UsersBlockType } from '../school-participants-widgets/school-participants-widgets.component';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";
import { ChatService } from '@app/core/services/chat.service';

@Component({
  selector: 'app-school-owners-widgets',
  templateUrl: './school-owners-widgets.component.html',
  styleUrls: ['./school-owners-widgets.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolOwnersWidgetsComponent extends ChangableComponent implements OnInit {
  @Input() set school(school: CSchool) {
    this._school = school;
    this.init();
  }

  private _school: CSchool;

  public users: UsersBlockType = {
    items: [],
    loading: false,
    loaded: false,
    filter: {
      use: FindAccountStrategyEnum.school,
      school_roles: [ESchoolParticipantRole.owner],
    },
    pagination: {
      page: 1,
      pageSize: 20,
    },
  };

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public chatService: ChatService,
    private store: Store,
    private accountService: AccountService,
    protected readonly cdr: ChangeDetectorRef) {
    super(cdr);
  }

  ngOnInit(): void {}

  public init() {
    this.users.filter.school_id = [this._school?.id];

    this.accountService
      .findAccounts(this.users.filter, this.users.pagination)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (data) => {
          this.users.items = data.results;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });
  }

  public cover(user: User): OnUiCover {
    return {
      img: FilesService.getFileUrlBySize(user?.userpic_id, ImageSizeEnum.sm),
      title: AccountService.getUserFullName(user),
      form: 'circle',
      type: 'avatar',
    };
  }

  public fullName(owner: User): string {
    return AccountService.getUserFullName(owner);
  }

  public onOpenChat(id: string) {
    this.chatService.openPrivateChat(id);
  }

  public schoolParticipantTrackBy(index, owner: User): string {
    return owner.id;
  }
}
