<div class="schedule-nav" [class.mobile]="deviceService.isMobile()">
  <ul class="tabs">
    <ng-template ngFor let-item [ngForOf]="menuList">
      <li class="categories-item">
        <app-categories-item [panel]="item"
                             [link]="link(item)"
                             displayMode="simple">
        </app-categories-item>
      </li>
    </ng-template>
  </ul>
</div>

