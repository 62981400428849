import { Component, OnInit } from '@angular/core';

export interface FakeSlider {
  text: string;
  backgroundColor: string;
}

@Component({
  selector: 'app-how-study',
  templateUrl: './how-study.component.html',
  styleUrls: ['./how-study.component.scss'],
})
export class HowStudyComponent implements OnInit {
  public sliderItemList: FakeSlider[];

  constructor() {}

  ngOnInit(): void {
    this.sliderItemList = [
      {
        text: 'landings.home.how-study-slide1',
        backgroundColor: '#EDA0A8',
      },
      {
        text: 'landings.home.how-study-slide2',
        backgroundColor: '#FDBD14',
      },
      {
        text: 'landings.home.how-study-slide3',
        backgroundColor: '#4DC0CF',
      },
    ];
  }

  public trackByFn(index) {
    return index;
  }
}
