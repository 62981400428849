import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { AccountSharedModule } from '@app/account/account-shared.module';
import { PaymentCheckoutWizardModule } from '@app/account/shared/payment-checkout-wizard/payment-checkout-wizard.module';
import { loadMe } from '@app/store/actions/profile.actions';
import { TeacherBookingSurfaceBlockComponent } from '@app/teachers/teacher-card/teacher-booking-surface-block/teacher-booking-surface-block.component';
import { IStatsList } from '@app/teachers/teacher-card/teacher-card-variables';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { SubscribeComponent } from '@core/components/subscribe/subscribe.component';
import {
  AccountService,
  AuthService,
  ConfigService,
  ContentService,
  FeaturesService,
  FilesService,
  PlatformService,
} from '@core/services';
import { BillingV2Service } from '@core/services/lingo2-account/billing-v2.service';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationDialogsService } from '@shared/application-dialogs';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { IconComponent } from '@shared/icon/icon.component';
import { RatingComponent } from '@shared/rating/rating.component';
import { loadFilteredLanguages, loadSubjects } from '@store/actions/data.actions';
import { CLibraryFilterData } from '@store/models';
import { getLibraryRouteData } from '@store/reducers/library.reducer';
import { getMe } from '@store/reducers/profile.reducer';
import { differenceInMinutes } from 'date-fns';
import {
  Country,
  EContentPanelRoute,
  IAccountStats,
  ImageSizeEnum,
  ITeachingSubject,
  Language,
  otherSubjectId,
  Subject as BaseSubject,
  SubjectsRegistry,
  TenantEnum,
  User,
  UserProfile,
} from 'lingo2-models';
import { ClipboardModule } from 'ngx-clipboard';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonModule, OnUiCover, OnUiCoverModule } from 'onclass-ui';
import { combineLatest, Observable, of, timer } from 'rxjs';
import { map, switchMap, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-user-widget',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    OnUiButtonModule,
    OnUiCoverModule,
    RouterModule,
    SubscribeComponent,
    ModalModule,
    MobileModalModule,
    IconComponent,
    AccountSharedModule,
    RatingComponent,
    ClipboardModule,
    PaymentCheckoutWizardModule,
    TeacherBookingSurfaceBlockComponent,
  ],
  templateUrl: './user-widget.component.html',
  styleUrls: ['./user-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UserWidgetComponent extends ChangableComponent implements OnInit {
  @ViewChild('fixedWidget', { static: false }) public fixedWidget: ElementRef;

  @Input() public withoutCover: boolean;
  @Input() public set user(user: User) {
    this._user = user;
    this.prepareUser();
  }
  public get user(): User {
    return this._user;
  }
  public get me(): User {
    return this._me;
  }
  public profileUrl: string[];
  public profileLink: string;
  public fullName: string;
  public statusOnline: boolean;
  public country: Country;
  public profile: Partial<UserProfile>;
  public teachingSubject: ITeachingSubject;
  public stats: Partial<IAccountStats>;
  public speakLanguages: Language[];
  public statsList: IStatsList[] = [];
  public isSubscribed: boolean;
  public isMe: boolean;
  public isGuest: boolean;
  public checkoutModalOpen = false;
  public teacherModalOpen = false;
  public avatar: OnUiCover;
  public canSignup$: Observable<boolean>;
  public profileVerified: boolean;
  public copied: boolean;

  private _user: User;
  private _me: User;
  private _languages: Language[];
  private _subjects: SubjectsRegistry;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private features: FeaturesService,
    private authService: AuthService,
    private accountService: AccountService,
    private billingV2Service: BillingV2Service,
    private configService: ConfigService,
    private contentService: ContentService,
    private store: Store,
    protected dialogsService: ApplicationDialogsService,
    protected cdr: ChangeDetectorRef,
    protected platform: PlatformService,
  ) {
    super(cdr, platform);

    this.canSignup$ = this.isBrowser
      ? timer(0, 60 * 1000).pipe(map(() => this.latest_applicable_date?.getTime() > Date.now()))
      : of(true).pipe(map(() => this.latest_applicable_date?.getTime() > Date.now()));
  }

  public ngOnInit() {
    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (me) => {
          this._me = me;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadMe());
        },
      });

    if (!this.user) {
      this.store
        .select(getLibraryRouteData)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (res) => {
            if (res.baseRoute === EContentPanelRoute.lessons) {
              this.getTeacherBtLesson(res);
              // eslint-disable-next-line @typescript-eslint/no-unsafe-enum-comparison
            } else if (res.baseRoute.split('/')[0] === EContentPanelRoute['my-classes']) {
              this._user = this.me;
              this.prepareUser();
            }
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'PROFILE-PROBLEM');
          },
        });
    }
  }

  public getTeacherBtLesson(res: CLibraryFilterData) {
    this.contentService
      .getContentBySlug(res.url)
      .pipe(switchMap((content) => this.accountService.getUserById(content.author_id, 'LOAD_TIME')))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (user) => {
          this._user = user;
          this.prepareUser();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public isMeVersion() {
    return this.features.tenant === TenantEnum.onclass_me;
  }

  public copy() {
    this.copied = true;

    this.setTimeout(() => {
      this.copied = false;
      this.detectChanges();
    }, 1000);
  }

  private prepareUser() {
    if (!this.user) {
      return;
    }

    this.isGuest = !this.authService.isAuthenticated;
    this.isMe = this.me?.id === this._user?.id;
    this.profileUrl = AccountService.accountRoute(this._user);
    this.profileLink = window.location.host + AccountService.accountRoute(this._user).join('/');
    this.fullName = AccountService.getUserFullName(this._user);
    this.statusOnline = differenceInMinutes(new Date(), new Date(this._user?.last_active_at)) <= 5;
    this.country = this._user?.country;
    this.profile = this._user?.profile;
    this.profileVerified = this.user?.teacher_profile_verified;
    this.stats = this._user?.stats;
    this.statsList = [
      {
        name: 'lessons_count',
        icon: 'stat-class',
        color: '#A4B0C3',
        title: 'teacher-card.stats.classes',
        value: this.stats?.meetings_count || 0,
      },
      {
        name: 'students_count',
        icon: 'stat-students',
        color: '#A4B0C3',
        title: 'teacher-card.stats.students',
        value: this.stats?.students_count || 0,
      },
    ];
    this.avatar = {
      type: 'avatar',
      img: FilesService.getFileUrlBySize(this.user.userpic_id, ImageSizeEnum.lg),
      title: this.fullName,
      link: this.profileUrl.join('/'),
      form: 'circle',
    };

    combineLatest([this.configService.languages$, this.configService.subjectsV2$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([languages, subjects]) => {
          this._languages = languages;
          this._subjects = subjects;
          this.speakLanguages = this._languages.filter(
            (_l) => !!this._user?.profile?.spoken_languages.find((_spokenL) => +_spokenL.language_id === +_l.id),
          );
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadFilteredLanguages({ locale: this.me.ui_language }));
          this.store.dispatch(loadSubjects({ locale: this.me.ui_language, force: true }));
        },
      });

    this.accountService
      .getIsSubscribed(this._user.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (result) => {
          this.isSubscribed = result;
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.isSubscribed = false;
        },
        complete: () => {
          this.detectChanges();
        },
      });
    this.detectChanges();
  }

  public get getSubjectId() {
    return this._user?.profile.teaching_subjects[0]?.subject_id;
  }

  public findSubjectById(teaching: ITeachingSubject): BaseSubject {
    if (teaching?.subject_id === otherSubjectId) {
      return {
        id: +teaching.subject_other_id,
        code: 'other...',
        title: teaching.subject_other_name,
        language_id: 0,
      } as BaseSubject;
    }

    return [
      ...this._subjects.native,
      ...this._subjects.foreign,
      ...this._subjects.non_categorized,
      ...this._subjects.user,
      ...this._subjects.recent,
      ...this._subjects.other,
    ].find((_s) => +_s.id === +teaching?.subject_id);
  }

  public getFormattedPrice(): Observable<string> {
    return this.billingV2Service.formatAmount$(this.stats?.min_price_tier);
  }

  public goSignup() {
    if (this._user.id === this._me?.id) {
      return;
    }

    if (!this.isGuest) {
      this.openCheckoutModal();
    } else {
      this.authService.showAuthModal(() => this.openCheckoutModal(), {
        caller: 'app-user-widget',
        reason: 'purchase service',
      });
    }
  }
  protected get latest_applicable_date(): Date {
    return this._user?.stats?.latest_applicable_date ? new Date(this._user?.stats?.latest_applicable_date) : null;
  }

  public closeCheckoutModal() {
    this.checkoutModalOpen = false;
    this.detectChanges();
  }

  private openCheckoutModal() {
    this.checkoutModalOpen = true;
    this.dialogsService.openUserServiceCheckoutWizard({
      teacherId: this._user.id,
    });
    this.detectChanges();
  }

  public trackByFn(index) {
    return index;
  }
}
