<section class="whoisneed">

  <div class="whoisneed-container">
    <div class="whoisneed-title">
      {{'job-landing.whoisneed.title' | translate}}
    </div>

    <div class="whoisneed-wrap">
      <div class="whoisneed-list">
        <div class="whoisneed-item">
          <div class="whoisneed-item-text-wrap">
            <div class="whoisneed-item-title">{{'job-landing.whoisneed.subtitle1' | translate}}</div>
            <div class="whoisneed-item-text">{{'job-landing.whoisneed.subtext1' | translate}}</div>
            <a class="whoisneed-item-link">{{'job-landing.whoisneed.more' | translate}}</a>
          </div>
        </div>
        <div class="whoisneed-item">
          <div class="whoisneed-item-text-wrap">
            <div class="whoisneed-item-title">{{'job-landing.whoisneed.subtitle2' | translate}}</div>
            <div class="whoisneed-item-text">{{'job-landing.whoisneed.subtext2' | translate}}</div>
            <a class="whoisneed-item-link">{{'job-landing.whoisneed.more' | translate}}</a>
          </div>
        </div>
        <div class="whoisneed-item">
          <div class="whoisneed-item-text-wrap">
            <div class="whoisneed-item-title">{{'job-landing.whoisneed.subtitle3' | translate}}</div>
            <div class="whoisneed-item-text">{{'job-landing.whoisneed.subtext3' | translate}}</div>
            <a class="whoisneed-item-link">{{'job-landing.whoisneed.more' | translate}}</a>
          </div>
        </div>
      </div>
    </div>
    <div class="whoisneed-background">
      <div class="whoisneed-background-placeholder">
        <span>{{'job-landing.whoisneed.imagetext' | translate}}</span>
      </div>
    </div>
  </div>
</section>
