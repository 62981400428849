import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { MeetingsService, PlatformService, RequestService } from '@core/services';
import { QRCodeModule } from '@le2xx/angularx-qrcode';
import { ChangableComponent } from '@models/changable.component';
import { TranslateModule } from '@ngx-translate/core';
import { Meeting, Router as ContentRouter } from 'lingo2-models';
import { takeUntil } from 'rxjs';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-meeting-join-widget',
  standalone: true,
  imports: [CommonModule, QRCodeModule, TranslateModule, RouterModule],
  templateUrl: './meeting-join-widget.component.html',
  styleUrls: ['./meeting-join-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MeetingJoinWidgetComponent extends ChangableComponent implements OnInit {
  public meeting: Meeting;
  public link: string;
  public copied: boolean;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private request: RequestService,
    private route: ActivatedRoute,
    private meetingsService: MeetingsService,
    private router: Router,
    protected cdr: ChangeDetectorRef,
    protected platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {
    const [slug, ...others] = this.router.url.split('/').reverse();
    this.loadMeetingBySlug(slug);
  }

  public get joinMeetingUrl(): string {
    if (this.meeting) {
      return this.request.host + this.link;
    }
  }

  /**
   * Загрузка митинга по параметрам из URL
   */
  private loadMeetingBySlug(meeting_slug: string) {
    if (!meeting_slug) {
      return;
    }
    this.meetingsService
      .getMeetingBySlug(meeting_slug)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (meeting) => {
          this.meeting = meeting;
          this.link = this.router.createUrlTree(ContentRouter.meetingJoinRoute(this.meeting)).toString();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });
  }
}
