<footer class="mobile-footer">
  <a *ngFor="let item of menuItems; trackBy: trackByFn"
     class="nav-item"
     itemprop="url"
     [routerLink]="item.url"
     routerLinkActive="active">
    <app-icon class="nav-item-icon" [ngClass]="item.id" file="header" [icon]="item.icon"></app-icon>
    {{ item.label | translate }}
  </a>
</footer>

<div class="gap"></div>
