<section class="school-owners-widgets">
  <div class="title-wrap">
    <h3 class="title">{{ 'school-owners.header' | translate }}</h3>
    <span class="count-owners">{{ users.items?.length }}</span>
  </div>

  <div class="owners">
    <div *ngFor="let participant of users.items; trackBy: schoolParticipantTrackBy" class="owner">
      <div class="avatar-wrap">
        <on-ui-cover [cover]="cover(participant)"></on-ui-cover>
      </div>
      <div class="info">
        <span class="name">{{ fullName(participant) }}</span>
        <span class="message-action normal" (click)="onOpenChat(participant.id)">{{ 'school-owners.message' | translate }}</span>
      </div>
    </div>
  </div>
</section>
