import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  PLATFORM_ID,
} from '@angular/core';
import { logger } from '@app/core/helpers/logger';
import { ChangableComponent } from '@app/models/changable.component';
import { takeUntil } from 'rxjs/operators';
import { CrispChatService, PlatformService } from '../../core/services';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-support-page',
  templateUrl: './support-page.component.html',
  styleUrls: ['./support-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SupportPageComponent extends ChangableComponent implements OnInit, OnDestroy {
  public opened = false;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private crispChat: CrispChatService,
    @Inject(PLATFORM_ID) private platformId: object,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    if (this.isBrowser) {
      this.crispChat.enabled$.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (enabled) => {
          if (enabled) {
            this.openCrispChat();
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });

      this.crispChat.opened$.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (opened) => {
          if (this.opened && !opened) {
            // был открыт, затем закрыли
            logger.log('TODO redirect to ...?');
          }
          this.opened = opened;
          this.markForCheck();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
          this.opened = false;
        }
      });
    }
  }

  public openCrispChat() {
    this.crispChat.openChat();
  }
}
