<div class="main" [class.mobile]="deviceService.isMobile()" [class.teacher]="isTeacher">
  <div class="left">
    <div class="avatar-block"
         *ngIf="!showCameraScreen"
         [class.avatar-block_disabled]="isDisabled">
      <div class="dropzone dropzone-container" #dropzone [dropzone]="dropzoneConfig" (init)="onUploadInit($event)"
           (sending)="onUploadStart($event)" (error)="onUploadError($event)" (success)="onUploadSuccess($event)">
        <div class="dz-default dz-message">
          <app-icon *ngIf="!userpic && !userpic_loading" icon="guest" class="placeholder"></app-icon>
          <spinner1 *ngIf="userpic_loading"></spinner1>
          <img *ngIf="userpic" [src]="userpic" alt=""/>
        </div>
      </div>
      <div class="avatar-tools">
        <div class="tools-item" (click)="dropzone.click()">{{ 'profile-form.about.upload' | translate }}</div>
        <div class="tools-item crop"
             *ngIf="userpic" (click)="onCrop()"> {{ 'meetings.select-area' | translate }} </div>
        <div *ngIf="userpic" class="tools-item" (click)="removeUserPic($event)">
          {{ 'profile-form.remove' | translate }}
        </div>
      </div>
    </div>
  </div>
</div>
<app-image-crop
  *ngIf="showCropImage"
  [imageURL]="imgWide"
  [aspectRatio]="'wide'"
  (changeImage)="onImageChange($event)">
</app-image-crop>
