import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AccountModule } from '@app/account/account.module';
import { LibraryPanesModule } from '@app/library/panes/library-panes.module';
import { SchoolFeedModule } from '@app/schools/school-feed/school-feed.module';
import { SchoolMainComponent } from '@app/schools/school-main/school-main.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { ClipboardModule } from 'ngx-clipboard';
import { OnUiButtonModule, OnUiCoverModule } from 'onclass-ui';
import { SchoolPageComponent } from './school-page/school-page.component';
import { SchoolOnboardingWidgetComponent } from './schools-sidebar/school-onboarding-widget/school-onboarding-widget.component';
import { SchoolOwnersWidgetsComponent } from './schools-sidebar/school-owners-widgets/school-owners-widgets.component';
import { SchoolParticipantsWidgetsComponent } from './schools-sidebar/school-participants-widgets/school-participants-widgets.component';
import { SchoolsSidebarComponent } from './schools-sidebar/schools-sidebar.component';

const routes: Routes = [
  {
    path: '',
    component: SchoolPageComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'main',
      },
      {
        path: ':tab',
        component: SchoolPageComponent,
      },
    ],
  },
  {
    path: '',
    outlet: 'right-sidebar-top',
    component: SchoolsSidebarComponent,
  },
];

@NgModule({
  declarations: [
    SchoolMainComponent,
    SchoolOnboardingWidgetComponent,
    SchoolOwnersWidgetsComponent,
    SchoolParticipantsWidgetsComponent,
    SchoolsSidebarComponent,
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    ClipboardModule,
    LibraryPanesModule,
    OnUiButtonModule,
    SchoolFeedModule,
    TranslateModule,
    OnUiCoverModule,
    AccountModule,
    IconComponent,
  ],
})
export class SchoolsModule {}
