import { AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BecomeTeacherEvent } from '@app/landings/job/models';
import { AuthModalModeType, AuthModalOptions, AuthService, PlatformService, ScreenService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { EContentPanelRoute } from 'lingo2-models';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-top-promo',
  templateUrl: './top-promo.component.html',
  styleUrls: ['./top-promo.component.scss'],
})
export class TopPromoComponent extends DestroyableComponent implements OnInit, AfterViewInit {
  @Output() becomeTeacher = new EventEmitter<BecomeTeacherEvent>();

  public isAuth: boolean = this.authService.isAuthenticated;
  public width = 0;
  public showPlayBtn = false;
  public mainRoute: string = EContentPanelRoute.main;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    private authService: AuthService,
    private screenService: ScreenService,
    private cdr: ChangeDetectorRef,
    private router: Router,
    protected readonly platform: PlatformService,
  ) {
    super(platform);
  }

  ngOnInit() {
    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (width) => {
        this.width = width;
        this.recalcBlockHeigth();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.width = window.innerWidth;
        this.recalcBlockHeigth();
      },
    });
  }

  ngAfterViewInit() {
    this.recalcBlockHeigth();
    if (this.isBrowser) {
      this.startAutoPlay();
    }
  }

  private startAutoPlay() {
    const video = document.querySelector('video');
    video.muted = true;
    const promise = video.play();

    if (promise !== undefined) {
      promise.then().catch((error) => {
        this.showPlayBtn = true;
        this.cdr.detectChanges();
      });
    }
  }

  public playVideo() {
    this.startAutoPlay();
  }

  private recalcBlockHeigth() {
    const containerBlock = document.getElementById('container');
    const textBlock = document.getElementById('text');
    const videoBlock = document.getElementById('video');

    if (this.width > 768) {
      if (videoBlock.clientHeight > textBlock.clientHeight) {
        containerBlock.style.height = String(videoBlock.clientHeight) + 'px';
      } else {
        containerBlock.style.height = String(textBlock.clientHeight) + 'px';
      }
    }
  }

  public navigateToMain() {
    this.router.navigate([EContentPanelRoute.main]).then(() => true);
  }

  public authorize(mode: AuthModalModeType) {
    this.authService.showAuthModal((u) => {}, { view: mode } as AuthModalOptions);
  }

  public onBecomeTeacher(e: Event, route = '', markAsTeacher = false) {
    // нативная навигация подавляется, а обработка в CommonLandingComponent.onBecomeTeacher
    /** @see CommonLandingComponent.onBecomeTeacher */

    e.preventDefault();
    e.cancelBubble = true;

    this.becomeTeacher.emit({
      block: 'student',
      route: [route],
      markAsTeacher,
    });
  }
}
