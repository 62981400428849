import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { AccountModule } from '@app/account/account.module';
import { UserWidgetComponent } from '@app/account/components/user-widget/user-widget.component';
import { ModalModule } from '@core/components/modal/modal.module';
import { PaginationModule } from '@core/components/pagination/pagination.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { CoreModule } from '@core/core.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { reducers, classroomsFeatureKey } from './_store';
import { ClassroomEffects } from './_store/classroom';
import { ClassroomsListEffects } from './_store/classrooms';
import { ClassroomMeetingsEffects } from './_store/meetings';
import { ClassroomParticipantsEffects } from './_store/participants';
import { ClassroomsRoutingModule } from './classrooms-routing.module';
import { ClassroomsSidebarComponent } from './components/classrooms-sidebar/classrooms-sidebar.component';

// Store
// TODO: переместить все эффекты в index

@NgModule({
  declarations: [ClassroomsSidebarComponent],
  imports: [
    CommonModule,
    CoreModule,
    TranslateModule,
    ReactiveFormsModule,
    StoreModule.forFeature(classroomsFeatureKey, reducers),
    EffectsModule.forFeature([
      ClassroomEffects,
      ClassroomMeetingsEffects,
      ClassroomParticipantsEffects,
      ClassroomsListEffects,
    ]),
    SpinnersModule,
    PaginationModule,
    PipesModule,
    LazyLoadImageModule,
    ModalModule,
    ClassroomsRoutingModule,
    AccountModule,
    UserWidgetComponent,
  ],
  exports: [],
})
export class ClassroomsModule {}
