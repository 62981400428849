<div class="form" [formGroup]="form">
  <div class="field field-id"><input formControlName="id" size="40" placeholder="id"></div>
  <div class="field field-code"><input formControlName="code" size="10" placeholder="code"></div>

  <on-ui-button type="text"
                [state]="saveState"
                class="primary"
                [label]="'Apply'"
                (click)="doSignin()">
  </on-ui-button>
</div>
