import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DomSanitizer } from '@angular/platform-browser';
import { logger } from '@app/core/helpers/logger';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { ConfigService, DropzoneConfig, FilesService, IFileType } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { IconComponent } from '@shared/icon/icon.component';
import { FilePurposeEnum } from 'lingo2-models';
import { DropzoneConfigInterface, DropzoneModule } from 'ngx-dropzone-wrapper';
import { OnUiProgressBarModule } from 'onclass-ui';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-video-uploader',
  standalone: true,
  imports: [CommonModule, TranslateModule, DropzoneModule, SpinnersModule, OnUiProgressBarModule, IconComponent],
  templateUrl: './video-uploader.component.html',
  styleUrls: ['./video-uploader.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VideoUploaderComponent),
      multi: true,
    },
  ],
})
export class VideoUploaderComponent extends ChangableComponent implements OnInit, ControlValueAccessor {
  @Output() changed = new EventEmitter<string>();

  public value: string;
  public isDisabled: boolean;
  public dropzoneConfig: DropzoneConfigInterface = {
    acceptedFiles: '.mp4,.mov',
    paramName: 'file',
  };
  public video_file: IFileType;
  public video_uploading = false;
  public filePurpose = FilePurposeEnum.content;
  public percent = 0;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    protected configService: ConfigService,
    protected filesService: FilesService,
    protected dzConfig: DropzoneConfig,
    protected sanitizer: DomSanitizer,
    protected store: Store,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  public get videoUrl(): string {
    if (!this.video_file) {
      return null;
    }
    return this.video_file.storage_file_url;
  }

  ngOnInit(): void {
    this.configureDropzone();
  }

  writeValue(value: string): void {
    this.value = value;
    if (this.value) {
      this.loadVideo(this.value);
    } else {
      this.video_file = null;
    }
  }

  registerOnChange(fn): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange(value: string): void {
    this.value = value;
    this.propagateChange(value);
    this.changed.emit(value);
    this.detectChanges();
  }

  public onUploadProgress(args: any): void {
    logger.log('dropzone:onUploadProgress', args);
    const [, file] = args;
    this.percent = file;
  }

  public onUploadStart() {
    this.video_uploading = true;
  }

  public onUploadError() {
    this.video_uploading = false;
  }

  public onUploadSuccess(args): void {
    this.video_uploading = false;
    const [, video] = args;
    this.video_file = video;
    this.onChange(video.id);
  }

  public onClick(args) {
    args.preventDefault();
    args.cancelBubble = true;
  }

  public removeVideo(event: Event) {
    this.onClick(event);
    this.video_file = null;
    this.onChange(null);
  }

  public setVideo(video: IFileType) {
    this.video_file = video;
    this.onChange(video.id);
  }

  protected configureDropzone() {
    this.dropzoneConfig = this.dzConfig.combine(this.dropzoneConfig, {
      url: [this.dzConfig.url, '/file/upload+process?purpose=', FilePurposeEnum.profileVideo].join(''),
    });
  }

  protected loadVideo(id: string) {
    if (!id || (this.video_file && this.video_file.id === id)) {
      return;
    }
    this.filesService
      .getFileById(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (file) => {
          this.video_file = file;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-FILE');
          this.video_file = null;
        },
      });
  }

  private propagateChange: any = () => {};

  private propagateTouched: any = () => {};
}
