import { Component, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { BecomeTeacherEvent } from '@app/landings/job/models';
import { AuthModalModeType, AuthModalOptions, AuthService } from '@core/services';
import { EContentPanelRoute } from 'lingo2-models';

@Component({
  selector: 'app-promo-footer-common',
  templateUrl: './promo-footer.component.html',
  styleUrls: ['./promo-footer.component.scss'],
})
export class PromoFooterCommonComponent {
  @Output() becomeTeacher = new EventEmitter<BecomeTeacherEvent>();

  public isAuth: boolean = this.authService.isAuthenticated;
  public mainRoute: string = EContentPanelRoute.main;

  constructor(private authService: AuthService, private router: Router) {}

  public authorize(mode: AuthModalModeType) {
    this.authService.showAuthModal((u) => {}, { view: mode } as AuthModalOptions);
  }

  public navigateToMain() {
    this.router.navigate([EContentPanelRoute.main]).then(() => true);
  }

  public onBecomeTeacher(e: Event, route = '', markAsTeacher = false) {
    // нативная навигация подавляется, а обработка в CommonLandingComponent.onBecomeTeacher
    /** @see CommonLandingComponent.onBecomeTeacher */

    e.preventDefault();
    e.cancelBubble = true;

    this.becomeTeacher.emit({
      block: 'student',
      route: [route],
      markAsTeacher,
    });
  }
}
