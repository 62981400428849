import { Component, EventEmitter, Output } from '@angular/core';
import { BecomeTeacherEvent } from '@app/landings/job/models';
import { EContentPanelRoute } from 'lingo2-models';

@Component({
  selector: 'app-promo-footer',
  templateUrl: './promo-footer.component.html',
  styleUrls: ['./promo-footer.component.scss'],
})
export class PromoFooterComponent {
  @Output() becomeTeacher = new EventEmitter<BecomeTeacherEvent>();

  public mainRoute: string = EContentPanelRoute.main;

  public onBecomeTeacher(route = '', markAsTeacher = false) {
    /** @see JobPageComponent.onBecomeTeacher */

    this.becomeTeacher.emit({
      block: 'app-promo-footer',
      route: [route],
      markAsTeacher,
    });
  }
}
