<div class="auth__content" [class.mobile]="!deviceService.isDesktop()">
<app-title [title]="'two-factor-auth.title'"></app-title>

<div class="form__content" [formGroup]="form">
  <on-ui-form-step [stepProps]="{ label: 'two-factor-auth.step-1.title', count: 1 }"
                   [labelType]="'text'"
                   [state]="'success'">
    <ng-container ngProjectAs="content">
      <div class="groups-wrap" formArrayName="group">
        <div *ngFor="let buttonTab of getFormArray('group').controls; let idx = index; trackBy: trackByFn" class="forms-item" >
          <on-ui-button-tab [formControlName]="idx"
                            [error]="isInvalid('group')"
                            [buttonTab]="groups[idx]"
                            (changed)="onChangedGroup(idx)">
          </on-ui-button-tab>
      </div>
      </div>

      <div class="qr-wrap">
        <div *ngFor="let qr of qrs; let idx = index; trackBy: trackByFn" class="qr">
          <div class="cover-wrap">
            <on-ui-cover [cover]="cover(qr.value)"></on-ui-cover>
          </div>
          <p>{{qr.label}}</p>
        </div>
      </div>
    </ng-container>
  </on-ui-form-step>

  <on-ui-form-step [stepProps]="{ label: 'two-factor-auth.step-2.title', count: 2 }"
                   [labelType]="'text'"
                   [state]="'success'">
    <ng-container ngProjectAs="content">
      <div class="qr-cover">
        <img *ngIf="qrForAuth" [src]="qrForAuth" alt="qr-scan"/>
      </div>
    </ng-container>
  </on-ui-form-step>

  <on-ui-form-step [stepProps]="{ label: 'two-factor-auth.step-3.title', count: 3 }"
                   [labelType]="'text'"
                   [state]="'success'">
    <ng-container ngProjectAs="content">
      <div class="col-210">
        <on-ui-form-field-error [error]="isInvalid('two_factor_code') ? (errorField === 'two_factor_code' ? 'two-factor-auth.error.code' : 'two-factor-auth.error.required') : null">
        <on-ui-input formControlName="two_factor_code"
                     [placeholder]="'two-factor-auth.step-3.field' | translate"
                     [error]="isInvalid('two_factor_code')">
        </on-ui-input>
      </on-ui-form-field-error>
      </div>
    </ng-container>
  </on-ui-form-step>

  <on-ui-form-step [stepProps]="{ label: 'two-factor-auth.step-4.title', count: 4 }"
                   [labelType]="'text'"
                   [state]="'success'">
    <ng-container ngProjectAs="content">
      <div class="col-210">
        <on-ui-form-field-error [error]="isInvalid('password') ? (errorField === 'password' ? 'two-factor-auth.error.password' : 'two-factor-auth.error.required') : null">
          <on-ui-input formControlName="password"
                       [placeholder]="'two-factor-auth.step-4.field' | translate"
                       [error]="isInvalid('password')">
          </on-ui-input>
        </on-ui-form-field-error>
      </div>
    </ng-container>
  </on-ui-form-step>
</div>

<div class="form__action">
  <on-ui-button class="primary"
                [state]="btnState"
                label="two-factor-auth.action.activate"
                (onclick)="onAuthProcess()">
  </on-ui-button>
</div>
</div>


