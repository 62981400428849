import { SocialAuthService } from '@abacritt/angularx-social-login';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { logger } from '@app/core/helpers/logger';
import { AuthService } from '@core/services';

@Component({
  selector: 'app-auth-signout-page',
  template: '',
  styles: [''],
})
export class AuthSignoutPageComponent implements OnInit {
  constructor(private authService: AuthService, private router: Router, private socialAuthService: SocialAuthService) {}

  public ngOnInit(): void {
    this.doSignOut();
  }

  protected doSignOut() {
    this.socialAuthService
      .signOut()
      .then(() => logger.info('AuthSignoutPageComponent::doSignOut -> socialAuthService.signOut'))
      .catch((e) => logger.error('AuthSignoutPageComponent::doSignOut -> socialAuthService.signOut error', e))
      .finally(() => {
        this.authService.backUrl = null;
        this.authService.logOut(true);
      });
  }
}
