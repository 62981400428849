<div class="filter-group">
  <div class="filter-header" (click)="toggleExpand()">
    <span class="filter-label medium">{{ 'constructor2.filter.content-type' | translate }}</span>
    <svg viewBox="0 0 8 5"
         class="filter-label-ico"
         [class.filter-label-ico--expanded]="isExpanded">
      <use xlink:href="/assets/icons/arrow_filter.svg#arrow"></use>
    </svg>
  </div>
  <ul class="filter-list" [class.filter-list--none]="isExpanded">
    <li class="filter-item">
      <label class="checkbox-wrap">
        <input type="checkbox" class="checkbox-input" [checked]="isCheckedAllContentTypes()"
               (change)="toggleAllContentTypes()">
        <span class="checkbox">
          <app-icon icon="checkbox-arrow" class="checkbox-icon"></app-icon>
        </span>
        <span class="checkbox-label normal">{{ 'constructor2.filter.all' | translate }}</span>
      </label>
    </li>
    <li *ngFor="let item of contentTypes; trackBy: trackByFn" class="filter-item">
      <label class="checkbox-wrap">
        <input type="checkbox" class="checkbox-input" [checked]="isCheckedContentType(item.contentType)"
               (change)="toggleContentType(item.contentType)">
        <span class="checkbox">
          <app-icon icon="checkbox-arrow" class="checkbox-icon"></app-icon>
        </span>
        <span class="checkbox-label normal">{{ item.title | translate }}</span>
      </label>
    </li>
  </ul>
</div>
