<div [class.card_mobile]="deviceService.isMobile()" class="card">
  <div class="card__content">
    <form [formGroup]="form" autocomplete="off" novalidate>
      <div formArrayName="teaching_subjects">
        <p class="label-title">{{ 'profile-form.teaching.title' | translate }}</p>
        <p [innerHTML]="'profile-form.teaching.subtitle' | translate | striptags | nl2br" class="label-subtitle"></p>
        <p class="hint-title"></p>

        <ng-container
          *ngFor="let group of teachingSubjects.controls; let idx = index; trackBy: trackByFn"
          [formGroupName]="idx">
          <div [class.form-group_mobile]="deviceService.isMobile()" class="profile-form-group" [id]="'teaching' + idx">
            <div class="profile-form-group__content">
              <div class="subject-wrap">
                <div class="row row_subject">
                  <app-form-field [control]="group.get('subject_id')" class="field">
                    <app-subject-selector
                      (selectSubjectId)="onSubjectIdChanged($event, idx)"
                      [disabled]="group.disabled"
                      [subjectSelectedId]="group.get('subject_id').value"
                      [subjects]="subjects"
                      [valid]="group.get('subject_id').valid"
                      data-test="subject-selector">
                    </app-subject-selector>
                    <span *ngIf="group.get('subject_id').invalid" class="warn-message">
                      {{ 'profile-form.teaching.already-selected' | translate }}
                    </span>
                  </app-form-field>

                  <app-form-field *ngIf="isOtherSubject(idx)" [control]="group.get('subject_other_name')" class="field">
                    <on-ui-input
                      [maxlength]="200"
                      [placeholder]="'profile-form.teaching.what-exactly' | translate"
                      class="col col_md"
                      data-test="subject-other-name"
                      formControlName="subject_other_name">
                    </on-ui-input>
                  </app-form-field>
                </div>

                <app-icon
                  (click)="removeTeachingSubject(idx)"
                  [class.visibility-hidden]="teachingSubjects.controls.length === 1"
                  [height]="30"
                  [width]="30"
                  class="trash-icon"
                  data-test="remove-teaching-subject"
                  file="basket"
                  icon="basket-3">
                </app-icon>
              </div>

              <app-form-field [control]="group.get('description')" class="field">
                <on-ui-textarea
                  (changed)="onChangeDescription($event, group)"
                  [placeholder]="'profile-form.teaching.about-teaching' | translate"
                  data-test="description"
                  formControlName="description">
                </on-ui-textarea>
              </app-form-field>

              <div class="video-recorder-wrap">
                <span class="label-title">{{ 'user-service-form-3.titles.title1' | translate }}</span>
                <span class="hint-title">{{ 'user-service-form-3.fields.label11' | translate }}</span>

                <div class="video-options-wrap">
                  <div class="like-radio-btn-holder">
                    <div
                      *ngFor="let radio of videoOptions; trackBy: trackByFn"
                      class="like-radio-btn"
                      [class.active]="group.get('video_mode')?.value === radio?.value"
                      (click)="onVideosChanged($event, idx, radio?.value)"
                      data-test="video-mode">
                      {{ radio?.label | translate }}
                    </div>
                  </div>
                </div>

                <ng-container [ngSwitch]="group.get('video_mode')?.value">
                  <div *ngSwitchCase="videoModes.use_media_url" class="video-inputs-holder-link">
                    <app-form-field [control]="group.get('video_url')" class="field">
                      <on-ui-input
                        [placeholder]="'https://www.youtube.com'"
                        data-test="video-url"
                        formControlName="video_url">
                      </on-ui-input>

                      <app-embed-video
                        *ngIf="group.get('video_url').valid && group.get('video_url').value"
                        (errors)="embedHasErrors($event)"
                        [src]="group.get('video_url')?.value"
                        [subject]="group.get('subject_id')?.value">
                      </app-embed-video>
                    </app-form-field>
                  </div>

                  <div
                    *ngSwitchCase="videoModes.use_media_id"
                    [class.safari]="isSafari"
                    class="video-inputs-holder-video">
                    <ng-container *ngIf="!isSafari">
                      <app-record-video
                        (fileChanged)="updateVideoId(group, $event)"
                        (recordStarted)="disableAddSubject($event)"
                        [file_id]="getVideoId(group)"
                        formControlName="video_id">
                      </app-record-video>
                      <div class="bullet-list-wrap">
                        <on-ui-bullet-list [bullet]="bullet"></on-ui-bullet-list>
                      </div>
                    </ng-container>

                    <ng-container *ngIf="isSafari">
                      <app-video-uploader (changed)="updateVideoId(group, $event)" formControlName="video_id">
                      </app-video-uploader>
                      <div class="bullet-list-wrap">
                        <on-ui-bullet-list [bullet]="bulletForSafari"></on-ui-bullet-list>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>

          <hr *ngIf="teachingSubjects.controls.length - 1 !== idx" class="separator" />
        </ng-container>
      </div>

      <div class="add-input-wrap">
        <a (click)="addTeachingSubject()" class="add-input" data-test="add-subject">
          {{ 'profile-form.add-more' | translate }}
        </a>
      </div>
    </form>
  </div>

  <div class="card__actions">
    <on-ui-button
      (click)="navigateBack()"
      [state]="'default'"
      class="primary outline margin-right-20"
      data-test="profile-back"
      label="profile-form.back">
    </on-ui-button>

    <ng-container [ngSwitch]="true">
      <ng-container *ngSwitchCase="isMeVersion()">
        <on-ui-button
          (click)="navigateToPaymentPage()"
          [disabled]="form.invalid || haveErrors()"
          [label]="'profile-form.next'"
          [state]="saveState"
          class="primary"
          data-test="profile-next">
        </on-ui-button>
      </ng-container>

      <ng-container *ngSwitchCase="!isMeVersion()">
        <on-ui-button
          (click)="onToggleRulesModal()"
          [disabled]="form.invalid || haveErrors()"
          [label]="
            isSent
              ? 'profile-form.send-for-moderation'
              : isCancel
              ? 'verification-widget.actions.cancel'
              : 'profile-form.next'
          "
          [state]="saveState"
          class="primary"
          data-test="profile-next">
        </on-ui-button>
      </ng-container>
    </ng-container>
  </div>
</div>

<ng-container *ngIf="isRulesModalOpened">
  <app-modal *ngIf="!deviceService.isMobile()" (closed)="onToggleRulesModal()" class="rules-modal">
    <app-modal-body>
      <app-rules (onAgree)="onToggleRulesModal()"></app-rules>
    </app-modal-body>
  </app-modal>

  <app-mobile-modal *ngIf="deviceService.isMobile()" (closed)="onToggleRulesModal()">
    <app-mobile-modal-body>
      <app-rules (onAgree)="onToggleRulesModal()"></app-rules>
    </app-mobile-modal-body>
  </app-mobile-modal>
</ng-container>

<app-alert *ngIf="emptyDisciplineAlert" type="info" [btnPosition]="'center'">
  <ng-container alert-icon>
    <app-icon width="36" height="36" file="emoji" icon="shy"></app-icon>
  </ng-container>
  <ng-container alert-title>{{ 'user-profile.teaching-block.alert.title' | translate }}</ng-container>
  <ng-container alert-body>
    {{ 'user-profile.teaching-block.alert.text' | translate }}
  </ng-container>
  <ng-container alert-buttons>
    <on-ui-button
      label="user-profile.teaching-block.alert.ok"
      state="default"
      class="primary outline center"
      (onclick)="closeAlert()">
    </on-ui-button>
  </ng-container>
</app-alert>
