import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';

import { CategoriesItemComponent } from '@app/library/panes/categories-content-filter/categories-item/categories-item.component';
import { UniversalSidebarComponent } from '@app/library/sidebars/universal-sidebar/universal-sidebar.component';
import { VocabularySurfaceComponent } from '@app/vocabulary/components/vocabulary-surface/vocabulary-surface.component';
import { VocabularyLearnedComponent } from '@app/vocabulary/pages/learned/vocabulary-learned.component';
import { VocabularyListsComponent } from '@app/vocabulary/pages/lists/vocabulary-lists.component';
import { VocabularyMainComponent } from '@app/vocabulary/pages/main/vocabulary-main.component';
import { VocabularyStudentsComponent } from '@app/vocabulary/pages/students/vocabulary-students.component';
import { VocabularyStudyComponent } from '@app/vocabulary/pages/study/vocabulary-study.component';
import { MainHeaderModule } from '@core/components/main-header/main-header.module';
import { TitleModule } from '@core/components/title/title.module';
import { CoreModule } from '@core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiButtonModule, OnUiInputModule, OnUiSelectModule } from 'onclass-ui';
import { VocabularyCardComponent } from './components/vocabulary-card/vocabulary-card.component';
import { VocabularyContentComponent } from './components/vocabulary-content/vocabulary-content.component';
import { VocabularyFilterComponent } from './components/vocabulary-filter/vocabulary-filter.component';
import { VocabularyFormAddedComponent } from './components/vocabulary-form-added/vocabulary-form-added.component';
import { VocabularyComponent } from './vocabulary.component';

const routes: Routes = [
  {
    path: '',
    component: VocabularyComponent,
    children: [
      {
        path: 'main',
        component: VocabularyMainComponent,
      },
      {
        path: 'lists',
        component: VocabularyListsComponent,
      },
      {
        path: 'students',
        component: VocabularyStudentsComponent,
      },
      {
        path: 'learned',
        component: VocabularyLearnedComponent,
      },
      {
        path: 'requires-study',
        component: VocabularyStudyComponent,
      },
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'main',
      },
    ],
  },
  {
    path: '',
    outlet: 'right-sidebar-top',
    component: UniversalSidebarComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    RouterModule,
    ReactiveFormsModule,
    CoreModule,
    TranslateModule,
    MainHeaderModule,
    CategoriesItemComponent,
    TitleModule,
    OnUiInputModule,
    OnUiSelectModule,
    OnUiButtonModule,
    VocabularyListsComponent,
    VocabularyStudentsComponent,
    VocabularyFormAddedComponent,
    VocabularyContentComponent,
    VocabularySurfaceComponent,
    VocabularyCardComponent,
    VocabularyFilterComponent,
  ],
  declarations: [VocabularyComponent, VocabularyMainComponent, VocabularyLearnedComponent, VocabularyStudyComponent],
})
export class VocabularyModule {}
