import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { CLibraryFilterData } from '@app/store/models';
import { getLibraryRouteData } from '@app/store/reducers/library.reducer';
import { AccountService, ContentService, FeaturesService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { getCurrentItem } from '@store/reducers/content.reducer';
import { getMe } from '@store/reducers/profile.reducer';
import {
  ContentTypeEnum,
  EContentModeType,
  EContentPaneFormat,
  EContentPanelRoute,
  IContentPane,
  MicroText,
  TenantEnum,
  User,
  UserService,
} from 'lingo2-models';
import { combineLatest, filter, takeUntil } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-universal-sidebar',
  templateUrl: './universal-sidebar.component.html',
  styleUrls: ['./universal-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class UniversalSidebarComponent extends ChangableComponent implements OnInit {
  public contentFilter: CLibraryFilterData;
  public panes: IContentPane[];
  public loading = true;
  public me: User;
  public user_id: string;
  public school_id: string;
  private isTeacher: boolean;
  public isMicroText: boolean;
  public paneWelcome: IContentPane = {
    format: EContentPaneFormat['widget-welcome'],
    order: 1,
    loaded: false,
  };

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private readonly store: Store,
    private contentService: ContentService,
    private features: FeaturesService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  get isNewsPage(): boolean {
    return ['people-news', 'school-news', 'classroom-news', 'comments', 'main'].includes(this.contentFilter?.baseRoute);
  }

  ngOnInit(): void {
    combineLatest([this.store.select(getMe), this.store.select(getLibraryRouteData).pipe(filter((v) => !!v))])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([me, data]) => {
          this.me = me;
          this.contentFilter = data;
          this.contentFilter = {
            ...this.contentFilter,
            contentMode: EContentModeType['sidebar-widgets'],
          };
          this.isTeacher = AccountService.isTeacher(me) || AccountService.isAsIfTeacher(me);
          this.loadContentBlocks();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });

    this.store
      .select(getCurrentItem)
      .pipe(filter((v) => !!v))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (item) => {
          if ((item as UserService).school_id) {
            this.school_id = (item as UserService).school_id;
          } else {
            this.isMicroText = (item as MicroText)?.type === ContentTypeEnum.microtext;
            this.user_id = (item as UserService).author_id;
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  private loadContentBlocks() {
    this.loading = true;
    this.contentService
      .getFeaturings(this.contentFilter)
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.loading = false;
          // logger.debug(cbs);
          this.panes = res.data;
          this.detectChanges();
        },
        error: (error: any) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.loading = false;
          this.detectChanges();
        },
      });
  }

  public isVocabularyPage() {
    return this.contentFilter.url.includes('my-vocabulary');
  }

  public showNewsWidget(): boolean {
    return (
      this.isTeacher &&
      (this.contentFilter?.baseRoute === EContentPanelRoute.news ||
        this.contentFilter?.baseRoute === EContentPanelRoute.main)
    );
  }

  public isMeVersion() {
    return this.features.tenant === TenantEnum.onclass_me;
  }

  public isVisibleUserWidget(): boolean {
    return (
      this.contentFilter?.baseRoute === EContentPanelRoute.lessons ||
      (this.isMeVersion() &&
        this.contentFilter?.baseRoute.split('/')[0] === String(EContentPanelRoute['my-classes']) &&
        this.isTeacher)
    );
  }

  public isClassesPage() {
    return this.contentFilter?.baseRoute === EContentPanelRoute.classes;
  }

  public isVisibleWelcomeWidget(): boolean {
    const pages = [EContentPanelRoute['my-classes'], EContentPanelRoute['my-library'], 'my-vocabulary', 'schedule'];
    return this.isMeVersion() && pages.indexOf(decodeURIComponent(this.contentFilter.url).split('/')[1]) != -1;
  }

  public onCloseFirstContactWidget(event: boolean) {
    if (this.isBrowser && localStorage) {
      localStorage?.setItem('first-contact-widget-hidden', event.toString());
    }
  }

  public trackByFn(index) {
    return index;
  }
}
