import { Injectable } from '@angular/core';

import { IControlOption } from '@shared/form-controls/form-controls.models';
import { Country, Language, LanguageLevel } from 'lingo2-models';

// TODO: duplicates functions from profile-form-about. Refactor.

const countryTitle = (country: Country): string => {
  const parts: string[] = [country.title];
  if (country.title && country.title_native !== country.title) {
    parts.push(`(${country.title_native})`);
  }
  return parts.join(' ');
};

@Injectable()
export class AboutFormMapperService {
  constructor() {}

  public countriesToOptions(countries: Country[]): IControlOption[] {
    return (countries || [])
      .map((country) => ({
        title: countryTitle(country),
        value: country.id.toString(),
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  public languagesToOptions(languages: Language[]): IControlOption[] {
    return languages
      .map((language) => ({
        title: language.title,
        value: language.id.toString(),
      }))
      .sort((a, b) => a.title.localeCompare(b.title));
  }

  public languageLevelsToOptions(languageLevels: LanguageLevel[], isTrimmed = false): IControlOption[] {
    const optionsOrderByValue = isTrimmed ? [1, 6, 5, 2] : [1, 6, 5, 2, 4, 3];
    const options = languageLevels.map((languageLevel) => ({
      title: 'language-levels.' + languageLevel.title,
      value: languageLevel.id.toString(),
    }));

    return optionsOrderByValue.map((value) => options.find((o) => +o.value === value));
  }
}
