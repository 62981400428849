import { Component, OnInit } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';

@Component({
  selector: 'app-advantages',
  templateUrl: './advantages.component.html',
  styleUrls: ['./advantages.component.scss'],
})
export class AdvantagesComponent extends DestroyableComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
