import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormControlsModule } from '@shared/form-controls';
import { IconComponent } from '@shared/icon/icon.component';
import {
  OnUiButtonModule,
  OnUiDateModule,
  OnUiInputModule,
  OnUiSelectModule,
  OnUiSelectSearchModule,
  OnUiTextareaModule,
} from 'onclass-ui';
import { AboutFormPageComponent } from './about-form-page.component';
import { AboutFormMapperService } from './mapper/about-form-mapper.service';
import { UserpicModule } from './userpic/userpic.module';

@NgModule({
  declarations: [AboutFormPageComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    FormControlsModule,
    UserpicModule,
    OnUiTextareaModule,
    OnUiButtonModule,
    IconComponent,
    OnUiSelectModule,
    OnUiInputModule,
    OnUiDateModule,
    OnUiSelectSearchModule,
  ],
  exports: [AboutFormPageComponent],
  providers: [AboutFormMapperService],
})
export class AboutFormPageModule {}
