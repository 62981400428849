import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

// модули
import { SpinnersModule } from '../core/components/spinners/spinners.module';
import { CoreModule } from '../core/core.module';

// страницы
import { MeetingFastStartPageComponent } from './meeting-fast-start-page/meeting-fast-start-page.component';

/**
 * Этот роут срабатывает после ленивой загрузки модуля по роуту /go/class
 *
 * @see AppRoutingModule
 */
const routes: Routes = [
  {
    path: '',
    // canActivate: https://app.clickup.com/t/8693zu39f бэк проверит доступ
    component: MeetingFastStartPageComponent,
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), CoreModule, TranslateModule, SpinnersModule],
  declarations: [MeetingFastStartPageComponent],
  providers: [],
  exports: [],
})
export class MeetingFastStartModule {}
