import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ChangableComponent } from '@models/changable.component';
import { IconComponent } from '@shared/icon/icon.component';
import { VocabularyList, VocabularyTerm } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { VocabularyCardComponent } from '../../../components/vocabulary-card/vocabulary-card.component';
import { VocabularyFilterComponent } from '../../../components/vocabulary-filter/vocabulary-filter.component';
import { VocabularySurfaceComponent } from '../../../components/vocabulary-surface/vocabulary-surface.component';
import { SharedModule } from '../../../shared.module';

@Component({
  selector: 'app-vocabulary-list-card-details',
  standalone: true,
  imports: [
    SharedModule,
    VocabularyCardComponent,
    VocabularySurfaceComponent,
    VocabularyFilterComponent,
    IconComponent,
  ],
  templateUrl: './card-details.component.html',
  styleUrls: ['./card-details.component.scss'],
})
export class ListCardDetailsComponent extends ChangableComponent implements OnInit {
  @Input() item: VocabularyList;
  @Output() back = new EventEmitter<boolean>();

  public vocabulary: Partial<VocabularyTerm>;

  constructor(protected readonly cdr: ChangeDetectorRef, public deviceService: DeviceDetectorService) {
    super(cdr);
  }

  ngOnInit(): void {}

  goBack() {
    this.back.emit(true);
  }

  public onVocabularyChange(e: Partial<VocabularyTerm>) {
    this.vocabulary = e;
  }
}
