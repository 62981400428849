<div class="scroll-selector">
  <div #scroll class="scroll">
    <ul class="days-wrap">
      <li *ngFor="let day of mobileDays; let i = index; trackBy: trackByValue"
          class="day"
          (click)="onSelectDay(i)">
        <span class="day-of-week normal">{{ day | dfnsFormat: 'cccccc' }}</span>
        <span class="day-number bold">{{ day | dfnsFormat: 'd' }}</span>
        <div class="points">
          <div class="point">
            <div *ngIf="dayStates().ongoing" class="point ongoing"></div>
            <div *ngIf="dayStates().unconfirmed" class="point unconfirmed"></div>
            <div *ngIf="dayStates().upcoming" class="point upcoming"></div>
            <div *ngIf="dayStates().passed" class="point passed"></div>
            <div *ngIf="dayStates().canceled" class="point canceled"></div>
            <div *ngIf="dayStates().vacation" class="point vacation"></div>
          </div>
        </div>
      </li>
    </ul>
    <div class="day active">
      <span class="day-of-week normal">{{ activeDay | dfnsFormat: 'cccccc' }}</span>
      <span class="day-number bold">{{ activeDay | dfnsFormat: 'd' }}</span>
      <div class="points">
      </div>
    </div>
  </div>
</div>
