import { createSelector } from '@ngrx/store';
import { getContentEditorState, ContentEditorState } from '..';
import { LibraryState } from './library.reducer';

export { IPagination, IFindContentFilter } from 'lingo2-models';

const getLibraryState = createSelector(getContentEditorState, (state: ContentEditorState) => state.library);

export const getLibraryItems = createSelector(getLibraryState, (state: LibraryState) => state.items);

export const getLibraryLoading = createSelector(getLibraryState, (state: LibraryState) => state.loading);

export const getLibraryLoaded = createSelector(getLibraryState, (state: LibraryState) => state.loaded);

export const getLibraryFilter = createSelector(getLibraryState, (state: LibraryState) => state.filter);

export const getLibraryPagination = createSelector(getLibraryState, (state: LibraryState) => state.pagination);
