import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { logger } from '@core/helpers/logger';
import { ContextService, CrispChatService, PlatformService } from '@core/services';
import {
  BillingV2Service,
  IFinanceCardCreateNewOptions,
  SuppliersByMerchant,
} from '@core/services/lingo2-account/billing-v2.service';
import {
  BillingApiMerchantResponseDto,
  BillingApiSupplierDto,
  BillingApiSupplierResponseDto,
  BillingApiTransactionFindQueryDto,
  BillingApiTransactionResponseDto,
} from '@lingo2-billing-sdk/models';
import { BillingCoreSupplierStatusEnum } from '@lingo2-billing-sdk/models/src/billing-core/billing-core-supplier/types/enums/billing-core-supplier-status.enum';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadMe } from '@store/actions/profile.actions';
import { CSchool, IContentBlock, User } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-school-settings-accounts',
  templateUrl: './school-settings-accounts.component.html',
  styleUrls: ['./school-settings-accounts.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolSettingsAccountsComponent extends ChangableComponent implements OnInit {
  @Input() school: CSchool;

  public activeSuppliers: SuppliersByMerchant[] = [];
  public inactiveSuppliers: SuppliersByMerchant[] = [];
  public merchants: BillingApiMerchantResponseDto[];
  public me: User;
  public paymentHistoryOpened = false;
  public newPaymentFormOpened = false;
  public addPaymentMerchantId = null;
  public showWarningAlert = false;

  public withdrawMoneySupplier: BillingApiSupplierResponseDto = null;

  public showNonActive = false;
  public showEditAlert = false;

  public supplierModalOptions: IFinanceCardCreateNewOptions = {
    where: {
      providerId: null,
      countryCode: null,
    },
    supplierId: null,
  };

  public transactions: Partial<IContentBlock<BillingApiTransactionResponseDto, BillingApiTransactionFindQueryDto>> = {
    loading: false,
    loaded: false,
    items: [],
    filter: {},
  };

  constructor(
    public errorNotificationService: ErrorNotificationService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
    private billingV2Service: BillingV2Service,
    private store: Store,
    private contextService: ContextService,
    private crispChat: CrispChatService,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.contextService.me$.pipe(tap(), takeUntil(this.destroyed$)).subscribe({
      next: (me) => {
        this.me = me;
        this.loadProviders();
        this.loadSuppliers();
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.store.dispatch(loadMe());
      },
    });
  }

  public getMoney(supplier) {
    this.withdrawMoneySupplier = supplier;
    this.detectChanges();
  }

  public setSupplierDefault(supplier: BillingApiSupplierResponseDto) {
    const payload = new BillingApiSupplierDto();
    payload.isActive = true;
    this.billingV2Service
      .patchSupplier(supplier.id, payload, this.school.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(
        () => {
          this.loadSuppliers();
          this.detectChanges();
        },
        (error) => {
          logger.debug('ERROR ON SET DEFAULT SUPPLIER: -> ' + JSON.stringify(error));
          this.detectChanges();
        },
      );
  }

  public loadProviders() {
    this.billingV2Service
      .getMerchants()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (merchants) => {
          this.merchants = merchants;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  private loadSuppliers() {
    this.billingV2Service
      .getSuppliers(this.school.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (suppliers) => {
          if (this.merchants) {
            this.merchants.forEach((merchant) => {
              this.activeSuppliers[merchant.id] = suppliers.filter(
                (item) => item.providerId === merchant.id && item.isActive,
              );
              this.inactiveSuppliers[merchant.id] = suppliers.filter(
                (item) => item.providerId === merchant.id && !item.isActive,
              );
            });
          }
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public updateMethodList() {
    this.loadSuppliers();
  }

  public isForNotRussia(merchant: BillingApiMerchantResponseDto) {
    if (merchant.unSupportCountries) {
      return (merchant.unSupportCountries as any[]).includes('RU');
    } else {
      if (merchant.supportedCountries) {
        return !(merchant.supportedCountries as any[]).includes('RU');
      } else {
        return true;
      }
    }
  }

  public editMethod(supplier: BillingApiSupplierResponseDto) {
    this.supplierModalOptions.supplierId = supplier?.id;
    this.supplierModalOptions.where.providerId = supplier?.providerId;
    this.supplierModalOptions.where.countryCode = supplier?.countryCode;

    if (supplier.status === BillingCoreSupplierStatusEnum.CONFIRMED) {
      this.showEditAlert = true;
      return;
    }
    this.newPaymentFormOpened = true;
  }

  public showAddPaymentAlert(merchantId: string) {
    if (!!this.activeSuppliers[merchantId].length) {
      this.addPaymentMerchantId = merchantId;
      this.showWarningAlert = true;
    } else {
      this.addPaymentMerchantId = merchantId;
      this.addPayment();
    }
  }

  public closeAddPaymentAlert() {
    this.showWarningAlert = false;
    this.newPaymentFormOpened = false;
    this.addPaymentMerchantId = null;
  }

  public addPayment(): void {
    if (this.addPaymentMerchantId) {
      this.showWarningAlert = false;
      this.supplierModalOptions.where.providerId = this.addPaymentMerchantId;
      this.supplierModalOptions.supplierId = null;
      this.newPaymentFormOpened = true;
    }
  }

  public closeNewCardForm() {
    this.newPaymentFormOpened = false;
    this.clearModalOptionsData();
    this.loadSuppliers();
  }

  public openPaymentHistory(providerId: string): void {
    this.loadTransactions(providerId);
    this.paymentHistoryOpened = true;
  }

  public closePaymentHistory() {
    this.paymentHistoryOpened = false;
  }

  public loadTransactions(providerId: string) {
    const request = new BillingApiTransactionFindQueryDto();
    request.page = 1;
    request.pageSize = 50;
    request.accountProviderId = providerId;

    this.billingV2Service
      .findTransactions(request)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.transactions.items = res.results;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public openCrispChat() {
    this.crispChat.openChat();
  }

  public toggleNonActive() {
    this.showNonActive = !this.showNonActive;
  }

  private clearModalOptionsData() {
    this.supplierModalOptions = {
      where: {
        providerId: null,
        countryCode: null,
      },
      supplierId: null,
    };
  }

  public closeWithdrawMoneyForm() {
    this.withdrawMoneySupplier = null;
  }

  public isTestProvider(provider: BillingApiMerchantResponseDto) {
    return provider.testMode;
  }

  public isTestBeneficiary(beneficiary: BillingApiSupplierResponseDto) {
    return this.merchants.find((provider) => provider.id === beneficiary.providerId).testMode;
  }

  public trackByFn(index) {
    return index;
  }
}
