{
  "APP_NAME": "Onclass",
  "DEFAULT_DESCRIPTION": "Learn any of 40 subjects. Learn any of 40 subjects. Learn any of 40 subjects. Learn any of 40 subjects. Learn any of 40 subjects.",
  "DEFAULT_TITLE": "Learn any of 40 subjects in 30 languages with professional tutors or with friends.",
  "cookie-text": "Onclass protects the personal data of users and processes cookies only to personalize the services. You can disable the processing of cookies in your browser settings. Please read the {{ terms1 }} and Cookies.",
  "cookie-terms": "Terms of Personal Data Processing",
  "cookie-btn": "Accept and close",
  "about-verification-widget": {
    "title": "How does verification work?",
    "description": "Once you submit your profile for verification, Onclass moderators will ensure that it contains complete and portal-compliant information. They will either approve it or return it to you for revision. This process typically takes no more than 48 hours, but it is often much faster.",
    "verified-profile": {
      "title": "Verified profile",
      "description-1": "Your profile can be published in the tutor catalog.",
      "description-2": "You can schedule public classes open to all users.",
      "description-3": "You can schedule individual classes in the calendar.",
      "description-4": "Your classes can be published in the Onclass public catalog.",
      "description-5": "You can settle payments with your students directly through Onclass using secure payments.",
      "description-6": "As a teacher, you will start receiving ratings from students, and your profile will feature a public ranking.",
      "description-7": "As a result, you can attract new students and conduct more classes on the platform."
    },
    "unverified-profile": {
      "title": "Unverified profile",
      "description": "Without verification by a moderator, you can only use the conference feature to work with your existing students by sending them an invitation to a session via a link."
    },
    "not-verified-title": "Unverified profile",
    "text-1": "Your profile can be published in the teacher catalog;",
    "text-2": "You can schedule public classes open to all users;",
    "text-3": "You can schedule individual classes in the calendar;",
    "text-4-1": "You can settle payments with your students directly through",
    "text-4-2": "using secure transactions;",
    "text-5": "Tutor ratings / Getting grades from students;",
    "text-6": "As a teacher, you will start receiving ratings from students, and your profile will feature a public ranking.",
    "verified-title": "Verified profile"
  },
  "account": {
    "action-subscribe-to-user": "Subscribe +",
    "change-timezone": "Change time zone",
    "edit-profile": "Edit profile",
    "not-found": {
      "title": "User profile not found"
    },
    "private-review": "Private review from",
    "send_message": "Message",
    "settings": "Settings",
    "subscribed-to-user": "Subscribed",
    "billing": {
      "meeting-price": "Participation fee",
      "bank-commission": "Processing fee",
      "purchase-collection": "Purchase collection"
    }
  },
  "account-check-sidebar-widget": {
    "action-add-card": "Add a bank card",
    "action-add-individual-lessons": "Add individual classes",
    "action-add-public-meeting-deposit": "Add public meeting deposit",
    "action-add-teaching-subjects": "Add teaching subjects",
    "action-add-working-hours": "Add working hours",
    "action-cancel": "Cancel",
    "action-change": "Change",
    "action-define-billing-details": "Define billing details",
    "action-resend": "Resend",
    "email-change-unconfirmed-title": "There is a requested email address change to {{email}} pending.",
    "email-unconfirmed-title": "You should confirm your email {{email}}.",
    "mobile-phone-change-unconfirmed-title": "There is a requested mobile phone change to {{number}} pending.",
    "mobile-phone-unconfirmed-title": "You should confirm your mobile phone {{number}}.",
    "title": "Complete your account"
  },
  "ad": {
    "ad-become-teacher": {
      "action": "Pass Verification",
      "title": "Tutors profile",
      "var1": "Go through profile verification to get into the list of tutors",
      "var2": "Go through profile verification and get teacher status"
    },
    "ad-create-class": {
      "action": "Open class",
      "action2": "Schedule a class",
      "action3": "Invite a teacher",
      "action4": "Watch a demo lesson",
      "thesis1": "Individual or group online classes",
      "thesis2": "Use Onclass сontent, including lessons you have created",
      "thesis3": "New technologies and opportunities in online learning",
      "thesis4": "Individual and group classes",
      "thesis5": "Interactive lessons and whiteboard",
      "title": "Video conference for online classes",
      "title-student": "Invite a teacher to the Onclass platform",
      "title-teacher": "Teach online on the Onclass virtual class",
      "var1": "Create your own class and evaluate the capabilities of the <em>onclass.com</em> platform"
    },
    "ad-create-content": {
      "action": "Create a lesson",
      "thesis1": "Create engaging lessons in an easy-to-use editor",
      "thesis2": "Use lessons in your online classes",
      "thesis3": "Add lessons to the Onclass conference",
      "title": "Create your lessons in the Onclass lesson builder"
    },
    "ad-incomplete-profile": {
      "action": "Edit profile",
      "title": "Your profile is not complete",
      "var1": "It’s easier for teachers to create content that’s right for you if they know more about you."
    },
    "ad-looking-for-teacher": {
      "label1": "Find a suitable student application",
      "label2": "Respond to it and wait for an answer",
      "label3": "Complete the application and get a reward",
      "title": "Students looking for a teacher to teach"
    }
  },
  "auth": {
    "action1": "Sign up with E-mail",
    "action4": "Sign in",
    "agreements": "By signing up, you agree to {{ terms1 }} and allow<br> {{ terms2 }}.",
    "agreements-terms1": "the terms of use of the site",
    "agreements-terms2": "the processing of personal data",
    "email": "E-mail",
    "errors": {
      "restore": {
        "email_invalid": "Invalid email",
        "account_invalid": "Looks like account not found",
        "required": "Please fill in all required fields",
        "Wrong phone number": "Wrong phone number",
        "Wrong E-mail address": "Wrong E-mail address"
      },
      "signin": {
        "Account not found": "Incorrect login or password",
        "Wrong password": "Incorrect login or password",
        "invalid_credentials": "Incorrect login or password",
        "password_invalid": "Please enter your password",
        "email_invalid": "Please enter your email"
      },
      "signup": {
        "email": "E-mail has already been taken",
        "slug": "Account name has already been taken",
        "Network not supported": "Network not supported",
        "Authorization not possible": "Authorization not possible",
        "account_already_exist": "Account with this email already exists",
        "first_name_invalid": "Please enter valid First Name",
        "last_name_invalid": "Please enter valid Last Name",
        "mobile_phone_invalid": "Please enter valid phone number",
        "email_invalid": "Please enter valid email address",
        "nick_invalid": "Please enter valid Nickname",
        "slug_invalid": "Please enter valid Account Name",
        "country_invalid": "Please, select country of residence",
        "password_invalid": "The password must be at least 8 characters",
        "required": "Please fill in all required fields"
      }
    },
    "name": "Name",
    "surname": "Surname",
    "phone-number": "Phone number",
    "new-password": "New password",
    "country-code": "Country code",
    "account-name": "Account name",
    "password": "Password",
    "repeat-password": "Repeat password",
    "reset-password": {
      "action-confirm": "Save new password",
      "action-signin": "Sign in with new password",
      "confirm-password": "Confirm password",
      "meta-title": "Set new password at Onclass",
      "text-check-fail": "Access was already recovered or recovery link is expired",
      "text-fail": "Access recovery not possible",
      "text-success": "Your password was changed",
      "title": "Account Recovery"
    },
    "restore": {
      "action-back": "Back",
      "action-continue": "Continue",
      "action-signin": "Sign in with new password",
      "meta-title": "Restore access at Onclass",
      "text": "Enter the email you used when registering your account, and we will send an email with a link to reset your password.",
      "text-success": "An email with a link to reset your password has been sent to the specified mail.",
      "title": "Account Recovery",
      "password-recovery": "Password Recovery",
      "password-recovery-with-email": "With an e-mail",
      "password-recovery-with-phone": "With a phone number",
      "recovery-text": {
        "text1": "Congratulations!",
        "text2": "Your password has been successfully reset. The new password has been sent to the email address you provided during registration.",
        "text3": "You can change your password later on the profile editing page."
      }
    },
    "signin": {
      "action1": "Sign in",
      "action2": "Sign in with social networks",
      "action4": "Forgot a password?",
      "meta-title": "Sign in into Onclass",
      "create-new-account": "Create new account",
      "forgot-it": "Forgot it",
      "forgot-password": "Forgot password"
    },
    "signout": {
      "action": "Sign out"
    },
    "signup": {
      "action1": "Sign up",
      "meta-title": "Sign up at Onclass",
      "sign-up": "Sign up",
      "choose-role": "Choose role",
      "student": "Student",
      "teacher": "Tutor",
      "methodist": "Methodist",
      "accept-text": "I accept the <a href='/terms' target='_blank'>Terms of use</a> and <a href='/privacy' target='_blank'>Privacy policy</a>",
      "send-message": "We sent an SMS with a code to the number",
      "send-message-email": "We have sent a password recovery code to the specified e-mail",
      "not-received-message": "If you do not receive SMS, check that the phone number you entered is correct",
      "not-received-message-email": "If the code does not come, check the correctness of the e-mail you entered",
      "incorrect-code": "Code is incorrect",
      "get-code-again": "You can get the code again in",
      "get-code": "Get code",
      "sec": "sec"
    },
    "text3": "Already have an account?",
    "title": "Sign in via Facebook or Google or Email",
    "toastr": {
      "sign-in-success": "You are signed in as {{username}}",
      "sign-in-title": "Sign in",
      "sign-up-success": "You are signed up as {{username}}",
      "sign-up-title": "Sign up"
    },
    "warnings": {
      "empty": "Required field",
      "invalid-email": "E-mail invalid",
      "invalid-pass": "Wrong password",
      "pass-not-equal": "Passwords do not match",
      "wrong-email": "Wrong format",
      "wrong-new-password": "Wrong new password",
      "wrong-old-password": "Wrong old password"
    }
  },
  "pull": {
    "poll-title1": "What do you expect from Onclass?",
    "poll-title2": "What features are you interested in?",
    "option1": "Work in your spare time",
    "option2": "Classes with your students",
    "option3": "Remote work",
    "option4": "Find new students",
    "option5": "Earn online",
    "option6": "Web conference + interactive whiteboard",
    "option7": "Lesson constructor",
    "option8": "Individual sessions",
    "option9": "Own online school",
    "option10": "Group lessons",
    "option11": "Training classes",
    "option12": "Webinars or streams",
    "option13": "Offline - courses",
    "option14": "Homework",
    "option15": "Online - courses",
    "option16": "Marathons",
    "content1": "You choose your own work schedule, specify working hours and days off.",
    "content2": "Bring your students from other platforms. For classes with your students, a discount is provided for a secure transaction.",
    "content3": "You can work from anywhere in the world.",
    "content4": "Glue new students who register on the portal.",
    "content5": "You set the prices for your services yourself. Everything is up to you.",
    "content6": "Interactive board with video and audio communication. Add exercises and other available content, do independent work and give homework to students.",
    "content7": "Create lessons, exercises and other educational content and publish it in the \"lessons\" section.",
    "content8": "One-on-one classes with students.",
    "content9": "Build a personal brand, hire tutors and earn passive income.",
    "content10": "Recruit groups of students up to 11 people and conduct group classes with them.",
    "content11": "Classes store the history of classes. You can always look at the boards of past events within the classroom and understand what has been studied.",
    "content12": "Webinars can accommodate up to 50 people, streams for 50+ participants.",
    "content13": "Collect collections of lessons, combine them into online courses, set a price and publish them to the public.",
    "content14": "Give homework to your students, indicate the date of the check and control the implementation.",
    "content15": "You can plan a chain of several classes on the same topic and conduct them according to the schedule.",
    "content16": "Conduct training marathons of various duration. Plan a content plan and set up automatic publication of materials.",
    "start": "Start"
  },
  "auth-widget": {
    "text": "Log in and evaluate the platform features",
    "title": "Sign in",
    "to-come": "Sign in"
  },
  "two-factor-auth": {
    "title": "Two-factor authentication",
    "status": {
      "not-activated": "Not activated",
      "activated": "Activated"
    },
    "hint": "Increase the security of your account",
    "action": {
      "activate": "Activate two-factor authentication",
      "disable": "Disable two-factor authentication"
    },
    "step-1": {
      "title": "Select the operating system of your smartphone, scan the QR code and download one of the proposed applications to choose from"
    },
    "step-2": {
      "title": "Register or log in to your account in the application and scan this QR code using the application"
    },
    "step-3": {
      "title": "A 6-digit code will appear in the application, which must be entered in this field",
      "field": "Code"
    },
    "step-4": {
      "title": "Enter the current password for your Onclass account",
      "field": "Password"
    },
    "error": {
      "required": "The field is required",
      "code": "Invalid code",
      "password": "Invalid password"
    }
  },
  "best-entity-widget": {
    "actions": {
      "content.action1": "Create a lesson",
      "teachers": {
        "action1": "Start tutoring",
        "action2": "Invite a colleague"
      },
      "user-service.action1": "Add individual class"
    },
    "book-now": "Book now",
    "from": "From",
    "hour": "hour",
    "interested-in": "You might be interested in:",
    "see-all-link": "Show more",
    "subtitles": {
      "content": "For this discipline, lessons have not yet been created",
      "teachers": "There are no tutors in this subject yet.",
      "user-services": "No user services have yet been created for this discipline."
    },
    "titles": {
      "content": "Popular lessons",
      "teachers": "Best tutors",
      "user-services": "Popular classes"
    }
  },
  "chat": {
    "cancel": "Cancel",
    "date": "Date",
    "enter-message": "Enter message",
    "leave": "Delete chat",
    "mark-as-read": "Mark as read",
    "open-profile": "Open profile",
    "pin-chat": "Pin chat",
    "select-chat": "Select chat",
    "unpin-chat": "Unpin chat",
    "write-to-support": "Write to support"
  },
  "classrooms": {
    "page-header-title": "Classrooms",
    "classroom": {
      "classes": "Classes:",
      "created": "Created:",
      "students": "Students:",
      "last-class": "Last class:",
      "next-class": "Upcoming class:",
      "date": "Date:",
      "tutor": "Tutor:",
      "participants": "Participants:",
      "open-classroom": "Open the classroom",
      "title": "New classroom",
      "deleted": "Deleted",
      "archived": "Archived",
      "deleted-by-teacher": "Removed by teacher",
      "deleted-message": "The classroom has been archived by the tutor. You can remove it from your list of classrooms if you don't want to keep it in the archive."
    },
    "classroom-page": {
      "buttons": {
        "chat": "Chat",
        "create-meeting": "Create meeting",
        "new-meeting": "New class",
        "join-class": "Join the class",
        "leave-class": "Leave class",
        "invite": "Invite"
      },
      "meetings": {
        "stubs": {
          "owned": {
            "description": "Teach students by creating one-off lessons on specific topics on-demand or as appropriate.",
            "title": "Create your first meeting"
          },
          "participated": {
            "description": "The instructor has not yet created the class. You will receive a notification as soon as your instructor creates a class.",
            "title": "No meetings"
          }
        },
        "titles": {
          "current": "Current",
          "upcoming": "Upcoming",
          "past": "Past",
          "canceled": "Canceled"
        }
      },
      "participants": {
        "stub": {
          "description": "There are no participants yet.",
          "title": "No participants"
        }
      },
      "content": {
        "stubs": {
          "description": "Share the news with your students. It's time to make your first post.",
          "description-student": "No publications yet"
        }
      },
      "tabs": {
        "meetings": "Classes",
        "participants": "Participants",
        "publications": "Publications"
      },
      "cancel-subscription": "Cancel subscription",
      "cancel": "Cancel",
      "decline-participant": "Decline to participate"
    },
    "form": {
      "actions": {
        "cancel": "Cancel",
        "create": "Create",
        "save": "Save"
      },
      "titles": {
        "create": "Create classroom",
        "edit": "Edit classroom"
      },
      "access": {
        "public": "Public",
        "public-text": "Anyone who clicks on the invitation link can join the classroom.",
        "private": "Private",
        "private-text": "Only those whom you have personally invited can join the class."
      }
    },
    "invite": {
      "copied": "Copied",
      "copy-link": "Copy link",
      "emails-placeholder": "Emails separated by commas",
      "get-link": "Invitation link:",
      "invitation-sent": "Invitation sent",
      "invite": "Invite",
      "invite-via-email": "Invite users via email",
      "stub": {
        "description": "Invite students to the classroom",
        "title": "You have no students"
      },
      "title": "Invitation to classroom"
    },
    "list": {
      "buttons": {
        "create": "Create a classroom"
      },
      "stubs": {
        "owned": {
          "description": "A classroom is a group of students you regularly teach. A class can consist of both individual activities created at the right time, and a pre-prepared cycle of activities that can be created and filled in advance.",
          "title": "What is a classroom?"
        },
        "participated": {
          "description": "A classroom is a group of students who work with one tutor. When a tutor invites you to a class, a new classroom will appear in this section and you can join it if you wish.",
          "title": "What is a classroom?"
        },
        "archived": {
          "description": "An archive is a section where unnecessary or irrelevant classes get and where unnecessary or irrelevant classes are stored. The archived class can be restored at any time.",
          "title": "What is an archive?"
        }
      },
      "tabs": {
        "owned": "I teach",
        "participated": "I study",
        "archived": "Archive"
      }
    },
    "menu": {
      "delete": "Archive classroom",
      "restore": "Restore classroom",
      "edit": "Edit",
      "cancel": "Cancel",
      "invite": "Invite students",
      "leave": "Leave the classroom",
      "pin": "Pin to menu",
      "unpin": "Unpin from menu",
      "schedule": "Schedule a meeting"
    },
    "meta": {
      "title": "Classrooms"
    },
    "steps": {
      "setup": "Set up and customize the class:",
      "students": "Students:",
      "type": "Class type:"
    },
    "types": {
      "classroom": "Classroom",
      "course": "Paid course of studies"
    },
    "class-break-reasons": {
      "regular": {
        "no_classes": {
          "title": "Cancel Subscription",
          "description": "Are you sure you want to cancel your regular class subscription?",
          "cancel": "Do not cancel",
          "confirm": "Cancel"
        },
        "left_classes": {
          "title": "Cancel Subscription",
          "description": "Are you sure you want to cancel your subscription to regular classes? There are no refunds for past classes.",
          "cancel": "Do not cancel",
          "confirm": "Cancel"
        },
        "less_24_hours": {
          "title": "Less than 24 hours until class starts",
          "description": "If you cancel now, 50% of the paid class fee will be refunded to the tutor.",
          "cancel": "Do not cancel",
          "confirm": "Cancel"
        }
      },
      "course": {
        "no_classes": {
          "title": "Cancel participation",
          "description": "By canceling participation, you will lose access to this course",
          "cancel": "Do not cancel",
          "confirm": "Cancel"
        },
        "left_classes": {
          "title": "Cancel participation",
          "description": "By canceling participation, you will lose access to this course. No refunds for past classes.",
          "cancel": "Do not cancel",
          "confirm": "Cancel"
        },
        "less_24_hours": {
          "title": "Less than 24 hours before class starts",
          "description": "If you cancel now, 50% of the course fee paid will be paid to the tutor as compensation.",
          "cancel": "Do not cancel",
          "confirm": "Cancel"
        }
      }
    }
  },
  "come-visit-banner": {
    "advantage1": "Work remotely at a convenient time",
    "advantage2": "Any items for students from all over the world",
    "advantage3": "You decide how much to earn",
    "btn": "Become a tutor",
    "high1": "remotely?",
    "high3": "income?",
    "question1": "Want to teach",
    "question2": "Find new students?",
    "question3": "Get an additional",
    "step1": "Tell us about yourself",
    "step2": "Add your services",
    "step3": "Link your credit card and receive payments",
    "student": {
      "advantage1": "159 subjects",
      "advantage2": "1000+ free interactive exercises",
      "advantage3": "40+ tutors",
      "advantage4": "Study on PC, tablet or phone",
      "btn": "Registration / Authorization",
      "subtitle": "Study in a virtual classroom with your tutor or find a new tutor on Onclass.",
      "text": "As well as:",
      "title": "Study on Onclass.com"
    },
    "text1": "Do you want to teach remotely?",
    "text2": "Find new students or study with your own?",
    "text3": "Get additional income?",
    "title": "Teach on Onclass.com"
  },
  "comment": {
    "cancel": "Cancel",
    "cause1": "Spamming",
    "cause10": "Animal abuse",
    "cause11": "Misleading",
    "cause12": "Fraud",
    "cause13": "Extremism",
    "cause2": "Insult",
    "cause3": "Material for adults",
    "cause4": "Child pornography",
    "cause5": "Drug propaganda",
    "cause6": "Weapons sale",
    "cause7": "Violence",
    "cause8": "Call if there is harassment",
    "cause9": "Call to report suicide",
    "comments-label": "Comment to the moderator",
    "comments-placeholder": "Describe the problem",
    "complain-title": "Complaint about a comment",
    "explanation": "Please tell us the reason",
    "send": "Send"
  },
  "comments": {
    "answer": "Answer",
    "complain": "complain",
    "delete": "delete",
    "new-comment-placeholder": "Leave a comment...",
    "next-comments": "Show the following comments",
    "next-responses": "Show the following responses",
    "page-header-title": "Comments",
    "meta-title": "Comments",
    "stub": "Track activity in posts and lessons where you left comments"
  },
  "common-landing": {
    "class": {
      "text": "You will learn something new and understand how exciting distance learning can be.",
      "title": "Take a free lesson on Onclass!"
    },
    "constructor-carousel": {
      "text": "Onclass tutors create a variety of learning materials using the Interactive Lesson Builder. Instead of sleepy lectures - watching videos from Youtube, instead of boring control tests - fascinating exercises and tests.",
      "title": "Lessons from which it is impossible to break away"
    },
    "online-meetings-carousel": {
      "text": "Online conferences at Onclass are designed specifically for engaging learning. Videos, audio, images, exercises and tests, communication with the teacher and other students, an interactive whiteboard - all this will help to make learning interesting and effective.",
      "title": "Memorable online classes"
    },
    "participants-common": {
      "item1": {
        "text": "Choose the tutors, subject, knowledge level, language and class schedule that suits you",
        "title": "Personal customization"
      },
      "item2": {
        "text": "Find a tutor for your budget and practice from any device: smartphone, tablet or computer",
        "title": "Affordable training"
      },
      "item3": {
        "text": "Learn, immerse yourself in the material and do the exercises. Onclass Knowledge Library available for free",
        "title": "Free access to knowledge"
      },
      "start-as-student": "Start as a student",
      "start-teaching": "Start teaching",
      "subtitle": "Why Onclass?",
      "text": "3 reasons to choose Onclass",
      "title": "Onclass is an online platform with a modern approach to education"
    },
    "text": "We help tutors earn money, and for students to receive distance education.",
    "title": "Onclass is an international online educational platform"
  },
  "complaint-form": {
    "close": "Close",
    "info-title": "We will consider your complaint within 3 days and will contact you."
  },
  "constructor": {
    "all-content-types": "All",
    "archived": "Archived",
    "audio-meta-panel-title": "Description",
    "audio-panel-title": "Add podcast",
    "cancel": "Cancel",
    "choose-content-type": "Choose content type",
    "close": "Close",
    "create": "Create",
    "crop": "Crop",
    "crop-title": "Edit cover",
    "drafts": "Drafts",
    "edit": "Edit",
    "filter": "Filter",
    "filter-content-type": "Publication types",
    "filter-sort-order": "Sort order",
    "form-audio-placeholder1": "Drop podcast file",
    "form-audio-placeholder2": "or click here to choose",
    "form-audio-please-wait": "Please wait while the file is loaded ...",
    "form-audio-size-file-message": "The maximum file size is 100 MB.",
    "form-audio-size-file-title": "Your file is too large",
    "form-cover-image-placeholder1": "Drop cover image",
    "form-cover-image-placeholder2": "or click here to choose",
    "form-cover-submenu-change": "Change",
    "form-cover-submenu-edit": "Edit",
    "form-description-placeholder": "Description",
    "form-explanation-label": "Explanation",
    "form-explanation-placeholder": "Explanation after answer is done",
    "form-file-placeholder1": "Drop file",
    "form-file-placeholder2": "or click here to choose",
    "form-file-placeholder3": "Download from phone",
    "form-file-title": "Upload file",
    "form-hint-label": "Hint",
    "form-hint-placeholder": "Hint for an answer",
    "form-hint1": "Choose quantity of answer variants",
    "form-image-placeholder1": "Drop image",
    "form-image-placeholder2": "or click here to choose",
    "form-language-label": "Language",
    "form-language-placeholder": "Choose",
    "form-language-search-placeholder": "Search...",
    "form-media-url-label": "Paste the link to the video of your lesson in the box below",
    "form-media-url-placeholder": "https://www.youtube.com/",
    "form-other-level-placeholder": "Enter level",
    "form-other-subject-placeholder": "Discipline title",
    "form-question-label": "Question",
    "form-question-placeholder": "Question",
    "form-question-placeholder2": "Write or drop",
    "form-sub-title-placeholder": "Additional title",
    "form-subject-level-label": "Difficulty Level",
    "form-subject-level-placeholder": "Choose",
    "form-subject-placeholder": "Subject",
    "form-title-placeholder": "Title",
    "form-variant-placeholder": "Answer variant",
    "form-variant-placeholder2": "Write the answer variant",
    "form-variant-placeholder3": "Write the answer variant or drop file",
    "form-vocabulary-definition-placeholder": "Definition",
    "form-vocabulary-term-placeholder": "Term",
    "form-vocabulary-warning-language-not-defined": "Select language first",
    "form-vocabulary-warning-subject-not-defined": "Select subject first",
    "generate": "Generate",
    "generate-voice": "Generate",
    "group-ad": "Advertisements",
    "group-lessons": "Lessons",
    "group-media": "Media",
    "group-texts": "Texts",
    "image-panel-title": "Add image",
    "incomplete-item-prompt-body": "Before publishing, complete the materials",
    "incomplete-item-prompt-cancel": "Later",
    "incomplete-item-prompt-edit": "Go to editor",
    "incomplete-item-prompt-title": "Publishing",
    "incomplete-slide-prompt-body-empty": "Slide #{{ index }} is empty",
    "incomplete-slide-prompt-body-partial": "Slide #{{ index }} is incomplete",
    "incomplete-slide-prompt-cancel": "Later",
    "incomplete-slide-prompt-edit": "Edit",
    "incomplete-slide-prompt-title": "Warning",
    "lesson-meta-panel-title": "Lesson Description",
    "lesson-slides-panel-title": "Lesson Editor",
    "mass_publish": "Publish all",
    "mass_unpublish": "Unpublish all",
    "meta-mine-title": "My",
    "meta-title": "Content editor",
    "page-header-title": "Content editor",
    "preview": "Preview",
    "publish": "Publish",
    "publish-prompt-body": "Correct the notes:",
    "publish-prompt-title": "Before publishing a lesson ...",
    "published": "Published",
    "record-voice": "Record",
    "remote-item-prompt-body": "Post {{title}} will be deleted. This action cannot be undone. Are you sure you want to permanently delete this post?",
    "remote-item-prompt-cancel": "Cancel",
    "remote-item-prompt-confirm": "Remove",
    "remote-item-prompt-title": "Remove forever",
    "remote-slide-prompt-body": "Slide #{{ index }} will be removed.<br/>This action cannot be undone.<br/>Are you sure you want to delete the slide?",
    "remote-slide-prompt-cancel": "Cancel",
    "remote-slide-prompt-confirm": "Remove",
    "remote-slide-prompt-title": "Remove slide",
    "remove": "Remove",
    "remove2": "Remove forever",
    "restore": "Restore",
    "save": "Save",
    "sidebar-create-item": "Content manager",
    "slide-audio-title": "Audio",
    "slide-duplicate": "Duplicate slide",
    "slide-image-title": "Image",
    "slide-reading-title": "Reading",
    "slide-remove": "Remove slide",
    "slide-scale-down": "Zoom out slide",
    "slide-scale-up": "Zoom in slide",
    "slide-scale-warning": "not recommended",
    "slide-sortorder-backward": "Move slide backward",
    "slide-sortorder-forward": "Move slide forward",
    "slide-task-check-list-title": "Multiple choice",
    "slide-task-fill-gaps-title": "Text exercise",
    "slide-task-match-pairs-title": "Match answer options",
    "slide-task-radio-list-title": "Single choice",
    "slide-task-table-title": "Table",
    "slide-theory-title": "Theory",
    "slide-type-label": "Choose slide type",
    "slide-video-title": "Video",
    "sort-order.newest": "Newest",
    "sort-order.oldest": "Oldest",
    "sort-order.popular": "Most popular",
    "sort-order.rated": "Best rated",
    "stub": {
      "advice": "Please use a PC or laptop to create content",
      "text": "Lesson Builder is not supported on smartphones and tablets."
    },
    "templates": "Templates",
    "text1": "New types of slides and tasks will appear soon ...",
    "unpublish": "Unpublish",
    "upload": "Upload",
    "video-meta-panel-title": "Description",
    "video-panel-title": "Add video",
    "vocabulary-terms-panel-title": "Editor",
    "warning-empty-cover-image": "Cover image not selected",
    "warning-empty-language": "Language not defined",
    "warning-empty-level": "Difficulty level not defined",
    "warning-empty-subject": "Subject not defined",
    "warning-empty-text": "Text is absent",
    "warning-empty-title": "Title not defined",
    "warning-few-slides": "Few slides",
    "warning-few-task-slides": "Few task slides",
    "warning-few-terms": "Few terms in memorizer",
    "warning-incomplete-media": "File not selected",
    "warning-incomplete-slides": "Some slides are incomplete",
    "warning-incomplete-terms": "Some terms in the memorizer are incomplete"
  },
  "constructor2": {
    "prevention-alert": {
      "title": "Warning",
      "message": "If you change the number of cells, some of the entered information will be deleted.",
      "close": "Do not change",
      "apply": "Change"
    },
    "tabs": {
      "archived": "Archived",
      "drafts": "Drafts",
      "constructor": "Content editor",
      "private": "Private",
      "published": "Published",
      "templates": "Templates"
    },
    "cancel": "Cancel",
    "categories-description": {
      "audio": "This type of lesson can only contain audio content. Audio can be one or several.",
      "audio_lesson": "A lesson of this type can contain any content and at least one exercise with audio.",
      "exercise": "A lesson of this type can contain any content and at least one exercise to consolidate the material.",
      "image": "A lesson of this type can only consist of one or several images.",
      "news": "There is always one slide that can contain any media content, but it cannot include exercises.",
      "reading": "Typically, this is a lesson with several slides containing only text and images.",
      "reading_lesson": "In a lesson of this type, text and exercises must be present. It is not recommended to use audio and video.",
      "theory": "Any content can be used in such a lesson, except exercises.",
      "theory_exercise": "A lesson of this type can contain any content and at least one exercise to consolidate the material.",
      "video": "A lesson of this type can only consist of videos on a specific topic. Video can be one or several.",
      "video_lesson": "A lesson of this type can contain any content, but at least one video and one exercise must be present.",
      "vocabulary": "The vocabulary trainer will help you quickly memorize foreign words and phrases."
    },
    "close": "Close",
    "create": "Create",
    "crop": "Crop",
    "drafts": "Drafts",
    "dropzone": {
      "audio-placeholder1": "Drop podcast file",
      "image-placeholder1": "Drop image",
      "placeholder2": "or click here to choose",
      "please-wait": "Please wait while the file is loaded ...",
      "size-file-message": "The maximum file size is 100 MB.",
      "size-file-title": "Your file is too large"
    },
    "edit": "Edit",
    "errors": {
      "select-correct": "Select the correct answer"
    },
    "filter": {
      "all": "All",
      "content-type": "Publication types",
      "sort-order": "Sort order",
      "title": "Filter"
    },
    "form-description-placeholder": "Description",
    "form-language-label": "Lesson language",
    "form-language-description": "Choose the language you used to create the slides and description",
    "form-language-placeholder": "Choose",
    "form-language-search-placeholder": "Search...",
    "form-other-level-placeholder": "Enter level",
    "form-other-subject-placeholder": "Subject title",
    "form-sub-title-placeholder": "Additional title",
    "form-subject-level-label": "Difficulty Level",
    "form-subject-level-placeholder": "Choose",
    "form-subject-placeholder": "Subject",
    "form-title-placeholder": "Title",
    "generate": "Generate",
    "incomplete-alert": {
      "cancel": "Later",
      "edit": "Edit",
      "title": "Warning",
      "body": "Some fields are missing or there are errors. Please make the necessary corrections and try again"
    },
    "incomplete-item-prompt-body": "Before publishing, complete the materials",
    "incomplete-item-prompt-cancel": "Later",
    "incomplete-item-prompt-edit": "Go to editor",
    "incomplete-item-prompt-title": "Publishing",
    "mass_delete": "Delete all",
    "mass_publish": "Publish all",
    "mass_unpublish": "Unpublish all",
    "page-header-title": "Content editor",
    "preview": "Preview",
    "publish": "Publish",
    "published": "Published",
    "published-modal": {
      "copied": "Copied",
      "copy": "Copy link",
      "description": "After passing the moderation, it will appear in the specified section, but you can now share the lesson with your friends",
      "title": "Lesson published!"
    },
    "private": "Private",
    "remote-item-prompt-body": "Post {{title}} will be deleted. This action cannot be undone. Are you sure you want to permanently delete this post?",
    "remote-item-prompt-cancel": "Cancel",
    "remote-item-prompt-confirm": "Remove",
    "remote-item-prompt-title": "Remove forever",
    "remove": "Remove",
    "remove2": "Remove forever",
    "restore": "Restore",
    "save": "Save",
    "section": {
      "example": "Example",
      "explanation": "Explanation",
      "fill-the-gaps-tip": "Enter your text, highlight a specific word, and choose the task type:",
      "gap-drop": "Drag and drop",
      "gap-input": "Input field",
      "gap-select": "Select list",
      "hint": "Hint",
      "remove": "Remove",
      "video-label": "Paste the link to the video of your lesson in the box below",
      "video-placeholder": "https://www.youtube.com/"
    },
    "section-types": {
      "audio": "Audio",
      "check-list": "Multiple choice answer",
      "fill-gaps": "Text exercise",
      "image": "Image",
      "match-pairs": "Match the pairs",
      "radio-list": "Single choice answer",
      "reading": "Reading",
      "table": "Table",
      "theory": "Theory",
      "video": "Video",
      "vocabulary": "Vocabulary"
    },
    "sections-select": {
      "content": "Content sections",
      "exercises": "Exercise sections",
      "vocabulary": "Vocabulary"
    },
    "sections-toolbar": {
      "bottom": "Move section bottom",
      "duplicate": "Duplicate section",
      "remove": "Remove section",
      "top": "Move section top"
    },
    "settings-form": {
      "alert": {
        "cancel": "Cancel",
        "delete": "Delete",
        "text": "Are you sure you want to delete the lesson? <br/>You can always find deleted lessons in the \"Archive\" section",
        "title": "Warning"
      },
      "answers": {
        "exercise": "Exercise",
        "exercise-description": "Exercises are checked directly in each slide by clicking the \"Check\" button",
        "not-limited": "Not limited",
        "testing": "Testing",
        "testing-description": "All exercises are checked at the very end of the lesson, regardless of the number and type of slides in it.",
        "time-limit": "Time limit (minutes)",
        "title": "Lesson type"
      },
      "delete-button": "Delete lesson",
      "main": {
        "sub-title": "Select the category that accurately represents your lesson ",
        "title": " Choose your lesson category"
      },
      "navigation": {
        "answers": "Lesson settings",
        "main": "Main",
        "privacy": "Privacy settings"
      },
      "privacy": {
        "private-description": "The private lesson will appear in the \"My Library\" > \"Private\" section and will be available to you and your students during classes.",
        "private-label": "Private lesson",
        "public-description": "The public lesson will be sent for moderation. After approval, it will appear in the \"My Library\" > \"Public\" section and will be available to all tutors and students.",
        "public-label": "Shared (public)",
        "title": "Who will see my lesson"
      }
    },
    "slide": {
      "add-more": "Add section",
      "has-duplicate": "There are matching answers",
      "empty-gaps": "Highlight a specific word, and choose the task type",
      "empty-field": "There are fields that must be filled in",
      "no-correct": "You must choose the correct answers",
      "few-variants": "You can't leave blank answers.",
      "incomplete-pairs": "Pairs uncompleted",
      "incomplete-media": "Media uncompleted",
      "add-section": "Add section",
      "empty-text": "Add various types of content into your slide, such as text, videos, podcasts, images, or interactive exercises."
    },
    "placeholder": {
      "question": "For example: What is the area of the rectangle",
      "gaps-question": "For example: Substitute the correct answers",
      "variant": "Possible answer",
      "write-something": "Write something",
      "cell-placeholder": "Write an answer or drag and drop a file",
      "gaps-content": "Type here..."
    },
    "slide-image": {
      "label": "Upload the picture"
    },
    "slide-question": {
      "label": "Question"
    },
    "slide-fill-gaps": {
      "title": "Select List",
      "save_action": "Save",
      "add_options": "Add Options"
    },
    "slides-toolbar": {
      "slide": "Slide",
      "add-slide": "Add slide",
      "backward": "Move slide backward",
      "duplicate": "Duplicate slide",
      "forward": "Move slide forward",
      "remove": "Remove slide"
    },
    "sort-order.newest": "Newest",
    "sort-order.oldest": "Oldest",
    "sort-order.popular": "Most popular",
    "sort-order.rated": "Best rated",
    "steps": {
      "description": "Building engaging lessons and exercises is easier than ever!  Our platform lets you create dynamic lessons and exercises with just a few clicks. Drag and drop any combination of text, images, videos, and more to bring your lessons to life",
      "edit": "Edit:",
      "goto-description": "Next",
      "goto-settings": "Next",
      "step": "Step {{ index }}.",
      "step-1": {
        "description": "Create a lesson using the editor",
        "label": "Editor"
      },
      "step-2": {
        "description": "Create a lesson description",
        "label": "Description"
      },
      "step-3": {
        "description": "Settings",
        "label": "Settings"
      }
    },
    "stub": {
      "advice": "Please use a PC or laptop to create content",
      "text": "Lesson Builder is not supported on smartphones and tablets.",
      "unsupported-type": "You can't edit {{ type }} in the constructor."
    },
    "templates": "Templates",
    "unpublish": "Unpublish",
    "upload": "Upload",
    "warnings": {
      "empty-cover-image": "Cover image not selected",
      "empty-language": "Language not defined",
      "empty-discount": "Empty discount",
      "empty-tiers": "Empty tiers",
      "empty-level": "Difficulty level not defined",
      "empty-subject": "Subject not defined",
      "empty-text": "Text is absent",
      "empty-title": "Title not defined",
      "few-slides": "Few slides",
      "few-task-slides": "Few task slides",
      "few-terms": "Few terms in memorizer",
      "incomplete-media": "File not selected",
      "incomplete-slides": "Some slides are incomplete",
      "incomplete-terms": "Some terms in memorizer are incomplete",
      "no-description": "No description",
      "no-students": "No selected students",
      "has-duplicate-variant": "Some tasks in slides have similar or same answers"
    }
  },
  "auto-save": {
    "saved": "Saved",
    "not-saved": "Not saved"
  },
  "lesson-constructor": {
    "actions": {
      "save-private-lesson": "Save private lesson",
      "send-to-moderation": "Send for moderation",
      "save": "Save",
      "dont-save": "Don't save",
      "dont-send": "Don't send"
    },
    "alerts": {
      "moderate": {
        "title": "Send lesson for moderation?",
        "description": "Lesson will be moved to “Lessons” -> “Pending”. Once moderation is complete, it will appear under “Public” lessons and become available to all users."
      },
      "private": {
        "title": "Save private lesson?",
        "description": "Lesson will be moved to “Private”. It won’t be shown in the public lesson catalog, but you can add it to the dashboard during classes with your students."
      }
    }
  },
  "collections-constructor": {
    "tabs": {
      "archived": "Archived",
      "moderation": "Awaiting moderation",
      "drafts": "Drafts",
      "constructor": "Collection constructor",
      "private": "Private",
      "purchased": "Purchased",
      "published": "Published",
      "on-moderation": "Awaiting moderation"
    },
    "steps": {
      "description": "Add lessons into your collection. You can add as many lessons as needed to the collection.",
      "step": "Step {{ index }}.",
      "step1": {
        "label": "Add lessons",
        "description": "Add lessons to the collection"
      },
      "step2": {
        "label": "Description",
        "description": "Describe the collection"
      },
      "step3": {
        "label": "Settings",
        "description": "Set the collection settings"
      }
    },
    "settings-subforms-nav": {
      "main": "Access and pricing",
      "set-type-price": "Set access and pricing"
    },
    "form": {
      "create-title": "Create new collection",
      "edit-title": "Description",
      "subject-label": "Subject",
      "category-label": "Category",
      "category-placeholder": "Category",
      "description-placeholder": "Description",
      "language-label": "What language is the collection in?",
      "excerpt-placeholder": "Additional title",
      "title-placeholder": "Title",
      "error-not-chosen": "Not chosen",
      "error-required-to-fill": "Required to fill",
      "error-min-length": "Minimum {{n}} characters",
      "info-block-label": "Describe the collection",
      "access-private-label": "Private access",
      "access-commercial-label": "Public access",
      "license-personal-label": "Basic Access (Personal Access)",
      "license-professional-label": "Professional access (Use in the classroom)",
      "license-business-label": "Business Access (Use in schools)",
      "discount-label": "Discount",
      "discount-placeholder": "Not selected",
      "price-placeholder": "Not active",
      "open-content-label": "Number of free (open) lessons",
      "error-price-required": "You must specify the price of the collection in one of the three access options"
    },
    "actions": {
      "publish": "Publish",
      "edit": "Edit",
      "remove": "Remove",
      "remove2": "Remove forever",
      "restore": "Restore",
      "goto-description": "Next",
      "goto-settings": "Next",
      "add-content1": "Add lessons",
      "remove-content": "Remove",
      "add-content2": "Add more lessons",
      "remove-collection": "Delete a collection",
      "save": "Save",
      "moderation-button": "Send for moderation",
      "private-lesson": "Private lesson"
    },
    "incomplete-alert": {
      "title": "Before publishing, complete the materials",
      "later": "Later",
      "edit": "Go to editor",
      "continue": "Continue"
    },
    "remote-item-prompt": {
      "body": "Collection {{title}} will be deleted. This action cannot be undone. Are you sure you want to permanently delete this collection?",
      "cancel": "Cancel",
      "confirm": "Remove",
      "title": "Remove forever"
    },
    "texts": {
      "add-content1": "Create your private collection of lessons or make it public and earn money",
      "access-private1": "Private access",
      "access-private2": "Collections are available only to you. you can use in my classes with students",
      "license-personal1": "Basic access",
      "license-personal2": "The student will be able to independently study lessons from the collection",
      "license-professional1": "Professional access",
      "license-professional2": "The tutor will be able to use the lessons from the collection for teaching on the Onclass platform",
      "license-business1": "Business access",
      "license-business2": "Lessons from the collection can be used for teaching in an online school on the Onclass platform",
      "license": "License agreement for the use of collections",
      "open-content-hint": "The lessons are counted from the very beginning",
      "no-free-lessons": "No free lessons",
      "teacher-need-card": "To receive payment, you need to link a bank card",
      "teacher-need-card-link": "Link a card",
      "teacher-need-card-btn": "Link"
    },
    "content-selector": {
      "search-placeholder": "Search",
      "add-to-collection": "Add more lessons",
      "add-lesson": "Add a lesson",
      "no-lessons1": "You don't have lessons to add to the collection.",
      "no-lessons2": "Create more lessons using the lesson editor.",
      "create-lesson": "Create a lesson",
      "title": "Choose lessons and add in your collection",
      "title-lesson": "Select a lesson",
      "invalid-hint": "Select a lesson and click the button"
    },
    "meta-title": "Collections",
    "meta-title-editor-new": "New collection",
    "meta-title-purchased": "Purchased collections",
    "meta-title-published": "Published collections",
    "meta-title-drafts": "Drafts collections",
    "meta-title-archived": "Archived collections",
    "meta-title-private": "Private collections",
    "delete-confirmation": {
      "title": "Are you sure you want to delete the collection?",
      "text": "If canceled, the collection will not be deleted",
      "cancel": "Cancel",
      "delete": "Delete"
    }
  },
  "collections": {
    "meta-title": "Collections",
    "collection": "Collection",
    "back-to-collection": "Back to collection",
    "paid-content-informer": {
      "title": "Paid content",
      "message": "Purchase a collection to have access to restricted materials",
      "action": "Choose a license"
    },
    "private-content-informer": {
      "title": "Private lesson",
      "message": "Purchase a collection to access the lesson.",
      "action": "Choose a license",
      "incollection": "This lesson is included in the collection"
    },
    "text": {
      "license-type-label": "Access type: {{ label }} (Use for classes)",
      "discount": "Discount: {{ discount }}%",
      "license": "License",
      "items": "Lessons:",
      "reviews-count": "reviews",
      "main-tab": {
        "all": "All"
      },
      "free": "for free"
    },
    "actions": {
      "menu-complain": "Complain",
      "menu-edit": "Description",
      "menu-share": "Share",
      "menu-remove": "Remove collection",
      "upgrade-license": "Upgrade the license",
      "choose-license": "Choose a license",
      "buy": "Buy",
      "details": "Show more",
      "edit": "Edit",
      "delete": "Delete",
      "remove-from-publication": "Unpublish",
      "license": "Choose a license"
    },
    "moderation-status": {
      "fail": "The collection has not been moderated",
      "pending": "Awaiting moderation",
      "on-moderation": "Awaiting moderation"
    },
    "drag-scroll": {
      "title": "Collections",
      "text": "Thematic lesson collections",
      "create": "Build your collection"
    },
    "collection-license-selector": {
      "title": "Choose your access",
      "discount": "{{ discount }}% discount",
      "description-personal": "You will be able to use the collection only for studying the material.",
      "description-professional": "You can use the collection in class with your students.",
      "description-business": "You will be able to use the collection in your schools on Onclass.",
      "total": "Total: {{ total }}",
      "cost": "Cost",
      "action-pay": "Buy",
      "already-use": "Already in use",
      "cannot-buy": "You can't buy your own collection"
    },
    "page-header-title": "Collections"
  },
  "collection-license": {
    "personal": "Student access",
    "professional": "Tutors access",
    "business": "Schools access"
  },
  "collection-license-short": {
    "personal": "For students",
    "professional": "For tutors",
    "business": "For school"
  },
  "content": {
    "cancel": "Cancel",
    "cause1": "Spam",
    "cause10": "Animal abuse",
    "cause11": "Misleading",
    "cause12": "Fraud",
    "cause13": "Extremism",
    "cause2": "Insult",
    "cause3": "Material for adults",
    "cause4": "Child pornography",
    "cause5": "Drug propaganda",
    "cause6": "Arms sale",
    "cause7": "Violence",
    "cause8": "Call for Harassment",
    "cause9": "Call for suicide",
    "comments-label": "Comment to moderator",
    "comments-placeholder": "Describe the problem",
    "complain": "Complain",
    "complain-title": "Content complaint",
    "explanation": "Please tell us the reason",
    "page-header-title": "Users",
    "search": "Search...",
    "send": "Send",
    "send-complaint": "Send a complaint",
    "subscribe": "Follow",
    "tabs-all": "All",
    "tabs-subscribed": "Subscribed",
    "tabs-subscriptions": "Subscriptions",
    "to-write": "Write",
    "unsubscribe": "Unsubscribe",
    "you-subscribed": "You are subscribed"
  },
  "content-mini-card": {
    "article": "Article",
    "author": "Author:",
    "native": "Native",
    "news": "News",
    "theory": "Theory",
    "archived": "Archived",
    "declined": "Declined by moderator",
    "draft": "Draft",
    "added": "Lesson added!",
    "already-added": "Lesson already added!"
  },
  "content-type-title": {
    "all": "All",
    "article": "Article",
    "articles": "Article",
    "audio": "Podcast",
    "audio-exercises": "Audio exercises",
    "audio-lesson": "Audio lessons",
    "document": "Document",
    "exercise": "Exercise",
    "exercises": "Exercises",
    "image": "Image",
    "images": "Image",
    "lesson": "Exercises",
    "news": "News",
    "podcasts": "Podcast",
    "posts": "Post",
    "reading": "Reading",
    "reading-exercises": "Reading exercises",
    "reading-lesson": "Reading lessons",
    "test": "Test",
    "theory": "Theory",
    "theory-exercise": "Theory with exercises",
    "theory-exercises": "Theory with exercises",
    "video": "Video",
    "video-exercises": "Video exercises",
    "video-lesson": "Video lessons",
    "vocabulary": "Vocabulary",
    "vocabulary-exercises": "Vocabulary exercises"
  },
  "content-types-title": {
    "article": "Articles",
    "audio": "Podcasts",
    "audio_lesson": "Audio exercises",
    "document": "Documents",
    "exercise": "Exercises",
    "image": "Images",
    "lesson": "Lessons",
    "news": "News",
    "reading": "Reading",
    "reading_lesson": "Reading exercises",
    "test": "Tests",
    "theory": "Theory",
    "theory_exercise": "Theory with exercises",
    "video": "Video",
    "video_lesson": "Video exercises",
    "vocabulary": "Vocabulary"
  },
  "currency": {
    "CREDITS": "Δ",
    "EUR": "EUR",
    "RUR": "RUB",
    "RUB": "RUB",
    "USD": "USD",
    "GBP": "GBP",
    "UAH": "UAH",
    "BYR": "BYR",
    "BYN": "BYN",
    "KZT": "KZT",
    "AZN": "AZN",
    "CHF": "CHF",
    "CZK": "CZK",
    "CAD": "CAD",
    "PLN": "PLN",
    "SEK": "SEK",
    "TRY": "TRY",
    "CNY": "CNY",
    "INR": "INR",
    "BRC": "BRC",
    "ZAR": "ZAR",
    "UZS": "UZS",
    "BGL": "BGL",
    "JPY": "JPY",
    "NOK": "NOK",
    "DKK": "DKK",
    "ISK": "ISK",
    "HRK": "HRK",
    "RON": "RON",
    "TWD": "TWD",
    "SGD": "SGD",
    "AED": "AED",
    "AMD": "AMD"
  },
  "transaction-history": {
    "history": "History",
    "for-all": "for all cards and accounts",
    "all-cards": "All cards and accounts",
    "card": "Card",
    "account": "Account",
    "tabs": {
      "all": "All",
      "credit": "Credit",
      "debit": "Debit"
    },
    "operations-for": "Months:",
    "search-placeholder": "Operation, amount",
    "date": "Date",
    "operation": "Operation",
    "amount": "Amount",
    "status": "Status",
    "types": {
      "PURCHASE": "Purchase",
      "SELLER_REFUND": "Seller Refund",
      "SELLER_PAYMENT_RECEIVED": "Seller Payment Received",
      "SELLER_PREPAYMENT_RECEIVED": "Seller Prepayment Received",
      "REFUND_PURCHASE": "Refund Purchase",
      "PAYOUT": "Payouts"
    },
    "statuses": {
      "completed": "completed",
      "canceled": "canceled",
      "pending": "pending"
    }
  },
  "dashboard": {
    "accept_required": "An invitation from a tutor received",
    "class-form": {
      "add-more": "Add more",
      "cancel": "Cancel",
      "confirm": "Confirm",
      "date": "Date",
      "not-selected": "Not selected",
      "occupation": "Occupation",
      "participant": "Participant",
      "title": "New occupation"
    },
    "date": "Date:",
    "event-card": {
      "cross-hint": "You already have another meeting planned at <span class=\"hint-text time normal\">{time}</span>. You have to decline one of them."
    },
    "event-state": {
      "canceled": "Canceled classes",
      "going": "On air",
      "past": "Past classes",
      "past-message": "No past classes",
      "unconfirmed": "Unconfirmed classes",
      "upcoming": "Upcoming"
    },
    "grade": "Grade:",
    "label": {
      "cost": "Cost: "
    },
    "label-btn": {
      "accept": "Accept",
      "accept-and-pay": "Accept and pay",
      "awaiting-confirmation": "Awaiting confirmation ...",
      "cancel": "Cancel meeting",
      "cancel-invite": "Cancel invitation",
      "cancel-waiting-header": "Awaiting cancel...",
      "cancel-waiting-text": "Can take couple minutes",
      "close": "Close",
      "confirm": "Confirm",
      "decline": "Decline",
      "enter-class": "Enter class",
      "reject": "Reject",
      "reschedule": "Reschedule",
      "reschedule-decline": "Cancel",
      "proceed-to-checkout": "Proceed to checkout",
      "waiting": "Waiting...",
      "withdraw-invitation": "Withdraw invitation",
      "leave": "Leave"
    },
    "leave-message": "Message",
    "meeting-cancel-confirmation": {
      "100-percent-sure": "Confirm",
      "error": "Error",
      "error-text": "The meeting can't be canceled right now.",
      "grade": "Auto-grade: ",
      "im-pissed-off": "Not confirm",
      "ok": "Ok",
      "penalty": "Penalty: ",
      "text": "Additional fines and rating changes can be applied. Are you sure to continue?",
      "title": "Are you sure to cancel this event?"
    },
    "meeting_reservation_unpaid": "You have an unpaid class",
    "course_reservation_unpaid": "You have an unpaid course",
    "meeting_reservation_waiting": "Awaiting Payment",
    "more-event": "Show more",
    "new-date": "New date:",
    "no-past-classes": "You have not passed any classes.",
    "old-date": "Old date:",
    "paid_accept_required": "This class requires payment",
    "paid_accept_required_simple": "Received an invitation to a class",
    "participation-free": "Free",
    "participation-price": "Participation:",
    "past-events-student-title": "Past classes with tutors",
    "past-events-teacher-title": "Past classes with students",
    "popup": {
      "cancel-lesson": "Cancel class",
      "change-data-time": "Change date and time",
      "details": "Show more",
      "invite-subscriber": "Invite",
      "multi-events.description": "You can cancel some of them or try to participate in all",
      "multi-events.header": "This event will be in",
      "multi-events.header-school": "There are {{count}} events scheduled for this time",
      "new-class": "New occupation",
      "non-working": "Indicate as non-working hours",
      "reschedule": "Reschedule class",
      "working": "Indicate as working hours",
      "join-class": "Join class"
    },
    "reschedule": {
      "alert-body": "In case of rejection, the date of the class will remain the same, but the tutor can cancel it.",
      "alert-title": "Reject date change request?",
      "grade": "Tutors rating:",
      "held": "Held:",
      "meetings": "meetings",
      "teacher": "Tutor:",
      "title": "Time reschedule"
    },
    "reschedule_requested": "A time reschedule request has been sent",
    "reschedule_required": "Time reschedule was requested",
    "schedule": {
      "today": "Today"
    },
    "stat": {
      "average-rating": "Tutors rating",
      "classes": "Classroom activities",
      "lesson-points": "Lesson Points",
      "lessons-completed": "Lessons completed",
      "publications": "Publications and lessons",
      "status": "Status",
      "students": "Students",
      "subtitle-student": "Statistics",
      "subtitle-teacher": "Increases student confidence in you",
      "teachers": "My tutors",
      "title": "Study",
      "tutor": "Tutor"
    },
    "student": "Student:",
    "student-not-invited": "not invited",
    "subtitle1": "Classes with students",
    "subtitle2": "Classes with tutors",
    "subtitle2_explain1": "You haven't upcoming classes. Find <a href='/teachers'> tutors</a> for yourself or sign in <a href='/classes'>any class</a>.",
    "subtitle2_explain2": "You haven't passed classes.",
    "tabs": {
      "all-news": "All news",
      "dashboard": "Dashboard",
      "events": "Classes",
      "feed": "Feed",
      "homework": "Homework",
      "main": "Main",
      "schedule": "Schedule",
      "teacher": "Tutor's office",
      "teaching": "I teach"
    },
    "teacher": "Tutor:",
    "time-before-start": "Start in",
    "time-in-minute": "in less than minute",
    "time-in-minute-countdown": "less than minute",
    "title": "Dashboard",
    "unconfirmed-causes": {
      "received-invite-student": "Received invitation from student",
      "received-invite-teacher": "Invitation from tutor received",
      "received-lesson-change": "Lesson change request received",
      "reservation": "Lesson is under reservation",
      "sent-invite-student": "Student invitation sent",
      "sent-lesson-change": "Lesson change request sent"
    },
    "upcoming-events-student-explain": "You have no upcoming classes. Find <a href='/teachers'>tutors</a> for yourself or sign in to <a href='/classes'>any class</a>.",
    "upcoming-events-student-title": "Upcoming classes with tutors",
    "upcoming-events-teacher-explain": "You don't have any upcoming class. Create <a href='/lessons'>lessons</a> to let students find you or invite your students to <a href='/classes'>classes</a>.",
    "upcoming-events-teacher-title": "Upcoming classes with students",
    "wallet": {
      "balance": "Balance",
      "current": "Current balance",
      "income": "You earned in a month",
      "passive-income": "Passive income",
      "private-credit": "Credits for private classes",
      "replenish": "Top up",
      "subtitle": "Information about your finances",
      "title": "Wallet",
      "withdraw": "Withdraw money",
      "withdraw2": "Withdraw from account"
    },
    "nearest-meet-widget": {
      "tutor": "Tutor",
      "on_air": "On Air",
      "enter_class": "Enter class",
      "show_more": "See all",
      "start_in": "Start in",
      "class-started": "Ongoing class...",
      "nearest-class": "Upcoming class..."
    },
    "your-income": "Participation:"
  },
  "dashboard-plan-widget": {
    "action-redeem-coupon": "Redeem coupon",
    "action-upgrade": "Upgrade plan",
    "balance-title": "Balance",
    "next-updates": "Date next updates",
    "plan-title": "Your plan",
    "update": "Update frequency"
  },
  "datetime-picker": {
    "no-time": "Unavailable",
    "text": "Choose a new class time",
    "timezone": "Based on your time zone",
    "teacher-busy": "At the moment, the tutor has no free time.<br>You can contact the teacher and try to negotiate.",
    "teacher-chat": "Contact tutor"
  },
  "default": {
    "meta-title": "-",
    "page-header-title": ""
  },
  "domain-selector": {
    "confirm": "Confirm",
    "text": "The language of your browser is {{ term }}. Do you want to switch the Onclass.com website to Russian?"
  },
  "profile-steps": {
    "personal-information": "Personal information",
    "teaching": "Teaching",
    "services": "Classes",
    "schedule": "Schedule",
    "bank-card": "Add bank card",
    "bank-card-world": "For worldwide payment",
    "bank-card-rus": "For payment within Russia",
    "test-bank-card": "Test card",
    "test-provider": "Test provider",
    "test-beneficiary": "Test beneficiary",
    "payment-info": "Payment information",
    "payin-info": "Payment details",
    "payout-info": "Payout details",
    "application": "Mobile app",
    "acquaintance": "Profile",
    "about-you": "About",
    "contacts": "Contacts",
    "education": "Education",
    "work-experience": "Work experience"
  },
  "edit-profile": {
    "edit": "Edit profile",
    "tabs-education": "Education",
    "tabs-personal": "Personal Information",
    "tabs-security": "Contacts",
    "tabs-studying": "Studying"
  },
  "edit-teacher-profile": {
    "page-header-title": "Tutor profile verification",
    "tabs-about": "Personal information",
    "tabs-billing": "Billing",
    "tabs-education": "Education",
    "tabs-experience": "Experience",
    "tabs-security": "Contacts",
    "tabs-teaching": "I teach"
  },
  "feed": {
    "meta-title": "Feed",
    "new-lessons-block-title": "New Lessons",
    "new-tests-block-title": "New Tests",
    "new-theory-block-title": "New Theory",
    "page-header-title": "Welcome to Onclass",
    "pagination-nothing-more": "Nothing more",
    "post-form": {
      "placeholder": "Anything new?"
    },
    "recommended-lessons-block-title": "Recommended Lessons",
    "show-more": "show more",
    "tasks-block-title": "Homework",
    "teachers-block-title": "New tutors"
  },
  "finance": {
    "card-create": {
      "title": "Linking a new debit card",
      "sub-title": "Credit card linking is not possible",
      "to-confirm": "To confirm the card, a small amount will be debited from it and returned back"
    },
    "card-page": {
      "add-card": "Add card",
      "card-remove-error-link": "You have an unpaid class",
      "card-remove-error-message": "There are pending transactions on this card. More details can be found in the history. Deletion is possible only after the completion of all transactions",
      "card-remove-error-title": "This card cannot be removed temporarily",
      "description": "Add a bank card to receive students payments.",
      "informer": "If it is not displayed in the list <br> after linking a bank card, please write to support.",
      "no-cards": "No linked cards",
      "support": "Send to support",
      "title": "Bank cards"
    },
    "tabs": {
      "billing": "Form",
      "cards": "Bank cards",
      "history": "History",
      "main": "Main",
      "payment": "Payments",
      "payout": "Payouts",
      "plan": "Plans",
      "subscriptions": "Subscriptions",
      "settings": "Payment Settings"
    },
    "title": "Payment profile"
  },
  "game": {
    "replay-game-confirmation": {
      "action-replay": "Start again",
      "action-show": "Show results",
      "body": "You can choose to see last results or do this lesson one more time",
      "title": "Would you like to start this lesson again?"
    }
  },
  "game-review": {
    "comment-placeholder": "Leave a comment...",
    "review-title": "Please rate the lesson",
    "success-title": "Thanks for your feedback!"
  },
  "games-header": {
    "author": "Author",
    "back-to-lesson": "Back to lesson",
    "contact": "Contact",
    "contact-teacher": "You can contact the tutor.",
    "create-content": "Create content and engage students.",
    "form": {
      "contact": "Contact tutor",
      "copied": "Copied",
      "copy-link": "Copy link to the lesson",
      "date-placeholder-not-ok": "No dates",
      "date-placeholder-ok": "Date and time",
      "lessons": "Classes:",
      "lessons-placeholder-not-ok": "No available lessons",
      "lessons-placeholder-ok": "Choose your online class",
      "select-date": "select date and time",
      "signup": "Sign up",
      "signup-own-lesson": "You cannot sign up for your own class, but you can invite students"
    },
    "no-lessons": "The tutor has no available classes.",
    "sign-up-teacher": "Sign up to the tutor",
    "start-teaching": "Start teaching on the Onclass.",
    "subscribe": "Subscribe",
    "teacher-has-lessons": "Tutor has available classes",
    "teacher-has-no-lessons": "The tutor has no available classes",
    "unsubscribe": "Classes2",
    "you-have-lessons": "You have available lessons",
    "you-have-no-lessons": "You have no available lessons"
  },
  "greetings": {
    "afternoon": "Good afternoon, {{user}}",
    "evening": "Good evening, {{user}}",
    "morning": "Good morning, {{user}}",
    "night": "Good night, {{user}}",
    "welcome": "Welcome to Onclass.com"
  },
  "hints": {
    "action-explain": "Explain",
    "action-hide": "Hide",
    "hint-feed.title": "How does the content feed work?",
    "hint-feed.var1": "The posts in the feed allow you to communicate with your subscribers easily. The helpful and interesting posts will help you make friends and gain new subscribers and students.",
    "hint-feed.var2": "Become an education blogger. Earn money by advertising, share helpful information with the subscribers, gain new students, inform them of upcoming conferences, post new exercises, tests, or share exciting news with the audience.",
    "hint-feed.var3": "Stay tuned for other subscribers’ updates on the feed. The new learning content created with the help of the lesson builder will also appear on your feed. Give it a try!",
    "hint-library-add-to-favorites.title": "You have no items in favorites yet",
    "hint-library-add-to-favorites.var1": "Add some nice pieces to favorites to find it quickly here",
    "hint-library.action-create": "Create a lesson",
    "hint-library.title": "How does the library work?",
    "hint-library.var1": "Create your lessons and exercises, upload the videos, and other types of media content with the help of our lesson builder.",
    "hint-library.var2": "The exciting posts will help you gain new subscribers, and the useful learning content will allow you to acquire new students. You can share your content with other subscribers.",
    "hint-library.var3": "Create your exercises and display them on the interactive whiteboard during an online conference. These exercises can also be done as homework assignments.",
    "hint-meetings.action-create": "Create a conference",
    "hint-meetings.action-demo": "See the tutorial",
    "hint-meetings.title": "How does the online conference work?",
    "hint-meetings.tooltip1": "Toolbar",
    "hint-meetings.tooltip2": "Individual or group classes",
    "hint-meetings.tooltip3": "Upload your lessons, exercises, images, video",
    "hint-meetings.tooltip4": "Interactive whiteboard",
    "hint-meetings.tooltip5": "Chatroom for communication",
    "hint-meetings.tooltip6": "Student list",
    "hint-meetings.tooltip7": "Tutor’s webcam",
    "hint-meetings.tooltip8": "Participant’s webcam",
    "hint-meetings.tooltip9": "Chalkboard Exercise",
    "hint-meetings.var1": "Create your lessons and exercises, upload the videos, and other types of media content with the help of our lesson builder.",
    "hint-meetings.var2": "The exciting posts will help you gain new subscribers, and the helpful learning content will allow you to acquire new students. You can share your content with other subscribers.",
    "hint-meetings.var3": "Create your exercises and display them on the interactive whiteboard during an online conference. These exercises can also be done as homework assignments.",
    "hint-not-found-mine.title": "You have no items published yet",
    "hint-not-found-mine.var1": "<i>Create</i> some lessons, articles, videos, podcasts, etc. and <em>Publish</em> them",
    "hint-teachers.action-teacher": "Become a tutor",
    "hint-teachers.title": "How does the tutor catalog work?",
    "hint-teachers.var1": "Start teaching online on onclass.com. Complete your tutor profile, and it will appear in the tutor catalog.",
    "hint-teachers.var2": "Onclass users will see your profile; it will also appear in Google and Yandex search results. Teach online anytime."
  },
  "invite-card": {
    "action-cancel": "Cancel",
    "action-copy-link": "Copy link",
    "action-invite": "Invite",
    "get-link": "Copy your invitation link",
    "invitation-accepted": "Invitation accepted",
    "invitation-declined": "Invitation declined",
    "invitation-sent": "Invitation sent",
    "link-copied": "Link copied",
    "title": "Invite to class"
  },
  "job-landing": {
    "actions": {
      "interested": "I'm interested!",
      "want-to-learn": "I want to learn",
      "want-to-see": "I want to see",
      "want-to-teach": "I want to teach"
    },
    "class": {
      "subtitle": "Online conference on Onclass.com",
      "text": "Create an online class, invite students,<br> add study material and start the lesson",
      "title": "Prepare a group or<br> individual class in 5 minutes"
    },
    "constructor-carousel": {
      "slide": {
        "exercises-text": "Exercises with text",
        "gallery": "Presentations / Pictures / PDF",
        "lesson": "Exercises",
        "media": "Video and audio",
        "theory": "Theory",
        "vocabulary": "Vocabulary"
      },
      "subtitle": "Interactive lesson builder",
      "text": "And your students will not be able to tear themselves away from their studies",
      "title": "Create multimedia exercises or<br> use ready-made ones - from the Onclass library"
    },
    "footer": {
      "about": "Personal information",
      "classes": "Classes",
      "library": "Lessons",
      "offer": "Public Offer",
      "privacy": "Privacy policy",
      "salesrefund": "Sales and returns",
      "teachers": "Tutors",
      "terms": "Terms of Use"
    },
    "header": {
      "text": "Individual and group online classes, interactive lesson builders, and learners thirsty for knowledge are already waiting for you!",
      "title": "Teach<br> on Onclass"
    },
    "meetings": {
      "online-classes": "Online classes",
      "subtitle": "Teach with us",
      "text": "Our students find tutors themselves.<br> You just need to create content that suits them.",
      "title": "Concentrate on learning<br> and the search for students is undertaken by Onclass!"
    },
    "online-meetings-carousel": {
      "slides": {
        "audio": "Audio",
        "board": "Board",
        "exercises": "Exercises",
        "gallery": "Images",
        "pdf": "PDF",
        "presentation": "Presentation",
        "theory": "Theory",
        "video": "Video",
        "vocabulary": "Vocabulary"
      },
      "subtitle": "Online conference on Onclass.com",
      "text": "Live you can draw and write on an interactive whiteboard, communicate<br>with students, test their knowledge and add any materials to your classes.",
      "title": "Use an online conference,<br>created for distance learning"
    },
    "participants": {
      "item1": {
        "text": "In the morning, in the evening, in the afternoon, at night, it doesn't matter.<br> The main thing is that you and the students feel comfortable",
        "title": "Teach at a convenient time for you"
      },
      "item2": {
        "text": "Add any materials to classes: text, audio, video,<br> images, formulas, tests, exercises and graphs.",
        "title": "Create interactive lessons"
      },
      "item3": {
        "text": "Use your phone, tablet, laptop<br> or conduct classes from a computer",
        "title": "No restrictions"
      },
      "subtitle": "Teach with us",
      "text": "Focus on teaching and we will help you find students",
      "title": "Feel free with Onclass"
    },
    "support": {
      "contact": "Contact support",
      "subtitle": "Help Center",
      "text": "Want to know more about Onclass earnings and opportunities? Click on the button below. Support will answer any question<br>about our platform.",
      "title": "Still have questions? <span>Onclass</span> will help"
    },
    "teacher-carousel": {
      "subtitle": "International platform",
      "teachers": "Tutors",
      "text": "Onclass brings together professionals from 70 countries around the world",
      "title": "We already have over a million tutors!<br> Join <span>Onclass</span>"
    },
    "teacher": {
      "title": "Teach online on the Onclass platform",
      "subtitle": "A virtual platform for conducting online classes and attracting new students",
      "button-text": "start teaching",
      "variant1": "Teach your favorite subjects",
      "variant2": "Study with your students",
      "variant3": "Create courses and collections",
      "video-link": "Watch an overview video",
      "subimage-text": "How are classes going"
    },
    "advantages": {
      "text1": "Free content and lesson builder",
      "text2": "Virtual classroom for online classes",
      "text3": "Tutor's personal page"
    },
    "whoisneed": {
      "title": "The Onclass platform is suitable",
      "more": "More",
      "subtitle1": "Tutors",
      "subtext1": "Study with your students or attract new ones",
      "subtitle2": "Methodists",
      "subtext2": "Create teaching aids for students and tutors",
      "subtitle3": "Online schools",
      "subtext3": "Create your own school and get a guaranteed income",
      "imagetext": "Onclass has everything you need to conduct interactive classes that students like"
    },
    "opportunities": {
      "title": "Discover new opportunities. Develop together with Onclass",
      "step1title": "Step 1",
      "step1text": "Study with your students",
      "step2title": "Step 2",
      "step2text": "Attract new students to onclass",
      "step3title": "Step 3",
      "step3text": "Create lessons, paid collections or courses or study plans",
      "step4title": "Step 4",
      "step4text": "Conduct group classes webinars or streams",
      "step5title": "Step 5",
      "step5text": "Create your own online school",
      "opporttext1": "You are creating a questionnaire (school/service/class/course)",
      "opporttext2": "We promote you and attract students",
      "opporttext3": "You earn money"
    },
    "whybetter": {
      "title": "Why is Onclass better?",
      "tabtitle1": "Tutors",
      "tabtitle2": "Methodists",
      "tabtitle3": "Schools",
      "slide1title1": "Teach your favorite subjects",
      "slide1title2": "Study with your students",
      "slide1title3": "Attract new students to Onclass",
      "slide1text1": "Teach any subjects according to your program",
      "slide1text2": "You can pay off your students off the platform",
      "slide1text3": "Payment after each lesson to your card",
      "slide2title1": "Create training programs",
      "slide2title2": "Create lessons",
      "slide2title3": "Involve tutors",
      "slide2text1": "Create collections of lessons in academic disciplines",
      "slide2text2": "Create lessons of any type in the content editor",
      "slide2text3": "Attract tutors and sell them your programs",
      "slide3title1": "Opportunities for growth",
      "slide3title2": "Own staff of tutors",
      "slide3title3": "Additional earnings",
      "slide3text1": "Any tutor who is stuck in the \"ceiling\" can create his own school and satisfy his hidden ambitions",
      "slide3text2": "Involve your fellow teachers and do one common thing together",
      "slide3text3": "Creating your own school is a great chance for tutors to increase their material wealth",
      "imagetext": "On class is great for tutors, methodologists and those who want to create their own online school."
    },
    "instruments": {
      "title": "Your main tools in achieving your goals",
      "col1title": "Lesson Constructor",
      "col1text": "Create lessons and other content needed for your classes",
      "more": "More",
      "col2title": "Virtual Classroom",
      "col2text": "Conduct online classes using the training content you have created",
      "col1icon1": "Interactive content",
      "col1icon2": "Joint passing of lessons",
      "col1icon3": "Webinars and streams",
      "col1icon4": "Homework",
      "col2icon1": "Tracking progress",
      "col2icon2": "History of classes",
      "col2icon3": "Group classes",
      "col2icon4": "Automatic task verification"
    },
    "howgetnew": {
      "title": "How to attract new students?",
      "imgtext": "Onclass allows you to organize classes with your students, but also attract new ones. Once your profile is verified, it will appear in the tutor catalog, and this opportunity is free of charge",
      "step1title": "Tell us about yourself",
      "step1text": "Fill out your profile, add videos and services to make your personal page look attractive",
      "step2title": "Select the desired bid",
      "step2text": "Onclass tutors themselves choose the cost of their classes and services",
      "step3title": "Make a work schedule",
      "step3text": "Teach at a time convenient for you, easily combine the schedule with the main work.",
      "step4title": "Link a bank card",
      "step4text": "Receive payments immediately after each lesson directly to your card"
    },
    "promoblue": {
      "title": "Teach on Onclass from your favorite devices"
    },
    "digitalcontent": {
      "title": "Create digital content, monetize your knowledge",
      "onlinetitle": "Online classes",
      "onlinetext1": "Popular classes",
      "onlinetext2": "Group classes",
      "onlinetext3": "Webinars",
      "onlinetext4": "Streams",
      "lessonstitle": "Lessons and content",
      "lessonstext1": "Courses",
      "lessonstext2": "Collections",
      "lessonstext3": "Curricula"
    },
    "mobileapp": {
      "title": "Onclass Messenger",
      "text": "Learning on Your Schedule, Connected Always: Stay Informed with OnClass' 24/7 Features!"
    },
    "reviews": {
      "title": "Start Learning Today",
      "name1": "Marina A.",
      "lang1": "English",
      "text1": "Just wanted to say how much I'm enjoying my time at Onclass so far! Everyone has been so helpful and supportive, and I feel like I'm already learning a lot",
      "name2": "Alexander L.",
      "lang2": "Spanish",
      "text2": "Learning on my time is a game-changer! Onclass connects me with amazing tutors, and I can schedule classes whenever it fits my busy schedule. Onсlass makes learning convenient and effective.",
      "name3": "Maxim E.",
      "lang3": "English",
      "text3": "Loving being a tutor on Onclass for the past 1.5 months! I get to do what I love (teaching!) and build a schedule that works for me."
    },
    "promofooter": {
      "title": "Start teaching for free",
      "text": "Sign up for Onclass and start teaching at a new level",
      "actionbtn": "start teaching"
    }
  },
  "landings": {
    "home": {
      "action-student": "Begin as student",
      "action-teacher": "Begin as tutor",
      "part1-subtitle": "Education world wide – <br/>Your Online School for the 21st Century",
      "part1-title": "Onclass.com - Join millions of learners from around the world",
      "part2-title": "How does it work?",
      "part2-tooltip1": "Toolbar",
      "part2-tooltip2": "Individual or group lessons",
      "part2-tooltip3": "Upload your lessons and exercises onto an interactive whiteboard",
      "part2-tooltip4": "Interactive whiteboard",
      "part2-tooltip5": "Tutor’s webcam",
      "part2-tooltip6": "Student list",
      "part2-tooltip7": "Chatroom for communication",
      "part2-tooltip8": "Participants’ webcams",
      "part2-var1": "Teach online anytime",
      "part2-var2": "You decide for yourself when to work",
      "part3-subtitle": "You can create your own lessons and exercises or use the learning content created by other tutors",
      "part3-title": "Lessons",
      "part4-card1-title": "Tutor catalog",
      "part4-card2-title": "Modern remote learning technologies",
      "part4-card3-title": "Create a blog, gain new subscribers",
      "part4-title": "Features",
      "part5-step1": "1) Register on <span>onclass.com</span>",
      "part5-step2": "2) <span>Create</span> a video conference",
      "part5-step3": "3) Invite students and <span>start teaching!</span>",
      "part5-steps-title": "3 simple steps",
      "part5-var1": "Any questions?",
      "top-promo-title": "Study online on the Onclass platform",
      "top-promo-text": "Discover Your Virtual Classroom: Find Top Tutors and Dive into Online Learning",
      "top-promo-btn": "Start learning",
      "top-promo-list1": "Free Lessons and Exercises",
      "top-promo-list2": "Connect with Top Tutors",
      "top-promo-list3": "Interactive Online Classes",
      "top-promo-videolink": "Watch the video about the platform",
      "advantages-text1": "Empower Your Learning: Dive into Free Lessons and exercises",
      "advantages-text2": "Boost Your Grades & Confidence: Find the Right Tutor for You",
      "advantages-text3": "Level Up Your Learning: Explore Interactive Online Classes Now",
      "who-can-title": "Empower Your Learning: Dive into Free Lessons and exercises",
      "who-can-slidetitle1": "Students",
      "who-can-slidetitle2": "Tutors",
      "who-can-slidetitle3": "Methodists",
      "who-can-slidetitle4": "Schools",
      "who-can-slidetext1": "Study any subjects. There are many tutors, courses and online schools at your service.",
      "who-can-slidetext2": "Teach any subjects according to your program. A lesson builder, a virtual classroom and much more are at your service",
      "who-can-slidetext3": "Create teaching aids for students and tutors. A lesson builder, a virtual classroom and much more are at your service",
      "who-can-slidetext4": "Create your own school and get a guaranteed income",
      "who-can-detail": "More",
      "how-study-title": "Curious about online classes? Learn how it works! ",
      "how-study-list1": "Connect with the perfect tutor! Find Your Subject & Schedule your Classes",
      "how-study-list2": "Step into your learning space! A quick click on the link or invitation at the scheduled time connects you to your virtual classroom",
      "how-study-list3": "Engage with interactive exercises! Complete tasks and activities added by your tutor to the virtual whiteboard in real-time",
      "how-study-list4": "Never lose track! Easily access past classes and materials on Onclass for convenient review and reinforcement",
      "how-study-slide1": "Automatic exercise checking",
      "how-study-slide2": "Engaging classes with personalized exercises and homework ",
      "how-study-slide3": "Review past classes and materials anytime",
      "onclass-it-title": "Experience the future of education with Onclass! Live online classes, interactive whiteboards, and personalized learning tools.",
      "onclass-it-slide1-title": "Onclass connects you with tutors to help you master any subject",
      "onclass-it-slide1-text": "Find your ideal tutor and dive into online classes on Onclass. We ensure a smooth and secure learning experience.",
      "onclass-it-slide1-link": "Browse tutors",
      "onclass-it-slide2-title": "From theory to practice! Our lessons provide a foundation, while exercises help you refine your skills and test your understanding",
      "onclass-it-slide2-text": "Go beyond textbooks! Engage with interactive activities, self-assessments, and multimedia lessons",
      "onclass-it-slide2-link": "Browse lessons",
      "onclass-it-slide3-title": "Private Classes or Group Classes: Choose the learning environment that best suits your needs",
      "onclass-it-slide3-text": "Unlock Your Learning Journey! Choose from Interactive Group Classes, Personalized One-on-One Sessions, In-Depth Courses, Engaging Webinars, and Live Streams. Find Your Perfect Fit on OnClass!",
      "onclass-it-slide3-link": "Browse classes",
      "teach-with-title": "Fit Learning into Your Life - Any Device, Any Time!",
      "teach-with-subtitle": "Desktop, tablet or phone",
      "teach-with-text": "Learning on the Go Just Got Easier! OnClass works on all your devices, so you can squeeze in some studying anytime, anywhere. No more excuses!",
      "promo-footer-title": "Start learning for free",
      "promo-footer-text": "Register on Onclass and get access to free materials",
      "promo-footer-link": "Start learning"
    },
    "meet": {
      "var1": "Organize your online class, conduct lessons and conferences online! Teach without leaving your home.",
      "var2": "Create class"
    }
  },
  "landing-me": {
    "title": "Introducing the Onclass <br/> <span class=\"highlight\"> your one-stop platform </span> <br/>for revolutionizing online education",
    "sub-title": "Your gateway to a flexible and personalized learning experience",
    "actions": {
      "log-in": "Sign in",
      "try-free": "Try for free",
      "period": "30 days for FREE"
    },
    "goals": {
      "title": "Spark student engagement with interactive lessons and exercises",
      "goal-1": {
        "title": "Lesson builder",
        "description": "Onclass empowers you to create impactful lessons and exercises that will leave your students excited to learn"
      },
      "goal-2": {
        "title": "Create instant class",
        "description": "Add engaging content & teach your classes online with an interactive whiteboard in the virtual classroom – All in one place!"
      },
      "list": {
        "title": "Unleash even more features",
        "goal-1": {
          "title": "Interactive Content",
          "description": "Students' actions on the board and their answers are visible to you in real time"
        },
        "goal-2": {
          "title": "Classes history",
          "description": "The history of each lesson is stored. You can always go back."
        },
        "goal-3": {
          "title": "Classrooms",
          "description": "Ability to group students for convenience"
        },
        "goal-4": {
          "title": "Group classes",
          "description": "Up to 55 people can practice on the board at the same time."
        },
        "goal-5": {
          "title": "Homework",
          "description": "The ability to assign homework to students to complete outside of class"
        },
        "goal-6": {
          "title": "Auto exercise check",
          "description": "The platform can independently check student answers so you don’t waste time checking."
        }
      }
    },
    "lessons": {
      "title": "Library of Ready-Made Lessons & Exercises",
      "description": "Free catalog for thousands of Ready-to-Use Learning lessons, exercises and other interactive content for your online classes."
    },
    "constructor": {
      "title": "Create Your Engaging Lessons and Exercises",
      "description": "The all-in-one Onclass lesson builder: From exercises to engaging lessons and other content needed for your online classes",
      "exercises": "Looking for inspiration to ignite your students' learning? Onclass showcases of ready-made lessons and interactive exercises across various subjects and age groups"
    },
    "dictionary": {
      "title": "Personal dictionary, shared with a tutor",
      "description": "Effortlessly save words and phrases you encounter in lessons or online classes, get instant translation and pronunciations. Add words and phrases to your personal or student dictionary. Turn your saved words into flashcards with the help of AI assistant for better memorization"
    },
    "dashboard": {
      "title": "Manage your schedule and classes",
      "description": "Manage your schedule, assignments, and student progress with ease. Seamlessly connect with your students and stay on top of everything"
    },
    "timetable": {
      "title": "Take control of your time",
      "description": "Plan your working hours and keep your class schedule organized – all in one place. See a clear view of past and upcoming classes for seamless workflow"
    },
    "devices": {
      "title": "Seamless learning across all devices",
      "description": "Seamless learning across all devices, so you don’t have to be tied to one place"
    },
    "reviews": {
      "title": "Reviews from teachers who have already become acquainted with the Onclass platform",
      "experience": "years of experience"
    },
    "plans": {
      "title": "Empower your teaching - Join Onclass today"
    }
  },
  "language-levels": {
    "basic": "Basic",
    "fluent": "Fluent",
    "native": "Native",
    "read": "Read",
    "read-write": "Read and Write",
    "speak": "Speak"
  },
  "language-selector": {
    "choose-language": "Choose language"
  },
  "currency-selector": {
    "choose-currency": "Choose currency"
  },
  "lesson-complain": {
    "cause": "Cause",
    "cause1": "Spamming",
    "cause10": "Animal abuse",
    "cause11": "Misleading",
    "cause12": "Fraud",
    "cause13": "Extremism",
    "cause2": "Insult",
    "cause3": "Material for adults",
    "cause4": "Child pornography",
    "cause5": "Drug propaganda",
    "cause6": "Weapons sale",
    "cause7": "Violence",
    "cause8": "Call for Harassment",
    "cause9": "Call for suicide",
    "comments-label": "Leave a comment...",
    "comments-label-options": "Optional",
    "comments-placeholder": "Describe the problem",
    "complain-title": "Complaint to the lesson",
    "send": "Send",
    "slide": "slide"
  },
  "lessons": {
    "meta-favorites-title": "Favorites",
    "meta-last-viewed-title": "Last viewed",
    "meta-mine-title": "My",
    "meta-popular-title": "Popular",
    "meta-recently-created-title": "Recently added",
    "meta-title": "Lessons",
    "page-header-title": "Lessons"
  },
  "library": {
    "meetings-stack": {
      "title": "Classes",
      "text": "Webinars, streams, group and individual lessons",
      "action": "Schedule class"
    },
    "user-services-stack": {
      "title": "As a teacher, you will start receiving ratings from students, and your profile will feature a public ranking.",
      "text": "Private lessons with tutors",
      "action": "Add class",
      "exception": "Only for verified tutors",
      "details": "Learn more about verification"
    },
    "base-pages": {
      "lessons": "Lessons",
      "classes": "Classes",
      "tutors": "Tutors",
      "collections": "Collections",
      "schools": "Schools",
      "courses": "Courses"
    },
    "breadcrumbs": {
      "content-type": "Category:",
      "home": "Main",
      "language": "Content language:",
      "subject": "Subject:",
      "classroom_for": "Classroom for:"
    },
    "cancel-action": "Cancel",
    "card-action-start-lesson": "Start lesson",
    "card-media-audio-not-ready": "Podcast not ready yet",
    "card-tabs-lesson-about": "Personal information",
    "card-tabs-lesson-content": "Content",
    "card-tabs-lesson-exercises": "Exercises",
    "categories": "Categories",
    "content-filter": {
      "create": "Create lesson",
      "sort": "Sort by",
      "choose-sorting": "filter",
      "order": {
        "date_asc": "Date, from older to newer",
        "date_desc": "Date, from newer to older",
        "popularity": "Recent popular",
        "popularity_week": "Popular of week",
        "popularity_year": "Popular all time"
      },
      "see-all": "Show more",
      "stub-subject-text": "There are no lessons in this subject yet",
      "tab-all": "All",
      "tab-favorites": "Favorites",
      "tab-foreign": "Foreign languages",
      "tab-native": "Native languages",
      "tab-new": "New",
      "tab-others": "Others",
      "tab-popular": "Popular",
      "title": "Subjects",
      "total": "Lessons:",
      "total-individual-lessons": "Individual lessons:",
      "total-teachers": "Tutors:"
    },
    "exercises": "Exercises",
    "favorites-title": "Favorites",
    "filter-cleaning": {
      "clean-all": "Reset filter",
      "subtitle": "Oops! We didn't find any results for your search. Try refining your keywords or browsing by category to find what you're looking for",
      "title": "Nothing found"
    },
    "filter-widget": {
      "add-filter": "Add filter",
      "apply": "Apply filter",
      "category": "Category",
      "clear": "Reset filter",
      "foreign": "Foreign languages",
      "header-text": "Selected filters:",
      "header-title": "Filter",
      "language": "Content language",
      "level": "Level",
      "native": "Native languages",
      "non_categorized": "Other subjects",
      "not-found": "Not found",
      "placeholders": {
        "category": "Category search",
        "language": "Language search",
        "level": "Search by level",
        "subject": "Subject search"
      },
      "popularity": "Popularity",
      "subject": "Subject"
    },
    "form-term-placeholder": "Search...",
    "form-term-placeholder-new": "What do you want to find?",
    "free-date-banner": {
      "action": "Create a class",
      "action2": "View tutors",
      "text-part1": "No planned clasess,",
      "text-part2": "create your own",
      "text-part3": "and it will be the first in the list",
      "text2": "However, you can book your favorite tutor for an individual lesson",
      "title": "Free date",
      "title2": "No public events planned"
    },
    "lesson-of-the-day": "Lesson of the day",
    "meeting-card": {
      "date": "Date:",
      "detailed": "Learn more",
      "free": "Is free",
      "participation": "Participation:",
      "types": {
        "group": "Group lesson:",
        "single": "Single lesson:",
        "stream": "Stream:",
        "webinar": "Webinar:"
      }
    },
    "meeting-drag-scroll": {
      "today": "Today",
      "tomorrow": "Tomorrow"
    },
    "menu-complain": "Complain",
    "menu-edit": "Edit",
    "menu-remove": "Remove",
    "menu-share": "Share",
    "menu-more": "More",
    "menu-bookmark": "Favorites",
    "menu-unsubscribe": "Unsubscribe",
    "meta-favorites-title": "Favorites",
    "meta-last-viewed-title": "Last viewed",
    "meta-mine-title": "My",
    "meta-popular-title": "Popular",
    "meta-recently-created-title": "Recently added",
    "meta-title": "Lessons",
    "meta-vocabulary-terms": "Learn",
    "no-image-text": "No image",
    "not-found.meta-title": "Page not found",
    "page-header-title": "Lessons",
    "pagination-more": "Load more",
    "publication-removed-text": "The publication was removed.",
    "publication-restore-action": "Restore?",
    "read-article": "Read the article",
    "read-hide": "hide",
    "read-more": "read more",
    "related-items-title": "Other",
    "share-link-widget": {
      "link": "Copy link",
      "text1": "Share the link with your friends",
      "text2": "Share your lesson on social media",
      "title1": "Do you like it? Share the link!",
      "title2": "Congratulations! Your lesson has been posted."
    },
    "show-more": "Show more",
    "subtitles": {
      "podcasts": "Podcasts",
      "popular": "Popular exercises of the week",
      "video": "Video",
      "what-to-read": "Articles"
    },
    "tabs-all": "All",
    "tabs-favorites": "Favorites",
    "tabs-mine": "My",
    "teacher-drag-scroll": {
      "best-of-week": "Best of the week",
      "take-up-teach": "Start teaching",
      "title": "Tutors"
    },
    "titles": {
      "categories": "Lesson types",
      "exercises": "Lessons",
      "gallery": "Images",
      "goback": "Back",
      "lesson-of-the-day": "Lesson of the day",
      "lessons": "Lessons",
      "collections": "Collections",
      "services": "Classes",
      "lessons-from-teacher": "Other tutor lessons",
      "media": "Mediacontent",
      "meetings": "Online classes",
      "most-popular": "Most popular",
      "popular": "Popular lessons",
      "recent-teachers": "New tutors",
      "recently": "Recently added",
      "recently-created": "Recently added",
      "recently-viewed": "Last viewed",
      "similar": "Similar lessons",
      "teacher-of-the-day": "Tutor of the day",
      "teachers": "Tutors",
      "types-of-study": "Types of classes",
      "what-to-listen": "What to listen to?",
      "what-to-read": "Reading",
      "what-to-see": "What to watch?",
      "popular-classes": "Popular classes",
      "best-teachers": "The best tutors",
      "popular-teachers": "Popular tutors",
      "other-lessons": "Other lessons",
      "popular-collections": "Popular collections",
      "popular-schools": "Popular schools",
      "best-collections": "Best collections",
      "best-schools": "Best schools",
      "other-collections": "Other collections",
      "other-schools": "Other schools",
      "other-tutors": "Other tutors",
      "other-classes": "Other classes",
      "other2-collections": "Other collections",
      "other2-tutors": "Other tutors",
      "other2-classes": "Other classes",
      "other2-lessons": "Other lessons",
      "lessons-other-subjects": "Lessons in other subjects",
      "collections-other-subjects": "Collections on other subjects",
      "schools-other-subjects": "Schools in other subjects",
      "tutors-other-subjects": "Tutors for other subjects",
      "classes-other-subjects": "Classes in other subjects"
    },
    "update-action": "Update",
    "user-card": {
      "from": "from",
      "highest-mark": "Highest rated",
      "lesson": "classes",
      "no-free-time": "Unavailable",
      "price": "Price per hour:",
      "send": "Contact the tutor",
      "sign": "Book now",
      "spoken": "Speaks:",
      "teach": "Teaches:",
      "title-action": "How to become a tutor of the day?"
    },
    "user-mini-card": {
      "duration": "on Onclass.com",
      "from": "from",
      "lessons": "lessons",
      "meetings": "classes",
      "on-request": "on request",
      "price": "per class",
      "teaching": "Teaching:",
      "speak-on": "Speaks:",
      "rating": "Rating tutor:"
    },
    "user-service-card": {
      "discount": "Discount",
      "hour": "hour",
      "lessons-count": "Classes:",
      "native": "Native",
      "reviews": "reviews",
      "no-reviews": "There are no reviews yet.",
      "unavailable": "Unavailable",
      "sign-up": "Book now",
      "sign-up-for-a-lesson": "Book now",
      "subscribe": "Subscribe",
      "cancel-subscription": "Cancel Subscription",
      "renew_subscription": "Renew subscription",
      "access_to_lesson_will_open": "Access to the class will open",
      "type": {
        "single": "One class",
        "regular": "Regular classes",
        "mini": "Mini course",
        "of": "of",
        "course": "Course"
      },
      "one-on-one": "1 on 1",
      "group": "Group",
      "entry-level": "Entry level",
      "min": "min",
      "label-period": {
        "single": "per class",
        "regular": "in week",
        "mini": "for 1 class",
        "course": "for 1 class"
      },
      "status": {
        "on-moderation": "Awaiting moderation",
        "archived": "Archived",
        "published": "Published",
        "declined": "The service has not been moderated"
      },
      "stats": {
        "tutor": "Tutor",
        "rating": "Rating",
        "classes": "Classes"
      }
    },
    "header.actions": {
      "lessons": "Create a lesson",
      "teachers": "Take up teaching",
      "study": "Schedule a class",
      "collections": "Build your collection"
    }
  },
  "loading-line.text": "Loading takes a little longer than usual.<br> Wait a little more, please.",
  "main.meta-title": "Main",
  "main.page-header-title": "Main",
  "media-gallery": {
    "input-search-placeholder": "Search online",
    "online-search-placeholder": "Type something in the search bar and press enter",
    "tab-favorites": "Favorites",
    "tab-from-web": "Online",
    "tab-recent": "Recent",
    "tab-upload": "Upload",
    "warning-button": "Close",
    "warning-title": "Warning"
  },
  "meeting-review-form": {
    "comment-placeholder": "Please leave a review about you class",
    "fail": "Message less than 50 characters",
    "increased": "Tutor's rating will be increased.",
    "label-rating": "Rate your class",
    "post-review": "Post a review",
    "complain": "Complain",
    "question": "Please rate your class with your tutor and leave a comment",
    "recommend": "Recommend a tutor",
    "sign-again": "Sign up again",
    "thanks": "Thanks for your feedback!"
  },
  "meeting-complaint-form": {
    "title": "Complaint against a tutor",
    "text": "Please tell us the reason why you were unhappy with the lesson",
    "comment-moderator": "Comment to the moderator",
    "send-complaint": "Send a complaint",
    "sent-text": "We will consider your complaint within 3 days and contact you via the email specified in the application form",
    "sent-text-check": "You can check if your email address is listed by following this link",
    "error-text": "Something went wrong!",
    "again": "To try one more time"
  },
  "meeting-type": {
    "group": "Group class:",
    "single": "Individual class:",
    "stream": "Stream",
    "webinar": "Webinar"
  },
  "meetings": {
    "additionally": "additionally",
    "archived": "Archived",
    "buy-credits-action": "buy credits",
    "calendar": "Calendar",
    "group_meeting": "Group meeting",
    "student": "Student:",
    "tutor": "Tutor:",
    "write": "Write",
    "write-to-chat": "Message",
    "chrome-text": "For the class to work smoothly, we recommend installing the Chrome browser.",
    "invite": {
      "emails-error": "Some emails are incorrect"
    },
    "complaint": {
      "rejected": "Complaint was rejected",
      "resolved": "Complaint was resolved"
    },
    "drafts": "Drafts",
    "dz-placeholder-link1": "upload",
    "dz-placeholder-link2": "to select",
    "dz-placeholder-text": "Drag and drop",
    "edit": "Edit",
    "edit-cover": "Edit cover",
    "form-access-label": "Access",
    "form-access-private": "Private",
    "form-access-public": "Public",
    "form-balance-label": "Balance",
    "form-balance-note": "Your balance:",
    "form-carrying-possible": "Class is possible",
    "form-change-title": "Changing a classroom",
    "form-charging-label": "Participation",
    "form-chrome-text": "For the smooth operation of the class, install the Chrome browser.",
    "form-create-action": "Create",
    "form-credits": "credits",
    "form-description-placeholder": "Description",
    "form-download": "Download",
    "form-fee-note": "Safe deal, student pays through Onclass.com platform",
    "form-level-label": "Level",
    "form-level-placeholder": "Choose level",
    "form-not-credits": "Not enough credits",
    "form-number-participants-label": "Number of participants",
    "form-participants-limit-warn": "Not chosen",
    "form-participants_limit-label": "Number of participants",
    "form-participation-free": "Free",
    "form-participation-label": "Participation",
    "form-participation-paid": "Paid",
    "form-price-label": "Price",
    "form-price-placeholder": "price",
    "form-price-tier-warn": "Not chosen",
    "form-private-access-note": "Invite to class by private link",
    "form-public-access-note": "Publish in the class catalog also invite by link",
    "form-required": "Required:",
    "form-save-action": "Save",
    "form-subject-warn": "The choice of discipline is required",
    "form-term-placeholder": "Search...",
    "form-time-label": "Date and time",
    "form-timezone-label": "Timezone",
    "form-timezone-message": "In your time zone",
    "form-title": "Creating a classroom",
    "form-title-placeholder": "Classroom topic",
    "form-title-warn": "Required field",
    "form-to-calendar": "Add to calendar",
    "form-type-calc-label": "Type of calculation",
    "form-valid-message-access": "Choose access",
    "form-valid-message-charging": "Choose participation",
    "form-valid-message-time-busy": "This time is busy",
    "form-valid-message-wrong": "Specify a different time.",
    "form-valid-message1": "You have not chosen a discipline",
    "form-valid-message2": "The field must be filled",
    "hint": {
      "buttons": {
        "check": "Check camera",
        "create": "Create a class",
        "schedule": "Schedule a class"
      },
      "faq": "FAQ",
      "questions": {
        "add-lesson": "How to add lessons on the whiteboard?",
        "add-participant": "How to invite a participant?",
        "add-whiteboard": "How to add a whiteboard?",
        "chat": "Where is the chat?",
        "settings": "Where are the settings?",
        "start-class": "How to start a class?",
        "tools": "Where is the toolbar?"
      },
      "tabs": {
        "exercise": "Exercise",
        "img-pres": "Images, presentations",
        "podcasts": "Podcasts",
        "theory": "Theory",
        "video": "Video",
        "vocabulary": "Vocabulary",
        "whiteboard": "Whiteboard"
      },
      "tooltip": "Create and add your custom lessons or use ready-made content from the library: exercises, videos/podcasts, theory, etc.",
      "watch-video": "Watch a video about the conference"
    },
    "join": {
      "accept-meeting": "Accept invitation",
      "accept-paid-meeting": "Purchase and accept",
      "auth_required": "Authorization...",
      "canceled": "Meeting was canceled",
      "canceled-meeting-title": "The class was canceled",
      "book-again-title": "Time expired, please try to book again",
      "checkout-paid-meting-title": "Checkout for paid meeting",
      "error": "A technical error has occurred please close the window and try again",
      "finished-meeting-title": "The class is over",
      "joining": "Joining conference...",
      "loading": "Loading conference...",
      "meeting-title": "You have been invited to the meeting",
      "no-more-places-to-join": "No more places to join",
      "not_found": "Impossible to join. The conference was not found. Check the invitation link for a typo.",
      "open": "Open conference",
      "min": "min",
      "free": "Free",
      "per_meet": "per class",
      "copy_link": "copy link",
      "invite_link": "Invite link",
      "opening": "Opening conference...",
      "opening-timeout-title": "Click here if meeting did not open yet",
      "payment-in-process": "Payment is being processed...",
      "paid-meeting-title": "You have been invited to the paid meeting",
      "participants_limit-meeting-title": "Impossible to join. The limit of the participants is reached.",
      "qr-code-note": "Scan this QR code to join the class session with your smartphone or tablet "
    },
    "join-action": "Join class",
    "lesson": "classes",
    "meeting-not-found": "Classroom not found",
    "menu-complain": "Complain",
    "menu-edit": "Edit",
    "menu-invite": "Invite",
    "menu-reschedule": "Change date and time",
    "menu-share": "Share",
    "menu-cancel-meeting": "Cancel",
    "menu-leave-meeting": "Leave",
    "menu-break-contract": "Cancel",
    "menu-unsubscribe": "Unsubscribe",
    "meta-create-title": "Create a classroom",
    "meta-favorites-title": "Favorites",
    "meta-form-title": "Classroom editor",
    "meta-mine-title": "My",
    "meta-title": "Study",
    "no-image-text": "No image",
    "open-action": "Open class",
    "or": "or",
    "other": "Other",
    "page-favorites-title": "Favorites",
    "page-header-title": "Study",
    "page-mine-title": "My",
    "paid-join-action": "Pay and join class",
    "participants-count": "Participants:",
    "participants-left": "({{participantsLeft}} places left)",
    "participants-limit-placeholder": "Choose",
    "participants_limit-1": "1",
    "participants_limit-10": "2-12",
    "participants_limit-100": "up to 100",
    "participants_limit-1000": "up to 1000",
    "participants_limit-2": "2",
    "participation-free": "Free",
    "participation-price": "Participation fee:",
    "plan-upgrade-action": "upgrade plan",
    "published": "Published",
    "read-hide": "hide",
    "read-more": "read more",
    "redeem-coupon-action": "redeem coupon",
    "reschedule_date": "New date:",
    "reservation_time_left": "Time left:",
    "select-area": "Select area",
    "skills-title": "After completing this course, you will learn:",
    "status": {
      "accept_required": "- Received an invitation from a tutor",
      "accept_required.any-role.accepted": "Awaiting confirmation",
      "accept_required.any-role.declined": "Invitation declined",
      "accept_required.any-role.not_required": "Awaiting confirmation",
      "accept_required.owner.accept_required": "Received an invitation from a student",
      "accept_required.owner.accepted": "Pending student confirmation",
      "accept_required.participant.accept_required": "An invitation from a tutor received",
      "accept_required.participant.accepted": "Awaiting confirmation",
      "accept_required.participant.paid_accept_required": "This class requires payment",
      "accept_required.reservation.accept_required": "An invitation from a tutor received",
      "accept_required.reservation.accepted": "Awaiting tutor confirmation",
      "accept_required.reservation.paid_accept_required": "This class requires payment",
      "accepted.owner.expired": "The invitation has expired",
      "accepted.owner.reserved": "Awaiting Payment",
      "accepted.participant.expired": "The invitation has expired",
      "accepted.reservation.expired": "The invitation has expired",
      "accepted.reservation.reserved": "Pay for participation",
      "cancel_required": "This meeting is canceling",
      "canceled": "This meeting was canceled",
      "finished": "Talk",
      "paid_accept_required": "- This meeting require payment before accept",
      "reschedule_requested": "- A time reschedule request has been sent",
      "reschedule_required": "- Time reschedule was requested",
      "started.participant.accept_required": "An invitation from a tutor received",
      "started.participant.paid_accept_required": "This class requires payment",
      "started.reservation.paid_accept_required": "This class requires payment"
    },
    "tabs-all": "All",
    "tabs-favorites": "Favorites",
    "tabs-mine": "My",
    "teacher": "Tutor:",
    "titles": {
      "for_all": "For all",
      "exam_training": "Exam training",
      "for_children": "For children",
      "other": "Other activities",
      "personal": "Personal lessons",
      "speaking": "Speaking practice",
      "master_class": "Master class",
      "discussion": "Discussion"
    },
    "types": {
      "single": "One class",
      "group": "Group",
      "webinar": "Webinar",
      "stream": "Stream",
      "talk": "Talk"
    },
    "to-write": "Message",
    "to-write-link": "Write a message",
    "entry-level": "Entry level",
    "classes": "Classes",
    "per_class": "per class",
    "value": "Value:",
    "wizard": {
      "average-rating": "Tutors rating:",
      "cant-pay": "The tutor cannot accept payment, {{ term }} to resolve this issue.",
      "cant-pay-term": "please contact the tutor",
      "no-payment-methods": "Add a payment method to pay for this class",
      "classes": "classes",
      "complete": "Complete",
      "delivered": "Delivered:",
      "next": "Next",
      "pay": "Pay",
      "pay-fail": "Payment failed!",
      "pay-success": "The payment was successful!",
      "add-card-success": "Your bank card is linked!",
      "select-payment": "Choose a payment method",
      "teacher": "Tutor:",
      "try-again": "Try again",
      "no-services": "The tutor has not yet added services in this discipline",
      "value": "Value",
      "choose-teacher": "Choose your tutor",
      "course": {
        "choose-group": "Choose a class",
        "free-places": "Free places",
        "see-all": "Schedule of classes",
        "choose": "Choose",
        "sign-in": "Booked",
        "no-places": "No more places",
        "purchase-incomplete": "Purchase was not complete",
        "proceed-purchase": "Proceed to purchase",
        "proceed-to-checkout": "Proceed to checkout"
      }
    },
    "subscription_suspended": "The subscription for this activity has been suspended",
    "next_meeting": "Date and time of the сlass:",
    "renew_subscription": "Renew subscription"
  },
  "alerts": {
    "reschedule": {
      "title": "Reschedule class",
      "description": "Has the time for which the class is being rescheduled been agreed with the students?",
      "close": "Not agreed",
      "confirm": "Agreed"
    }
  },
  "menu": {
    "landing-footer": {
      "about": "Personal information",
      "app-full-name": "Onclass | 2020",
      "classes": "Conferences",
      "library": "Lessons",
      "privacy": "Privacy Policy",
      "public": "Public Offer",
      "sales": "Sales and Refunds",
      "teachers": "Tutors",
      "terms": "Terms of Use"
    },
    "landing-top": {
      "classes": "Study",
      "library": "Lessons",
      "signin": "Sign in / Sign up",
      "teachers": "Tutors"
    },
    "left-sidebar": {
      "all-subjects": "All subjects",
      "auth": "Sign in / Sign up",
      "classrooms": "Classrooms",
      "collections": "My content",
      "community": "Friends/groups",
      "constructor": "Lesson editor",
      "content-manager": "Content manager",
      "create": "Create lesson",
      "create-content-link": "Create a lesson",
      "dashboard": "Dashboard",
      "events": "Classes",
      "faq": "FAQ",
      "favorites": "Favorites",
      "foreign": "Foreign languages",
      "messages": "Chat",
      "news": "All news",
      "non-categorized": "Other subjects",
      "not-found-text": "No lessons have been found for this discipline. Create the first lesson in this discipline!",
      "notifications": "Notifications",
      "profile": "Profile",
      "request": "Request",
      "schedule": "Calendar",
      "schools": "Schools",
      "search-placeholder": "Search subject",
      "settings": "Settings",
      "signout": "Sign out",
      "study-create": "Create instant class",
      "support": "Support",
      "tasks": "Tasks",
      "teachers-room": "Tutor's office",
      "users": "Users",
      "vocabulary": "Vocabulary",
      "my-feed": "My Feed",
      "my-classes": "My Classes",
      "my-library": "My Library",
      "my-vocabulary": "My Vocabulary",
      "schedule-class-catalog": "Create a class in the catalog",
      "create-classroom": "Create a classroom",
      "create-lesson": "Create a lesson",
      "create-collection": "Create a collection",
      "create-school": "Create a school",
      "classes-submenu": {
        "conversation-clubs": "Conversation clubs",
        "courses": "Courses",
        "group-lessons": "Group lessons",
        "groups": "Groups",
        "lesson-collections": "Lesson Collections",
        "marathons": "Marathons",
        "private-lessons": "Private lessons",
        "schools": "Schools",
        "streams": "Streams",
        "webinars": "Webinars"
      }
    },
    "top": {
      "classes": "Classes",
      "dashboard": "Dashboard",
      "feed": "Feed",
      "library": "Lessons",
      "main": "Main",
      "teachers": "Tutors",
      "chat": "Chat"
    },
    "content-tabs": {
      "main": "Main",
      "lessons": "Lessons",
      "collections": "Collections",
      "courses": "Courses"
    }
  },
  "my-vocabulary": {
    "page-header-title": "My Vocabulary",
    "tabs": {
      "main": "Vocabulary",
      "lists": "Folders",
      "learned": "Learned",
      "students": "Students",
      "requires-study": "Requires study",
      "added-words": "Added words"
    },
    "word": "Word",
    "words": "Words",
    "selected-words": "Selected words",
    "translation": "Translation",
    "dictionaries": "Dictionaries",
    "action": {
      "add-word": "Add word",
      "select-all": "Select all",
      "clear-all": "Clear all",
      "select-all-students": "Select all",
      "delete": "Delete",
      "move": "Move",
      "add-word-to-list": "Add words to the list",
      "new-list": "New folder",
      "edit": "Edit",
      "create-lesson": "Create a lesson from words",
      "duplicate": "Duplicate",
      "save": "Save",
      "ready": "Ready"
    },
    "create-list": "Create list",
    "edit-list": "Edit list",
    "move-words": "Move words",
    "to-your-list": "To your list",
    "to-student-dictionary": "In the student's dictionary",
    "title": "Title",
    "all-dictionaries": "All dictionaries",
    "choose-dictionary": "From which dictionary to create a lesson?",
    "empty-vocabulary": {
      "title": "The dictionary is empty",
      "info": "Add a word or phrase"
    },
    "empty-list": {
      "title": "There are no lists",
      "info": "Create a new list"
    },
    "empty-students": "You don't have students to manage their dictionaries"
  },
  "new-subject-banner": {
    "action": "Create a lesson",
    "text1": "Haven't found the discipline you teach?",
    "text2": "Create",
    "text3": "a new discipline and exercise or other content",
    "text4": "in the lesson builder"
  },
  "news": {
    "get-started": {
      "informer1": {
        "link": "Open editor",
        "text": "Create lessons and other educational content for your online classes.",
        "title": "Lesson editor"
      },
      "informer2": {
        "link": "Open catalog",
        "text": "Invite students or attract new ones, earning on your knowledge.",
        "title": "Applications"
      },
      "informer3": {
        "link": "Open class",
        "text": "Study online on the platform with existing Onclass students",
        "title": "Create instant class"
      },
      "informer4": {
        "link": "Open lessons",
        "text": "Free lessons and other content to improve knowledge of the subjects",
        "title": "Library with lessons"
      },
      "informer5": {
        "link": "Open catalog",
        "text": "Look for tutors to prepare for exams or do your homework",
        "title": "Find your tutor"
      },
      "informer6": {
        "link": "Send link",
        "text": "Do you have your own tutor? Invite him to the Onclass platform and study here.",
        "title": "Create instant class"
      },
      "title": "How to get started with Onclass",
      "title-student": "Interesting study at Onclass"
    },
    "page-header-title": "Main",
    "teacher-card": {
      "from": "from",
      "price": "per class",
      "teaches": "Teaches:"
    },
    "teacher-drag-scroll": {
      "title": "New tutors on Onclass",
      "title-right-link": "Start teaching"
    },
    "welcome": {
      "text": "Welcome to the Onclass main news feed. Here, you'll find recommendations, popular highlights, and announcements. Stay tuned for more engaging content coming your way soon",
      "title": "Hi,"
    }
  },
  "no-content-stub": {
    "library": {
      "favorites": "You do not have featured lessons. Add a lesson to favorites by clicking the icon on the lesson card",
      "favorites-link": "Watch lessons",
      "my": "The My tab displays lessons created by you and moderated. Create your first lesson.",
      "my-link": "Create a lesson"
    },
    "meetings": {
      "favorites": "You have no favorites. Add a class to your favorites by clicking the icon on the lesson card",
      "favorites-link": "View catalog",
      "my": "This tab will display classes in which you took part or created by you personally.",
      "my-link": "Create class"
    },
    "teachers": {
      "favorites": "You have no selected teachers. Add the tutor to your favorites by clicking the icon on the tutor card",
      "favorites-link": "View catalog",
      "my": "The tutors with whom you took classes will be displayed in the My tab."
    },
    "users": {
      "students": "You don't have your students yet. The My Students tab will display everyone you have ever invited to your online class",
      "subscribers": "Be proactive, create engaging lessons, and run online classes to attract your first subscribers",
      "subscriptions": "Follow the news of your friends and teachers. In the future, in this tab, you will be able to see everyone you follow",
      "subscribers-student": "You don’t have subscribers",
      "subscriptions-student": "You don’t have any subscriptions"
    }
  },
  "not-found": {
    "action-go-main": "Return to main page",
    "balloon": "It seems that something went wrong in our class ...",
    "description": "Page not found or not yet created",
    "meta-title": "Page Not found",
    "page-title": "Page Not found",
    "title": "Error"
  },
  "not-verified-widget": {
    "about-subscription": "About subscription",
    "description-1": "Not verified",
    "description-2": "The profile will suit you if you plan to study only with your students and do not want publicity.",
    "description-3": "Verified",
    "description-4": "Teachers have access to all the functionality of our educational platform.",
    "text-1": "You can conduct classes in conference with your students;",
    "text-2": "Access to your classes only by invitation link;",
    "text-3": "5 classes per month are available;",
    "text-4": "Your tutor profile is not published in the catalog;",
    "title-1": "Your profile is",
    "title-2": "not verified"
  },
  "notifications": {
    "content_comment": {
      "text": "{{user}} commented on your post {{title}}",
      "text_post": "{{user}} commented your {{title}}",
      "post": "post",
      "title": "New comment"
    },
    "content_like": {
      "text": "{{user}} liked your post {{title}}",
      "text_post": "{{user}} liked your {{title}}",
      "post": "post",
      "title": "Like"
    },
    "meeting_invite": {
      "accept": "Accept",
      "decline": "Decline",
      "action": "Join the class",
      "text": "Teacher {{teacher}} invites you to join the class {{title}}",
      "title": "Invitation to a class"
    },
    "meeting_purchase_was_canceled": {
      "text": "You haven't paid for the class {{title}}. Please try to book your class again",
      "title": "Time for payment expired"
    },
    "meeting_reservation": {
      "text": "You have an unpaid class {{title}}",
      "title": "Awaiting Payment",
      "action": "Proceed with payment"
    },
    "teacher_rescheduled_meeting": {
      "text": "Tutor {{teacher}} has rescheduled {{title}} class from {{old}} to {{new}}",
      "title": "Rescheduling a class"
    },
    "meeting_started": {
      "action": "Join the class",
      "text": "Your private class with tutor {{teacher}} has started",
      "text_group": "Your group class {{title}} with tutor {{teacher}} has started",
      "title": "Your class has started"
    },
    "my_meeting_started": {
      "action": "Join the class",
      "text": "A private class with {{participant}} has started",
      "text_group": "A group class {{title}} has started",
      "title": "Your class has started"
    },
    "profile_declined": {
      "action": "Go to profile",
      "text": "Your profile update has been declined",
      "title": "Verification"
    },
    "profile_verified": {
      "action": "New features available",
      "text": "Your profile has been successfully verified",
      "title": "Verification"
    },
    "subscriber": {
      "text": "{{user}} subscribed to your profile",
      "title": "New subscriber"
    },
    "classroom-invite": {
      "text": "{{teacher}} invites you to classroom {{title}}",
      "title": "Classroom invitation",
      "action": "Go to classroom"
    },
    "classroom_new_invitation_2": {
      "text": "Tutor {{user_fullname}} invites you to classroom {{classroom_title}}",
      "title": "Classroom invitation",
      "action": "Go to classroom"
    },
    "classroom-leave": {
      "text": "Student {{student}} left classroom {{title}}",
      "title": "Leaving classroom",
      "action": "Go to classroom"
    },
    "classroom_new_participant_2": {
      "text": "Student {{user_fullname}} joined classroom {{classroom_title}}",
      "title": "New classroom member",
      "action": "Go to classroom"
    },
    "meeting_participant_joined": {
      "title": "Invitation accepted by student",
      "text": "Student {{student}} accepted the invitation to the class {{title}}",
      "action": "Go to classroom"
    },
    "classroom-removed": {
      "text": "Classroom {{title}} deleted by tutor",
      "title": "Classroom deleted"
    },
    "school-status-changed": {
      "text": "The school {{title}} is {{status}}",
      "title": "School status changed",
      "action": "Go to school",
      "status": {
        "school-status": "School status",
        "send-for-moderation": "Send for moderation",
        "removed": "removed",
        "draft": "just created",
        "verification_declined": "moderation rejected",
        "under_verification": "moderation",
        "verified": "moderated",
        "published": "Published",
        "working": "published and working",
        "not-published": "Not published",
        "on-moderation": "Awaiting moderation",
        "changed": "Changed. Re-moderation required"
      }
    },
    "contract-signed-up": {
      "text_lesson": "New entry for the class",
      "text_course": "New entry for course of classes",
      "text_regular": "New entry for regular class",
      "title": "New entry for the class",
      "date_time": "Date and time",
      "student": "Student",
      "every-week": "Every week",
      "number_of_classes": "Number of classes",
      "first_class": "First class"
    },
    "contract_signed_up_student": {
      "text_lesson": "New entry for the class",
      "text_course": "New entry for course of classes",
      "text_regular": "New entry for regular class",
      "title": "New entry for the class",
      "info_onetime_date": "Date and time: {{ begin_at_local }}",
      "info_course_number": "Number of classes: {{ count }}",
      "info_course_date": "First class: {{ begin_at_local }}",
      "info_regular_weekly": "Weekly, every {{ weekdays }}",
      "info_regular_date": "Date and time: {{ begin_at_local }}",
      "teacher": "Tutor: {{ teacher_fullname }}"
    },
    "contract-prolong-order-unpaid": {
      "text": "Please try again or use another payment method",
      "title": "Payment failed",
      "action": "Link a card"
    },
    "billing-card": {
      "card": "Сard",
      "bank-card": "Bank card",
      "bank-cards": "Bank cards",
      "bank-card-description": "Add a bank card to accept payments from students",
      "bank-card-support": "If, after linking a bank card, it is not displayed in the list, please contact support.",
      "support": "Write to support",
      "process-delete": "Your bank card is in the process of being deleted. This may take some time",
      "success-delete": "Your bank card has been successfully deleted!",
      "error-delete": "Your bank card cannot be deleted at the moment. Perhaps you have created paid collections or the card is occupied by an incomplete transaction"
    },
    "title": "Notifications",
    "today": "Today",
    "yesterday": "Yesterday",
    "notifications-stub": "No notifications yet",
    "modal.title": "notifications",
    "billing_plan_was_deactivated": {
      "title": "Plan deactivated",
      "text": "You plan was deactivated. {{ reason_for_deactivation }}"
    },
    "billing_plan_will_be_prolong_soon": {
      "title": "Paying for the subscription",
      "text": "After {{ number_of_days_for_upcoming_payment }} days, the subscription fee will be deducted. Make sure that the required amount is on the bank card to pay for the subscription"
    },
    "vacation_reminder": {
      "title": "Vacation",
      "text": "Your vacation is planned from {{begin_at}} to {{end_at}}. Should you have any classes planned for this period, don't forget to reschedule or cancel them.",
      "action": "Go to calendar"
    },
    "meeting_complaint_reported": {
      "title": "Complaint from a student",
      "text": "Your class {{title}} received a complaint from a student {{student}}. Wait for a decision."
    },
    "meeting_complaint_resolved_teacher": {
      "title": "Complaint outcome",
      "text": "Based on a complaint from student {{student}} about your class {{title}}, a decision has been made. Details in your email."
    },
    "meeting_complaint_resolved_reporter": {
      "title": "Complaint outcome",
      "text": "A decision has been made regarding your complaint about the class: {{title}}. Details in your email."
    },
    "meeting_with_teacher_will_start": {
      "title": "Class soon",
      "text": "In {{delay_to_begin_min}} minutes the class {{meeting_title}} will begin with tutor {{teacher_fullname}}",
      "action": "Go to classroom"
    },
    "meeting_with_student_will_start": {
      "title": "Class soon",
      "text": "In {{delay_to_begin_min}} minutes the class {{meeting_title}} will begin with student {{participant_fullname}}",
      "action": "Go to classroom"
    },
    "meeting_will_start": {
      "title": "Class soon",
      "text": "In {{delay_to_begin_min}} minutes, the class {{meeting_title}} will begin",
      "action": "Go to classroom"
    },
    "collection-approved": {
      "text": "{{title}} collection is posted in the collections section",
      "title": "Collection published"
    },
    "collection-softDeclined": {
      "text": "The {{title}} collection has been rejected for publication. Look at the moderator's comments.",
      "title": "Collection not published"
    },
    "collection-hardDeclined": {
      "text": "The {{title}} collection has been declined for publication. Look at the moderator's comments.",
      "title": "Collection declined"
    },
    "you_successfully_enrolled_in_meeting": {
      "title": "New entry for the class",
      "text": "<p>New entry for the class {{meeting_title}}</p>Date and time: {{meeting_begin_at}}<br>Tutor: {{teacher_fullname}}"
    },
    "student_successfully_enrolled_in_meeting": {
      "title": "New entry for the class",
      "text": "<p>New entry for the class {{meeting_title}}</p>Date and time: {{meeting_begin_at}}<br>Student: {{participant_fullname}}"
    }
  },
  "onboarding": {
    "access-knowledge": "Access the extensive Onclass knowledge base",
    "badges": {
      "billing": "Billing details",
      "mobile-app": "Download mobile app",
      "schedule": "Your schedule",
      "services": "Add classes",
      "verification": "Complete verification"
    },
    "checklist": {
      "name": "Add a full name",
      "photo": "Add a photo",
      "sign-in": "Sign in",
      "verify": "Verify your profile"
    },
    "classes-hosted": "Classes hosted",
    "classes-hosted-per-month": "Classes hosted per month",
    "lessons-created": "Lessons created",
    "lessons-created-per-month": "Lessons created per month",
    "now-available": "Now available to you",
    "panel": {
      "chat": "Chat with students via Onclass messenger",
      "chat-teachers": "Chat with tutors via Onclass messenger",
      "create-content": "Create educational content and exercises in Onclass lesson editor",
      "feature-profile": "Feature their profile in the tutors' chart",
      "free-classes": "10 free classes",
      "get-free-support": "Get free support and educational materials",
      "get-payments": "Get payments via Safe deal by Onclass",
      "host-classes": "Host online classes",
      "host-group-classes": "Host group classes and webinars",
      "host-private-classes": "Host private classes",
      "invite-students": "Invite students to the classroom with a link",
      "safe-deal": "Safe deal commission 15% (instead of 30%)",
      "take-classes": "Take online classes",
      "top-spot": "A top spot in the tutors’ chart"
    },
    "rank-panel": {
      "find-students": "An opportunity to find new students",
      "grades": "New rating",
      "homeworks": "Homeworks",
      "host-webinars": "Capability to host webinars",
      "income": "Additional income",
      "payments": "Recieving payments via the Safe deal tool",
      "private-lessons": "Private lessons via invitation link",
      "publishing": "Publishing your profile to the tutor catalog",
      "respond": "Capability to quickly respond to Onclass events",
      "support": "Free support and educational materials",
      "tools": "Lots of other tools"
    },
    "sign-in": "Sign in",
    "sign-in-student": "Sign in as a student",
    "sign-in-teacher": "I want to teach",
    "start-teaching": "Begin your tutoring journey in just 4 easy steps",
    "start-teaching-and-earn": "Start teaching to a wider audience and earn more with Onclass",
    "statuses": {
      "auth-required": "Authentication required",
      "limited-access": "Limited access",
      "not-verified": "Not verified",
      "on-moderation": "Awaiting moderation",
      "rank": "Rank",
      "status": "Status",
      "student": "Student",
      "teacher": "Tutor",
      "tutor": "Tutor",
      "upgrade": "Upgrade your profile"
    },
    "stay-active": "Stay active within the next <span>month</span> to keep your Tutor status",
    "teacher-get": "As a Tutor you get",
    "time-left": "Time left",
    "upgrade-profile": "Upgrade your profile and become a Tutor",
    "want-to-learn": "I want to learn",
    "want-to-teach": "I want to teach",
    "what-authorized-can": "What can an authorized tutors do?",
    "what-teacher-rank-provide": "What does the Tutor rank provide?",
    "what-verified-can": "\"What are the capabilities of a verified user?\"",
    "steps-widget": {
      "profile": "Profile",
      "title": "Tutor profile",
      "description": "To start teaching, fill out the tutor profile and pass verification.",
      "more-details": "More about teaching",
      "button-1": "Fill out the form",
      "button-2": "Continue",
      "steps-checked": "Step {{ checked-steps }} from {{ all_steps }}",
      "percent-checked": "{{ percent }}% completed"
    }
  },
  "pagination": {
    "nothing-found": "Nothing found",
    "nothing-more": "No subscribers"
  },
  "payment-form": {
    "summary": {
      "text1": "Prices are displayed in",
      "text2": "Calculation in",
      "text3": "The exchange rate for today is",
      "text4": "for",
      "currency1": "US dollars (USD)",
      "currency2": "Virtual credits (CREDITS)",
      "currency3": "Russian ruble (RUB)",
      "currency4": "Euro (EUR)",
      "currency5": "Pound Sterling (GBP)",
      "currency6": "Ukrainian hryvnia (UAH)",
      "currency7": "Belarusian Ruble (BYN)",
      "currency8": "Belarusian Ruble (BYN)",
      "currency9": "Kazakh Tenge (KZT)",
      "currency10": "Azerbaijani Manat (AZN)",
      "currency11": "Swiss Franc (CHF)",
      "currency12": "Czech Crown (CZK)",
      "currency13": "Canadian Dollar (CAD)",
      "currency14": "Polish Zloty (PLN)",
      "currency15": "Swedish Krona (SEK)",
      "currency16": "Turkish Lira (TRY)",
      "currency17": "Chinese Yuan (CNY)",
      "currency18": "Indian Rupee (INR)",
      "currency19": "Brazilian Real (BRL)",
      "currency20": "South African Rand (ZAR)",
      "currency21": "Uzbek Sum (UZS)",
      "currency22": "Bulgarian Lev (BGN)",
      "currency23": "Japanese Yen (JPY)",
      "currency-code1": "USD",
      "currency-code2": "CREDITS",
      "currency-code3": "RUB",
      "currency-code4": "EUR",
      "currency-code5": "GBP",
      "currency-code6": "UAH",
      "currency-code7": "BYN",
      "currency-code8": "BYN",
      "currency-code9": "KZT",
      "currency-code10": "AZN",
      "currency-code11": "CHF",
      "currency-code12": "CZK",
      "currency-code13": "CAD",
      "currency-code14": "PLN",
      "currency-code15": "SEK",
      "currency-code16": "TRY",
      "currency-code17": "CNY",
      "currency-code18": "INR",
      "currency-code19": "BRL",
      "currency-code20": "ZAR",
      "currency-code21": "UZS",
      "currency-code22": "BGN",
      "currency-code23": "JPY"
    },
    "summary-item": {
      "label1": "Amount:",
      "label2": "Coupon:",
      "label3": "Service fee:",
      "label4": "Total:"
    },
    "payment-in-progress": "Wait...",
    "in-progress": "In progress...",
    "agreement1": "I agree to the",
    "terms-and-conditions": "terms and conditions",
    "agreement2": "and with the",
    "privacy-policy": "privacy policy",
    "payment-method": "Add a payment method",
    "payment-method-not-selected": "No payment method has been selected. To pay for a lesson, please select a payment method.",
    "order": {
      "price": "Price",
      "processing_fee": "Processing fee",
      "total": "Total",
      "approximate": "Approximate",
      "additional_text1": "Onclass uses the Open Exchange Rates service to convert currencies. The actual exchange rates depend on your bank and may vary.",
      "additional_text2": "Your final payment will be made in"
    }
  },
  "profile-form": {
    "about": {
      "birth_date": "Birth date",
      "country": "Country",
      "first_name": "Name",
      "company_name": "Company name",
      "type_of_employment": "Type of employment",
      "business_types": {
        "individual": "Individual",
        "company": "Company"
      },
      "enter_bank_details": "Enter your bank details",
      "full_name": "Full name",
      "last_name": "Surname",
      "meta-title": "Personal information",
      "speak": "Languages spoken:",
      "tell-about-yourself": "Tell about yourself",
      "upload": "Upload",
      "your-photo": "Photo",
      "your-name": "What is your name?",
      "your-name-hint": "Please provide your full first and last name",
      "nickname": "Nickname",
      "nickname-hint": "Nickname used as a link to your teacher page",
      "address-question": "Where do you live?",
      "address-hint": "Please specify your country of residence",
      "upload-photo": "Upload your photo",
      "upload-photo-cover": "Please upload your photo and cover image",
      "photo-hint": "Add a high-quality photo and cover",
      "dob": "Date of Birth",
      "language-spoken": "What language do you speak?",
      "about-yourself": "Please tell about yourself for your profile"
    },
    "action-complete": "Complete",
    "action-next": "Next",
    "add-more": "Add more +",
    "add-more-language": "Add more +",
    "add-subject": "Add discipline",
    "native-level-hint": "⚠️ \"Native\" refers to the language in which you are comfortable teaching and creating educational materials",
    "billing": {
      "account-number": "Account number",
      "address": "Address",
      "agreement1": "I have",
      "agreement2": "read the terms and conditions",
      "agreement3": "conditions",
      "bank": "Name of the bank",
      "bank-address": "Bank address",
      "bank_account_number": "Correspondence account",
      "beneficiary": "Beneficiary",
      "cancel": "Cancel",
      "city": "City",
      "edit": "Edit",
      "full-name": "Full Name",
      "index": "Index",
      "meta-title": "Billing",
      "not-specified": "Not specified",
      "region": "Region / State / Province",
      "residence_country": "Residence Country",
      "save": "Save",
      "send-invoice": "Send invoice to email",
      "specify-tin": "Specify TIN",
      "tax-entrepreneur": "Individual entrepreneur / LLC",
      "tax-individual": "Individual / self-employed",
      "tax-number": "Tax number",
      "tax-status": "Tax status"
    },
    "complete": {
      "next": "Next",
      "title": "Thank you for filling out your profile"
    },
    "duplicate-text": "The selected languages must not match",
    "education": {
      "title": "Where did you study?",
      "certificate": "Certificates",
      "faculty": "Class/Faculty",
      "graduation-year": "Graduation",
      "institution": "Education",
      "institution-name": "School/College/University",
      "meta-title": "Education",
      "certificates": "Certificates",
      "certificates-hint": "Please, upload certificates to verify your qualifications, which may help in attracting more students (optional)",
      "format-hint": "Accepted file formats: jpg, png, pdf, with a maximum size of 5 MB",
      "delete": "Delete"
    },
    "experience": {
      "title": "Working experience",
      "from": "From",
      "job": "Place of work",
      "meta-title": "Experience",
      "organization": "Organization",
      "position": "Position",
      "to": "To",
      "until-now": "until now",
      "no-working-experience": "No experience"
    },
    "max-length-text": "Text must be no more than 2000 characters",
    "min-length-text": "Text must be at least 150 characters",
    "microphone": {
      "setting-audio-video": "Check and configure your video/audio devices",
      "possible-solution": "Possible solutions to the problem if your video / microphone does not work:",
      "reload": "Restart your browser",
      "check-your-setting": "Check your camera and microphone access settings in your browser. Click on one of these icons in the address bar line and allow access to media devices",
      "safari-instruction": "(Instruction for Safari)",
      "another-browser": "Try another browser.",
      "recommended": "(recommended)",
      "install-chrome": "Install Chrome Browser",
      "camera": "Camera",
      "microphone": "Microphone",
      "camera-error": "Camera is not found or there is no access, check the settings"
    },
    "remove": "Remove",
    "required-native": "Select at least one native language",
    "save": "Save",
    "saved": "Saved",
    "saving": "Saving",
    "next": "Next",
    "back": "Back",
    "skip": "Skip",
    "finish": "Finish",
    "to-main": "Go to main page",
    "continue": "Continue",
    "send-for-moderation": "Send for moderation",
    "security": {
      "accounts": "Accounts",
      "email": "E-mail",
      "get-code": "Get code",
      "meta-title": "Contacts",
      "password": "Password",
      "phone": "Phone",
      "skype": "Skype",
      "telegram": "Telegram",
      "contact-information": "Contact Information",
      "contact-information-hint": "For site access and password reset purposes, you are required to provide your phone number and email address",
      "sms-code": "SMS code",
      "verify": "Verify",
      "your-id": "Your ID",
      "confirm": "Confirm",
      "change": "Change",
      "cancel": "Cancel",
      "alert-info-title": {
        "days-14": "You need to wait",
        "minutes-5": "You need to wait a little",
        "same-number": "The phone number has not changed"
      },
      "alert-info-message": {
        "days-14": "You can change your phone number only once every 14 days",
        "minutes-5": "You can confirm your phone number once every 5 minutes",
        "same-number": "This phone number has already been verified previously"
      }
    },
    "select": "Select language",
    "select-language": "Select language",
    "select-country": "Select country",
    "studying": {
      "level": "level",
      "meta-title": "Studying"
    },
    "subject": "Subject",
    "teaching": {
      "title": "What do you teach?",
      "subtitle": "Add the subjects you wish to teach on the Onclass platform\n <p></p>\n⚠️ If you teach a foreign language, please select a language labeled as 'Foreign'",
      "about-teaching": "Please provide a more detailed description of how you teach this subject, a detailed description can significantly help attract interested students (minimum of 400 characters)",
      "meta-title": "I teach",
      "video": "Video",
      "safari-holder-text": "Video recording using a webcam in Safari is not supported. Use another browser, such as Google Chrome.",
      "what-exactly": "What exactly?",
      "record-video": "Record your video",
      "introduce-yourself": "Now introduce yourself to students and tell about your teaching",
      "start-record": "Start recording",
      "stop-record": "Stop recording",
      "option-link-text": "Paste a link to your video (Youtube, Vimeo)",
      "option-video-text": "Record your intro using a webcam",
      "option-upload-video-text": "Upload your video",
      "option-upload-video-description": "Safari browser only. If you want to record a video from a browser, use Chrome",
      "allow-video": "To record a video, please allow",
      "camera-access": "access to the camera",
      "loading-video": "Video is loading",
      "short-video": "Whoops! Your video is too short. To proceed, make sure your video is more then 30 seconds long.",
      "long-video": "Whoops! Your video is too long. To proceed, make sure to register a video lasting less than 2 minutes.",
      "same-video": "Whoops! Your video is used early. To proceed, make sure your video is unique.",
      "incorrect-format": "Incorrect link format",
      "tips-for-video": "Tips for making a good video",
      "record-orientation": "Record videos in landscape orientation",
      "video-duration": "The duration of the video must be at least 30 seconds and no more than 2 minutes",
      "background": "Use a neutral background",
      "face-visible": "Your face and eyes must be fully visible",
      "presentation": "Don't use presentations",
      "violating": "When submitting your video, make sure that you are not violating anyone else's copyright.",
      "file-size": "File size cannot exceed 500 MB",
      "video-format": "For best results, the video format should be 16:9",
      "delete": "Delete",
      "overwrite": "Overwrite",
      "example": "Example",
      "already-selected": "This item has already been selected",
      "paste-as-link": "Paste a link to video",
      "no-camera-alert": {
        "title": "The camera is not connected",
        "description": "Video recording is not possible because there is no camera connected to your device"
      }
    },
    "services": {
      "title": "Add your classes",
      "hint": "Create your own online classes and publish them in our comprehensive catalog. Anyone interested can easily find and enroll in your sessions, expanding your reach to a wider audience eager to learn from you",
      "action": "Add new class +"
    },
    "card": {
      "title": "Link your bank card",
      "hint": "Linking a card will allow you to accept payment for your classes and services and receive guaranteed payments",
      "pending": "Card activation",
      "active": "Card active",
      "canceled": "Card canceled",
      "expired": "Card expired",
      "deleting": "Card deleting"
    },
    "app": {
      "title": "Mobile app"
    },
    "user-pic": {
      "take-photo": "Take a photo",
      "see-example": "See example",
      "use-background": "Make use of a consistent background",
      "smile": "Smile",
      "look-camera": "Look at the camera",
      "tips-for-photo": "Tips for taking a good photo",
      "used-as-link": "Used as a link to your personal page",
      "upload-photo": "Upload photo",
      "close-photo": "Cancel",
      "put-face-in-circle": "Put your face in the circle and press the button"
    },
    "confirm-modal": {
      "title": "Enter code",
      "subtitle": "The verification code has been sent to your email inbox.",
      "subtitle2": "We sent an SMS with a code to the number +{{ term }}",
      "text": "If the code does not arrive, make sure that it is not in the spam, check the correctness of the e-mail you entered or request the code again after {{ term }} seconds.",
      "text2": "If the code does not arrive, check the correctness of the phone you entered or request the code again after {{ term }} seconds.",
      "again": "Send code again"
    },
    "filled-all": {
      "title": "Thank you!",
      "description-1": "Congratulations! Your application is under consideration and will be considered within 5 days. If we have any questions we will contact you.",
      "description-2": "Now you can study with your students and use the onclass platform!"
    },
    "on-moderation": {
      "title": "Profile submitted for moderation",
      "description": "Once the changes are approved, you will receive a email and push notification\n\n\n\n\n\n"
    }
  },
  "profile-sidebar-widget-20210625": {
    "hide-reviews": "Hide reviews",
    "hr": "hr",
    "price-title": "Individual classes from:",
    "rating-label": "Tutors rating",
    "show-reviews": "Show reviews",
    "sign-up": "Book now",
    "popover-text": "Add your services <br> and place them on <a href=\"/schedule/calendar\" class=\"link\">your <br> schedule </a> so others <br> can sign up for <br> your classes.",
    "popover-link": "Add a service"
  },
  "profile-verification-widget": {
    "action-change": "Change",
    "action-verify": "Verify",
    "profile-verified": "Profile verified",
    "teacher-status": "Tutor status:",
    "teacher-status-not_teacher": "not verified",
    "teacher-status-not_verified": "awaiting verification",
    "teacher-status-verified": "verified",
    "verification-details": "Show details",
    "verification-help": "Verified profile",
    "verification-in-progress": "Profile verification can take from 1 to 7 days"
  },
  "help-widget": {
    "title": "Need help?",
    "description": "Contact our support",
    "action": "Click to chat"
  },
  "regular-schedule-form": {
    "before-start-widget-text": "How much time in advance is needed to book a class to allow for proper preparation?",
    "before-start-widget-title": "What is the lead time for booking a class before it starts?",
    "signup-time-limits-title": "Booking limits of classes",
    "signup-time-min-limit": "Not earlier than",
    "signup-time-max-limit": "No later than",
    "signup-time.1 week": "1 week",
    "signup-time.2 weeks": "2 weeks",
    "signup-time.3 weeks": "3 weeks",
    "signup-time.4 weeks": "4 weeks",
    "signup-time.1 hour": "1 hour",
    "signup-time.2 hours": "2 hours",
    "signup-time.3 hours": "3 hours",
    "signup-time.4 hours": "4 hours",
    "signup-time.5 hours": "5 hours",
    "signup-time.6 hours": "6 hours",
    "signup-time.12 hours": "12 hours",
    "signup-time.24 hours": "24 hours",
    "signup-time.48 hours": "48 hours",
    "signup-time.72 hours": "72 hours",
    "hide": "Hide",
    "recommendation-text": "To ensure adequate preparation time and avoid missed classes, it's recommended to set appropriate booking limits.\n<p>\nFor classes lasting 2 hours or longer, we suggest setting your working periods to at least 2 consecutive hours.\n<p>\n⚠️ Please note that accurate scheduling is crucial. Missing classes can lead to penalties and negative reviews for non-attendance.",
    "recommendation-title": "Recommendation",
    "save": "Save",
    "saved": "Saved",
    "show": "Open",
    "text": "To avoid scheduling conflicts with students in different time zones, it's essential to set the correct time zone",
    "timezone-placeholder": "Select your time zone",
    "title": "Set up your schedule",
    "title-select": "Select your time zone",
    "to": "to",
    "subtitle": "Automate your class scheduling by setting your availability in your calendar. The system will automatically schedule your regular and one-time classes. Mini-courses and courses will need to be added manually"
  },
  "settings-page": {
    "notifications": {
      "feedback": "Reviews",
      "new-message": "New message",
      "like-dislike": "Like / Dislike",
      "shared": "Shared",
      "comments": "Comments",
      "mentions": "Mentions",
      "events": "Classes",
      "new-subscriber": "New subscriber",
      "group-invitation": "Group invitation",
      "birthdays": "Birthdays",
      "upcoming-events": "Upcoming events",
      "waiting-for-payment": "Awaiting Payment",
      "rating": "Rating",
      "reviews": "Reviews",
      "new-teacher-selected-disciplines": "New teacher in selected disciplines",
      "invitation-lesson": "Invitation to the lesson",
      "pay": "Pay",
      "new-homework": "New homework",
      "new-post-subscriber": "New post from subscriber",
      "email-notifications": "Email Notifications",
      "email-for-notifications": "Email for notifications",
      "notifications-frequency": "Notifications frequency",
      "change": "Change",
      "never-notify": "Never notify"
    },
    "password": {
      "change": "Change",
      "fields": {
        "label1": "Old password",
        "label2": "New password",
        "label3": "Confirm password"
      },
      "label": "Password",
      "submit-form": "Save new password",
      "title": "Change Password"
    },
    "timezone": {
      "title": "Select your time zone",
      "description": "The right time zone can help synchronize class time with your instructors"
    },
    "tabs": {
      "common": "Common",
      "finance": "Payment profile",
      "notifications": "Notifications",
      "password": "Password",
      "security": "Contacts",
      "timezone": "Timezone"
    }
  },
  "sidebar-options": {
    "balance": "Balance",
    "class-calendar": "Class calendar",
    "edit-profile": "Edit profile",
    "individual-sessions": "As a teacher, you will start receiving ratings from students, and your profile will feature a public ranking.",
    "on-the-account": "Balance",
    "profile": "Profile",
    "questionnaire": "Profile",
    "replenish": "Top up",
    "settings": "Settings",
    "sign-up-lesson": "Sign up for a lesson",
    "teacher-profile": "Tutor profile"
  },
  "statistic-informer": {
    "create-content": "Create your lesson",
    "lesson": "Lessons <br> created",
    "lesson-student": "Free <br> Classes:",
    "subject": "Number <br> of subjects",
    "subtitle": "Create valuable learning content with the editor, engage students, and capitalize on your knowledge.",
    "subtitle-student": "Free lessons and other useful content, study with tutors on the Onclass platform",
    "teachers": "Number <br> of tutors",
    "title": "Join the Onclass community",
    "title-student": "Study with Onclass tutors"
  },
  "classes": {
    "meta-create-title": "Create a classroom",
    "meta-form-title": "Classroom editor",
    "meta-title": "Classes",
    "page-header-title": "Classes"
  },
  "subject-filter": {
    "disabled": "disabled",
    "enabled": "enabled"
  },
  "subject-filters": {
    "apply": "Apply filter",
    "clear": "Clear filter",
    "current-filter": "Selected",
    "current-filter-all": "All",
    "foreign-language": "Foreign",
    "label-current": "Selected:",
    "native-language": "Native",
    "not-found": "No such discipline found",
    "recent": "Recent",
    "search-input-placeholder": "Search",
    "tab-all": "All",
    "tab-favorites": "Favorites",
    "title": "Subjects",
    "title-foreign": "Foreign languages",
    "title-native": "Native languages",
    "title-non_categorized": "Others"
  },
  "subject-levels": {
    "1-class": "1 class",
    "10-class": "10 class",
    "11-class": "11 class",
    "2-class": "2 class",
    "3-class": "3 class",
    "4-class": "4 class",
    "5-class": "5 class",
    "6-class": "6 class",
    "7-class": "7 class",
    "8-class": "8 class",
    "9-class": "9 class",
    "advanced": "Advanced",
    "any-level": "Any Level",
    "beginner": "Beginner",
    "elementary": "Elementary",
    "intermediate": "Intermediate",
    "pre-intermediate": "Pre-Intermediate",
    "proficiency": "Proficiency",
    "upper-intermediate": "Upper Intermediate"
  },
  "subject-selector": {
    "create": "Create new",
    "foreign": "Foreign",
    "lvl1": {
      "foreign": "Foreign languages",
      "native": "Native languages",
      "non_categorized": "Other subjects",
      "other": "New discipline"
    },
    "native": "Native",
    "not-subject": "No such discipline found",
    "recent-title": "Recent",
    "search-placeholder": "Subject search",
    "select-placeholder": "Subject"
  },
  "subject-selector-in-header": {
    "category": {
      "foreign": "Foreign languages",
      "native": "Native languages",
      "others": "Other subjects"
    },
    "default-placeholder": "Choose a subject",
    "link": "Become a tutor",
    "recent": "Recent",
    "tabs.all": "All",
    "tabs.favorites": "Favorites",
    "text": "Didn't find your subject?"
  },
  "subscription-plan-periods": {
    "day": "every day",
    "month1": "every month",
    "month3": "every three months",
    "month6": "every six months",
    "year": "annual"
  },
  "subscription-plans": {
    "action-activate": "Activate",
    "action-activate2": "Activate",
    "action-active": "Active plan",
    "action-current": "Current",
    "period": "per month",
    "free-for-students": "Free for students",
    "bank-cards": {
      "add": "Add a card",
      "expires-flag": "Deadline expires",
      "expires-label": "Card expiry date:",
      "label": "Your cards",
      "link": "Edit",
      "no-card-title": "No linked cards",
      "text": "Linked cards"
    },
    "benefit-1": "You don't need to pay the <span>onclass.com</span> fee for your classes",
    "benefit-2": "You might provide free lessons to attract students",
    "benefit-3": "You might accept payments using bank card",
    "benefits-title": "Subscription benefits",
    "calculator": {
      "action": "Upgrade",
      "action2": "Buy credits",
      "credits": "credits",
      "credits-label": "How many students are you planning to study with?",
      "credits-label2": "How many times a month do you plan to hold classes?",
      "credits-label3": "The number of credits you need per month:",
      "expand-action": "Calculate the number of credits?",
      "info-label": "Your plan:",
      "info-label2": "Recommended plan:",
      "title": "Select your credits package"
    },
    "cancel-subscription": "Cancel subscription",
    "cancel-subscription-switch": "Will canceled after update",
    "commission-action": "Reduce your commission?",
    "credit-value-label": "Credits left:",
    "every-month": "Every month",
    "forms": {
      "action-activate-plan": "Upgrade",
      "action-apply": "Apply",
      "action-buy-credits": "Pay",
      "action-cancel": "Unsubscribe",
      "action-payment": "Payment",
      "activate-text": "Activating your subscription",
      "agree": "I agree to the terms and conditions, as well as",
      "alert-text": "Attention! Canceling your subscription will burn your credits for subscription as well as purchase additionally. Bonus credits will be saved.",
      "amount-credits": "Credits amount:",
      "bonus": "Bonus:",
      "buying-credits": "Purchase of credits",
      "by-subscription": "Subscription credits:",
      "cancel-mode-text": "Do you want to cancel your subscription? Please write below what did not suit you and why you are going to do it:",
      "cancel-text": "Cancellation of subscription",
      "cancellation-label": "Your plan after cancellation:",
      "cancellation-label2": "Your credits after canceling your subscription:",
      "header-label": "Your plan:",
      "info-label": "Monthly subscription to Onclass",
      "next-payment": "Next payment date:",
      "next-payment-text1": "Payment will be charged automatically.",
      "next-payment-text2": "You can cancel your subscription at any time.",
      "payment": "Proceed to checkout",
      "privacy-policy": "the privacy policy",
      "promo-placeholder": "Promo code",
      "purchased": "Purchased:",
      "recommended-plan": "Recommended plan:",
      "total-credit": "Total credits left:",
      "total-pay": "Total:",
      "your-plan": "Your plan:"
    },
    "history-mini": {
      "link": "See the whole story",
      "subtitle": "Latest transactions",
      "title": "History"
    },
    "improve-plan": "Improve plan",
    "change-plan": "Change plan",
    "resume": "Renew",
    "label-hit": "Hit",
    "next-update-label": "Renews: ",
    "pay-action": "Add additional credits",
    "periods": {
      "day": "day",
      "month1": "month",
      "month3": "3 month",
      "month6": "6 month",
      "year": "year"
    },
    "plan-advanced": {
      "bonus-hint": "+40 bonus",
      "hint": "Up to 100 lesson per month",
      "nameplate": "MOST POPULAR",
      "subtitle": "Optimal commission",
      "title": "Advanced"
    },
    "plan-beginners": {
      "hint": "Up to 20 lesson per month",
      "subtitle": "20 credits",
      "title": "Beginners"
    },
    "plan-free": {
      "hint": "Up to 5 lesson per month",
      "subtitle": "5 credits",
      "title": "Free"
    },
    "plan-mini-card": {
      "credits": "Credits:",
      "improve": "Improve plan",
      "label": "Your plan:",
      "replenish": "Add additional credits"
    },
    "plan-professional": {
      "nameplate": "BEST OFFER",
      "subtitle": "Minimal commissions",
      "title": "Professional"
    },
    "plan-standard": {
      "bonus-hint": "+10 bonus",
      "hint": "Up to 50 lesson per month",
      "subtitle": "40 credits",
      "title": "Standard"
    },
    "plan-starter": {
      "subtitle": "High commission",
      "title": "Starter"
    },
    "plan-trial": {
      "hint": "Try 30 days free, then {{ price }} per month",
      "nameplate": "Special offer",
      "subtitle": "30 days for FREE",
      "title": "Trial"
    },
    "plan-not-selected": "Plan is not selected",
    "choose-plan": "Choose a plan",
    "subscription-description": "Subscription at Onclass",
    "terms": {
      "term1": "Credits per month: {{ receive_credits_per_month }}",
      "term2": "Onclass payout: {{ safe_trade_commission }} %",
      "term3": "Discount for extra credits: {{ discount_the_purchase_of_credits }} %",
      "term4": "Receive a {{ conference_commission_discount }} % payout with Onclass payments after each class",
      "term5": "Credits per month:  {{ credits_per_month }} ",
      "term6": "Discount for additional credits {{ discount_on_purchase_of_additional_credits }} %"
    },
    "terms-title": "Terms and Conditions",
    "title": "Choose your subscription plan",
    "titles": {
      "current": "Your plan",
      "payment": "Additional credits",
      "plans": "Other tariffs"
    },
    "your-plan": "Your plan",
    "lessons-per-month": "classes per month*",
    "text-paywall-wrapper": "\"Onclass payouts\" is a payment method where Onclass ensures the security of the transaction, acting as a guarantor. This guarantees that you receive your payment promptly after the class. In this arrangement, students pay for their classes in advance. Onclass then processes the payment to the tutor within 1-3 business days following the class, after deducting a commission. The commission rate varies across different tariff plans, allowing you to select the one that best fits your needs.\n<p>\n\"Onclass credits\" serve as a means to conduct classes with all payment settlements occurring outside of the Onclass payment system. This method allows for direct financial transactions between students and tutors. The cost structure is simple: 1 credit equals up to 1 hour of teaching for one student, 2 credits for two students in a class, and so forth. For example, 10 credits can be utilized for 10 individual classes or a single group class with 10 students.\n<p>\nYou have the flexibility to purchase credits at any time. Additionally, the tariff plans include discounts on the purchase of these credits, offering you more value and convenience in managing your class schedules and payments.",
    "title-paywall": "Choose your plan",
    "next-plan-text": "Starting on {{date}}, your plan will automatically change to {{plan}} plan. The cost of {{plan}} plan is {{price}}.",
    "next-plan-text-onclass-me": "Your subscription will be canceled on {{date}}",
    "cancel": "Cancel",
    "stub-billing-history": "You have no billing transaction",
    "stub-billing-subscribe": "You have no subscriptions",
    "plan-inactive": "Activate your plan",
    "reason-for-deactivation": {
      "payment_method_is_not_selected": "Payment method is not selected",
      "billing-error": "Error on the payment gateway side",
      "bank-declined-payment": "Your bank declined the payment"
    }
  },
  "teacher-badges-widget": {
    "bank-card": {
      "item1": "Set an easy payment method for your private classes",
      "item2": "Get access to the Safe deal tool by Onclass",
      "link": "Manage billing details",
      "title": "Billing details"
    },
    "calendar": {
      "item1": "Your schedule",
      "item2": "Plan your next classes",
      "link": "Go to Calendar",
      "title": "Calendar"
    },
    "mobile-app": {
      "item1": "Get notifications from your Calendar",
      "item2": "Host your classes from anywhere",
      "link": "Install the app",
      "title": "Mobile app"
    },
    "services": {
      "item1": "Host private classes",
      "item2": "Set the option to order a class via your profile",
      "item3": "Quick search in the tutors’ chart",
      "link": "Add services",
      "title": "Classes"
    },
    "verification": {
      "item1": "Feature in the tutors’ chart",
      "item2": "Post your content in the Library",
      "item3": "Get grades from students and upgrade your rank",
      "item4": "Free support and educational materials",
      "item5": "Safe deal payment method by Onclass",
      "link": "Verify account",
      "title": "Verification"
    }
  },
  "teacher-card": {
    "actions": {
      "more-details": "Show more",
      "next-reviews": "Show next reviews",
      "no-available-hours": "Unavailable",
      "sign-up": "Book now"
    },
    "ago": "ago",
    "awaiting-approval": "Awaiting tutor approval",
    "before-start": "before the beginning",
    "from": "from",
    "hr": "hr",
    "last-visit": "last visit",
    "leave-message": "Send message",
    "lessons-quantity": "Classes:",
    "menu-complain": "Complain",
    "menu-unsubscribe": "Unsubscribe",
    "native": "Native",
    "native-lang": "Speaks:",
    "no-content1": "The tutor has",
    "no-content2": "not uploaded a video",
    "no-info": "The user did not provide this information.",
    "no-reviews": "No reviews yet",
    "one-hour": "(1 hour)",
    "read-more": "Read more",
    "reviews-quantity": "Reviews:",
    "roll-up": "roll up",
    "scheduled": "Scheduled for",
    "select-date-time": "Please select a date and time",
    "selected": "Selected:",
    "send-message": "Message",
    "show-more": "show more",
    "sign-up-lesson": "Sign up for a lesson",
    "speak-on": "Speaks:",
    "stats": {
      "classes": "Classes:",
      "rating": "Rating:",
      "reviews": "reviews",
      "reviews-count": "Reviews:",
      "students": "Students:"
    },
    "subscribe": "Subscribe",
    "subscribe-to": "Subscribe",
    "subscribed": "You are subscribed",
    "tab-about": "About the tutor",
    "tab-reviews": "Reviews",
    "tab-schedule": "Lessons and Schedule",
    "tabs": {
      "about": "Personal information",
      "lessons": "Popular classes",
      "reviews": "Reviews",
      "schedule": "Schedule",
      "teaching": "I teach"
    },
    "teaches": "Teaches:",
    "titles": {
      "add-favorites": "Add to favorites",
      "remove-favorites": "Remove from favorites",
      "subscribe": "Subscribe",
      "teacher-page": "Tutors page",
      "unsubscribe": "Unsubscribe",
      "video-presentation": "Watch video presentation",
      "write-message": "Write a message",
      "write-message-sub": "Usually responds within 4 hours"
    },
    "total": "Total",
    "per-class": "per class",
    "share-link-text": "Share your profile link with your students so they can get to know you better"
  },
  "teacher-filter-popup": {
    "button-1": "Name",
    "button-2": "Date added",
    "button-3": "Popularity",
    "title": "Sort by"
  },
  "teacher-page": {
    "user-service-form": {
      "label1": "Subject",
      "label2": "Name",
      "label3": "Short description",
      "label4": "Price per hour",
      "placeholder1": "Subject",
      "placeholder2": "Course name",
      "placeholder3": "Short course description",
      "placeholder4": "Detailed course description",
      "remove": "Remove",
      "save": "Save"
    }
  },
  "teacher-profile": {
    "action-add-individual-lesson": "Add individual class",
    "edit-service": "Edit individual lesson",
    "individual-lessons-title": "As a teacher, you will start receiving ratings from students, and your profile will feature a public ranking.",
    "meta-title": "Tutor profile",
    "new-service": "New private lesson",
    "title": "Tutor profile"
  },
  "teacher-steps": {
    "alternative-titles": {
      "bank_card": "Your bank cards",
      "schedule": "Your working days",
      "services": "Your services",
      "use_mobile_app": "Mobile app",
      "verification": "Tutor profile"
    },
    "placeholder": {
      "bank_card": {
        "label": "Why should I link a card?",
        "link": "Link card",
        "text": "Linking a card will allow you to accept payments for your classes and services and get guaranteed payouts."
      },
      "schedule": {
        "label": "What is it for?",
        "link": "Set my schedule",
        "text": "When you mark your workdays on the calendar, students can sign up for classes with you. Otherwise, they will not be able to do it."
      },
      "services": {
        "label": "What is it for?",
        "link": "Add new class +",
        "text": "By talking about your services, you can attract more students and, as a result, earn more."
      },
      "use-mobile-app": {
        "label": "What is the application for me?",
        "text": "Stay connected on the go with the Onclass mobile application, ensuring you never miss your classes. Receive immediate notifications and keep in touch through the in-app chat, no matter your location",
        "text2": "Scan this QR code with your smartphone camera and follow the link",
        "text-done": "With the Onclass application, you can quickly receive notifications of all events, as well as carry out chat correspondence."
      },
      "verification": {
        "label": "Verified profile",
        "text": "Verification is confirmation of your qualifications as a tutor. After verification, your profile will appear in the tutor's directory and be available to all Onclass users. In addition, you can create public classes on which anyone can sign up."
      }
    },
    "title": "Start teaching on Onclass",
    "text": "To start enjoying the full benefits of the Onclass platform as a tutor, please follow the 5 steps below. If you have any difficulties, you can always contact support and <a class=\"link\">get advice.</a>",
    "titles": {
      "bank_card": "Step 4. Fill out a financial profile and link a bank card",
      "schedule": "Step 3. Set your schedule",
      "services": "Step 2. Add your individual classes",
      "use_mobile_app": "Step 5. Download and install the Onclass mobile app",
      "verification": "Step 1. Complete verification",
      "bank_card_done": "Bank card",
      "schedule_done": "Schedule",
      "services_done": "Popular classes",
      "use_mobile_app_done": "Onclass mobile app",
      "verification_done": "Profile"
    },
    "tooltip-text": "Fill out the profile and send it for moderation to add services",
    "working-days": "Selected working days:"
  },
  "tutors": {
    "filter": "Filter",
    "meta-favorites-title": "Favorite tutors",
    "meta-mine-title": "My tutors",
    "meta-title": "Tutors",
    "page-header-title": "Tutors",
    "search": "Search...",
    "tabs-all": "All",
    "tabs-favorites": "Favorites",
    "tabs-mine": "My"
  },
  "user-menu": {
    "add-avatar": "Add a photo",
    "items": {
      "edit-profile": "Edit",
      "payments": "Payment profile",
      "personal-profile": "Profile",
      "settings": "Settings",
      "signout": "Sign out",
      "signin": "Sign in",
      "rules": "Rules for tutors",
      "support": "Support",
      "how-onclass-work": "How does Onclass work?",
      "take-up-teaching": "Start teaching",
      "teacher-office": "Tutor's office",
      "introduction": "Introduction"
    },
    "question": "What's your name?"
  },
  "rules": {
    "widget": {
      "text": "Rules for attending classes for tutors",
      "hide": "Hide",
      "more": "More"
    },
    "page": {
      "title1": "Tutor Guidelines for Class Attendance",
      "text1": "Onclass expects tutors to maintain professionalism and punctuality.",
      "text2": " ",
      "text3": "Cancellation Policy: Cancellation Policy:\nLate Cancellations: Tutors can cancel classes booked more than 24 hours in advance without penalty.\nNo-Shows/Last-Minute Cancellations: If a tutor misses or cancels a class less than 24 hours before it starts, they will face the following consequences:",
      "option1-1": "Negative auto-review",
      "option1-2": "Penalty in the amount of 50% of the cost of the lesson",
      "title2": "How can tutors avoid missing classes?",
      "option2-1": "Adjust your availability to minimize unexpected bookings",
      "option2-2": "Keep your Onclass calendar up-to-date",
      "option2-3": "Stay informed about new bookings and changes",
      "option2-4": "If unexpected circumstances do not allow the lesson to be held at the appointed time, agree with the student on another time and reschedule the lesson.",
      "accept": "I have read the rules and accept them",
      "informed": "Accept"
    }
  },
  "review": {
    "edit-review": "Edit review",
    "private-class": "Private Class",
    "class": "Class"
  },
  "user-profile": {
    "accounts": "Accounts",
    "billing": {
      "balance_type": {
        "block": "Blocked",
        "fine": "Fine",
        "plan_grants": "Plan grants",
        "paid": "Paid",
        "onclass_fee": "Onclass fee",
        "primary": "Primary",
        "transaction_fee": "Transaction fee"
      },
      "cards": {
        "remove": "Remove"
      },
      "history": {
        "amount": "Amount",
        "from_account": "From account",
        "from_balance": "From balance",
        "operation_name": "Operation",
        "to_account": "To account",
        "to_balance": "To balance"
      },
      "operation": {
        "bonus": "Bonus",
        "bonus_credits_subscription": "Bonus credits",
        "coupon": "Coupon",
        "gift": "Gift",
        "meeting_providing": "Meeting providing",
        "paid_meeting_deposit": "Paid meeting deposit",
        "paid_meeting_deposit_undo": "Withdraw meeting deposit",
        "payment": "Payment",
        "payment_processing_fee": "Payment processing fee",
        "payout": "Payouts",
        "payout_processing_fee": "Payout processing fee",
        "platform_fee": "Platform fee",
        "public_meeting_fine": "Public meeting fee",
        "purchase_content": "Purchase content",
        "purchase_credits": "Purchase credits",
        "purchase_credits_subscription": "Purchase subscription",
        "purchase_group_access": "Purchase group access",
        "purchase_meeting": "Purchase meeting",
        "refund_content": "Content refund",
        "refund_group_access": "Refund group access",
        "refund_meeting": "Purchase meeeting refund",
        "reward_content": "Reward for content",
        "reward_content_succeed": "reward_content_succeed",
        "reward_meeting": "Reward for meeting",
        "reward_meeting_succeed": "reward_meeting_succeed",
        "reward_referral": "Reward for referral",
        "reward_referral_purchase_succeed": "reward_referral_purchase_succeed",
        "reward_referral_subscription_succeed": "reward_referral_subscription_succeed",
        "reward_teacher_verified": "reward_teacher_verified",
        "reward_user_profile_done": "reward_user_profile_done",
        "reward_user_signed_up": "reward_user_signed_up",
        "safe_card_attach": "Card attach",
        "purchase_collection": "Purchase collection",
        "purchase_course": "Purchase course",
        "meeting_checkout": "Payment for the lesson",
        "purchase_meeting_by_safe_deal": "Purchase meeting by safe deal",
        "switch_to_billing_plan": "Activation of the billing plan",
        "admin_correction": "Credit account replenishment",
        "renew_billing_plan": "Renewing your subscription"
      },
      "status": {
        "canceled": "canceled",
        "failed": "failed",
        "pending": "pending",
        "processing": "processing",
        "succeed": "succeed"
      }
    },
    "certifications": "Certificates",
    "create-lesson": "Create lesson",
    "create-publication": "Create publication",
    "edit": "Edit",
    "i-speak": "Languages spoken:",
    "i-study": "I study different subjects:",
    "i-teach": "I teach different subjects:",
    "individual-sessions-create": "Add individual class",
    "individual-sessions-title": "Popular classes",
    "leave-message": "Send message",
    "meeting-reviews-sort-latest": "Latest",
    "meeting-reviews-sort-rating-asc": "Positive",
    "meeting-reviews-sort-rating-desc": "Negative",
    "menu-complain": "Complain",
    "menu-unsubscribe": "Unsubscribe",
    "meta-teacher-title": "Learn {{subjects}} online with {{name}}",
    "meta-teacher-title2": "Learn yourself {{subjects}} online with {{name}}",
    "no-info": "The user did not provide this information.",
    "no-lessons": "No lessons yet",
    "no-publications": "No posts yet",
    "promo-info": {
      "answer1": "Create lessons for self-study",
      "answer2": "Conduct online lessons",
      "question": "How do I get feedback and improve my teacher rating?",
      "sign-lesson": "Schedule your class"
    },
    "provide-info": "Edit",
    "reviews": "Reviews",
    "reviews-see-all": "Show all",
    "reviews-see-more": "Show more",
    "roll-up": "roll up",
    "send": "Message",
    "service-card": {
      "alert": {
        "close": "Close",
        "remove": "Remove",
        "text": "In case of rejection, the list of individual lessons will not be changed",
        "title": "Delete private lesson?",
        "have-collection-title": "The card cannot be deleted",
        "have-collection-text": "You have published collections. To delete a map, you need to remove all paid collections from publication"
      },
      "edit": "Edit",
      "no-free-time": "Unavailable",
      "price": "Cost of participation:",
      "remove": "Remove",
      "signup": "Book now"
    },
    "teaching-block": {
      "alert": {
        "ok": "Ok",
        "title": "Fill in empty fields",
        "text": "Fill in the fields in the previous discipline before adding a new one. "
      }
    },
    "show-more": "show more",
    "sorter": {
      "date": "By date",
      "rating": "By rating"
    },
    "speak-on": "Speaks:",
    "stat-title-1": "Followers",
    "stat-title-2": "Following",
    "stat-title-3": "Lessons",
    "stat-title-4": "Points",
    "stat-title-5": "Lessons passed",
    "stat-title-6": "Grade point average",
    "stat-title-7": "Pupils",
    "stat-title-8": "Reviews",
    "stat-title-9": "Something else",
    "stats": {
      "publications": "Publications",
      "subscribers": "Followers",
      "subscriptions": "Subscriptions"
    },
    "subscribe": "Follow",
    "subscribe-user": "Subscribe",
    "subscribed": "Following",
    "tab-main": "Main",
    "tab-classes": "Classes",
    "tab-lessons": "Lessons",
    "tab-schools": "Schools",
    "tab-feedback": "Reviews",
    "tab-about": "Personal information",
    "tab-about-student": "About student",
    "tab-accounts": "Accounts:",
    "tab-certifications": "Certificates",
    "tab-education": "Education",
    "tab-experience": "Experience",
    "tab-learning": "Learning",
    "tab-publications": "Publications",
    "tab-collections": "Collections",
    "tab-teaching": "I teach",
    "tab-about-tutor": "About tutor",
    "teach": "Teaches:",
    "teacher": "Tutor",
    "guarantee-text": "3-day money-back guarantee",
    "subjects-taught": "Subject",
    "placeholder": {
      "teaching": {
        "text": "List the subjects you teach"
      },
      "about": {
        "text": "Enter information about yourself",
        "link": "Go to profile"
      },
      "Classes": {
        "text": "Students will be able to sign up for private lessons with you if you describe how you can be useful",
        "link": "Create a class"
      },
      "collection": {
        "text": "Create collections of your lessons, list them for sale or use in class",
        "link": "Create a collection"
      },
      "feedback": {
        "text": "Create and run classes to get feedback",
        "link": "Schedule a class"
      },
      "lesson": {
        "text": "Create lessons and other educational content for your online classes",
        "link": "Create lesson"
      },
      "school": {
        "text": "Create your own school and get guaranteed income",
        "link": "Create a school"
      }
    },
    "teacher-stat": {
      "title-stat": "Tutor's statistics",
      "class": "Classes",
      "country": "Country",
      "date": "Member since",
      "geo": "Geo",
      "last-activity-time": "Last active",
      "not_verified": "On verification",
      "publications": "Publications",
      "rating": "Tutors rating",
      "response-time": "Average response time",
      "status": "Status",
      "students": "Students",
      "teacher": "Tutor",
      "title": "About the tutor",
      "title2": "Other",
      "tutor": "Tutor",
      "average-rating": "Rating",
      "total-publications": "Publications",
      "created-lessons": "Lessons"
    },
    "teaching": "I teach",
    "title-1": "Lessons",
    "title-2": "Lessons learned",
    "title-about": "Personal information",
    "title-about-me": "About",
    "title-collections": "Collections",
    "title-certification": "Certification",
    "title-education": "Education",
    "title-experience": "Experience",
    "warning-no-teaching-subjects": "Please setup teaching subjects",
    "was": "was",
    "online-now": "Online now",
    "what-is-your-name": "Your Name",
    "you-no-info": "You did not provide any information.",
    "you-subscribed": "You are subscribed"
  },
  "user-profile-filter": {
    "button-1": "All",
    "button-2": "Lessons",
    "button-3": "Theory",
    "button-4": "Exercises",
    "button-5": "Tests",
    "button-6": "Video",
    "button-7": "Audio",
    "button-8": "Pictures",
    "button-9": "Lexicon",
    "edu-material": "Lessons",
    "filter": "Filter",
    "show": "Show"
  },
  "user-service-card-full": {
    "all": "All",
    "at-the-rate": "At the rate",
    "by-date": "By date",
    "tabs": {
      "main": "Main",
      "other-lessons": "Other classes",
      "reviews": "Reviews",
      "schedule": "Tutor Schedule",
      "video": "Tutor video"
    },
    "titles": {
      "about": "About",
      "attach": "Attach",
      "latest-reviews": "Latest reviews",
      "other-user-services": "Other classes",
      "reviews": "Reviews",
      "schedule": "Tutor Schedule",
      "tags": "Tags",
      "video": "Tutor video",
      "school-video": "Video presentation",
      "skills": "What will you learn?",
      "requirements": "Requirements",
      "registered-class": "You booked this class on: ",
      "accept-required": "Please complete your booking for a class on {{date}}"
    }
  },
  "collection-checkout-wizard": {
    "collection-author": "Collection author:",
    "average-rating": "Average rating:",
    "lessons-done": "Lessons done:",
    "select-payment-method": "Choose a payment method"
  },
  "payment-checkout-wizard": {
    "steps": {
      "meeting": {
        "teacher": "Tutors",
        "detail": "Show more",
        "date-and-time": "Date and time",
        "payment": "Payment"
      },
      "user-service": {
        "teacher": "Tutors",
        "detail": "Classes",
        "date-and-time": "Date and time",
        "payment": "Payment"
      },
      "collection": {
        "teacher": "Tutors",
        "detail": "License type",
        "date-and-time": "Date and time",
        "payment": "Payment"
      },
      "tariff-plan": {
        "teacher": "Tutors",
        "detail": "Tariff plan",
        "date-and-time": "Date and time",
        "payment": "Payment"
      },
      "paywall": {
        "teacher": "Tutors",
        "detail": "Tariff plan",
        "date-and-time": "Date and time",
        "payment": "Payment"
      },
      "credits": {
        "teacher": "Tutors",
        "detail": "Loan selection",
        "date-and-time": "Date and time",
        "payment": "Payment"
      }
    }
  },
  "user-service-checkout-wizard": {
    "average-rating": "Rating:",
    "carried-out": "Classes:",
    "classes": "Classes",
    "cost": "Participation fee",
    "hour": "hour",
    "lesson-cost": "Lesson cost",
    "is-my-lesson-text": "You cannot enroll in your own class",
    "is-my-collection-text": "You can't buy your collection",
    "meetings": "meetings",
    "next": "Next",
    "own-service-error": "You cannot enroll in your own class",
    "pay": "Complete payment",
    "pay-failed": "Payment failed!",
    "pay-success": "The payment was successful!",
    "unsub-success": "Your subscription has been cancelled!",
    "unsub-failed": "Unsubscribe error!",
    "select": "Select list",
    "selected-activities": "Selected time:",
    "steps": {
      "date-and-time": "Date and time",
      "lesson-selection": "Lesson selection",
      "payment": "Payment",
      "teacher-selection": "Tutors"
    },
    "teacher": "Tutor:",
    "to-complete": "To complete",
    "close": "Close",
    "total": "Total",
    "try-again": "Try again",
    "subscribe": {
      "completetext": "Your current plan {{plan}}"
    },
    "unsubscribe": {
      "completetext": "You will automatically switch to the Free plan {{date}} at the end of the current subscription. The cost of the Free plan will be $0"
    },
    "subscribe-badge-message": "Sign up for regular classes with a tutor. You can cancel your subscription at any time in the section",
    "payments-payouts": "Payments and payouts",
    "purchasing-tariff": "Purchasing a tariff plan",
    "purchasing-credits": "Purchasing a credits",
    "my-subscriptions": "Subscriptions",
    "newsubscribe": "Subscribe for {{ amount }} per week",
    "newpay": "Pay {{ amount }}",
    "no-selected-time": "No classes selected",
    "classes-per-week": "{{ times }} classes per week",
    "per-week": "per week",
    "from": "From",
    "every": "Every",
    "in": "in",
    "see-all": "Schedule of classes"
  },
  "user-service-form": {
    "label1": "Name",
    "label2": "Short description",
    "label3": "Full description",
    "label4": "Lesson price",
    "placeholder1": "Course name",
    "placeholder2": "Short course description",
    "placeholder3": "Detailed course description",
    "remove": "Remove",
    "save": "Save"
  },
  "user-service-form-2": {
    "150-characters": "Minimum 150 characters",
    "category-label": "Category",
    "category-placeholder": "Not chosen",
    "create-action": "Create",
    "create-title": "Add your individual class",
    "description-placeholder": "Description",
    "discount-label": "Discount",
    "discount-placeholder": "Not chosen",
    "duration-label": "Duration of the class",
    "duration-placeholder": "Not chosen",
    "edit-title": "Private lesson",
    "error-not-chosen": "Not chosen",
    "error-required-to-fill": "Required to fill",
    "excerpt-placeholder": "Subtitle",
    "language-label": "What language is the class in?",
    "name-placeholder": "Title",
    "preview-action": "Preview",
    "price-tier-label": "Price",
    "price-tier-placeholder": "Not chosen",
    "save-action": "Save",
    "service-label": "Service information",
    "subject-label": "Subject",
    "subject-placeholder": "Not chosen",
    "text1": "Attachments",
    "text2": "(Optional)",
    "text3": "You can attach images, a presentation about your lesson, or screenshots to the lesson previous classes. This will help your potential students learn more about you."
  },
  "user-service-sidebar-widget": {
    "hourly-rate": "Hourly rate from",
    "no-free-time": "Unavailable",
    "signup": "Sign up for a lesson"
  },
  "user-widget": {
    "send-message": "Contact the tutor",
    "subscribe": "Follow"
  },
  "user-widget-20210721": {
    "price-title": "Individual classes from:",
    "roll-up": "Roll up",
    "unsubscribe": "Unfollow"
  },
  "users": {
    "cancel": "Cancel",
    "cause1": "Pornography",
    "cause2": "Spamming",
    "cause3": "Offensive behaviour",
    "cause4": "Fraud",
    "cause5": "Search clogging ad page",
    "cause6": "Clone of my page (or my old page)",
    "comments-label": "Comment to moderator",
    "comments-placeholder": "Describe the problem",
    "complain": "Complain",
    "complain-subtitle": "Please tell us the reason for your complaint:",
    "complain-title": "User complaint",
    "leave-message": "Message",
    "meta-my-students-title": "My Students",
    "meta-subscribers-title": "Subscribed on me",
    "meta-subscriptions-title": "My subscriptions",
    "meta-title": "Users",
    "page-header-title": "Users",
    "search": "Search...",
    "send": "Send",
    "subscribe": "Subscribe",
    "tabs-all": "All",
    "tabs-students": "My students",
    "tabs-subscribers": "Followers",
    "tabs-subscriptions": "Following",
    "tabs-other-users": "Other users",
    "unsubscribe": "Unfollow",
    "you-subscribed": "You are subscribed",
    "write": "Message",
    "its-you": "It's you",
    "exclude-from-class": "Exclude from class",
    "kick": "Remove"
  },
  "vacation-form": {
    "label": "Vacation mode enabled",
    "label-off": "Vacation mode disabled",
    "save": "Save",
    "saved": "Saved. The schedule will be updated after some time.",
    "text": "Activate vacation mode if you're planning to be absent or encounter unexpected circumstances. When vacation mode is enabled, your regular working hours will be temporarily hidden from your student's schedule but preserved in your settings",
    "title": "Vacation mode",
    "from": "From",
    "to": "to"
  },
  "vacation-informer": {
    "vacation-dates": "Your vacation is planned for {{begin_at}} to {{end_at}}. Your class enrollment schedule will be blocked during this time",
    "events-count": "Don't forget about {{count}} classes during this period, they can be rescheduled or cancelled.",
    "go-to-calendar": "Open calendar"
  },
  "verification-widget": {
    "actions": {
      "cancel": "Cancel verification request",
      "edit": "Edit profile",
      "fill": "Fill out profile",
      "send": "Send verification request"
    },
    "description": {
      "about": "About verification",
      "changed": "Profile updated, re-verification is required",
      "declined-1": "Profile change request was",
      "declined-2": "declined",
      "incomplete": "To begin using our platform, we kindly ask that you create a tutor profile for verification purposes",
      "resent": "Profile verification request submitted\n\n\n\n\n\n"
    },
    "edit-link": "Edit",
    "no-name": "No name",
    "profile": "Profile",
    "status": {
      "approved": "Verified",
      "changed": "Profile updated",
      "declined": "Not verified",
      "incomplete": "Profile is incomplete",
      "not_verified": "Not verified",
      "redeclined": "Not verified",
      "resent": "Awaiting moderation",
      "sent": "Awaiting moderation"
    },
    "teacher-status": "Tutor status:"
  },
  "webinar-widget": {
    "action": "Please select a date and time",
    "text": "We will hold a <span class=\"highlight\">free</span> training webinar for you, where we will demonstrate all the possibilities online platforms Onclass. To make an appointment, choose a convenient date and time 🙂",
    "title": "Onclass platform training webinar"
  },
  "header-titles": {
    "news": "News",
    "profile": "Profile",
    "classrooms": "Classrooms",
    "events": "Classes",
    "teachers-room": "Tutor's office",
    "teachers-room-plan": "Payment profile",
    "schedule": "Calendar",
    "constructor": "My content",
    "users": "Users",
    "schools": "Schools"
  },
  "schools": {
    "stub": "You don't have own schools",
    "meta-title": "Schools",
    "page-header-title": "Schools"
  },
  "school-header": {
    "title": "Schools",
    "description": "Online schools",
    "actions": {
      "create": "Create school",
      "schedule": "Schedule a class",
      "chat": "Chat",
      "join": "Join"
    },
    "status": {
      "tutor": "You are a tutor",
      "owner": "You are an owner",
      "student": "Following"
    },
    "leave": {
      "alert-leave": "Unfollow",
      "alert-cancel": "Cancel",
      "alert-title": "Do you really want to unfollow this school?",
      "alert-body": "If you cancel, you will remain enrolled in this school"
    }
  },
  "school-card": {
    "teachers": "Tutors in school:",
    "subjects": "Subjects studied at school:",
    "actions": {
      "share": "Share",
      "edit": "Edit school",
      "delete": "Delete school",
      "complain": "Complain",
      "join": "Join",
      "enter": "Open",
      "add-favorites": "Add to Favorites",
      "delete-favorites": "Delete from Favorites",
      "details": "Show more",
      "open": "Open"
    },
    "status": {
      "owner": "You are an owner",
      "tutor": "You are a tutor",
      "student": "Following"
    },
    "rating": "Rating:",
    "students": "Students:",
    "classes": "Classes:",
    "from": "from"
  },
  "school-form": {
    "header": "New school",
    "action": "Create school",
    "message": "Not selected",
    "message1": "Required field",
    "message2": "Choose language",
    "step-title": {
      "subjects": "Subjects",
      "school-info": "Information about the school",
      "language": "What languages are used for teaching?"
    }
  },
  "invite-to-school": {
    "header1": "Appointment of the tutor",
    "header2": "Appointment of the owner",
    "email": "Email addresses (comma-separated list)",
    "option1": "Invite users by email",
    "option2": "Or can use the invitation link:",
    "action": "Invite"
  },
  "school-user-card": {
    "status": "Appointed",
    "action": "Appoint"
  },
  "delete-school": {
    "header": "Delete a school?",
    "text": "The school will be deleted from the My Schools list.",
    "close": "Close",
    "delete": "Delete"
  },
  "school-main-tabs": {
    "main": "Main",
    "tutors": "Tutors",
    "classes": "Classes",
    "feed": "Feed",
    "educational-material": "Lessons",
    "add-educational-material": "Add educational material",
    "classrooms": "Classrooms",
    "feedback": "Reviews"
  },
  "school-tabs": {
    "main": "School Information",
    "calendar": "Calendar",
    "calculations": "Calculations",
    "participants": "Participants",
    "owners": "Owners",
    "set-tutors": "Set tutors",
    "classes": "School classes",
    "card": "Bank card",
    "accounts": "Payout accounts",
    "status": "School status"
  },
  "school-stats": {
    "title-stat": "School statistics",
    "students": "Students",
    "classes": "Classes",
    "rating": "Rating"
  },
  "school-main": {
    "my": "My",
    "all": "All"
  },
  "school-steps": {
    "about": "About school",
    "step1": "Step 1. Add information about a school",
    "step2": {
      "title": "Step 2. Appoint a tutor",
      "text": "You have not invited any tutors yet. You can also appoint yourself as a tutor.",
      "action": "Appoint tutor"
    },
    "step3": {
      "title": "Step 3. Add services",
      "text": "Add services available at the school: exam preparation, training, etc. Students may select the services they are interested in and sign up for a lesson",
      "action": "Add new class +"
    },
    "step4": {
      "title": "Step 4. Add credit card",
      "text": "Important! The cards linked to the school and to the tutor profile (the school founder) must be different",
      "action": "Add credit card"
    },
    "step5": "The school has not yet been published.",
    "step6": "Your school has been verified and published in the catalog",
    "action": "Publish"
  },
  "school-state-widget": {
    "state": "School status:",
    "title": "New school",
    "action": "Edit"
  },
  "school-state": {
    "published": "Published",
    "unpublished": "Unpublished"
  },
  "school-management": {
    "settings": "School management"
  },
  "school-onboarding": {
    "status": "School status:",
    "instruction": "Verified you school in 4 easy steps",
    "step1": "Add description",
    "step2": "Add tutors",
    "step3": "Add services",
    "step4": "Link card",
    "step5": "Publish school"
  },
  "school-owners": {
    "header": "Owners",
    "message": "Message"
  },
  "school-participants-widget": {
    "header": "Participants",
    "text": "Tell about your school by inviting participants via the link",
    "action": "Copy link",
    "action-done": "Copied"
  },
  "school-participants": {
    "owner": "Owners",
    "tutor": "Tutors",
    "students": "Students",
    "action-owner": "Appoint an owner",
    "action-tutor": "Appoint an tutor",
    "status": "It's you"
  },
  "school-schedule": {
    "header": "Schedule",
    "text": "The working days of hired teachers will be displayed in the calendar. You can see the schedule of all tutors or each tutor separately. Tutors point working hours on their profile.",
    "tab": "All"
  },
  "school-calculation-banner": {
    "header": "How is income distributed?",
    "item1": "The student pays 100% of the payment for the lesson",
    "item2": "Onclass gets a fixed percentage (15%)",
    "item3": "You can distribute the percentage between the school and the tutors yourself. You can set a percentage for each tutor"
  },
  "school-calculation": {
    "tutor": "Tutors",
    "tutorPer": "Tutor, %",
    "school": "School, %",
    "onclass": "Onclass, %",
    "income-distribution": "Income distribution",
    "history-calculation": "History of calculations"
  },
  "school-participants-card": {
    "message": "Message",
    "complain": "Complain",
    "delete-owner": "Delete from owners",
    "delete-tutor": "Delete from tutors",
    "add-student": "Move to students?",
    "student-message": "In case of refusal, the user will stay in the tutor role",
    "add-tutor": "Move to tutors?",
    "tutor-message": "In case of refusal, the user will stay in the owner role",
    "close": "Close",
    "apply": "Apply"
  },
  "school-placeholder": {
    "tutors": {
      "text": "You haven't hired any tutors yet. You can also designate yourself as a tutor",
      "link": "Hire a tutor"
    },
    "classes": {
      "text": "Students will be able to sign up for private lessons with you if you describe how you can be useful",
      "link": "Add class"
    },
    "feed": {
      "text": "Publications are needed to maintain activity and attract the attention of the audience",
      "link": "Create a post"
    },
    "lessons": {
      "text": "Add learning materials created in the constructor",
      "link": "Add study material"
    },
    "classrooms": {
      "text": "A classroom is a group of students with whom you regularly study and keep a history of classes",
      "link": "Create a classroom"
    },
    "feedback": {
      "text": "There are no reviews for your school yet"
    }
  },
  "error": {
    "not-chosen": "Not chosen",
    "required": "The field is required",
    "min": "Minimum {{n}} characters",
    "max": "Maximum {{n}} characters",
    "min-length": "At least {{n}} characters",
    "max-length": "No more than {{n}} characters",
    "whitespace": "Text contains of only spaces",
    "confirm": "Required for confirmation",
    "pattern": "Pattern does not match"
  },
  "user-service-form-3": {
    "main-title": "Add a new class to the catalog",
    "steps": {
      "title1": "Type of class",
      "title2": "Class settings",
      "title3": "Information about your class",
      "title4": "Tutors"
    },
    "group-label": "Group",
    "single-label": "Individual",
    "upload-media-text": "Drag file or click select",
    "types": {
      "label1": "One class",
      "text1": "After you add your working hours in the calendar schedule, it will automatically be listed in the catalog of classes",
      "type-text1": "A single-session class focusing on one specific topic, guaranteed for each student. This format is also ideal for trial lessons and serves as an effective way to introduce students to your other classes and courses",
      "label2": "Regular classes",
      "text2": "After you add your working hours in the calendar schedule, it will automatically be listed in the catalog of classes",
      "type-text2": "These classes are held according to your work schedule and are part of a weekly subscription model. Students can enroll by selecting an available time slot and purchasing this subscription. Payments will be automatically deducted from the student's card every 7 days from the date of purchase. After each weekly class is conducted, the payment is credited to your bank card.",
      "label3": "Mini - course",
      "text3": "This type of class requires you to manually set its schedule in the calendar at a specific time you determine",
      "type-text3": "This mini-course consists of a series of up to 5 classes, conducted regularly multiple times a week. All classes in this mini-course are centered around a single common topic, which students will explore throughout the duration of the course",
      "label4": "Course",
      "text4": "This type of class requires you to manually set its schedule in the calendar at a specific time you determine",
      "type-text4": "This course comprises a series of 5 or more classes, scheduled regularly several times a week. Each class within this course is interconnected by a common theme, providing students with a comprehensive study of that particular topic throughout the course"
    },
    "titles": {
      "title1": "Video presentation",
      "title2": "Requirements",
      "title3": "What students will learn",
      "title4": "Attachments",
      "title5": "What language is the class taught in?"
    },
    "fields": {
      "label1": "Schedule:",
      "label2": "Number of classes:",
      "label3": "Duration of each class:",
      "label4": "Cost of participation:",
      "label5": "Discount:",
      "label6": "Subject",
      "label7": "Category",
      "label8": "Number of participants:",
      "label9": "Age:",
      "label10": "Level:",
      "label11": "Optional",
      "placeholder1": "Not selected",
      "placeholder2": "Title",
      "placeholder3": "Description",
      "placeholder4": "Requirements",
      "placeholder5": "Skills",
      "placeholder6": "Without discount"
    },
    "bullet": {
      "title": "Tips on how to make a good video",
      "item1": "Record videos in horizontal orientation",
      "item2": "The duration of the video should be at least 30 seconds and no more than 2 minutes",
      "item3": "Use a neutral background",
      "item4": "Your face and eyes should be fully visible",
      "item5": "Don't use presentations"
    },
    "attached-text": "You have the option to enhance your classes appeal by attaching images, a presentation, or screenshots from previous classes. Doing so provides potential students with a better understanding of your teaching style and what they can expect from your classes",
    "actions": {
      "save": "Save",
      "create": "Create",
      "previews": "Preview"
    },
    "errors": {
      "error1": "Mandatory choice",
      "error2": "Choose the right option",
      "error3": "Required field",
      "error4": "Please enter at least 100 characters"
    },
    "weekdays-empty": {
      "text": "According of your",
      "link": "work schedule"
    },
    "teacher-selector": {
      "placeholder": "Choose the tutor",
      "rating": "Rating tutor:",
      "no-teacher": "You have not yet assigned a tutor for the selected subject"
    },
    "video-placeholder": "The tutor has not added a video presentation",
    "time-duration": {
      "minutes": "minutes"
    },
    "subject-popover": {
      "text": "List the subjects you teach.",
      "link": "Go to the questionnaire"
    },
    "show-more": "Provide more detailed information about your class, this will help attract more students (optional)",
    "period": {
      "per-class": "per class",
      "per-week": "per week",
      "for-class": "for 1 class"
    }
  },
  "days-of-week": {
    "su": "Sun",
    "mo": "Mon",
    "tu": "Tue",
    "we": "Wed",
    "th": "Thu",
    "fr": "Fri",
    "sa": "Sat"
  },
  "event-calendar": {
    "context-menu": {
      "schedule-event": "Schedule an event",
      "assign-class": "Schedule a class",
      "available-for-booking": "Available for booking ",
      "not-available-for-booking": "Not available for booking"
    },
    "status": {
      "finished": "Completed classes",
      "scheduled": "Scheduled classes",
      "accept-required": "Unconfirmed classes",
      "started": "Current classes",
      "upcoming": "Upcoming classes",
      "failed": "Canceled classes",
      "past-date": "Past date",
      "available": "Working hours",
      "day-off": "Not available for booking",
      "vacation-days": "Vacation, not available"
    },
    "multi-selector": {
      "hint-text": "Classes that can be held on {{day}} are available for selection.",
      "intersection-text": "One or more classes have been selected that, when published, will overlap with classes that students are already enrolled in. Please select a different cell to publish to.",
      "overlap-text": "{{date}} overlap with lesson {{title}}",
      "add": "Add to calendar",
      "remove": "Remove from calendar",
      "change": "Change calendar",
      "other": "Other classes",
      "placeholder-text": "You have not created any services yet",
      "placeholder-link": "Create a service"
    },
    "empty-text": "Preparing your <br> schedule",
    "event-popover": {
      "cancel-event": "Cancel event"
    },
    "types": {
      "single": "One class",
      "regular": "Regular",
      "mini": "Mini course",
      "course": "Course",
      "individual": "Individual",
      "group": "Group",
      "webinar": "Webinar",
      "stream": "Stream",
      "talk": "Talk"
    },
    "ongoing": "Ongoing",
    "participants-limit": {
      "private": "1 on 1",
      "group": "Group"
    }
  },
  "first-contact": {
    "widget": {
      "title": "Welcome",
      "text": "Presenting an overview of Onclass' main features",
      "action-label": "Start",
      "action-close": "Close"
    },
    "first-contact-selector": {
      "role": {
        "student": "Students",
        "tutor": "Tutors",
        "methodist": "Methodists",
        "school": "Schools"
      },
      "titles": {
        "student": "A convenient platform for online learning!",
        "tutor": "New opportunities for online learning!",
        "methodist": "Monetize your knowledge <br> on the Onclass platform!",
        "school": "Open your own online school <br> on the Onclass platform"
      },
      "texts": {
        "student": "A convenient platform for online learning! Onclass is a new and exciting opportunity for online education in any subject. You don't have to buy textbooks or write down exercises in a notebook – the teachers have prepared all the materials for you!",
        "tutor": "Onclass is a convenient online platform for conducting your classes in any disciplines. Study individually or with a group of students from anywhere in the world on a comfortable schedule.",
        "methodist": "Onclass is a convenient platform for creating your author's lessons, collections and courses. Publish materials, set a price and receive payments to your card.",
        "school": "Onclass is a convenient and simple platform for launching your online school."
      },
      "list": {
        "student": {
          "item1": "Catalog of free lessons and exercises",
          "item2": "Online classes with an interactive whiteboard.\n",
          "item3": "Tutors from all over the world in different subjects",
          "item4": "3-day refund guarantee"
        },
        "tutor": {
          "item1": "Lesson Constructor",
          "item2": "Virtual classroom with interactive whiteboard",
          "item3": "3-day refund guarantee"
        },
        "methodist": {
          "item1": "Create tutorials using the built-in designer",
          "item2": "Combine the created materials into collections",
          "item3": "Sell ready-made lesson plans on the platform",
          "item4": "Get money to your card on a secure transaction"
        },
        "school": {
          "item1": "Create lessons using the built-in constructor",
          "item2": "Combine the created materials into collections and courses",
          "item3": "Add employees and configure access control",
          "item4": "Get money to your card on a secure transaction"
        }
      }
    },
    "first-contact-tools": {
      "title": "How are online classes going?",
      "action": "Create a lesson",
      "texts": {
        "student": "Studying according to ready-made programs, either individually or in groups with other students, is a great option. Set a learning goal and achieve it with the help of tutors you like",
        "tutor": "Invite one or a group of students to the classroom for an interactive online lesson. Use video and audio communication, chat for <br> communication and a board with the ability to draw.",
        "methodist": "Create your first tutorial using the built-in <br> constructor right on the platform. Add the required number of blocks or slides and select the type of task or content that suits your goals.",
        "school": "Create your own online school using the capabilities of <br> Onclass. We will take a fixed percentage of 15%, and the rest you can divide between the school and the tutors yourself."
      }
    },
    "first-contact-additional-tools": {
      "titles": {
        "tutor": {
          "title1": "Automatic <br> task verification",
          "title2": "Home <br> and independent <br> work",
          "title3": "History of classes <br> in the classroom"
        },
        "student": {
          "title1": "Automatic <br> task verification",
          "title2": "Home <br> and independent <br> work",
          "title3": "Monitoring <br> academic performance"
        },
        "methodist": {
          "title1": "Automatic <br> task verification",
          "title2": "Home <br> and independent <br> work",
          "title3": "Monitoring <br> academic performance"
        },
        "school": {
          "title1": "Automatic <br> task verification",
          "title2": "Home <br> and independent <br> work",
          "title3": "Monitoring <br> academic performance"
        }
      },
      "text": "Take a few free lessons from different tutors to decide on a further training plan",
      "text2": "Other users will be able to use your <br> content, and you will receive money directly to your card.",
      "action1": "Create a lesson",
      "action2": "Create a school",
      "action3": "Create a lesson"
    },
    "first-contact-free-lesson": {
      "title": "You can familiarize yourself with the features of the platform by attending a class with a tutor",
      "text": "In the Lessons section you will find a lot of <br> free educational content",
      "list": {
        "title1": "Popular lessons",
        "text1": "Other users have already completed some classes on the platform. Trust their feedback and ratings and start learning!",
        "title2": "What to see",
        "text2": "The platform contains interesting video lessons from different tutors. Choose a topic of interest and enjoy watching.",
        "title3": "What to listen to",
        "text3": "Educational podcasts or stories in a foreign language can be found in the audio section.",
        "title4": "Exercise of the day",
        "text4": "The platform system will always show you interesting exercises according to your preferences."
      }
    },
    "choose-tutor": {
      "title": "Or you can choose a tutor you like and <br> sign up for a class with him yourself",
      "list": {
        "title1": "Additional materials",
        "text1": "On the Onclass platform, you can find many collections and collections of exercises on a specific topic. Some materials may be paid, as they are an addition to the basic materials of tutors.",
        "title2": "Verified online schools",
        "text2": "You can choose a tutor yourself or apply to an online school, where they will pick up a convenient schedule, a training program and the best tutor who will help you achieve the desired result.",
        "title3": "Safety and reliability",
        "text3": "Pay off with the tutor, as it is convenient for you. Pay for classes using the secure transaction system or pay off personally with the tutor if you have already built a trusting relationship between you.",
        "title4": "Comfortable conditions",
        "text4": "Study where and when it is convenient for you. You choose how often and for how long you will be engaged"
      }
    },
    "meeting-types": {
      "titles": {
        "student": "Choose a convenient training format",
        "school": "Use all kinds of content"
      },
      "tutor-list": {
        "title1": "One lesson",
        "text1": "A one-time lesson on a specific day and time.",
        "title2": "Regular <br> classes",
        "text2": "Repetitive classes on certain days.",
        "title3": "Course",
        "text3": "A series of classes designed for a long time (from 5 days)",
        "title4": "Mini-course",
        "text4": "A series of classes designed for a short time (from 2 to 5 days)",
        "title5": "Webinar",
        "text5": "The format of broadcasting to a large audience with the possibility of feedback",
        "title6": "Stream",
        "text6": "The format of broadcasting to a large audience without the possibility of feedback"
      },
      "student-list": {
        "title1": "Group <br> classes",
        "text1": "Study in a group of like-minded people so as not to lose motivation and achieve great results.",
        "title2": "One-time <br> lesson",
        "text2": "Choose a one-time lesson if you have any questions about a certain topic or rules.",
        "title3": "Course",
        "text3": "Take a full course to delve into the study of the subject of interest. The training lasts from 5 days",
        "title4": "Individual <br> classes",
        "text4": "Identify individual learning goals and pursue them at a pace that is convenient for you.",
        "title5": "Regular <br> classes",
        "text5": "Arrange with the tutor for certain days and times of classes so as not to slow down the pace of learning.",
        "title6": "Mini-course",
        "text6": "Learn the main points of the desired discipline in just a few lessons. The training lasts from 2 to 5 days"
      },
      "methodist-list": {
        "title1": "Link <br> bank card",
        "text1": "Link your bank card in your personal account to accept payments from users from all over the world.",
        "title2": "Guarantee <br> of a safe transaction",
        "text2": "Use the secure transaction system on the Onclass platform to not worry about the receipt of money.",
        "title3": "Marketing <br> promotion",
        "text3": "Your materials will be seen by thousands of users on our platform. You will definitely find your buyer."
      },
      "school-list": {
        "title1": "Lessons",
        "text1": "Create an unlimited number of lessons using the built-in constructor right on the platform.",
        "title2": "Paid collections",
        "text2": "Combine lessons into collections and sell them on the platform. Get money directly to your card using the secure transaction system.",
        "title3": "Webinars",
        "text3": "Declare yourself and your school!Conduct useful broadcasts for platform users.",
        "title4": "Interactive <br> tasks",
        "text4": "Tests, questions with the choice of the correct answer, comparison of options and other opportunities to make lessons interesting for students.",
        "title5": "Homework <br> tasks",
        "text5": "Set the correct values in tests and exercises when creating them, so as not to waste time checking lessons.",
        "title6": "Courses",
        "text6": "Create a series of classes on a specific topic for more detailed study. The duration of the course is from 5 days."
      }
    },
    "steps": {
      "title": "6 simple steps to get started",
      "text": "Done! You will receive a reminder about the lesson so that <br> you will definitely not miss it!",
      "steps-list": {
        "text1": "Go to the Classes section at the top of the screen",
        "text2": "Choose the discipline you are interested in",
        "text3": "Find a suitable activity and click the “Sign up” button",
        "text4": "Choose a convenient date and time of the lesson",
        "text5": "Pay for the lesson with a bank card to book it",
        "text6": "Install the Onclass app on your smartphone to see notifications about new classes and communicate with tutors."
      }
    },
    "capabilities": {
      "titles": {
        "tutor": "Expand your capabilities <br> with Onclass!",
        "methodist": "Why is it easy and convenient to work with us?",
        "school": "Expand your capabilities <br> with Onclass!"
      },
      "tutor-list": {
        "title1": "The choice of any discipline",
        "text1": "You can teach any subject in which you are an expert.",
        "title2": "Convenient work schedule",
        "text2": "Independently set your own schedule and duration of lessons. Combine it with your main job or teach full time.",
        "title3": "Quick search for students",
        "text3": "Add interesting and useful content to the platform so that new students choose you. Working on Onсlass, you get access to an ever-growing database of students.",
        "title4": "Payments after each lesson",
        "text4": "Link your bank card in your personal account to accept payments from students from all over the world.",
        "title5": "Marketing and promotion",
        "text5": "Your lessons and courses will be placed in the catalog on our platform, where thousands of users visit every day. Therefore, you don't have to worry about additional promotion.",
        "title6": "3-day refund guarantee",
        "text6": "Use the secure transaction system on the Onclass platform if you are just starting a class with a new student.",
        "title7": "Work from anywhere in the world",
        "text7": "Conduct classes online at a convenient time from any place where there is Internet.",
        "title8": "Develop your personal brand",
        "text8": "Declare yourself on the platform by creating lessons and courses based on the author's methods.",
        "title9": "Stay in touch",
        "text9": "Install the Onсlass app on your smartphone to see notifications about new classes and communicate with students.",
        "title10": "Open your school",
        "text10": "Create your own online school on the Onсlass platform. Hire employees and increase your earnings."
      },
      "methodist-list": {
        "title1": "The choice of any discipline",
        "text1": "You can create materials on any subjects in which you are an expert.",
        "title2": "Work from anywhere in the world",
        "text2": "Download materials and conduct useful broadcasts at a convenient time for you from any place where there is Internet.",
        "title3": "Quick search for buyers",
        "text3": "Add involving and useful content to the platform so that new students choose you. Working on Onclass, you get access to an ever-growing database of students.",
        "title4": "Payments after each sale",
        "text4": "Link your bank card in your personal account to accept payments from users from all over the world.",
        "title5": "Marketing and promotion",
        "text5": "Your materials will be placed in the catalog on our platform, where thousands of users visit every day. Therefore, you don't have to worry about additional promotion.",
        "title6": "3-day refund guarantee",
        "text6": "Use the secure transaction system on the Onclass platform when selling training materials.",
        "title7": "Stay in touch",
        "text7": "Install the Onclass app on your smartphone to see notifications about new classes and communicate with other users.",
        "title8": "Develop your personal brand",
        "text8": "Declare yourself on the platform by creating lessons and courses based on the author's methods. Expand your audience and open an online school."
      },
      "school-list": {
        "title1": "Unlimited choice",
        "text1": "Your school can work in several directions at once. The more tutors there are in various disciplines, the more students there are in the classroom.",
        "title2": "Convenient work schedule",
        "text2": "Set a convenient schedule and duration of lessons. Your employees will be able to combine with their main job or work with you full time.",
        "title3": "Distribution of roles",
        "text3": "You can hire employees who will conduct classes at your school, and you will act as a manager. If you wish, you can also be a tutor.",
        "title4": "Develop your personal brand",
        "text4": "Declare yourself on the platform by creating lessons and courses based on the author's methods. Expand your audience and attract the most successful tutors.",
        "title5": "Marketing and promotion",
        "text5": "Your school will be placed in the catalog on our platform, where thousands of users visit every day. Therefore, you don't have to worry about additional promotion.",
        "title6": "3-day refund guarantee",
        "text6": "Use the secure transaction system on the Onclass platform to settle accounts with your employees and receive payment from students.",
        "title7": "Work from anywhere in the world",
        "text7": "The Onсlass platform allows you to unite students and tutors from all over the world.",
        "title8": "Stay in touch",
        "text8": "Install the OnClass app on your smartphone to see notifications about new classes and communicate with students and school staff."
      }
    },
    "constructor": {
      "titles": {
        "tutor": "Lesson Constructor",
        "student": "Choose different types of content",
        "methodist": "Convenient constructor",
        "school": "Just get started"
      },
      "subtitles": {
        "tutor": "Add the required number of blocks or slides and select <br> the type of task or content that suits your goals.",
        "methodist": "Add the required number of blocks or slides and select <br> the type of task or content that suits your goals."
      },
      "list-tutors": {
        "title1": "Theory",
        "text1": "Add theoretical information to the lessons to help students understand new rules.",
        "title2": "Exercises",
        "text2": "Set one or more correct answers in the created exercises. Add the ability to fill in the gaps to make learning even more interesting.",
        "title3": "Audio, photo and video",
        "text3": "Use the user-friendly interface for uploading media files to the platform. All the necessary materials will always be at hand.",
        "title4": "Learning words",
        "text4": "Learning new words using flashcards is simple and effective! Create flashcards for your students to achieve results faster."
      },
      "list-student": {
        "title1": "Theory",
        "text1": "Carefully study the theoretical part before performing the exercises. It is especially important to delve into the theory if you are starting to study a new topic.",
        "title2": "Exercises",
        "text2": "The platform contains a variety of types of exercises: choosing one or more correct answers, the ability to fill in gaps, comparing options and much more.",
        "title3": "Audio, photo and video",
        "text3": "To practice the learned rules, you can perform exercises in audio or video format. Train the correct pronunciation, vocabulary, the ability to perceive speech by ear.",
        "title4": "Learning words",
        "text4": "Learning new words using flashcards is simple and effective! Choose this type of content to achieve faster results in learning foreign words."
      },
      "list-school": {
        "title1": "Create online products",
        "text1": "Create lessons, paid collections and online courses with the help of a convenient constructor on the platform",
        "title2": "Register the name of the school",
        "text2": "Go through the online school registration stage on the platform to use all the features of OnClass",
        "title3": "Hire employees",
        "text3": "Create a cool team to achieve new heights. Team up with teachers in different subjects and reach a new audience",
        "title4": "Set a convenient work schedule",
        "text4": "The calendar will display the working days of the hired tutors. You can view the schedule of all at once or each separately. Teachers indicate working hours in their profile.",
        "title5": "Distributes profits among employees",
        "text5": "You can distribute the percentage between the school and the teachers yourself. Each teacher can set their own percentage.",
        "title6": "Control all processes",
        "text6": "There is a lesson recording function on the platform, so you can always monitor how your employees work."
      }
    },
    "user-services": {
      "title": "Classes",
      "text": "Classes are online lessons with your students that take place on our interactive platform. Onclass has six types of classes designed for specific purposes."
    },
    "processes": {
      "title": "Earn (more) with Onclass",
      "subtitle": "We present many opportunities for additional <br> earnings to teachers",
      "methodist-title": "Payouts are immediately credited to your card after each sale!",
      "methodist-action": "Link a card",
      "list-tutor": {
        "title1": "Post paid materials <br> on the platform",
        "text1": "Create paid collections and collections of lessons on a specific topic. Display materials on the platform for a fixed price. Other users will be able to purchase ready-made manuals for self-use. You will receive payments for the sale immediately to your card.",
        "link1": "Create a collection",
        "title2": "Conduct various types of classes",
        "text2": "Create private or public classes, courses, webinars, streams in your discipline. Set your price for them and attract an interested audience. The payment for classes takes place directly with the students or through a secure transaction, the guarantor of which is Onclass.",
        "link2": "Create an individual lesson",
        "title3": "Create your own online school",
        "text3": "Tired of being an ordinary teacher? Become the director of your own online school on Onclass and hire your colleagues in it. All income from teaching at your school will be distributed according to the percentage you set, and payments will be made automatically to the vm on the card.",
        "link3": "Create your own school"
      },
      "list-methodist": {
        "title1": "Post paid materials <br> on the platform",
        "text1": "Create paid collections and collections of lessons on a specific topic. Display materials on the platform for a fixed price. Other users will be able to purchase ready-made manuals for self-use. You will receive payments for the sale immediately to your card.",
        "link1": "Create a collection",
        "title2": "Conduct useful broadcasts and marathons",
        "text2": "Conduct webinars, streams and marathons in your chosen discipline. Share useful knowledge and attract an interested audience. Communicating with users will help attract new buyers of your content.",
        "link2": "Create a broadcast",
        "title3": "Create your own online school",
        "text3": "Tired of being an ordinary methodist? Become the director of your own online school on Onclass and hire your colleagues in it. All income from teaching at your school will be distributed according to the percentage you set, and payments will be made automatically to your card.",
        "link3": "Create your own school"
      }
    },
    "school-advantages": {
      "list": {
        "title1": "Fair and understandable conditions",
        "text1": "The student pays 100% of the payment for the lesson. The platform will take a fixed commission of 15% of. You will be able to independently set the amount of payments to your employees for their work.",
        "title2": "Safety and reliability",
        "text2": "Use the secure transaction system on the On class platform to settle accounts with your employees. Receive payments for the work of the school directly to your card.",
        "title3": "Marketing and promotion",
        "text3": "With us you will be able to master new heights in the field of education. Every day, a team of IT specialists attracts traffic to our platform so that new students see your school."
      }
    },
    "faq": {
      "list": {
        "question1": "What does the verification process on the platform give?",
        "answer1": "After filling out the profile and passing verification, you will be able to: 1. conduct individual classes with students 2. your profile will be published in the tutor catalog 3. you will be able to receive free support and training on using the platform 4. calculations via Onclass.com with a secure transaction",
        "question2": "What functionality is there on the platform?",
        "answer2": "The Onclass platform opens up new opportunities for online learning. 1. Virtual classroom, for conducting interactive online classes. Video and audio communication, chat for communication and a board with the ability to draw.\n2. A convenient constructor for creating lessons, exercises and other educational content. 3.The tutor's personal page, which will allow you to promote your online classes and other services. 4.Catalog of online schools created by you or other teachers. Online school is a great opportunity to realize your professional ambitions using the Onclass platform.",
        "question3": "How to build a class schedule?",
        "answer3": "Study at a time convenient for you, combine with your main job or teach full time. When you mark your working days in the calendar, students can sign up for classes with you on their own.",
        "question4": "How to prepare for the lesson?",
        "answer4": "Arrange with the students about a convenient class time.To start the lesson, open a virtual classroom and prepare for the lesson. Add the required number of boards, and then select the materials for the lesson from those available on the platform or upload new materials yourself. Invite a student to the class using the link by sending it to any convenient messenger or chat on the Onclass platform. Or through the Invite Student button.",
        "question5": "I have no teaching experience at all. Can I teach courses with you?",
        "answer5": "It is important to us that teachers on the Onclass platform have sufficient experience in working with students, are able to explain the material in an accessible way and have good oratorical qualities. But we would also like our platform to be useful for young professionals who have interesting completed projects and a great desire to teach. Therefore, the Onclass platform unites everyone who is ready to share their knowledge with other people. \n\nThe Onclass platform opens up new opportunities for teachers with any level of work experience! If you have sufficient experience in working with students, are able to explain the material in an accessible way and have good oratorical qualities, then you will certainly be able to evaluate all the functionality of the platform. For those who are just starting their way in teaching, the Onclass platform will be an excellent platform for starting a career.",
        "question6": "What subjects can be taught on the platform?",
        "answer6": "Now you can already find many lessons in English and other foreign languages, such as Italian, French, etc. In addition to foreign languages, the platform provides lessons on subjects of the school or university program. You can add materials on any desired subject and start learning with your students, as well as find new ones using the Onclass platform.",
        "question7": "How to find new students?",
        "answer7": "Create useful content and share it with other users on the On class platform. Collections of lessons, interactive tasks and additional materials are what will definitely attract new students. By talking about your services, you can attract more students and, as a result, earn more. Don't forget to pass verification to be displayed in the tutor catalog.",
        "question8": "How do I invite students to a class?",
        "answer8": "Invite a student to the class using the link by sending it to any convenient messenger or chat on the Onclass platform. Or through the Invite Student button.",
        "question9": "What materials can I add for classes with students?",
        "answer9": "You can download all the materials necessary for the lesson. Add podcasts or audio lessons.Exercises in which you need to fill in the gaps, enter the text or choose the correct answer. You can print text, make notes with a marker, insert graphs and geometric shapes, or add the necessary images.",
        "question10": "How do I add a lesson?",
        "answer10": "Go to the My Content section to add the necessary materials to the platform.  Add the required number of blocks or slides and select the type of task or content that suits your goals. Enter a detailed and understandable description of the materials so as not to lose the necessary exercise. Go to Settings and set all the necessary parameters. Use the Preview function to check the lesson before publishing. If everything is displayed correctly, then publish the lesson. Do not forget that the lesson will be moderated before publication, so it may not be displayed in the profile immediately.",
        "question11": "How do I check my homework?",
        "answer11": "When adding test tasks or test exercises to the platform, be sure to specify the correct answer options so that the process of checking the completion of homework occurs automatically on the platform.",
        "question12": "Who can view my materials on the platform?",
        "answer12": "When creating a lesson on the platform, you can choose privacy settings for each material. Choose Shared Access if you want to share your lessons with other users on the platform and Private access for personal use only.",
        "question13": "What guarantees does the platform provide?",
        "answer13": "Accept payment for classes on the platform on a secure transaction. A secure transaction is a type of settlement in which the Onclass platform acts as a guarantor of the transaction. You will definitely get the money you earned right after the lesson. When choosing this type, the student pays for the lesson in advance. Within 3 days after the lesson, Onclass pays for the tutor's training, minus the commission. The amount of the commission depends on the tariff. Add a bank card to accept payments from students.",
        "question14": "What guarantees does the platform provide?",
        "answer14": "We guarantee a refund if the service was rendered poorly. After the lesson, you can share feedback about the lesson by filling out a small questionnaire. If the tutor behaved unprofessionally, then be sure to let us know about it and our support service will sort out the situation.",
        "question15": "How to find tutors using the Onclass platform?",
        "answer15": "Just 4 simple steps will help you find the right tutor: 1. Fill out your profile. Tell us about yourself so that tutors can learn more about you. 2. Select the necessary filters in the tutors directory. You can choose the necessary subjects, as well as choose the language of the content and the cost of the lesson. 3. Write to the tutor or make an appointment with him for a lesson at a convenient time for you. 4. Pay off the tutor as it suits you. Pay for classes using the secure transaction system or pay off personally with the tutor if you have already built a trusting relationship between you.",
        "question16": "What items are already available on the platform?",
        "answer16": "You can find many lessons in English and other foreign languages, such as Italian, French, etc. In addition to learning foreign languages, you can find lessons in the subjects of the school or university program.",
        "question17": "How to choose a tutor?",
        "answer17": "Go to the 'Tutors' section to see the list of tutors on the Onclass platform. Tutors in the list will be displayed by popularity, according to their rating by the number and quality of classes held. To find a tutor for a specific subject, click the Select Subject button in the upper-left corner.",
        "question18": "Where can I find homework?",
        "answer18": "After the lesson, the teacher can ask homework to his students. Do your homework immediately on the platform at a convenient time for you. Homework is checked automatically.",
        "question19": "Can I go back to the material I have already covered?",
        "answer19": "Yes, all the completed materials are stored on the platform. You can always review past classes to repeat the lessons you have completed.  They can be found in the Events section."
      }
    },
    "school-tools": {
      "title": "All tools on one platform",
      "text": "Create your own school <br> and make a profit",
      "action": "Create a school",
      "list": {
        "title1": "Chat with comments",
        "text1": "Communicate with students and other users on the platform in a special chat.",
        "title2": "Mobile application",
        "text2": "Install the Onсlass app on your smartphone to see notifications about new classes and communicate with your school staff.",
        "title3": "Analysis and statistics",
        "text3": "You can always track the progress of your school or a particular employee in your personal account.",
        "title4": "Accepting payments",
        "text4": "The profit from the work of your school goes directly to your card. Use the secure transaction system on the Onclass platform when making settlements with employees."
      }
    }
  },
  "payments-history": {
    "success": "Success",
    "pending": "Pending",
    "canceled": "Canceled",
    "yesterday": "Yesterday",
    "today": "Today",
    "written-off": "Written off",
    "received": "Received",
    "per-lesson": "per lesson",
    "per-class": "per class",
    "per-tariff-plan": "per tariff plan",
    "per-collection": "per collection",
    "participants": "Number of participants:",
    "total": "Total",
    "no-data-available": "No data available"
  },
  "my-classes/dashboard.page-header-title": "Dashboard",
  "my-classes/events.page-header-title": "Classes",
  "my-classes/tutors.page-header-title": "Tutors",
  "my-classes/classrooms.page-header-title": "Classrooms",
  "my-classes/homework.page-header-title": "Homework",
  "my-classes/students.page-header-title": "Students",
  "my-classes/schools.page-header-title": "Schools",
  "my-classes/dashboard.meta-title": "Dashboard",
  "my-classes/events.meta-title": "Classes",
  "my-classes/tutors.meta-title": "Tutors",
  "my-classes/classrooms.meta-title": "Classrooms",
  "my-classes/homework.meta-title": "Homework",
  "my-classes/students.meta-title": "Students",
  "my-classes/schools.meta-title": "Schools",
  "my-library/activity.page-header-title": "Activity",
  "my-library/services.page-header-title": "Classes",
  "my-library/courses.page-header-title": "Courses",
  "my-library/collections.page-header-title": "Collections",
  "my-library/collections.page-header-title.test": "Collections",
  "my-library/lessons.page-header-title": "Lessons",
  "my-library/activity.meta-title": "Activity",
  "my-library/lessons.meta-title": "Lessons",
  "my-library/services.meta-title": "Classes",
  "my-library/courses.meta-title": "Courses",
  "my-library/collections.meta-title": "Collections",
  "my-feed/feed.page-header-title": "Feed",
  "my-feed/publications.page-header-title": "Publications",
  "my-feed/lessons.page-header-title": "Lessons",
  "my-feed/collections.page-header-title": "Collections",
  "my-feed/classes.page-header-title": "Classes",
  "my-feed/reviews.page-header-title": "Reviews",
  "my-feed/comments.page-header-title": "Comments",
  "schedule/calendar.meta-title": "Calendar",
  "schedule/working-hours.meta-title": "Working hours",
  "schedule/vacation.meta-title": "Vacation",
  "my-vocabulary/main.meta-title": "My Vocabulary",
  "my-vocabulary/lists.meta-title": "Folders",
  "my-vocabulary/students.meta-title": "Students",
  "publish-status": {
    "published": "Publiс",
    "private": "Private",
    "draft": "Drafts",
    "purchased": "Purchased",
    "moderation": "Pending",
    "archive": "Archive"
  },
  "my-library": {
    "stub": {
      "stub1_all_tutor": "There are no collections. Create <br> your first collection.",
      "stub1_all_student": "There are no collections. <br> You can purchase any collection in <br> <a href\"/collections\" class=\"stub-link\">the catalog.</a>",
      "stub1_all_guest": "<span class=\"link\">Log in</span> to see <br> the collections you have <br> purchased here.",
      "stub1_published_tutor": "Collections yet to be published",
      "stub1_published_student": "You haven’t collections.",
      "stub1_published_guest": "Your collections of lessons will be displayed here",
      "stub1_private_tutor": "No private <br> collections. Create <br> your first collection.",
      "stub1_private_student": "No private <br> collections. Create <br> your first collection.",
      "stub1_private_guest": "<span class=\"link\">Log in</span> to see <br> the collections you have <br> purchased here.",
      "stub1_draft_tutor": "There are no collections. Create <br> your first collection.",
      "stub1_draft_student": "There are no collections. Create <br> your first collection.",
      "stub1_draft_guest": "<span class=\"link\">Log in</span> to see <br> the collections you have <br> purchased here.",
      "stub1_moderation_tutor": "There are no collections <br> on moderation.",
      "stub1_moderation_student": "There are no collections <br> on moderation.",
      "stub1_moderation_guest": "<span class=\"link\">Log in</span> to see <br> the collections you have <br> purchased here.",
      "stub1_purchased_tutor": "There are no purchased collections.",
      "stub1_purchased_student": "There are no purchased collections.",
      "stub1_purchased_guest": "<span class=\"link\">Log in</span> to see <br> the collections you have <br> purchased here.",
      "stub1_archive_tutor": "There are no archival collections.",
      "stub1_archive_student": "There are no archival collections.",
      "stub1_archive_guest": "<span class=\"link\">Log in</span> to see <br> the collections you have <br> purchased here.",
      "stub2": "There are no lessons. Create your <br> first lesson.",
      "stub3": "No course. Create your <br> first course.",
      "stub4": "Classes yet to be published. <br>Create a new class."
    },
    "no-published-lesson": "Lessons yet to be published",
    "no-private-lesson": "No private lessons",
    "no-draft-lesson": "There are no lessons. Create your <br> first lesson.",
    "no-moderation-lesson": "No lessons awaiting moderation",
    "no-archive-lesson": "No archived lessons",
    "need-authorization": "Your lessons will be displayed here",
    "meta-title": "My library",
    "page-header-title": "My Library",
    "actions": {
      "create-lesson": "Create a lesson",
      "create-service": "Create a class",
      "create-collection": "Create a collection",
      "schedule-class": "Schedule a class",
      "create-classroom": "Create a classroom",
      "new-homework": "New homework"
    },
    "cards-actions": {
      "view": "Preview",
      "unpublish": "Unpublish",
      "publish": "Publish",
      "edit": "Edit",
      "archive": "Archive",
      "restore": "Restore",
      "show-stats": "Show statistics",
      "hide-stats": "Hide statistics"
    },
    "tabs": {
      "last-viewed": "Last Viewed",
      "in-progress": "In Progress",
      "finished": "Finished"
    },
    "alerts": {
      "actions": {
        "unpublish": "Unpublish",
        "dont-unpublish": "Cancel",
        "send-to-archive": "Send to archive",
        "dont-send": "Don't send",
        "restore": "Restore",
        "dont-restore": "Don't restore"
      },
      "unpublish": {
        "title": "Unpublish lesson?",
        "text": "Unpublished lesson will be moved to Drafts."
      },
      "archive-public": {
        "title": "Send public lesson to Archive?",
        "text": "The lesson will be sent to Archive and will no more be available during classes. Public lessons require re-moderation after being restored from the archive."
      },
      "archive-private": {
        "title": "Send private lesson to Archive?",
        "text": "Private lesson will be moved to Archive. Archived private lessons can be restored from the Archive without moderation."
      },
      "archive-draft": {
        "title": "Send draft lesson to Archive?",
        "text": "Draft lesson will be moved to Archive."
      },
      "restore-archive": {
        "title": "Restore lesson from Archive?",
        "text": "Lesson will be moved to Drafts, where you can edit and publish it as public or private."
      }
    }
  },
  "my-classes": {
    "stub": {
      "stub1": "There are no classes here yet. <br> <span class=\"stub-link\">Schedule</span> a new class.",
      "stub1_1": "Your future classes with tutors will be displayed here",
      "stub2": "Here you'll find the tutors with whom you're scheduled for classes",
      "stub2_1": "There are no teachers with whom you <br> studied.",
      "stub3": "You don't have any students yet. <br> Start running classes to see a list of your students",
      "stub4": "No homework. Give first <br> homework to your students",
      "stub4_1": "Here, you will find the homework assignments provided by your tutors",
      "stub5": "There are no classrooms. Create <br> your first classroom for <br> student activities",
      "stub5_1": "Your classrooms in which you are a member and where your online classes are held will be displayed here",
      "stub6": "There are no schools. Create <br> the first school or <br> join the school",
      "stub6_1": "Here you'll find the school with whom you're scheduled for classes\n\n"
    },
    "no-events": {
      "title": "Your classes",
      "text": "The schedule of your classes with tutors will be displayed here",
      "text_teacher": "You haven't scheduled any classes yet",
      "link_label": "Catalog of classes",
      "link_label_teacher": "Schedule class",
      "filter": {
        "All": "All",
        "Upcoming": "Upcoming",
        "Unconfirmed": "Unconfirmed",
        "Past": "Past",
        "Canceled": "Canceled"
      }
    },
    "no-tutors": {
      "title": "Your tutors",
      "text": "Here you'll find the tutors with whom you're scheduled for classes\n\n\n\n",
      "link_label": "Tutor catalog"
    },
    "no-classes": {
      "title": "Your classrooms",
      "text": "Here you'll find the classrooms you're a member of and where your online classes take place.",
      "text_teacher": "There are no classrooms yet",
      "link_label": "Catalog of classes",
      "link_label_teacher": "Create a classroom",
      "filter": {
        "Active": "Active",
        "Archive": "Archive"
      }
    },
    "no-schools": {
      "title": "No schools",
      "text": "Your schools in which you are a member and where you owner will be displayed here.",
      "link_label": "Catalog of schools",
      "filter": {
        "owner": "I am owner",
        "student": "I am student",
        "teacher": "I am teacher"
      }
    },
    "no-students": {
      "title": "Your students",
      "text": "Your students with whom you are studying will be displayed here",
      "text_teacher": "You do not yet have students with whom you study individually"
    },
    "no-homeworks": {
      "title": "Your homework",
      "text": "Here, you will find the homework assignments provided by your tutors",
      "text_teacher": "Have you given homework to your students yet",
      "filter": {
        "All": "All",
        "Verified": "Verified",
        "Unverified": "Unverified"
      }
    },
    "meta-title": "My classes",
    "page-header-title": "My Classes",
    "actions": {
      "create-lesson": "Create a lesson",
      "create-service": "Create a service",
      "create-collection": "Create a collection",
      "schedule-class": "Schedule a class",
      "create-classroom": "Create a classroom",
      "create-school": "Create a school",
      "new-homework": "New homework"
    }
  },
  "activity-lesson": {
    "tabs": {
      "completed": "Completed",
      "recently-watched": "Recently watched",
      "purchased": "Purchased"
    }
  },
  "favorites": {
    "page-header-title": "Favorites",
    "meta-title": "Favorites",
    "stub": {
      "lessons": "You don't have favorite lessons <br> <a href=\"/lessons\" class=\"stub-link\">Lesson catalog</a>",
      "classes": "You don't have favorite classes <br> <a href=\"/classes\" class=\"stub-link\">Classes catalog</a>",
      "tutors": "You haven't selected any tutor <br> <a href=\"/tutors\" class=\"stub-link\">Tutor catalog</a>",
      "collections": "You don't have any favorite collections <br> <a href=\"/collections\" class=\"stub-link\">Collection catalog</a>",
      "users": "You don't have favorite users <br> <a href=\"/users\" class=\"stub-link\">User directory</a>",
      "schools": "You don't have favorite schools <br> <a href=\"/schools\" class=\"stub-link\">View catalog</a>"
    },
    "empty-message": "You haven't added anything to your favorites yet"
  },
  "favorites/lessons": {
    "page-header-title": "Lessons",
    "meta-title": "Lessons",
    "empty": "Catalog of lessons"
  },
  "favorites/services": {
    "page-header-title": "Classes",
    "meta-title": "Classes",
    "empty": "Catalog of classes"
  },
  "favorites/tutors": {
    "page-header-title": "Tutors",
    "meta-title": "Tutors",
    "empty": "Tutor catalog"
  },
  "favorites/users": {
    "page-header-title": "Users",
    "meta-title": "Users",
    "empty": "Users directory"
  },
  "favorites/schools": {
    "page-header-title": "Schools",
    "meta-title": "Schools",
    "empty": "Schools directory"
  },
  "favorites/collections": {
    "page-header-title": "Collections",
    "meta-title": "Collections",
    "empty": "Collection catalog"
  },
  "schedule-page": {
    "tabs": {
      "working-hours": "Working hours",
      "calendar": "Calendar",
      "vacation": "Vacation"
    }
  },
  "banner-plan": {
    "title": "Your plan",
    "credit-label": "Available credits:",
    "expired-at": "Expiry:",
    "action": "Upgrade"
  },
  "plan-warning-message": {
    "lesson": "The ability to create lessons with private access is available only on paid plans.",
    "collection": "The ability to create private collections is only available on paid plans.",
    "choose-plan": "Choose a plan"
  },
  "classroom-news": {
    "stub": "Track activity in the classrooms you have been invited to",
    "page-header-title": "Classrooms",
    "meta-title": "Classrooms"
  },
  "school-news": {
    "stub": "Track activity in the schools in which you are a member",
    "page-header-title": "Schools",
    "meta-title": "Schools"
  },
  "menu-widget": {
    "title": "News",
    "list": {
      "lessons": "Lessons",
      "publications": "Publications",
      "classrooms": "Classrooms",
      "homework": "Homework",
      "schools": "Schools",
      "comments": "Comments"
    }
  },
  "suggest-add-card": {
    "text": "Start receiving payments by providing your payment details",
    "action": "Update"
  },
  "people-news": {
    "page-header-title": "People",
    "meta-title": "People",
    "stub": "Find out the news of those you subscribe to"
  },
  "timezone-notifier": {
    "title": "Timezone settings",
    "question": "Change timezone to [{{timezone}} {{offset}}]",
    "btn-skip": "Don't change",
    "btn-save": "Change"
  },
  "currency-notifier": {
    "title": "Change currency",
    "question": "Want to change currency from {{from}} to {{to}}?",
    "btn-skip": "Don't change",
    "btn-save": "Change"
  },
  "payout": {
    "status": {
      "error": "Requisites are not specified",
      "completed": "Complete",
      "confirmed": "Complete",
      "pending": "Pending",
      "restricted": "Restricted"
    },
    "text": {
      "error": "In order for you to receive payments for classes, you need to specify the details for receiving payment. In the card menu, select \"Edit\" and specify the card or account details.",
      "restricted": "You can post your services, but payments will be made manually by the operator, which takes more time than automatic payments. To enable automatic payments, please make sure that the information you have entered is correct and accurate"
    },
    "set-default": "Set default",
    "get-money": "Get money",
    "bank-account": "Bank Account",
    "card-number": "Card",
    "swift": "SWIFT",
    "bank": "Bank name",
    "edit": "Edit",
    "remove": "Remove",
    "balance": "Balance",
    "upcoming": "Upcoming",
    "advances": "Advances",
    "wait-balance-text": "It takes up to 10 days to process the queue of incoming payments. After that, the next processed amount will be credited to your balance. and will be available for withdrawal.",
    "rate-balance-text": "The approximate amount you will receive when withdrawing funds. The commission and the current exchange rate are taken into account",
    "auto-balance-text": "Amount to be paid. To be paid gradually for the classes held.",
    "convertation-balance-text": "The approximate amount you will receive when withdrawing funds. The commission and the current exchange rate are taken into account",
    "default": "Default",
    "inactive": "Inactive beneficiaries.",
    "inactive_accounts": "Inactive accounts.",
    "all_world": "Worldwide",
    "russia": "Russia",
    "add_method": "Add a payout method",
    "add_payment_method": "Add a payment method",
    "support-text": "Need help? Contact our support team",
    "support-link": "Write to support",
    "enter-tax-info": "Enter your tax information",
    "beneficiary": "Beneficiary",
    "autopayout": "Autopayout",
    "auto-payout-alert-title": "Payments are automatic",
    "auto-payout-alert-text": "Money is withdrawn automatically to your bank card 3 days after the class is completed if there are no complaints about it",
    "auto-payout-alert-btn": "I get it",
    "delete-supplier-title": "It is not yet possible to remove the method for obtaining the result",
    "delete-supplier-text": "You have classes scheduled or a payment is pending.",
    "delete-supplier-btn": "Close",
    "auto-payout-help": "Money is withdrawn automatically to your bank card 3 days after the class is completed if there are no complaints about it",
    "change-alert": {
      "title": "Are you sure you want to change your information?",
      "description": "All information is entered correctly and verified, it is better not to change it.",
      "cancel": "Do not change",
      "change": "Change"
    },
    "profile_page": {
      "payout_info_title": "Payout information",
      "payout_add_world_text": "Set up a payout method to receive payments worldwide",
      "payout_add_russia_text": "Set up a payout method to receive payments in Russia",
      "payment_info_title": "Payment information",
      "payment_add_world_text": "Linking bank cards around the world",
      "payment_add_russia_text": "Linking cards of Russian banks"
    },
    "add-alert-title": "Add a new account for payouts?",
    "add-alert-text": "Each user can have only one active payout account. If you add another account, the previous account will be deactivated and moved to the ‘Inactive beneficiaries' category.",
    "dontadd": "Don't add",
    "add": "Add"
  },
  "finance-page": {
    "title": {
      "main": "Main",
      "payout": "Payouts",
      "payment": "Payment",
      "history": "Transaction history",
      "subscriptions": "Subscriptions",
      "plan": "Your Plan"
    },
    "card": {
      "expiry-date": "Card expiry date",
      "expiry-text": "Card expired",
      "main-card": "Primary",
      "set-as-main": "Set as primary",
      "remove": "Remove"
    }
  },
  "vocabulary-page": {
    "title": {
      "main": "My Vocabulary",
      "students": "Students"
    }
  },
  "schedule-calendar": {
    "title": {
      "calendar": "Calendar",
      "working-hours": "Working hours"
    }
  },
  "user-page": {
    "title": {
      "subscriptions": "Following",
      "subscribers": "Followers",
      "students": "My students",
      "all": "Other users"
    }
  },
  "withdraw": {
    "available-for-payout": "Available for payout",
    "minimum-amount": "Minimum withdrawal amount",
    "where-withdrawal": "Choose where you want to withdraw money",
    "processing-fee": "Processing fee",
    "will-be-credited": "Will be credited to your account",
    "will-be-credited-to": "Money will be credited to",
    "get": "Получить {{amount}}",
    "success-text": "Money sent successfully. They will arrive to your personal account or card within 7 days",
    "fail-text": "Money sent fail",
    "account-selection": "Account selection",
    "amount": "Amount"
  },
  "no-internet": {
    "title": "Offline mode",
    "text": "Internet connection lost. To continue working, wait until the connection is restored."
  },
  "loading": "Loading",
  "titles": {
    "main": {
      "lessons": "Lessons",
      "tutors": "Tutors",
      "classes": "Classes",
      "collections": "Collections",
      "schools": "Schools"
    }
  },
  "error-notificator": {
    "LOAD-USER": "Failed to load user data",
    "LOAD-SOMEDATA": "Failed to load data",
    "LOAD-FILE": "Failed to upload the file",
    "CANT-LOGIN": "Failed to authorise",
    "SOCKET-ERROR": "Update error",
    "PROFILE-PROBLEM": "Profile error",
    "PAYMENT-PROBLEM": "Something's wrong with the purchase",
    "SAVE-PROBLEM": "Problem with saving",
    "OTHER-PROBLEM": "Something went wrong",
    "report-error": "Report error",
    "error": "error #"
  },
  "user-service-category": {
    "individual": "Popular classes",
    "speaking": "Speaking practice",
    "exam_training": "Exam training",
    "for_children": "For children",
    "discussion": "Discussion",
    "master_class": "Master class"
  },
  "languages": {
    "ru": "Russian",
    "en": "English",
    "fr": "French",
    "de": "German",
    "es": "Spanish",
    "pt": "Portuguese",
    "it": "Italian",
    "zh-tw": "Chinese",
    "ja": "Japanese",
    "en-us": "American English",
    "tr": "Turkish",
    "ar": "Arabic",
    "ko": "Korean",
    "vi": "Vietnamese",
    "sk": "Slovak",
    "cs": "Czech",
    "pl": "Polish",
    "ro": "Romanian",
    "hu": "Hungarian",
    "no": "Norwegian",
    "da": "Danish",
    "nl": "Dutch",
    "sv": "Swedish",
    "fi": "Finnish",
    "el": "Greek",
    "he": "Hebrew",
    "hi": "Hindi",
    "id": "Indonesian",
    "th": "Thai",
    "es-mx": "Mexican Spanish",
    "ar-eg": "Egyptian Arabic",
    "pt-br": "Brazil Portuguese",
    "zh": "Traditional Chinese",
    "zh-hk": "Chinese(Hong Kong)",
    "bg": "Bulgarian",
    "ca": "Catalan",
    "ms": "Malay",
    "sr": "Serbian",
    "uk": "Ukrainian",
    "tl": "Tagalog",
    "fl": "Philippine",
    "fr-ca": "French Canadian",
    "af": "Afrikaans",
    "sq": "Albanian",
    "am": "Amharic",
    "hy": "Armenian",
    "az": "Azerbaijani",
    "be": "Belarusian",
    "bn": "Bengal",
    "bs": "Bosnian",
    "my": "Burmese",
    "hr": "Croatian",
    "fa-af": "Dari",
    "et": "Estonian",
    "ka": "Georgian",
    "kk": "Kazakh",
    "ku": "Kurdish",
    "lo": "Lao",
    "lv": "Latvian",
    "lt": "Lithuanian",
    "mk": "Macedonian",
    "mg": "Malagasy",
    "mn": "Mongolian",
    "ne": "Nepali",
    "ps": "Pashto",
    "fa": "Persian",
    "si": "Sinhala",
    "sl": "Slovenian",
    "so": "Somali",
    "sw": "Swahili",
    "ta": "Tamil",
    "ti": "Tigrinya",
    "ur": "Urdu"
  },
  "common-components": {
    "rating-v2": {
      "rating": "Rating",
      "reviews": "reviews"
    },
    "user-services": {
      "no-services": "The tutor has not yet added services in this discipline"
    }
  },
  "lingo2-forms": {
    "meetings": {
      "buy-credits-action": "Buy credits",
      "choose-plan-action": "Choose a plan",
      "classrooms": {
        "all": "All",
        "continue-class": "Continue the class",
        "groups": "Groups",
        "no-classes": "There are no classrooms yet",
        "no-classes-description": "Classrooms are created to group students together for online classes. A new classroom is formed when a new activity is created and all participants become members of the classroom. Select “New Classroom”.<br/>You can also select previously created classrooms when planning a lesson.",
        "panel-classes": "Classes:",
        "panel-students": "Students:",
        "students": "Students",
        "empty": "Empty"
      },
      "create-action": "Create classroom",
      "dz-placeholder-link1": "select button",
      "dz-placeholder-link2": "to select",
      "dz-placeholder-text": "Drag the cover or press the",
      "form-access-label": "Access",
      "form-access-private": "Private",
      "form-access-public": "Public",
      "form-balance-label": "Balance",
      "form-balance-note": "Your balance:",
      "form-buy-credits-header": "Buy credits",
      "form-carrying-possible": "Classes are possible",
      "form-change-title": "Schedule a class",
      "form-charging-label": "Participation",
      "form-create-action": "Create",
      "form-credits": "credits",
      "form-credits-available": "Credits available:",
      "form-download": "Download",
      "form-keywords-hint-label": "Recommended Skills",
      "form-keywords-label": "Skills that the student will receive",
      "form-keywords-placeholder": "Start typing here",
      "form-language-label": "Lesson language",
      "form-language-placeholder": "Choose language",
      "form-level-label": "Level",
      "form-level-other-name-placeholder": "Level name",
      "form-level-placeholder": "Choose level",
      "form-not-credits": "Class is not possible",
      "form-number-participants-label": "Number of participants",
      "form-participants-limit-warn": "Not chosen",
      "form-participants_limit-label": "Number of participants",
      "form-participation-free": "Free",
      "form-participation-label": "Participation cost",
      "form-participation-paid": "Paid",
      "form-price-label": "Price",
      "form-price-placeholder": "price",
      "form-price-tier-warn": "Not chosen",
      "form-error-choose-option": "Choose the right option",
      "form-required": "Required:",
      "form-save-action": "Save",
      "form-search-placeholder": "Search...",
      "form-subject-other-placeholder": "Discipline name",
      "form-subject-placeholder": "Choose discipline",
      "form-subject-warn": "The choice of discipline is required",
      "form-term-placeholder": "Search...",
      "form-time-label": "Date and time",
      "form-timezone-label": "Timezone",
      "form-timezone-message": "In your time zone",
      "form-title": "Schedule a class",
      "form-title-placeholder": "Classroom topic",
      "form-title-warn": "Required field",
      "form-to-calendar": "Add to calendar",
      "form-type-calc-label": "Type of payment",
      "form-bank-cards": "To create a conference with this type of calculation, you need to link <span class=\"link\">a bank card</span>",
      "hints": {
        "credit": "Classes for credits, allow you to conduct free classes or get paid by your students off the platform.",
        "private": "Private classroom for conducting classes with students by invitation link. Invite students to your lesson using an invitation link",
        "public": "Your conference will be published in the class directory, and anyone can sign up for your online lesson.",
        "secure": "The Onclass.com platform acts as the guarantor of the deal. Students pay for the lesson through the platform. The Onclass commission is {{ term1 }}% of the cost of classes, the remaining {{ term2 }}% is paid to the teacher.",
        "verification-for-fee": "To create conferences with this type of calculation, you must pass teacher profile verification.",
        "verification-for-beneficiary": "To create conferences with this type of calculation, you must have payout information",
        "verification-for-public": "To create public conference, you must pass teacher profile verification.",
        "verification-link": "Verify",
        "beneficiary-link": "Add payment information",
        "paywall-action": "reduce commission"
      },
      "inputs-label": {
        "choose-time": "Select time",
        "create-now": "Create now",
        "credits": "Payment by credits",
        "free": "Free classes. Limited offer, hurry up",
        "private": "Private class with invitation link",
        "public": "Public class in the class catalog",
        "safe": "Safe transaction"
      },
      "meeting-type": {
        "group": "Group (2 - 24)",
        "single": "Private (1)",
        "stream": "Stream (50 - 1000)",
        "talk": "Talk (1 - 6)",
        "webinar": "Webinar (25 - 50)"
      },
      "meeting-type-short": {
        "group": "Group class",
        "single": "Individual class",
        "stream": "Stream",
        "talk": "Talk",
        "webinar": "Webinar"
      },
      "not-available-time-informer": {
        "cancel-class": "Cancel class",
        "info-text": "If you need to reschedule or cancel a class, it is recommended to do so at least 24 hours before it is scheduled to start.",
        "postpone-class": "Postpone class",
        "send-message": "Send a message",
        "student": "Student:",
        "title-part1": "Time",
        "title-part2": "is busy with the lesson:"
      },
      "participants-limit-placeholder": "Choose",
      "placeholders": {
        "category": "Not chosen",
        "classroom": "Not chosen",
        "detailed": {
          "description": "Scheduled classes with your own or new students",
          "title": "Set up a class"
        },
        "meeting-type": "Not chosen",
        "quick": {
          "description": "Instant set-up and invite your students with link",
          "title": "Quick class"
        },
        "tab": "New classroom"
      },
      "plan-upgrade-action": "upgrade plan",
      "redeem-coupon-action": "redeem coupon",
      "steps": {
        "classroom": "Select a classroom:",
        "setup": "Set up and customize the class:",
        "template": "Select a template:",
        "type": "Class type:",
        "tutor": "Tutor"
      },
      "titles": {
        "exam_training": "Exam training",
        "for_children": "For children",
        "other": "Other activities",
        "personal": "Personal lessons",
        "speaking": "Speaking practice"
      },
      "meeting-select-author": {
        "reviews-count": "reviews",
        "select-tutor": "Select a tutor",
        "no-tutor-message": "There are no tutors at your school yet"
      }
    },
    "subject-selector": {
      "create": "Create new",
      "foreign": "Foreign",
      "lvl1": {
        "foreign": "Foreign languages",
        "native": "Native languages",
        "non_categorized": "Other subjects",
        "other": "New discipline"
      },
      "native": "Native",
      "not-subject": "No such discipline found",
      "recent-title": "Recent",
      "search-placeholder": "Search",
      "select-placeholder": "Subject"
    },
    "subscription-plans": {
      "forms": {
        "action-activate-plan": "Upgrade",
        "action-apply": "Apply",
        "why-plan-text": "Save {{ discount }}% and receive an additional {{ credits }} credits",
        "action-buy-credits": "Pay",
        "action-cancel": "Unsubscribe",
        "action-payment": "Payment",
        "activate-text": "Activating your subscription",
        "agreements": "I agree to {{ terms1 }}, as well as {{ terms2 }}",
        "agreements-terms1": "the terms and conditions",
        "agreements-terms2": "the privacy policy",
        "alert-text": "Attention! Canceling your subscription will burn your credits for subscription as well as purchase additionally. Bonus credits will be saved.",
        "amount-credits": "Credits amount:",
        "bonus": "Bonus:",
        "close": "Close",
        "buying-credits": "Purchase of credits",
        "by-subscription": "Subscription credits:",
        "cancel-mode-text": "do you want to cancel your subscription? Please write below what did not suit you and why you are going to cancel:",
        "cancel-mode-text2": "Subscription credits that are not utilized prior to the expiration date will be forfeited. However, gifted and purchased credits will remain valid",
        "cancel-text": "Cancellation of subscription",
        "cancellation-label": "Your plan after cancellation:",
        "cancellation-label2": "Your credits after canceling your subscription:",
        "header-label": "Your plan:",
        "info-label": "Monthly subscription to Onclass",
        "next-payment": "Next payment date:",
        "next-payment-text1": "Payment will be charged automatically.",
        "next-payment-text2": "You can cancel your subscription at any time.",
        "payment": "Proceed to checkout",
        "promo-placeholder": "Promo code",
        "purchased": "Purchased:",
        "recommended-plan": "Recommended plan:",
        "total-credit": "Total credits left:",
        "total-pay": "Total:",
        "your-plan": "Your plan:",
        "pay": "Pay",
        "current-plan": "Current plan",
        "subscription-will-canceled": "Subscription will be cancelled on",
        "total-credits-amount": "Total credits amount",
        "cancelled": "forfeited",
        "persist": "remain",
        "activated": "Activated",
        "cancel-subscription": "Cancel subscription"
      },
      "periods": {
        "day": "day",
        "month1": "month",
        "month3": "3 month",
        "month6": "6 month",
        "year": "year"
      },
      "plan-advanced": {
        "bonus-hint": "+40 bonus",
        "hint": "Up to 100 lesson per month",
        "nameplate": "MOST POPULAR",
        "subtitle": "Optimal commission",
        "title": "Advanced"
      },
      "plan-beginners": {
        "hint": "Up to 20 lesson per month",
        "subtitle": "20 credits",
        "title": "Beginners"
      },
      "plan-free": {
        "hint": "Up to 5 lesson per month",
        "subtitle": "5 credits",
        "title": "Free"
      },
      "plan-professional": {
        "nameplate": "BEST OFFER",
        "subtitle": "Minimal commissions",
        "title": "Professional"
      },
      "plan-standard": {
        "bonus-hint": "+10 bonus",
        "hint": "Up to 50 lesson per month",
        "subtitle": "40 credits",
        "title": "Standard"
      },
      "plan-starter": {
        "subtitle": "High commission",
        "title": "Starter"
      },
      "plan-trial": {
        "hint": "Try 30 days free, then {{ price }} per month",
        "nameplate": "Special offer",
        "subtitle": "30 days for FREE",
        "title": "Trial"
      },
      "terms": {
        "term1": "Receive credits per month: {{ receive_credits_per_month }}",
        "term2": "Safe Trade Commission: {{ safe_trade_commission }} %",
        "term3": "Discount on the purchase of credits: {{ discount_the_purchase_of_credits }} %",
        "term4": "Receive a {{ conference_commission_discount }}% discount on commission fees for classes conducted through Onclass when calculating payments",
        "term5": "Get {{ credits_per_month }} credits per month",
        "term6": "Discount on purchase of additional credits {{ discount_on_purchase_of_additional_credits }} %"
      }
    },
    "pretected-payment": "Onclass payment protection"
  },
  "plans": {
    "special_offer": "Special offer",
    "30_days_for_free": "30 days for free",
    "30_days_for_free_description": "Try 30 days free, then {{price}} per month",
    "trial_subscription_activation": "Trial subscription activation",
    "first_month": "first month",
    "first_payment_date": "First payment date:",
    "text_per_month": "{{date}} - {{price}} per month",
    "activate_30_days_free": "Activate 30 days free",
    "30_days_for_free_Onclass": "30 days for free subscription to Onclass",
    "receive_credits_per_month": "Receive credits per month: {{credits}}",
    "payment_charged_automatically": "Payment will be charged automatically.",
    "can_cancel_any_time": "You can cancel your subscription at any time."
  }
}
