import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiButtonModule } from 'onclass-ui';
import { OnModerationComponent } from './on-moderation.component';

@NgModule({
  declarations: [OnModerationComponent],
  imports: [CommonModule, RouterModule, TranslateModule, OnUiButtonModule],
  exports: [OnModerationComponent],
})
export class OnModerationModule {}
