import { Component, OnInit, Input, AfterViewInit, ChangeDetectorRef } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  ValidationErrors,
  AbstractControl,
  UntypedFormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { logger } from '@app/core/helpers/logger';
import { CanComponentDeactivate } from '@core/guards/can-deactivate.guard';
import { AuthService, AccountService, ContextService, ProfileService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { IControlOption } from '@shared/form-controls/form-controls.models';
import { setProfile } from '@store/actions/profile.actions';
import { ISchool } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonState } from 'onclass-ui';
import { Subject, Observable } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { ProfileFormService } from '../_services/profile-form.service';
import { forceLoadAccountCheck } from '@app/store/actions/data.actions';

export interface IEducationForm {
  education_schools: ISchool[];
  education_docs: string[];
}

export function isEducationCompleted(value: IEducationForm): boolean {
  return (
    value.education_schools.length &&
    value.education_schools.every((x) => x.school_name && x.department_name && x.end_year)
  );
}

const isEmptyString = (value: string): boolean => !value.toString().trim().length;

@Component({
  selector: 'app-education-form-page',
  templateUrl: './education-form-page.component.html',
  styleUrls: ['./education-form-page.component.scss'],
})
export class EducationFormPageComponent
  extends ChangableComponent
  implements OnInit, CanComponentDeactivate, AfterViewInit
{
  @Input() data: IEducationForm;
  @Input() needAdditionalAction: boolean;

  protected formVersion = 1; // TODO автоматически вычислять версию формата формы
  protected storageKey = 'APP__PROFILE_ABOUT';

  public form: UntypedFormGroup;
  public value: IEducationForm;
  public yearsOptions: IControlOption[];
  public saveState: OnUiButtonState = 'default';

  private required = false;
  private validateFormGroup = ProfileFormService.validateFormGroup;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    protected fb: UntypedFormBuilder,
    protected profileService: ProfileService,
    protected contextService: ContextService,
    protected accountService: AccountService,
    protected authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private profileFormService: ProfileFormService,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.profileFormService.profileForm$
      .pipe(
        map((x) => x.education),
        filter((x) => !!x),
        take(1),
        takeUntil(this.destroyed$),
      )
      .subscribe((res) => (this.value = res));

    const currentYear = new Date().getFullYear();
    this.yearsOptions = Array(60)
      .fill(0)
      .map((x, i) => ({ title: (currentYear - i).toString(), value: currentYear - i })); // [2020, 2019, 2018, 2017, ...]

    // Init form and set form values
    this.form = this.fb.group({
      education_schools: this.fb.array(this.educationSchoolsArray(this.value.education_schools)),
      education_docs: this.fb.array([...this.value.education_docs, ...['']]),
    });

    this.form.patchValue(this.value);

    this.profileFormService.verificationRequested$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (requested) => {
        this.required = requested;
        this.validateFormGroup(this.form);
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    this.autoSave();
  }

  public isInvalid(formGroup: UntypedFormGroup, formControlName: string): boolean {
    return formGroup.get(formControlName)?.invalid;
  }

  private autoSave(): void {
    this.form.valueChanges
      .pipe(debounceTime(300))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => this.saveForm(),
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SAVE-PROBLEM');
        },
      });
  }

  ngAfterViewInit(): void {
    if (!this.needAdditionalAction) {
      this.educationSchools.markAsUntouched();
      this.educationSchools.updateValueAndValidity();
      this.markForCheck();
    }
  }

  private educationSchoolsArray(values: ISchool[]): UntypedFormGroup[] {
    if (!values.length) {
      return [this.educationSchoolForm()];
    } else {
      return values.map((value) => this.educationSchoolForm(value));
    }
  }

  private educationSchoolForm(value?: ISchool): UntypedFormGroup {
    const _value: Partial<ISchool> = value || {};
    return this.fb.group({
      school_name: [_value.school_name || '', [Validators.maxLength(200), this.conditionalEmptyValidator.bind(this)]],
      department_name: [
        _value.department_name || '',
        [Validators.maxLength(200), this.conditionalEmptyValidator.bind(this)],
      ],
      end_year: [_value.end_year || '', [this.conditionalEmptyValidator.bind(this)]],
    });
  }

  private conditionalEmptyValidator(control: UntypedFormControl): ValidationErrors | null {
    if (!this.required) {
      return null;
    }
    return isEmptyString(control.value) ? { required: { value: true } } : null;
  }

  public get educationSchools(): UntypedFormArray {
    return this.form.get('education_schools') as UntypedFormArray;
  }

  public get educationDocs(): UntypedFormArray {
    return this.form.get('education_docs') as UntypedFormArray;
  }

  public removeEducationSchool(index: number) {
    this.educationSchools.removeAt(index);
    this.form.markAsDirty();
  }

  public addEducationSchool() {
    this.educationSchools.push(this.educationSchoolForm());
  }

  public addMedia() {
    this.educationDocs.push(EducationFormPageComponent.mediaFormControl());
  }

  public removeMedia(index: number) {
    this.educationDocs.removeAt(index);
    this.form.markAsDirty();
  }

  private static mediaFormControl(value: string = null): AbstractControl {
    return new UntypedFormControl(value);
  }

  public onChangedMedia(args: string, idx: number) {
    const len = this.educationDocs.length;
    if (len - 1 !== idx && args) {
      return;
    }

    if (args) {
      this.addMedia();
    } else {
      this.removeMedia(idx);
    }
  }

  public saveForm(): void {
    if (this.form.invalid || !this.form.dirty || this.saveState === 'progress') {
      return;
    }

    this.saveState = 'progress';

    this.save(this.form.value).then(
      () => {
        this.saveState = 'done';
        this.resetState();
      },
      () => {
        this.saveState = 'fail';
        this.resetState();
      },
    );
  }

  public resetState() {
    this.setTimeout(() => {
      this.saveState = 'default';
      this.detectChanges();
    }, 1000);
  }

  public save(values: IEducationForm): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const { education_schools, education_docs } = values;
      const _education_schools = (education_schools || []).filter((v) => v.school_name.length > 0);
      const _education_docs = education_docs || []; /* .filter((values) => {
        return values.school_name.length > 0;
      });*/

      this.profileService
        .updateProfile({
          education_schools: _education_schools,
          education_docs: _education_docs.filter((el) => el),
        })
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (profile) => {
            this.contextService.patchProfile({
              education_schools: profile.education_schools,
              education_docs: profile.education_docs,
              verification_status: profile.verification_status,
            });

            this.profileFormService.setProfileForm({ education: values });
            this.store.dispatch(setProfile({ profile }));
            this.store.dispatch(forceLoadAccountCheck());
            resolve(true);
          },
          error: (error: any) => {
            reject(error);
          },
        });
    });
  }

  public navigateBack(): void {
    this.forceSave();
    this.setTimeout(() => {
      this.router.navigate(['/profile/contacts']);
    }, 1500);
  }

  private forceSave() {
    this.saveForm();
  }

  public navigateToNextStep(): void {
    this.forceSave();
    this.setTimeout(() => {
      this.router.navigate(['/profile/experience']);
    }, 1500);
  }

  public canDeactivate(): Observable<boolean> | boolean {
    if (this.form.valid && this.form.dirty) {
      this.saveState = 'progress';
      const deactivate = this.register(new Subject<boolean>());
      const deactivate$ = deactivate.asObservable();

      this.save(this.form.value).then(
        () => {
          this.saveState = 'done';
          deactivate.next(true);
        },
        () => {
          this.saveState = 'fail';
          deactivate.next(true);
        },
      );

      return deactivate$;
    }

    return true;
  }

  public trackByFn(index) {
    return index;
  }
}
