<spinner2 *ngIf="loading"></spinner2>

<ng-template [ngIf]="!loading && !loaded">
  <h1 class="text-danger m-t-20 text-center">404 Article not found</h1>
</ng-template>

<ng-template [ngIf]="loaded">
  <div class="page-wrap">
    <div class="title-wrap">
      <h1>{{ item.title }}</h1>
      <a *ngIf="item.purl" [href]="item.purl" target="_blank">edit</a>
    </div>
    <div class="content rich-html">
      <div [innerHTML]="item.text | sanitizeHtml"></div>
    </div>
  </div>
</ng-template>
