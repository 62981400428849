import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { logger } from '@app/core/helpers/logger';
import { getMe } from '@app/store/reducers/profile.reducer';
import { AccountService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { Store } from '@ngrx/store';
import { UserRoleEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiTab } from 'onclass-ui';
import { takeUntil, filter, withLatestFrom, debounceTime } from 'rxjs/operators';

// Store
import { loadLibraryContent, setLibraryFilter } from './store/library';
import { loadStats } from './store/stats';
import { getStats } from './store/stats/stats.selectors';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

const TABS: OnUiTab[] = [
  {
    code: 'form',
    label: 'Content editor',
    route: 'edit',
    routeMatch: 'includes',
  },
  {
    code: 'published',
    label: 'Published',
    route: 'published',
    routeMatch: 'end',
  },
  {
    code: 'private',
    label: 'Private',
    route: 'private',
    routeMatch: 'end',
  },
  {
    code: 'draft',
    label: 'Draft',
    route: 'draft',
    routeMatch: 'end',
  },
];

const TEMPLATE_TAB: OnUiTab = {
  code: 'template',
  label: 'Templates',
  route: 'template',
  routeMatch: 'end',
};

@Component({
  selector: 'app-content-editor',
  templateUrl: './content-editor.component.html',
  styleUrls: ['./content-editor.component.scss'],
})
export class ContentEditorComponent extends DestroyableComponent implements OnInit {
  public tabs: OnUiTab[] = TABS;
  public searchControl: UntypedFormControl;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private store: Store,
    private accountService: AccountService,
    private fb: UntypedFormBuilder,
    public deviceService: DeviceDetectorService,
  ) {
    super();
  }

  ngOnInit() {
    this.initSearchControl();

    this.store
      .select(getStats)
      .pipe(
        filter((x) => !!x),
        withLatestFrom(this.store.select(getMe)),
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([stats, me]) => {
          if (AccountService.hasRole(me, UserRoleEnum.editor) && !this.tabs.some((x) => x.code === 'template')) {
            this.tabs.push(TEMPLATE_TAB);
          }
          this.tabs = this.tabs.map((x) => ({ ...x, count: stats[x.code] }));
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        }
      });
    this.store.dispatch(loadStats());
  }

  onTermChanged(term: string): void {
    logger.log('[Content Editor] onTermChanged = ', term);
    this.store.dispatch(setLibraryFilter({ filter: { term } }));
    this.store.dispatch(loadLibraryContent());
  }

  private initSearchControl(): void {
    this.searchControl = this.fb.control('');

    this.searchControl.valueChanges
      .pipe(debounceTime(500))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (value) => {
          this.onTermChanged(value);
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        }
      });
  }
}
