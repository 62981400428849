<section class="promoblue">
  <div class="promoblue-devices"></div>
  <div class="promoblue-bg">
    <div class="promoblue-container">
      <div class="promoblue-text">
        {{'job-landing.promoblue.title' | translate}}
      </div>
    </div>
  </div>
</section>
