import { Component, OnInit, OnDestroy } from '@angular/core';
import { logger } from '@app/core/helpers/logger';
import { DestroyableComponent } from '@app/models/destroyable.component';
import { CLibraryFilterData } from '@app/store/models';
import { LoadingBarService } from '@core/components/spinners/loading-bar/loading-bar.service';
import { ContextService, MeetingsService, MetaService, MeetingsBlockType, ContentService } from '@core/services';
import { WebsocketService } from '@core/websocket';
import { IEntityUpdate } from '@models/lingo2-models/messaging';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadFeaturings } from '@store/actions/featuring.actions';
import { getFeaturingsByKey } from '@store/reducers/featuring.reducer';
import { IContentPane, Meeting, EContentModeType, EContentPanelRoute, MeetingDetailsType } from 'lingo2-models';
import { first, takeUntil } from 'rxjs/operators';
import { MeetingsFormService } from '../meetings-form.service';

@Component({
  selector: 'app-meetings',
  templateUrl: './meetings.component.html',
  styleUrls: ['./meetings.component.scss'],
})
/** @deprecated Заменён библиотечным роутингом */
export class MeetingsComponent extends DestroyableComponent implements OnInit, OnDestroy {
  public me$ = this.contextService.me$;

  public meetings: MeetingsBlockType = {
    items: [],
    loading: false,
    loaded: false,
    filter: {
      public: true,
    },
    pagination: {
      page: 1,
      pageSize: 5,
      total: 0,
      totalPages: 0,
    },
  };
  private details: MeetingDetailsType[] = [
    'id',
    'slug',
    'title',
    'cover',
    'author:sm',
    'subject',
    'level',
    'language',
    'begin_at',
    'end_at',
    'description',
    'keywords',
    'participants_limit',
    'participants_count',
    'charging',
    'visit_info',
    'published_at',
    'created_at',
    'status',
    'started_at',
    'finished_at',
    'is',
    'can',
  ];
  public paneList: IContentPane[];
  private paneRequestsCount = 0;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private contextService: ContextService,
    private meetingsService: MeetingsService,
    private websocketService: WebsocketService,
    private meetingsForm: MeetingsFormService,
    private loadingBar: LoadingBarService,
    private store: Store,
    private meta: MetaService,
    private contentService: ContentService,
  ) {
    super();
  }

  public ngOnInit(): void {
    // уведомление об изменении одного из загруженных классов
    this.websocketService
      .on<IEntityUpdate>('update')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (update) => {
          if (update.entity === 'meeting' && !!this.meetings.items.find((m) => m.id === update.id)) {
            this.reloadMeeting(update.id); // обновить один класс
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SOCKET-ERROR');
        },
      });
    this.meetingsForm.form$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (form) => {
        if (this.meetings.filter.term !== form.term) {
          this.meetings.pagination.page = 1;
        }
        this.meetings.filter.term = form.term;
        this.loadMeetings();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
    });

    this.loadMeetings();
    this.setupMeta();

    const _libraryFilter: Partial<CLibraryFilterData> = {
      url: '/' + EContentPanelRoute.classes,
      baseRoute: EContentPanelRoute.lessons,
      contentMode: EContentModeType.all,
      contentOrder: undefined,
      contentTypeCodes: [],
      isDefaultOnlyLanguages: true,
      languageCodes: [],
      subjectCodes: [],
      term: undefined,
    };

    this.store.dispatch(loadFeaturings({ contentFilter: _libraryFilter }));
    this.loadContentBlocks(_libraryFilter);
  }

  private loadContentBlocks(contentFilter: Partial<CLibraryFilterData>) {
    this.paneRequestsCount++;
    this.store
      .select(getFeaturingsByKey, { contentFilter })
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          if (res) {
            this.paneList = res.data;
            this.paneRequestsCount = 0;
          } else {
            if (this.paneRequestsCount < 10) {
              this.setTimeout(() => {
                this.loadContentBlocks(contentFilter);
              }, 200);
            }
          }
        },
        error: (err: any) => {
          logger.error(err);
        },
      });
  }

  private featuringKey(contentFilter: Partial<CLibraryFilterData>) {
    const baseRoute = contentFilter.baseRoute;
    const subjectRoute = contentFilter.subjectCodes.join('-');
    const typesRoute = contentFilter.contentTypeCodes.join('-');
    return `${baseRoute}#${subjectRoute}#${typesRoute}`;
  }

  ngOnDestroy() {
    this.stopWatching();
    this.meta.reset();
    super.ngOnDestroy();
  }

  protected loadMeetings(nextPage?: boolean) {
    if (this.meetings.loading) {
      return;
    }
    this.meetings.loading = true;
    this.loadingBar.fetchingStart(35);
    if (nextPage && this.meetings.pagination.page < this.meetings.pagination.totalPages) {
      this.meetings.pagination.page = this.meetings.pagination.page + 1;
    }
    this.stopWatching();

    this.meetingsService
      .getMeetings(this.meetings.filter, this.meetings.pagination, this.details)
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          if (nextPage) {
            response.results.map((m) => {
              if (!this.meetings.items.find((m1) => m1.id === m.id)) {
                this.meetings.items.push(m);
              }
            });
          } else {
            this.meetings.items = response.results;
          }
          this.meetings.pagination.total = response.total;
          this.meetings.pagination.totalPages = response.totalPages;
          this.meetings.pagination.page = response.page;
          this.meetings.loading = false;
          this.meetings.loaded = true;
          this.startWatching();
          this.loadingBar.fetchingComplete();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.meetings.loading = false;
          this.loadingBar.fetchingStop();
        },
      });
  }

  protected reloadMeeting(id) {
    this.loadingBar.fetchingStart(35);
    this.websocketService.stopWatching('meeting', id);
    this.meetingsService
      .getMeetingById(id)
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (meeting) => {
          const index = this.meetings.items.findIndex((m) => m.id === id);
          if (index >= 0) {
            this.meetings.items[index] = meeting;
            this.websocketService.startWatching('meeting', id);
            this.loadingBar.fetchingComplete();
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.loadingBar.fetchingStop();
        },
      });
  }

  // функция для трекинга списка, чтобы он не 'моргал'
  public trackByFn(index, meeting: Meeting): string {
    return meeting.id;
  }

  public paneTrackByFn(index: number, item: IContentPane): number {
    return item.order;
  }

  public nextPage() {
    this.loadMeetings(true);
  }

  private startWatching() {
    this.meetings.items.map((item) => {
      this.websocketService.startWatching('meeting', item.id);
    });
  }

  private stopWatching() {
    this.meetings.items.map((item) => {
      this.websocketService.stopWatching('meeting', item.id);
    });
  }

  private setupMeta() {
    this.meta.setTitle(['meetings.meta-title']);
    // TODO meta
  }
}
