<ng-template [ngIf]="!isMeVersion() && !isMicroText">
  <div class="meeting-review-widget-wrap">
    <app-meeting-review-widget></app-meeting-review-widget>
  </div>
</ng-template>

<div class="teacher-widget-wrap">
  <ng-template [ngIf]="isVisibleWelcomeWidget()">
    <app-pane [item]="paneWelcome"></app-pane>
  </ng-template>
  <ng-template [ngIf]="isVisibleUserWidget()">
    <app-user-widget></app-user-widget>
  </ng-template>

  <ng-template [ngIf]="isClassesPage() && !isMeVersion()">
    <ng-template [ngIf]="user_id">
      <app-profile-sidebar-widget [user_id]="user_id"></app-profile-sidebar-widget>
    </ng-template>
    <ng-template [ngIf]="school_id">
      <app-school-sidebar-widget></app-school-sidebar-widget>
    </ng-template>
  </ng-template>
</div>

<div class="new-widgets" *ngIf="!loading && !isVocabularyPage()">
  <div *ngIf="panes" class="panes">
    <section>
      <app-pane *ngFor="let pane of panes; trackBy: trackByFn"
                [item]="pane">
      </app-pane>
    </section>
  </div>
</div>
