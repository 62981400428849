import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { LibraryFilterWidgetModule } from '@app/library/library-filter-widget/library-filter-widget.module';
import { BestEntityWidgetModule } from '@core/components/ad/best-entity-widget/best-entity-widget.module';
import { PaginationModule } from '@core/components/pagination/pagination.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { TitleModule } from '@core/components/title/title.module';
import { TranslateModule } from '@ngx-translate/core';

// модули
import { AccountModule } from './account/account.module';
import { LibraryElementsModule } from './library/library-elements.module';
import { LibraryModule } from './library/library.module';

// import { ContentPageComponent } from './library/content-page.component';
import { LibraryPanesModule } from './library/panes/library-panes.module';
import { PanesComponent } from './library/panes/panes/panes.component';
import { SidebarsModule } from './library/sidebars/sidebars.module';
import { UniversalSidebarComponent } from './library/sidebars/universal-sidebar/universal-sidebar.component';

/**
 * Универсальный роут для страниц контентного типа
 *
 * @see AppRoutingModule
 */
const routes: Routes = [
  {
    data: { layout: 'MainLayoutComponent' },
    path: '',
    children: [
      {
        /**
         * теперь всё - это вывод с использованием настраиваемых панелей
         * К сожалению, мэтчер здесь бесполезен, всё разруливается в компоненте,
         * в том числе и страница 404
         */
        path: '**',
        component: PanesComponent,
      },
    ],
  },
  {
    path: '',
    outlet: 'right-sidebar-top',
    component: UniversalSidebarComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    TitleModule,
    LibraryModule,
    LibraryElementsModule,
    LibraryPanesModule,
    TranslateModule,
    PaginationModule,
    SpinnersModule,
    AccountModule,
    BestEntityWidgetModule,
    LibraryFilterWidgetModule,
    SidebarsModule,
  ],
  declarations: [
    // ContentPageComponent,
    // LibraryPageComponent,
    // LibrarySidebarComponent,
  ],
  exports: [],
})
export class ContentRoutingModule {}
