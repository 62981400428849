import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotificationsPageComponent } from '@app/notifications/notifications-page/notifications-page.component';
import { NoContentStubComponent } from '@core/components/no-content-stub/no-content-stub.component';
import { NotificationModule } from '@core/components/notification/notification.module';
import { TranslateModule } from '@ngx-translate/core';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: NotificationsPageComponent,
  },
];

@NgModule({
  imports: [CommonModule, TranslateModule, NotificationModule, RouterModule.forChild(routes), NoContentStubComponent],
  declarations: [NotificationsPageComponent],
})
export class NotificationsPageModule {}
