import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UrlSegment } from '@angular/router';
import { AccessActionEnum, AccountService, FeaturesService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { loadMe } from '@store/actions/profile.actions';
import { getMe } from '@store/reducers/profile.reducer';
import { FeatureEnum, IContentFilterBlock, Subject as BaseSubject, User } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

type ScheduleTab = 'calendar' | 'working-hours' | 'vacation';

interface IScheduleMenuItem extends IContentFilterBlock {
  code: ScheduleTab;
  url: string;
}

const menuItems: IScheduleMenuItem[] = [
  {
    order: 0,
    id: 0,
    title: 'schedule-page.tabs.calendar',
    code: 'calendar',
    url: '/schedule/calendar',
    type: 'subject',
    item: new BaseSubject(),
    total_count: 0,
  },
  {
    order: 1,
    id: 1,
    title: 'schedule-page.tabs.working-hours',
    code: 'working-hours',
    url: '/schedule/working-hours',
    type: 'subject',
    item: new BaseSubject(),
    total_count: 0,
  },
  {
    order: 2,
    id: 2,
    title: 'schedule-page.tabs.vacation',
    code: 'vacation',
    url: '/schedule/vacation',
    type: 'subject',
    item: new BaseSubject(),
    total_count: 0,
  },
];

@Component({
  selector: 'app-schedule-nav',
  templateUrl: './schedule-nav.component.html',
  styleUrls: ['./schedule-nav.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScheduleNavComponent extends ChangableComponent implements OnInit {
  public menuItems = menuItems;
  public activeTab: IScheduleMenuItem;
  private _me: User;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    protected readonly accountService: AccountService,
    protected readonly store: Store,
    protected readonly features: FeaturesService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (me) => {
          this._me = me;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadMe());
        }
      });
  }

  public link(item: IScheduleMenuItem): UrlSegment {
    return new UrlSegment(item.url, {});
  }

  public get menuList(): IScheduleMenuItem[] {
    return this.menuItems.filter((item) => this.isVisible(item));
  }

  public isVisible(item: IScheduleMenuItem): boolean {
    switch (item.code) {
      case 'calendar':
        return true;

      case 'working-hours':
        return (
          this.accountService.can(AccessActionEnum.canSetupWorkingSchedule, this._me) &&
          this.features.isAvailable(FeatureEnum.schedule_working_schedule, this._me)
        );

      case 'vacation':
        return (
          this.accountService.can(AccessActionEnum.canSetupVacation, this._me) &&
          this.features.isAvailable(FeatureEnum.schedule_vacation, this._me)
        );
    }
  }
}
