<div class="unit">
  <app-library-filter-widget></app-library-filter-widget>
</div>

<div class="unit">
  <app-best-entity-widget [displayMode]="'user_service'"></app-best-entity-widget>
</div>

<div class="unit">
  <app-best-entity-widget [displayMode]="'content'"></app-best-entity-widget>
</div>
