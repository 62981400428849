import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NavigationEnd, Router, UrlSegment } from '@angular/router';
import { vocabularyTabs } from '@app/vocabulary/vocabulary.variables';
import { AccountService, MetaService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadMe } from '@store/actions/profile.actions';
import { getMe } from '@store/reducers/profile.reducer';
import { IContentFilterBlock } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { filter } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-vocabulary',
  templateUrl: './vocabulary.component.html',
  styleUrls: ['./vocabulary.component.scss'],
})
export class VocabularyComponent extends ChangableComponent implements OnInit {
  public tabs: IContentFilterBlock[] = [];

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private router: Router,
    private store: Store,
    private meta: MetaService,
    protected readonly cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.updateMeta();

    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.updateMeta();
      });

    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (me) => {
          if (AccountService.isAsIfTeacher(me)) {
            vocabularyTabs[2].is_visible = true;
          }
          this.tabs = vocabularyTabs.filter((el) => el.is_visible);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadMe());
        },
      });
  }

  public routeLinkByItem(item: IContentFilterBlock): UrlSegment {
    return new UrlSegment(item.code, {});
  }

  public currentUrl() {
    return this.router.url;
  }

  public showTitle() {
    const urlArray = this.currentUrl().split('/');
    const code = urlArray[urlArray.length - 1];
    return code !== 'lists';
  }

  public updateMeta() {
    const path = this.currentUrl().substring(1);
    this.meta.setTitle([path + '.meta-title']);
  }

  public pageTitle() {
    const urlArray = this.currentUrl().split('/');
    return urlArray[urlArray.length - 1];
  }

  public trackByFn(index) {
    return index;
  }
}
