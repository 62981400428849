<div class="steps-widget">
  <div class="widget-block">
    <div class="img"></div>
    <p class="title">{{ 'profile-form.filled-all.title' | translate }}</p>
    <p class="hint">{{ 'profile-form.filled-all.description-1' | translate }}</p>
    <p class="hint">{{ 'profile-form.filled-all.description-2' | translate }}</p>
    <on-ui-button state="default"
                  class="primary"
                  label="profile-form.finish"
                  (click)="navigateTo()">
    </on-ui-button>
  </div>
</div>
