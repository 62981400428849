<section class="mobile-app">
  <div class="mobile-app-container">
    <div class="mobile-app-text-wrap">
      <div class="mobile-app-text">
        <div class="mobile-app-text-title">
          {{'job-landing.mobileapp.title' | translate}}

        </div>
        <div class="mobile-app-text-text">
          {{'job-landing.mobileapp.text' | translate}}

        </div>
      </div>

      <div class="mobile-app-text-btns-wrap">
        <a class="mobile-app-text-btn apple" [href]="apple" target="_blank" aria-label="AppStore">
          <img [width]="165" [height]="50" src="{{assetsUrl('/assets/images/landings/job/apple-app-btn.png')}}" alt="" />
        </a>
        <a class="mobile-app-text-btn android" [href]="android" target="_blank" aria-label="PlayMarket">
          <img [width]="165" [height]="50" src="{{assetsUrl('/assets/images/landings/job/android-app-btn.png')}}" alt="" />
        </a>
      </div>
    </div>
    <div class="mobile-app-bg"></div>
  </div>
</section>
