import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnInit, Optional } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LibraryRouter } from '@app/models/library.router';
import { IContentBlock } from '@core/services/interfaces';
import { DestroyableComponent } from '@models/destroyable.component';
import { User, Content, IFindContentFilter, ContentDetailsType } from 'lingo2-models';
import { takeUntil } from 'rxjs/operators';
import { RESPONSE } from '../../../express.tokens';
import { AccountService, ContentService, RequestService } from '../../core/services';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

type ContentBlockType = IContentBlock<Content, IFindContentFilter>;

@Component({
  selector: 'app-content-slug-redirect-page',
  templateUrl: './content-slug-redirect-page.component.html',
  styleUrls: ['./content-slug-redirect-page.component.scss'],
})
export class ContentSlugRedirectPageComponent extends DestroyableComponent implements OnInit {
  public loading = true;
  public loaded = false;
  public error = '';
  public related: ContentBlockType = {
    items: [],
    loading: false,
    loaded: false,
    filter: {},
    pagination: {
      page: 1,
      pageSize: 10,
      total: 0,
      totalPages: 1,
    },
  };
  private user_slug: string;
  private content_slug: string;
  private content: Content;
  private author: User;
  private contentRoute = LibraryRouter.contentRouteUniversal;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private accountService: AccountService,
    private contentService: ContentService,
    private route: ActivatedRoute,
    private router: Router,
    private request: RequestService,
    @Inject(DOCUMENT) private document: any,
    @Optional() @Inject(RESPONSE) private response: any,
  ) {
    super();
  }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (params) => {
        this.user_slug = params.get('user');
        this.content_slug = params.get('slug');
        this.loadContent();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      }
    });
  }

  private loadContent() {
    if (!this.content_slug) {
      return;
    }
    this.loading = true;
    const details: ContentDetailsType[] = ['id', 'title', 'author:sm', 'slug', 'type', 'language', 'subject'];
    const observable = this.user_slug
      ? this.contentService.getContentByAuthorAndSlug(this.user_slug, this.content_slug, false, details)
      : this.contentService.getContentBySlug(this.content_slug, false, details);

    observable.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (item: Content) => {
        this.content = item;
        this.loading = false;
        this.loaded = true;
        this.redirectToContent();
      },
      error: (error: any) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.loading = false;
        this.loadOtherContent();
      }
    });
  }

  protected loadOtherContent() {
    // найти автора, показать что есть другие материалы
    this.accountService
      .getUserBySlug(this.user_slug, ['id', 'slug'])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (user) => {
          this.author = user;
          this.loadContentByAuthor();
        },
        error: (error: unknown) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          if (this.response) {
            this.response.statusCode = 404;
            this.response.statusMessage = 'Page Not Found';
          }
        }
      });
  }

  private redirectToContent() {
    if (!this.content) {
      return;
    }

    const contentRoute = this.contentRoute(this.content);
    if (this.response) {
      const contentUrl = contentRoute;
      this.response.status(303);
      this.response.setHeader('Location', contentUrl);
    } else {
      this.router.navigateByUrl(contentRoute).then(() => true);
    }
  }

  private loadContentByAuthor() {
    const accountRoute = AccountService.accountRoute(this.author);
    if (this.response) {
      const authorUrl = this.request.host + this.router.createUrlTree(accountRoute).toString();
      this.response.status(303);
      this.response.setHeader('Location', authorUrl);
    } else {
      this.router.navigate(accountRoute).then(() => true);
    }
  }
}
