/* eslint-disable max-len */
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InviteToMeetingModule } from '@app/account/shared/invite-to-meeting/invite-to-meeting.module';
import { PaymentCheckoutWizardModule } from '@app/account/shared/payment-checkout-wizard/payment-checkout-wizard.module';
import { MeetingFormWrapperModule } from '@app/core/components/meeting-form-wrapper/meeting-form-wrapper.module';
import { MeetingReviewFormComponent } from '@app/library/panes/meeting-review-form/meeting-review-form.component';
import { MeetingJoinSidebarComponent } from '@app/library/sidebars/meeting-join-sidebar/meeting-join-sidebar.component';
import { SidebarsModule } from '@app/library/sidebars/sidebars.module';
import { ContentNotFoundModule } from '@app/shared';
import { UserCardMiniModule } from '@app/users/user-card-mini/user-card-mini.module';
import { AlertModule } from '@core/components/alert/alert.module';
import { AlertSingleModule } from '@core/components/alert-single/alert-single.module';
import { MeetingsCardModule } from '@core/components/meeting-card/meetings-card.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { UserServiceCard2Module } from '@core/components/service-card/user-service-card-2.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { TitleModule } from '@core/components/title/title.module';
import { UserServiceContractCardModule } from '@core/components/user-service-contract-card';
import { CoreModule } from '@core/core.module';
import { ZonedDatePipe } from '@core/pipes/zoned-time-to-utc.pipe';
import { QRCodeModule } from '@le2xx/angularx-qrcode';
import { TranslateModule } from '@ngx-translate/core';
import { MeetingCheckoutWizardDialogModule } from '@shared/checkout-wizards/meeting-checkout-wizard-dialog/meeting-checkout-wizard-dialog.module';
import { IconComponent } from '@shared/icon/icon.component';
import { RatingComponent } from '@shared/rating/rating.component';
import { SkeletonComponent } from '@shared/skeleton/skeleton.component';
import { FormatPipeModule } from 'lingo2-ngx-date-fns';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { ClipboardModule } from 'ngx-clipboard';
import { OnUiButtonModule, OnUiCardMenuModule, OnUiCoverModule } from 'onclass-ui';
import { MeetingJoinPageComponent } from './meeting-join-page/meeting-join-page.component';
import { MeetingsModule } from './meetings.module';
import { UserServiceCardFullModule } from './user-service-card-full/user-service-card-full.module';

/**
 * Этот роут срабатывает после ленивой загрузки модуля по роуту /classes/join
 *
 * @see AppRoutingModule
 */
const routes: Routes = [
  {
    // СТАРЫЙ роут для присоединения к классу и открытию конференции (для старых нотификаций)
    path: ':user/:slug',
    component: MeetingJoinPageComponent,
  },
  {
    // роут для присоединения к классу и открытию конференции
    path: ':slug',
    component: MeetingJoinPageComponent,
  },
  {
    path: '',
    outlet: 'right-sidebar-top',
    component: MeetingJoinSidebarComponent,
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    CoreModule,
    TranslateModule,
    SpinnersModule,
    MeetingsModule,
    ModalModule,
    AlertSingleModule,
    QRCodeModule,
    MobileModalModule,
    LazyLoadImageModule,
    UserServiceCard2Module,
    OnUiCoverModule,
    MeetingsCardModule,
    FormatPipeModule,
    OnUiButtonModule,
    OnUiCardMenuModule,
    ClipboardModule,
    InviteToMeetingModule,
    ContentNotFoundModule,
    MeetingFormWrapperModule,
    PaymentCheckoutWizardModule,
    TitleModule,
    UserCardMiniModule,
    SidebarsModule,
    MeetingReviewFormComponent,
    MeetingCheckoutWizardDialogModule,
    UserServiceContractCardModule,
    UserServiceCardFullModule,
    RatingComponent,
    AlertModule,
    SkeletonComponent,
    ZonedDatePipe,
    IconComponent,
  ],
  declarations: [MeetingJoinPageComponent],
  providers: [],
  exports: [],
})
export class MeetingJoinModule {}
