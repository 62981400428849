import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ImageCropModule } from '@app/collections-constructor/components/image-crop/image-crop.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';

import { UserpicComponent } from './userpic.component';

@NgModule({
  declarations: [UserpicComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    DropzoneModule,
    SpinnersModule,
    ImageCropModule,
    IconComponent,
  ],
  exports: [UserpicComponent],
})
export class UserpicModule {}
