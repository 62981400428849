import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { getMe } from '@app/store/reducers/profile.reducer';
import { ContentService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { Lesson } from 'lingo2-models';
import { of } from 'rxjs';
import { switchMap, map, catchError, withLatestFrom } from 'rxjs/operators';

import { loadLibraryContent, loadLibraryContentSuccess, requestFail } from './library.actions';
import { getLibraryFilter, getLibraryPagination } from './library.selectors';

@Injectable()
export class LibraryEffects {
  constructor(
    private actions$: Actions,
    private contentService: ContentService,
    private router: Router,
    private store: Store,
  ) {}

  // Library

  loadLibraryContent$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadLibraryContent),
      withLatestFrom(this.store.select(getLibraryFilter)),
      withLatestFrom(this.store.select(getLibraryPagination)),
      withLatestFrom(this.store.select(getMe)),
      switchMap(([[[, filter], pagination], me]) => {
        filter = { ...filter, author_id: me.id };
        // TODO: refactor
        if (filter.content_type && !(filter.content_type as any[]).length) {
          delete filter.content_type;
        }
        return this.contentService.getContent(filter, pagination, true).pipe(
          map((response) =>
            loadLibraryContentSuccess({
              items: response.results as Lesson[],
              pagination: response.pagination,
            }),
          ),
          catchError((error: any) => of(requestFail({ error: error.message }))),
        );
      }),
    ),
  );
}
