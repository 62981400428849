import { isPlatformBrowser } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnDestroy, OnInit, PLATFORM_ID } from '@angular/core';
import { AccountService, ContextService } from '@core/services';
import { Store } from '@ngrx/store';
import { User, UserSegmentEnum } from 'lingo2-models';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {DestroyableComponent} from "@models/destroyable.component";
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-teachers-sidebar',
  templateUrl: './teachers-sidebar.component.html',
  styleUrls: ['./teachers-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TeachersSidebarComponent extends DestroyableComponent implements OnInit, OnDestroy {
  public me: User;

  private ngUnsubscribe = new Subject();

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private contextService: ContextService,
    private readonly store: Store,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {
    super();
  }

  ngOnInit() {
    this.contextService.me$.pipe(takeUntil(this.ngUnsubscribe)).subscribe({
      next: (me) => (this.me = me),
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      }
    });
  }

  ngOnDestroy() {
    this.ngUnsubscribe.next(true);
    this.ngUnsubscribe.complete();
  }

  public get isAsIfTeacher(): boolean {
    return (
      this.me && !AccountService.isTeacher(this.me) && AccountService.hasSegment(this.me, UserSegmentEnum.as_if_teacher)
    );
  }
}
