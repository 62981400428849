import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { SafeResourceUrl } from '@angular/platform-browser';
import { loadMe } from '@app/store/actions/profile.actions';
import {
  AuthService,
  BillingV2Service,
  FeaturesService,
  FilesService,
  PlatformService,
  SchoolsService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { LibraryRouter } from '@models/library.router';
import { Store } from '@ngrx/store';
import { ApplicationDialogsService } from '@shared/application-dialogs';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { EWidgetStatType } from '@store/models';
import { getMe } from '@store/reducers/profile.reducer';
import { CSchool, ITeachingSubject, User, UserService, UserStatusEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiCover } from 'onclass-ui';
import { combineLatest, Observable, of, timer } from 'rxjs';
import { map, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-school-sidebar-widget',
  templateUrl: './school-sidebar-widget.component.html',
  styleUrls: ['./school-sidebar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolSidebarWidgetComponent extends ChangableComponent implements OnInit {
  @Input() public withoutPrice: boolean;

  public statsList = [];
  public eTypeStats = EWidgetStatType;
  public isTeacher = false;
  public userServiceCardFullInModal: boolean;
  public openedUserService: UserService;
  public surfaceBlockDisplay: boolean;
  public subjectId: string;
  public teachingSubject: ITeachingSubject;
  public isMediaPrepared: boolean;
  public safeMediaUrl: SafeResourceUrl;
  public canSignup$: Observable<boolean>;

  private _me: User;
  private _school: CSchool;
  private isGuest = true;
  private originalUrl: string;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private location: Location,
    private authService: AuthService,
    private schoolService: SchoolsService,
    private billingV2Service: BillingV2Service,
    private store: Store,
    protected dialogsService: ApplicationDialogsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);

    this.canSignup$ = this.isBrowser
      ? timer(0, 60 * 1000).pipe(map(() => this.latest_applicable_date?.getTime() > Date.now()))
      : of(true).pipe(map(() => this.latest_applicable_date?.getTime() > Date.now()));
  }

  ngOnInit() {
    if (this.isBrowser) {
      combineLatest([this.store.select(getMe), this.schoolService.currentSchool$])
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: ([me, school]) => {
            this._me = me;
            this._school = school;
            this.statsList = this.initStats();
            this.isGuest = me?.status === UserStatusEnum.guest;
            this.detectChanges();
          },
          error: (error) => {
            this.store.dispatch(loadMe());
            this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          },
        });
    }
  }

  public get stats() {
    return this._school?.stats;
  }

  public openCheckoutModal() {
    const onAuthComplete = () => {
      this.dialogsService.openUserServiceCheckoutWizard({
        teacherId: this._me?.id,
      });
      this.detectChanges();
    };

    if (this.isGuest) {
      this.authService.showAuthModal(onAuthComplete, {
        caller: 'app-school-sidebar-widget',
        reason: 'purchase service',
      });
    } else {
      onAuthComplete();
    }
  }

  public getFormattedAmount(): Observable<string> {
    return this.billingV2Service.formatAmount$(this.minPriceTier);
  }

  public get minPriceTier(): number {
    return 0;
  }

  public onToggleUserServiceInModal(service: UserService = null) {
    this.openedUserService = service;
    this.userServiceCardFullInModal = !this.userServiceCardFullInModal;
    this.setAndResetUrlForUserService();
    this.detectChanges();
  }

  public onIinViewportChange(e: boolean) {
    this.surfaceBlockDisplay = e;
    this.detectChanges();
  }

  public get latest_applicable_date(): Date {
    return null;
  }

  private setAndResetUrlForUserService() {
    const url = LibraryRouter.userServiceRouteUniversal(this.openedUserService);
    const currentUrl = this.location.path(false);
    if (this.userServiceCardFullInModal) {
      if (currentUrl !== url) {
        if (!this.originalUrl) {
          this.originalUrl = currentUrl;
        }
        this.location.replaceState(url);
      }
    } else {
      if (this.originalUrl) {
        this.location.replaceState(this.originalUrl);
        this.originalUrl = null;
      }
    }
  }

  public trackByFn(index) {
    return index;
  }

  public get cover(): OnUiCover {
    return {
      form: 'circle',
      type: 'cover',
      img: FilesService.getFileUrlBySize(this._school?.cover_id),
    };
  }

  private initStats() {
    const stats = [];
    stats.push({
      type: EWidgetStatType.Separator,
    });
    stats.push({
      label: 'school-stats.students',
      type: EWidgetStatType.Value,
      value: this.stats?.participant_count,
    });
    stats.push({
      label: 'school-stats.classes',
      type: EWidgetStatType.Value,
      value: this.stats?.meeting_count,
    });
    stats.push({
      className: 'rating',
      label: 'school-stats.rating',
      type: EWidgetStatType.Rating,
      value: this._school?.rating ? this._school?.rating : '0',
    });
    return stats;
  }
}
