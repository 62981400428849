import { isPlatformBrowser } from '@angular/common';
import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { ContentService } from '@core/services';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { map } from 'rxjs/operators';

import { loadUIFilters, loadUIFiltersSuccess, saveUIFilters, saveUIFiltersSuccess } from './sidebar.actions';
import { SidebarState } from './sidebar.reducer';

const localStorageSidebarKey = 'APP__CNSTRCTR_FILTER';
@Injectable()
export class SidebarEffects {
  constructor(
    private actions$: Actions,
    private contentService: ContentService,
    private router: Router,
    private store: Store,
    @Inject(PLATFORM_ID) private platformId: object,
  ) {}

  protected get isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  protected getStorageByKey() {
    return localStorage.getItem(localStorageSidebarKey) !== null
      ? JSON.parse(localStorage.getItem(localStorageSidebarKey))
      : {};
  }

  protected setStorageState(state: Partial<SidebarState>) {
    const storage = this.getStorageByKey();
    const currentState = storage.state ? storage.state : {};
    localStorage.setItem(localStorageSidebarKey, JSON.stringify({ ...storage, state: { ...currentState, ...state } }));
  }

  loadUIFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loadUIFilters),
      map(() => {
        if (this.isBrowser && localStorage) {
          const storage = this.getStorageByKey();
          return storage.state || {};
        }
        return {};
      }),
      map((sidebar) =>
        loadUIFiltersSuccess({
          sidebar,
        }),
      ),
    ),
  );

  saveUIFilters$ = createEffect(() =>
    this.actions$.pipe(
      ofType(saveUIFilters),
      map(({ sidebar }) => {
        if (this.isBrowser && localStorage) {
          this.setStorageState(sidebar);
        }
        return sidebar;
      }),
      map((sidebar) =>
        saveUIFiltersSuccess({
          sidebar,
        }),
      ),
    ),
  );
}
