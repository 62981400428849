<div class="card" [class.card_mobile]="deviceService.isMobile()">
  <div class="card__content" [formGroup]="form" novalidate autocomplete="off">

    <p class="label-title">{{ 'profile-form.security.contact-information' | translate }}</p>
    <p class="hint-title">{{ 'profile-form.security.contact-information-hint' | translate }}</p>
    <app-form-field class="field"
                    [class.mobile-changed]="isMobileChanged && !required"
                    [control]="form.controls.mobile_phone"
                    [label]="'profile-form.security.phone' | translate">
      <div class="input-group">
        <div class="input-wrap">
                       <ngx-intl-phone-input
                       [cssClass]="'custom'"
                       [searchCountryFlag]="true"
                       [exclusionarySearch]="true"
                       [enablePlaceholder]="true"
                       [localization]="currentLang" [searchCountryPlaceholder]="'profile-form.select-country' | translate"
                       [includeDialCode]="true"
                       [selectFirstCountry]="false"
                       [separateDialCode]="false"
                       [phoneValidation]="true" [useMask]="true" [showMaskTyped]="true"
                       [selectedCountryISO]="myCountry"
                       [inputId]="'mobile-phone-wrap'"
                       [formControlName]="'mobile_phone'"
                     ></ngx-intl-phone-input>
        </div>

        <ng-container *ngIf="value.mobile_phone_confirmed; else mobile_phone_not_confirmed">
          <app-verification-mark [passed]="value.mobile_phone_confirmed"></app-verification-mark>

          <ng-container *ngIf="form.get('mobile_phone').disabled">
            <span class="action"
                  data-test="change-mobile"
                  (click)="onEnableMobilePhone()">
              {{ 'profile-form.security.change' | translate }}
            </span>
          </ng-container>

          <ng-container *ngIf="form.get('mobile_phone').enabled">
            <loading-line *ngIf="showMobileLoader" height="40px" width="100%" class="action"></loading-line>
            <span class="action"
                  *ngIf="!showMobileLoader"
                  data-test="confirm-mobile"
                  [class.disabled]="form.get('mobile_phone').invalid"
                  (click)="onToggleConfirmModal('mobile_phone')">
              {{ 'profile-form.security.confirm' | translate }}
            </span>
          </ng-container>

          <ng-container *ngIf="form.get('mobile_phone').enabled">
            <span *ngIf="!showMobileLoader" class="action"
                  data-test="confirm-cancel"
                  (click)="onDisableMobilePhone()">
              {{ 'profile-form.security.cancel' | translate }}
            </span>
          </ng-container>
        </ng-container>

        <ng-template #mobile_phone_not_confirmed>
          <span class="action" (click)="onToggleConfirmModal('mobile_phone')">
            {{ 'profile-form.security.confirm' | translate }}
          </span>
        </ng-template>
      </div>
      <p class="error-hint" *ngIf="isMobileChanged && !required && form.get('mobile_phone').enabled && !form.controls?.mobile_phone.value">{{'error.confirm' | translate}}</p>
    </app-form-field>

    <app-form-field class="field"
                    [class.email-changed]="isEmailChanged && !required"
                    [control]="form.controls.email"
                    [label]="'profile-form.security.email' | translate">
      <div class="input-group">
        <div class="input-wrap">
          <on-ui-input formControlName="email"
                       data-test="email"
                       [placeholder]="'profile-form.security.email' | translate"></on-ui-input>
        </div>

        <ng-container *ngIf="value.email_confirmed; else email_not_confirmed">
          <app-verification-mark [passed]="email.value_confirmed"></app-verification-mark>

          <ng-container *ngIf="form.get('email').disabled">
            <span class="action" (click)="onEnableEmail()"
                  data-test="change-email">
              {{ 'profile-form.security.change' | translate }}
            </span>
          </ng-container>

          <ng-container *ngIf="form.get('email').enabled">
            <span class="action"
                  data-test="confirm-email"
                  [class.disabled]="form.get('email').invalid"
                  (click)="onToggleConfirmModal('email')">
              {{ 'profile-form.security.confirm' | translate }}
            </span>
          </ng-container>

          <ng-container *ngIf="form.get('email').enabled">
            <span class="action" (click)="onDisableEmail()"
                  data-test="cancel-email">
              {{ 'profile-form.security.cancel' | translate }}
            </span>
          </ng-container>
        </ng-container>

        <ng-template #email_not_confirmed>
          <span class="action" (click)="onToggleConfirmModal('email')">
            {{ 'profile-form.security.confirm' | translate }}
          </span>
        </ng-template>
      </div>
      <p class="error-hint" *ngIf="isEmailChanged && !required && form.controls?.email.value">{{'error.confirm' | translate}}</p>
      <p class="error-hint" *ngIf="emailTakenText">{{'auth.errors.signup.email' | translate}}</p>
    </app-form-field>
  </div>

  <div class="card__content two-factor-authentication">
    <p class="label-title">{{ 'two-factor-auth.title' | translate }}
      <span class="green" *ngIf="is2FA">{{ 'two-factor-auth.status.activated' | translate }}</span>
      <span *ngIf="!is2FA">{{ 'two-factor-auth.status.not-activated' | translate }}</span>
    </p>
    <p class="hint-title">{{ 'two-factor-auth.hint' | translate }}</p>
    <div *ngIf="is2FA" class="col-210">
      <on-ui-form-field-error [error]="isInvalid('password') ? (errorField === 'password' ? 'two-factor-auth.error.password' : 'two-factor-auth.error.required') : null">
        <on-ui-input [(ngModel)]="passwordFor2FA"
                     [placeholder]="'two-factor-auth.step-4.field' | translate"
                     [error]="isInvalid('password')">
        </on-ui-input>
      </on-ui-form-field-error>
    </div>
    <on-ui-button class="{{is2FA ? 'danger' : 'primary'}} outline"
                  [label]="is2FA ? 'two-factor-auth.action.disable' : 'two-factor-auth.action.activate'"
                  (click)="toggle2FAModal()">
    </on-ui-button>
  </div>

  <div class="card__actions">
    <on-ui-button class="primary outline margin-right-20"
                  label="profile-form.back"
                  data-test="profile-back"
                  (click)="navigateBack()">
    </on-ui-button>
    <on-ui-button [disabled]="form.invalid"
                  [state]="saveState"
                  class="primary"
                  label="profile-form.next"
                  data-test="profile-next"
                  (onclick)="navigateNextStep()">
    </on-ui-button>
  </div>
</div>

<ng-template #confirmModal let-confirmMode="confirmMode">
  <section class="confirm-modal">
    <h2 class="title">{{ 'profile-form.confirm-modal.title' | translate }}</h2>

    <ng-template [ngIf]="confirmMode === 'email'">
      <p class="subtitle">{{ 'profile-form.confirm-modal.subtitle' | translate }}</p>
    </ng-template>

    <ng-template [ngIf]="confirmMode === 'mobile_phone'">
      <p class="subtitle">{{ 'profile-form.confirm-modal.subtitle2' | translate: { term: getMobilePhoneText } }}</p>
    </ng-template>

    <ng-otp-input [config]="{allowNumbersOnly: true,
    length: codeLength,
    inputClass: statusCode === 'invalid' ? 'is-error' : (statusCode === 'valid' ? 'is-success': '')}"
                  (onInputChange)="onOtpChange($event)">
    </ng-otp-input>

    <ng-container *ngIf="statusCode === 'invalid'">
      <p class="error-message">{{ 'auth.signup.incorrect-code' | translate }}</p>
    </ng-container>

    <ng-template [ngIf]="confirmMode === 'email' && !showSendAgainButton">
      <p class="text">{{ 'profile-form.confirm-modal.text' | translate: { term: confirmModalOpenTimer } }}</p>
    </ng-template>

    <ng-template [ngIf]="confirmMode === 'mobile_phone' && !showSendAgainButton">
      <p class="text">{{ 'profile-form.confirm-modal.text2' | translate: { term: confirmModalOpenTimer } }}</p>
    </ng-template>

    <ng-template [ngIf]="showSendAgainButton">
      <on-ui-button label="profile-form.confirm-modal.again" (onclick)="onToggleConfirmModal(confirmMode)"></on-ui-button>
    </ng-template>
  </section>
</ng-template>

<app-modal *ngIf="confirmModalOpen"
           [closeInside]="deviceService.isMobile()"
           [canClose]="deviceService.isMobile()"
           [middle]="!deviceService.isMobile()"
           [narrow2]="deviceService.isMobile()"
           [class.confirmError]="statusCode === 'invalid'"
           (closed)="stopTimer()">
  <app-modal-body>
    <ng-container *ngTemplateOutlet="confirmModal; context: { confirmMode: confirmMode }"></ng-container>
  </app-modal-body>
</app-modal>

<app-alert *ngIf="showWarningAlert" type="info" [btnPosition]="'center'">
  <ng-container alert-icon>
    <app-icon width="36" height="36" file="emoji" icon="think"></app-icon>
  </ng-container>
  <ng-container alert-title>{{ 'profile-form.security.alert-info-title.' + warningMessage | translate }}</ng-container>
  <ng-container alert-body>{{ 'profile-form.security.alert-info-message.' + warningMessage | translate }}</ng-container>
  <ng-container alert-buttons>
    <on-ui-button label="Ок"
                  class="primary outline center"
                  (onclick)="showWarningAlert = false">
    </on-ui-button>
  </ng-container>
</app-alert>

<app-modal *ngIf="is2FAModalOpened && deviceService.isDesktop()" (closed)="toggle2FAModal()">
  <app-modal-body>
    <app-two-factor-authentication (saved)="on2FASaved($event)"></app-two-factor-authentication>
  </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="is2FAModalOpened && !deviceService.isDesktop()" (closed)="toggle2FAModal()">
  <app-mobile-modal-body>
    <app-two-factor-authentication (saved)="on2FASaved($event)"></app-two-factor-authentication>
  </app-mobile-modal-body>
</app-mobile-modal>
