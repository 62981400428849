import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { AdvantagesCommonComponent } from '@app/landings/job/components/common/advantages/advantages.component';
import { MobileAppCommonComponent } from '@app/landings/job/components/common/mobile-app/mobile-app.component';
import { PromoFooterCommonComponent } from '@app/landings/job/components/common/promo-footer/promo-footer.component';
import { ReviewsCommonComponent } from '@app/landings/job/components/common/reviews/reviews.component';
import { JobFooterComponent } from '@app/landings/job/components/job/job-footer/job-footer.component';
import { LanguageSelectorModule } from '@core/components/language-selector/language-selector.module';
import { MainLayoutModule } from '@core/layouts/main/main-layout.module';
import { LeftSidebarComponent } from '@core/layouts/main/sidebar/left-sidebar.component';
import { DragScrollComponent, DragScrollItemDirective } from '@le2xx/ngx-drag-scroll';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { PaywallWrapperModule } from 'lingo2-forms';
import { InViewportModule } from 'ng-in-viewport';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { OnUiButtonModule } from 'onclass-ui';
import { CommonLandingComponent } from './common-landing/common-landing.component';
import { ChooseRoleComponent } from './components/common/choose-role/choose-role.component';
import { FooterCommonComponent } from './components/common/footer-common/footer-common.component';
import { HowStudyComponent } from './components/common/how-study/how-study.component';
import { MobileFooterComponent } from './components/common/mobile-footer/mobile-footer.component';
import { OnclassItComponent } from './components/common/onclass-it/onclass-it.component';
import { PromoMeComponent } from './components/common/promo-me/promo-me.component';
import { TeachWithOnclassComponent } from './components/common/teach-with-onclass/teach-with-onclass.component';
import { TopPromoComponent } from './components/common/top-promo/top-promo.component';
import { WhoCanComponent } from './components/common/who-can/who-can.component';
import { AdvantagesComponent } from './components/job/advantages/advantages.component';
import { DigitalContentComponent } from './components/job/digital-content/digital-content.component';
import { HowgetnewComponent } from './components/job/howgetnew/howgetnew.component';
import { InstrumentsComponent } from './components/job/instruments/instruments.component';
import { MobileAppComponent } from './components/job/mobile-app/mobile-app.component';
import { OpportunitiesComponent } from './components/job/opportunities/opportunities.component';
import { PromoFooterComponent } from './components/job/promo-footer/promo-footer.component';
import { PromoblueComponent } from './components/job/promoblue/promoblue.component';
import { ReviewsComponent } from './components/job/reviews/reviews.component';
import { TeacherComponent } from './components/job/teacher/teacher.component';
import { WhoisneedComponent } from './components/job/whoisneed/whoisneed.component';
import { WhybetterComponent } from './components/job/whybetter/whybetter.component';
import { JobPageComponent } from './job-page.component';
import { HeaderComponent } from "../../core/header/header.component";

const routes: Routes = [
  {
    path: '',
    component: CommonLandingComponent,
  },
  {
    path: 'study',
    component: CommonLandingComponent, // Study using Onclass
  },
  {
    path: 'job',
    component: JobPageComponent, // Teach using Onclass
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    FormsModule,
    LazyLoadImageModule,
    ScrollingModule,
    TranslateModule,
    LanguageSelectorModule,
    InViewportModule,
    MainLayoutModule,
    OnUiButtonModule,
    DragScrollComponent,
    DragScrollItemDirective,
    ChooseRoleComponent,
    PaywallWrapperModule,
    IconComponent,
    LeftSidebarComponent,
    HeaderComponent
],
  declarations: [
    JobPageComponent,
    CommonLandingComponent,
    TeacherComponent,
    AdvantagesComponent,
    WhoisneedComponent,
    OpportunitiesComponent,
    WhybetterComponent,
    InstrumentsComponent,
    HowgetnewComponent,
    PromoblueComponent,
    DigitalContentComponent,
    MobileAppComponent,
    ReviewsComponent,
    PromoFooterComponent,
    FooterCommonComponent,
    PromoFooterCommonComponent,
    ReviewsCommonComponent,
    MobileAppCommonComponent,
    TeachWithOnclassComponent,
    OnclassItComponent,
    HowStudyComponent,
    WhoCanComponent,
    AdvantagesCommonComponent,
    TopPromoComponent,
    JobFooterComponent,
    MobileFooterComponent,
    PromoMeComponent,
  ],
})
export class JobModule {}
