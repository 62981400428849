import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InviteToMeetingModule } from '@app/account/shared/invite-to-meeting/invite-to-meeting.module';
import { MeetingFormWrapperModule } from '@app/core/components/meeting-form-wrapper/meeting-form-wrapper.module';
import { RulesComponent } from '@app/landings/article/rules/rules.component';
import { AlertModule } from '@core/components/alert/alert.module';
import { HorizontalScrollMenuModule } from '@core/components/horizontal-scroll-menu/horizontal-scroll-menu.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { ZonedDatePipe } from '@core/pipes/zoned-time-to-utc.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { TimezoneComponent } from '@shared/timezone/timezone.component';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { FormatPipeModule } from 'lingo2-ngx-date-fns';
import {
  OnUiButtonModule,
  OnUiCardMenuModule,
  OnUiCheckboxModule,
  OnUiCoverModule,
  OnUiDateModule,
  OnUiPopoverModule,
} from 'onclass-ui';
import { CheckboxEventCardComponent } from './checkbox-event-card/checkbox-event-card.component';
import { EventPopoverComponent } from './event-popover/event-popover.component';
import { MyCalendarComponent } from './my-calendar/my-calendar.component';
import { SchoolCalendarComponent } from './school-calendar/school-calendar.component';
import { SchoolTeacherCalendarComponent } from './school-teacher-calendar/school-teacher-calendar.component';
import { ScrollSelectorComponent } from './scroll-selector/scroll-selector.component';
import { UserServiceMultiSelectorComponent } from './user-service-multi-selector/user-service-multi-selector.component';

@NgModule({
  imports: [
    CommonModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    RouterModule,
    FormatPipeModule,
    ModalModule,
    MobileModalModule,
    MeetingFormWrapperModule,
    TranslateModule,
    ReactiveFormsModule,
    InviteToMeetingModule,
    HorizontalScrollMenuModule,
    OnUiCoverModule,
    OnUiCardMenuModule,
    OnUiPopoverModule,
    OnUiCheckboxModule,
    OnUiDateModule,
    OnUiButtonModule,
    RulesComponent,
    TimezoneComponent,
    AlertModule,
    ZonedDatePipe,
    IconComponent,
  ],
  declarations: [
    MyCalendarComponent,
    SchoolCalendarComponent,
    SchoolTeacherCalendarComponent,
    EventPopoverComponent,
    CheckboxEventCardComponent,
    UserServiceMultiSelectorComponent,
    ScrollSelectorComponent,
  ],
  exports: [MyCalendarComponent, SchoolCalendarComponent, SchoolTeacherCalendarComponent],
})
export class EventsCalendarModule {}
