<div class="vocabulary-form-added" [formGroup]="form">
  <div class="vocabulary-form-added__left" [class.on-focus]="isFocusLeft">
    <input class="vocabulary-form-added__input" autofocus formControlName="term"
           [placeholder]="'my-vocabulary.word' | translate" (focus)="onFocusLeft()" (blur)="onFocusLeft()">
  </div>
  <div class="vocabulary-form-added__right" [class.on-focus]="isFocusRight">
    <input class="vocabulary-form-added__input" formControlName="definition"
           [placeholder]="'my-vocabulary.translation' | translate" (focus)="onFocusRight()" (blur)="onFocusRight()">
    <app-dots-loader *ngIf="loading" class="loading-dots" ></app-dots-loader>
    <p *ngIf="!deviceService.isMobile()" class="add-word" (click)="onAddWords()" [class.disabled]="form.invalid || loading">
      {{'my-vocabulary.action.save' | translate}}
    </p>
    <div *ngIf="deviceService.isMobile() && !loading && !form.invalid" class="checkbox save-holder" (click)="onAddWords()">
      <on-ui-button [class]="'secondary'"
                        [label]="'my-vocabulary.action.save'"
                        (onclick)="onAddWords()"></on-ui-button>
    </div>
  </div>
</div>
