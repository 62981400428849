import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TranslationCacheService {
  private translationCache = new Map<string, BehaviorSubject<object>>();
  private lastLoadedLang: string | null = null;

  public getCache(): Map<string, BehaviorSubject<object>> {
    return this.translationCache;
  }

  public getTranslationSubject(lang: string): BehaviorSubject<object> | undefined {
    return this.translationCache.get(lang);
  }

  public setTranslationSubject(lang: string, subject: BehaviorSubject<object>): void {
    this.translationCache.set(lang, subject);
  }

  public clearCache(): void {
    this.translationCache.clear();
  }

  public getLastLoadedLang(): string | null {
    return this.lastLoadedLang;
  }

  public setLastLoadedLang(lang: string): void {
    this.lastLoadedLang = lang;
  }
}
