import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// страницы

// компоненты
import { ClassroomsSidebarComponent } from './components/classrooms-sidebar/classrooms-sidebar.component';

/**
 * Этот роут срабатывает после ленивой загрузки модуля по роуту /classrooms
 *
 * @see AppRoutingModule
 */
const routes: Routes = [
  {
    // страница для отдельного учебного класса
    path: ':slug',
    loadChildren: () => import('./classroom-page/classroom-page.module').then((m) => m.ClassroomPageModule),
    data: { preload: true },
  },
  {
    path: '',
    outlet: 'right-sidebar-top',
    component: ClassroomsSidebarComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ClassroomsRoutingModule {}
