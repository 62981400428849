import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ChangableComponent } from '@models/changable.component';
import { PortalArticleType } from '@models/lingo2-models/content';
import { IFindArticlesFilter, Language } from 'lingo2-models';
import { Observable } from 'rxjs';
import { switchMap, tap, filter, takeUntil } from 'rxjs/operators';
import { ArticleService, ContextService, LanguageService, MetaService } from '../../core/services';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-article-page',
  templateUrl: './article-page.component.html',
  styleUrls: ['./article-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ArticlePageComponent extends ChangableComponent implements OnInit, OnDestroy {
  public loading = false;
  public loaded = false;
  public slug: string;
  public language: Language;
  public item: PortalArticleType;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private contextService: ContextService,
    private articleService: ArticleService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private location: Location,
    private languageService: LanguageService,
    private metaService: MetaService,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.watchLanguage$ // определить язык
      .pipe(switchMap(() => this.watchRouteChanged$)) // затем следить за сменой URL
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.metaService.reset();
  }

  protected get watchLanguage$(): Observable<Language> {
    return this.languageService.language$.pipe(
      tap((language) => {
        this.language = language;
        this.parseUrl(this.router.url);
        this.loadArticle();
      }),
    );
  }

  protected get watchRouteChanged$(): Observable<any> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      tap(() => {
        this.parseUrl(this.router.url);
        this.loadArticle();
      }),
    );
  }

  private parseUrl(url: string) {
    const parts = url.split('/');
    this.slug = (parts[2] || parts[1]).split('?')[0];
  }

  protected loadArticle() {
    if (!this.slug || !this.language) {
      return;
    }
    const _filter: Partial<IFindArticlesFilter> = {
      language_code: this.language.code,
      fallback_language_code: 'en',
      slug: this.slug,
    };

    this.loading = true;
    this.markForCheck();
    this.articleService
      .findArticle(_filter)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.item = response;
          this.loading = false;
          this.loaded = true;
          this.setupMeta();
          this.markForCheck();
        },
        error: (error: any) => {
          this.loading = false;
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.markForCheck();
        }
      });
  }

  public coverUrl(item: PortalArticleType): string {
    return item.cover && item.cover.md ? item.cover.md.url : null;
  }

  private setupMeta() {
    this.metaService.setTitle([this.item.title]);
    if (this.item.cover_id) {
      this.metaService.setImage(this.item.cover_id);
    }
  }
}
