import { transition, trigger, useAnimation } from '@angular/animations';
import { Location } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { logger } from '@app/core/helpers/logger';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { remoteConfigDefaults, RemoteConfigType } from '@app/core/services/remote-config/constants';
import { RemoteConfigService } from '@app/core/services/remote-config/remote-config.service';
import { slideDown, slideUp } from '@core/animations';
import {
  AccountService,
  AnalyticsService,
  AuthService,
  BillingService,
  ContextService,
  FeaturesService,
  LanguageService,
} from '@core/services';
import { DragScrollComponent } from '@le2xx/ngx-drag-scroll';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadBillingPlans } from '@store/actions/content.actions';
import { loadMyBillingPlan } from '@store/actions/profile.actions';
import { getPreparedBusinessPlans } from '@store/reducers/content.reducer';
import { getMyBillingPlan } from '@store/reducers/profile.reducer';
import { EContentPanelRoute, Language, Plan, TenantEnum, User, UserSegmentEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { combineLatest, fromEvent } from 'rxjs';
import { debounceTime, filter, takeUntil } from 'rxjs/operators';
import { reviewers } from './constants';

/*
 * examples:
 * https://onclass.me?utm_campaign=promo_a_config
 * https://onclass.me?utm_campaign=promo_b_config
 * https://onclass.me?utm_campaign=promo_c_config
 * https://onclass.me?utm_campaign=promo_d_config
 */
const сonfigParamKey = 'utm_campaign';

@Component({
  selector: 'app-promo-me',
  templateUrl: './promo-me.component.html',
  styleUrls: ['./promo-me.component.scss'],
  animations: [
    trigger('menu', [transition(':enter', useAnimation(slideDown)), transition(':leave', useAnimation(slideUp))]),
  ],
})
export class PromoMeComponent extends ChangableComponent implements OnInit, AfterViewInit {
  @ViewChild('dragScroll', { read: DragScrollComponent }) ds: DragScrollComponent;
  public isAuth: boolean = this.authService.isAuthenticated;
  public showPlayBtn = true;
  public goalsList = Array(6);
  public reviewers = reviewers;
  public activeVideo = 'Darina';
  public popupOpen: boolean;
  public fixedState: boolean;
  public label: string;
  public index = 0;
  public language: Language;
  public languages: Language[];
  public debug$ = this.contextService.debug$;
  public chooseRoleModalOpened = false;
  private remoteConfig: RemoteConfigType = remoteConfigDefaults;
  private me: User;
  private configParam = '';
  public tenant: TenantEnum;
  public activePlan: Plan;
  public plans: Plan[];

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private accountService: AccountService,
    private authService: AuthService,
    private billingService: BillingService,
    private contextService: ContextService,
    private languageService: LanguageService,
    private features: FeaturesService,
    private store: Store,
    private router: Router,
    private route: ActivatedRoute,
    private remoteConfigService: RemoteConfigService,
    private analytics: AnalyticsService,
    private location: Location,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  public ngOnInit() {
    this.analytics.event(EventActionEnum.view_landing, EventCategoryEnum.engagement, [
      'app-promo-me',
      this.location.path(true),
    ]);

    combineLatest([this.languageService.languages$, this.languageService.language$])
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: ([languages, language]) => {
          this.languages = languages;
          this.language = language;
          this.label = this.language ? this.language.code.toUpperCase() : '';
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });

    if (this.isBrowser) {
      fromEvent(window, 'scroll')
        .pipe(debounceTime(10), takeUntil(this.destroyed$))
        .subscribe({
          next: (e) => {
            if (window.scrollY >= 600) {
              this.fixedState = true;
              this.popupOpen = false;
              this.detectChanges();
              return;
            }
            if (this.fixedState) {
              this.fixedState = false;
              this.popupOpen = false;
              this.detectChanges();
            }
          },
          error: (error) => {
            this.fixedState = false;
            this.popupOpen = false;
            this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
          },
        });
    }
    this.features.tenant$
      .pipe(
        filter((tenant) => !!tenant),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (tenant) => (this.tenant = tenant),
        error: (error) => {
          this.tenant = TenantEnum.onclass_com;
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
    this.store.dispatch(loadBillingPlans());
    if (this.authService.isAuthenticated) {
      this.store.dispatch(loadMyBillingPlan());
    }

    this.remoteConfigService.updated$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (config) => {
        this.afterFreeOfferAction();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    this.loadData();
  }

  private loadData() {
    this.store
      .select(getPreparedBusinessPlans)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (plans) => {
          this.plans = plans;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });

    this.store
      .select(getMyBillingPlan)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (activePlan) => {
          this.activePlan = activePlan?.plan;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });

    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (me) => {
        this.me = me;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    this.remoteConfigService.config$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (config) => {
        this.remoteConfig = config;
        this.detectChanges();
      },
      error: (error) => {
        this.remoteConfig = remoteConfigDefaults;
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    this.overrideFreeOfferConfig(this.route.snapshot.queryParamMap);
    this.route.queryParamMap.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (params) => this.overrideFreeOfferConfig(params),
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });
  }

  public ngAfterViewInit() {
    if (this.isBrowser) {
      this.setTimeout(() => {
        this.startAutoPlayMainVideo();
        if (this.isRussian) {
          this.setVideoActive(this.index);
        }
      }, 0);
    }
  }

  public get isRussian() {
    return this.language.code === 'ru';
  }

  public setLanguage(language: Language) {
    this.languageService.changeLanguage(language);
    this.closeLanguagePopup();
  }

  public showLanguagePopup() {
    this.popupOpen = true;
    this.detectChanges();
  }

  public closeLanguagePopup() {
    this.popupOpen = false;
    this.detectChanges();
  }

  public playVideo(e) {
    document.querySelectorAll('.video').forEach((el) => {
      const videoElement = el as HTMLVideoElement;
      videoElement.pause();
    });
    const element = e.target.parentElement.parentElement;
    const video = element.parentElement.querySelector('.video');
    element.classList.add('hide');
    element.nextSibling.classList.remove('hide');
    video.play();
  }

  public stopVideo(e) {
    const element = e.target.parentElement.parentElement;
    const video = element.parentElement.querySelector('.video');
    element.classList.add('hide');
    element.previousSibling.classList.remove('hide');
    video.pause();
  }

  public setVideoActive(i: number) {
    this.ds?.moveTo(i);
    this.index = i;
    this.activeVideo = reviewers[i].name;
    this.showPlayBtn = true;
  }

  public navigateToMain() {
    this.router
      .navigate([EContentPanelRoute['my-classes/dashboard']])
      .then(() => (document.querySelector('html').scrollTop = 0));
  }

  public navigateToProfile(me: User) {
    this.router.navigate(AccountService.accountRoute(me)).then(() => (document.querySelector('html').scrollTop = 0));
  }

  public authorize() {
    this.authService.showAuthModal((me) => this.afterAuth(me));
  }

  public freeOfferAction() {
    const promoSegments = this.remoteConfig?.appPromoMe__freeOffer__configs || [];
    const hasSegment = (s: string) => {
      const _s = UserSegmentEnum[s];
      return !!_s && AccountService.hasSegment(this.me, _s);
    };
    const hasPromoSegment = promoSegments.some((s) => hasSegment(s));
    if (hasPromoSegment) {
      return this.afterFreeOfferAction();
    }

    const config = this.configParam || this.remoteConfig?.appPromoMe__freeOffer__config;
    if (config) {
      const segmentName = config; // ВОЗМОЖНО надо какое-то преобразование
      const segment = UserSegmentEnum[segmentName];
      if (segment) {
        this.remoteConfigService.reconfigureForMe(segmentName);
        this.accountService.addSegment(segment).pipe(takeUntil(this.destroyed$)).subscribe();
        return;
      }

      logger.error(
        "Unknown configuration option 'appPromoMe__freeOffer__config'=" +
          this.remoteConfig?.appPromoMe__freeOffer__config,
      );
    }

    return this.afterFreeOfferAction();
  }

  public onMoveLeft() {
    if (this.index) {
      this.index--;
      this.activeVideo = reviewers[this.index].name;
      this.ds.moveLeft();
    }
  }

  public onMoveRight() {
    if (this.index < 3) {
      this.index++;
      this.activeVideo = reviewers[this.index].name;
      this.ds.moveRight();
    }
  }

  private afterFreeOfferAction() {
    switch (this.remoteConfig?.appPromoMe__freeOffer__action) {
      case 'signin': {
        // показать диалог авторизации, а после авторизации продолжить
        // this.authService.setAuthModalMode('signup'); // debug
        this.authService.showAuthModal((me) => this.afterAuth(me));
        break;
      }

      case 'choose_role': {
        // показать диалог выбора роли, а после выбора роли продолжить
        this.openChooseRoleModal();
        /** @see onRoleChoosen */
        break;
      }

      default:
        logger.error(
          "Unknown configuration option 'appPromoMe__freeOffer__action'=" +
            this.remoteConfig?.appPromoMe__freeOffer__action,
        );
        this.navigateToMain();
    }
  }

  public openChooseRoleModal() {
    this.chooseRoleModalOpened = true;
    this.detectChanges();
  }

  public closeChooseRoleModal() {
    this.chooseRoleModalOpened = false;
    this.detectChanges();
  }

  /** После выбора роли */
  public afterRoleChoose() {
    if (this.remoteConfig?.appPromoMe__onRoleChoosen__paywall) {
      // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
      this.billingService.showPaywall(() => this.navigateToMain(), {
        caller: 'app-promo-me',
        reason: '',
      });
    } else {
      // сразу отправить на целевую страницу
      this.navigateToMain();
    }
  }

  /** После авторизации */
  private afterAuth(me: User) {
    if (!me || AccountService.isGuest(me)) {
      return;
    }

    if (this.remoteConfig?.authModal__onAuthSuccess__paywall) {
      // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
      this.billingService.showPaywall(() => this.navigateToMain(), {
        caller: 'app-promo-me',
        reason: '',
      });
    } else {
      // сразу отправить на целевую страницу
      this.navigateToMain();
    }
  }

  private startAutoPlayMainVideo() {
    const video = document.querySelectorAll('video');
    video[0].muted = true;
    const promise = video[0].play();

    if (promise !== undefined) {
      promise.then().catch((error) => {
        this.detectChanges();
      });
    }
  }

  private overrideFreeOfferConfig(params: ParamMap) {
    if (params.has(сonfigParamKey)) {
      this.configParam = params.get(сonfigParamKey); // ВОЗМОЖНО надо какое-то преобразование
    }
  }

  public onUpdate() {
    this.loadData();
  }

  public trackByFn(index) {
    return index;
  }
}
