<section class="digital-content">
  <div class="digital-content-container">
    <div class="digital-content-title">
      {{'job-landing.digitalcontent.title' | translate}}

    </div>
    <div class="digital-content-wrap">
      <div class="digital-content-col">
        <div class="digital-content-col-image-wrap">
          <div class="digital-content-col-image-wrap-image">
            <img src="{{assetsUrl('/assets/images/landings/job/digital-content-teacher.png')}}" alt="" />
            <span>{{'job-landing.digitalcontent.onlinetitle' | translate}}</span>
          </div>
        </div>
        <div class="digital-content-col-image-wrap-list">
          <ul>
            <li>{{'job-landing.digitalcontent.onlinetext1' | translate}}</li>
            <li>{{'job-landing.digitalcontent.onlinetext2' | translate}}</li>
            <li>{{'job-landing.digitalcontent.onlinetext3' | translate}}</li>
            <li>{{'job-landing.digitalcontent.onlinetext4' | translate}}</li>
          </ul>
        </div>

      </div>
      <div class="digital-content-col">
        <div class="digital-content-col-image-wrap">
          <div class="digital-content-col-image-wrap-image">
            <img src="{{assetsUrl('/assets/images/landings/job/digital-content-dashboard.png')}}" alt="" />
            <span>{{'job-landing.digitalcontent.lessonstitle' | translate}}</span>
          </div>
        </div>
        <div class="digital-content-col-image-wrap-list">
          <ul>
            <li>{{'job-landing.digitalcontent.lessonstext1' | translate}}</li>
            <li>{{'job-landing.digitalcontent.lessonstext2' | translate}}</li>
            <li>{{'job-landing.digitalcontent.lessonstext3' | translate}}</li>
          </ul>
        </div>

      </div>
    </div>
  </div>
</section>
