<div class="users-page">
  <div class="header-wrap" [class.mobile]="deviceService.isMobile()">
    <app-main-header></app-main-header>
  </div>

  <div [class.scroll]="deviceService.isMobile()" class="tabs" [class.mobile]="deviceService.isMobile()">
    <div *ngFor="let item of menuList; trackBy: trackByFn" class="categories-item" [style.order]="item.order">
      <app-categories-item [displayMode]="'simple'"
                           [link]="link(item)"
                           [panel]="item">
      </app-categories-item>
    </div>
  </div>

  <div class="simple-title" [class.mobile]="deviceService.isMobile()" *ngIf="showTitle">
    {{'user-page.title.' + pageTitle | translate}}
  </div>
  <router-outlet></router-outlet>
</div>
