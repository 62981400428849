import { AfterViewInit, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { forceLoadAccountCheck } from '@app/store/actions/data.actions';
import { vimeoEmbedUrl, youtubeEmbedUrl } from '@core/helpers/video';
import {
  AccountService,
  AuthService,
  ConfigService,
  ContextService,
  FeaturesService,
  PlatformService,
  ProfileService,
  ScreenService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadMe, setProfile } from '@store/actions/profile.actions';
import {
  FeatureEnum,
  ITeachingSubject,
  otherSubjectId,
  ProfileMediaModeEnum,
  SubjectsRegistry,
  TenantEnum,
  User,
  UserProfileVerificationEnum,
} from 'lingo2-models';
import { differenceWith, isEqual } from 'lodash-es';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonState, OnUiRadioOption } from 'onclass-ui';
import { OnUiBullet } from 'onclass-ui/lib/modules/on-ui-bullet-list/on-ui-bullet-list.component';
import { map, mergeMap, takeUntil } from 'rxjs';
import { debounceTime, filter, tap } from 'rxjs/operators';
import { ProfileFormService } from '../_services/profile-form.service';
import { ITeachingFormReferences } from './resolvers/teaching-form-references.resolver';

export interface ITeachingForm {
  teaching_subjects: ITeachingSubject[];
  verification_status: UserProfileVerificationEnum;
}

export const isTeachingCompleted = (value: ITeachingForm): boolean =>
  Object.keys(value).every((key) => !!value[key]) &&
  value.teaching_subjects.length &&
  value.teaching_subjects.every((x) => {
    const isSubjectOtherNameOk = x.subject_id === otherSubjectId ? x.subject_other_name : true;
    return x.subject_id && x.description && isSubjectOtherNameOk;
  });

const OPTIONS: OnUiRadioOption[] = [
  {
    value: ProfileMediaModeEnum.use_media_url,
    label: 'profile-form.teaching.option-link-text',
  },
  {
    value: ProfileMediaModeEnum.use_media_id,
    label: 'profile-form.teaching.option-video-text',
  },
];

const OPTIONS_SAFARI: OnUiRadioOption[] = [
  {
    value: ProfileMediaModeEnum.use_media_url,
    label: 'profile-form.teaching.option-link-text',
  },
  {
    value: ProfileMediaModeEnum.use_media_id,
    label: 'profile-form.teaching.option-upload-video-text',
    text: 'profile-form.teaching.option-upload-video-description',
  },
];

@Component({
  selector: 'app-teaching-form-page',
  templateUrl: './teaching-form-page.component.html',
  styleUrls: ['./teaching-form-page.component.scss'],
})
export class TeachingFormPageComponent extends ChangableComponent implements OnInit, AfterViewInit {
  public readonly videoModes = ProfileMediaModeEnum;
  public me: User;
  public form: UntypedFormGroup;
  public value: ITeachingForm;
  public subjects: SubjectsRegistry;
  public videoDurationErrors: boolean;
  public isRulesModalOpened: boolean;
  public saveState: OnUiButtonState = 'default';
  public debug$ = this.contextService.debug$;
  public isSafari = this.screenService.isSafari;
  public selectedSubjectsList = [];
  public bullet: OnUiBullet = {
    title: 'profile-form.teaching.tips-for-video',
    list: [
      'profile-form.teaching.record-orientation',
      'profile-form.teaching.video-duration',
      'profile-form.teaching.background',
      'profile-form.teaching.face-visible',
      'profile-form.teaching.presentation',
    ],
  };
  public bulletForSafari: OnUiBullet = {
    list: ['profile-form.teaching.violating', 'profile-form.teaching.file-size', 'profile-form.teaching.video-format'],
  };
  public videoOptions: OnUiRadioOption[] = [];
  public disableAddSubjectBtn = false;
  public otherVideoInit = false;
  public emptyDisciplineAlert = false;

  private readonly formVersion = 1;
  private readonly storageKey = 'APP__PROFILE_ABOUT';
  private required = false;
  private readonly validateFormGroup = ProfileFormService.validateFormGroup;
  private _safeMediaUrl: SafeResourceUrl[] = [];
  private initedTeacherSubjets: ITeachingSubject[] = [];
  private pendingChanges: boolean = false;
  private videoValidationPending: boolean = false;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    protected fb: UntypedFormBuilder,
    protected profileService: ProfileService,
    protected contextService: ContextService,
    protected featureService: FeaturesService,
    protected accountService: AccountService,
    protected authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private sanitizer: DomSanitizer,
    private profileFormService: ProfileFormService,
    private configService: ConfigService,
    public deviceService: DeviceDetectorService,
    public screenService: ScreenService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);

    // Инициализируем форму с пустыми значениями
    this.form = this.fb.group({
      teaching_subjects: this.fb.array([]),
    });

    // Инициализируем value с пустыми значениями
    this.value = {
      teaching_subjects: [],
      verification_status: UserProfileVerificationEnum.not_verified,
    };
  }

  public get isAvailableWebCam(): boolean {
    return this.featureService.isAvailable(FeatureEnum.teaching_video_webcam, this.me);
  }

  public get teachingSubjects(): UntypedFormArray {
    return this.form?.get('teaching_subjects') as UntypedFormArray;
  }

  public get isSent(): boolean {
    return [
      UserProfileVerificationEnum.incomplete,
      UserProfileVerificationEnum.not_verified,
      UserProfileVerificationEnum.changed,
    ].includes(this.value.verification_status);
  }

  public get isCancel(): boolean {
    return [UserProfileVerificationEnum.sent, UserProfileVerificationEnum.resent].includes(
      this.value.verification_status,
    );
  }

  public isMeVersion(): boolean {
    return this.featureService.tenant === TenantEnum.onclass_me;
  }

  ngOnInit(): void {
    this.videoOptions = this.isSafari ? OPTIONS_SAFARI : OPTIONS;
    this.setupSubscriptions();
    this.autoSave();
  }

  private setupSubscriptions(): void {
    this.setupProfileFormSubscription();
    this.setupVerificationSubscription();
    this.setupSubjectsSubscription();
    this.setupMeSubscription();
  }

  private setupProfileFormSubscription(): void {
    this.profileFormService.profileForm$
      .pipe(
        map((x) => x.teaching),
        filter((x) => !!x),
        takeUntil(this.destroyed$),
      )
      .subscribe((res) => {
        this.value = res;
        // Обновляем форму при получении данных
        this.form = this.fb.group({
          teaching_subjects: this.fb.array(this.teachingSubjectArray(this.value.teaching_subjects)),
        });
        this.initedTeacherSubjets = this.value.teaching_subjects;
        this.form.patchValue(this.value);
      });
  }

  private setupVerificationSubscription(): void {
    this.profileFormService.verificationRequested$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (requested) => {
        this.required = requested;
        this.validateFormGroup(this.form);
      },
      error: (error) => this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA'),
    });
  }

  private setupSubjectsSubscription(): void {
    this.configService.subjectsV2$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (subjects) => {
        this.subjects = subjects;
        this.detectChanges();
      },
      error: (error) => this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA'),
    });
  }

  private setupMeSubscription(): void {
    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (me) => {
        this.me = me;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.store.dispatch(loadMe());
      },
    });
  }

  ngAfterViewInit(): void {
    this.setTimeout(() => {
      this.teachingSubjects.markAsUntouched();
      this.teachingSubjects.updateValueAndValidity();
      this.markForCheck();
    }, 0);
  }

  public getVideoId(form: AbstractControl): string {
    return form.get('video_id').value;
  }

  public onVideosChanged(event: ProfileMediaModeEnum, index: number, value: number): void {
    const currentValue = this.teachingSubjects.at(index).get('video_mode').value;
    if (currentValue === value) {
      this.teachingSubjects.at(index).get('video_mode').patchValue(null);
      this.disableAddSubjectBtn = false;
    } else {
      this.teachingSubjects.at(index).get('video_mode').patchValue(value);
      this.disableAddSubjectBtn = value === Number(ProfileMediaModeEnum.use_media_id);
    }
    this.videoValidationPending = false;
    this.detectChanges();
  }

  public onChangeDescription(event: string, group: AbstractControl): void {
    if (event?.trim()?.length === 0) {
      group.patchValue({ description: event.trim() });
    }
  }

  public noWhitespaceValidator(control: UntypedFormControl): ValidationErrors | null {
    return (control.value || '').trim().length === 0 ? { whitespace: true } : null;
  }

  public disableAddSubject($event: boolean): void {
    this.disableAddSubjectBtn = $event;
    this.otherVideoInit = true;
  }

  public updateVideoId(form: AbstractControl, video_id: string): void {
    form.get('video_id').setValue(video_id);
    this.otherVideoInit = false;
    this.videoValidationPending = false;
    this.form.markAsDirty();
  }

  public setVideoMode(value: any): number | undefined {
    return value.video_mode;
  }

  public removeTeachingSubject(index: number): void {
    this.removeSelectedSubject(index);
    this.teachingSubjects.removeAt(index);
    if (this.teachingSubjects.value.filter((item) => item.subject_id === '').length > 0) {
      this.form.setErrors({ subjects: true });
    }
    this.form.markAsDirty();
    this.scrollToTargetAdjusted(`teaching${index - 1}`);
  }

  public haveErrors(): boolean {
    // Проверяем обязательные поля: дисциплина и описание
    const hasFormErrors = this.teachingSubjects.value.some((item) => {
      const hasEmptyDescription = !item.description || item.description.trim() === '';
      const hasEmptySubject = !item.subject_id && !item.subject_other_id;
      const hasOtherSubjectError = item.subject_id === otherSubjectId && !item.subject_other_name;

      return hasEmptyDescription || hasEmptySubject || hasOtherSubjectError;
    });

    // Проверяем ошибки видео только если есть URL и есть ошибки
    const hasVideoErrors =
      this.videoDurationErrors &&
      this.teachingSubjects.value.some((item) => item.video_url) &&
      Object.values(this.videoDurationErrors).some((error) => error === true);

    return hasFormErrors || hasVideoErrors;
  }

  public embedHasErrors(event: boolean): void {
    if (event) {
      // Если есть ошибки в видео
      this.form.setErrors({ video: true });
      // Сбрасываем флаги, так как есть ошибки
      this.videoValidationPending = false;
      this.pendingChanges = false;
    } else {
      // Если ошибок нет, удаляем ошибку video из текущих ошибок формы
      const currentErrors = this.form.errors || {};
      delete currentErrors.video;

      // Если других ошибок нет, устанавливаем null, иначе обновляем объект ошибок
      this.form.setErrors(Object.keys(currentErrors).length ? currentErrors : null);

      // Сбрасываем флаг ожидания валидации видео
      this.videoValidationPending = false;

      // Если есть отложенные изменения, сохраняем форму
      if (this.pendingChanges) {
        this.pendingChanges = false;
        this.saveForm();
      }
    }
  }

  public addTeachingSubject(): void {
    const prevTeachingForm = this.teachingSubjects.controls[this.teachingSubjects.controls.length - 1];
    const isInvalid =
      prevTeachingForm?.value?.description === '' ||
      (prevTeachingForm?.value?.subject_id === '' && prevTeachingForm?.value?.subject_other_id === '');

    if (isInvalid) {
      this.emptyDisciplineAlert = true;
      this.detectChanges();
      return;
    }

    this.teachingSubjects.push(this.teachingSubjectForm());
    this.form.markAsDirty();
    this.detectChanges();
    this.scrollToTargetAdjusted(`teaching${this.teachingSubjects.length - 1}`);
  }

  public scrollToTargetAdjusted(target: string): void {
    this.setTimeout(() => {
      const element = document.getElementById(target);
      const headerOffset = 120;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.pageYOffset - headerOffset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth',
      });
    }, 0);
  }

  public isOtherSubject(index: number): boolean {
    return +this.teachingSubjects.at(index).value.subject_id === otherSubjectId;
  }

  public onSubjectIdChanged(subject_id: number, index: number): void {
    const formControl = this.teachingSubjects.at(index);
    const isOther = subject_id === otherSubjectId;

    formControl.get('subject_other_name')[isOther ? 'enable' : 'disable']();

    const ids = this.teachingSubjects.value.filter((el) => el.subject_id).map((el) => el.subject_id);

    if (ids.includes(subject_id) && subject_id !== otherSubjectId) {
      formControl.get('subject_id').setErrors({ mustMatch: true });
    } else {
      formControl.get('subject_id').setValue(subject_id);
      formControl.get('subject_id').setErrors(null);
    }

    this.form.setErrors(
      this.teachingSubjects.value.filter((item) => item.subject_id === '').length > 0 ? { subjects: true } : null,
    );
  }

  public videoUrl(index: number): AbstractControl {
    return this.teachingSubjects.at(index).get('video_url');
  }

  public safeMediaUrl(index: number): SafeResourceUrl {
    return this._safeMediaUrl[index] || null;
  }

  public prepareMedia(index: number): void {
    const mediaUrl = this.videoUrl(index).value;
    const embedUrl = youtubeEmbedUrl(mediaUrl) || vimeoEmbedUrl(mediaUrl);
    this._safeMediaUrl[index] = embedUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl) : '';
  }

  public resetMedia(index: number): void {
    this._safeMediaUrl[index] = '';
  }

  public getSanitizedUrl(mediaUrl: string): SafeResourceUrl {
    const embedUrl = youtubeEmbedUrl(mediaUrl) || vimeoEmbedUrl(mediaUrl);
    return embedUrl ? this.sanitizer.bypassSecurityTrustResourceUrl(embedUrl) : '';
  }

  public saveForm(): void {
    if (this.form.invalid || !this.form.dirty || this.haveErrors() || this.saveState === 'progress') {
      return;
    }

    this.saveState = 'progress';
    this.save(this.form.value)
      .then(() => {
        this.saveState = 'done';
        this.videoValidationPending = false;
        this.resetState();
      })
      .catch(() => {
        this.saveState = 'fail';
        this.videoValidationPending = false;
        this.resetState();
      });
  }

  public resetState(): void {
    this.setTimeout(() => {
      this.saveState = 'default';
      this.detectChanges();
    }, 1000);
  }

  public save(values: ITeachingForm): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const { teaching_subjects } = values;
      const _teaching_subjects = (teaching_subjects || [])
        .map((_values) => {
          let { subject_other_name, subject_other_id, subject_id } = _values;
          subject_id = +subject_id;

          if (subject_id === otherSubjectId && !subject_other_name.length && !subject_other_id.length) {
            subject_id = null;
          }

          if (subject_id !== otherSubjectId) {
            subject_other_name = '';
            subject_other_id = '';
          }

          return {
            ..._values,
            subject_id,
            subject_other_name,
            subject_other_id,
          };
        })
        .filter((_values) => !!_values.subject_id || _values.subject_other_name.length > 0);

      this.profileService
        .updateProfile({ teaching_subjects: _teaching_subjects })
        .pipe(
          map((profile) => {
            this.contextService.patchProfile({
              teaching_subjects: profile.teaching_subjects,
              verification_status: profile.verification_status,
            });
            this.profileFormService.setProfileForm({ teaching: profile });
            this.store.dispatch(setProfile({ profile }));
            this.store.dispatch(forceLoadAccountCheck());
            this.detectChanges();
            return profile;
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe({
          next: () => resolve(true),
          error: (error) => {
            this.errorNotificationService.captureError(error, 'PROFILE-PROBLEM');
            reject(error);
          },
        });
    });
  }

  public navigateBack(): void {
    this.forceSave();
    this.setTimeout(() => {
      this.router.navigate(['/profile/experience']).then();
    }, 1500);
  }

  public moderateAndNavigate(): void {
    if (this.isCancel) {
      this.handleCancelVerification();
    } else if (this.isSent) {
      this.handleSendToVerification();
    } else {
      this.router.navigate(['/profile/payment']).then();
    }
  }

  private handleCancelVerification(): void {
    this.profileService
      .cancelVerification()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.detectChanges();
          this.router.navigate(['/profile']).then();
        },
        error: (error) => this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA'),
      });
  }

  private handleSendToVerification(): void {
    this.profileService
      .sendToVerification()
      .pipe(
        mergeMap((res) => {
          if (res.status === 'ok') {
            return this.profileService.updateProfile({
              verification_status: UserProfileVerificationEnum.resent,
            });
          }
        }),
      )
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (profile) => {
          this.contextService.patchProfile({
            teaching_subjects: profile.teaching_subjects,
            verification_status: profile.verification_status,
          });
          this.store.dispatch(setProfile({ profile }));
          this.store.dispatch(forceLoadAccountCheck());
          this.detectChanges();
        },
        error: (error) => this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA'),
      });
  }

  public navigateToPaymentPage(): void {
    this.saveForm();
    this.router.navigate(['/profile/payment']).then();
  }

  public onToggleRulesModal(): void {
    if (localStorage.getItem('rules_warning')) {
      this.moderateAndNavigate();
      return;
    }
    this.isRulesModalOpened = !this.isRulesModalOpened;
    this.detectChanges();
  }

  private autoSave(): void {
    this.form.valueChanges.pipe(debounceTime(300), takeUntil(this.destroyed$)).subscribe({
      next: (controls) => {
        // Если изменилось количество предметов, сохраняем сразу
        if (this.initedTeacherSubjets.length !== controls.teaching_subjects.length) {
          this.videoValidationPending = false;
          this.saveForm();
          return;
        }

        const initedTeacherSubjetsLikeSorted = this.initedTeacherSubjets.map((item) =>
          Object.fromEntries(Object.entries(item).sort()),
        );
        const editedTeacherSubjetsLikeSorted = controls.teaching_subjects.map((item) =>
          Object.fromEntries(Object.entries(item).sort()),
        );

        const differences = differenceWith(initedTeacherSubjetsLikeSorted, editedTeacherSubjetsLikeSorted, isEqual);

        if (!differences.length) {
          return;
        }

        // Проверяем, какие поля изменились
        const changedFields = differences
          .map((diff) => {
            if (!diff) {
              return null;
            }
            return Object.keys(diff);
          })
          .flat()
          .filter(Boolean);

        // Проверяем, есть ли изменения в видео
        const hasVideoChanges = changedFields.some((field) => ['video_url', 'video_id', 'video_mode'].includes(field));

        // Проверяем, есть ли изменения в обязательных полях
        const hasRequiredFieldsChanges = changedFields.some((field) =>
          ['subject_id', 'subject_other_name', 'description'].includes(field),
        );

        if (hasVideoChanges) {
          // Проверяем, было ли видео в исходных данных
          const hadVideoInInitial = initedTeacherSubjetsLikeSorted.some((item) => item.video_url);
          // Проверяем, есть ли видео в текущих данных
          const hasVideoNow = editedTeacherSubjetsLikeSorted.some((item) => item.video_url);
          // Проверяем, есть ли ошибки валидации видео
          const hasVideoValidationErrors = editedTeacherSubjetsLikeSorted.some((item) => {
            const videoUrl = item.video_url;
            if (!videoUrl) {
              return false;
            }
            const embedUrl = youtubeEmbedUrl(videoUrl) || vimeoEmbedUrl(videoUrl);
            return !embedUrl;
          });

          if (hasVideoNow) {
            if (hasVideoValidationErrors) {
              // Если видео невалидно, сохраняем сразу
              this.videoValidationPending = false;
              this.saveForm();
            } else {
              // Если видео валидно, ждем валидации
              this.videoValidationPending = true;
              this.pendingChanges = true;
            }
          } else if (hadVideoInInitial) {
            // Если видео было удалено, сохраняем сразу
            this.videoValidationPending = false;
            this.saveForm();
          }
        } else if (hasRequiredFieldsChanges) {
          // Если изменились обязательные поля, сохраняем сразу
          this.videoValidationPending = false;
          this.saveForm();
        }
      },
      error: (error) => {
        this.videoValidationPending = false;
        this.errorNotificationService.captureError(error, 'SAVE-PROBLEM');
      },
    });
  }

  private teachingSubjectArray(values: ITeachingSubject[]): UntypedFormGroup[] {
    return values.length ? values.map((value) => this.teachingSubjectForm(value)) : [this.teachingSubjectForm()];
  }

  private teachingSubjectForm(value?: ITeachingSubject): UntypedFormGroup {
    const _value: Partial<ITeachingSubject> = value || {};
    const { subject_other_name, subject_other_id } = _value;
    let { subject_id } = _value;

    if (!subject_id && (subject_other_id || subject_other_name)) {
      subject_id = otherSubjectId;
    }

    return this.fb.group({
      subject_id: [
        subject_id || '',
        {
          updateOn: 'change',
          validators: [this.conditionalRequired.bind(this)],
        },
      ],
      subject_other_name: [subject_other_name || '', [Validators.maxLength(200), this.conditionalRequired.bind(this)]],
      subject_other_id: [subject_other_id || '', [Validators.maxLength(200)]],
      description: [
        _value.description || '',
        [
          Validators.minLength(150),
          Validators.maxLength(2000),
          this.noWhitespaceValidator,
          this.conditionalRequired.bind(this),
        ],
      ],
      video_url: [
        _value.video_url || '',
        {
          updateOn: 'change',
          validators: [Validators.maxLength(200), this.embedVideoValidator],
        },
      ],
      video_id: [_value.video_id || ''],
      video_mode: [this.isAvailableWebCam ? _value.video_mode : ProfileMediaModeEnum.use_media_url],
    });
  }

  private embedVideoValidator(control: AbstractControl): { [key: string]: any } | null {
    if (!control.value) {
      return null;
    }

    const mediaUrl = control.value;
    const embedUrl = youtubeEmbedUrl(mediaUrl) || vimeoEmbedUrl(mediaUrl);

    return !embedUrl
      ? {
          video: {
            value: true,
            text: 'profile-form.teaching.incorrect-format',
          },
        }
      : null;
  }

  private conditionalRequired(control: AbstractControl): ValidationErrors | null {
    return this.required && !control.value ? { conditionalRequired: true } : null;
  }

  private removeSelectedSubject(formIndex: number): void {
    this.selectedSubjectsList = this.selectedSubjectsList.filter(
      (item) => this.teachingSubjects.at(formIndex).value.subject_id !== item,
    );
  }

  private forceSave(): void {
    this.saveForm();
  }

  public trackByFn(index: number): number {
    return index;
  }

  public closeAlert(): void {
    this.emptyDisciplineAlert = false;
  }
}
