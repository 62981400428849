import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { libraryFeatureKey, reducer } from '@app/store/reducers/library.reducer';
import { CoreModule } from '@core/core.module';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { LibraryEffects } from '@store/effects/library.effects';
import { DateFnsModule } from 'lingo2-ngx-date-fns';
import { ClickOutsideModule } from 'ng-click-outside';

// модули
import { LibraryElementsModule } from './library-elements.module';
import { LibraryFormService } from './library-form.service';
import { LibraryPanesModule } from './panes/library-panes.module';

// компоненты
import { LibrarySidebarService } from './sidebars/library-sidebar/library-sidebar.service';
import { SubjectsPageComponent } from './subjects-page/subjects-page.component';

// сервисы

// Store

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    TranslateModule,
    DateFnsModule,
    FormsModule,
    ReactiveFormsModule,
    ClickOutsideModule,
    StoreModule.forFeature(libraryFeatureKey, reducer),
    EffectsModule.forFeature([LibraryEffects]),
  ],
  exports: [],
  declarations: [SubjectsPageComponent],
  providers: [LibraryFormService, LibrarySidebarService],
})
export class LibraryModule {}
