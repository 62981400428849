import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { FilesService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { LibraryRouter } from '@models/library.router';
import { TranslateService } from '@ngx-translate/core';
import { EUserServiceType, UserServiceStateEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiCover } from 'onclass-ui';
import { OverlapEvent, UserServiceItem } from '../models';

const daysOfWeek = ['su', 'mo', 'tu', 'we', 'th', 'fr', 'sa', 'su'];

interface IStatusOption {
  value: UserServiceStateEnum;
  label: string;
}

@Component({
  selector: 'app-checkbox-event-card',
  templateUrl: './checkbox-event-card.component.html',
  styleUrls: ['./checkbox-event-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxEventCardComponent extends ChangableComponent implements AfterViewInit {
  @Input()
  set userService(userService: UserServiceItem) {
    this._userService = userService;
    this.prepareUserServiceCard();
  }

  @Input()
  set checked(checked: boolean) {
    this._checked = checked;
    this.form?.patchValue({ checkbox: this._checked });
  }

  @Input()
  processing: boolean;

  @Input()
  readonly: boolean;

  @Output()
  selectionChanged = new EventEmitter<boolean>();

  public alertOpened: boolean;
  public eUserServiceType = EUserServiceType;
  public type: EUserServiceType;
  public title: string;
  public subject: string;
  public cover: OnUiCover;
  public coverImageUrl: string;
  public fullTitle: string;
  public userServiceRoute: string;
  public discount: number;
  public duration: number;
  public price_tier: number;
  public age_from: number;
  public age_to: number;
  public participants_limit: number;
  public meetings_limit: number;
  public weekdays: string;
  public form: UntypedFormGroup;

  private _userService: UserServiceItem;
  private _checked: boolean;

  public constructor(
    public deviceService: DeviceDetectorService,
    private translate: TranslateService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
    this.form = new UntypedFormGroup({
      checkbox: new UntypedFormControl(false),
    });
  }

  public ngAfterViewInit(): void {
    this.form.patchValue({ checkbox: this._checked });
    // this.detectChanges();
  }

  public typeName(type: EUserServiceType): string {
    switch (type) {
      case EUserServiceType.single:
        return 'library.user-service-card.type.single';
      case EUserServiceType.regular:
        return 'library.user-service-card.type.regular';
      case EUserServiceType.mini:
        return 'library.user-service-card.type.mini';
      case EUserServiceType.course:
        return 'library.user-service-card.type.course';
      default:
        return '';
    }
  }

  public get moderationStatus(): IStatusOption {
    if (this._userService.is?.declined) {
      return {
        value: UserServiceStateEnum.declined,
        label: 'library.user-service-card.status.declined',
      };
    } else if (this._userService.is?.on_moderation) {
      return {
        value: UserServiceStateEnum.on_moderation,
        label: 'library.user-service-card.status.on-moderation',
      };
    } else {
      return { value: UserServiceStateEnum.on_moderation, label: '' };
    }
  }

  public get reasonMessage(): string {
    return this.moderationStatus.value === UserServiceStateEnum.declined && this._userService.moderation_message;
  }

  public get isSingle(): boolean {
    return this.participants_limit === 1;
  }

  public onChanged() {
    const selected = !!this.form.get('checkbox').value;
    this.selectionChanged.emit(selected);
  }

  public get overlapEvents(): OverlapEvent[] {
    return this._checked ? this._userService?.overlap?.overlappingEvents : [];
  }

  public get isOverlapping(): boolean {
    return this._checked && this._userService?.overlap?.overlapping;
  }

  private prepareUserServiceCard() {
    this.type = this._userService?.type;
    this.title = this._userService?.title;
    this.subject = this._userService?.subject?.title;
    this.coverImageUrl = FilesService.getFileUrlBySize(this._userService?.cover_id);
    this.discount = this._userService?.discount;
    this.duration = this._userService?.duration;
    this.price_tier = this._userService?.price_tier;
    this.userServiceRoute = LibraryRouter.userServiceRouteUniversal(this._userService);
    this.cover = {
      img: this.coverImageUrl,
      title: this.title,
      link: this.userServiceRoute,
      aspect: '16 / 9',
    };
    this.age_from = this._userService?.options?.age_from;
    this.age_to = this._userService?.options?.age_to;
    this.meetings_limit = this._userService?.options?.meetings_limit;
    this.weekdays = this._userService?.options?.weekdays
      ?.map((_d) => this.translate.instant('days-of-week.' + daysOfWeek[_d]))
      .join(', ');
    this.participants_limit = this._userService?.participants_limit;

    this.detectChanges();
  }

  public trackByFn(index) {
    return index;
  }
}
