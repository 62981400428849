import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-vocabulary-study',
  templateUrl: './vocabulary-study.component.html',
  styleUrls: ['./vocabulary-study.component.scss'],
})
export class VocabularyStudyComponent extends ChangableComponent implements OnInit {
  constructor(protected readonly cdr: ChangeDetectorRef, public deviceService: DeviceDetectorService) {
    super(cdr);
  }

  ngOnInit(): void {}
}
