import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { AccountService, ContextService, PlatformService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { User, UserSegmentEnum } from 'lingo2-models';
import { Observable } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-news-sidebar',
  templateUrl: './news-sidebar.component.html',
  styleUrls: ['./news-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NewsSidebarComponent extends DestroyableComponent implements OnInit {
  public me: User;
  public user$: Observable<User>;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private contextService: ContextService,
    protected readonly platform: PlatformService,
  ) {
    super(platform);
  }

  ngOnInit() {
    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (me) => (this.me = me),
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      }
    });
  }

  public get isAsIfTeacher(): boolean {
    return (
      this.me && !AccountService.isTeacher(this.me) && AccountService.hasSegment(this.me, UserSegmentEnum.as_if_teacher)
    );
  }
}
