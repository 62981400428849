import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { RouterModule, Routes } from '@angular/router';
import { CategoriesItemComponent } from '@app/library/panes/categories-content-filter/categories-item/categories-item.component';
import { UniversalSidebarComponent } from '@app/library/sidebars/universal-sidebar/universal-sidebar.component';
import { MainHeaderModule } from '@core/components/main-header/main-header.module';
import { TranslateModule } from '@ngx-translate/core';

import { UsersComponent } from './users/users.component';
import { UsersPageComponent } from './users-page.component';
import { UsersModule } from './users.module';

/**
 * Этот роут срабатывает после ленивой загрузки модуля по роуту /users
 *
 * @see AppRoutingModule
 */
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        outlet: 'right-sidebar-top',
        component: UniversalSidebarComponent,
      },
      {
        path: '',
        component: UsersPageComponent,
        children: [
          {
            path: '',
            pathMatch: 'full',
            redirectTo: 'subscriptions',
          },
          {
            path: 'all',
            component: UsersComponent,
          },
          {
            path: 'subscribers',
            component: UsersComponent,
          },
          {
            path: 'subscriptions',
            component: UsersComponent,
          },
          {
            path: 'students',
            component: UsersComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    UsersModule,
    TranslateModule,
    FormsModule,
    MainHeaderModule,
    CategoriesItemComponent,
  ],
  exports: [],
  declarations: [UsersPageComponent],
  providers: [],
})
export class UsersRoutingModule {}
