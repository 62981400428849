import { ChangeDetectionStrategy, Component, Injector } from '@angular/core';
import { IPagination } from '@app/content-editor/store/library/library.selectors';
import { BaseCalendarComponent } from '@app/events-calendar/base-calendar/base-calendar.component';
import { CalendarContext } from '@app/events-calendar/user-service-multi-selector/user-service-multi-selector.component';
import { FeaturesService, fullUserServiceDetails } from '@core/services';
import { FindUserServiceStrategyEnum, IFindUserServicesFilter, PublishStatusEnum, UserService } from 'lingo2-models';
import { Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';

@Component({
  selector: 'app-my-calendar',
  templateUrl: '../base-calendar/base-calendar.component.html',
  styleUrls: ['../base-calendar/base-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MyCalendarComponent extends BaseCalendarComponent {
  public constructor(protected inject: Injector, public features: FeaturesService) {
    super(inject, features);
  }

  protected findUserServices$(): Observable<UserService[]> {
    const filter: Partial<IFindUserServicesFilter> = {
      use: FindUserServiceStrategyEnum.owned,
      publish_status: [
        // все кроме архивных
        PublishStatusEnum.published,
        PublishStatusEnum.draft,
        PublishStatusEnum.moderation,
      ],
    };
    const pagination: IPagination = {
      page: 1,
      pageSize: 100,
    };

    return this.userServicesService
      .getServices(filter, pagination, fullUserServiceDetails)
      .pipe(map((response) => response.results));
  }

  protected get watchMe$() {
    return super.watchMe$.pipe(
      tap((me) => {
        if (me?.id) {
          this.reloadSchedule();
          this.loadUserServices();
          this.startWatchingSchedule(me?.id);
        }
      }),
    );
  }

  public get context(): CalendarContext {
    return {
      context: 'teacher',
      data: this._me,
    };
  }
}
