<div class="steps-widget" [class.mobile]="deviceService.isMobile()">
  <p class="title">{{type === 'payout' ?
    ('payout.profile_page.payout_info_title' | translate) :
    ('payout.profile_page.payment_info_title' | translate)}}</p>
  <div class="payments-wrapper">
    <div class="payments" *ngFor="let merchant of merchants; trackBy: trackByFn">
      <p class="hint">
        {{type === 'payout' ?
        isForNotRussia(merchant) ? ('payout.profile_page.payout_add_world_text' | translate)
          : ('payout.profile_page.payout_add_russia_text' | translate)
        : isForNotRussia(merchant) ? ('payout.profile_page.payment_add_world_text' | translate)
          : ('payout.profile_page.payment_add_russia_text' | translate)}}
      </p>
      <p class="title">
        {{isForNotRussia(merchant) ? ('payout.all_world' | translate) : ('payout.russia' | translate)}}
        {{isTestProvider(merchant) ? '(' + merchant.note + ')' : null}}
      </p>
      <div class="payment-list" [attr.data-test]="'list' + (isForNotRussia(merchant) ? '-all-world' : '-ru')">
        <!-- Уже добавленные методы -->
        <ng-container *ngIf="type === 'payout'">
          <div class="payment-wrap" *ngFor="let supplier of activeSuppliers[merchant.id]; trackBy: trackByFn">
            <app-payout-card
              [supplier]="supplier" [is_test]="isTestBeneficiary(supplier)" (onChange)="updateSupplierList()"
              (onEdit)="editMethod($event)" (getMoney)="getMoney($event)"
              (openHistory)="openPaymentHistory($event)"></app-payout-card>
          </div>
          <div class="payment add"
               [attr.data-test]="'add-payout' + (isForNotRussia(merchant) ? '-all-world' : '-ru')"
               (click)="showAddPaymentAlert(merchant.id)">
            <div class="add-link">
              <span class="plus">
                <app-icon width="12" height="12" icon="plus"></app-icon>
              </span> <span class="text">{{'payout.add_method' | translate}}</span>
            </div>
          </div>
        </ng-container>

        <ng-container *ngIf="type === 'payment'">
          <div class="payment-wrap" *ngFor="let account of activeAccounts[merchant.id]; trackBy: trackByFn">
            <app-payment-card
              [account]="account" [view]="'full'" [is_test]="isTestCard(account)"
              (onChange)="updateAccountList(merchant.id)"
              (openHistory)="openPaymentHistory($event)"></app-payment-card>
          </div>
          <div class="payment-wrap" *ngFor="let account of inactiveAccounts[merchant.id]; trackBy: trackByFn">
            <app-payment-card
              [account]="account" [view]="'full'" [is_test]="isTestCard(account)"
              (onChange)="updateAccountList(merchant.id)"
              (openHistory)="openPaymentHistory($event)"></app-payment-card>
          </div>
          <div class="payment add account"
               [attr.data-test]="'add-payment' + (isForNotRussia(merchant) ? '-all-world' : '-ru')"
               (click)="addAccount(merchant.id)">
            <div class="add-link">
              <span class="plus">
                <app-icon width="12" height="12" icon="plus"></app-icon>
              </span> <span class="text">{{'payout.add_payment_method' | translate}}</span>
            </div>
          </div>
        </ng-container>

      </div>

      <ng-container *ngIf="type === 'payout'">
        <div class="non-active-suppliers" *ngIf="nonActiveSuppliers[merchant.id] && nonActiveSuppliers[merchant.id].length">
          <div class="toggler"
               data-test="active-suppliers"
               [class.show]="showNonActiveSuppliers" (click)="toggleNonActiveSupplier()">
        <span
          class="name">{{'payout.inactive' | translate}} {{isForNotRussia(merchant) ? ('payout.all_world' | translate)
          : ('payout.russia' | translate)}}</span>
            <app-icon class="btn-arrow-ico" width="11" height="6" icon="angle-arrow"></app-icon>
          </div>
          <div class="payment-list non-active" [class.show]="showNonActiveSuppliers">
            <div class="payment-wrap suppliers" *ngFor="let supplier of nonActiveSuppliers[merchant.id]; trackBy: trackByFn">
              <app-payout-card
                [supplier]="supplier" [is_test]="isTestBeneficiary(supplier)" (onSetDefault)="setSupplierDefault($event)"
                (onChange)="updateSupplierList()"
                (onEdit)="editMethod($event)"></app-payout-card>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
  <div class="widget-actions" [class.mobile]="deviceService.isMobile()">
    <on-ui-button state="default"
                  class="primary outline margin-right-20 back"
                  label="profile-form.back"
                  (click)="navigateTo('back')">
    </on-ui-button>
    <on-ui-button state="default"
                  class="primary next"
                  label="profile-form.next"
                  (click)="navigateTo('next')">
    </on-ui-button>
  </div>
</div>

<app-modal *ngIf="newPaymentFormOpened && !deviceService.isMobile()" [canClose]="false"
           (closed)="closeNewCardForm()">
  <app-modal-body>
    <app-finance-card-create-new [options]="supplierModalOptions"
                                 (finished)="closeNewCardForm()"></app-finance-card-create-new>
  </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="newPaymentFormOpened && deviceService.isMobile()"
                  (closed)="closeNewCardForm()">
  <app-mobile-modal-body class="finance-mobile">
    <app-finance-card-create-new [options]="supplierModalOptions"
                                 (finished)="closeNewCardForm()"></app-finance-card-create-new>
  </app-mobile-modal-body>
</app-mobile-modal>

<app-modal *ngIf="paymentHistoryOpened && !deviceService.isMobile()" [canClose]="false"
           (closed)="closePaymentHistory()">
  <app-modal-body class="no-padding">
    <app-payment-history [history]="transactions.items" [type]="'modal'"></app-payment-history>
  </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="paymentHistoryOpened && deviceService.isMobile()" (closed)="closePaymentHistory()">
  <app-mobile-modal-body class="finance-mobile no-padding">
    <app-payment-history [history]="transactions.items" [type]="'modal'"></app-payment-history>
  </app-mobile-modal-body>
</app-mobile-modal>
<app-add-card-wizard-dialog *ngIf="selectedProviderId" [providerId]="selectedProviderId"
                            (closed)="onCloseAddAccount()"></app-add-card-wizard-dialog>

<app-modal *ngIf="withdrawMoneySupplier && !deviceService.isMobile()" [canClose]="false"
           (closed)="closeWithdrawMoneyForm()">
  <app-modal-body>
    <app-withdraw-money-form [supplier]="withdrawMoneySupplier"
                             (success)="updateSupplierList()"
                              (fail)="updateSupplierList()" (close)="closeWithdrawMoneyForm()"></app-withdraw-money-form>
  </app-modal-body>
</app-modal>
<app-mobile-modal *ngIf="withdrawMoneySupplier && deviceService.isMobile()"
                  [titleVisible]="false"  [withCloseButton]="true"
                  [customTitle]="'finance.tabs.payout' | translate"
                  (closed)="closeWithdrawMoneyForm()">
  <app-mobile-modal-body class="finance-mobile">
    <app-withdraw-money-form [supplier]="withdrawMoneySupplier"
                             (success)="updateSupplierList()"
                             (fail)="updateSupplierList()" (close)="closeWithdrawMoneyForm()"></app-withdraw-money-form>
  </app-mobile-modal-body>
</app-mobile-modal>

<app-alert-payout *ngIf="showEditAlert" (cancel)="showEditAlert = null" (change)="showEditAlert = false; newPaymentFormOpened = true"></app-alert-payout>

<app-alert *ngIf="showWarningAlert" type="warning" [btnPosition]="'center'">
  <ng-container alert-icon>
    <app-icon width="36" height="36" file="emoji" icon="think"></app-icon>
  </ng-container>
  <ng-container alert-title>{{'payout.add-alert-title' | translate}}</ng-container>
  <ng-container alert-body>{{'payout.add-alert-text' | translate}} </ng-container>
  <ng-container alert-buttons>
    <on-ui-button label="{{'payout.dontadd' | translate}}" style="margin-right: 15px;"
                  class="primary outline transparent center"
                  (onclick)="closeAddPaymentAlert()">
    </on-ui-button>
    <on-ui-button label="{{'payout.add' | translate}}"
                  class="primary center"
                  (onclick)="addPayment()">
    </on-ui-button>
  </ng-container>
</app-alert>
