<div class="school-calculations">
  <div class="tabs-block">
    <on-ui-tabs [tabs]="tabs" displayMode="delimiter"></on-ui-tabs>
  </div>
  <ng-container *ngIf="activeTab?.code==='distribution'">
    <div class="distribution-table">
      <div class="table-header" *ngIf="!deviceService.isMobile()">
        <div class="col-name">
          <span class="header-title">{{'school-calculation.tutor' | translate}}</span>
        </div>
        <div class="col-percentages">
          <span class="header-title">{{'school-calculation.tutorPer' | translate}}</span>
          <span class="header-title">{{'school-calculation.school' | translate}}</span>
        </div>
      </div>
      <div class="table-body">
        <div *ngFor="let teacher of teachersList(); trackBy: teachersTrackByFn" class="card-wrap">
          <app-school-calc-teacher-card [teacher]="teacher"
                                        [schoolOptions]="school?.options"
                                        (changed)="onChanged($event)">
          </app-school-calc-teacher-card>
        </div>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="activeTab?.code==='history'">
    <div class="history-wrap">
      <app-finance-history-page [schoolId]="school.id"></app-finance-history-page>
    </div>
  </ng-container>
</div>
