import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { loadMe } from '@app/store/actions/profile.actions';
import { SchoolsService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { getMe } from '@store/reducers/profile.reducer';
import { CSchool, ESchoolParticipantRole, User } from 'lingo2-models';
import { takeUntil } from 'rxjs';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-schools-sidebar',
  templateUrl: './schools-sidebar.component.html',
  styleUrls: ['./schools-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolsSidebarComponent extends ChangableComponent implements OnInit {
  public isFirstContactWidget: boolean;

  private _me: User;
  private _school: CSchool;

  constructor(
    private router: Router,
    private schoolService: SchoolsService,
    private store: Store,
    public errorNotificationService: ErrorNotificationService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.router.events.pipe(takeUntil(this.destroyed$)).subscribe({
      next: () => {
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      }
    });

    this.schoolService.currentSchool$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (school) => {
        this._school = school;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      }
    });

    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (me) => {
          this._me = me;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadMe());
        }
      });
  }

  public get school(): CSchool {
    return this._school;
  }

  public get me(): User {
    return this._me;
  }

  public isSchoolMain(): boolean {
    return this.router.url === '/schools';
  }

  public isSettings(): boolean {
    const [settings] = this.router.url.split('/');
    return settings === 'settings';
  }

  public get meIsOwnerSchool(): boolean {
    return !!this._school?.participants
      .filter((_p) => _p.role === ESchoolParticipantRole.owner)
      .find((_part) => _part.account_id === this._me?.id);
  }
}
