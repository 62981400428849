import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UsersBlockType } from '@app/schools/schools-sidebar/school-participants-widgets/school-participants-widgets.component';
import { AccountService, PlatformService, SchoolsService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { CSchool, ESchoolParticipantRole, FindAccountStrategyEnum, User } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiTab } from 'onclass-ui';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-school-participants',
  templateUrl: './school-participants.component.html',
  styleUrls: ['./school-participants.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolParticipantsComponent extends ChangableComponent implements OnInit {
  @Input() schoolParticipantsFilter: 'owners' | 'teachers';

  public activeFilter: OnUiTab = {
    code: 'all',
    label: 'school-participants.all',
    route: '',
  };
  public inviteModal = false;
  public invitedTeachers: boolean;
  public inviteMode: 'owners' | 'teachers' = 'owners';
  public users: UsersBlockType = {
    items: [],
    loading: false,
    loaded: false,
    filter: { use: FindAccountStrategyEnum.school },
    pagination: {
      page: 1,
      pageSize: 20,
    },
  };

  private _school: CSchool;
  private _onChange: Subscription;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private schoolService: SchoolsService,
    private accountService: AccountService,
    protected cdr: ChangeDetectorRef,
    protected platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public get school() {
    return this._school;
  }

  public ngOnInit() {
    this.schoolService.currentSchool$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (school) => {
        this._school = school;
        if (this.schoolParticipantsFilter) {
          this.activeFilter.code = this.schoolParticipantsFilter;
          this.inviteMode = this.schoolParticipantsFilter;
        }
        this.getParticipants();
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      }
    });
  }

  public getParticipants() {
    this.users.loaded = false;
    this.users.filter.school_id = [this._school?.id];

    switch (this.activeFilter.code) {
      case 'all':
      case 'students':
        this.users.filter.school_roles = [ESchoolParticipantRole.student];
        break;
      case 'owners':
        this.users.filter.school_roles = [ESchoolParticipantRole.owner];
        break;
      case 'teachers':
        this.users.filter.school_roles = [ESchoolParticipantRole.teacher];
        break;
      default:
    }

    this.accountService
      .findAccounts(this.users.filter, this.users.pagination)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.users.items = res.results;
          this.users.loaded = true;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.users.loaded = true;
        }
      });
  }

  public getParticipant(id: string) {
    let role: ESchoolParticipantRole;
    switch (this.activeFilter.code) {
      case 'all':
      case 'students':
        role = ESchoolParticipantRole.student;
        break;
      case 'owners':
        role = ESchoolParticipantRole.owner;
        break;
      case 'teachers':
        role = ESchoolParticipantRole.teacher;
        break;
      default:
    }
    return this._school.participants.find((el) => el.account_id === id && el.role === role);
  }

  public usersTrackBy(index, owner: User): string {
    return owner.id;
  }

  public onToggleInviteModal() {
    this.inviteModal = !this.inviteModal;
    this.detectChanges();
  }

  public onChangeInviteTeachers() {
    this.invitedTeachers = true;
    this.detectChanges();

    this.clearTimeout(this._onChange);
    this._onChange = this.setTimeout(() => {
      this.invitedTeachers = false;
      this.loadSchool();
      this.onToggleInviteModal();
      this.detectChanges();
    }, 2000);
  }

  public loadSchool() {
    this.schoolService
      .getSchoolByIdOrSlug(this._school.slug, undefined, 'LOAD_TIME')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (school) => {
          this._school = school;
          this.schoolService.setCurrentSchool(school);
          this.getParticipants();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });
  }
}
