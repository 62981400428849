import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { PlatformService, ScheduleService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { UpdateUserVacationRequest, UserVacation } from 'lingo2-models';
import { DateFnsConfigurationService, FormatPipe } from 'lingo2-ngx-date-fns';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonState } from 'onclass-ui';
import { of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-vacation-form',
  templateUrl: './vacation-form.component.html',
  styleUrls: ['./vacation-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VacationFormComponent extends ChangableComponent implements OnInit {
  public form: UntypedFormGroup;
  public saveState: OnUiButtonState = 'default';
  public saved = false;
  public saving = false;
  public error: string;
  public initialFormValue: string;
  public placeholder = 'dd/MM/yyyy';
  private vacation: UserVacation;
  private dateFormat = new FormatPipe(this.dateConfig, null);
  private dateFormatString = 'yyyy-MM-dd'; // 'dd.MM.yyyy';

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private scheduleService: ScheduleService,
    private dateConfig: DateFnsConfigurationService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.load();
  }

  protected load() {
    this.scheduleService
      .getVacation()
      .pipe(
        catchError((err: HttpErrorResponse) => {
          if (err.status === 404) {
            return of(new UserVacation({}));
          }
          throw err;
        }),
        tap(),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (vacation: UserVacation) => {
          this.vacation = vacation;
          this.createForm();
          // this.form.patchValue(this.vacation);
          this.checkCondition();
          this.detectChanges();
        },
        error: (error) => {
          if (error.status === 404) {
            return of(new UserVacation({}));
          }
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  private createForm(): void {
    this.form = new UntypedFormGroup({
      is_vacation: new UntypedFormControl(!!this.vacation.is_vacation),
      vacation_begin_at: new UntypedFormControl(
        this.dateFormat.transform(this.vacation?.vacation_begin_at, this.dateFormatString),
        [Validators.required],
      ),
      vacation_end_at: new UntypedFormControl(
        this.dateFormat.transform(this.vacation?.vacation_end_at, this.dateFormatString),
        [Validators.required],
      ),
    });

    this.initialFormValue = this.form.getRawValue();

    this.form
      .get('vacation_begin_at')
      .valueChanges.pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => this.form.get('vacation_end_at').patchValue(null),
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });

    this.form
      .get('is_vacation')
      .valueChanges.pipe(tap(), takeUntil(this.destroyed$))
      .subscribe({
        next: () => this.checkCondition(),
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });
  }

  public isFormUnchanged(): boolean {
    return JSON.stringify(this.initialFormValue) === JSON.stringify(this.form.getRawValue());
  }

  public checkCondition(): void {
    if (this.isActive) {
      this.form?.get('vacation_end_at').enable({ emitEvent: false });
      this.form?.get('vacation_begin_at').enable({ emitEvent: false });
    } else {
      this.form?.get('vacation_end_at').disable({ emitEvent: false });
      this.form?.get('vacation_begin_at').disable({ emitEvent: false });
    }
  }

  public get minDateFrom(): Date {
    return new Date();
  }

  public get minDateTo(): Date {
    return this.form?.get('vacation_begin_at').value;
  }

  public get isActive(): boolean {
    return this.form?.get('is_vacation').value === true;
  }

  public save() {
    if (!this.form.valid || this.saving) {
      return;
    }

    this.saving = true;
    this.saved = false;
    this.error = null;
    this.saveState = 'progress';
    this.detectChanges();

    this.scheduleService
      .updateVacation(new UpdateUserVacationRequest(this.form.value))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          if (response.status) {
            this.form.patchValue(response.model, { emitEvent: false });
            this.saveState = 'done';
            this.setTimeout(() => {
              this.saveState = 'default';
              this.initialFormValue = this.form.getRawValue();
              this.detectChanges();
            }, 1000);
            this.checkCondition();
          } else {
            this.error = response.statusText;
            this.saveState = 'fail';
          }
          this.saved = true;
          this.saving = false;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.error = error.message;
          this.saveState = 'fail';
          this.saving = false;
          this.detectChanges();
        },
      });
  }
}
