<div class="event-popover">
  <div class="header" *ngIf="isShadowedEvent">
    <div class="time-info">
      {{ 'library.user-service-card.access_to_lesson_will_open' | translate }} {{begin_at | dfnsFormat: 'dd.MM.yyyy'}}
    </div>
  </div>
  <div class="body">
    <div class="cover-wrap">
      <on-ui-cover [cover]="cover"></on-ui-cover>
    </div>
    <div class="meta-info">
      <div class="extra-meta-info" *ngIf="userServiceType === 3 || userServiceType === 4; else typeEvent">
        <ul class="extra-meta-list">
          <li>
            <span *ngIf="userServiceType === 4">{{ 'library.user-service-card.type.course' | translate }}</span>
            <span *ngIf="userServiceType === 3">{{ 'library.user-service-card.type.mini' | translate }}</span>
          </li>
          <li>
            {{ sequenceNumber }} {{ 'library.user-service-card.type.of' | translate }} {{ sequenceTotal }}
          </li>
        </ul>
      </div>

      <ng-template #typeEvent>
        <span class="type">{{ 'event-calendar.types.' + eventType() | translate }}</span>
      </ng-template>

      <h4 class="title" [class.oneline]="begin_at" [class.twoline]="!begin_at">{{ title }}</h4>
      <div class="period" *ngIf="begin_at">
        <div class="item">{{ begin_at | zonedDate: me?.timezone | dfnsFormat: 'dd MMM yyy HH:mm' }}</div>
        <div class="item">{{ duration }} {{'meetings.join.min' | translate}}</div>
      </div>
    </div>
    <div class="menu-wrap">
      <ng-container *ngTemplateOutlet="menu"></ng-container>
    </div>
  </div>
  <div class="footer">
    <div class="participants">
      <div class="students" *ngIf="students?.length">
        {{'school-card.students' | translate}}

        <div *ngFor="let participant of students; let i = index; trackBy: trackByFn"
             [style.transform]="'translateX(' + -8 * i + 'px)'"
             class="teacher">
          <!--<on-ui-cover [cover]="avatarParticipant(participant)"></on-ui-cover>-->
        </div>

      </div>
      <div class="teachers" *ngIf="teacher">
        <div class="type">{{'meetings.teacher' | translate}}</div>
        <div class="avatar">
          <on-ui-cover [cover]="teacher.avatar"></on-ui-cover>
        </div>
        <div class="name">
          <a class="teacher-link" [routerLink]="teacher.link">{{teacher.name}}</a>
        </div>
      </div>
    </div>
    <a [routerLink]="meetingJoinRoute" [class.disabled]="!meetingJoinRoute"
       class="join-link">{{ 'dashboard.popup.join-class' | translate }}</a>
  </div>
</div>


<ng-template #menu>
  <on-ui-card-menu icon="menu-circle">
    <a class="menu-item warn" *ngIf="meeting?.purl" [href]="meeting?.purl">[⚠️] Panel [meeting]</a>
    <a class="menu-item warn" *ngIf="user_service?.purl" [href]="user_service?.purl">[⚠️] Panel [service]</a>
    <a class="menu-item warn" *ngIf="user_service_contract?.purl" [href]="user_service_contract?.purl">[⚠️] Panel [contract]</a>
    <div class="menu-item" *ngIf="debug$ | async" (click)="printMeetingInfo()">[DEBUG]</div>
    <a class="menu-item" *ngIf="meetingRoute"
       data-test="show-more"
       [routerLink]="meetingJoinRoute">{{ 'dashboard.popup.details' | translate }}</a>
    <div class="menu-item" *ngIf="can('edit')"
         data-test="edit-meeting"
         (click)="onEditMeeting()">{{ 'meetings.menu-edit' | translate }}</div>
    <div class="menu-item" *ngIf="can('reschedule')"
         data-test="reschedule-meeting"
         (click)="onRescheduleMeeting()">{{ 'dashboard.popup.reschedule' | translate }}</div>
    <div class="menu-item" *ngIf="can('invite')"
         data-test="invite-meeting"
         (click)="onToggleInviteModal()">{{ 'dashboard.popup.invite-subscriber' | translate }}</div>
    <div class="menu-item warn" *ngIf="can('leave') || can('leaveWithPenalty')" data-test="leave-meeting" (click)="onLeaveMeeting()">{{ 'dashboard.label-btn.leave' | translate }}</div>
    <div class="menu-item warn" *ngIf="can('cancel') || can('cancelWithPenalty')" data-test="cancel-meeting" (click)="onCancelMeeting()">{{ 'dashboard.label-btn.cancel' | translate }}</div>
  </on-ui-card-menu>
</ng-template>
