import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { IMenuItem } from '@models/routes';
import { EContentPanelRoute } from 'lingo2-models';

const menuItems: IMenuItem[] = [
  {
    id: 'main',
    label: 'menu.top.main',
    icon: 'header-home',
    route: EContentPanelRoute.main,
    url: `/${EContentPanelRoute.main}`,
  },
  {
    id: 'classes',
    label: 'menu.top.classes',
    icon: 'header-classes',
    route: EContentPanelRoute.classes,
    url: `/${EContentPanelRoute.classes}`,
  },
  {
    id: 'teachers',
    label: 'menu.top.teachers',
    icon: 'header-teachers',
    route: EContentPanelRoute.tutors,
    url: `/${EContentPanelRoute.tutors}`,
  },
  {
    id: 'library',
    label: 'menu.top.library',
    icon: 'header-lessons',
    route: EContentPanelRoute.lessons,
    url: `/${EContentPanelRoute.lessons}`,
  },
];

@Component({
  selector: 'app-mobile-footer',
  templateUrl: './mobile-footer.component.html',
  styleUrls: ['./mobile-footer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MobileFooterComponent implements OnInit {
  public menuItems = menuItems;

  constructor() {}

  ngOnInit(): void {}

  public trackByFn(index) {
    return index;
  }
}
