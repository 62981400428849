<div class="notifications" [class.mobile]="deviceService.isMobile()">
  <div class="container" *ngIf="notices?.length; else noContentStub">
    <ng-container *ngFor="let notice of notices; trackBy: trackByFn">
      <app-notification [notification]="notice"></app-notification>
    </ng-container>
  </div>

  <ng-template #noContentStub>
    <app-no-content-stub
      [icon]="'notifications-stub'"
      [text]="'notifications.notifications-stub'">
    </app-no-content-stub>
  </ng-template>
</div>
