import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { SchoolsService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { CSchool, CSchoolParticipant, ESchoolParticipantRole } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiTab } from 'onclass-ui';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-school-calculations',
  templateUrl: './school-calculations.component.html',
  styleUrls: ['./school-calculations.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolCalculationsComponent extends ChangableComponent implements OnInit {
  private _school: CSchool;
  public activeTab: OnUiTab = {
    code: 'distribution',
    label: 'Распределение дохода',
    route: this.location.path(),
  };

  public tabs: OnUiTab[] = [
    {
      code: 'distribution',
      label: 'school-calculation.income-distribution',
      route: this.location.path(),
      routeCondition: this.condition.bind(this),
    },
    {
      code: 'history',
      label: 'school-calculation.history-calculation',
      route: '',
      routeCondition: this.condition.bind(this),
    },
  ];

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private schoolService: SchoolsService,
    private location: Location,
    protected cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  public ngOnInit(): void {
    this.schoolService.currentSchool$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (school) => {
        this._school = school;
        this.activeTab.code = 'distribution';
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });
  }

  private condition(tab: OnUiTab) {
    this.activeTab = tab;
    this.tabs.map((_t) => (_t.route = ''));
    const selectedFilterIndex = this.tabs.findIndex((_t) => _t.code === this.activeTab.code);
    this.tabs[selectedFilterIndex].route = this.location.path();
    this.detectChanges();
  }

  public get school(): CSchool {
    return this._school;
  }

  public teachersList(): CSchoolParticipant[] {
    return this._school?.participants?.filter((_p) => _p.role === ESchoolParticipantRole.teacher);
  }

  public teachersTrackByFn(index: number, teacher: CSchoolParticipant): string {
    return teacher.account_id;
  }

  public onChanged(e: boolean) {
    if (e) {
      this.loadSchool();
    }
  }

  private loadSchool() {
    this.schoolService
      .getSchoolByIdOrSlug(this._school?.id, undefined, 'LOAD_TIME')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (school) => {
          this._school = school;
          this.schoolService.setCurrentSchool(this._school);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }
}
