import { ChangeDetectorRef, Component, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ConfigService, DropzoneConfig, FilesService, IFileType, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { FilePurposeEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { DropzoneConfigInterface } from 'ngx-dropzone-wrapper';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-userpic',
  templateUrl: './userpic.component.html',
  styleUrls: ['./userpic.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => UserpicComponent),
      multi: true,
    },
  ],
})
export class UserpicComponent extends ChangableComponent implements OnInit, ControlValueAccessor {
  @Input() placeholder: string;
  @Input() maxlength: number;
  @Input() isTeacher: boolean;

  @Input() originalId: string;

  @Output() changed = new EventEmitter<string>();

  public value: string;
  public isDisabled: boolean;

  public userpic_file: IFileType; // TODO File!
  public original_file: IFileType; // Оригинальное изображение
  public userpic_loading = false;
  public dropzoneConfig: DropzoneConfigInterface = {
    acceptedFiles: 'image/jpeg,image/jpg,image/png',
    paramName: 'file',
    uploadMultiple: false,
    clickable: true,
    autoReset: 1,
    errorReset: null,
    cancelReset: null,
    dictDefaultMessage: '',
    timeout: 120 * 1000, // 120s = 2m
    createImageThumbnails: false,
  };
  showCameraScreen = false;
  public cameraError: string;
  public showCropImage = false;
  public filePurpose = FilePurposeEnum.content;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    protected configService: ConfigService,
    protected filesService: FilesService,
    protected dzConfig: DropzoneConfig,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {
    this.configureDropzone();
  }

  private propagateChange: any = () => {};
  private propagateTouched: any = () => {};

  writeValue(value: string): void {
    this.value = value;
    this.loadUserpic(this.value);
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  onChange(value: string): void {
    this.value = value;
    this.propagateChange(value);
    this.changed.emit(value);
    this.detectChanges();
  }

  onBlur(): void {
    this.propagateTouched();
  }

  onShowCamera(): void {
    this.showCameraScreen = !this.showCameraScreen;
  }

  onCancel(): void {
    this.showCameraScreen = false;
  }

  onTakePhoto(evt): void {
    if (evt) {
      this.onCancel();
      this.userpic_file = evt;
      this.onChange(evt.id);
    }
  }

  cameraHaveError(error: string): void {
    this.cameraError = error;
    this.showCameraScreen = false;
  }

  closedTakePhoto(): void {
    this.onCancel();
  }

  protected configureDropzone() {
    this.dropzoneConfig = this.dzConfig.combine(this.dropzoneConfig, {
      url: this.dzConfig.url + '/file/upload+process?purpose=' + FilePurposeEnum.userpic, // особый URL для загрузки
    });
  }

  onCrop(): void {
    if (this.userpic) {
      this.showCropImage = true;
    }
  }

  onImageChange(evt: { type: string; data?: string }): void {
    if (evt.type === 'imageCropped') {
      let origignalImageId = null;
      if (this.original_file && this.original_file.id) {
        origignalImageId = this.original_file.id;
      } else {
        if (!this.userpic_file.parent_file_id) {
          origignalImageId = this.userpic_file.id;
        }
      }
      this.filesService
        .uploadFileByData(evt.data, this.filePurpose, String(Date.now()), origignalImageId)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (file) => {
            this.setAvatar(file);
            this.loadUserpic(file.id);
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'LOAD-FILE');
          },
        });
      this.showCropImage = false;
    } else if (evt.type === 'cancelCropped') {
      this.showCropImage = false;
    }
  }

  public setAvatar(cover: IFileType) {
    // this.userpic_file = cover;
    this.onChange(cover.id);
  }

  public get userpic(): string {
    if (!this.userpic_file) {
      return null;
    }
    return this.userpic_file.storage_file_url;
  }

  public get imgWide(): string {
    if (!this.original_file) {
      if (!this.userpic_file) {
        return null;
      } else {
        return this.userpic_file.storage_file_url;
      }
    } else {
      return this.original_file.storage_file_url;
    }
  }

  protected loadUserpic(id: string) {
    // const id = this.form.get('userpic_id').value;

    if (!id) {
      // нет картинки
      this.userpic_file = null;
      return;
    }
    if (this.userpic_file && this.userpic_file.id === id) {
      // та же самая картинка
      return;
    }
    if (this.userpic_loading) {
      // уже загружается
      return;
    }
    this.userpic_loading = true;
    this.filesService
      .getFileById(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (file) => {
          this.userpic_file = file;
          this.userpic_loading = false;
          if (this.userpic_file.parent_file_id) {
            this.getOriginalImage(this.userpic_file.parent_file_id);
          }
          this.markForCheck();
        },
        error: (error: any) => {
          this.errorNotificationService.captureError(error, 'LOAD-FILE');
          this.userpic_file = null;
          this.userpic_loading = false;
          this.markForCheck();
        },
      });
  }

  public getOriginalImage(id: string) {
    this.filesService
      .getFileById(id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (file) => {
          this.original_file = file;
          this.markForCheck();
        },
        error: (error: any) => {
          this.errorNotificationService.captureError(error, 'LOAD-FILE');
          this.original_file = null;
          this.markForCheck();
        },
      });
  }

  public removeUserPic(e: Event) {
    e.preventDefault();
    this.userpic_file = null;
    this.original_file = null;
    this.onChange(null);
  }

  public onUploadInit(args: any): void {
    // console.debug('ProfileFormAboutComponent:dropzone:onUploadInit', args);
  }

  public onUploadStart(args: any): void {
    // logger.log('ProfileFormAboutComponent:dropzone:onUploadStart', args);
    this.userpic_loading = true;
  }

  public onUploadError(args: any): void {
    // logger.log('ProfileFormAboutComponent:dropzone:onUploadError', args);
    this.userpic_loading = false;
  }

  public onUploadSuccess(args: any): void {
    // logger.log('ProfileFormAboutComponent:dropzone:onUploadSuccess', args);
    this.userpic_loading = false;
    const [, cover] = args;

    this.userpic_file = cover;
    this.original_file = null;
    this.onCrop();
    this.onChange(cover.id);
  }

  public openCameraAccess(): void {}
}
