<article class="vocabulary-card"
         itemscope
         itemtype="https://schema.org/Article"
         [class.mobile]="deviceService.isMobile()">
  <div class="card" [class.active]="selectedList?.id === item.id">
    <div class="info">
      <div class="left" (click)="openDetails()">
        <div class="cover-wrap">
          <on-ui-cover [cover]="cover()"></on-ui-cover>
        </div>
        <div>
          <p class="title">{{item.title}}</p>
          <div class="details" *ngIf="false">
            {{'my-vocabulary.dictionaries' | translate}}: {{item.stats_vocabulary_count}}
            <div class="delimiter"></div>
            {{'my-vocabulary.words' | translate}}: {{item.stats_word_count}}
          </div>
        </div>
      </div>
      <div class="right" *ngIf="withActions">
        <ng-template [ngIf]="deviceService.isMobile()"
                     [ngIfThen]="mobileActionsRef"
                     [ngIfElse]="webActionsRef">
        </ng-template>
        <ng-template #webActionsRef>
          <div class="actions">
            <span class="action" (click)="onAction('add-word')">{{'my-vocabulary.action.add-word' | translate}}</span>
            <span class="action" (click)="onAction('edit')">{{'my-vocabulary.action.edit' | translate}}</span>
            <ng-container *ngTemplateOutlet="vocabulariesModal"></ng-container>
          </div>
          <on-ui-card-menu>
            <a class="menu-item" (click)="onAction('delete')">{{'my-vocabulary.action.delete' | translate}}</a>
            <a class="menu-item" (click)="onAction('duplicate')">{{'my-vocabulary.action.duplicate' | translate}}</a>
          </on-ui-card-menu>
        </ng-template>

        <ng-template #mobileActionsRef>
          <on-ui-card-menu>
            <a class="menu-item" (click)="onAction('add-word')">{{'my-vocabulary.action.add-word' | translate}}</a>
            <a class="menu-item" (click)="onAction('edit')">{{'my-vocabulary.action.edit' | translate}}</a>
            <a class="menu-item" (click)="onAction('delete')">{{'my-vocabulary.action.delete' | translate}}</a>
            <a class="menu-item" (click)="onAction('duplicate')">{{'my-vocabulary.action.duplicate' | translate}}</a>
          </on-ui-card-menu>
        </ng-template>
      </div>
    </div>
    <div *ngIf="deviceService.isMobile() && withActions" class="footer">
      <div class="actions">
        <ng-container *ngTemplateOutlet="vocabulariesModal"></ng-container>
      </div>
    </div>
  </div>
</article>

<ng-template #vocabulariesModal>
  <div class="vocabulary-filter" *ngIf="false">
    <div class="vocabulary-filter__btn" (click)="item.stats_vocabulary_count ? onAction('create-lesson') : ''">
      <span class="action"
            [class.disabled]="!item.stats_vocabulary_count">{{'my-vocabulary.action.create-lesson' | translate}}</span>
    </div>

    <div class="vocabulary-filter-popup" *ngIf="popup_open">
      <div class="shade" (click)="popup_open=false"></div>
      <div class="window popup-right">
        <p class="popup-title">{{'my-vocabulary.choose-dictionary' | translate}}</p>
        <p class="text list" *ngFor="let v of vocabularies; trackBy: trackByFn" (click)="navigateToConstructor(v)">
          {{getLanguageName(v.term_language_id)}}
          -
          {{getLanguageName(v.definition_language_id)}}</p>
      </div>
    </div>
  </div>
</ng-template>
