<div class="list-form"
     [class.mobile]="deviceService.isMobile()">
  <h2 class="title">
    {{ (isNew ? 'my-vocabulary.create-list' : 'my-vocabulary.edit-list') | translate }}
  </h2>

  <div *ngIf="form" class="form" [formGroup]="form">

    <div class="content">
      <div class="cover">
        <app-form-field [control]="form.controls.image_id">
          <app-image-uploader class="control"
                              formControlName="image_id">
          </app-image-uploader>
        </app-form-field>
      </div>
      <div class="meta">
        <app-form-field [required]="isInvalid('title')" [control]="form.controls.title" class="title-field">
          <on-ui-input class="control title"
                     formControlName="title"
                     [maxlength]="90"
                     [hasCounter]="true"
                     [placeholder]="'my-vocabulary.title' | translate">
          </on-ui-input>
        </app-form-field>

        <div class="actions">
          <on-ui-button [state]="saveState"
                        class="button primary"
                        [label]="isNew ? 'classrooms.form.actions.create' : 'classrooms.form.actions.save'"
                        (click)="save()">
          </on-ui-button>
        </div>
      </div>
    </div>
  </div>
</div>
