import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormFieldModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { ClipboardModule } from 'ngx-clipboard';
import { OnUiButtonModule, OnUiCoverModule, OnUiInputModule } from 'onclass-ui';
import { InviteToSchoolComponent } from './invite-to-school.component';
import { SchoolUserCardComponent } from './school-user-card/school-user-card.component';

@NgModule({
  declarations: [InviteToSchoolComponent, SchoolUserCardComponent],
  exports: [InviteToSchoolComponent],
  imports: [
    ClipboardModule,
    CommonModule,
    FormFieldModule,
    IconComponent,
    OnUiButtonModule,
    OnUiInputModule,
    ReactiveFormsModule,
    TranslateModule,
    OnUiCoverModule,
  ],
})
export class InviteToSchoolModule {}
