<ng-container *ngIf="showRulesWarning">
  <div class="rules-warning" [class.mobile]="deviceService.isMobile()">
    <ng-container *ngIf="!deviceService.isMobile(); else mobileView">
      <div class="right">
        <img class="bulb-icon" [src]="assetsUrl('/assets/images/rules-icon.png')" alt="">
        <p class="text">{{'rules.widget.text' | translate}}</p>
      </div>
      <div class="left">
        <on-ui-button state="default"
                      class="transparent"
                      [label]="'rules.widget.more' | translate"
                      (click)="openRulesModal()">
        </on-ui-button>
      </div>
    </ng-container>

    <ng-template #mobileView>
      <div class="right">
        <img class="bulb-icon" [src]="assetsUrl('/assets/images/rules-icon.png')" alt="">
      </div>
      <div class="left">
        <p class="text">{{'rules.widget.text' | translate}}</p>
        <p class="link" (click)="openRulesModal()">{{'rules.widget.more' | translate}}</p>
      </div>
    </ng-template>
  </div>
</ng-container>

<section class="calendar"
         [class.mobile]="deviceService.isMobile()">

  <div *ngIf="isConfirmationWaiting" class="loading">
    <div class="empty">
      <app-icon class="empty-icon" icon="loading"></app-icon>
      <span class="empty-text" [innerHTML]="'event-calendar.empty-text' | translate"></span>
    </div>
  </div>

  <ng-template [ngIf]="!deviceService.isMobile()" [ngIfElse]="mobile">
    <ng-container *ngTemplateOutlet="directionBlock"></ng-container>
    <hr class="separator">
    <ng-container *ngTemplateOutlet="actions"></ng-container>

    <mwl-calendar-week-view [hourSegmentHeight]="mwlCalendarConfig.minCellHeight"
                            [minimumEventHeight]="mwlCalendarConfig.minCellHeight"
                            [hourSegmentTemplate]="hourSegmentTemplate"
                            [headerTemplate]="headerTemplate"
                            [eventTemplate]="eventTemplate"
                            [viewDate]="viewDate"
                            [events]="mwlCalendarEvents"
                            [refresh]="mwlCalendarRefresh$"
                            [locale]="mwlCalendarConfig.locale"
                            [weekStartsOn]="mwlCalendarConfig.weekStartsOn"
                            (eventClicked)="handleEvent('Clicked', $event.event)"
                            (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-week-view>
  </ng-template>

  <ng-template #mobile>
    <ng-container *ngTemplateOutlet="actions"></ng-container>

    <div class="scroll-selector-wrap">
      <app-scroll-selector [events]="currentWeekEvents"
                           [viewDate]="viewDate"
                           (shiftDais)="onShiftDais($event)">
      </app-scroll-selector>
      <hr class="separator">
    </div>

    <mwl-calendar-day-view [hourSegmentHeight]="mwlCalendarConfig.minCellHeight"
                           [minimumEventHeight]="mwlCalendarConfig.minCellHeight"
                           [hourSegmentTemplate]="hourSegmentMobileTemplate"
                           [eventTemplate]="eventTemplate"
                           [viewDate]="viewDate"
                           [events]="mwlCalendarEvents"
                           [refresh]="mwlCalendarRefresh$"
                           [locale]="mwlCalendarConfig.locale"
                           (eventClicked)="handleEvent('Clicked', $event.event)"
                           (eventTimesChanged)="eventTimesChanged($event)">
    </mwl-calendar-day-view>
  </ng-template>
</section>

<ng-template #directionBlock>
  <div class="direction-block">
    <div class="col">
      <div class="direction-item">
        <div class="color finished"></div>
        <span class="label">{{ 'event-calendar.status.finished' | translate }}</span>
      </div>
      <div class="direction-item">
        <div class="color scheduled"></div>
        <span class="label">{{ 'event-calendar.status.scheduled' | translate }}</span>
      </div>
      <div class="direction-item">
        <div class="color accept-required"></div>
        <span class="label">{{ 'event-calendar.status.accept-required' | translate }}</span>
      </div>
    </div>
    <div class="col">
      <div class="direction-item">
        <div class="color started"></div>
        <span class="label">{{ 'event-calendar.status.started' | translate }}</span>
      </div>
      <div class="direction-item">
        <div class="color upcoming"></div>
        <span class="label">{{ 'event-calendar.status.upcoming' | translate }}</span>
      </div>
      <div class="direction-item">
        <div class="color failed"></div>
        <span class="label">{{ 'event-calendar.status.failed' | translate }}</span>
      </div>
    </div>
    <div class="col">
      <div class="direction-item">
        <div class="color vacation-days"></div>
        <span class="label">{{ 'event-calendar.status.vacation-days' | translate }}</span>
      </div>
      <div class="direction-item">
        <div class="color available"></div>
        <span class="label">{{ 'event-calendar.status.available' | translate }}</span>
      </div>
      <div class="direction-item">
        <div class="color day-off"></div>
        <span class="label">{{ 'event-calendar.status.day-off' | translate }}</span>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #actions>
  <div [formGroup]="form" class="actions">
    <div class="action prev"
         mwlCalendarPreviousView
         [view]="mwlCalendarConfig.view"
         [(viewDate)]="viewDate"
         (viewDateChange)="onWeekShift()">
      <app-icon class="action-icon" icon="arrow-schedule"></app-icon>
    </div>
    <on-ui-date formControlName="date"
                startView="month"
                [inputDisplayed]="false"
                (changed)="onChangedDate($event)">
      <div class="view-date">
        {{ viewDate | calendarDate:(mwlCalendarConfig.view + 'ViewTitle'):mwlCalendarConfig.locale:mwlCalendarConfig.weekStartsOn }}
      </div>
    </on-ui-date>
    <div class="action next"
         data-test="next-week"
         mwlCalendarNextView
         [view]="mwlCalendarConfig.view"
         [(viewDate)]="viewDate"
         (viewDateChange)="onWeekShift()">
      <app-icon class="action-icon" icon="arrow-schedule"></app-icon>
    </div>
  </div>
  <app-timezone class="timezone-wrap"></app-timezone>
</ng-template>

<ng-template #hourSegmentTemplate
             let-segment="segment"
             let-locale="locale"
             let-isTimeLabel="isTimeLabel">
  <ng-template [ngIf]="isTimeLabel" [ngIfElse]="hour">
    <div class="hour-label-block">
      <ng-template [ngIf]="(segment.date | dfnsFormat: 'mm') != '30'">
        <span class="hour-label">{{ segment.date | dfnsFormat: 'HH:mm' }}</span>
      </ng-template>
    </div>
  </ng-template>

  <ng-template #hour>
    <ng-template [ngIf]="isPastTime(segment.date)" [ngIfElse]="teacher">
      <div class="hour-segment past-time"></div>
    </ng-template>
    <ng-template #teacher>
      <ng-template [ngIf]="isTeacher || !isAuth"
                   [ngIfThen]="hourSegmentTeacherRef"
                   [ngIfElse]="hourSegmentDefaultRef">
      </ng-template>
      <ng-template #hourSegmentTeacherRef>
        <div class="hour-segment"
             [class.selected]="isSelectedHourSegment(segment?.date, currentWeekViewHourSegment?.date)"
             [appPopover]="hourSegmentPopoverRef"
             position="bottom"
             popoverOnly="true"
             data-test="hour-segment"
             [close]="closedPopover"
             (opened)="onSetCurrentSegment($event, segment)">
        </div>
      </ng-template>
      <ng-template #hourSegmentDefaultRef>
        <div class="hour-segment student"></div>
      </ng-template>
    </ng-template>
  </ng-template>
</ng-template>

<ng-template #hourSegmentMobileTemplate
             let-segment="segment"
             let-locale="locale"
             let-isTimeLabel="isTimeLabel">
  <div class="hour-segment-mobile">
    <div class="hour-label-block">
      <ng-template [ngIf]="(segment.date | dfnsFormat: 'mm') != '30'">
        <span class="hour-label">{{ segment.date | dfnsFormat: 'HH:mm' }}</span>
      </ng-template>
    </div>

    <ng-template [ngIf]="isPastTime(segment.date)" [ngIfElse]="hourSegment">
      <div class="hour-segment past-time"></div>
    </ng-template>

    <ng-template #hourSegment>
      <div class="hour-segment"
           [class.selected]="isSelectedHourSegment(segment?.date, currentWeekViewHourSegment?.date)"
           [appPopover]="hourSegmentPopoverRef"
           position="bottom"
           popoverOnly="true"
           data-test="hour-segment"
           [close]="closedPopover"
           (opened)="onSetCurrentSegment($event, segment)">
      </div>
    </ng-template>
  </div>
</ng-template>

<ng-template #headerTemplate let-days="days">
  <div class="header-template">
    <div class="header-icon-wrap">
      <svg viewBox="0 0 22 22" class="header-icon">
        <use xlink:href="#schedule"></use>
      </svg>
    </div>
    <div *ngFor="let day of days; trackBy: trackByFn" class="day-label">
      {{ day.date | dfnsFormat: 'iii.'}} {{ day.date | dfnsFormat: 'd'}}
    </div>
  </div>
</ng-template>

<ng-template #eventTemplate
             let-tooltipTemplate="tooltipTemplate"
             let-weekEvent="weekEvent">
  <div class="event-template"
       [attr.title]="eventFullTitle(weekEvent)"
       [appPopover]="hourSegmentPopoverRef"
       position="{{calcEventPosition(weekEvent)}}"
       popoverOnly="true"
       data-test="event"
       [close]="closedPopover"
       [ngClass]="eventStatus(weekEvent)"
       [class.event-in-vacation]="weekEvent?.event?.meta?.alert === 'vacation'"
       (opened)="onSetCurrentEvent($event, weekEvent)">

    <ng-template [ngIf]="weekEventCoverUrl(weekEvent)">
      <img [src]="weekEventCoverUrl(weekEvent)" [attr.alt]="eventFullTitle(weekEvent)" class="cover">
    </ng-template>

    <span class="title-event">{{ weekEvent?.event?.title }}</span>
  </div>
</ng-template>

<ng-template #hourSegmentPopoverRef>
  <ng-container [ngSwitch]="hourSegmentType(currentWeekEvent)">
    <ng-container *ngSwitchCase="'meeting'">
      <app-event-popover [event]="currentWeekEvent?.event?.meta"
                         (inviteMeeting)="onInviteMeeting($event)"
                         (leaveMeeting)="onLeaveMeeting($event)"
                         (cancelMeeting)="onCancelMeeting($event)"
                         (editMeetingId)="onEditMeeting($event)"
                         (closePopover)="closePopover()">
      </app-event-popover>
    </ng-container>

    <ng-container *ngSwitchCase="'service'">
      <app-user-service-multi-selector *ngIf="deviceService.isDesktop()"
                                       [userServices]="userServices"
                                       [events]="mwlCalendarEvents"
                                       [currentDayOfWeek]="currentDayOfWeek"
                                       [currentSegmentOrEvent]="currentWeekEvent"
                                       [context]="context"
                                       [popoverMode]="true"
                                       [readonly]="!canScheduleServices"
                                       [teacher]="scheduleServiceTeacher"
                                       (saved)="onUserServicesSaved()">
      </app-user-service-multi-selector>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div class="context-menu" data-test="context-menu">
        <div class="context-menu-item"
             *ngIf="canScheduleMeeting"
             data-test="new-class"
             (click)="onScheduleMeeting()">{{ 'event-calendar.context-menu.schedule-event' | translate }}</div>

        <div class="context-menu-item"
             *ngIf="canScheduleServices"
            (click)="openUserServicesDialog()">{{ 'event-calendar.context-menu.assign-class' | translate }}</div>

        <div class="context-menu-item"
             *ngIf="canMarkAvailableHour && isStatusAvailable(currentWeekEvent?.event?.meta)"
             data-test="non-working"
            (click)="onMarkOffHour()">{{ 'event-calendar.context-menu.not-available-for-booking' | translate }}</div>

        <div class="context-menu-item"
             *ngIf="canMarkAvailableHour && !isStatusAvailable(currentWeekEvent?.event?.meta)"
             data-test="working"
             (click)="onMarkAvailableHour()">{{ 'event-calendar.context-menu.available-for-booking' | translate }}</div>

        <div class="context-menu-item"
             *ngIf="debug"
             (click)="infoEvent(currentWeekEvent?.event?.meta)">{{ '[debug] Инфо' }}</div>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<ng-template [ngIf]="meetingFormOpened">
  <app-modal *ngIf="deviceService.isDesktop(); else mobileMeetingForm" [canClose]="false" (closed)="closeMeetingForm()">
    <app-modal-body>
      <app-meeting-form-wrapper [day]="dateWithoutTime(currentDayOfWeek)"
                                [minute]="minutes(currentDayOfWeek)"
                                [meetingId]="editMeetingId"
                                [caller]="'app-events-calendar'">
      </app-meeting-form-wrapper>
    </app-modal-body>
  </app-modal>

  <ng-template #mobileMeetingForm>
    <app-mobile-modal (closed)="closeMeetingForm()">
      <app-mobile-modal-title>{{ 'lingo2-forms.meetings.form-title' | translate }}</app-mobile-modal-title>
      <app-mobile-modal-body>
        <app-meeting-form-wrapper [day]="dateWithoutTime(currentDayOfWeek)"
                                  [minute]="minutes(currentDayOfWeek)"
                                  [meetingId]="editMeetingId"
                                  [caller]="'app-events-calendar'">
        </app-meeting-form-wrapper>
      </app-mobile-modal-body>
    </app-mobile-modal>
  </ng-template>
</ng-template>

<ng-template [ngIf]="userServicesDialogOpened">
  <app-modal *ngIf="deviceService.isDesktop(); else mobileUserServices"
             (closed)="closeUserServicesDialog()">
    <app-modal-body>
      <app-user-service-multi-selector [userServices]="userServices"
                                       [events]="mwlCalendarEvents"
                                       [currentDayOfWeek]="currentDayOfWeek"
                                       [currentSegmentOrEvent]="currentSegmentOrEvent"
                                       [context]="context"
                                       [readonly]="!canScheduleServices"
                                       [teacher]="scheduleServiceTeacher"
                                       (saved)="onUserServicesSaved()">
      </app-user-service-multi-selector>
    </app-modal-body>
  </app-modal>

  <ng-template #mobileUserServices>
    <app-mobile-modal [titleVisible]="false">
      <app-mobile-modal-body>
        <app-user-service-multi-selector [userServices]="userServices"
                                         [events]="mwlCalendarEvents"
                                         [currentDayOfWeek]="currentDayOfWeek"
                                         [currentSegmentOrEvent]="currentSegmentOrEvent"
                                         [context]="context"
                                         [readonly]="!canScheduleServices"
                                         [teacher]="scheduleServiceTeacher"
                                         (saved)="onUserServicesSaved()">
        </app-user-service-multi-selector>
      </app-mobile-modal-body>
    </app-mobile-modal>
  </ng-template>
</ng-template>

<ng-template [ngIf]="inviteModal">
  <app-modal *ngIf="deviceService.isDesktop(); else mobileInviteModal"
             [schedule]="true"
             [canClose]="false"
             (closed)="closeInviteModal()">
    <app-modal-body>
      <app-invite-to-meeting [meeting]="inviteMeeting"></app-invite-to-meeting>
    </app-modal-body>
  </app-modal>

  <ng-template #mobileInviteModal>
    <app-mobile-modal [titleVisible]="true"
                      (closed)="closeInviteModal()">
      <app-mobile-modal-body>
        <app-invite-to-meeting [meeting]="inviteMeeting"></app-invite-to-meeting>
      </app-mobile-modal-body>
    </app-mobile-modal>
  </ng-template>
</ng-template>

<ng-template [ngIf]="isRulesModalOpened">
  <app-modal *ngIf="!deviceService.isMobile()"
             class="rules-modal"
             (closed)="closeRulesModal()">
    <app-modal-body>
      <app-rules (onAgree)="acceptRules()"></app-rules>
    </app-modal-body>
  </app-modal>

  <app-mobile-modal *ngIf="deviceService.isMobile()"
                    (closed)="closeRulesModal()">
    <app-mobile-modal-body>
      <app-rules (onAgree)="acceptRules()"></app-rules>
    </app-mobile-modal-body>
  </app-mobile-modal>
</ng-template>

<app-alert *ngIf="confirmationAlert && confirmationOptions"
           [type]="confirmationOptions.type"
           [btnPosition]="'center'">
  <ng-container *ngIf="confirmationOptions.icon" alert-icon>
    <app-icon width="34" height="36" file="emoji" icon="think"></app-icon>
  </ng-container>
  <ng-container alert-title>{{confirmationOptions.title | translate}}</ng-container>
  <ng-container alert-body>{{confirmationOptions.description | translate}}</ng-container>
  <ng-container alert-buttons>
    <on-ui-button label="{{ confirmationOptions.close_button_text }}"
                  data-test="cancel-confirm"
                  state="default"
                  class="transparent"
                  (onclick)="closeConfirmationAlert()">
    </on-ui-button>
    <on-ui-button label="{{ confirmationOptions.confirm_button_text }}"
                  state="default"
                  data-test="confirm"
                  class="primary center"
                  (onclick)="confirm()">
    </on-ui-button>
  </ng-container>
</app-alert>
