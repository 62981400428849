// eslint-disable-next-line import/order

import { NgModule, ErrorHandler, APP_INITIALIZER } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { Router } from '@angular/router';
// import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '@env/environment';
import { createErrorHandler, TraceService } from '@sentry/angular';
import { ToastrModule } from 'ngx-toastr';

import { AppComponent } from './app.component';
import { AppModule } from './app.module';

@NgModule({
  imports: [
    BrowserAnimationsModule,
    AppModule,
    ToastrModule.forRoot(),
    // ServiceWorkerModule.register('ngsw-worker.js', {
    //   enabled: true,
    //   registrationStrategy: 'registerWhenStable:30000',
    // }),
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: createErrorHandler({
        showDialog: !environment.production,
      }),
    },
    {
      provide: TraceService,
      deps: [Router],
      useValue: undefined,
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [TraceService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppBrowserModule {}
