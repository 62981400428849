import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { remoteConfigDefaults, RemoteConfigType } from '@app/core/services/remote-config/constants';
import { RemoteConfigService } from '@app/core/services/remote-config/remote-config.service';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { AuthService, BillingService, FilesService, VocabularyService } from '@core/services';
import { OpenAiService } from '@core/services/lingo2-content/open-ai.service';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { VocabularyTerm } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { debounceTime, filter, mergeMap, takeUntil } from 'rxjs/operators';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-vocabulary-form-added',
  standalone: true,
  imports: [SharedModule, ReactiveFormsModule, SpinnersModule],
  templateUrl: './vocabulary-form-added.component.html',
  styleUrls: ['./vocabulary-form-added.component.scss'],
})
export class VocabularyFormAddedComponent extends ChangableComponent implements OnInit {
  @Input() vocabulary_list_id: number;
  @Input() student_id: string;
  @Output() added = new EventEmitter<VocabularyTerm>();

  public form: UntypedFormGroup;
  public loading: boolean;
  public isFocusLeft: boolean;
  public isFocusRight: boolean;
  private remoteConfig: RemoteConfigType;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private authService: AuthService,
    private billingService: BillingService,
    private openAiService: OpenAiService,
    private remoteConfigService: RemoteConfigService,
    private vocabularyService: VocabularyService,
    private fb: UntypedFormBuilder,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  public ngOnInit(): void {
    this.form = this.fb.group({
      term: ['', [Validators.required]],
      definition: ['', [Validators.required]],
    });

    this.form
      .get('term')
      .valueChanges.pipe(
        filter((term) => !!term && term?.length >= 3),
        debounceTime(500),
        mergeMap((term) => {
          this.loading = true;
          return this.openAiService.translateWord(
            term,
            this.vocabularyService.term_language_name,
            this.vocabularyService.definition_language_name,
          );
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (translation) => {
          this.form.get('definition').setValue(translation);
          this.loading = false;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
          this.loading = false;
          this.detectChanges();
        },
      });

    this.remoteConfigService.config$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (config) => {
        this.remoteConfig = config;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.remoteConfig = remoteConfigDefaults;
        this.detectChanges();
      },
    });
  }

  public onAddWords() {
    if (this.form.invalid || this.loading) {
      return;
    }

    const options = {
      caller: 'app-vocabulary-form-added',
      reason: 'add vocabulary word',
    };

    // TODO this.analytics.event(EventActionEnum.vocabulary_creating, EventCategoryEnum.content, options.caller);

    const _continue = () => {
      if (this.remoteConfig?.createVocabulary__paywall) {
        // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
        this.billingService.showPaywall(() => this.addWords(), options);
      } else {
        // сразу отправить на целевую страницу
        this.addWords();
      }
    };

    if (this.remoteConfig?.createVocabulary__auth) {
      // показать диалог авторизации, а после авторизации продолжить
      if (!this.authService.isAuthenticated) {
        this.authService.showAuthModal(() => _continue(), options);
      } else {
        _continue();
      }
    } else {
      // продолжить даже если не авторизован
      _continue();
    }
  }

  public addWords(): void {
    this.loading = true;
    this.vocabularyService
      .createVocabularyTerm({
        ...this.form.value,
        term_language_id: this.vocabularyService.term_language_id,
        definition_language_id: this.vocabularyService.definition_language_id,
        vocabulary_list_id: this.vocabulary_list_id ? this.vocabulary_list_id : null,
        participant_ids: this.student_id ? [this.student_id] : null,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.loading = false;
          this.added.emit(Array.isArray(res) ? res[0] : res);
          this.form.reset();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.loading = false;
        },
      });
  }

  public onFocusLeft() {
    this.isFocusLeft = !this.isFocusLeft;
  }

  public onFocusRight() {
    this.isFocusRight = !this.isFocusRight;
  }
}
