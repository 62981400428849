import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

// Модули
import { AuthPageComponent } from '@app/auth/auth-page/auth-page.component';
import { AuthModalModule } from '@core/components/auth-modal/auth-modal.module';
import { CoreModule } from '@core/core.module';
import { AuthEmergencyPageModule } from './auth-emergency-page/auth-emergency-page.module';

// страницы
import { AuthSignoutPageComponent } from './auth-signout-page/auth-signout-page.component';

@NgModule({
  imports: [CommonModule, RouterModule, CoreModule, AuthModalModule, AuthEmergencyPageModule],
  declarations: [AuthSignoutPageComponent, AuthPageComponent],
})
export class AuthModule {}
