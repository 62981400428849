import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ChangableComponent } from '@models/changable.component';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-vocabulary-learned',
  templateUrl: './vocabulary-learned.component.html',
  styleUrls: ['./vocabulary-learned.component.scss'],
})
export class VocabularyLearnedComponent extends ChangableComponent implements OnInit {
  constructor(protected readonly cdr: ChangeDetectorRef, public deviceService: DeviceDetectorService) {
    super(cdr);
  }

  ngOnInit(): void {}
}
