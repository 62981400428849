<div class="job-page">
  <app-header></app-header>
  <main class="main">
    <app-teacher (becomeTeacher)="onBecomeTeacher($event)"></app-teacher>
    <app-advantages></app-advantages>
    <app-whoisneed></app-whoisneed>
    <app-opportunities></app-opportunities>
    <app-whybetter></app-whybetter>
    <app-instruments></app-instruments>
    <app-howgetnew></app-howgetnew>
    <app-promoblue></app-promoblue>
    <app-digital-content></app-digital-content>
    <app-mobile-app></app-mobile-app>
    <app-reviews></app-reviews>
    <app-promo-footer (becomeTeacher)="onBecomeTeacher($event)"></app-promo-footer>
  </main>
  <app-job-footer></app-job-footer>
</div>
