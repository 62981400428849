import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CoreModule } from '@core/core.module';
import { OnUiButtonModule } from 'onclass-ui';
import { AuthEmergencyPageComponent } from './auth-emergency-page.component';

@NgModule({
  imports: [CommonModule, RouterModule, CoreModule, ReactiveFormsModule, OnUiButtonModule],
  declarations: [AuthEmergencyPageComponent],
  exports: [AuthEmergencyPageComponent],
  providers: [],
})
export class AuthEmergencyPageModule {}
