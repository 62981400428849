import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: 'edit/:collection_id',
        loadChildren: () =>
          import('./pages/collection-form-page/collection-form-page.module').then((m) => m.CollectionFormPageModule),
        data: { preload: true },
      },
      {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'edit/new',
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CollectionsConstructorRoutingModule {}
