<div class="card" [class.card_mobile]="deviceService.isMobile()">
  <div class="card__content">
    <form [formGroup]="form" novalidate autocomplete="off">
      <p class="label-title">{{ 'profile-form.experience.title' | translate }}</p>
      <p class="hint-title"></p>
      <!-- FormArray: work_experience -->
      <div class="no-experience-field">
        <on-ui-checkbox formControlName="noExperience"
                        data-test="no-experience">
          {{ 'profile-form.experience.no-working-experience' | translate }}
        </on-ui-checkbox>
      </div>

      <div formArrayName="work_experience" *ngIf="!form.controls.noExperience.value">

        <div *ngFor="let group of workExperiences.controls; let idx = index; trackBy: trackByFn"
          [formGroupName]="idx">

          <app-form-field class="field" [control]="form.controls.work_experience">

            <div class="profile-form-group" [class.form-group_mobile]="deviceService.isMobile()">

              <div class="profile-form-group__content">

                <div class="row">
                  <app-form-field class="field" [control]="group.controls.organization">
                    <on-ui-input formControlName="organization"
                                 data-test="organization"
                                 class="col col_lg"
                                 [placeholder]="'profile-form.experience.organization' | translate"
                                 [maxlength]="200">
                    </on-ui-input>
                  </app-form-field>
                </div>

                <div class="row">
                  <app-form-field class="field" [control]="group.controls.position">
                    <on-ui-input formControlName="position"
                                 data-test="position"
                                 class="col col_lg"
                                 [placeholder]="'profile-form.experience.position' | translate"
                                 [maxlength]="200">
                    </on-ui-input>
                  </app-form-field>
                </div>

                <div class="row">
                  <app-form-field class="field" [control]="group.controls.begin_year">
                    <on-ui-select class="col col_sm"
                                  data-test="begin-year"
                                  formControlName="begin_year"
                                  [error]="isInvalid(group, 'begin_year')"
                                  [options]="yearsOptionsFrom"
                                  (changed)="experienceFrom($event)"
                                  placeholder="{{ 'profile-form.experience.from' | translate }}">
                    </on-ui-select>
                  </app-form-field>

                  <app-form-field class="field" [control]="group.controls.end_year">
                    <on-ui-select class="col col_sm"
                                  data-test="end-year"
                                  formControlName="end_year" [options]="yearsOptionsTo"
                                  [error]="isInvalid(group, 'end_year')"
                                  (changed)="experienceTo($event)"
                                  placeholder="{{ 'profile-form.experience.to' | translate }}">
                    </on-ui-select>
                  </app-form-field>
                </div>


              </div>

              <div class="profile-form-group__actions">
                <app-icon
                  data-test="remove-work-experience"
                  class="trash-icon"
                  file="basket"
                  icon="basket-3"
                  [width]="30"
                  [height]="30"
                  (click)="removeWorkExperience(idx)"
                  [class.visibility-hidden]="workExperiences.controls.length === 1">
                </app-icon>
              </div>

            </div>

          </app-form-field>

        </div>

        <button class="add-input"
                data-test="add-work-experience"
                (click)="addWorkExperience()"
                [disabled]="form.disabled">
          {{ 'profile-form.add-more' | translate }}
        </button>

      </div>
    </form>
  </div>

  <div class="card__actions">
    <on-ui-button [state]="'default'"
                  class="primary outline margin-right-20"
                  label="profile-form.back"
                  data-test="profile-back"
                  (click)="navigateBack()">
    </on-ui-button>
    <on-ui-button [disabled]="form.invalid && !form.controls.noExperience.value"
                  [state]="saveState"
                  class="primary"
                  label="profile-form.next"
                  data-test="profile-next"
                  (click)="navigateToNextStep()">
    </on-ui-button>
  </div>

  <!-- <pre>{{ form.value | json }}</pre> -->

</div>
