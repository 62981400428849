import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '@core/services';
import { DestroyableComponent } from '@models/destroyable.component';
import { ToastrService } from 'ngx-toastr';
import { OnUiButtonState } from 'onclass-ui';
import { of } from 'rxjs';
import { catchError, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-auth-emergency-page',
  templateUrl: 'auth-emergency-page.component.html',
  styleUrls: ['./auth-emergency-page.component.scss'],
})
export class AuthEmergencyPageComponent extends DestroyableComponent {
  public form = this.fb.group({
    id: ['', [Validators.required]],
    code: ['', [Validators.required]],
  });
  public saveState: OnUiButtonState = 'default';

  constructor(
    private fb: UntypedFormBuilder,
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
  ) {
    super();
  }

  public doSignin() {
    const { id, code } = this.form.value;
    // if (code.length >= 0) {
    //   this.authService
    //     .emergencySignin(id, code)
    //     .pipe(
    //       takeUntil(this.destroyed$),
    //     )
    //     .subscribe({
    //       next: () => {
    //         this.toastr.success('Emergency Signin');
    //         this.router.navigateByUrl('/').then();
    //       },
    //       error: (err: any) => {
    //         this.toastr.error(err.message, 'Emergency Signin');
    //         return of(null);
    //       },
    //     });
    // }
  }
}
