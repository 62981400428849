import { createSelector } from '@ngrx/store';
import { Classroom } from 'lingo2-models';
import { getClassroomsState, ClassroomsState } from '..';
import { ClassroomsListState } from './classrooms.reducer';

export const getClassroomsListState = createSelector(getClassroomsState, (state: ClassroomsState) => state.classrooms);

export const getClassroomsList = createSelector(getClassroomsListState, (state: ClassroomsListState) =>
  state.items.map((item) => new Classroom(item)),
);

export const getClassroomsListDetails = createSelector(
  getClassroomsListState,
  (state: ClassroomsListState) => state.details,
);

export const getClassroomsListFilter = createSelector(
  getClassroomsListState,
  (state: ClassroomsListState) => state.filter,
);

export const getClassroomsListPagination = createSelector(
  getClassroomsListState,
  (state: ClassroomsListState) => state.pagination,
);

export const getClassroomsListLoading = createSelector(
  getClassroomsListState,
  (state: ClassroomsListState) => state.loading,
);

export const getClassroomsListLoaded = createSelector(
  getClassroomsListState,
  (state: ClassroomsListState) => state.loaded,
);

export const getClassroomsListIsNotEmpty = createSelector(
  getClassroomsListState,
  (state: ClassroomsListState) => state.items && !!state.items.length,
);
