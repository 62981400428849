import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormControl, ValidationErrors } from '@angular/forms';
import { regex } from '@app/shared';
import { AccountService, PlatformService, SchoolsService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { CSchool, CSchoolParticipant, ESchoolParticipantRole, User } from 'lingo2-models';
import { uniqBy } from 'lodash';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonState } from 'onclass-ui';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-invite-to-school',
  templateUrl: './invite-to-school.component.html',
  styleUrls: ['./invite-to-school.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class InviteToSchoolComponent extends ChangableComponent implements OnInit {
  @Input() public set school(school: CSchool) {
    this._school = school;
  }
  public get school(): CSchool {
    return this._school;
  }
  @Input() mode: 'owners' | 'teachers' = 'teachers';
  @Output() changed = new EventEmitter<boolean>();
  public usersList: User;
  public emailsControl: UntypedFormControl;
  public emailButtonState: OnUiButtonState;
  public emailSent = false;
  public copied = false;

  private _school: CSchool;
  private _onCopied: any;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private fb: UntypedFormBuilder,
    private readonly schoolService: SchoolsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {
    this.emailButtonState = 'default';
    this.emailsControl = this.fb.control(
      {
        value: '',
        disabled: false,
      },
      {
        updateOn: 'blur',
        validators: [this.emailsValidator.bind(this)],
      },
    );
  }

  public onChanged(event: boolean) {
    this.changed.emit(event);
  }

  public filteredTeachers(participants: CSchoolParticipant[]): CSchoolParticipant[] {
    const isTeacherFiltered = participants.filter((_part) => AccountService.isTeacher(_part.account));
    const ownersRole = isTeacherFiltered.filter((_p) => _p.role === ESchoolParticipantRole.owner);
    const teachersRole = isTeacherFiltered.filter((_p) => _p.role === ESchoolParticipantRole.teacher);
    const studentRole = isTeacherFiltered.filter((_p) => _p.role === ESchoolParticipantRole.student);

    if (this.mode === 'owners') {
      return uniqBy([...ownersRole, ...teachersRole, ...studentRole], 'account_id');
    }
    return uniqBy([...teachersRole, ...ownersRole, ...studentRole], 'account_id');
  }

  public get schoolUrl(): string {
    return SchoolsService.schoolRoute(this._school).join('/');
  }

  // TODO: требуется серверная логика для отправки приглашения по e-mail
  public inviteViaEmail() {
    if (this.emailsControl.value) {
      const emails = this.parseEmails(this.emailsControl.value);
      this.schoolService
        .inviteParticipantsByEmail(this._school.id, emails)
        .pipe(first(), takeUntil(this.destroyed$))
        .subscribe({
          next: () => {
            this.emailSent = true;
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
            this.emailSent = false;
          },
        });
    }
  }

  public onCopied(event: any) {
    this.copied = true;
    this.markForCheck();

    this.clearTimeout(this._onCopied);
    this._onCopied = this.setTimeout(() => {
      this.copied = false;
      this.markForCheck();
    }, 2000);
  }

  private parseEmails(emails: string): string[] {
    return emails
      ?.split(',')
      .map((x) => x.trim())
      .filter((x) => !!x);
  }

  private emailsValidator(control: UntypedFormControl): ValidationErrors | null {
    const emails = this.parseEmails(control.value.value);
    const isOk = emails?.every((email) => regex.email.test(email));
    return !isOk ? { emails: { value: true, text: 'Some emails are incorrect' } } : null;
  }

  public trackByFn(index) {
    return index;
  }
}
