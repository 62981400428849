import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { logger } from '@core/helpers/logger';
import { AccountService, FilesService, PlatformService, SchoolsService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { CSchoolParticipant, ESchoolParticipantRole, ImageSizeEnum, User } from 'lingo2-models';
import { OnUiCover } from 'onclass-ui';
import { takeUntil } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-school-user-card',
  templateUrl: './school-user-card.component.html',
  styleUrls: ['./school-user-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolUserCardComponent extends ChangableComponent implements OnInit {
  @Input() set participant(participant: CSchoolParticipant) {
    this._participant = participant;
    this.prepareCard();
  }
  @Input() set mode(mode: 'owners' | 'teachers') {
    this._mode = mode;
    this.prepareCard();
  }
  @Output() changed = new EventEmitter<boolean>();
  public fullName: string;
  public country: string;
  public isInvited: boolean;
  public loader = false;

  private _participant: CSchoolParticipant;
  private _mode: 'owners' | 'teachers' = 'teachers';

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private schoolService: SchoolsService,
    private accountService: AccountService,
    protected cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {}

  public onAddTeacher() {
    // logger.debug('SchoolUserCardComponent::onAddTeacher');
    this.loader = true;
    this.markForCheck();

    this.schoolService
      .createParticipant(this._participant.school_id, {
        account_id: this._participant?.account_id,
        role: this._mode === 'teachers' ? ESchoolParticipantRole.teacher : ESchoolParticipantRole.owner,
      })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.changed.emit(true);
          this.setTimeout(() => {
            this.loader = false;
            this.detectChanges();
          }, 3000);
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.setTimeout(() => {
            this.loader = false;
            this.markForCheck();
          }, 3000);
        }
      });
  }

  public avatar(participant: User): OnUiCover {
    return {
      type: 'avatar',
      img: FilesService.getFileUrlBySize(participant?.userpic_id, ImageSizeEnum.sm),
      title: AccountService.getUserFullName(participant),
      form: 'circle',
    };
  }

  public loadParticipant$() {
    return this.accountService.getUserById(this._participant.account_id, 'LOAD_TIME');
  }

  private prepareCard() {
    this.fullName = AccountService.getUserFullName(this._participant?.account);
    this.country = this._participant?.account?.country?.title;

    if (this._mode === 'teachers') {
      this.isInvited = this._participant?.role === ESchoolParticipantRole.teacher;
    } else if (this._mode === 'owners') {
      this.isInvited = this._participant?.role === ESchoolParticipantRole.owner;
    }
  }
}
