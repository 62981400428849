import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { tabs } from '@app/library/panes/my-library-services/constants';
import { IServicesTab } from '@app/library/panes/my-library-services/models';
import { ChangableComponent } from '@app/models/changable.component';
import {
  AccountService,
  ConfigService,
  FeaturesService,
  fullUserServiceDetails,
  MetaService,
  PlatformService,
  ProfileService,
  ServicesBlockType,
  UserServicesService,
} from '@core/services';
import { LibraryRouter } from '@models/library.router';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadMe } from '@store/actions/profile.actions';
import { setMeAsCurrentUser } from '@store/actions/users.actions';
import { getMe, getMyProfile } from '@store/reducers/profile.reducer';
import {
  EContentOrder,
  FeatureEnum,
  FindUserServiceStrategyEnum,
  PublishStatusEnum,
  User,
  UserProfile,
  UserSegmentEnum,
  UserService,
} from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { mergeAll, of, throwError } from 'rxjs';
import { catchError, first, map, switchMap, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-profile-services',
  templateUrl: './profile-services.component.html',
  styleUrls: ['./profile-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileServicesComponent extends ChangableComponent implements OnInit, OnDestroy {
  @Input() hasServiceOrSkip: boolean;

  public me: User;
  public editService: UserService;
  public newService: UserService;
  public profile: Partial<UserProfile>;
  public openedUserService: UserService;
  public surfaceBlockDisplay: boolean;
  public userServiceCardFullInModal: boolean;
  public originalUrl: string;
  public tabs = tabs;
  public activeTab: IServicesTab;
  public services: ServicesBlockType = {
    items: [],
    loading: false,
    loaded: false,
    filter: {
      use: FindUserServiceStrategyEnum.owned,
      order: EContentOrder.date_desc,
      publish_status: [PublishStatusEnum.published],
    },
    pagination: {
      page: 1,
      pageSize: 10,
    },
  };

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private router: Router,
    private userServices: UserServicesService,
    private profileService: ProfileService,
    private accountService: AccountService,
    private configService: ConfigService,
    private features: FeaturesService,
    private meta: MetaService,
    private store: Store,
    private location: Location,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    this.store.dispatch(setMeAsCurrentUser());

    this.store
      .select(getMe)
      .pipe(
        map((me) => {
          this.me = me;
          return me;
        }),
        switchMap((me) => this.store.select(getMyProfile).pipe(first())),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (profile) => {
          this.profile = profile;
          this.setupMeta();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadMe());
        },
      });

    this.loadServices();
  }

  private get isAvailableUserServices(): boolean {
    return this.features.isAvailable(FeatureEnum.user_services, this.me);
  }

  protected loadServices() {
    if (!this.activeTab) {
      this.activeTab = this.tabs[0];
    }
    if (!this.isAvailableUserServices) {
      return;
    }

    this.services.loading = true;
    this.detectChanges();

    this.userServices
      .getServices(this.services.filter, this.services.pagination, fullUserServiceDetails)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.services.items = response.results;
          this.services.pagination = response.pagination;
          this.services.loaded = true;
          this.services.loading = false;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.services.loading = false;
          this.detectChanges();
        },
      });
  }

  public activateTab(tab: IServicesTab, force = false) {
    if (this.activeTab?.code !== tab.code || force) {
      this.activeTab = tab;
      this.services.filter = {
        ...this.services.filter,
        publish_status: [PublishStatusEnum[this.activeTab?.code]],
      };
      this.services.pagination.page = 1;
      this.loadServices();
    }
  }

  public skipStep(): void {
    this.accountService
      .addSegment(UserSegmentEnum.skipped_services_segment_in_introducion_form)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.navigateTo('schedule');
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });
  }

  public onCancel() {
    this.editService = null;
    this.newService = null;
  }

  public onCreate() {
    this.newService = new UserService({
      author_id: this.me.id,
      subject_id: this.profile?.teaching_subjects[0]?.subject_id,
      subject_other_id: this.profile?.teaching_subjects[0]?.subject_other_id?.toString(),
    });
  }

  public onSelect(service: any) {
    this.editService = service;
  }

  public onChange() {
    this.editService = null;
    this.newService = null;
    if (this.activeTab.code === 'published') {
      this.setTimeout(() => {
        this.activateTab(
          this.tabs.find((tab) => tab.code === 'moderation'),
          true,
        );
      }, 1000);
    }
    this.loadServices();
  }

  public onToggleUserServiceInModal(userService: UserService = null) {
    this.openedUserService = userService;
    this.userServiceCardFullInModal = !this.userServiceCardFullInModal;
    this.setAndResetUrl();
    this.detectChanges();
  }

  public onIinViewportChange(e) {
    this.surfaceBlockDisplay = e;
    this.detectChanges();
  }

  public trackUserServiceByFn(index, userService: UserService): string {
    return userService.id;
  }

  private setAndResetUrl() {
    const url = LibraryRouter.userServiceRouteUniversal(this.openedUserService);
    const currentUrl = this.location.path(false);
    if (this.userServiceCardFullInModal) {
      if (currentUrl !== url) {
        if (!this.originalUrl) {
          this.originalUrl = currentUrl;
        }
        this.location.replaceState(url);
      }
    } else {
      if (this.originalUrl) {
        this.location.replaceState(this.originalUrl);
        this.originalUrl = null;
      }
    }
  }

  public navigateTo(step: string): void {
    this.router.navigate(['/profile/' + step]);
  }

  protected setupMeta() {
    this.meta.setTitle(['teacher-profile.meta-title']);
  }
}
