import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Output } from '@angular/core';
import { AccountService, AnalyticsService, PlatformService } from '@app/core/services';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { ChangableComponent } from '@app/models/changable.component';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { UserSegmentEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil} from 'rxjs';

type RoleType = 'teacher' | 'student';

@Component({
  selector: 'app-choose-role',
  standalone: true,
  imports: [CommonModule, TranslateModule, ModalModule, MobileModalModule],
  templateUrl: './choose-role.component.html',
  styleUrls: ['./choose-role.component.scss'],
})
export class ChooseRoleComponent extends ChangableComponent {
  @Output()
  public onRoleChoose = new EventEmitter<void>();

  @Output()
  public onClosed = new EventEmitter<void>();

  public selectedRole: RoleType;
  public roles = ['student', 'teacher'];

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    protected accountService: AccountService,
    protected analytics: AnalyticsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public onClose() {
    this.onClosed.emit();
  }

  public setRole(role: RoleType) {
    this.selectedRole = role;
    const segment = this.selectedRole === 'teacher' ? UserSegmentEnum.as_if_teacher : UserSegmentEnum.as_if_student;
    const event = this.selectedRole === 'teacher' ? EventActionEnum.become_teacher : EventActionEnum.become_student;
    this.onRoleChoose.emit();
    this.accountService
      .addSegment(segment)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.analytics.event(event, EventCategoryEnum.engagement, 'app-choose-role');
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public trackByFn(index) {
    return index;
  }
}
