<div class="invite-to-school" [class.mobile]="deviceService.isMobile()">
  <div class="header">
    <p class="header-title">
      <ng-template [ngIf]="mode === 'teachers'">
        {{ 'invite-to-school.header1' | translate }}
      </ng-template>
      <ng-template [ngIf]="mode === 'owners'">
        {{ 'invite-to-school.header2' | translate }}
      </ng-template>
    </p>
  </div>
  <div class="body">
    <div *ngFor="let participant of filteredTeachers(school?.participants); trackBy: trackByFn" class="user-item">
      <app-school-user-card [participant]="participant"
                            [mode]="mode"
                            (changed)="onChanged($event)">
      </app-school-user-card>
    </div>
  </div>
  <div class="footer">

    <!--Temporary hide for now - https://app.clickup.com/t/8692pd8d4-->
    <!--<div class="footer-title">{{ 'invite-to-school.option1' | translate }}</div>

    <div class="email-wrapper" [class.sent]="emailSent">
      <app-form-field class="email-field" [control]="emailsControl">
        <on-ui-input class="email-control"
                   [formControl]="emailsControl"
                   [placeholder]="'invite-to-school.email' | translate">
        </on-ui-input>
      </app-form-field>
      <on-ui-button class="email-button primary"
                  label="invite-to-school.action"
                  [state]="emailButtonState"
                  (click)="inviteViaEmail()">
      </on-ui-button>

      <div class="email-sent-message">
        {{ 'classrooms.invite.invitation-sent' | translate }}
      </div>
    </div>-->

    <div class="link-wrapper">
      <!--Temporary hide for now - https://app.clickup.com/t/8692pd8d4-->

      <!--<div class="link"> {{ 'invite-to-school.option2' | translate }}
        <a [href]="schoolUrl" target="_blank" rel="noopener noreferrer"> {{ schoolUrl }}</a>
      </div>-->
      <div class="copy-button" [class.copied]="copied" #shareLinkRef>
        <span [ngxClipboard]
              [cbContent]="schoolUrl"
              (cbOnSuccess)="onCopied($event)"
              [container]="shareLinkRef">
          <span class="copy-button-message">{{ 'classrooms.invite.copied' | translate }}</span>

          <span class="copy-button-link">{{ 'classrooms.invite.copy-link' | translate }}
            <app-icon icon="copy-link" width="12" height="12"></app-icon>
          </span>
        </span>
      </div>
    </div>
  </div>
</div>
