import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { PlatformService, ScreenService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-reviews',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsComponent extends ChangableComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scroller', { read: CdkVirtualScrollViewport }) scroller: CdkVirtualScrollViewport;
  public itemSize = 306;
  public slidesList = [
    {
      avatar: this.assetsUrl('/assets/images/landings/job/avatar1.png'),
      name: 'job-landing.reviews.name1',
      language: 'job-landing.reviews.lang1',
      rating: 5,
      text: 'job-landing.reviews.text1',
    },
    {
      avatar: this.assetsUrl('/assets/images/landings/job/avatar2.png'),
      name: 'job-landing.reviews.name2',
      language: 'job-landing.reviews.lang2',
      rating: 3,
      text: 'job-landing.reviews.text2',
    },
    {
      avatar: this.assetsUrl('/assets/images/landings/job/avatar3.png'),
      name: 'job-landing.reviews.name3',
      language: 'job-landing.reviews.lang3',
      rating: 2,
      text: 'job-landing.reviews.text3',
    },
  ];

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private screenService: ScreenService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.autoMove();
    }

    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (width) => {
        if (width >= 1024) {
          this.itemSize = 326;
          this.detectChanges();
        } else if (width < 1024) {
          if (width < 768) {
            this.itemSize = width - 80;
            this.detectChanges();
          } else {
            this.itemSize = width / 2 - 40;
            this.detectChanges();
          }
        }
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        if (window.innerWidth >= 1024) {
          this.itemSize = 326;
          this.detectChanges();
        } else if (window.innerWidth < 1024) {
          if (window.innerWidth < 768) {
            this.itemSize = window.innerWidth - 80;
            this.detectChanges();
          } else {
            this.itemSize = window.innerWidth / 2 - 40;
            this.detectChanges();
          }
        }
      },
    });
  }

  moveLeft() {
    this.scroller.scrollToIndex(0, 'smooth');
  }

  moveRight() {
    const offset = this.scroller.measureScrollOffset('left');
    // сдвинуть на 1 карточку
    this.scroller.scrollToOffset(offset + this.itemSize, 'smooth');
  }

  private autoMove() {
    this.setInterval(() => {
      const isMaxScroll =
        this.scroller.measureScrollOffset('left') >=
        this.slidesList.length * this.itemSize - this.scroller.getViewportSize();
      if (isMaxScroll) {
        this.moveLeft();
      } else {
        this.moveRight();
      }
    }, 3000);
  }

  public trackByFn(index) {
    return index;
  }
}
