import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { IconComponent } from '@shared/icon/icon.component';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { OnUiCoverModule, OnUiProgressBarModule } from 'onclass-ui';
import { CertificateUploaderComponent } from './certificate-uploader.component';

@NgModule({
  declarations: [CertificateUploaderComponent],
  exports: [CertificateUploaderComponent],
  imports: [CommonModule, DropzoneModule, SpinnersModule, OnUiCoverModule, OnUiProgressBarModule, IconComponent],
})
export class CertificateUploaderModule {}
