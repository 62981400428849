import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';

import { ConstructorSidebarComponent } from './constructor-sidebar.component';
import { ContentTypeFilterComponent } from './content-type-filter/content-type-filter.component';
import { SortOrderFilterComponent } from './sort-order-filter/sort-order-filter.component';
@NgModule({
  declarations: [ConstructorSidebarComponent, SortOrderFilterComponent, ContentTypeFilterComponent],
  imports: [CommonModule, TranslateModule, RouterModule, IconComponent],
  exports: [ConstructorSidebarComponent],
})
export class ConstructorSidebarModule {}
