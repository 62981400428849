import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';

@Component({
  selector: 'app-verification-mark',
  templateUrl: './verification-mark.component.html',
  styleUrls: ['./verification-mark.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VerificationMarkComponent extends DestroyableComponent implements OnInit {
  @Input() public passed: boolean;

  constructor() {
    super();
  }

  ngOnInit() {}
}
