import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { FormErrorsModule } from '@core/components/form-errors/form-errors.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { OnUiButtonModule, OnUiCheckboxModule, OnUiSelectModule, OnUiSelectSearchModule } from 'onclass-ui';
import { RegularScheduleDayFormComponent } from './regular-schedule-day-form/regular-schedule-day-form.component';
import { RegularScheduleFormComponent } from './regular-schedule-form.component';

@NgModule({
  declarations: [RegularScheduleFormComponent, RegularScheduleDayFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    OnUiCheckboxModule,
    OnUiSelectModule,
    OnUiButtonModule,
    FormErrorsModule,
    PipesModule,
    IconComponent,
    OnUiSelectSearchModule,
  ],
  exports: [RegularScheduleFormComponent],
})
export class RegularScheduleFormModule {}
