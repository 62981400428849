<section class="promo-me"
         [class.tablet]="deviceService.isTablet()"
         [class.mobile]="deviceService.isMobile()"
         [class.debug]="debug$ | async">
  <div *ngIf="fixedState" class="fixed-section" [class.mobile]="deviceService.isMobile()" [@menu]>
    <div class="logo-block">
      <img src="/assets/images/logo.svg" alt=""/>
    </div>

    <div class="actions-block" *ngIf="!deviceService.isMobile()">
      <div class="button sign-in" (click)="authorize()">{{ 'landing-me.actions.log-in' | translate }}</div>
      <div class="button sign-up" (click)="freeOfferAction()"><i debug>1.</i>{{ 'landing-me.actions.try-free' | translate }}</div>
    </div>

    <ng-container *ngTemplateOutlet="langWrap"></ng-container>

  </div>

  <div *ngIf="fixedState && deviceService.isMobile()" class="fixed-section on-bottom" [@menu]>
    <div class="actions-block">
      <div class="button sign-in" (click)="authorize()">{{ 'landing-me.actions.log-in' | translate }}</div>
      <div class="button sign-up" (click)="freeOfferAction()"><i debug>3.</i>{{ 'landing-me.actions.try-free' | translate }}</div>
    </div>
  </div>
  <div class="container container-1" id="container">

    <ng-container *ngIf="!fixedState">
      <ng-container *ngTemplateOutlet="langWrap"></ng-container>
    </ng-container>

    <div class="data-wrap">
      <div class="logo-block">
        <img src="/assets/images/logo.svg"/>
      </div>

      <div class="text-block" id="text">
        <div class="main-title">
          <p [innerHTML]="'landing-me.title' | translate"></p>
        </div>
        <div class="text">{{ 'landing-me.sub-title' | translate }}</div>
      </div>

      <div class="actions-block">
        <div class="button sign-in" (click)="authorize()">{{ 'landing-me.actions.log-in' | translate }}</div>
        <div class="actions">
          <div class="button sign-up" (click)="freeOfferAction()"><i debug>4.</i>{{ 'landing-me.actions.try-free' | translate }}</div>
          <div class="button free-offer" (click)="freeOfferAction()"><i debug>5.</i>{{ 'landing-me.actions.period' | translate }}</div>
        </div>
      </div>

      <div class="image-block" id="video">
        <div class="image">
          <div class="bg"></div>
          <div class="video-wrap" id="video-holder">
            <video autoplay muted loop playsinline>
              <source src="/assets/images/landings/common/intro-video.mp4" type="video/mp4"/>
            </video>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="container container-2 gray">
    <div class="title">{{ 'landing-me.goals.title' | translate }}</div>
    <div class="arrow">
      <img src="/assets/images/landings/common/me/line-1.png"/>
    </div>
    <div class="goals">
      <div class="goal">
        <div class="image">
        <img src="/assets/images/landings/common/me/goal-1.png"/>
        </div>
        <span class="goal-title">{{ 'landing-me.goals.goal-1.title' | translate }}</span>
        <span class="goal-description">{{ 'landing-me.goals.goal-1.description' | translate }}</span>
      </div>
      <div class="goal">
        <div class="image">
        <img src="/assets/images/landings/common/me/goal-2.png"/>
        </div>
        <div class="goal-title">{{ 'landing-me.goals.goal-2.title' | translate }}</div>
        <div class="goal-description">{{ 'landing-me.goals.goal-2.description' | translate }}</div>
      </div>
    </div>

    <div class="title title-small">{{ 'landing-me.goals.list.title' | translate }}...</div>
    <div class="goals-list">
      <div class="item" *ngFor="let item of goalsList; let i = index; trackBy: trackByFn">
        <div class="icon">
          <img [src]="'/assets/images/landings/common/me/goals/goal-'+ (i+1) +'.svg'"/>
        </div>
        <div class="details">
          <div class="title">{{'landing-me.goals.list.goal-' + (i+1) + '.title' | translate}}</div>
          <div class="info">{{'landing-me.goals.list.goal-' + (i+1) + '.description' | translate}}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="container">
    <div class="title">{{ 'landing-me.lessons.title' | translate }}</div>
    <div class="sub-title">{{ 'landing-me.lessons.description' | translate }}</div>
    <img class="content full-screen" src="/assets/images/landings/common/me/lessons.jpg"/>
  </div>

  <div class="container gray">
    <div class="title">{{ 'landing-me.constructor.title' | translate }}</div>
    <div class="sub-title">{{ 'landing-me.constructor.description' | translate }}</div>
    <img class="content" src="/assets/images/landings/common/me/materials.png"/>

    <div class="title" [ngClass]="deviceService.isDesktop() ? 'pt100' : 'pt50'">{{ 'landing-me.constructor.exercises' | translate }}</div>
    <img class="content pt100" src="/assets/images/landings/common/me/examples.png"/>
  </div>

  <div class="container">
    <div class="title">{{ 'landing-me.dictionary.title' | translate }}</div>
    <div class="sub-title">{{ 'landing-me.dictionary.description' | translate }}</div>
    <img class="content" src="/assets/images/landings/common/me/vocabulary.png"/>
  </div>

  <div class="container gray">
    <div class="title">{{ 'landing-me.dashboard.title' | translate }}</div>
    <div class="sub-title">{{ 'landing-me.dashboard.description' | translate }}</div>
    <img class="content" src="/assets/images/landings/common/me/dashboard.png"/>
  </div>

  <div class="container">
    <div class="title">{{ 'landing-me.timetable.title' | translate }}</div>
    <div class="sub-title">{{ 'landing-me.timetable.description' | translate }}</div>
    <img class="content" src="/assets/images/landings/common/me/timetable.png"/>
  </div>

  <div class="container gray">
    <div class="title">{{ 'landing-me.devices.title' | translate }}</div>
    <div class="sub-title">{{ 'landing-me.devices.description' | translate }}</div>
    <img class="content w600" src="/assets/images/landings/common/me/devices.png"/>
  </div>

  <div class="container">
    <ng-template [ngIf]="isRussian">
    <div class="title">{{ 'landing-me.reviews.title' | translate }}</div>
    <div class="reviews pt50">
      <div class="reviewers-block" [class.scroll]="!deviceService.isDesktop()">
        <div (click)="setVideoActive(i)" class="reviewer" [class.active]="activeVideo === item.name" *ngFor="let item of reviewers; let i = index; trackBy: trackByFn">
          <img [src]="'/assets/images/landings/common/me/reviewers/'+item.name+'.png'" alt=""/>
          <div>
            <p class="name">{{item.name}}</p>
            <p class="experience"> {{item.experience}} {{ 'landing-me.reviews.experience' | translate }}</p>
          </div>
        </div>
      </div>

      <section class="reviews-carousel"
               [class.mobile]="deviceService.isMobile()">

          <div class="left-action" (click)="onMoveLeft()">
            <app-icon class="icon" icon="drag-scroll-arrow"></app-icon>
          </div>

          <div class="right-action" (click)="onMoveRight()">
            <app-icon class="icon" icon="drag-scroll-arrow"></app-icon>
          </div>

        <div class="scroll">
          <drag-scroll #dragScroll class="carousel-wrap" [snap-duration]="200">
            <div drag-scroll-item class="reviews-item" *ngFor="let item of reviewers; trackBy: trackByFn">
              <div class="video-block">
                <video class="video" loop playsinline>
                  <source [src]="'/assets/images/landings/common/me/reviewers/'+item.name+'.mp4#t=0.001'" type="video/mp4"/>
                </video>
                <div class="play-btn" (click)="playVideo($event)">
                  <app-icon width="60" height="60" icon="video-play-holder"></app-icon>
                </div>
                <div class="stop-btn hide" (click)="stopVideo($event)">
                  <app-icon width="60" height="60" icon="video-stop-holder"></app-icon>
                </div>
              </div>
            </div>
          </drag-scroll>
        </div>
      </section>
    </div>
    </ng-template>

    <div class="title">{{ 'landing-me.plans.title' | translate }}</div>
    <div class="plans-wrap">
      <app-paywall-wrapper [tenant]="tenant"
                           [preparedBusinessPlans]="plans"
                           [myBillingPlanId]="activePlan?.id" (update)="onUpdate()"
                           [isSeparate]="true"></app-paywall-wrapper>
    </div>
  </div>
</section>

<app-choose-role
  *ngIf="chooseRoleModalOpened"
  (onRoleChoose)="afterRoleChoose()"
  (onClosed)="closeChooseRoleModal()"
></app-choose-role>

<ng-template #langWrap>
  <div class="lang-wrap" [class.fixed]="fixedState">
    <div class="lang-filter">
      <div class="lang-filter__btn" (click)="showLanguagePopup()">
        <span>{{ label }}</span>
        <app-icon class="lang-filter__btn-arrow-ico" width="11" height="6" icon="angle-arrow"></app-icon>
      </div>

      <div class="lang-filter-popup" *ngIf="popupOpen">
        <div class="shade" (click)="closeLanguagePopup()"></div>
        <div class="window">
          <p class="text" [class.active]="lang.code === language.code" *ngFor="let lang of languages; trackBy: trackByFn" (click)="setLanguage(lang)">{{lang.code | uppercase }}</p>
        </div>
      </div>
    </div>
  </div>
</ng-template>
