import { Component, OnInit, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AccountService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonState, OnUiCover } from 'onclass-ui';
import { OnUiButtonTabOption } from 'onclass-ui/lib/modules/on-ui-button-tab/on-ui-button-tab.component';
import { takeUntil } from 'rxjs/operators';

export const groupsOptions: OnUiButtonTabOption[] = [
  { value: 'ios', label: 'IOS' },
  { value: 'android', label: 'Android' },
];

export const qrs: OnUiButtonTabOption[] = [
  { value: 'authy', label: 'Authy' },
  { value: 'microsoft_authenticator', label: 'Microsoft Authenticator' },
  { value: 'google_authenticator', label: 'Google Authenticator' },
  { value: '1_pass', label: '1Pass' },
];

@Component({
  selector: 'app-two-factor-authentication',
  templateUrl: './two-factor-authentication.component.html',
  styleUrls: ['./two-factor-authentication.component.scss'],
})
export class TwoFactorAuthenticationComponent extends ChangableComponent implements OnInit {
  @Output() public saved = new EventEmitter<boolean>();

  public groups = groupsOptions;
  public qrs = qrs;
  public qrForAuth: string;
  public errorField: string;
  public displayErrors: boolean;
  public selectedGroup: string | number;
  public btnState: OnUiButtonState = 'default';
  public form: FormGroup;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    protected fb: FormBuilder,
    public deviceService: DeviceDetectorService,
    public accountService: AccountService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit() {
    this.form = this.fb.group({
      group: new FormArray([new FormControl(true), new FormControl(false)]),
      two_factor_code: ['', [Validators.required]],
      password: ['', [Validators.required]],
    });
    this.selectedGroup = this.groups[0].value;
    this.get2FACode();
  }

  public get2FACode() {
    this.accountService
      .get2FACode()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.qrForAuth = res;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public isInvalid(control: string): boolean {
    return this.displayErrors && (this.form.controls[control]?.invalid || this.errorField === control);
  }

  public onChangedGroup(idx: number) {
    const controls = this.getFormArray('group').controls;
    controls.forEach((_control, index) => {
      if (index === idx) {
        return;
      }
      _control.patchValue(false);
    });
    this.selectedGroup = this.groups[idx].value;
    this.detectChanges();
  }

  public getFormArray(control: string): FormArray {
    return this.form.controls[control] as FormArray;
  }

  public cover(qr: string): OnUiCover {
    return {
      form: 'none',
      img: this.assetsUrl('/assets/icons/qr/' + qr + '_' + this.selectedGroup + '.svg'),
    };
  }

  public onAuthProcess() {
    this.displayErrors = false;
    if (this.form.invalid || this.btnState === 'progress') {
      this.displayErrors = true;
      return;
    }

    const body = {
      two_factor_code: this.form.value.two_factor_code,
      password: this.form.value.password,
    };

    this.btnState = 'progress';

    this.accountService
      .turnOn2FACode(body)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.btnState = 'done';
          this.setTimeout(() => {
            this.saved.emit(true);
            this.detectChanges();
          }, 1000);
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.btnState = 'fail';
          this.setTimeout(() => {
            this.btnState = 'default';
            this.detectChanges();
          }, 2000);
          this.displayErrors = true;
          this.errorField = error.error?.invalid_field;
        },
      });
  }

  public trackByFn(index) {
    return index;
  }
}
