<article class="school-sidebar-widget">
  <div class="cover-wrap">
    <div class="cover">
      <on-ui-cover [cover]="cover"></on-ui-cover>
    </div>
  </div>

  <ng-template [ngIf]="!withoutPrice">
    <div class="price-block">
      <div *ngIf="minPriceTier" class="prices">
        <span class="price-title">{{ 'teacher-card.from' | translate }}</span>
        <span class="price-value">{{ minPriceTier ? (getFormattedAmount() | async) : 0 }}</span>
      </div>

      <ng-template [ngIf]="canSignup$ | async" [ngIfElse]="cantSignupRef">
        <on-ui-button [attr.data-test]="'sign-up-button'"
                      class="secondary"
                      label="profile-sidebar-widget-20210625.sign-up"
                      (onclick)="openCheckoutModal()">
        </on-ui-button>
        <span class="back-info">{{ 'user-profile.guarantee-text' | translate }}</span>
      </ng-template>
      <ng-template #cantSignupRef>
        <on-ui-button class="status"
                      label="teacher-card.actions.no-available-hours">
        </on-ui-button>
      </ng-template>
    </div>
  </ng-template>

  <div class="stats-block">
    <ng-container *ngTemplateOutlet="statsWrap"></ng-container>
  </div>
</article>

<ng-template #statsWrap>
  <p class="stat-title">{{ 'school-stats.title-stat' | translate }}</p>
  <ng-container *ngFor="let stats of statsList; trackBy: trackByFn">
    <ng-container *ngIf="stats?.value">
      <div  class="stat-wrap">
        <ng-template [ngIf]="stats.type === eTypeStats.Separator" [ngIfElse]="others">
          <hr class="separator">
        </ng-template>
        <ng-template #others>
          <div class="label-wrap">
            <span class="label">{{ stats.label | translate }}</span>
          </div>
          <div class="value-wrap">
            <ng-container [ngSwitch]="stats.type">
              <div *ngSwitchCase="eTypeStats.Rating" class="rating-block">
                <app-icon class="rating-icon" [height]="16" [width]="16" [icon]="'rating'"></app-icon>
                <span class="value medium rating">{{ stats.value | numberRound }}</span>
              </div>
              <span *ngSwitchCase="eTypeStats.Value" class="value">{{ stats.value | numberRound }}</span>
            </ng-container>

          </div>
        </ng-template>
      </div>
    </ng-container>
  </ng-container>
</ng-template>

<app-modal *ngIf="deviceService.isDesktop() && userServiceCardFullInModal"
           [canClose]="false"
           (closed)="onToggleUserServiceInModal()">
  <app-modal-body>
    <app-user-service-card-full [userService]="openedUserService"
                                [modal]="true"
                                (inViewportChange)="onIinViewportChange($event)">
    </app-user-service-card-full>
  </app-modal-body>
  <app-modal-ad>
    <ng-template [ngIf]="!surfaceBlockDisplay">
      <app-user-service-surface-block [userService]="openedUserService"></app-user-service-surface-block>
    </ng-template>
  </app-modal-ad>
</app-modal>

<app-mobile-modal *ngIf="!deviceService.isDesktop() && userServiceCardFullInModal"
                  [titleVisible]="true"
                  (closed)="onToggleUserServiceInModal()">
  <app-mobile-modal-body>
    <app-user-service-card-full [userService]="openedUserService" [modal]="true"></app-user-service-card-full>
  </app-mobile-modal-body>
</app-mobile-modal>
