import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ChangableComponent } from '@app/models/changable.component';
import { PlatformService } from '@core/services';

@Component({
  selector: 'app-on-moderation',
  templateUrl: './on-moderation.component.html',
  styleUrls: ['./on-moderation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class OnModerationComponent extends ChangableComponent implements OnDestroy {
  constructor(
    private router: Router,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public navigateTo(): void {
    this.router.navigate(['/profile/payment']).then();
  }
}
