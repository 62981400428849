import { Location } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { NavigationEnd, Router } from '@angular/router';
import { loadMe } from '@app/store/actions/profile.actions';
import { logger } from '@core/helpers/logger';
import { vimeoEmbedUrl, youtubeEmbedUrl } from '@core/helpers/video';
import {
  AccountService,
  AuthService,
  BillingV2Service,
  FeaturesService,
  FilesService,
  PlatformService,
  ProfileService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { LibraryRouter } from '@models/library.router';
import { Store } from '@ngrx/store';
import { ApplicationDialogsService } from '@shared/application-dialogs';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { EWidgetStatType } from '@store/models';
import { getMe } from '@store/reducers/profile.reducer';
import {
  ITeachingSubject,
  MediaFormatEnum,
  ProfileMediaModeEnum,
  TenantEnum,
  User,
  UserProfile,
  UserService,
  UserStatusEnum,
} from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { Observable, of, timer } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-profile-sidebar-widget',
  templateUrl: './profile-sidebar-widget.component.html',
  styleUrls: ['./profile-sidebar-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileSidebarWidgetComponent extends ChangableComponent implements OnInit {
  @ViewChild('fixed', { static: false }) public fixedSidebar: ElementRef;

  @Input() public withoutPrice: boolean;
  @Input() public set user(user: User) {
    const oldUserId = this._user?.id || null;
    const newUserId = user?.id || null;
    this._user = user;
    if (user.stats && oldUserId !== newUserId) {
      this.statsList = this.initStats();
      this.getUserProfile();
    }
  }
  public get user(): User {
    return this._user;
  }

  @Input() public set user_id(id: string) {
    this.accountService
      .getUserById(id, 'LOAD_TIME')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (user) => {
          this._user = user;
          this.statsList = this.initStats();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'PROFILE-PROBLEM');
        },
        complete: () => {
          this.canSignup$ = this.isBrowser
            ? timer(0, 60 * 1000).pipe(map(() => this.latest_applicable_date?.getTime() > Date.now()))
            : of(true).pipe(map(() => this.latest_applicable_date?.getTime() > Date.now()));
          this.getUserProfile();
          this.detectChanges();
        },
      });
  }

  public me: User;
  public profile: UserProfile;
  public checkoutModalOpen = false;
  public statsList = [];
  public eTypeStats = EWidgetStatType;
  public isTeacher = false;
  public userServiceCardFullInModal: boolean;
  public openedUserService: UserService;
  public surfaceBlockDisplay: boolean;
  public subjectId: string;
  public teachingSubject: ITeachingSubject;
  public isMediaPrepared: boolean;
  public isVideoFromYoutube: boolean;
  public safeMediaUrl: SafeResourceUrl;
  public canSignup$: Observable<boolean>;

  private _user: User;
  private isGuest = true;
  private originalUrl: string;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    private location: Location,
    private authService: AuthService,
    private billingV2Service: BillingV2Service,
    private profileService: ProfileService,
    private filesService: FilesService,
    private accountService: AccountService,
    private features: FeaturesService,
    private sanitizer: DomSanitizer,
    private router: Router,
    private store: Store,
    protected dialogsService: ApplicationDialogsService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);

    this.canSignup$ = this.isBrowser
      ? timer(0, 60 * 1000).pipe(map(() => this.latest_applicable_date?.getTime() > Date.now()))
      : of(true).pipe(map(() => this.latest_applicable_date?.getTime() > Date.now()));
  }

  ngOnInit() {
    if (this.isServer) {
      logger.debug('ProfileSidebarWidgetComponent -> server -> skip widget');
      return;
    }

    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (me) => {
          this.me = me;
          this.isGuest = me?.status === UserStatusEnum.guest;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadMe());
        },
      });

    this.subjectId = this.parseSubjectIdFromUrl(this.router.url);
    this.getSubjectId$.pipe(tap(), takeUntil(this.destroyed$)).subscribe({
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
      },
      complete: () => {
        this.isMediaPrepared = false;
      },
    });
  }

  public get stats() {
    return this._user?.stats;
  }

  public openCheckoutModal() {
    const onAuthComplete = () => {
      this.checkoutModalOpen = true;
      this.dialogsService.openUserServiceCheckoutWizard({
        teacherId: this._user.id,
      });
      this.detectChanges();
    };

    if (this.isGuest) {
      this.authService.showAuthModal(onAuthComplete, {
        caller: 'app-profile-sidebar-widget',
        reason: 'purchase service',
      });
    } else {
      onAuthComplete();
    }
  }

  public closeCheckoutModal() {
    this.checkoutModalOpen = false;
    this.detectChanges();
  }

  public getFormattedAmount(): Observable<string> {
    return this.billingV2Service.formatAmount$(this.minPriceTier);
  }

  public get minPriceTier(): number {
    return this.user?.stats.min_price_tier;
  }

  public onToggleUserServiceInModal(service: UserService = null) {
    this.openedUserService = service;
    this.userServiceCardFullInModal = !this.userServiceCardFullInModal;
    this.setAndResetUrlForUserService();
    this.detectChanges();
  }

  public onIinViewportChange(e: boolean) {
    this.surfaceBlockDisplay = e;
    this.detectChanges();
  }

  public get latest_applicable_date(): Date {
    if (!!this._user?.stats?.latest_applicable_date) {
      return new Date(this._user?.stats?.latest_applicable_date);
    } else {
      return null;
    }
  }

  private setAndResetUrlForUserService() {
    const url = LibraryRouter.userServiceRouteUniversal(this.openedUserService);
    const currentUrl = this.location.path(false);
    if (this.userServiceCardFullInModal) {
      if (currentUrl !== url) {
        if (!this.originalUrl) {
          this.originalUrl = currentUrl;
        }
        this.location.replaceState(url);
      }
    } else {
      if (this.originalUrl) {
        this.location.replaceState(this.originalUrl);
        this.originalUrl = null;
      }
    }
  }

  public isMeVersion() {
    return this.features.tenant === TenantEnum.onclass_me;
  }

  public trackByFn(index) {
    return index;
  }

  private initStats() {
    const stats = [];
    stats.push({
      type: EWidgetStatType.Separator,
    });
    stats.push({
      label: 'user-profile.teacher-stat.students',
      type: EWidgetStatType.Value,
      value: this._user.stats.students_count,
    });
    stats.push({
      label: 'user-profile.teacher-stat.class',
      type: EWidgetStatType.Value,
      value: this._user.stats.meetings_count,
    });

    if (!this.isMeVersion()) {
      stats.push({
        className: 'rating',
        label: 'user-profile.teacher-stat.average-rating',
        type: EWidgetStatType.Rating,
        value: this._user.stats.reviews_rating ? this._user.stats.reviews_rating : '0',
      });
    }
    stats.push({
      label: 'user-profile.teacher-stat.total-publications',
      type: EWidgetStatType.Value,
      value: this._user.stats.publications_count,
    });
    stats.push({
      label: 'user-profile.teacher-stat.created-lessons',
      type: EWidgetStatType.Value,
      value: this._user.stats.lessons_count,
    });
    stats.push({
      label: 'user-profile.teacher-stat.date',
      type: EWidgetStatType.Date,
      date: this._user.created_at,
    });
    return stats;
  }

  protected getUserProfile(): void {
    this.profileService
      .getProfile(this._user.id)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (profile) => {
          this.profile = profile;
          this.teachingSubject = this.profile.teaching_subjects.find((el) => el.subject_id === +this.subjectId);
          if (!this.teachingSubject) {
            this.teachingSubject = this.profile.teaching_subjects[0];
          }
          this.prepareMedia();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'PROFILE-PROBLEM');
        },
      });
  }

  public prepareMedia() {
    const teachingSubject = this.teachingSubject;
    if (!teachingSubject || (!teachingSubject?.video_url && !teachingSubject?.video_id)) {
      this.safeMediaUrl = null;
      this.isMediaPrepared = false;
      return;
    }
    if (teachingSubject?.video_id && teachingSubject?.video_mode === ProfileMediaModeEnum.use_media_id) {
      this.filesService
        .getFileById(teachingSubject.video_id)
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (file) => {
            // Приоритет на файлы mp4, как наиболее используемые, если нет, тогда любой
            const priorityFile = file.media.find((_file) => _file.format === MediaFormatEnum.mp4);
            if (priorityFile) {
              this.safeMediaUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.assetsUrl(priorityFile?.url));
            } else {
              this.safeMediaUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.assetsUrl(file.media[0]?.url));
            }
            this.isMediaPrepared = true;
            this.isVideoFromYoutube = false;
            this.detectChanges();
          },
          error: (error) => {
            this.safeMediaUrl = null;
            this.isMediaPrepared = false;
            this.isVideoFromYoutube = false;
            this.errorNotificationService.captureError(error, 'LOAD-FILE');
          },
        });
    } else if (teachingSubject?.video_url && teachingSubject?.video_mode === ProfileMediaModeEnum.use_media_url) {
      this.safeMediaUrl = this.sanitizer.bypassSecurityTrustResourceUrl(
        youtubeEmbedUrl(teachingSubject?.video_url) || vimeoEmbedUrl(teachingSubject?.video_url),
      );
      this.isMediaPrepared = true;
      this.isVideoFromYoutube = true;
    } else {
      this.safeMediaUrl = null;
      this.isMediaPrepared = false;
    }
  }

  private get getSubjectId$(): Observable<string> {
    return this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map((event: NavigationEnd) => {
        this.subjectId = this.parseSubjectIdFromUrl(event.url);
        return this.subjectId;
      }),
    );
  }

  private parseSubjectIdFromUrl(url: string): string {
    return this.router.url.split('/').pop();
  }
}
