<div class="steps-widget" *ngIf="!lastStep">
  <p class="title">{{ 'profile-form.app.title' | translate }}</p>
  <p class="hint">{{ 'teacher-steps.placeholder.use-mobile-app.text' | translate }}</p>
    <div class="widget-block mobile-app">
      <div class="mobile-app-icon" *ngIf="!hasApp && !deviceService.isMobile()">
        <app-icon
          class="placeholder-icon"
          file="teacher-steps"
          icon="placeholder-use-mobile-app"
          [width]="110"
          [height]="182">
        </app-icon>
      </div>

      <ng-template [ngIf]="!hasApp">
        <div class="apps">
          <ng-container *ngIf="true; else activeMobileStep">
            <div class="qr">
              <div class="qrcode-wrap">
                <app-onboarding-qr-code></app-onboarding-qr-code>
              </div>
              <div class="text-wrap">
                <span class="qr-text normal">{{ 'teacher-steps.placeholder.use-mobile-app.text2' | translate }}</span>
                <app-icon
                  class="placeholder-icon"
                  file="teacher-steps"
                  icon="arrow-apps"
                  [width]="45"
                  [height]="40">
                </app-icon>
              </div>
            </div>
            <ng-container *ngTemplateOutlet="apps"></ng-container>
          </ng-container>

          <ng-template #activeMobileStep>
            <div class="qr">
              <div class="qrcode-wrap">
                <app-onboarding-qr-code></app-onboarding-qr-code>
              </div>

              <div class="text-wrap active-mobile-step">
                <span class="qr-text normal">{{ 'teacher-steps.placeholder.use-mobile-app.text-done' | translate }}</span>
                <ng-container *ngTemplateOutlet="apps"></ng-container>
              </div>
            </div>
          </ng-template>
        </div>
      </ng-template>

      <ng-template [ngIf]="hasApp">
        <div class="apps" [class.mobile]="hasApp && deviceService.isMobile()">
          <div class="qr all-steps">
            <div class="qrcode-wrap">
              <app-onboarding-qr-code></app-onboarding-qr-code>
            </div>

            <div class="text-wrap">
              <span class="qr-text all-steps normal">
                {{ 'teacher-steps.placeholder.use-mobile-app.text' | translate }}
              </span>
              <ng-template [ngIf]="!deviceService.isMobile()">
                <ng-container *ngTemplateOutlet="apps"></ng-container>
              </ng-template>
            </div>
          </div>
          <ng-template [ngIf]="deviceService.isMobile()">
            <ng-container *ngTemplateOutlet="apps"></ng-container>
          </ng-template>
        </div>
      </ng-template>
  </div>

  <div class="widget-actions" [class.mobile]="deviceService.isMobile()">
    <on-ui-button state="default"
                  class="primary outline margin-right-20 back"
                  label="profile-form.back"
                  (click)="navigateTo('schedule')">
    </on-ui-button>
    <on-ui-button state="default"
                  class="primary next"
                  label="profile-form.next"
                  (click)="showLastStep()">
    </on-ui-button>
    <on-ui-button state="default"
                  *ngIf="!hasAppOrSkip && !hasApp"
                  class="transparent skip"
                  label="profile-form.skip"
                  (click)="skipStep()">
    </on-ui-button>
  </div>
</div>

<ng-template #apps>
  <div class="apps-labels">
    <a target="_blank" [href]="apple"><div class="app-label ios"></div></a>
    <a target="_blank" [href]="android"><div class="app-label android"></div></a>
  </div>
</ng-template>

<app-all-steps *ngIf="lastStep"></app-all-steps>
