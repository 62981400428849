<div class="whybetter">
  <div class="whybetter-container">
    <div class="whybetter-title">{{'job-landing.whybetter.title' | translate}}</div>
    <div class="slider-wrap">
      <div class="slider">
        <div class="scroll">
          <ul class="slide-menu-list">
            <li *ngFor="let listItem of slidesList; let i = index; trackBy: trackByFn"
                class="list-item medium"
                [class.selected]="selectedIndex === i"
                (click)="onSelectItem(i)">
              {{ listItem.title | translate}}
            </li>
          </ul>
        </div>
        <div class="carousel-wrap">
          <div class="carousel-list slide">
            <div class="carousel-list-item" *ngFor="let slide of slidesList[selectedIndex].data; trackBy: trackByFn">
              <div class="carousel-list-item-image">
                <img src="{{ slide.icon }}" alt="{{slide.title | translate}}"/>
              </div>
              <div class="carousel-list-item-text-wrap">
                <div class="carousel-list-item-text-title">
                  {{slide.title | translate}}
                </div>
                <div class="carousel-list-item-text-text">
                  {{slide.text | translate}}
                </div>
              </div>
            </div>
          </div>
          <div class="whybetter-bg">
            <div class="whybetter-bg-img">
              <img src="{{ assetsUrl('/assets/images/landings/job/whybest-bg.png') }}" alt="" />
              <div class="whoisneed-background-placeholder">
                <span>{{'job-landing.whybetter.imagetext' | translate}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
