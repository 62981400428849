import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { PlatformService, ScreenService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-reviews-common',
  templateUrl: './reviews.component.html',
  styleUrls: ['./reviews.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ReviewsCommonComponent extends ChangableComponent implements AfterViewInit, OnDestroy {
  @ViewChild('scroller', { read: CdkVirtualScrollViewport }) scroller: CdkVirtualScrollViewport;
  public itemSize = 335;
  public slidesList = [
    {
      avatar: this.assetsUrl('/assets/images/landings/job/avatar1.png'),
      name: 'job-landing.reviews.name1',
      language: 'job-landing.reviews.lang1',
      rating: 5,
      text: 'job-landing.reviews.text1',
    },
    {
      avatar: this.assetsUrl('/assets/images/landings/job/avatar2.png'),
      name: 'job-landing.reviews.name2',
      language: 'job-landing.reviews.lang2',
      rating: 5,
      text: 'job-landing.reviews.text2',
    },
    {
      avatar: this.assetsUrl('/assets/images/landings/job/avatar3.png'),
      name: 'job-landing.reviews.name3',
      language: 'job-landing.reviews.lang3',
      rating: 5,
      text: 'job-landing.reviews.text3',
    },
  ];

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private screenService: ScreenService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngAfterViewInit() {
    if (this.isBrowser) {
      this.autoMove();
    }

    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (width) => {
        this.calcItemSize(width);
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.calcItemSize(window.innerWidth);
      },
    });
  }

  calcItemSize(width: number) {
    if (width < 768) {
      this.itemSize = width - 80;
      this.detectChanges();
    } else if (width >= 768 && width < 1024) {
      this.itemSize = 335;
      this.detectChanges();
    } else if (width >= 1024 && width < 1368) {
      this.itemSize = 335;
      this.detectChanges();
    } else if (width >= 1024 && width < 1368) {
      this.itemSize = 310;
      this.detectChanges();
    }
  }

  moveLeft() {
    this.scroller.scrollToIndex(0, 'smooth');
  }

  moveRight() {
    const offset = this.scroller.measureScrollOffset('left');
    const isMaxScroll = offset >= this.slidesList.length * this.itemSize + 30 - this.scroller.getViewportSize();
    if (isMaxScroll) {
      this.moveLeft();
    } else {
      this.scroller.scrollToOffset(offset + (this.itemSize + 30), 'smooth');
    }
  }

  private autoMove() {
    this.setInterval(() => {
      const isMaxScroll =
        this.scroller.measureScrollOffset('left') >=
        this.slidesList.length * this.itemSize - this.scroller.getViewportSize();
      if (isMaxScroll) {
        this.moveLeft();
      } else {
        this.moveRight();
      }
    }, 3000);
  }

  public trackByFn(index) {
    return index;
  }
}
