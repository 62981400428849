<section class="school-participants">
  <ng-template [ngIf]="schoolParticipantsFilter">
    <div class="header-action" (click)="onToggleInviteModal()">
      <ng-template [ngIf]="inviteMode === 'owners'">
        <span class="action-label normal">{{ 'school-participants.action-owner' | translate }}</span>
      </ng-template>
      <ng-template [ngIf]="inviteMode === 'teachers'">
        <span class="action-label normal">{{ 'school-participants.action-tutor' | translate }}</span>
      </ng-template>
    </div>
  </ng-template>
  <div class="participant-block" [class.mobile]="deviceService.isMobile()">
    <div *ngFor="let user of users.items; trackBy: usersTrackBy" class="participant-card">
      <app-user-card-mini [user]="user" [participant]="getParticipant(user.id)" (onRemove)="loadSchool()"></app-user-card-mini>
    </div>
  </div>
</section>

<app-modal *ngIf="inviteModal && !deviceService.isMobile()"
           [schedule]="true"
           [canClose]="false"
           (closed)="onToggleInviteModal()">
  <app-modal-body>
    <app-invite-to-school [school]="school"
                          (changed)="onChangeInviteTeachers()"
                          [mode]="inviteMode">
    </app-invite-to-school>
  </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="inviteModal && deviceService.isMobile()"
                  (closed)="onToggleInviteModal()">
  <app-mobile-modal-body>
    <app-invite-to-school [school]="school"
                          (changed)="onChangeInviteTeachers()"
                          [mode]="inviteMode">
    </app-invite-to-school>
  </app-mobile-modal-body>
</app-mobile-modal>
