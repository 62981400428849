import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ProfileFormService } from '@app/account/profile-form-page/_services/profile-form.service';
import { IAboutForm } from '@app/account/profile-form-page/about-form-page/about-form-page.component';
import { IAboutFormReferences } from '@app/account/profile-form-page/about-form-page/resolvers';
import { IContactsForm } from '@app/account/profile-form-page/contacts-form-page/contacts-form-page.component';
import { IEducationForm } from '@app/account/profile-form-page/education-form-page/education-form-page.component';
import { IExperienceForm } from '@app/account/profile-form-page/experience-form-page/experience-form-page.component';
import { ITeachingForm } from '@app/account/profile-form-page/teaching-form-page/teaching-form-page.component';
import { ChangableComponent } from '@app/models/changable.component';
import { ProfileStepsEnum } from '@core/layouts/profile/sidebar/profile-left-sidebar.component';
import {
  AccountService,
  AuthService,
  ContextService,
  FeaturesService,
  PlatformService,
  ProfileService,
} from '@core/services';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { getAccountCheck } from '@store/reducers/data.reducer';
import { getMe } from '@store/reducers/profile.reducer';
import {
  EContentPanelRoute,
  User,
  UserAchievementEnum,
  UserSegmentEnum,
  UserProfileVerificationEnum as VE,
  IAccountCheck,
  TenantEnum,
} from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs';
import { tap } from 'rxjs/operators';
export interface IProfileForm {
  about: IAboutForm;
  contacts: IContactsForm;
  education: IEducationForm;
  experience: IExperienceForm;
  teaching: ITeachingForm;
}

@Component({
  selector: 'app-profile-page',
  templateUrl: './profile-page.component.html',
  styleUrls: ['./profile-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfilePageComponent extends ChangableComponent implements OnInit, OnDestroy {
  public step: ProfileStepsEnum | string;
  public steps = ProfileStepsEnum;
  public values: IProfileForm;
  public aboutReferences: IAboutFormReferences;
  public checkedStepsCount: number;
  public maxSteps = 6;
  public isAll: boolean;
  public me: User;
  public userAccountCheck: Partial<IAccountCheck>;
  public show = false;
  public needAdditionalAction = false;
  public verification_status: VE;
  public hasSegment = AccountService.hasSegment;
  public hasAchievement = AccountService.hasAchievement;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private features: FeaturesService,
    private contextService: ContextService,
    private profileFormService: ProfileFormService,
    private authService: AuthService,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    if (!this.authService.isAuthenticated) {
      this.router.navigate(['/' + EContentPanelRoute.main]).catch();
      return;
    }

    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe((me) => (this.me = me));
    this.contextService.profile$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((res) => (this.verification_status = res.verification_status));

    this.store
      .select(getAccountCheck)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (accountCheck) => {
          this.userAccountCheck = accountCheck;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });

    this.route.data.pipe(takeUntil(this.destroyed$)).subscribe((res) => {
      this.values = { ...res.profileInfo };
      this.aboutReferences = { ...res.aboutReferences };

      this.isAll = this.setAllSteps();
      this.show = true;
      this.checkStep();
      this.detectChanges();
    });
  }

  public checkStep(): void {
    this.route.paramMap.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (query) => {
        this.step = query.get('step');

        if (!this.isMeVersion()) {
          if (!this.step) {
            this.router.navigate(['/profile/' + this.firstUnfilledStep]).catch();
            this.step = 'main';
            if (
              this.isTeacher
                ? !AccountService.isAllStepsFilledProfileTeacher(this.userAccountCheck)
                : !AccountService.isAllStepsFilledProfileStudent(this.userAccountCheck)
            ) {
              this.router.navigate(['/profile/' + this.firstUnfilledSubStep]).catch();
              this.step = this.firstUnfilledSubStep;
            }
          }
        }
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });
  }

  private setAllSteps(): boolean {
    const segments = [
      UserSegmentEnum.has_verified_teacher_profile,
      UserSegmentEnum.has_approved_bank_card,
      UserSegmentEnum.use_mobile_app,
    ];

    const achievements = [UserAchievementEnum.added_working_time_once, UserAchievementEnum.created_user_services_once];

    const filteredSegments = this.values.about?.segments
      .filter((_a) => segments.includes(_a.segment))
      .sort((a, b) => {
        const _a = new Date(a.created_at);
        const _b = new Date(b.created_at);
        return _b > _a ? 1 : -1;
      });

    const filteredAchievements = this.values.about?.achievements
      .filter((_a) => achievements.includes(_a.achievement))
      .sort((a, b) => {
        const _a = new Date(a.created_at);
        const _b = new Date(b.created_at);
        return _b > _a ? 1 : -1;
      });

    const teachingSubjects = this.values.teaching?.teaching_subjects?.length > 0 ? 1 : 0;

    this.checkedStepsCount = filteredSegments.length + filteredAchievements.length + teachingSubjects;

    return this.checkedStepsCount === this.maxSteps;
  }

  public get hasApp(): boolean {
    return this.hasSegment(this.me, UserSegmentEnum.use_mobile_app);
  }

  public get hasServiceOrSkip(): boolean {
    return (
      this.hasAchievement(this.me, UserAchievementEnum.created_user_services_once) ||
      this.hasSegment(this.me, UserSegmentEnum.skipped_services_segment_in_introducion_form)
    );
  }

  public get hasAppOrSkip(): boolean {
    return (
      this.hasSegment(this.me, UserSegmentEnum.use_mobile_app) ||
      this.hasSegment(this.me, UserSegmentEnum.skipped_mobile_app_segment_in_introducion_form)
    );
  }

  public get hasScheduleOrSkip(): boolean {
    return (
      this.hasAchievement(this.me, UserAchievementEnum.added_working_time_once) ||
      this.hasSegment(this.me, UserSegmentEnum.skipped_schedule_segment_in_introducion_form)
    );
  }

  public get firstUnfilledStep(): string {
    return this.isTeacher
      ? AccountService.isAllStepsFilledProfileTeacher(this.userAccountCheck)
        ? !this.userAccountCheck?.no_teaching_subjects
          ? this.hasAchievement(this.me, UserAchievementEnum.created_user_services_once)
            ? this.values?.about?.achievements.filter(
                (_a) => _a.achievement === UserAchievementEnum.added_working_time_once,
              )
              ? this.hasSegment(this.me, UserSegmentEnum.has_approved_bank_card) ||
                this.hasSegment(this.me, UserSegmentEnum.can_acceptance_payments)
                ? this.hasSegment(this.me, UserSegmentEnum.use_mobile_app)
                  ? this.steps.main
                  : this.steps.application
                : this.steps.payment
              : this.steps.schedule
            : this.steps.services
          : this.steps.teaching
        : this.steps.main
      : AccountService.isAllStepsFilledProfileStudent(this.userAccountCheck)
      ? this.steps.application
      : this.steps.main;
  }

  public get firstUnfilledSubStep(): string {
    return !(
      this.userAccountCheck?.no_first_name ||
      this.userAccountCheck?.no_last_name ||
      this.userAccountCheck?.no_country_id ||
      this.userAccountCheck.no_userpic_id
    )
      ? !(
          this.userAccountCheck?.no_spoken_languages ||
          this.userAccountCheck?.no_birth_date ||
          this.userAccountCheck?.no_about
        )
        ? !(this.userAccountCheck?.no_confirmed_email || this.userAccountCheck?.no_confirmed_mobile_phone)
          ? !this.userAccountCheck.no_education_schools
            ? !this.userAccountCheck.no_work_experience
              ? this.steps.teaching
              : this.steps.experience
            : this.steps.education
          : this.steps.contacts
        : this.steps.about
      : this.steps.main;
  }

  public checkSteps(): void {
    if (
      this.isTeacher
        ? AccountService.isAllStepsFilledProfileTeacher(this.userAccountCheck)
        : AccountService.isAllStepsFilledProfileStudent(this.userAccountCheck)
    ) {
      this.profileFormService.verify(false);
      this.router.navigate(['/profile/' + this.steps.teaching]).catch();
    } else {
      this.needAdditionalAction = true;
      this.profileFormService.verify(true);
      this.router.navigate(['/profile/' + this.firstUnfilledSubStep]).catch();
    }
  }

  public changePage(): void {
    if (this.isTeacher) {
      this.router.navigate(['/profile/' + this.steps.education]).catch();
    } else {
      this.router.navigate(['/profile/' + this.steps.application]).catch();
    }
  }

  public get isTeacher(): boolean {
    return AccountService.isAsIfTeacher(this.me);
  }

  public get onModeration() {
    return this.verification_status === VE.resent || this.verification_status === VE.sent;
  }

  public isMeVersion() {
    return this.features.tenant === TenantEnum.onclass_me;
  }
}
