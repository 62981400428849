<!--<div class="cards-block">-->
  <!--<div class="card" *ngFor="let widget of widgets">-->
    <!--<img [src]="assetsUrl(widget.icon)" [alt]="widget.title | translate"/>-->
    <!--<div>-->
      <!--<p class="title">{{widget.title | translate}}</p>-->
      <!--<p class="count">{{widget.count}}</p>-->
    <!--</div>-->
  <!--</div>-->
<!--</div>-->

<div class="title-wrap" [class.mobile]="deviceService.isMobile()">
  <app-vocabulary-filter (onChange)="onVocabularyChange($event)"></app-vocabulary-filter>
</div>

<app-vocabulary-card [page]="'main'" [vocabulary]="vocabulary"></app-vocabulary-card>
