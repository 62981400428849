<article class="checkbox-event-card"
         [class.mobile]="deviceService.isMobile()"
         [class.warning]="isOverlapping">
  <div class="meta">
    <div class="type" [ngClass]="eUserServiceType[type]">{{ typeName(type) | translate }}</div>

    <div [formGroup]="form" class="checkbox">
      <ng-template [ngIf]="processing" [ngIfElse]="checkboxRef">
        <app-icon class="loading-icon" icon="loading"></app-icon>
      </ng-template>
      <ng-template #checkboxRef>
        <on-ui-checkbox formControlName="checkbox"
                        data-test="select-event"
                        [disabled]="readonly"
                        (change)="onChanged()"></on-ui-checkbox>
      </ng-template>
    </div>

    <div class="cover-wrap">
      <on-ui-cover [cover]="cover"></on-ui-cover>
    </div>

    <div class="info-block">
      <span class="subject">{{ subject }}</span>
      <span class="title">{{ title }}</span>

      <ng-template [ngIf]="!deviceService.isMobile()">
        <ng-container [ngTemplateOutlet]="secondBlock"></ng-container>
        <ng-container [ngTemplateOutlet]="statusBlock"></ng-container>
      </ng-template>
    </div>
  </div>

  <ng-template [ngIf]="deviceService.isMobile()">
    <ng-container [ngTemplateOutlet]="secondBlock"></ng-container>
    <ng-container [ngTemplateOutlet]="statusBlock"></ng-container>
  </ng-template>

  <ng-container [ngTemplateOutlet]="warningsBlock"></ng-container>

</article>

<ng-template #secondBlock>
  <div class="second-info">
    <div class="left">
      <ng-template [ngIf]="weekdays">
        <span class="text">{{ weekdays }}</span>
        <div class="text-separator"></div>
      </ng-template>

      <span class="text">{{ duration }} min</span>
    </div>
    <div class="right">
      <span class="text">{{ isSingle ? '1 on 1' : 'Group' }}</span>
      <ng-template [ngIf]="age_from">
        <div class="text-separator"></div>
        <span class="text">Age: {{ age_from }}-{{ age_to }}</span>
      </ng-template>
    </div>
  </div>
</ng-template>

<ng-template #statusBlock>
  <div class="status">
    <div class="status-block status-block__{{moderationStatus.value}}" *ngIf="moderationStatus.label">
      {{ moderationStatus.label | translate }}
    </div>
    <div class="reason-icon" *ngIf="reasonMessage">
      <app-icon width="15" height="15" icon="question-service"></app-icon>
      <div class="reason-text">
        <p class="label">Причина:</p>
        <p class="text">{{reasonMessage}}</p>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #warningsBlock>
  <div *ngIf="isOverlapping && overlapEvents?.length" class="warnings">
    <div class="warning" *ngFor="let event of overlapEvents; trackBy: trackByFn">
      {{ 'event-calendar.multi-selector.overlap-text' | translate : {
      'date': event.begin_at | dfnsFormat: 'dd.MM',
      'title': event.title
    } }}
    </div>
  </div>
</ng-template>
