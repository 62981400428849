<ng-container *ngIf="deviceService.isDesktop(); else mobile_menu">
  <section class="profile-left-sidebar">
    <ng-container *ngTemplateOutlet="menu"></ng-container>
  </section>
</ng-container>

<ng-template #mobile_menu>
  <div class="left-sidebar-floating" [class.visible]="visible">
    <div class="shade" (click)="closeSidebar()"></div>
    <section class="profile-left-sidebar menu">
      <ng-container *ngTemplateOutlet="menu"></ng-container>
    </section>
  </div>
</ng-template>

<ng-template #menu>
  <nav class="navigate">
    <ul class="main-list">
      <li class="main-list-item" *ngFor="let item of schoolMenuList; let i = index; trackBy: trackByFn">
        <div class="list-title" [class.active]="isActive(item)">
          <p (click)="navigateTo(item.route)">{{ item.label | translate }}</p>
        </div>
      </li>
    </ul>
  </nav>
</ng-template>
