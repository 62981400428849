<div class="steps-widget">
  <div class="widget-block">
    <div class="img"></div>
    <p class="title">{{ 'profile-form.on-moderation.title' | translate }}</p>
    <p class="hint">{{ 'profile-form.on-moderation.description' | translate }}</p>
    <on-ui-button data-test="go-home"
                  class="primary"
                  [label]="'profile-form.continue'"
                  (click)="navigateTo()">
    </on-ui-button>
  </div>
</div>
