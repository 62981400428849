import { Component, OnInit, ChangeDetectorRef, ChangeDetectionStrategy, Input } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { IControlOption } from '@app/shared';
import { loadMe } from '@app/store/actions/profile.actions';
import {
  AccountService,
  ConfigService,
  ContextService,
  FeaturesService,
  ITimezone,
  PlatformService,
  ScheduleService,
  ScreenService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { eachDayOfInterval, startOfWeek, endOfWeek } from 'date-fns';
import {
  RegularSchedule,
  RegularWeekday,
  UserSegmentEnum,
  WeekdaysEnum,
  maxMeetingSignupDaysOptions,
  signupGapHoursOptions,
  FeatureEnum,
  User,
} from 'lingo2-models';
import { DateFnsConfigurationService, FormatPipe } from 'lingo2-ngx-date-fns';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonState } from 'onclass-ui';
import { filter, takeUntil } from 'rxjs/operators';
import { beginHourAtAndEndHourAtValidate } from './regular-schedule-form.validators';

interface IWeekday {
  weekday: WeekdaysEnum;
  full: string;
}

@Component({
  selector: 'app-regular-schedule-form',
  templateUrl: './regular-schedule-form.component.html',
  styleUrls: ['./regular-schedule-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegularScheduleFormComponent extends ChangableComponent implements OnInit {
  @Input() public isSeparate;
  @Input() public hasScheduleOrSkip: boolean;

  public debug$ = this.contextService.debug$;
  public weekdays: IWeekday[] = [];
  public timezones: ITimezone[] = [];
  public timezonesOptions: IControlOption[] = [];
  private scheduleData: RegularSchedule;
  public signupGapHoursOptions: IControlOption[] = signupGapHoursOptions;
  public maxMeetingSignupDaysOptions: IControlOption[] = maxMeetingSignupDaysOptions;
  public form: UntypedFormGroup;
  public ready = false;
  public saveState: OnUiButtonState = 'default';
  public saving = false;
  public saved = false;
  public isPortrait = false;
  public expandPanel = true;
  public forceSave = false;
  public validationEnabled = false;
  public me: User;

  private _dateFormat = new FormatPipe(this.dateConfig, null);

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private configService: ConfigService,
    private scheduleService: ScheduleService,
    private contextService: ContextService,
    private fb: UntypedFormBuilder,
    private dateConfig: DateFnsConfigurationService,
    private screenService: ScreenService,
    private features: FeaturesService,
    private accountService: AccountService,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit() {
    this.weekdays = this.prepareWeekdays();
    this.load();

    this.screenService.options.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (options) => {
        this.isPortrait = options.isPortrait;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.isPortrait = false;
        this.detectChanges();
      },
    });

    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (me) => {
        this.me = me;
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.store.dispatch(loadMe());
      },
    });

    if (this.isSeparate) {
      this.expandPanel = true;
    }
  }

  protected load() {
    this.configService.load(['timezones$']);

    this.configService.timezones$
      .pipe(filter((x) => !!x))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (timezones) => {
          this.timezones = timezones;
          this.timezonesOptions = this.timezonesOptionsInit(timezones);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });

    this.scheduleService
      .getRegular()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (schedule) => {
          const [_schedule, changed] = this.initSchedule(schedule);
          this.createForm(_schedule);
          this.scheduleData = _schedule;
          if (changed) {
            // Открыть панель настройки
            this.expandPanel = true;
            this.forceSave = true;
          }
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  private createForm(schedule: RegularSchedule) {
    this.ready = false;

    this.form = this.fb.group({
      timezone: [schedule.timezone, [Validators.required]],
      signup_gap_hours: [schedule.signup_gap_hours, [Validators.required]],
      max_meeting_signup_days: [schedule.max_meeting_signup_days, [Validators.required]],
      weekdays: this.fb.array([]),
    });

    this.weekdays.map((weekday) => {
      const d = schedule.weekdays.find((w) => w.weekday === weekday.weekday);
      this.addWeekday(d);
    });
    this.ready = true;
  }

  private addWeekday(weekday: RegularWeekday) {
    const hours = weekday.hours.map((event) => this.createHours(event.begin_hour_at, event.end_hour_at));

    const element = this.fb.group({
      weekday: weekday.weekday,
      enabled: weekday.enabled,
      hours: this.fb.array(hours),
    });

    this.weekdaysArray.push(element);
  }

  protected createHours(begin_hour_at: number, end_hour_at: number): UntypedFormGroup {
    return this.fb.group(
      {
        begin_hour_at,
        end_hour_at,
      },
      { validator: beginHourAtAndEndHourAtValidate() },
    );
  }

  public get weekdaysArray(): UntypedFormArray {
    return this.form.get('weekdays') as UntypedFormArray;
  }

  public timezonesOptionsInit(timezones: ITimezone[]): IControlOption[] {
    return timezones.map((tz) => ({
      title: `(${this.timezoneOffsetToHours(tz.offset)}) ${tz.name} - ${tz.cities.join(', ')}`,
      value: tz.name,
    }));
  }

  /**
   * @sample timezoneOffsetToHours(-4200) -> 'UTC-07:00'
   * @sample timezoneOffsetToHours(4200) -> 'UTC+07:00'
   */
  public timezoneOffsetToHours(offset: number): string {
    const sign = offset < 0 ? '-' : '+';
    const hhmm = new Date(Math.abs(offset) * 60 * 1000).toISOString().substr(11, 5);
    return 'UTC' + sign + hhmm;
  }

  public currentTimezone(): string {
    try {
      return Intl.DateTimeFormat().resolvedOptions().timeZone;
    } catch (e) {
      // eslint-disable-next-line max-len
      const offset = new Date().getTimezoneOffset(); // и по нему из массива timezones подобрать первый у которого такое смещение
      return this.timezones.find((t) => t.offset === offset)?.name;
    }
  }

  public isValid(field: string): boolean {
    const control = this.form?.get(field);

    return !this.validationEnabled || control.valid;
  }

  public weekDayName(weekday: WeekdaysEnum): string {
    const d = this.weekdays.find((w) => w.weekday === weekday);
    return d?.full || WeekdaysEnum[weekday];
  }

  // Утилиты для работы с временем

  /**
   * Возвращает массив дней недели в соотвествии с текущими языковыми настройками
   */
  private prepareWeekdays(): IWeekday[] {
    const now = new Date();
    const interval = {
      start: this.startOfWeek(now),
      end: this.endOfWeek(now),
    };
    return eachDayOfInterval(interval).map((date) => {
      const weedayNum = +this.dateFormat(date, 'i');
      return {
        // NOTE! в date-fns : 1 = monday, 7 = sunday
        // NOTE! в lingo2-models : 0 = WeekdaysEnum.sunday, 6 = WeekdaysEnum.saturday
        weekday: weedayNum % 7,
        full: this.dateFormat(date, 'iiii'),
      };
    });
  }

  private dateFormat(date: Date, format: string): string {
    return this._dateFormat.transform(date, format);
  }

  private startOfWeek(date: Date): Date {
    return startOfWeek(date, { locale: this.dateConfig.locale() });
  }

  private endOfWeek(date: Date): Date {
    return endOfWeek(date, { locale: this.dateConfig.locale() });
  }

  public save() {
    this.validationEnabled = true;
    if (!this.form.valid || this.saving) {
      this.route.fragment.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (f) => {
          const element = document.querySelector('#anchor-regular');
          if (element) {
            element.scrollIntoView(true);
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
      return;
    }

    this.forceSave = false;
    this.saveState = 'progress';
    this.saving = true;
    this.detectChanges();

    this.accountService
      .updateAccount({ timezone: this.form.value.timezone })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (account) => {
          this.contextService.updateMe(account);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SAVE-PROBLEM');
        },
      });

    this.scheduleService
      .updateRegular({ ...this.form.value })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (schedule) => {
          this.createForm(schedule);
          this.saved = true;
          this.saving = false;
          if (this.isSeparate) {
            this.router.navigate(['/profile/application']);
          }
          this.saveState = 'default';
          this.detectChanges();
        },
        error: (err: any) => {
          this.saved = false;
          this.saving = false;
          this.saveState = 'fail';
          this.detectChanges();
        },
      });
  }

  /**
   * Создаёт расписание и настраивает интерфейс для нового пользователя
   */
  protected initSchedule(schedule: RegularSchedule): [RegularSchedule, boolean] {
    let changed = false;

    // Добавляем пустые дни недели, если их ещё нет
    const newWeekdays = schedule.weekdays.map((w) => {
      if (w.hours.length === 0) {
        changed = true;
        const begin_hour_at = 10;
        const end_hour_at = 19;
        return new RegularWeekday({
          ...w,
          enabled: false,
          hours: [{ begin_hour_at, end_hour_at }],
        });
      }
      return w;
    });

    return [
      new RegularSchedule({
        ...schedule,
        weekdays: newWeekdays,
      }),
      changed,
    ];
  }

  public skipStep(): void {
    this.accountService
      .addSegment(UserSegmentEnum.skipped_schedule_segment_in_introducion_form)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (account) => {
          this.router.navigate(['/profile/application']);
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        },
      });
  }

  public navigateBack(): void {
    this.router.navigate(['/profile/services']);
  }

  public trackByFn(index) {
    return index;
  }
}
