import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ConfigService, VocabularyService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { IconComponent } from '@shared/icon/icon.component';
import { Subject, VocabularyTerm } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';
import { SharedModule } from '../../shared.module';

@Component({
  selector: 'app-vocabulary-filter',
  standalone: true,
  imports: [SharedModule, IconComponent],
  templateUrl: './vocabulary-filter.component.html',
  styleUrls: ['./vocabulary-filter.component.scss'],
})
export class VocabularyFilterComponent extends ChangableComponent implements OnInit {
  @Input() position = 'popup-left';
  @Input() vocabulary_list_id: string;
  @Output() onChange = new EventEmitter<Partial<VocabularyTerm>>();
  public popup_open = false;
  public languagesList: Subject[];
  public vocabularies: Array<Partial<VocabularyTerm>> = [];
  public selectedVocabulary: string;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    public store: Store,
    protected readonly cdr: ChangeDetectorRef,
    public vocabularyService: VocabularyService,
    public configService: ConfigService,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    this.configService.subjectsV2$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (res) => {
        this.languagesList = res.foreign;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    this.loadVocabularies();

    this.vocabularyService.currentVocabulary$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (res) => {
        const activeVocabulary = this.vocabularies.find(
          (v) => v.definition_language_id == res.definition_language_id && v.term_language_id == res.term_language_id,
        );
        if (activeVocabulary) {
          this.setVocabularyLanguage(activeVocabulary, false);
        }
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });
  }

  public popupState(arg) {
    this.popup_open = arg;
  }

  public loadVocabularies() {
    const vocabulary_list_id = this.vocabulary_list_id ? [this.vocabulary_list_id] : null;
    this.vocabularyService
      .getVocabularies({ vocabulary_list_id })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.vocabularies = res;
          if (this.vocabularies.length) {
            this.setVocabularyLanguage(this.vocabularies[0], false);
          }
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public setVocabularyLanguage(item: Partial<VocabularyTerm>, onChange: boolean) {
    this.selectedVocabulary =
      this.getLanguageName(item.term_language_id) + ' - ' + this.getLanguageName(item.definition_language_id);
    if (onChange) {
      this.onChange.emit(item);
      this.popupState(false);
    }
  }

  public getLanguageName(id: number) {
    return this.languagesList.find((el) => el.language_id === id)?.title;
  }

  public trackByFn(index) {
    return index;
  }
}
