<div class="constructor" [class.mobile]="deviceService.isMobile()" [class.tablet]="deviceService.isTablet()">

  <div *ngIf="false" class="header">
    <h1 class="header-title">{{ 'constructor2.page-header-title' | translate }}</h1>
    <app-search-input [formControl]="searchControl"></app-search-input>
  </div>

  <div *ngIf="false" class="header-wrap">
    <app-main-header></app-main-header>
  </div>

  <on-ui-tabs *ngIf="false" [tabs]="tabs"></on-ui-tabs>

  <router-outlet></router-outlet>
</div>

