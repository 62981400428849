import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { ChangableComponent } from '@app/models/changable.component';
import { AccountService, FeaturesService, PlatformService } from '@core/services';
import { User, UserSegmentEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { takeUntil } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-profile-application',
  templateUrl: './profile-application.component.html',
  styleUrls: ['./profile-application.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProfileApplicationComponent extends ChangableComponent implements OnInit, OnDestroy {
  @Input() hasApp: boolean;
  @Input() hasAppOrSkip: boolean;
  @Input() user: User;
  public hasSegment = AccountService.hasSegment;
  public lastStep: boolean;
  public apple: SafeResourceUrl = '';
  public android: SafeResourceUrl = '';

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private router: Router,
    private sanitizer: DomSanitizer,
    public accountService: AccountService,
    public features: FeaturesService,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
    this.apple = this.sanitizer.bypassSecurityTrustUrl('https://apps.apple.com/app/onclass-messenger/id1536409641');
    this.android = this.sanitizer.bypassSecurityTrustUrl(
      'https://play.google.com/store/apps/details?id=com.onclass.chat',
    );
  }

  public ngOnInit() {}

  public get isTeacher(): boolean {
    return AccountService.isAsIfTeacher(this.user);
  }

  public navigateTo(step: string): void {
    if (this.isTeacher) {
      this.router.navigate(['/profile/' + step]);
    } else {
      this.router.navigate(['/profile/contacts']);
    }
  }

  public skipStep(): void {
    this.accountService
      .addSegment(UserSegmentEnum.skipped_mobile_app_segment_in_introducion_form)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (account) => {
          this.markForCheck();
          this.lastStep = true;
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
          this.lastStep = false;
        }
      });
  }

  public showLastStep(): void {
    if (this.isTeacher) {
      this.lastStep = true;
    } else {
      this.router.navigate(['/main']);
    }
  }
}
