import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { MainHeaderModule } from '@core/components/main-header/main-header.module';

// Store
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { OnUiTabsModule } from 'onclass-ui';
import { ContentValidationService } from './_services/content-validation.service';
import { ConstructorSidebarModule } from './constructor-sidebar/constructor-sidebar.module';
import { ContentEditorRoutingModule } from './content-editor-routing.module';
import { ContentEditorComponent } from './content-editor.component';
import { reducers, contentEditorFeatureKey } from './store';
import { LessonEffects } from './store/lesson/lesson.effects';
import { LibraryEffects } from './store/library/library.effects';
import { SidebarEffects } from './store/sidebar/sidebar.effects';
import { StatsEffects } from './store/stats/stats.effects';
import { StubPageModule } from './stub-page/stub-page.module';

@NgModule({
  imports: [
    CommonModule,
    TranslateModule,
    RouterModule,
    StoreModule.forFeature(contentEditorFeatureKey, reducers),
    EffectsModule.forFeature([LessonEffects, LibraryEffects, SidebarEffects, StatsEffects]),
    ReactiveFormsModule,
    ConstructorSidebarModule,
    StubPageModule,
    ContentEditorRoutingModule,
    MainHeaderModule,
    OnUiTabsModule,
  ],
  declarations: [ContentEditorComponent],
  providers: [ContentValidationService],
})
export class ContentEditorModule {}
