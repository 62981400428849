import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SidebarService } from '@core/layouts/main/sidebar/sidebar.service';
import {
  ContextService,
  FeaturesService,
  PlatformService,
  ProfileService,
  SchoolsService,
  ScreenService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadMe } from '@store/actions/profile.actions';
import { FeatureEnum, User } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiTab } from 'onclass-ui';
import { takeUntil } from 'rxjs';

export enum SchoolStepsEnum {
  main = 'main',
  owners = 'owners',
  setTeacher = 'set-teacher',
  classes = 'classes',
  card = 'card',
  accounts = 'accounts',
  schedule = 'schedule',
  calculations = 'calculations',
  participants = 'participants',
  status = 'status',
}

interface IMenuItem {
  label: string;
  route: SchoolStepsEnum;
}

@Component({
  selector: 'app-school-settings-left-sidebar',
  templateUrl: './school-settings-left-sidebar.component.html',
  styleUrls: ['./school-settings-left-sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolSettingsLeftSidebarComponent extends ChangableComponent implements OnInit {
  @Output() setActiveMenu = new EventEmitter<string>();
  public visible = false;
  private _clickOutsideEnabled = false;
  private _timer: any;
  public _schoolMenuList: OnUiTab[] = [
    { code: SchoolStepsEnum.main, label: 'school-tabs.main', route: SchoolStepsEnum.main },
    { code: SchoolStepsEnum.owners, label: 'school-tabs.owners', route: SchoolStepsEnum.owners },
    { code: SchoolStepsEnum.setTeacher, label: 'school-tabs.set-tutors', route: SchoolStepsEnum.setTeacher },
    { code: SchoolStepsEnum.classes, label: 'school-tabs.classes', route: SchoolStepsEnum.classes },
    { code: SchoolStepsEnum.card, label: 'school-tabs.card', route: SchoolStepsEnum.card },
    { code: SchoolStepsEnum.accounts, label: 'school-tabs.accounts', route: SchoolStepsEnum.accounts },
    { code: SchoolStepsEnum.schedule, label: 'school-tabs.calendar', route: SchoolStepsEnum.schedule },
    { code: SchoolStepsEnum.calculations, label: 'school-tabs.calculations', route: SchoolStepsEnum.calculations },
    { code: SchoolStepsEnum.participants, label: 'school-tabs.participants', route: SchoolStepsEnum.participants },
    { code: SchoolStepsEnum.status, label: 'school-tabs.status', route: SchoolStepsEnum.status },
  ];

  public activeMenu: SchoolStepsEnum | string;
  public me: User;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public router: Router,
    public route: ActivatedRoute,
    public screenService: ScreenService,
    public profileService: ProfileService,
    public contextService: ContextService,
    public sidebarService: SidebarService,
    public schoolService: SchoolsService,
    public features: FeaturesService,
    public store: Store,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.route.paramMap.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (query) => {
        this.activeMenu = query.get('step');
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    this.sidebarService.leftState$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (visible) => {
        this.visible = visible;
        this.screenService.setBodyFixed(visible);
        this.clickOutsideEnabled = visible;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.visible = true;
        this.screenService.setBodyFixed(false);
        this.clickOutsideEnabled = false;
      },
    });

    this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (me) => {
        this.me = me;
        this.markForCheck();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.store.dispatch(loadMe());
      },
    });
  }

  public get schoolMenuList() {
    return this._schoolMenuList.filter((item) => item.code !== (SchoolStepsEnum.card as string));
  }

  public get clickOutsideEnabled(): boolean {
    return this._clickOutsideEnabled;
  }

  public set clickOutsideEnabled(value: boolean) {
    this.onBrowserOnly(() => {
      if (value) {
        this.clearTimeout(this._timer);
        this._timer = this.setTimeout(() => {
          this._clickOutsideEnabled = true;
          this.detectChanges();
        }, 200);
      } else {
        this._clickOutsideEnabled = false;
        this.detectChanges();
      }
    });
  }

  public closeSidebar() {
    this.sidebarService.setLeftSidebarState(false);
  }

  public navigateTo(url: string): void {
    this.activeMenu = url;
    this.setActiveMenu.emit(url);
    this.closeSidebar();
  }

  public isActive(step: IMenuItem): boolean {
    return this.activeMenu === (step.route as string);
  }

  public trackByFn(index) {
    return index;
  }
}
