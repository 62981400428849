import { Injectable } from '@angular/core';

import { ConfigService, ITimezone } from '@core/services';
import { Country, Language, LanguageLevel } from 'lingo2-models';
import { Observable, zip } from 'rxjs';
import { map, take, filter } from 'rxjs/operators';

export interface IAboutFormReferences {
  countries: Country[];
  languages: Language[];
  languageLevels: LanguageLevel[];
  timezones: ITimezone[];
}

@Injectable()
export class AboutFormReferencesResolver {
  constructor(protected configService: ConfigService) {}

  resolve(): Observable<IAboutFormReferences> {
    this.configService.load(['countries$', 'languages$', 'languageLevels$', 'timezones$']);

    return zip(
      this.configService.countries$.pipe(filter((items) => items?.length > 0)),
      this.configService.languages$.pipe(filter((items) => items?.length > 0)),
      this.configService.languageLevels$.pipe(filter((items) => items?.length > 0)),
      this.configService.timezones$.pipe(filter((items) => items?.length > 0)),
    ).pipe(
      map(([countries, languages, languageLevels, timezones]) => ({ countries, languages, languageLevels, timezones })),
      take(1),
    );
  }
}
