import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { UntypedFormArray, UntypedFormGroup, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { IControlOption } from '@app/shared';
import { ChangableComponent } from '@models/changable.component';
import { beginHourAtAndEndHourAtValidate } from '../regular-schedule-form.validators';

@Component({
  selector: 'app-regular-schedule-day-form',
  templateUrl: './regular-schedule-day-form.component.html',
  styleUrls: ['./regular-schedule-day-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RegularScheduleDayFormComponent extends ChangableComponent implements OnInit {
  @Input() public control: UntypedFormGroup;
  public allHoursOptions: IControlOption[] = [];
  public hoursOptionsFrom: IControlOption[] = [];
  public hoursOptionsTo: IControlOption[][] = [];

  public constructor(private fb: UntypedFormBuilder, protected readonly cdr: ChangeDetectorRef) {
    super(cdr);
  }

  public ngOnInit() {
    this.prepareAllHoursOptions();
  }

  public addHours(begin_hour_at = 9, end_hour_at = 15) {
    const element = this.fb.group(
      {
        begin_hour_at,
        end_hour_at,
      },
      { validator: beginHourAtAndEndHourAtValidate() },
    );

    this.hoursArray.push(element);
    this.prepareToHours();
    this.control.markAsDirty();
  }

  public removeHours(idx: number) {
    this.hoursArray.removeAt(idx);
    this.prepareToHours();
    this.control.markAsDirty();
  }

  public get hoursArray(): UntypedFormArray {
    return this.control.get('hours') as UntypedFormArray;
  }

  private prepareAllHoursOptions() {
    this.allHoursOptions = [...new Array(25)].map((_dummy, h) => ({
      value: h,
      title: h < 10 ? `0${h}:00` : `${h}:00`,
    }));

    // https://app.clickup.com/t/862kamf3y Календарь: убрать 00:00 из полей "время"
    this.hoursOptionsFrom = this.allHoursOptions.filter((item) => item.value >= 1 && item.value <= 23);

    this.prepareToHours();
    this.detectChanges();
  }

  private prepareToHours() {
    this.hoursArray.value.forEach((el, index) => {
      this.hoursOptionsTo[index] = this.allHoursOptions.filter((v) => v.value > el.begin_hour_at);
    });
  }

  public onChangeHour(index, control: FormGroup) {
    const hour_from = this.hoursArray.value[index]?.begin_hour_at;
    const hour_to = this.hoursArray.value[index]?.end_hour_at;
    if (hour_from >= hour_to) {
      control.controls['end_hour_at'].setValue(hour_from + 1);
    }
    this.prepareToHours();
  }

  public canAddHours(idx: number): boolean {
    return this.hoursArray.controls.length - 1 === idx;
  }

  public canRemoveHours(): boolean {
    return this.hoursArray.controls.length > 1;
  }

  public trackByFn(index) {
    return index;
  }
}
