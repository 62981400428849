import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DestroyableComponent } from '@models/destroyable.component';

@Component({
  selector: 'app-mobile-app',
  templateUrl: './mobile-app.component.html',
  styleUrls: ['./mobile-app.component.scss'],
})
export class MobileAppComponent extends DestroyableComponent implements OnInit {
  constructor(protected sanitizer: DomSanitizer) {
    super();
    this.apple = this.sanitizer.bypassSecurityTrustUrl('https://apps.apple.com/app/onclass-messenger/id1536409641');
    this.android = this.sanitizer.bypassSecurityTrustUrl(
      'https://play.google.com/store/apps/details?id=com.onclass.chat',
    );
  }

  public apple: SafeResourceUrl = '';
  public android: SafeResourceUrl = '';

  ngOnInit(): void {}
}
