import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  BillingService,
  defaultUserServiceDetails,
  UserServicesService,
  ServicesBlockType,
  FilesService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import {
  CSchool,
  ESchoolParticipantRole,
  ESchoolStatus,
  FindUserServiceStrategyEnum,
  IBillingCard,
  IFindUserServicesFilter,
  User,
  UserServiceDetailsType,
} from 'lingo2-models';
import { throwError } from 'rxjs';
import { catchError, first, takeUntil, tap } from 'rxjs/operators';

@Component({
  selector: 'app-school-onboarding-widget',
  templateUrl: './school-onboarding-widget.component.html',
  styleUrls: ['./school-onboarding-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolOnboardingWidgetComponent extends ChangableComponent implements OnInit {
  @Input() set school(school: CSchool) {
    this._school = school;
    this.prepareCard();
    this.loadServices();
    this.loadBankCards();
  }
  @Input() me: User;
  public coverImgUrl: string;
  public title: string;
  public status: ESchoolStatus;
  public expandBlock: boolean;
  public checkList: any[] = [];
  public billingCards: IBillingCard[] = [];
  public services: ServicesBlockType = {
    items: [],
    loading: false,
    loaded: false,
    filter: {
      use: FindUserServiceStrategyEnum.school_owned,
    },
    pagination: {
      page: 1,
      pageSize: 10,
    },
  };

  private _school: CSchool;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private userServicesService: UserServicesService,
    private readonly billingService: BillingService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit(): void {}

  public get meIsOwnerSchool(): boolean {
    return !!this._school?.participants
      .filter((_p) => _p.role === ESchoolParticipantRole.owner)
      .find((_part) => _part.account_id === this.me?.id);
  }

  public toggleExpand() {
    this.expandBlock = !this.expandBlock;
  }

  public statusValue(): string {
    return this.status >= ESchoolStatus.published ? 'school-state.published' : 'school-state.unpublished';
  }

  public get isVerified(): boolean {
    return this.status >= ESchoolStatus.published;
  }

  private prepareCard() {
    this.coverImgUrl = FilesService.getFileUrlBySize(this._school?.cover_id);
    this.title = this._school?.title;
    this.status = this._school?.status;
  }

  private checkListGenerate() {
    this.checkList = [
      {
        state: true,
        label: 'school-onboarding.step1',
      },
      {
        state: !!this._school?.participants?.filter((_t) => _t.role === ESchoolParticipantRole.teacher)?.length,
        label: 'school-onboarding.step2',
      },
      {
        state: !!this.services?.items?.length,
        label: 'school-onboarding.step3',
      },
      {
        state: !!this.billingCards?.length,
        label: 'school-onboarding.step4',
      },
      {
        state: this._school?.status >= ESchoolStatus.published,
        label: 'school-onboarding.step5',
      },
    ];
  }

  private loadServices() {
    if (!this._school) {
      return;
    }
    this.services.loading = true;
    this.detectChanges();

    const filter: Partial<IFindUserServicesFilter> = {
      ...this.services.filter,
      school_id: this._school?.id,
    };

    const details: UserServiceDetailsType[] = [...defaultUserServiceDetails];

    this.userServicesService
      .getServices(filter, this.services.pagination, details)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          this.services.items = response.results;
          this.services.pagination = response.pagination;
          this.services.loaded = true;
          this.services.loading = false;
          this.checkListGenerate();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.services.loading = false;
          this.detectChanges();
        },
      });
  }

  private loadBankCards() {
    if (!this._school?.id) {
      return;
    }

    this.billingService
      .getCardsForSchool(this._school?.id)
      .pipe(first(), takeUntil(this.destroyed$))
      .subscribe({
        next: (cardList) => {
          this.billingCards = cardList;
          this.checkListGenerate();
          this.markForCheck();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public trackByFn(index) {
    return index;
  }
}
