import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { CertificateUploaderModule } from '@app/account/profile-form-page/education-form-page/certificate-uploader/certificate-uploader.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldModule } from '@shared/form-controls';
import { IconComponent } from '@shared/icon/icon.component';
import { OnUiButtonModule, OnUiInputModule, OnUiSelectModule } from 'onclass-ui';
import { EducationFormPageComponent } from './education-form-page.component';

@NgModule({
  declarations: [EducationFormPageComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    IconComponent,
    OnUiInputModule,
    FormFieldModule,
    CertificateUploaderModule,
    OnUiSelectModule,
    OnUiButtonModule,
  ],
  exports: [EducationFormPageComponent],
})
export class EducationFormPageModule {}
