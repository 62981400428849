<section class="advantages-common">
  <div class="container">
    <div class="advant-wrapper">
      <div class="advant-item">
        <div class="image first-advant"></div>
        <div class="title">{{'landings.home.advantages-text1' | translate}}</div>
      </div>
      <div class="advant-item">
        <div class="image second-advant"></div>
        <div class="title">{{'landings.home.advantages-text2' | translate}}</div>
      </div>
      <div class="advant-item">
        <div class="image third-advant"></div>
        <div class="title">{{'landings.home.advantages-text3' | translate}}</div>
      </div>
    </div>
  </div>
</section>
