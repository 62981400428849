<ng-container *ngIf="!isListSelected; else listDetails">

  <div class="title-wrap" [class.mobile]="deviceService.isMobile()">
    <app-title [title]="'my-vocabulary.tabs.lists' | translate">
      <ng-container side>
        <on-ui-button class="primary outline"
                      state="default"
                      [label]="'my-vocabulary.action.new-list' | translate"
                      (click)="onCreateList()">
        </on-ui-button>
      </ng-container>
    </app-title>
  </div>

  <ng-container *ngIf="lists.loaded">
  <div class="list-wrap" *ngIf="lists.items.length;else noItems">
    <app-vocabulary-list-card-mini
      *ngFor="let item of lists.items; trackBy: trackByFn"
      [item]="item"
      [withActions]="true"
      (click)="setActiveItem(item)"
      (open)="onListSelect(item)"
      (action)="onChange($event, item)">
    </app-vocabulary-list-card-mini>
  </div>
  </ng-container>

  <ng-template #noItems>
    <app-no-content-stub
      [icon]="'stub-vocabulary'"
      [text]="'my-vocabulary.empty-list.title'"
      [text2]="'my-vocabulary.empty-list.info'">
    </app-no-content-stub>
  </ng-template>

  <div class="pagination-wrap">
    <app-paginator [pagination]="lists.pagination" (pageChanged)="loadPage($event)"></app-paginator>
  </div>
</ng-container>

<ng-template #listDetails>
  <app-vocabulary-list-card-details [item]="selectedList" (back)="goBack()"></app-vocabulary-list-card-details>
</ng-template>

<ng-template [ngIf]="listFormModalOpened">
  <app-modal *ngIf="!deviceService.isMobile()"
             [canClose]="false"
             (closed)="onCancel()">
    <app-modal-body>
      <app-list-form [list]="selectedList" (saved)="onListSave()"></app-list-form>
    </app-modal-body>
  </app-modal>

  <app-mobile-modal *ngIf="deviceService.isMobile()"
                    (closed)="onCancel()">
    <app-mobile-modal-body>
      <app-list-form [list]="selectedList" (saved)="onListSave()"></app-list-form>
    </app-mobile-modal-body>
  </app-mobile-modal>
</ng-template>

<ng-template [ngIf]="listModalOpened">
  <app-modal *ngIf="!deviceService.isMobile()"
             [canClose]="false"
             (closed)="onCancel()">
    <app-modal-body>
      <app-vocabulary-card [page]="'list'"
                           (onSelect)="onItemsSelect($event)">
      </app-vocabulary-card>
    </app-modal-body>
    <app-modal-ad *ngIf="showSurface">
      <app-vocabulary-surface [item]="selectedList" [count]="showSurface" (action)="onAction($event)"></app-vocabulary-surface>
    </app-modal-ad>
  </app-modal>

  <app-mobile-modal *ngIf="deviceService.isMobile()"
                    (closed)="onCancel()">
    <app-mobile-modal-body>
      <app-vocabulary-card
        [page]="'list'"
        (onSelect)="onItemsSelect($event)">
      </app-vocabulary-card>
    </app-mobile-modal-body>
    <app-mobile-modal-ad *ngIf="showSurface">
      <app-vocabulary-surface [item]="selectedList" [count]="showSurface" (action)="onAction($event)"></app-vocabulary-surface>
    </app-mobile-modal-ad>
  </app-mobile-modal>
</ng-template>

