import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';

import { AllStepsModule } from '@app/landings/profile/all-steps/all-steps.module';
import { OnboardingQrCodeModule } from '@core/widgets/onboarding-widget/shared';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { OnUiButtonModule } from 'onclass-ui';
import { ProfileApplicationComponent } from './profile-application.component';

@NgModule({
  declarations: [ProfileApplicationComponent],
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    IconComponent,
    OnboardingQrCodeModule,
    AllStepsModule,
    OnUiButtonModule,
  ],
  exports: [ProfileApplicationComponent],
})
export class ProfileApplicationModule {}
