<ng-template [ngIf]="meetings.loaded && meetings.pagination.total === 0">
  <!--<app-no-content-stub></app-no-content-stub>-->
</ng-template>

<ng-template ngFor let-item [ngForOf]="paneList" let-i="index" [ngForTrackBy]="paneTrackByFn"
             class="feed-item">
  <section [attr.rel]="item.rel">
    <app-pane [item]="item"></app-pane>
  </section>
</ng-template>

<ng-template [ngIf]="meetings.loaded && meetings.items.length > 0">
  <app-meeting-card
    *ngFor="let meeting of meetings.items; trackBy: trackByFn"
    [meeting]="meeting"></app-meeting-card>
</ng-template>

<app-pagination
  [enabled]="meetings.loaded"
  [loading]="meetings.loading"
  [pagination]="meetings.pagination"
  baseUrl=""
  (nextPage)="nextPage()"></app-pagination>
