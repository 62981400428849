<div class="profile-page" [class.mobile]="deviceService.isMobile()" *ngIf="show">
  <ng-container [ngSwitch]="step">
    <ng-container *ngSwitchCase="steps.personal">
      <div *ngIf="!onModeration; else moderationBlock">
        <app-about-form-page [referencesData]="aboutReferences"></app-about-form-page>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="steps.main">
      <div *ngIf="!onModeration; else moderationBlock">
        <app-about-form-page [referencesData]="aboutReferences"></app-about-form-page>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="steps.about">
      <div *ngIf="!onModeration; else moderationBlock">
        <app-about-form-page [referencesData]="aboutReferences"></app-about-form-page>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="steps.contacts">
      <div *ngIf="!onModeration; else moderationBlock">
        <app-contacts-form-page (changeStep)="changePage()"></app-contacts-form-page>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="steps.education">
      <div *ngIf="!onModeration; else moderationBlock">
        <app-education-form-page
          [needAdditionalAction]="needAdditionalAction">
        </app-education-form-page>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="steps.experience">
      <div *ngIf="!onModeration; else moderationBlock">
        <app-experience-form-page
          (checkSteps)="checkSteps()"
        ></app-experience-form-page>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="steps.teaching">
      <div *ngIf="!onModeration; else moderationBlock">
        <app-teaching-form-page></app-teaching-form-page>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="steps.services">
      <app-profile-services [hasServiceOrSkip]="hasServiceOrSkip"></app-profile-services>
    </ng-container>

    <ng-container *ngSwitchCase="steps.schedule">
      <app-regular-schedule-form [hasScheduleOrSkip]="hasScheduleOrSkip" [isSeparate]="true"></app-regular-schedule-form>
    </ng-container>

    <ng-container *ngSwitchCase="steps.payout">
      <app-profile-payment [type]="'payout'"></app-profile-payment>
    </ng-container>
    <ng-container *ngSwitchCase="steps.payment">
      <app-profile-payment [type]="'payment'"></app-profile-payment>
    </ng-container>


    <ng-container *ngSwitchCase="steps.application">
      <app-profile-application [hasApp]="hasApp" [hasAppOrSkip]="hasAppOrSkip" [user]="me"></app-profile-application>
    </ng-container>

    <ng-container *ngSwitchDefault>
      <div *ngIf="!onModeration; else moderationBlock">
        <app-about-form-page [referencesData]="aboutReferences"></app-about-form-page>
      </div>
    </ng-container>

    <ng-template #moderationBlock>
      <app-on-moderation></app-on-moderation>
    </ng-template>

  </ng-container>
</div>
