<div *ngIf="control" [formGroup]="control">

  <div class="hours" formArrayName="hours" data-test="hours">
    <div *ngFor="let control of hoursArray.controls; let idx = index; trackBy: trackByFn" [formGroupName]="idx" class="hour">
      <div class="select-wrap">
        <on-ui-select [options]="hoursOptionsFrom"
                      data-test="begin-hour-at"
                      formControlName="begin_hour_at"
                      [error]="control.invalid"
                      [placeholder]="'9:00'"
                      (changed)="onChangeHour(idx, control)">
        </on-ui-select>
      </div>
      <span class="to normal">{{ 'regular-schedule-form.to' | translate }}</span>
      <div class="select-wrap">
        <on-ui-select [options]="hoursOptionsTo[idx]"
                      data-test="end-hour-at"
                      formControlName="end_hour_at"
                      [error]="control.invalid"
                      [placeholder]="'17:00'"
                      (changed)="onChangeHour(idx, control)">
        </on-ui-select>
      </div>
      <ng-template [ngIf]="canRemoveHours()">
        <div class="action" (click)="removeHours(idx)"
             data-test="delete-hour">
          <app-icon class="basket-icon"
                    [width]="30"
                    [height]="30"
                    file="basket"
                    icon="basket-3">
          </app-icon>
        </div>
      </ng-template>

      <ng-template [ngIf]="canAddHours(idx)">
        <div class="action" (click)="addHours()"
             data-test="add-hour">
          <app-icon class="plus-icon"
                    [width]="16"
                    [height]="16"
                    file="plus"
                    icon="plus-2">
          </app-icon>
        </div>
      </ng-template>
    </div>
  </div>
</div>
