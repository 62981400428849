import { formatDate } from '@angular/common';
import { Injectable } from '@angular/core';
import { CalendarDateFormatter, DateFormatterParams, getWeekViewPeriod } from 'angular-calendar';

@Injectable()
export class CustomDateFormatter extends CalendarDateFormatter {
  /**
   * The week view title
   */
  public weekViewTitle({ date, locale, weekStartsOn, excludeDays, daysInWeek }: DateFormatterParams): string {
    const { viewStart, viewEnd } = getWeekViewPeriod(this.dateAdapter, date, weekStartsOn, excludeDays, daysInWeek);
    const format = (dateToFormat: Date, showYear: boolean) =>
      formatDate(dateToFormat, `MMM d${showYear ? ', yyyy' : ''}`, locale);
    return `${format(viewStart, viewStart.getUTCFullYear() !== viewEnd.getUTCFullYear())} - ${format(viewEnd, true)}`;
  }

  /**
   * The day view title
   */
  public dayViewTitle({ date, locale }: DateFormatterParams): string {
    if (locale === 'ru') {
      return formatDate(date, 'EEEE, d MMMM, y', locale);
    }
    return formatDate(date, 'EEEE, MMMM d, y', locale);
  }
}
