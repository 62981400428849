<footer class="job-footer">
  <div class="nav" itemscope itemtype="http://schema.org/SiteNavigationElement">
    <div class="links">
      <ul class="link-wrap">
        <li class="link-item" *ngFor="let item of linkItems1; trackBy: trackByFn"
            [attr.title]="item.label | translate">
          <a class="link normal"
             itemprop="url"
             [routerLink]="item.route">
            <span>{{ item.label | translate }}</span>
          </a>
        </li>
      </ul>
    </div>

    <div class="copyright">
      ©&nbsp;{{ 'APP_NAME' | translate }}, {{ copyYear }}
    </div>
  </div>
</footer>
