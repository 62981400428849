import { Component, OnInit } from '@angular/core';
import { DestroyableComponent } from '@models/destroyable.component';

@Component({
  selector: 'app-instruments',
  templateUrl: './instruments.component.html',
  styleUrls: ['./instruments.component.scss'],
})
export class InstrumentsComponent extends DestroyableComponent implements OnInit {
  constructor() {
    super();
  }

  ngOnInit(): void {}
}
