import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, Output } from '@angular/core';
import { AccountService, FilesService, PlatformService, SchoolsService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { CSchoolParticipant, SchoolOptions } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiCover } from 'onclass-ui/lib/modules/on-ui-cover/on-ui-cover.component';
import { takeUntil } from 'rxjs';

@Component({
  selector: 'app-school-calc-teacher-card',
  templateUrl: './school-calc-teacher-card.component.html',
  styleUrls: ['./school-calc-teacher-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolCalcTeacherCardComponent extends ChangableComponent {
  @Input() set teacher(teacher: CSchoolParticipant) {
    this._teacher = teacher;
    this.prepareCard();
  }
  @Input() schoolOptions: SchoolOptions;
  @Output() changed = new EventEmitter<boolean>();
  public fullName: string;
  public ownProfit: number;
  public saved = false;
  public saveSuccess = false;
  public cover: OnUiCover;

  private _teacher: CSchoolParticipant;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public deviceService: DeviceDetectorService,
    protected schoolService: SchoolsService,
    protected accountService: AccountService,
    protected cdr: ChangeDetectorRef,
    protected readonly platform?: PlatformService,
  ) {
    super(cdr, platform);
  }

  public updateParticipant() {
    this.saved = true;
    const own_profit = this.ownProfit / 100;
    this.schoolService
      .updateParticipant(this._teacher?.id, { own_profit })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (newParticipant) => {
          this._teacher = newParticipant;
          this.setTimeout(() => {
            this.changed.emit(true);
            this.saved = false;
            this.saveSuccess = true;
            this.setTimeout(() => {
              this.saveSuccess = false;
              this.detectChanges();
            }, 2000);
            this.detectChanges();
          }, 2000);
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.saved = false;
          this.detectChanges();
        },
      });
  }

  public get schoolProfit(): number {
    return 100 - this.ownProfit;
  }

  private loadAccountDetails(id: string) {
    if (!id) {
      return;
    }
    this.accountService
      .getUserById(id, 'LOAD_TIME')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (user) => {
          this.fullName = AccountService.getUserFullName(user);
          this.cover = {
            type: 'avatar',
            img: FilesService.getFileUrlBySize(user.userpic_id),
            title: this.fullName,
            form: 'circle',
          };
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  private prepareCard() {
    this.loadAccountDetails(this._teacher.account_id);
    this.ownProfit = Math.round(this._teacher?.own_profit * 100);
    this.detectChanges();
  }
}
