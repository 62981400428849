<ng-container *ngIf="users.loaded">
<div class="users-wrap" [class.mobile]="deviceService.isMobile()" *ngIf="users.items.length; else noItems">
  <div *ngFor="let user of users.items; trackBy: trackByFn" class="tutor-item">
  <app-tutor-mini [display_mode]="'row'" [user]="user" [hide_classes]="true" (clicked)="openUserVocabulary($event)"></app-tutor-mini>
  </div>
</div>
</ng-container>

<ng-template #noItems>
  <app-no-content-stub
    [icon]="'stub-vocabulary'"
    [text]="'my-vocabulary.empty-students'">
  </app-no-content-stub>
</ng-template>

<app-modal *ngIf="!deviceService.isMobile() && vocabularyModalOpen"
           [canClose]="false"
           (closed)="onCancel()">
  <app-modal-body>
    <app-title [title]="userFullName"></app-title>
    <app-vocabulary-card
      [page]="'student'"
      [student]="selectedUser">
    </app-vocabulary-card>
  </app-modal-body>
</app-modal>

<app-mobile-modal *ngIf="deviceService.isMobile() && vocabularyModalOpen"
                  (closed)="onCancel()">
  <app-mobile-modal-body>
    <app-title [title]="userFullName"></app-title>
    <app-vocabulary-card
      [page]="'student'"
      [student]="selectedUser">
    </app-vocabulary-card>
  </app-mobile-modal-body>
</app-mobile-modal>
