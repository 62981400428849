import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TwoFactorAuthenticationComponent } from '@app/account/profile-form-page/contacts-form-page/two-factor-authentication/two-factor-authentication.component';
import { AlertModule } from '@core/components/alert/alert.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { TitleModule } from '@core/components/title/title.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldModule } from '@shared/form-controls';
import { IconComponent } from '@shared/icon/icon.component';

import { NgOtpInputModule } from 'ng-otp-input';
import {
  OnUiButtonModule,
  OnUiButtonTabModule,
  OnUiCoverModule,
  OnUiFormFieldErrorModule,
  OnUiFormStepModule,
  OnUiInputModule,
} from 'onclass-ui';
import { ContactsFormPageComponent } from './contacts-form-page.component';
import { VerificationMarkComponent } from './verification-mark/verification-mark.component';
import { NgxIntlPhoneInputModule } from 'ngx-intl-phone-input';

@NgModule({
  declarations: [ContactsFormPageComponent, VerificationMarkComponent, TwoFactorAuthenticationComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    FormFieldModule,
    OnUiInputModule,
    OnUiButtonModule,
    ModalModule,
    NgOtpInputModule,
    AlertModule,
    SpinnersModule,
    TitleModule,
    OnUiFormStepModule,
    OnUiButtonTabModule,
    OnUiFormFieldErrorModule,
    MobileModalModule,
    OnUiCoverModule,
    IconComponent,
    NgxIntlPhoneInputModule,
  ],
  exports: [ContactsFormPageComponent],
})
export class ContactsFormPageModule {}
