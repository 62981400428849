import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { getClassroom } from '@app/classrooms/_store/classroom/classroom.selectors';
import { AccountDetailsType, AccountService, PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { User } from 'lingo2-models';
import { Classroom } from 'lingo2-models';
import { filter, takeUntil, mergeMap } from 'rxjs';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-classrooms-sidebar',
  templateUrl: './classrooms-sidebar.component.html',
  styleUrls: ['./classrooms-sidebar.component.scss'],
})
export class ClassroomsSidebarComponent extends ChangableComponent implements OnInit, OnDestroy {
  public classroom: Classroom;
  public teacher: User;
  public isClassroomPage = false;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    public errorNotificationService: ErrorNotificationService,
    public accountService: AccountService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  public ngOnInit(): void {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: () => {
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });

    this.store
      .select(getClassroom)
      .pipe(
        mergeMap((classroom) => {
          if (classroom && classroom.id) {
            this.classroom = classroom;
            return this.accountService.getUserById(this.classroom?.author_id, 'LOAD_TIME');
          }
        }),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (user) => {
          this.isClassroomPage = true;
          this.teacher = user;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.isClassroomPage = true;
          this.detectChanges();
        }
      });
  }

  public get loaded(): boolean {
    return !!this.isClassroomPage;
  }

  public get isClassroomMain(): boolean {
    return this.router.url === '/my-classes/classrooms';
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }
}
