import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AccountModule } from '@app/account/account.module';
import { UserWidgetComponent } from '@app/account/components/user-widget/user-widget.component';
import { LibraryFilterWidgetModule } from '@app/library/library-filter-widget/library-filter-widget.module';
import { LibraryPanesModule } from '@app/library/panes/library-panes.module';
import { BestEntityWidgetModule } from '@core/components/ad/best-entity-widget/best-entity-widget.module';
import { FormErrorsModule } from '@core/components/form-errors/form-errors.module';
import { MediaFileSelectorModule } from '@core/components/media-file-selector/media-file-selector.module';
import { MeetingsCardModule } from '@core/components/meeting-card/meetings-card.module';
import { MobileModalModule } from '@core/components/mobile-modal/mobile-modal.module';
import { ModalModule } from '@core/components/modal/modal.module';
import { PaginationModule } from '@core/components/pagination/pagination.module';
import { SpinnersModule } from '@core/components/spinners/spinners.module';
import { TitleModule } from '@core/components/title/title.module';
import { CoreModule } from '@core/core.module';
import { PipesModule } from '@core/pipes/pipes.module';
import { NearestMeetWidgetModule } from '@core/widgets';
import { QRCodeModule } from '@le2xx/angularx-qrcode';
import { TranslateModule } from '@ngx-translate/core';
import {
  MeetingFormModule,
  FinancePlanBuyCreditsFormModule,
  FinancePlanActivateFormModule,
  FinancePlanBuyCreditsFormV2Module,
  FinancePlanActivateFormV2Module,
} from 'lingo2-forms';
import { DateFnsModule } from 'lingo2-ngx-date-fns';
import { ClickOutsideModule } from 'ng-click-outside';
import { ClipboardModule } from 'ngx-clipboard';
import { DropzoneModule } from 'ngx-dropzone-wrapper';
import { MeetingsSidebarComponent } from './components/meetings-sidebar/meetings-sidebar.component';
import { MeetingPageModule } from './meeting-page/meeting-page.module';
import { MeetingsComponent } from './meetings/meetings.component';
import { MeetingsFormService } from './meetings-form.service';
import { MeetingsPageComponent } from './meetings-page/meetings-page.component';
import { UserServiceCardFullModule } from './user-service-card-full/user-service-card-full.module';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    TranslateModule,
    ReactiveFormsModule,
    DateFnsModule,
    FormsModule,
    DropzoneModule,
    ClickOutsideModule,
    ClipboardModule,
    SpinnersModule,
    TitleModule,
    FormErrorsModule,
    MediaFileSelectorModule,
    PipesModule,
    ModalModule,
    LibraryPanesModule,
    QRCodeModule,
    PaginationModule,
    MobileModalModule,
    MeetingsCardModule,
    UserServiceCardFullModule,
    MeetingFormModule,
    FinancePlanBuyCreditsFormModule,
    FinancePlanActivateFormModule,
    LibraryFilterWidgetModule,
    MediaFileSelectorModule,
    MeetingPageModule,
    BestEntityWidgetModule,
    AccountModule,
    UserWidgetComponent,
    NearestMeetWidgetModule,
    FinancePlanBuyCreditsFormV2Module,
    FinancePlanActivateFormV2Module,
  ],
  declarations: [MeetingsPageComponent, MeetingsSidebarComponent, MeetingsComponent],
  providers: [MeetingsFormService],
  exports: [MeetingsSidebarComponent],
})
export class MeetingsModule {}
