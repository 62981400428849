import { Component, OnInit, OnDestroy, Input, ChangeDetectorRef } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
  UntypedFormArray,
  ValidationErrors,
  UntypedFormControl,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { forceLoadAccountCheck } from '@app/store/actions/data.actions';
import {
  AuthService,
  AccountService,
  ContextService,
  ProfileService,
  PlatformService,
  ConfigService,
  ITimezone,
  FeaturesService,
} from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { IControlOption } from '@shared/form-controls/form-controls.models';
import { loadMe, setProfile } from '@store/actions/profile.actions';
import { getMe } from '@store/reducers/profile.reducer';
import { ISpokenLanguage, IUserSegment, IUserAchievement, User, TenantEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonState } from 'onclass-ui';
import { mergeMap, takeUntil } from 'rxjs';

import { debounceTime, filter, map, take } from 'rxjs/operators';
import { ProfileFormService } from '../_services/profile-form.service';
import { AboutFormMapperService } from './mapper/about-form-mapper.service';
import { IAboutFormReferences } from './resolvers/about-form-references.resolver';

const checkDuplicates = (arr) => new Set(arr).size !== arr.length;

export interface IAboutForm {
  first_name: string;
  last_name: string;
  slug?: string;
  timezone: string;
  country_id: string;
  birth_date: Date;
  userpic_id: string;
  cover_id: string;
  spoken_languages: ISpokenLanguage[];
  about: string;
  segments: IUserSegment[];
  achievements: IUserAchievement[];
}

// TODO: move to lingo2-models
export enum LanguageLevelsEnum {
  native = 1,
  basic = 2,
  read = 3,
  'read-write' = 4,
  speak = 5,
  fluent = 6,
}

const isEmptyString = (value: string): boolean => value?.trim().length === 0;
export const isAboutCompleted = (value: IAboutForm): boolean => {
  const all = Object.keys(value).every((key) => !!value[key]);
  const languages = value.spoken_languages.length && value.spoken_languages.every((x) => x.language_id && x.level_id);
  const native = value.spoken_languages.some((x) => +x.level_id === Number(LanguageLevelsEnum.native));
  const first_name = !isEmptyString(value.first_name);
  const last_name = !isEmptyString(value.last_name);

  return [all, languages, native, first_name, last_name].every((x) => !!x);
};

@Component({
  selector: 'app-about-form-page',
  templateUrl: './about-form-page.component.html',
  styleUrls: ['./about-form-page.component.scss'],
})
export class AboutFormPageComponent extends ChangableComponent implements OnInit, OnDestroy {
  @Input() referencesData: IAboutFormReferences;

  public form: UntypedFormGroup;
  public formAbout: UntypedFormGroup;
  public value: IAboutForm;
  public references: IAboutFormReferences;
  public _me: User;

  public countriesOptions: IControlOption[];
  public languagesOptions: IControlOption[];
  public languageLevelsOptions: IControlOption[];
  public timezonesOptions: IControlOption[] = [];
  public levels = LanguageLevelsEnum;

  public maxDate = new Date();
  public minDate = new Date(-2208988799000);

  public saveState: OnUiButtonState = 'default';
  private required = false;
  public step: string;
  private validateFormGroup = ProfileFormService.validateFormGroup;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    protected fb: UntypedFormBuilder,
    protected profileService: ProfileService,
    protected contextService: ContextService,
    protected accountService: AccountService,
    protected authService: AuthService,
    protected configService: ConfigService,
    protected features: FeaturesService,
    private route: ActivatedRoute,
    private router: Router,
    private mapper: AboutFormMapperService,
    private store: Store,
    private profileFormService: ProfileFormService,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.profileFormService.profileForm$
      .pipe(
        map((x) => x.about),
        filter((x) => !!x),
        take(1),
        takeUntil(this.destroyed$),
      )
      .subscribe((res) => {
        this.value = res;
        this.initForms();
      });
    this.references = this.referencesData;
    this.countriesOptions = this.mapper.countriesToOptions(this.references.countries);
    this.languagesOptions = this.mapper.languagesToOptions(this.references.languages);
    this.languageLevelsOptions = this.mapper.languageLevelsToOptions(this.references.languageLevels);
    this.timezonesOptions = this.timezonesOptionsInit(this.references.timezones);

    this.profileFormService.verificationRequested$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (requested) => {
        this.required = requested;
        this.validateFormGroup(this.form);
        this.validateFormGroup(this.formAbout);
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    this.route.paramMap.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (query) => {
        this.step = query.get('step');
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.step = 'main';
      },
    });

    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (me) => {
          this._me = me;
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadMe());
        },
      });
    // this.autoSaveUserInfo();
    // this.autoSaveProfileInfo();
  }

  public initForms() {
    // Init form and set form values
    this.form = this.fb.group({
      first_name: [this.value.first_name || '', [Validators.maxLength(100), this.conditionalEmptyValidator.bind(this)]],
      last_name: [this.value.last_name || '', [Validators.maxLength(100), this.conditionalEmptyValidator.bind(this)]],
      slug: [this.value.slug || ''],
      cover_id: [this.value.cover_id || '', []],
      timezone: [this.value.timezone || null],
      country_id: [
        this.value.country_id ? this.value.country_id.toString() : '',
        [this.conditionalEmptyValidator.bind(this)],
      ],
      userpic_id: [this.value.userpic_id || '', [this.conditionalEmptyValidator.bind(this)]],
    });

    this.formAbout = this.fb.group({
      birth_date: [this.value.birth_date || '', [this.conditionalEmptyValidator.bind(this)]],
      about: [this.value.about || '', [Validators.maxLength(2000), this.conditionalEmptyValidator.bind(this)]],
      spoken_languages: this.fb.array(this.spokenLanguagesArray(this.value.spoken_languages), [
        this.repeatLanguagesValidator,
        this.requiredNativeValidator,
      ]),
    });
  }

  private autoSaveUserInfo(): void {
    this.form.valueChanges
      .pipe(debounceTime(500))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => this.saveForm('user'),
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SAVE-PROBLEM');
        },
      });
  }

  private autoSaveProfileInfo(): void {
    this.formAbout.valueChanges
      .pipe(debounceTime(500))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => this.saveForm('profile'),
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SAVE-PROBLEM');
        },
      });
  }

  public timezonesOptionsInit(timezones: ITimezone[]): IControlOption[] {
    return timezones.map((tz) => ({
      title: `(${this.timezoneOffsetToHours(tz.offset)}) ${tz.name} - ${tz.cities.join(', ')}`,
      value: tz.name,
    }));
  }

  public timezoneOffsetToHours(offset: number): string {
    const sign = offset < 0 ? '-' : '+';
    const hhmm = new Date(Math.abs(offset) * 60 * 1000).toISOString().substr(11, 5);
    return 'UTC' + sign + hhmm;
  }

  public get isTeacher(): boolean {
    return AccountService.isAsIfTeacher(this._me);
  }

  private spokenLanguagesArray(values: ISpokenLanguage[]): UntypedFormGroup[] {
    if (!values?.length) {
      return [this.spokenLanguageForm()];
    } else {
      return values.map((value) => this.spokenLanguageForm(value));
    }
  }

  private spokenLanguageForm(value?: ISpokenLanguage): UntypedFormGroup {
    const _value: Partial<ISpokenLanguage> = value || {};
    return this.fb.group({
      language_id: [
        _value.language_id ? _value.language_id.toString() : '',
        [this.conditionalEmptyValidator.bind(this)],
      ],
      level_id: [
        _value.level_id ? _value.level_id.toString() : LanguageLevelsEnum.native.toString(),
        [this.conditionalEmptyValidator.bind(this)],
      ],
    });
  }

  private repeatLanguagesValidator(array: UntypedFormArray): ValidationErrors | null {
    const ids = array.value.map((x) => x.language_id);
    return checkDuplicates(ids) ? { duplicate: { value: true, text: 'profile-form.duplicate-text' } } : null;
  }

  private requiredNativeValidator(array: UntypedFormArray): ValidationErrors | null {
    const isNative = array.value.some((x) => +x.level_id === Number(LanguageLevelsEnum.native));
    return !isNative ? { requiredNative: { value: true, text: 'profile-form.required-native' } } : null;
  }

  private conditionalEmptyValidator(control: UntypedFormControl): ValidationErrors | null {
    if (!this.required) {
      return null;
    }
    return isEmptyString(control.value) ? { required: { value: true } } : null;
  }

  public get spokenLanguages(): UntypedFormArray {
    return this.formAbout.get('spoken_languages') as UntypedFormArray;
  }

  public isSpokenLanguageSelected(index: number): boolean {
    const curLanguage = this.spokenLanguages.at(index);
    return !!curLanguage.value.language_id;
  }

  public hasEmptySpokenLanguage(): boolean {
    return (this.spokenLanguages.value as any[]).filter((v) => !v.language_id || !v.level_id).length > 0;
  }

  public removeSpokenLanguage(index: number) {
    this.spokenLanguages.removeAt(index);
    this.formAbout.markAsDirty();
    this.formAbout.markAsTouched();
  }

  public addSpokenLanguage() {
    this.spokenLanguages.push(this.spokenLanguageForm());
  }

  public onUserPicChange() {
    this.form.markAsDirty();
    this.form.markAsTouched();
  }

  public saveForm(type: string): void {
    if (type === 'user') {
      if (this.form.invalid || !this.form.touched || this.saveState === 'progress') {
        return;
      }
    }

    if (type === 'profile') {
      if (this.formAbout.invalid || !this.formAbout.touched || this.saveState === 'progress') {
        return;
      }
    }

    this.saveState = 'progress';

    this.save({ ...this.form.value, ...this.formAbout.value }).then(
      () => {
        this.saveState = 'done';
        this.resetState();
      },
      () => {
        this.saveState = 'fail';
        this.resetState();
      },
    );
  }

  public isMeVersion() {
    return this.features.tenant === TenantEnum.onclass_me;
  }

  public navigateBack(): void {
    this.router.navigate(['/profile/main']).then();
  }

  public navigateToNextPage(page: string): void {
    this.forceSave();
    if (this.isMeVersion() && !this.isTeacher) {
      return;
    }
    this.setTimeout(() => {
      this.router.navigate(['/profile/' + page]).then();
    }, 1500);
  }

  public isInvalid(formControlName: string): boolean {
    return this.formAbout?.get(formControlName)?.invalid;
  }

  public isInvalidMain(formControlName: string): boolean {
    return this.form?.get(formControlName)?.invalid;
  }

  public isInvalidGroup(formGroup: UntypedFormGroup, formControlName: string): boolean {
    return formGroup.get(formControlName)?.invalid;
  }

  public onChangedField() {
    this.formAbout.markAsDirty();
    this.formAbout.markAsTouched();
  }

  public resetState() {
    this.setTimeout(() => {
      this.saveState = 'default';
      this.detectChanges();
    }, 1000);
  }

  private forceSave() {
    this.onChangedField();
    this.saveForm('profile');
  }

  public save(values: IAboutForm): Promise<boolean> {
    return new Promise<boolean>((resolve) => {
      const { first_name, last_name, slug, country_id, timezone, userpic_id, cover_id } = values;
      this.accountService
        .updateAccount({ first_name, last_name, slug, country_id, timezone, userpic_id, cover_id })
        .pipe(
          mergeMap((me) => {
            this.contextService.updateMe(me);
            const { spoken_languages, birth_date } = values;
            const about = this.profileFormService.stripHtml(values.about); // Удаляем HTML тэги
            const filtered_spoken_languages = spoken_languages.filter((el) => el.language_id);
            return this.profileService.updateProfile({
              about,
              spoken_languages: filtered_spoken_languages,
              birth_date,
            });
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe({
          next: (profile) => {
            this.contextService.patchProfile({
              about: profile.about,
              birth_date: profile.birth_date,
              spoken_languages: profile.spoken_languages,
              verification_status: profile.verification_status,
            });
            this.profileFormService.setProfileForm({ about: values });
            this.store.dispatch(setProfile({ profile }));
            this.store.dispatch(forceLoadAccountCheck());
            this.form.markAsUntouched();
            this.formAbout.markAsUntouched();
            localStorage.removeItem('timezone-skip');
            resolve(true);
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'SAVE-PROBLEM');
          },
        });
    });
  }

  public trackByFn(index) {
    return index;
  }
}
