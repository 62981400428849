import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { PlatformService, ScreenService } from '@core/services';
import { WebsocketService } from '@core/websocket';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { getMe } from '@store/reducers/profile.reducer';
import { addDays, addMonths, isAfter, startOfDay } from 'date-fns';
import { SingleEvent, IEntityUpdate } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { fromEvent, Observable } from 'rxjs';
import { debounceTime, filter, map, takeUntil } from 'rxjs/operators';
import { ScheduleEventVisualState } from '../models';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

export interface IShiftDais {
  shift: number;
  date: Date;
}

@Component({
  selector: 'app-scroll-selector',
  templateUrl: './scroll-selector.component.html',
  styleUrls: ['./scroll-selector.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ScrollSelectorComponent extends ChangableComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() events: SingleEvent[];
  @Input() set viewDate(viewDate: Date) {
    const findedIndex = this.mobileDays.findIndex((item) => isAfter(item, viewDate));
    this.activeDayIndex = findedIndex < 0 ? this.activeDayIndex : findedIndex - 1;
    this.scrollDayByIndex();
  }
  @Output() shiftDais = new EventEmitter<IShiftDais>();

  public mobileDays: Date[] = [];
  public activeDayIndex: number;

  @ViewChild('scroll') scroll: ElementRef;

  private meId: string;
  private scrollStep = 54;
  private scroll$: Observable<Event>;
  private screenWidth: number;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private screenService: ScreenService,
    private store: Store,
    public deviceService: DeviceDetectorService,
    private websocketService: WebsocketService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit(): void {
    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (width) => {
        this.screenWidth = width;
        this.markForCheck();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.screenWidth = window.innerWidth;
      }
    });

    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (me) => {
          this.meId = me?.id;
          this.markForCheck();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });

    let startMockDay = startOfDay(addMonths(new Date(), -3));
    const endMockDay = startOfDay(addMonths(new Date(), 3));
    while (startMockDay < endMockDay) {
      this.mobileDays.push(startMockDay);
      startMockDay = addDays(startMockDay, 1);
    }
  }

  ngAfterViewInit() {
    this.onBrowserOnly(() => {
      if (this.deviceService.isMobile() && this.scroll) {
        this.scroll$ = fromEvent<Event>(this.scroll.nativeElement, 'scroll').pipe(
          map((event) => event),
          debounceTime(200),
        );

        this.scroll$.pipe(takeUntil(this.destroyed$)).subscribe({
          next: (scroll) => this.scrollDays(scroll),
          error: (error) => {
            this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
          },
        });

        this.scrollDaysInit();
        this.initScheduleRefresh();
      }
    });
  }

  ngOnDestroy() {
    super.ngOnDestroy();
  }

  public onSelectDay(i) {
    this.scrollDayByIndex();
    this.shiftDais.emit({ shift: i - this.activeDayIndex, date: this.mobileDays[i] });
  }

  public scrollDayByIndex() {
    const clientWidth = this.scroll?.nativeElement?.clientWidth;
    const newScrollLeft = (this.activeDayIndex - Math.floor(clientWidth / this.scrollStep / 2)) * this.scrollStep;
    this.setTimeout(() => {
      this.scroll.nativeElement.scrollLeft = newScrollLeft;
    }, 0);
  }

  public get activeDay(): Date {
    return this.mobileDays && this.activeDayIndex ? this.mobileDays[this.activeDayIndex] : new Date();
  }

  public dayStates() {
    return {
      ongoing: false,
      unconfirmed: false,
      upcoming: false,
      passed: false,
      canceled: false,
      vacation: false,
    };
  }

  protected initScheduleRefresh() {}

  protected getHourState(events: SingleEvent[]): ScheduleEventVisualState {
    switch ((events || []).length) {
      case 0:
        return null;

      case 1:
        const event = events[0];
        const isPassed = event.begin_at.getTime() < Date.now();
        let stateClass: ScheduleEventVisualState;
        if (event && event.details) {
          stateClass = event.details.stateClass || 'off';

          if (stateClass === 'free' && event.meeting_id) {
            // патч на нечанно прошедшие, но не отменённые митинги
            stateClass = 'canceled';
          }
        } else {
          stateClass = isPassed ? 'passed' : 'off';
        }
        return stateClass;

      default: // > 1
        return 'unconfirmed';
    }
  }

  private correctionScroll(): number {
    const res = ((this.screenWidth - this.scrollStep) / 2) % this.scrollStep;
    return res > this.scrollStep / 2 ? res - this.scrollStep : res;
  }

  private scrollDaysInit() {
    const clientWidth = this.scroll.nativeElement.clientWidth;
    const findedIndex = this.mobileDays.findIndex((item) => isAfter(item, this.activeDay));
    this.activeDayIndex = findedIndex < 0 ? this.activeDayIndex : findedIndex - 1;
    const newScrollLeft =
      (this.activeDayIndex - Math.floor(clientWidth / this.scrollStep / 2)) * this.scrollStep - this.correctionScroll();
    this.setTimeout(() => {
      this.scroll.nativeElement.scrollLeft = newScrollLeft;
    }, 0);
  }

  private scrollDays(e) {
    const clientWidth = e.target.clientWidth;
    const currentScroll = e.target.scrollLeft;
    const lastActiveDay = this.activeDay;
    const prevActiveDayIndex = this.activeDayIndex;
    this.activeDayIndex = Math.floor(currentScroll / this.scrollStep + clientWidth / this.scrollStep / 2);
    if (lastActiveDay !== this.activeDay) {
      this.shiftDais.emit({
        shift: this.activeDayIndex - prevActiveDayIndex,
        date: this.mobileDays[this.activeDayIndex],
      });
    }
    // новое кратное шагу положение скролла
    const newScrollLeft =
      (this.activeDayIndex - Math.floor(clientWidth / this.scrollStep / 2)) * this.scrollStep - this.correctionScroll();
    e.target.scrollLeft = newScrollLeft; // автоматически доворачивает скролл до дискретного значения
    this.markForCheck();
  }

  public get statesClass() {
    return {
      // идущие прямо сейчас
      ongoing: this.getHourState(this.events) === 'ongoing',

      // неподтвержденные
      unconfirmed: this.getHourState(this.events) === 'unconfirmed',

      // предстоящие
      upcoming: this.getHourState(this.events) === 'upcoming',

      // прошедшие
      passed: this.getHourState(this.events) === 'passed',

      // отмененные
      canceled: this.getHourState(this.events) === 'canceled',

      // отпуск
      vacation: this.getHourState(this.events) === 'vacation',
    };
  }

  public trackByValue(value) {
    return value;
  }
}
