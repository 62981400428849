<section class="promo-footer">

  <div class="promo-footer-container">

    <div class="promo-footer-data">

      <div class="promo-footer-title">
        {{'job-landing.promofooter.title' | translate}}
      </div>
      <div class="promo-footer-text">
        {{'job-landing.promofooter.text' | translate}}
      </div>

      <div class="promo-footer-action-btn">
        <div class="action-btn with-arrow text-center" (click)="onBecomeTeacher(mainRoute, true)">
          {{'job-landing.promofooter.actionbtn' | translate}}
        </div>
      </div>
    </div>
    <div class="promo-footer-bg">
      <img src="/assets/images/landings/job/promo-footer-people.png" alt=""/>
    </div>
  </div>
</section>
