<section class="school-participants-widgets">
  <div class="title-wrap">
    <h3 class="title">{{ 'school-participants-widget.header' | translate}}</h3>
    <span class="count-owners">{{ users.items?.length }}</span>
  </div>

  <div class="participants-wrap">
    <div *ngFor="let student of users.items; trackBy: schoolParticipantTrackBy" class="participant">
      <div class="avatar">
        <on-ui-cover [cover]="cover(student)"></on-ui-cover>
      </div>
    </div>
  </div>

  <div class="actions-wrap">
    <span class="action-label">{{ 'school-participants-widget.text' | translate}}</span>
    <a [href]="schoolUrl" target="_blank" rel="noopener noreferrer" class="school-link">{{ schoolUrl }}</a>
    <div [ngxClipboard]
         [cbContent]="schoolUrl"
         (cbOnSuccess)="onCopyLink()">
      <on-ui-button [class]="copied ? 'secondary outline' : 'primary outline'"
                    state="default"
                    [label]="(copied ? 'school-participants-widget.action-done' : 'school-participants-widget.action')">
      </on-ui-button>
    </div>
  </div>
</section>
