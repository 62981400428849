<section class="how-study">
  <div class="container">
    <div class="title">
      {{'landings.home.how-study-title' | translate}}
    </div>
    <div class="advantages-wrap">
      <div class="content">
        <ul>
          <li>{{'landings.home.how-study-list1' | translate}}</li>
          <li>{{'landings.home.how-study-list2' | translate}}</li>
          <li>{{'landings.home.how-study-list3' | translate}}</li>
          <li>{{'landings.home.how-study-list4' | translate}}</li>
        </ul>
      </div>
      <div class="image"></div>
    </div>
  </div>
  <div class="container-slider">
    <div class="fake-slider-wrap">
      <div class="fake-slider-item slide{{i + 1}}"
           [style]="{'background-color': item.backgroundColor}"
           *ngFor="let item of sliderItemList; let i = index; trackBy: trackByFn">
        <div class="item-wrap">
          <div class="text" [innerHTML]="item.text | translate"></div>
        </div>
      </div>
    </div>
  </div>
</section>
