<div *ngIf="showForm" class="meeting-widget">
  <div class="close-btn" (click)="showForm=false">
    <app-icon width="10" height="10" icon="close-mobile-new"></app-icon>
  </div>
  <p class="label">{{ 'meeting-review-form.label-rating' | translate }}</p>
  <p class="subject">{{meetingSubject}}</p>
  <p class="title" (click)="navigateToMeeting()">{{review?.entryTitle}}</p>
  <div class="tutor-wrap">
    <span class="tutor-label">{{'dashboard.nearest-meet-widget.tutor' | translate}}:</span>&nbsp;
    <a class="tutor-name" target="_blank" [routerLink]="accountRoute">{{authorName}}</a>
  </div>
  <div class="rating-wrap">
    <div class="rating-stars">
      <app-icon *ngFor="let rating of [1, 2, 3, 4, 5]; trackBy: trackByFn"
           class="rating-stars-icon"
           [class.active]="rating <= review?.rating"
           width="22" height="22" icon="rating"
           (click)="setRating(rating)">
      </app-icon>
    </div>
  </div>
  <p *ngIf="!review?.rating" class="link-action" (click)="openComplainModal()">{{'meeting-review-form.complain' | translate}}</p>
  <div *ngIf="review?.rating>0" [@expandableState]>
    <div class="comment-wrap">
      <on-ui-textarea [placeholder]="'meeting-review-form.comment-placeholder' | translate"
                      [(ngModel)]="review.comment"></on-ui-textarea>
    </div>

    <p class="link-action footer-action" (click)="openComplainModal()">{{'meeting-review-form.complain' | translate}}</p>

    <on-ui-button (click)="submit()"
                  [state]="submitState"
                  [label]="'meeting-review-form.post-review'"
                  [class]="'primary'">
    </on-ui-button>
  </div>
</div>

<ng-template [ngIf]="complaintFormOpened">
  <app-modal *ngIf="deviceService.isDesktop(); else mobileComplainForm"
             (closed)="closeComplaintForm()">
    <app-modal-body>
      <app-complaint-meeting-form [meeting]="meeting" [author]="meeting?.author"></app-complaint-meeting-form>
    </app-modal-body>
  </app-modal>

  <ng-template #mobileComplainForm>
    <app-mobile-modal (closed)="closeComplaintForm()">
      <app-mobile-modal-title>{{ 'lingo2-forms.meetings.form-title' | translate }}</app-mobile-modal-title>
      <app-mobile-modal-body>
        <app-complaint-meeting-form [meeting]="meeting" [author]="meeting.author"></app-complaint-meeting-form>
      </app-mobile-modal-body>
    </app-mobile-modal>
  </ng-template>
</ng-template>
