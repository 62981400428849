import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ReactiveFormsModule, UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { MyClassesTutorsModule } from '@app/library/panes/my-classes-tutors/my-classes-tutors.component';
import { IControlOption, PaginatorModule } from '@app/shared';
import { NoContentStubComponent } from '@core/components/no-content-stub/no-content-stub.component';
import { AccountService, ConfigService, LanguageService, VocabularyService } from '@core/services';
import { IContentBlock } from '@core/services/interfaces';
import { ChangableComponent } from '@models/changable.component';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { IconComponent } from '@shared/icon/icon.component';
import {
  FindAccountStrategyEnum,
  IContentFilterBlock,
  IFindAccountsFilter,
  IFindVocabularyList,
  IFindVocabularyTerm,
  Subject as BaseSubject,
  Subject,
  User,
  VocabularyList,
  VocabularyTerm,
} from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiCheckboxModule } from 'onclass-ui';
import { combineLatest } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { ActionTypes, ListCardMiniComponent } from '../../pages/lists/card-mini/card-mini.component';
import { SharedModule } from '../../shared.module';
import { VocabularyContentComponent } from '../vocabulary-content/vocabulary-content.component';
import { VocabularySurfaceComponent } from '../vocabulary-surface/vocabulary-surface.component';

export type pageTypes = 'main' | 'list' | 'learned' | 'student';

@Component({
  selector: 'app-vocabulary-card',
  standalone: true,
  imports: [
    SharedModule,
    ReactiveFormsModule,
    VocabularyContentComponent,
    VocabularySurfaceComponent,
    ListCardMiniComponent,
    NoContentStubComponent,
    PaginatorModule,
    MyClassesTutorsModule,
    OnUiCheckboxModule,
    IconComponent,
  ],
  templateUrl: './vocabulary-card.component.html',
  styleUrls: [
    './vocabulary-card.component.scss',
    '../../../account/profile/user-profile-page/user-profile-nav/user-profile-nav.component.scss',
  ],
})
export class VocabularyCardComponent extends ChangableComponent implements OnInit {
  @Input() page: pageTypes;

  @Input()
  public set vocabulary(data: Partial<VocabularyTerm>) {
    if (data) {
      this.form.patchValue(data);
      this.terms.pagination.page = 1;
      this.loadVocabulary();
    }
  }

  @Input() list: VocabularyList;
  @Input() student: User;
  @Output() onSelect = new EventEmitter<string[]>();

  public lists: IContentBlock<VocabularyList, IFindVocabularyList> = {
    loading: false,
    loaded: false,
    items: [],
    filter: {},
    pagination: {
      page: 1,
      total: 0,
      totalPages: 0,
      pageSize: 100,
    },
  };

  public users: IContentBlock<User, IFindAccountsFilter> = {
    items: [],
    loading: false,
    loaded: false,
    filter: { use: FindAccountStrategyEnum.my_students },
    pagination: {
      page: 1,
      pageSize: 100,
      total: 0,
      totalPages: 0,
    },
  };

  public langOptions: IControlOption[];
  public languagesList: Subject[];
  public loading: boolean;

  public allSelected = false;
  public showSurface: number;
  public isFocus: boolean;
  public listsModalOpen: boolean;
  public selectedItemsId: VocabularyTerm[];
  public selectedUsersId: string[] = [];
  public form: UntypedFormGroup;
  public items: VocabularyTerm[];
  public selectedList: VocabularyList;
  public terms: IContentBlock<VocabularyTerm, IFindVocabularyTerm> = {
    loading: false,
    loaded: false,
    items: [],
    filter: {},
    pagination: {
      page: 1,
      total: 0,
      totalPages: 0,
      pageSize: 10,
    },
  };

  public menuForMoving: IContentFilterBlock[] = [
    {
      order: 1,
      id: 1,
      title: 'my-vocabulary.to-your-list',
      code: 'list',
      type: 'subject',
      item: new BaseSubject(),
      total_count: 0,
    },
    {
      order: 2,
      id: 2,
      title: 'my-vocabulary.to-student-dictionary',
      code: 'students-list',
      type: 'subject',
      item: new BaseSubject(),
      total_count: 0,
    },
  ];

  public activeTab = 'list';

  constructor(
    public errorNotificationService: ErrorNotificationService,
    public configService: ConfigService,
    public languageService: LanguageService,
    public vocabularyService: VocabularyService,
    public accountService: AccountService,
    protected fb: UntypedFormBuilder,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
    this.form = this.fb.group({
      search_term: [''],
      term_language_id: [1, [Validators.required]],
      definition_language_id: [2, [Validators.required]],
    });
  }

  public ngOnInit() {
    this.configService.subjectsV2$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (subjectsRegistry) => {
        this.languagesList = subjectsRegistry.foreign;
        this.langOptions = this.languagesList.map(({ title, code, language_id }) => ({
          title,
          value: language_id,
        }));
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.detectChanges();
      },
    });
    this.onSearch();
    this.loadVocabulary();
  }

  public onSearch() {
    this.form
      .get('search_term')
      .valueChanges.pipe(debounceTime(1000), takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.terms.filter.term = [res];
          this.loadVocabulary();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.detectChanges();
        },
      });
  }

  public showLoader(e: boolean) {
    this.loading = e;
  }

  public loadPage(e: number) {
    this.terms.pagination.page = e;
    this.loadVocabulary();
  }

  public loadVocabulary(): void {
    this.terms.filter = {
      ...this.terms.filter,
      term_language_id: this.form.get('term_language_id').value,
      definition_language_id: this.form.get('definition_language_id').value,
    };

    if (this.list) {
      this.terms.filter.vocabulary_list_id = [this.list.id];
    }

    if (this.student) {
      this.terms.filter.user_id = [this.student.id];
    }

    this.vocabularyService
      .findVocabularyTerms(this.terms.filter, this.terms.pagination)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (result) => {
          this.terms.items = result.results;
          this.terms.pagination = result.pagination;
          this.terms.loaded = true;
          this.vocabularyService.term_language_id = this.form.get('term_language_id')?.value;
          this.vocabularyService.definition_language_id = this.form.get('definition_language_id')?.value;
          this.vocabularyService.term_language_name = this.languagesList.find(
            (el) => el.language_id === this.vocabularyService.term_language_id,
          )?.title;
          this.vocabularyService.definition_language_name = this.languagesList.find(
            (el) => el.language_id === this.vocabularyService.definition_language_id,
          )?.title;
          this.vocabularyService.currentVocabulary.next({
            term_language_id: this.form.get('term_language_id')?.value,
            definition_language_id: this.form.get('definition_language_id')?.value,
          });
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.detectChanges();
        },
      });
  }

  public clearSearchForm() {
    this.form.get('search_term').setValue('');
    this.loadVocabulary();
  }

  public switchLanguages() {
    const term_language_id = this.form.get('term_language_id').value;
    this.form.get('term_language_id').setValue(this.form.value.definition_language_id);
    this.form.get('definition_language_id').setValue(term_language_id);
    this.terms.pagination.page = 1;
    this.loadVocabulary();
  }

  public onItemsSelect(e) {
    this.selectedItemsId = e;
    if (this.page === 'main') {
      this.showSurface = e.length;
      if (!e.length) {
        this.allSelected = false;
      }
    } else if (this.page === 'list') {
      this.onSelect.emit(e);
    }
  }

  public onAction(action: ActionTypes) {
    switch (action) {
      case 'move':
        this.onListsOpen();
        this.allSelected = false;
        break;
      case 'delete':
        this.onBulkDelete();
        this.allSelected = false;
        break;
      case 'select-all':
        this.allSelected = !this.allSelected;
        break;
    }
  }

  public onListSelect(item: VocabularyList) {
    this.selectedList = item;
  }

  public afterMove() {
    this.listsModalOpen = false;
    this.selectedList = null;
    this.selectedItemsId = [];
    this.showSurface = 0;
  }

  public moveTermsToList() {
    const arrayOfTerms = this.selectedItemsId.map((item) => ({
      id: item.id,
      vocabulary_list_id: this.selectedList.id,
    }));

    this.vocabularyService
      .bulkUpdateVocabularyTerms(arrayOfTerms)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.afterMove();
          this.loadVocabulary();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.detectChanges();
        },
      });
  }

  public moveTermsToStudentList() {
    const arrayOfTerms = this.selectedItemsId.map((o) => ({
      term: o.term,
      definition: o.definition,
      definition_voice_id: o.definition_voice_id,
      image_id: o.image_id,
    }));

    this.selectedUsersId.forEach((el, idx, array) => {
      arrayOfTerms.forEach((term) => {
        this.vocabularyService
          .createVocabularyTerm({
            ...term,
            term_language_id: this.vocabularyService.term_language_id,
            definition_language_id: this.vocabularyService.definition_language_id,
            participant_ids: [el],
          })
          .pipe(takeUntil(this.destroyed$))
          .subscribe({
            next: () => {
              if (idx === array.length - 1) {
                this.afterMove();
                this.loadVocabulary();
              }
            },
            error: (error) => {
              this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
              this.detectChanges();
            },
          });
      });
    });
  }

  public onListsOpen() {
    this.vocabularyService
      .findVocabularyLists({}, this.lists.pagination)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.lists.items = res.results;
          this.listsModalOpen = true;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.detectChanges();
        },
      });

    this.accountService
      .findAccounts(this.users.filter, this.users.pagination)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (res) => {
          this.users.items = res.results;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.detectChanges();
        },
      });
  }

  public onBulkDelete() {
    this.vocabularyService
      .bulkDeleteVocabularyTerm(this.selectedItemsId.map((el) => el.id))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.afterMove();
          this.loadVocabulary();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.detectChanges();
        },
      });
  }

  public onFocus() {
    this.isFocus = !this.isFocus;
  }

  public get showTools(): boolean {
    return this.page === 'main' || this.page === 'student';
  }

  public onClose() {
    this.listsModalOpen = false;
    this.selectedUsersId = [];
  }

  public setActiveTab(page: string): string {
    this.activeTab = page;
    return page;
  }

  public selectUsers(id: string) {
    if (this.selectedUsersId.indexOf(id) === -1) {
      this.selectedUsersId.push(id);
    } else {
      this.selectedUsersId = this.selectedUsersId.filter((el) => el !== id);
    }
  }

  public selectAll(checked: boolean) {
    if (checked) {
      this.selectedUsersId = this.users.items.map((el) => el.id);
    } else {
      this.selectedUsersId = [];
    }
  }

  public trackByFn(index) {
    return index;
  }
}
