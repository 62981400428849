<div class="status-block" [class.mobile]="deviceService.isMobile()">
  <div class="cover-wrap">
    <on-ui-cover [cover]="cover"></on-ui-cover>
    <app-icon
      class="status-icon"
      [width]="30"
      [height]="30"
      file="status"
      [icon]="statusInfo(status).icon">
    </app-icon>
  </div>
  <div class="content">
    <div class="status-info">
      <p>{{title}}</p>
      <span>{{ 'notifications.school-status-changed.status.school-status' | translate }}:</span>
      <span class="status-widget" [ngClass]="'status_' + status">
      {{ statusInfo(status).text | translate }}
    </span>
    </div>
    <div class="actions">
      <div class="badges">
        <div class="badge badge_light" [class.badge_active]="isActive('verification')">
          <app-icon class="badge__icon" width="21" height="21" icon="badge-verification"></app-icon>
        </div>

        <div class="badge badge_light" [class.badge_active]="isActive('tutors')">
          <app-icon class="badge__icon" width="29" height="17" icon="badge-services"></app-icon>
        </div>

        <div class="badge badge_light" [class.badge_active]="isActive('services')">
          <app-icon class="badge__icon" width="22" height="22" icon="badge-tutors"></app-icon>
        </div>

        <div class="badge badge_light" [class.badge_active]="isActive('bank_card')">
          <app-icon class="badge__icon" width="20" height="16" icon="badge-bank_card"></app-icon>
        </div>
      </div>
      <p (click)="onPublish()" *ngIf="canSendToModeration" class="publish-link">{{ 'notifications.school-status-changed.status.send-for-moderation' | translate}}</p>
    </div>
  </div>
</div>
