import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { CategoriesItemComponent } from '@app/library/panes/categories-content-filter/categories-item/categories-item.component';
import { ScheduleRoutingModule } from '@app/schedule-page/schedule-routing.module';
import { MainHeaderModule } from '@core/components/main-header/main-header.module';
import { NearestMeetWidgetModule, OnboardingWidgetModule } from '@core/widgets';
import { TranslateModule } from '@ngx-translate/core';
import { ScheduleNavComponent } from './schedule-nav/schedule-nav.component';
import { SchedulePageComponent } from './schedule-page.component';

@NgModule({
  declarations: [SchedulePageComponent, ScheduleNavComponent],
  imports: [
    CommonModule,
    RouterModule,
    ScheduleRoutingModule,
    MainHeaderModule,
    CategoriesItemComponent,
    TranslateModule,
    NearestMeetWidgetModule,
    OnboardingWidgetModule,
  ],
})
export class SchedulePageModule {}
