<div class="app app-main" [ngClass]="pageStyle"
     [class.mobile-app]="deviceService.isMobile()">
  <ng-container #app_header></ng-container>

  <main class="app-body">
    <div class="spacer"></div>
    <app-school-settings-left-sidebar (setActiveMenu)="setActiveMenu($event)"></app-school-settings-left-sidebar>
    <div class="content school-settings">
      <div class="header">
        <a [routerLink]="routeLinkSchool" class="back-action">
          <app-icon width="10" height="14" file="chevron" icon="left"></app-icon>
        </a>
        <div class="header-icon">
          <div class="icon-wrap">
            <app-icon width="24" height="24" icon="gear"></app-icon>
          </div>
          <span class="label normal">{{'school-management.settings'| translate}}</span>
        </div>
      </div>
      <div class="step-content" [ngClass]="step">
        <ng-template [ngIf]="isOwnerSchool">

          <ng-container [ngSwitch]="step">

            <ng-container *ngSwitchCase="steps.main">
              <app-school-form [fromSettings]="true" [school]="school" [modalDisplayMode]="true" (changed)="save()"></app-school-form>
            </ng-container>

            <ng-container *ngSwitchCase="steps.owners">
              <app-school-participants [schoolParticipantsFilter]="'owners'"></app-school-participants>
            </ng-container>

            <ng-container *ngSwitchCase="steps.setTeacher">
              <app-school-participants [schoolParticipantsFilter]="'teachers'"></app-school-participants>
            </ng-container>

            <ng-container *ngSwitchCase="steps.classes">
              <app-school-settings-classes [school]="school" [me]="me"></app-school-settings-classes>
            </ng-container>

            <ng-container *ngSwitchCase="steps.card">
              <app-school-settings-card [school]="school"></app-school-settings-card>
            </ng-container>

            <ng-container *ngSwitchCase="steps.accounts">
              <app-school-settings-accounts [school]="school"></app-school-settings-accounts>
            </ng-container>

            <ng-container *ngSwitchCase="steps.schedule">
              <app-school-schedule></app-school-schedule>
            </ng-container>

            <ng-container *ngSwitchCase="steps.calculations">
              <app-school-calculations></app-school-calculations>
            </ng-container>

            <ng-container *ngSwitchCase="steps.participants">
              <app-school-participants></app-school-participants>
            </ng-container>

            <ng-container *ngSwitchCase="steps.status">
              <app-school-settings-status [school]="school"></app-school-settings-status>
            </ng-container>

          </ng-container>

        </ng-template>
      </div>
    </div>
    <app-school-settings-right-sidebar *ngIf="deviceService.isDesktop()"></app-school-settings-right-sidebar>
    <div class="spacer"></div>
  </main>

  <ng-template [ngIf]="isAvailableChat && isChatVisible">
    <div *ngIf="deviceService.isDesktop()" class="chat-action" [class.disabled]="!iframe">
      <app-button-fab
        [icon]="'chat-bubble'"
        [counter]="unreadCount"
        [isToggled]="false"
        (click)="openChatWindow()">
      </app-button-fab>
    </div>
    <div class="chat-wrap"
         [class.display]="isChatVisible && alternativeChatState"
         (mouseenter)="onMouseenter()"
         (mouseleave)="onMouseleave()">

         <iframe [src]="safeChatUrl"
         width="100%" sandbox="allow-same-origin allow-scripts allow-forms"
         allow="accelerometer; encrypted-media; gyroscope; picture-in-picture"
         allowfullscreen
         class="chat" id="chatFrame">
         </iframe>
    </div>
  </ng-template>

  <ng-container *ngIf="fileFromChat">
    <app-modal *ngIf="!deviceService.isMobile()" [thin]="true" [canClose]="true" (closed)="closeFileFromChat()">
      <app-modal-body>
        <img [src]="fileFromChat" class="chat-image" />
      </app-modal-body>
    </app-modal>

    <app-mobile-modal *ngIf="deviceService.isMobile()" (closed)="closeFileFromChat()">
      <app-mobile-modal-body>
        <img [src]="fileFromChat" class="chat-image" />
      </app-mobile-modal-body>
    </app-mobile-modal>
  </ng-container>

</div>
