import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';
import { FormFieldModule } from '@shared/form-controls';
import { IconComponent } from '@shared/icon/icon.component';
import {
  OnUiButtonModule,
  OnUiCheckboxModule,
  OnUiFormFieldErrorModule,
  OnUiInputModule,
  OnUiSelectModule,
} from 'onclass-ui';
import { ExperienceFormPageComponent } from './experience-form-page.component';

@NgModule({
  declarations: [ExperienceFormPageComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    RouterModule,
    IconComponent,
    OnUiInputModule,
    FormFieldModule,
    OnUiFormFieldErrorModule,
    OnUiInputModule,
    OnUiCheckboxModule,
    OnUiButtonModule,
    OnUiSelectModule,
  ],
  exports: [ExperienceFormPageComponent],
})
export class ExperienceFormPageModule {}
