import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  ValidationErrors,
  Validators,
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { logger } from '@app/core/helpers/logger';
import { CanComponentDeactivate } from '@core/guards/can-deactivate.guard';
import { AccountService, AuthService, ContextService, PlatformService, ProfileService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { IControlOption } from '@shared/form-controls/form-controls.models';
import { setProfile } from '@store/actions/profile.actions';
import { IWorkExperience } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonState } from 'onclass-ui';
import { Observable, Subject } from 'rxjs';
import { debounceTime, filter, map, take, takeUntil } from 'rxjs/operators';
import { ProfileFormService } from '../_services/profile-form.service';
import { forceLoadAccountCheck } from '@app/store/actions/data.actions';

export interface IExperienceForm {
  work_experience: IWorkExperience[];
}

export const isExperienceCompleted = (value: IExperienceForm): boolean =>
  Object.keys(value).every((key) => !!value[key]) &&
  value.work_experience.length &&
  value.work_experience.every((x) => x.organization && x.position && x.begin_year);

const untilNow: IControlOption = { title: 'profile-form.experience.until-now', value: '3000' };
const isEmptyString = (value: string): boolean => !value.toString().trim().length;

@Component({
  selector: 'app-experience-form-page',
  templateUrl: './experience-form-page.component.html',
  styleUrls: ['./experience-form-page.component.scss'],
})
export class ExperienceFormPageComponent extends ChangableComponent implements OnInit, CanComponentDeactivate {
  @Output() checkSteps = new EventEmitter<boolean>();

  public form: UntypedFormGroup;
  public value: IExperienceForm;
  public yearsOptionsFrom: IControlOption[];
  public yearsOptionsTo: IControlOption[];
  public saveState: OnUiButtonState = 'default';

  private required = false;
  private validateFormGroup = ProfileFormService.validateFormGroup;

  constructor(
    public errorNotificationService: ErrorNotificationService,
    protected fb: UntypedFormBuilder,
    protected profileService: ProfileService,
    protected contextService: ContextService,
    protected accountService: AccountService,
    protected authService: AuthService,
    private route: ActivatedRoute,
    private router: Router,
    private store: Store,
    private profileFormService: ProfileFormService,
    public deviceService: DeviceDetectorService,
    protected readonly cdr: ChangeDetectorRef,
    protected readonly platform: PlatformService,
  ) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.profileFormService.profileForm$
      .pipe(
        map((x) => x.experience),
        filter((x) => !!x),
        take(1),
        takeUntil(this.destroyed$),
      )
      .subscribe((res) => (this.value = res));

    // Map data and set options
    const currentYear = new Date().getFullYear();
    this.yearsOptionsFrom = Array(60)
      .fill(0)
      .map((x, i) => ({ title: (currentYear - i).toString(), value: currentYear - i })); // [2020, 2019, 2018, 2017, ...]
    this.yearsOptionsTo = [untilNow, ...this.yearsOptionsFrom];
    // Init form and set form values
    this.form = this.fb.group({
      noExperience: [this.hasNotWorkingExperience],
      work_experience: this.fb.array(this.workExperienceArray(this.value.work_experience || [])),
    });

    this.form.patchValue({
      work_experience: this.value.work_experience || [],
    });

    this.profileFormService.verificationRequested$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (requested) => {
        this.required = requested;
        this.validateFormGroup(this.form);
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    this.autoSave();
  }

  private autoSave(): void {
    this.form.valueChanges
      .pipe(debounceTime(300))
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => this.saveForm(),
        error: (error) => {
          this.errorNotificationService.captureError(error, 'SAVE-PROBLEM');
        },
      });
  }

  public get hasNotWorkingExperience() {
    return this.value?.work_experience === null;
  }

  public experienceFrom($event: number) {
    this.yearsOptionsTo = this.yearsOptionsTo.filter((item: IControlOption) => item.value >= $event);
  }

  public experienceTo($event: number) {
    this.yearsOptionsFrom = this.yearsOptionsFrom.filter((item: IControlOption) => item.value <= $event);
  }

  private workExperienceArray(values: IWorkExperience[]): UntypedFormGroup[] {
    if (!values?.length) {
      return [this.workExperienceForm()];
    } else {
      return values.map((value) => this.workExperienceForm(value));
    }
  }

  private workExperienceForm(value?: IWorkExperience): UntypedFormGroup {
    const _value: Partial<IWorkExperience> = value || {};
    return this.fb.group({
      organization: [_value.organization || '', [Validators.maxLength(200), this.conditionalEmptyValidator.bind(this)]],
      position: [_value.position || '', [Validators.maxLength(200), this.conditionalEmptyValidator.bind(this)]],
      begin_year: [_value.begin_year || '', [this.conditionalEmptyValidator.bind(this)]],
      end_year: [_value.end_year || '', [this.conditionalEmptyValidator.bind(this)]],
    });
  }

  private conditionalEmptyValidator(control: UntypedFormControl): ValidationErrors | null {
    if (!this.required) {
      return null;
    }
    return isEmptyString(control.value) ? { required: { value: true } } : null;
  }

  public get workExperiences(): UntypedFormArray {
    return this.form.get('work_experience') as UntypedFormArray;
  }

  public removeWorkExperience(index: number) {
    this.workExperiences.removeAt(index);
    this.form.markAsDirty();
  }

  public addWorkExperience() {
    this.workExperiences.push(this.workExperienceForm());
  }

  public saveForm(): void {
    if (
      (this.form.invalid && !this.form.controls.noExperience.value) ||
      !this.form.dirty ||
      this.saveState === 'progress'
    ) {
      return;
    }

    this.saveState = 'progress';

    this.save(this.form.value).then(
      () => {
        this.saveState = 'done';
        this.resetState();
      },
      () => {
        this.saveState = 'fail';
        this.resetState();
      },
    );
  }

  public resetState() {
    this.setTimeout(() => {
      this.saveState = 'default';
      this.detectChanges();
    }, 1000);
  }

  public save(values: IExperienceForm): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      let _work_experience = null;

      if (!this.form.controls.noExperience.value) {
        const { work_experience } = values;
        _work_experience = (work_experience || []).filter((v) => v.organization.length > 0);
      }

      this.profileService
        .updateProfile({ work_experience: _work_experience })
        .pipe(takeUntil(this.destroyed$))
        .subscribe({
          next: (profile) => {
            this.contextService.patchProfile({
              work_experience: profile.work_experience,
              verification_status: profile.verification_status,
            });

            this.profileFormService.setProfileForm({ experience: values });
            this.store.dispatch(setProfile({ profile }));
            this.store.dispatch(forceLoadAccountCheck());
            resolve(true);
          },
          error: (error: any) => {
            reject(error);
          },
        });
    });
  }

  public isInvalid(formGroup: UntypedFormGroup, formControlName: string): boolean {
    return formGroup.get(formControlName)?.invalid;
  }

  public navigateBack(): void {
    this.forceSave();
    this.setTimeout(() => {
      this.router.navigate(['/profile/education']);
    }, 1500);
  }

  private forceSave() {
    this.saveForm();
  }

  public navigateToNextStep(): void {
    this.forceSave();
    this.setTimeout(() => {
      this.checkSteps.emit(true);
    }, 1500);
  }

  public canDeactivate(): Observable<boolean> | boolean {
    if (this.form.valid && this.form.dirty) {
      this.saveState = 'progress';
      const deactivate = this.register(new Subject<boolean>());
      const deactivate$ = deactivate.asObservable();

      this.save(this.form.value).then(
        () => {
          this.saveState = 'done';
          deactivate.next(true);
        },
        () => {
          this.saveState = 'fail';
          deactivate.next(true);
        },
      );

      return deactivate$;
    }

    return true;
  }

  public trackByFn(index) {
    return index;
  }
}
