<div class="vocabulary-card" [ngClass]="page" [class.mobile]="deviceService.isMobile()">
  <div [formGroup]="form">
    <div *ngIf="showTools" class="tools">
      <div class="search-wrap" [class.on-focus]="isFocus">
        <app-icon class="search-content-icon" icon="search"></app-icon>
        <input type="text"
               class="search-input"
               formControlName="search_term"
               (focus)="onFocus()"
               (blur)="onFocus()"
               [placeholder]="'library.form-term-placeholder' | translate">
        <app-icon class="search-icon-close"
                  icon="close-search"
                  [class.visible]="!!form.get('search_term').value"
                  (click)="clearSearchForm()">
        </app-icon>
      </div>

      <div class="lang-wrap">
        <on-ui-select formControlName="term_language_id"
                      [options]="langOptions"
                      [autosize]="true"
                      (changed)="loadVocabulary()">
        </on-ui-select>
        <span>
          <app-icon (click)="switchLanguages()" class="switch-languages-icon" width="30" height="30" icon="switch-languages"></app-icon>
        </span>
        <on-ui-select formControlName="definition_language_id"
                      [options]="langOptions"
                      [autosize]="true"
                      (changed)="loadVocabulary()">
        </on-ui-select>
      </div>
    </div>

    <div *ngIf="page === 'list'" class="tools">
      <app-title [title]="'my-vocabulary.words' | translate"></app-title>
      <div class="lang-wrap">
        <on-ui-select formControlName="term_language_id"
                      [options]="langOptions"
                      [autosize]="true"
                      (changed)="loadVocabulary()">
        </on-ui-select>
        <span>
          <app-icon (click)="switchLanguages()" class="switch-languages-icon" width="30" height="30" icon="switch-languages"></app-icon>
        </span>
        <on-ui-select formControlName="definition_language_id"
                      [options]="langOptions"
                      [autosize]="true"
                      (changed)="loadVocabulary()">
        </on-ui-select>
      </div>
      <div></div>
    </div>
  </div>

  <ng-container *ngIf="terms.loaded">
  <app-vocabulary-content
    [terms]="terms.items"
    [vocabulary_list_id]="list?.id"
    [student_id]="student?.id"
    [isSearchTerm]="!!form.get('search_term').value"
    [page]="page"
    [allSelected]="allSelected"
    [selectedItemsId]="!!selectedItemsId.length"
    (showLoader)="showLoader($event)"
    (changed)="loadVocabulary()"
    (selected_items)="onItemsSelect($event)">
  </app-vocabulary-content>
  </ng-container>

  <div class="pagination-wrap">
    <app-paginator [pagination]="terms.pagination" (pageChanged)="loadPage($event)"></app-paginator>
  </div>

  <app-vocabulary-surface *ngIf="showSurface" [withFullActions]="true" [item]="list"
                          [count]="showSurface"
                          (action)="onAction($event)"></app-vocabulary-surface>

  <app-modal *ngIf="!deviceService.isMobile() && listsModalOpen"
             [canClose]="false"
             (closed)="onClose()">
    <app-modal-body>
      <ng-container *ngTemplateOutlet="listsModalRef"></ng-container>
    </app-modal-body>
  </app-modal>

  <app-mobile-modal *ngIf="deviceService.isMobile() && listsModalOpen"
             (closed)="onClose()">
    <app-mobile-modal-body>
      <ng-container *ngTemplateOutlet="listsModalRef"></ng-container>
    </app-mobile-modal-body>
  </app-mobile-modal>
</div>

<ng-template #listsModalRef>
  <div class="header">
    <app-title [title]="'my-vocabulary.move-words' | translate"></app-title>
  </div>

  <div class="tabs-wrap" [class.mobile]="deviceService.isMobile()">
    <ul class="tabs">
      <ng-template ngFor let-item [ngForOf]="menuForMoving">
        <li [attr.title]="item.title | translate" class="categories-item">
          <a class="tab" [class.active]="activeTab === item.code"
             itemprop="url"
             (click)="setActiveTab(item.code)">
            <span class="tab-name">{{ item.title | translate }}</span>
          </a>
        </li>
      </ng-template>
    </ul>
  </div>

  <ng-container *ngIf="activeTab==='list'">
    <div class="list-wrap" *ngIf="lists.items.length;else noItems">
      <app-vocabulary-list-card-mini
        *ngFor="let item of lists.items; trackBy: trackByFn"
        [withActions]="false"
        [selectedList]="selectedList"
        (click)="onListSelect(item)"
        [item]="item">
      </app-vocabulary-list-card-mini>
    </div>
    <div class="footer" *ngIf="lists.items.length">
      <on-ui-button class="primary"
                    state="default"
                    [disabled]="!selectedList"
                    (click)="moveTermsToList()"
                    [label]="'my-vocabulary.action.ready' | translate">
      </on-ui-button>
    </div>
    <ng-template #noItems>
      <app-no-content-stub
        [position]="'center'"
        [icon]="'stub-vocabulary'"
        [text]="'my-vocabulary.empty-list.title'"
        [text2]="'my-vocabulary.empty-list.info'">
      </app-no-content-stub>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="activeTab==='students-list'">
    <div class="users-wrap" *ngIf="users.items.length;else noUsers">
      <div *ngFor="let user of users.items; trackBy: trackByFn" class="user-item"
           [class.active]="selectedUsersId.indexOf(user.id)!==-1">
        <app-tutor-mini [user]="user" [hide_classes]="true" (clicked)="selectUsers(user.id)"></app-tutor-mini>
      </div>
    </div>
    <div class="footer footer-users" *ngIf="users.items.length">
      <on-ui-checkbox [class]="'small'" (changed)="selectAll($event)">
        <span class="select-all">{{'my-vocabulary.action.select-all-students' | translate}}</span>
      </on-ui-checkbox>
      <on-ui-button class="primary"
                    state="default"
                    [disabled]="!selectedUsersId.length"
                    (click)="moveTermsToStudentList()"
                    [label]="'my-vocabulary.action.ready' | translate">
      </on-ui-button>
    </div>
    <ng-template #noUsers>
      <app-no-content-stub
        [position]="'center'"
        [icon]="'stub-vocabulary'"
        [text]="'my-vocabulary.empty-students'">
      </app-no-content-stub>
    </ng-template>
  </ng-container>
</ng-template>
