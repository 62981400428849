import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { TranslateModule } from '@ngx-translate/core';

// модули
import { SpinnersModule } from '../../core/components/spinners/spinners.module';
import { PipesModule } from '../../core/pipes/pipes.module';

// страницы
import { ArticlePageComponent } from './article-page.component';

/**
 * Этот роут срабатывает после ленивой загрузки модуля по роутам
 *     /рrivacy
 *     /terms
 *     /about
 *     /offer
 *     /salesrefund
 *
 * @see AppRoutingModule
 */
const routes: Routes = [
  {
    // ! base-path = ''
    path: '',
    component: ArticlePageComponent,
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), TranslateModule, PipesModule, SpinnersModule],
  declarations: [ArticlePageComponent],
})
export class ArticleModule {}
