import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ActionTypes } from '@app/vocabulary/pages/lists/card-mini/card-mini.component';
import { FilesService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';
import { TranslateModule } from '@ngx-translate/core';
import { IconComponent } from '@shared/icon/icon.component';
import { VocabularyList } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { OnUiButtonModule, OnUiCover, OnUiCoverModule } from 'onclass-ui';

@Component({
  selector: 'app-vocabulary-surface',
  standalone: true,
  imports: [CommonModule, OnUiButtonModule, OnUiCoverModule, TranslateModule, IconComponent],
  templateUrl: './vocabulary-surface.component.html',
  styleUrls: ['./vocabulary-surface.component.scss'],
})
export class VocabularySurfaceComponent extends ChangableComponent implements OnInit {
  @Input() withFullActions: boolean;
  @Input() item: VocabularyList;
  @Input() public set count(data: number) {
    this._count = data;
  }

  public get count() {
    return this._count;
  }

  @Output() action = new EventEmitter<string>();

  private _count: number;
  public cover: OnUiCover;
  public allSelected = false;

  public constructor(protected readonly cdr: ChangeDetectorRef, public deviceService: DeviceDetectorService) {
    super(cdr);
  }

  ngOnInit(): void {
    this.cover = {
      img: FilesService.getFileUrlBySize(this.item?.image_id),
      form: 'base',
      type: 'cover',
    };
  }

  public onAction(action: ActionTypes) {
    if (action === 'select-all') {
      this.allSelected = !this.allSelected;
    }
    this.action.emit(action);
  }
}
