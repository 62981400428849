import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { PlatformService } from '@core/services';
import { ChangableComponent } from '@models/changable.component';

export interface SlidesList {
  title: string;
  text: string;
  class: string;
  link: string;
}

@Component({
  selector: 'app-who-can',
  templateUrl: './who-can.component.html',
  styleUrls: ['./who-can.component.scss'],
})
export class WhoCanComponent extends ChangableComponent implements OnInit, OnDestroy {
  public slidesList: SlidesList[];
  public selectedIndex = 0;

  constructor(protected readonly cdr: ChangeDetectorRef, protected readonly platform: PlatformService) {
    super(cdr, platform);
  }

  ngOnInit() {
    this.slidesList = [
      {
        title: 'landings.home.who-can-slidetitle1',
        text: 'landings.home.who-can-slidetext1',
        class: 'students',
        link: null,
      },
      {
        title: 'landings.home.who-can-slidetitle2',
        text: 'landings.home.who-can-slidetext2',
        class: 'tutors',
        link: '/job',
      },
      {
        title: 'landings.home.who-can-slidetitle3',
        text: 'landings.home.who-can-slidetext3',
        class: 'methodists',
        link: null,
      },
      {
        title: 'landings.home.who-can-slidetitle4',
        text: 'landings.home.who-can-slidetext4',
        class: 'schools',
        link: null,
      },
    ];
  }

  public onSelectItem(index: number) {
    this.selectedIndex = index;
    this.markForCheck();
  }

  public trackByFn(index) {
    return index;
  }
}
