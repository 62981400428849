<div class="vocabulary-content"
     [class.no-editable]="!editable"
     [class.no-selectable]="!selectable"
     [class.mobile]="deviceService.isMobile()">
  <div class="info">
    <app-vocabulary-form-added *ngIf="editable" [vocabulary_list_id]="vocabulary_list_id" [student_id]="student_id"
                               (added)="onAdd($event)"></app-vocabulary-form-added>

    <div class="form-holder" *ngIf="terms.length; else noItems">
      <div *ngFor="let item of terms; let idx = index; trackBy: trackByFn"
      class="info-item">
        <div class="info-left" [class.on-focus]="isFocusLeft && activeRow == idx">
          <div class="word-wrap">
            <div class="word-input left" [contentEditable]="true"
            (focusedChange)="onFocusLeft($event, idx)" (input)="terms$.next({event: $event, id: item.id})">
              {{item.term}}
            </div>
          </div>
          <label class="checkbox-wrap">
            <on-ui-checkbox [class]="'small'"
                            (changed)="mark(item, $event)"></on-ui-checkbox>
          </label>
        </div>

        <div class="info-right" [class.on-focus]="isFocusRight && activeRow === idx">
          <div class="word-wrap">
            <div class="word-input" [contentEditable]="true"
            (focusedChange)="onFocusRight($event, idx)" (input)="definition$.next({event: $event, id: item.id})">
              {{item.definition}}
            </div>
          </div>
          <app-icon *ngIf="!item?.definition_voice_id && !item?.image_id" class="loading-icon" icon="loading"></app-icon>

          <div class="audio-wrap" *ngIf="item?.definition_voice_id">
            <app-vocabulary-media-player [value]="item?.definition_voice_id"></app-vocabulary-media-player>
          </div>
          <div class="img-wrap">
            <div class="with-hover">
              <app-image-uploader [imageId]="item?.image_id" [display]="'small'" [autoCrop]="true"></app-image-uploader>
            </div>
            <div class="tooltip" *ngIf="item?.image_id && !deviceService.isMobile()">
              <img [src]="getImageUrl(item?.image_id)" alt=""/>
            </div>
          </div>
        </div>
    </div>

  </div>
  <ng-template #noItems>
    <ng-container *ngIf="!isSearchTerm">
      <app-no-content-stub
        [icon]="'stub-vocabulary'"
        [text]="('my-vocabulary.empty-vocabulary.title' | translate) + '. ' + ('my-vocabulary.empty-vocabulary.info' | translate)">
      </app-no-content-stub>
    </ng-container>
  </ng-template>
</div>
