<ng-template [ngIf]="!isSettings() && !isSchoolMain()">

  <div class="unit">
    <app-school-sidebar-widget></app-school-sidebar-widget>
  </div>

  <div class="unit" *ngIf="meIsOwnerSchool">
    <app-school-onboarding-widget [school]="school" [me]="me"></app-school-onboarding-widget>
  </div>

  <div class="unit">
    <app-school-owners-widgets [school]="school"></app-school-owners-widgets>
  </div>

  <div class="unit">
    <app-school-participants-widgets [school]="school"></app-school-participants-widgets>
  </div>
</ng-template>
