<div *ngIf="form && ready"
     [formGroup]="form"
     #formRef
     class="regular-schedule-form"
     [class.tablet]="deviceService.isTablet()"
     [class.mobile]="deviceService.isMobile()"
     [class.portrait]="isPortrait"
     [class.landscape]="!isPortrait">

  <div class="anchor" id="anchor-regular"></div>

  <div class="block2" [class.expand]="expandPanel" [class.isSeparate]="isSeparate">
    <div class="header">
      <div class="text-wrap">
        <span class="title">{{ 'regular-schedule-form.title' | translate }}</span>
        <span class="subtitle">{{ 'regular-schedule-form.subtitle' | translate }}</span>
      </div>
    </div>

    <div class="content">
      <h3 class="title-select">{{ 'regular-schedule-form.title-select' | translate }}</h3>

      <p class="text">{{ 'regular-schedule-form.text' | translate }}</p>

      <div class="timezone-wrap">
        <on-ui-select-search [placeholder]="'regular-schedule-form.timezone-placeholder' | translate"
                            formControlName="timezone"
                            data-test="timezone"
                            [options]="timezonesOptions"
                            [error]="!isValid('timezone')">
        </on-ui-select-search>
      </div>

      <div class="weekdays">
        <div formArrayName="weekdays">
          <div *ngFor="let control of weekdaysArray.controls; let idx = index; trackBy: trackByFn" [formGroupName]="idx" class="weekday">
            <label class="checkbox-wrap">
              <on-ui-checkbox formControlName="enabled" data-test="enabled">
                <span class="checkbox-label">
                  <ng-template [ngIf]="debug$ | async">{{ +control.get('weekday').value }}</ng-template>
                  {{ weekDayName(+control.get('weekday').value) }}
                </span>
              </on-ui-checkbox>
            </label>
            <div [hidden]="!control.get('enabled').value">
              <app-regular-schedule-day-form [control]="control"></app-regular-schedule-day-form>
            </div>
          </div>
        </div>
        <div class="hint-wrapper">
          <div class="hint">
            <div class="info-wrap">
              <div class="icon-wrap">
                <app-icon width="35" height="35" icon="calendar"></app-icon>
              </div>
            </div>
            <div class="action-wrap">
              <div class="text-wrap">
                <span class="hint-title">{{ 'regular-schedule-form.signup-time-limits-title' | translate }}</span>
              </div>
              <div class="action">
                <span class="name">{{ 'regular-schedule-form.signup-time-min-limit' | translate }}</span>
                <on-ui-select placeholder=""
                              data-test="signup-gap-hours"
                              formControlName="signup_gap_hours"
                              [options]="signupGapHoursOptions"
                              [error]="!isValid('signup_gap_hours')">
                </on-ui-select>
              </div>
              <div class="action">
                <span class="name">{{ 'regular-schedule-form.signup-time-max-limit' | translate }}</span>
                <on-ui-select placeholder=""
                              data-test="max-meeting-signup-days"
                              formControlName="max_meeting_signup_days"
                              [options]="maxMeetingSignupDaysOptions"
                              [error]="!isValid('max_meeting_signup_days')">
                </on-ui-select>
              </div>
            </div>
          </div>
          <div class="hint">
          <div class="icon-wrap">
            <app-icon width="35" height="35" icon="clock2"></app-icon>
          </div>
          <div class="text-wrap">
            <span class="hint-title">{{ 'regular-schedule-form.recommendation-title' | translate }}</span>
            <p class="hint-text" [innerHTML]="'regular-schedule-form.recommendation-text' | translate | striptags | nl2br"></p>
          </div>
        </div>
        </div>
      </div>

      <div class="actions-wrap">
        <on-ui-button *ngIf="!isSeparate" class="primary"
                      [state]="saveState"
                      [disabled]="form.pristine && !forceSave"
                      label="regular-schedule-form.save"
                      data-test="profile-save"
                      (onclick)="save()">
        </on-ui-button>
        <ng-container *ngIf="isSeparate">
          <on-ui-button [state]="'default'"
                        class="primary outline margin-right-20 back"
                        label="profile-form.back"
                        data-test="profile-back"
                        (click)="navigateBack()">
          </on-ui-button>
          <on-ui-button [state]="saveState"
                        class="primary next"
                        label="profile-form.next"
                        data-test="profile-save"
                        (click)="save()">
          </on-ui-button>
          <on-ui-button state="default"
                        *ngIf="!hasScheduleOrSkip"
                        class="transparent skip"
                        data-test="profile-skip"
                        label="profile-form.skip"
                        (click)="skipStep()">
          </on-ui-button>
        </ng-container>
      </div>

      <div *ngIf="debug$ | async">
        <pre>weekdays = {{ weekdays | json }}</pre>
        <pre>currentTimezone = {{ currentTimezone() }}</pre>
        <!--pre>timezonesOptions = {{ timezonesOptions | json }}</pre-->
        <pre style="height: 450px; overflow: auto;">{{ form.value | json }}</pre>
        <app-form-errors [form]="form" [formRef]="formRef" [messages]="{ language: 'Language' }"></app-form-errors>
      </div>
    </div>
  </div>
</div>
