import { Injectable } from '@angular/core';

import { IProfileForm } from '@app/landings/profile/profile-page.component';
import { ContextService, ProfileService } from '@core/services';
import { Store } from '@ngrx/store';
import { UserProfile, User, IUserAccess } from 'lingo2-models';
import { Observable, switchMap, take, zip } from 'rxjs';
import { map, tap, distinctUntilChanged } from 'rxjs/operators';

import { ProfileFormService } from '../_services/profile-form.service';

@Injectable()
export class ProfileFormValueResolver {
  constructor(
    private contextService: ContextService,
    private profileService: ProfileService,
    private store: Store,
    private profileFormService: ProfileFormService,
  ) {}

  resolve(): Observable<IProfileForm> {
    const profile$ = this.contextService.me$.pipe(
      switchMap((me: User) => this.profileService.getProfile(me.id).pipe(distinctUntilChanged())),
    );

    const settings$ = this.contextService.me$.pipe(
      switchMap(() => this.profileService.getSettings().pipe(distinctUntilChanged())),
    );

    return zip(this.contextService.me$, profile$, settings$).pipe(
      map(([user, profile, settings]: [User, UserProfile, IUserAccess]) => {
        const { first_name, last_name, slug, country_id, timezone, userpic_id, cover_id, segments, achievements } =
          user;
        const {
          about,
          spoken_languages,
          birth_date,
          education_docs,
          education_schools,
          work_experience,
          teaching_subjects,
          verification_status,
        } = profile;
        const { email, mobile_phone, email_confirmed, mobile_phone_confirmed } = settings;

        return {
          about: {
            first_name,
            last_name,
            slug,
            country_id,
            timezone,
            birth_date: new Date(birth_date),
            userpic_id,
            cover_id,
            spoken_languages,
            about,
            segments,
            achievements,
          },
          contacts: { email, mobile_phone, email_confirmed, mobile_phone_confirmed },
          education: { education_docs, education_schools },
          experience: { work_experience },
          teaching: { teaching_subjects, verification_status },
        };
      }),
      tap((x) => this.profileFormService.setProfileForm(x)),
      take(1),
    );
  }
}
