import { Location } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, ParamMap } from '@angular/router';
import { SchoolsService } from '@app/core/services';
import { ChangableComponent } from '@app/models/changable.component';
import { CSchool, ESchoolParticipantRole, User } from 'lingo2-models';
import { OnUiTab } from 'onclass-ui';
import { of } from 'rxjs';
import { mergeAll, takeUntil, tap } from 'rxjs/operators';
import {ErrorNotificationService} from "@shared/error-notification/error-notification.service";

@Component({
  selector: 'app-school-schedule',
  templateUrl: './school-schedule.component.html',
  styleUrls: ['./school-schedule.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolScheduleComponent extends ChangableComponent implements OnInit {
  public activeFilter: OnUiTab;
  public tabs: OnUiTab[] = [];
  private _school: CSchool;
  private _schoolTeachers: Array<Partial<User>> = [];

  constructor(
    public errorNotificationService: ErrorNotificationService,
    private location: Location,
    protected readonly schoolService: SchoolsService,
    protected readonly route: ActivatedRoute,
    protected readonly cdr: ChangeDetectorRef,
  ) {
    super(cdr);
  }

  ngOnInit(): void {
    of(this.watchRouteChanged$)
      .pipe(mergeAll(), takeUntil(this.destroyed$))
      .subscribe({
        error: (error) => {
          this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        }
      });
  }

  public get school(): CSchool {
    return this._school;
  }

  public get schoolTeachers(): Array<Partial<User>> {
    return this._schoolTeachers || [];
  }

  public get selectedTeacher(): Partial<User> {
    const account_id = this.activeFilter?.code;
    return account_id ? this._schoolTeachers.find((p) => p.id === account_id) : null;
  }

  public get teachersTabs(): OnUiTab[] {
    return this._schoolTeachers.map((_t) => ({
      code: _t.id,
      label: `${_t.first_name?.charAt(0)}. ${_t.last_name}`,
      route: '',
      routeCondition: this.condition.bind(this),
    }));
  }

  private condition(tab: OnUiTab) {
    this.activeFilter = tab;
    this.tabs.map((_t) => (_t.route = ''));
    const selectedFilterIndex = this.tabs.findIndex((_t) => _t.code === this.activeFilter.code);
    this.tabs[selectedFilterIndex].route = this.location.path();
  }

  private get watchRouteChanged$() {
    return this.route.parent.paramMap.pipe(
      tap((params: ParamMap) => {
        const slug = SchoolsService.getSchoolPostfix(params.get('slug'));
        this.loadSchool(slug);
      }),
    );
  }

  private loadSchool(slug: string) {
    if (this._school?.slug === slug) {
      return;
    }

    this.schoolService
      .getSchoolByIdOrSlug(slug, undefined, 'LOAD_TIME')
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (school) => {
          this._school = school;
          this._schoolTeachers = school.participants
            .filter((_p) => _p.role === ESchoolParticipantRole.teacher)
            .map((t) => t.account);
          if (this._schoolTeachers.length) {
            this.tabs = this.teachersTabs;
            this.condition(this.teachersTabs[0]);
          }
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        }
      });
  }
}
