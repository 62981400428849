<div class="school-user-card">
  <div class="user">
    <div class="avatar-wrap">
      <on-ui-cover [cover]="avatar(loadParticipant$() | async)"></on-ui-cover>
    </div>
    <div class="user-info">
      <span class="full-name bold">{{ fullName }}</span>
      <span class="country normal">{{ country }}</span>
    </div>
  </div>
  <div class="action-wrap" [class.hidden]="loader">
    <ng-template [ngIf]="loader">
      <div class="loader-wrap">
        <div class="loader item1"></div>
        <div class="loader item2"></div>
        <div class="loader item3"></div>
      </div>
    </ng-template>

    <ng-template [ngIf]="isInvited" [ngIfElse]="invite">
      <span class="action invited normal">{{ 'school-user-card.status' | translate}}</span>
    </ng-template>
    <ng-template #invite>
      <span class="action normal" (click)="onAddTeacher()">{{ 'school-user-card.action' | translate}}</span>
    </ng-template>
  </div>
</div>
