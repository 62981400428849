import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// модули
import { AccountModule } from './account.module';

// страницы

// компонтенты
import { AccountSidebarComponent } from './components/account-sidebar/account-sidebar.component';
import {
  UserProfileTeachingSubpageComponent,
  UserProfileAboutSubpageComponent,
  UserProfilePublicationsSubpageComponent,
  UserProfileCollectionsSubpageComponent,
  UserProfileFeedbackSubpageComponent,
  UserProfileClassesSubpageComponent,
  UserProfileLessonsSubpageComponent,
  UserProfileSchoolsSubpageComponent,
} from './profile';
import { UserProfilePageComponent } from './profile/user-profile-page/user-profile-page.component';

/**
 * Этот роут срабатывает после ленивой загрузки модуля по роуту /u/
 *
 * @see AppRoutingModule
 */
const routes: Routes = [
  {
    // ! base-path = /u
    path: ':user',
    children: [
      {
        path: '',
        outlet: 'right-sidebar-top',
        component: AccountSidebarComponent,
      },
      {
        path: '',
        component: UserProfilePageComponent,
        children: [
          {
            path: 'teaching',
            component: UserProfileTeachingSubpageComponent,
            children: [
              {
                // Виртуальный роут
                path: ':subject',
                component: UserProfileTeachingSubpageComponent,
              },
            ],
          },
          {
            path: 'classes',
            component: UserProfileClassesSubpageComponent,
            children: [
              {
                path: ':subject',
                component: UserProfileClassesSubpageComponent,
              },
            ],
          },
          {
            path: 'lessons',
            component: UserProfileLessonsSubpageComponent,
            children: [
              {
                path: ':subject',
                component: UserProfileLessonsSubpageComponent,
              },
            ],
          },
          {
            path: 'about',
            component: UserProfileAboutSubpageComponent,
            children: [
              {
                path: ':subject',
                component: UserProfileAboutSubpageComponent,
              },
            ],
          },
          {
            path: 'publications',
            component: UserProfilePublicationsSubpageComponent,
          },
          {
            path: 'collections',
            component: UserProfileCollectionsSubpageComponent,
          },
          {
            path: 'feedback',
            component: UserProfileFeedbackSubpageComponent,
          },
          {
            path: 'schools',
            component: UserProfileSchoolsSubpageComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [CommonModule, RouterModule.forChild(routes), AccountModule],
  exports: [],
  declarations: [],
  providers: [],
})
export class AccountRoutingModule {}
