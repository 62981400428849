{
  "APP_NAME": "Onclass",
  "DEFAULT_DESCRIPTION": "Изучайте любые из 40 предметов. Изучайте любые из 40 предметов. Изучайте любые из 40 предметов. Изучайте любые из 40 предметов.",
  "DEFAULT_TITLE": "Изучайте любые из 40 предметов на 30 языках с профессиональными преподавателями и с друзьями.",
  "cookie-text": "Onclass защищает персональные данные пользователей и обрабатывает Cookies только для персонализации сервисов. Запретить обработку Cookies можно в настройках Вашего браузера. Пожалуйста, ознакомьтесь с {{ terms1 }} и Cookies.",
  "cookie-terms": "Условия обработки персональных данных",
  "cookie-btn": "Принять и закрыть",
  "about-verification-widget": {
    "title": "Как работает верификация?",
    "description": "После того как вы отправите профиль на верификацию, модераторы Onclass убедятся в том, что в нем указана полная и соответствующая правилам портала информация, и либо одобрят его, либо вернут вам для доработки. Обычно это занимает не более 48 часов, но в среднем происходит намного быстрее.",
    "verified-profile": {
      "title": "Верифицированный профиль",
      "description-1": "Ваш профиль может быть опубликован в каталоге преподавателей.",
      "description-2": "Вы пока не занимались с преподавателями.",
      "description-3": "Вы сможете планировать индивидуальные занятия в календаре.",
      "description-4": "Ваши занятия смогут быть опубликованы в общедоступном каталоге Onclass.",
      "description-5": "Вы сможете рассчитываться с вашими учениками напрямую через Onclass с помощью безопасной сделки.",
      "description-6": "Как преподаватель вы начнете получать оценки от учеников и у вас появится публичный рейтинг.",
      "description-7": "Таким образом, вы сможете привлекать новых учеников и проводить больше занятий на платформе."
    },
    "unverified-profile": {
      "title": "Неверифицированный профиль",
      "description": "Без проверки профиля модератором вы можете использовать только конференцию, чтобы заниматься с вашими уже существующими учениками, отправляя им приглашение на занятие в виде ссылки."
    },
    "not-verified-title": "Без верификации",
    "text-1": "Занятия с Вашими учениками по пригласительной ссылке;",
    "text-2": "Публикация вашего профиля в каталоге преподавателей;",
    "text-3": "Публичные занятия, публикация Ваших занятий в каталоге классов;",
    "text-4-1": "Расчеты через",
    "text-4-2": "c помощью безопасной сделки;",
    "text-5": "Рейтинги преподавателей / Получение оценок от учеников;",
    "text-6": "Индивидуальные занятия",
    "verified-title": "Что дает верификация?"
  },
  "account": {
    "action-subscribe-to-user": "Подписаться +",
    "change-timezone": "Изменить часовой пояс",
    "edit-profile": "Редактировать профиль",
    "not-found": {
      "title": "Профиль пользователя не найден"
    },
    "private-review": "Приватный отзыв от",
    "send_message": "Сообщение",
    "settings": "Настройки",
    "subscribed-to-user": "Вы подписаны",
    "billing": {
      "meeting-price": "Стоимость занятия",
      "bank-commission": "Обработка платежа",
      "purchase-collection": "Покупка коллекции"
    }
  },
  "account-check-sidebar-widget": {
    "action-add-card": "Добавить банковскую карту",
    "action-add-individual-lessons": "Добавить индивидуальное занятие",
    "action-add-public-meeting-deposit": "Добавить депозит для занятия",
    "action-add-teaching-subjects": "Добавьте дисциплины",
    "action-add-working-hours": "Укажите рабочее время",
    "action-cancel": "Отмена",
    "action-change": "Изменить",
    "action-define-billing-details": "Заполните финансовый профиль",
    "action-resend": "Отправить снова",
    "email-change-unconfirmed-title": "Запрошенное изменение адреса электронной почты на {{email}} находится на рассмотрении.",
    "email-unconfirmed-title": "Вам нужно подтвердить Ваш email {{email}}.",
    "mobile-phone-change-unconfirmed-title": "Запрошенная смена мобильного телефона на {{number}} находится на рассмотрении.",
    "mobile-phone-unconfirmed-title": "Вы должны подтвердить свой мобильный телефон {{number}}.",
    "title": "Заполните свой аккаунт"
  },
  "ad": {
    "ad-become-teacher": {
      "action": "Пройти верификацию",
      "title": "Профиль репетитора",
      "var1": "Пройдите верификацию, чтобы попасть в каталог преподавателей",
      "var2": "Пройдите верификацию профиля, получите статус преподавателя"
    },
    "ad-create-class": {
      "action": "Открыть класс",
      "action2": "Запланировать занятие",
      "action3": "Пригласить преподавателя",
      "action4": "Смотреть демо-урок",
      "thesis1": "Индивидуальные или групповые онлайн-занятия",
      "thesis2": "Используйте контент Onclass, включая созданные вами уроки",
      "thesis3": "Новые технологии и возможности в онлайн обучении",
      "thesis4": "Индивидуальные и групповые занятия",
      "thesis5": "Интерактивные уроки и доски",
      "title": "Видеоконференция для онлайн-занятий",
      "title-student": "Пригласи преподавателя на платформу Onclass",
      "title-teacher": "Преподавайте онлайн на платформе Onclass",
      "var1": "Начните преподавать дистанционно. Создайте свой онлайн класс и оцените возможности платформы <em>onclass.com</em>"
    },
    "ad-create-content": {
      "action": "Создать урок",
      "thesis1": "Создавай увлекательные уроки в удобном редакторе",
      "thesis2": "Используйте уроки на онлайн занятиях",
      "thesis3": "Добавляйте уроки в конференцию Onclass",
      "title": "Создавайте уроки в конструкторе Onclass"
    },
    "ad-incomplete-profile": {
      "action": "Редактировать профиль",
      "title": "Ваш профиль заполнен не полностью",
      "var1": "Преподавателям будет проще создавать для вас подходящий контент, если они будут больше о вас знать"
    },
    "ad-looking-for-teacher": {
      "label1": "Найдите подходящую заявку от ученика",
      "label2": "Откликнитесь на нее и ждите ответа",
      "label3": "Выполните заявку и получите вознаграждение",
      "title": "Ученики в поисках преподавателя для занятий"
    }
  },
  "auth": {
    "action1": "Зарегистрироваться по E-mail",
    "action4": "Войти",
    "agreements": "Авторизуясь, Вы соглашаетесь {{ terms1 }} и даете согласие на<br> {{ terms2 }}.",
    "agreements-terms1": "с правилами<br> пользования сайтом",
    "agreements-terms2": "обработку персональных данных",
    "email": "Электронная почта",
    "errors": {
      "restore": {
        "email_invalid": "Некорректный email",
        "account_invalid": "Похоже, что данный аккаунт не существует",
        "required": "Пожалуйста, заполните все поля",
        "Wrong phone number": "Некорректный телефонный номер",
        "Wrong E-mail address": "Некорректный адрес эл. почты"
      },
      "signin": {
        "Account not found": "Неверный логин или пароль",
        "Wrong password": "Неверный логин или пароль",
        "invalid_credentials": "Неверный логин или пароль",
        "password_invalid": "Введите пароль",
        "email_invalid": "Введите электронную почту"
      },
      "signup": {
        "email": "Адрес эл. почты уже занят",
        "slug": "Данный аккаунт уже занят",
        "Network not supported": "Проблема с подключением",
        "Authorization not possible": "Авторизация невозможна",
        "account_already_exist": "Пользователь с таким e-mail уже существует",
        "first_name_invalid": "Введите имя",
        "last_name_invalid": "Введите пароль",
        "mobile_phone_invalid": "Некорректный телефонный номер",
        "email_invalid": "Некорректный адрес эл. почты",
        "nick_invalid": "Некорректное имя аккаунта",
        "slug_invalid": "Некорректное имя аккаунта",
        "country_invalid": "Пожалуйста, укажите страну проживания",
        "password_invalid": "Пароль должен быть больше 8 символов",
        "required": "Пожалуйста, заполните все поля"
      }
    },
    "name": "Имя",
    "surname": "Фамилия",
    "phone-number": "Номер телефона",
    "new-password": "Новый пароль",
    "country-code": "Код страны",
    "account-name": "Название аккаунта",
    "password": "Пароль",
    "repeat-password": "Повторите пароль",
    "reset-password": {
      "action-confirm": "Сохранить новый пароль",
      "action-signin": "Войти с новым паролем",
      "confirm-password": "Подтвердить пароль",
      "meta-title": "Установить новый пароль в Onclass",
      "text-check-fail": "Ссылка устарела или пароль уже был изменен",
      "text-fail": "Восстановление доступа невозможно",
      "text-success": "Ваш пароль был изменен",
      "title": "Восстановление аккаунта"
    },
    "restore": {
      "action-back": "Назад",
      "action-continue": "Продолжить",
      "action-signin": "Войти с новым паролем",
      "meta-title": "Восстановить доступ к Onclass",
      "text": "Введите почту, указанную при регистрации аккаунта и мы вышлем письмо со ссылкой на восстановление пароля.",
      "text-success": "Письмо со ссылкой для восстановления пароля отправлено на указанную почту.",
      "title": "Восстановление аккаунта",
      "password-recovery": "Восстановление пароля",
      "password-recovery-with-email": "С помощью e-mail",
      "password-recovery-with-phone": "С помощью номера телефона",
      "recovery-text": {
        "text1": "Отлично!",
        "text2": "Ваш пароль успешно сброшен. Новый пароль отправлен вам на электронный адрес, указанный в профиле регистрации.",
        "text3": "При необходимости вы можете снова поменять ваш пароль в настройках профиля."
      }
    },
    "signin": {
      "action1": "Войти",
      "action2": "Войти через соцсети",
      "action4": "Забыли пароль?",
      "meta-title": "Вход в Onclass",
      "create-new-account": "Создать новый аккаунт",
      "forgot-it": "Не помню",
      "forgot-password": "Не помню пароль"
    },
    "signout": {
      "action": "Выйти"
    },
    "signup": {
      "action1": "Зарегистрироваться",
      "meta-title": "Регистрация в Onclass",
      "sign-up": "Зарегистрироваться",
      "choose-role": "Выберите роль",
      "student": "Студент",
      "teacher": "Преподаватель",
      "methodist": "Методист",
      "accept-text": "Я принимаю <a href='/terms' target='_blank'>Условия использования</a> и <a href='/privacy' target='_blank'>Политику конфиденциальности</a>",
      "send-message": "Мы отправили СМС с кодом на номер",
      "send-message-email": "Мы отправили на указанный e-mail код для восстановления пароля",
      "not-received-message": "Если смс не приходит, проверьте правильность введенного вами номера телефона",
      "not-received-message-email": "Если код не приходит, проверьте правильность введенного вами e-mail",
      "incorrect-code": "Код неверный",
      "get-code-again": "Получить код еще раз можно через",
      "get-code": "Получить код",
      "sec": "сек"
    },
    "text3": "У Вас уже есть аккаунт?",
    "title": "Вход через Facebook, Google или по e-mail",
    "toastr": {
      "sign-in-success": "Вы вошли как {{username}}",
      "sign-in-title": "Войти",
      "sign-up-success": "Вы зарегистрировались как {{username}}",
      "sign-up-title": "Зарегистрироваться"
    },
    "warnings": {
      "empty": "Поле обязательно для заполнения",
      "invalid-email": "Неверный e-mail",
      "invalid-pass": "Неверный пароль",
      "pass-not-equal": "Пароли не совпадают",
      "wrong-email": "Неверный формат",
      "wrong-new-password": "Новый пароль неверный",
      "wrong-old-password": "Старый пароль неверный"
    }
  },
  "pull": {
    "poll-title1": "Что Вы хотите получить от Onclass?",
    "poll-title2": "Какие функции Вам интересны?",
    "option1": "Работать в свободное время",
    "option2": "Занятия со своими учениками",
    "option3": "Дистанционная работа",
    "option4": "Найти новых учеников",
    "option5": "Зарабатывать онлайн",
    "option6": "Веб - конференция + интерактивная доска",
    "option7": "Конструктор уроков",
    "option8": "Индивидуальные занятия",
    "option9": "Своя онлайн - школа",
    "option10": "Групповые занятия",
    "option11": "Учебные классы",
    "option12": "Вебинары или стримы",
    "option13": "Офлайн - курсы",
    "option14": "Домашнее задание",
    "option15": "Онлайн - курсы",
    "option16": "Марафоны",
    "content1": "Вы сами выбираете свой рабочий график, указываете рабочее время и выходные дни.",
    "content2": "Приводите своих учеников с других платформ. На занятия со своими учениками предусмотерна скидка по безопасной сделке.",
    "content3": "Вы можете работать из любой точки мира.",
    "content4": "Приклекайте новых учеников, которые регистрируются на портале.",
    "content5": "Цены на свои услуги вы устанавливаете самостоятельно. Все только на ваше усмотрение.",
    "content6": "Интерактивная доска с видео и аудио связью. Добавляйте упражнения и другой доступный контент, проводите самостоятельные работы и выдавайте домашние задания ученикам.",
    "content7": "Создавайте уроки, упражнения и другой обучающий контент и публикуйте его в разделе “уроки”.",
    "content8": "Занятия с учеником один на один.",
    "content9": "Развивайте личный бренд, нанимайте преподавателей и получайте пассивный доход.",
    "content10": "Набирайте группы учеников до 11 человек и проводите с ними групповые занятия.",
    "content11": "Классы хранят историю занятий. Вы всегда можете в рамках учебного класса посмотреть доски прошлых занятий, и понять что было изучено.",
    "content12": "Вебинары вмещают до 50 человек, стримы на 50+ участников.",
    "content13": "Собирайте подборки уроков, объединяйте их в онлайн курсы, устанавливайте цену и публикуйте в общий доступ.",
    "content14": "Выдавайте домашнее задание своим ученикам, указывайте дату проверки и контролируйте выполнение.",
    "content15": "Вы можете запланировать цепочку из нескольких занятий на одну тему и проводить их согласно расписанию.",
    "content16": "Проводите обучающие марафоны различной длительности. Запланируйте контент-план и настройте автоматическую публикацию материалов.",
    "start": "Старт"
  },
  "auth-widget": {
    "text": "Учитесь и преподавайте дистанционно, оцените возможности платформы",
    "title": "Войти",
    "to-come": "Войти"
  },
  "two-factor-auth": {
    "title": "Двухфакторная аутентификация",
    "status": {
      "not-activated": "Не активирована",
      "activated": "Активирована"
    },
    "hint": "Повысьте безопасность своей учетной записи",
    "action": {
      "activate": "Активировать двухфакторную аутентификацию",
      "disable": "Деактивировать двухфакторную аутентификацию"
    },
    "step-1": {
      "title": "Выберите операционную систему вашего смартфона, отсканируйте QR-код и скачайте одно из предложенных приложений на выбор"
    },
    "step-2": {
      "title": "Зарегистрируйтесь или войдите в приложении в свой аккаунт и отсканируйте этот QR - код с помощью приложения"
    },
    "step-3": {
      "title": "В приложении появится 6-значный код, который нужно ввести в это поле",
      "field": "Код"
    },
    "step-4": {
      "title": "Введите текущий пароль от вашего аккаунта Onclass",
      "field": "Пароль"
    },
    "error": {
      "required": "Поле обязательно для заполнения",
      "code": "Неверный код",
      "password": "Неверный пароль"
    }
  },
  "best-entity-widget": {
    "actions": {
      "content.action1": "Создать урок",
      "teachers": {
        "action1": "Начать преподавать",
        "action2": "Пригласить коллегу"
      },
      "user-service.action1": "Добавить индивидуальное занятие"
    },
    "book-now": "Записаться",
    "from": "C",
    "hour": "час",
    "interested-in": "Вам может быть интересно:",
    "see-all-link": "Показать ещё",
    "subtitles": {
      "content": "По этой дисциплине уроков еще не создано",
      "teachers": "По этой дисциплине еще нет преподавателей.",
      "user-services": "По этой дисциплине занятий еще не создано"
    },
    "titles": {
      "content": "Популярные уроки",
      "teachers": "Популярные репетиторы",
      "user-services": "Популярные занятия"
    }
  },
  "chat": {
    "cancel": "Отмена",
    "date": "Дата",
    "enter-message": "Написать сообщение",
    "leave": "Удалить чат",
    "mark-as-read": "Отметить как прочитанное",
    "open-profile": "Открыть профиль",
    "pin-chat": "Закрепить чат",
    "select-chat": "Выберите чат",
    "unpin-chat": "Открепить чат",
    "write-to-support": "Написать в поддержку"
  },
  "classrooms": {
    "page-header-title": "Учебные классы",
    "classroom": {
      "classes": "Занятий:",
      "created": "Создан:",
      "students": "Ученики:",
      "last-class": "Последнее занятие:",
      "next-class": "Предстоящее занятие:",
      "date": "Дата:",
      "tutor": "Репетитор:",
      "participants": "Участников:",
      "open-classroom": "Войти в учебный класс",
      "title": "Новый учебный класс",
      "deleted": "Удален",
      "archived": "Удалённые",
      "deleted-by-teacher": "Удален преподавателем",
      "deleted-message": "Учебный класс удален преподавателем. Вы можете покинуть класс, чтобы удалить его из архива."
    },
    "classroom-page": {
      "buttons": {
        "chat": "Чат",
        "create-meeting": "Создать занятие",
        "new-meeting": "Новое занятие",
        "join-class": "Войти в класс",
        "leave-class": "Выйти из класса",
        "invite": "Пригласить"
      },
      "meetings": {
        "stubs": {
          "owned": {
            "description": "Обучайте студентов, создавая разовые уроки по определенным темам по запросу или по мере необходимости.",
            "title": "Создайте первое занятие"
          },
          "participated": {
            "description": "Преподаватель еще не создавал занятия. Как только преподаватель создаст занятие вы получите уведомление.",
            "title": "Нет занятий"
          }
        },
        "titles": {
          "current": "Текущий",
          "upcoming": "Предстоящие",
          "past": "Прошедшие",
          "canceled": "Отмененные"
        }
      },
      "participants": {
        "stub": {
          "description": "В данном учебном классе пока что нет участников.",
          "title": "Нет участников"
        }
      },
      "content": {
        "stubs": {
          "description": "Делитесь со своими учениками новостями. Самое время создать первую публикацию.",
          "description-student": "Публикаций пока нет."
        }
      },
      "tabs": {
        "meetings": "Занятия",
        "participants": "Участники",
        "publications": "Публикации"
      },
      "cancel-subscription": "Отменить подписку",
      "cancel": "Отмена",
      "decline-participant": "Отказаться от участия"
    },
    "form": {
      "actions": {
        "cancel": "Отмена",
        "create": "Создать",
        "save": "Сохранить"
      },
      "titles": {
        "create": "Создать учебный класс",
        "edit": "Редактирование класса"
      },
      "access": {
        "public": "Публичный",
        "public-text": "Вступить в классрум могут все, кто перейдет по ссылке-приглашению.",
        "private": "Приватный",
        "private-text": "Вступить в класс могут только те, кого вы пригласили лично."
      }
    },
    "invite": {
      "copied": "Скопировано",
      "copy-link": "Копировать ссылку",
      "emails-placeholder": "Почтовые адреса через запятую",
      "get-link": "Или по пригласительной ссылке:",
      "invitation-sent": "Приглашение отправлено",
      "invite": "Приглашение",
      "invite-via-email": "Пригласите пользователей по email",
      "stub": {
        "description": "Пригласите участников в класс",
        "title": "У вас нет учеников"
      },
      "title": "Приглашение в класс"
    },
    "list": {
      "buttons": {
        "create": "Создать учебный класс"
      },
      "stubs": {
        "owned": {
          "description": "Учебный класс — это группа учеников с которыми вы регулярно занимаетесь. Класс может состоять как из отдельных занятий создаваемых в нужное время, так и заранее подготовленного цикла занятий, которые можно создать и наполнить заранее.",
          "title": "Что такое учебный класс?"
        },
        "participated": {
          "description": "Учебный класс — это группа учеников, которые занимаются с одним преподавателем. Как только преподаватель пригласит вас на занятие, вы увидите в этом разделе новый класс и сможете при желании к нему присоединиться.",
          "title": "Что такое учебный класс?"
        },
        "archived": {
          "description": "Архив — это раздел куда попадают и где хранятся ненужные или неактуальные классы. Класс находящийся в архиве можно восстановить в любой момент.",
          "title": "Что такое архив?"
        }
      },
      "tabs": {
        "owned": "Преподаю",
        "participated": "Я ученик",
        "archived": "Архив"
      }
    },
    "menu": {
      "delete": "Архивировать класс",
      "restore": "Восстановить класс",
      "edit": "Редактировать",
      "cancel": "Отмена",
      "invite": "Пригласить учеников",
      "leave": "Покинуть класс",
      "pin": "Закрепить в меню",
      "unpin": "Открепить из меню",
      "schedule": "Запланировать занятие"
    },
    "meta": {
      "title": "Учебные классы"
    },
    "steps": {
      "setup": "Описание и оформление класса:",
      "students": "Ученики:",
      "type": "Тип обучения:"
    },
    "types": {
      "classroom": "Учебный класс",
      "course": "Платный курс занятий"
    },
    "class-break-reasons": {
      "regular": {
        "no_classes": {
          "title": "Отменить подписку",
          "description": "Вы уверены, что хотите отменить подписку на регулярные занятия?",
          "cancel": "Не отменять",
          "confirm": "Отменить"
        },
        "left_classes": {
          "title": "Отменить подписку",
          "description": "Вы уверены, что хотите отменить подписку на регулярные занятия? Оплата уже прошедших занятий не возвращается.",
          "cancel": "Не отменять",
          "confirm": "Отмена"
        },
        "less_24_hours": {
          "title": "До начала занятия менее 24 часов",
          "description": "Если вы отмените подписку сейчас, 50% стоимости занятия будет перечислено преподавателю.",
          "cancel": "Не отменять",
          "confirm": "Отмена"
        }
      },
      "course": {
        "no_classes": {
          "title": "Отменить участие",
          "description": "Отменив участие, вы потеряете доступ к курсу",
          "cancel": "Не отменять",
          "confirm": "Отмена"
        },
        "left_classes": {
          "title": "Отменить участие",
          "description": "Отменив участие, вы потеряете доступ к курсу. Средства за прошедшие занятия не будут возвращены.",
          "cancel": "Не отменять",
          "confirm": "Отмена"
        },
        "less_24_hours": {
          "title": "Менее 24 часов до начала занятия",
          "description": "При отмене 50% стоимости курса будет перечислено преподавателю в качестве компенсации.",
          "cancel": "Не отменять",
          "confirm": "Отмена"
        }
      }
    }
  },
  "come-visit-banner": {
    "advantage1": "Преподавайте удаленно в удобное время",
    "advantage2": "Любые предметы для учеников со всего мира",
    "advantage3": "Вы сами решаете сколько зарабатывать",
    "btn": "Станьте репетитором",
    "high1": "дистанционно?",
    "high3": "заработок?",
    "question1": "Хотите преподавать",
    "question2": "Найти новых учеников или заниматься со своими учениками?",
    "question3": "Получить дополнительный",
    "step1": "Расскажите о себе",
    "step2": "Добавьте доступные занятия",
    "step3": "Привяжите карту и получайте платежи",
    "student": {
      "advantage1": "159 дисциплин",
      "advantage2": "1000+ бесплатных интерактивных упражнений",
      "advantage3": "40+ репетиторов",
      "advantage4": "Учитесь на ПК, планшете или телефоне",
      "btn": "Регистрация / авторизация",
      "subtitle": "Занимайтесь в виртуальном классе со своим преподавателем или находите новых на Onclass.",
      "text": "А так же:",
      "title": "Обучайтесь на Onclass.com"
    },
    "text1": "Хотите преподавать дистанционно?",
    "text2": "Найти новых учеников или заниматься со своими?",
    "text3": "Получить дополнительный заработок?",
    "title": "Преподавайте на Onclass.com"
  },
  "comment": {
    "cancel": "Отмена",
    "cause1": "Рассылка спама",
    "cause10": "Жестокое обращение с животными",
    "cause11": "Введение в заблуждение",
    "cause12": "Мошенничество",
    "cause13": "Экстремизм",
    "cause2": "Оскорбление",
    "cause3": "Материал для взрослых",
    "cause4": "Детская порнография",
    "cause5": "Пропаганда наркотиков",
    "cause6": "Продажа оружия",
    "cause7": "Насилие",
    "cause8": "Сообщить о домогательстве",
    "cause9": "Сообщить о самоубийстве",
    "comments-label": "Комментарий модератору",
    "comments-placeholder": "Опишите проблему",
    "complain-title": "Жалоба на комментарий",
    "explanation": "Пожалуйста, сообщите нам причину",
    "send": "Отправить"
  },
  "comments": {
    "answer": "Ответить",
    "complain": "пожаловаться",
    "delete": "удалить",
    "new-comment-placeholder": "Написать комментарий...",
    "next-comments": "Показать следующие комментарии",
    "next-responses": "Показать следующие комментарии",
    "page-header-title": "Комментарии",
    "meta-title": "Комментарии",
    "stub": "Отслеживайте активность в постах и уроках, где вы оставили комментарии"
  },
  "common-landing": {
    "class": {
      "text": "Вы узнаете что-то новое и поймёте, насколько интересным может быть дистанционное обучение.",
      "title": "Пройдите бесплатный урок на Onclass!"
    },
    "constructor-carousel": {
      "text": "Преподаватели на Onclass создают разнообразный учебный материал с помощью конструктора интерактивных уроков. Вместо сонных лекций — просмотр видео с Youtube, вместо нудных контрольных — увлекательные упражнения и тесты.",
      "title": "Уроки, от которых невозможно оторваться"
    },
    "online-meetings-carousel": {
      "text": "Онлайн-конференции на Onclass созданы специально для увлекательного обучения. Видео, аудио, изображения, упражнения и тесты, общение с преподавателем и другими учениками, интерактивная доска — всё это поможет сделать обучение интересным и эффективным.",
      "title": "Запоминающиеся онлайн-занятия"
    },
    "participants-common": {
      "item1": {
        "text": "Выбирайте преподавателей, предмет, уровень знаний,  язык и расписание занятий, которое вам подходит",
        "title": "Персональная настройка "
      },
      "item2": {
        "text": "Найдите репетитора под ваш бюджет и занимайтесь с любого устройства: смартфона,  планшета или компьютера",
        "title": "Доступное обучение"
      },
      "item3": {
        "text": "Учитесь, погружайтесь в материал и проходите упражнения. Библиотека знаний Onclass доступна бесплатно",
        "title": "Свободный доступ к знаниям"
      },
      "start-as-student": "Начать как ученик",
      "start-teaching": "Начать преподавать",
      "subtitle": "Почему Onclass?",
      "text": "3 причины выбрать Onclass",
      "title": "Onclass — онлайн-платформа с современным подходом к образованию"
    },
    "text": "Помогаем репетиторам зарабатывать, а ученикам получать необходимые знания",
    "title": "Onclass — это международная образовательная онлайн-платформа"
  },
  "complaint-form": {
    "close": "Закрыть",
    "info-title": "Мы рассмотрим Вашу жалобу в течение 3 дней, и свяжемся с Вами."
  },
  "constructor": {
    "all-content-types": "Все",
    "archived": "Удалённые",
    "audio-meta-panel-title": "Описание",
    "audio-panel-title": "Добавьте подкаст",
    "cancel": "Отмена",
    "choose-content-type": "Выберите тип контента",
    "close": "Закрыть",
    "create": "Создать",
    "crop": "Сохранить",
    "crop-title": "Редактировать обложку",
    "drafts": "Черновики",
    "edit": "Редактировать",
    "filter": "Фильтр",
    "filter-content-type": "Виды контента",
    "filter-sort-order": "Сортировка",
    "form-audio-placeholder1": "Перетащите подкаст",
    "form-audio-placeholder2": "или нажмите здесь для выбора",
    "form-audio-please-wait": "Пожалуйста, подождите, пока файл загрузится...",
    "form-audio-size-file-message": "Максимальный размер файла - 100 мб.",
    "form-audio-size-file-title": "Ваш файл слишком большой",
    "form-cover-image-placeholder1": "Перетащите обложку",
    "form-cover-image-placeholder2": "или нажмите здесь для выбора",
    "form-cover-submenu-change": "Изменить",
    "form-cover-submenu-edit": "Редактировать",
    "form-description-placeholder": "Описание",
    "form-explanation-label": "Пояснение",
    "form-explanation-placeholder": "Пояснение после ответа",
    "form-file-placeholder1": "Перетащите файл",
    "form-file-placeholder2": "или нажмите здесь для выбора",
    "form-file-placeholder3": "Загрузить с телефона",
    "form-file-title": "Загрузить файл",
    "form-hint-label": "Подсказка",
    "form-hint-placeholder": "Подсказка для ответа",
    "form-hint1": "Выбери количество вариантов ответов",
    "form-image-placeholder1": "Перетащите изображение",
    "form-image-placeholder2": "или нажмите здесь для выбора",
    "form-language-label": "Язык",
    "form-language-placeholder": "Выбрать",
    "form-language-search-placeholder": "Поиск...",
    "form-media-url-label": "Вставьте ссылку на видеоролик с вашим уроком в поле ниже",
    "form-media-url-placeholder": "https://www.youtube.com/",
    "form-other-level-placeholder": "Введите уровень",
    "form-other-subject-placeholder": "Название дисциплины",
    "form-question-label": "Задание",
    "form-question-placeholder": "Задание",
    "form-question-placeholder2": "Напишите или перетащите",
    "form-sub-title-placeholder": "Дополнительное название",
    "form-subject-level-label": "Уровень",
    "form-subject-level-placeholder": "Выбрать",
    "form-subject-placeholder": "Subject",
    "form-title-placeholder": "Название",
    "form-variant-placeholder": "Вариант ответа",
    "form-variant-placeholder2": "Напишите вариант ответа",
    "form-variant-placeholder3": "Напишите вариант ответа или перетащите файл",
    "form-vocabulary-definition-placeholder": "Перевод на родном языке",
    "form-vocabulary-term-placeholder": "Слово",
    "form-vocabulary-warning-language-not-defined": "Родной язык не выбран",
    "form-vocabulary-warning-subject-not-defined": "Дисциплина не выбрана",
    "generate": "Сгенерировать",
    "generate-voice": "Сгенерировать",
    "group-ad": "Реклама",
    "group-lessons": "Уроки",
    "group-media": "Медиа",
    "group-texts": "Текст",
    "image-panel-title": "Добавьте изображение",
    "incomplete-item-prompt-body": "Перед публикацией завершите оформление материалов",
    "incomplete-item-prompt-cancel": "Позже",
    "incomplete-item-prompt-edit": "В редактор",
    "incomplete-item-prompt-title": "Публикация",
    "incomplete-slide-prompt-body-empty": "Слайд №{{ index }} не заполнен",
    "incomplete-slide-prompt-body-partial": "Слайд №{{ index }} заполнен не полностью",
    "incomplete-slide-prompt-cancel": "Позже",
    "incomplete-slide-prompt-edit": "Редактировать",
    "incomplete-slide-prompt-title": "Предупреждение",
    "lesson-meta-panel-title": "Описание урока",
    "lesson-slides-panel-title": "Редактор урока",
    "mass_publish": "Опубликовать все",
    "mass_unpublish": "Отменить публикацию всех",
    "meta-mine-title": "Мои",
    "meta-title": "Редактор контента",
    "page-header-title": "Редактор контента",
    "preview": "Предпросмотр",
    "publish": "Опубликовать",
    "publish-prompt-body": "Исправьте замечания:",
    "publish-prompt-title": "Прежде чем опубликовать урок ...",
    "published": "Опубликованные",
    "record-voice": "Записать",
    "remote-item-prompt-body": "Публикация {{ title }} будет удалена. Восстановить её будет невозможно. Вы уверены, что хотите окончательно удалить?",
    "remote-item-prompt-cancel": "Отмена",
    "remote-item-prompt-confirm": "Удалить",
    "remote-item-prompt-title": "Удалить навсегда",
    "remote-slide-prompt-body": "Слайд №{{ index }} будет удален.<br/>Восстановить его будет невозможно.<br/>Вы уверены, что хотите удалить его?",
    "remote-slide-prompt-cancel": "Отмена",
    "remote-slide-prompt-confirm": "Удалить",
    "remote-slide-prompt-title": "Удалить слайд",
    "remove": "Удалить",
    "remove2": "Удалить навсегда",
    "restore": "Восстановить",
    "save": "Сохранить",
    "sidebar-create-item": "Менеджер контента",
    "slide-audio-title": "Аудио",
    "slide-duplicate": "Дублировать слайд",
    "slide-image-title": "Галерея",
    "slide-reading-title": "Чтение",
    "slide-remove": "Удалить слайд",
    "slide-scale-down": "Уменьшить слайд",
    "slide-scale-up": "Увеличить слайд",
    "slide-scale-warning": "не рекомендуется",
    "slide-sortorder-backward": "Переместить слайд назад",
    "slide-sortorder-forward": "Переместить слайд вперед",
    "slide-task-check-list-title": "Несколько правильных вариантов",
    "slide-task-fill-gaps-title": "Текстовое упражнение",
    "slide-task-match-pairs-title": "Сопоставить варианты ответов",
    "slide-task-radio-list-title": "Один правильный вариант",
    "slide-task-table-title": "Выбрать правильный вариант",
    "slide-theory-title": "Теория",
    "slide-type-label": "Выберите тип слайда",
    "slide-video-title": "Видео",
    "sort-order.newest": "Новое",
    "sort-order.oldest": "Старое",
    "sort-order.popular": "Популярное",
    "sort-order.rated": "Лучшее",
    "stub": {
      "advice": "Для создания контента воспользуйтесь, пожалуйста, ПК или ноутбуком",
      "text": "На смартфоне и планшете конструктор уроков не поддерживается."
    },
    "templates": "Шаблоны",
    "text1": "Скоро появятся новые типы и виды заданий...",
    "unpublish": "Снять с публикации",
    "upload": "Загрузить",
    "video-meta-panel-title": "Описание",
    "video-panel-title": "Добавьте видео",
    "vocabulary-terms-panel-title": "Редактор",
    "warning-empty-cover-image": "Отсутствует обложка",
    "warning-empty-language": "Язык не определен",
    "warning-empty-level": "Уровень сложности не определен",
    "warning-empty-subject": "Дисциплина не задана",
    "warning-empty-text": "Отсутствует текст",
    "warning-empty-title": "Отсутствует название",
    "warning-few-slides": "Мало слайдов",
    "warning-few-task-slides": "Недостаточно слайдов с заданиями",
    "warning-few-terms": "Мало слов в словаре",
    "warning-incomplete-media": "Не загружен медиа-файл",
    "warning-incomplete-slides": "Некоторые слайды не заполнены",
    "warning-incomplete-terms": "Некоторые слова в словаре не заполнены"
  },
  "constructor2": {
    "prevention-alert": {
      "title": "Предупреждение",
      "message": "Если Вы измените количество ячеек, часть введенной информации удалится.",
      "close": "Не изменять",
      "apply": "Изменить"
    },
    "tabs": {
      "archived": "Удалённые",
      "drafts": "Черновики",
      "constructor": "Редактор контента",
      "private": "Приватный",
      "published": "Опубликованные",
      "templates": "Шаблоны"
    },
    "cancel": "Отмена",
    "categories-description": {
      "audio": "Урок данного типа может содержать только аудио контент. Аудио может быть как одно так и несколько.",
      "audio_lesson": "Урок данного типа может содержать любой контент, и хотя бы одно упражнение с аудио.",
      "exercise": "Урок данного типа может содержать любой контент, и хотя бы одно упражнение для закрепления материала.",
      "image": "Урок данного типа может состоять только из одного или нескольких изображений.",
      "news": "Это всегда один слайд, \u2028на котором может быть любой медиаконтент, но не может быть упражнений.",
      "reading": "Как правило это урок с несколькими слайдами, на которых есть только текст и изображения.",
      "reading_lesson": "В уроке данного типа обязательно должны присутствовать текст и упражнения. Не рекомендуется использовать аудио и видео.",
      "theory": "В этом уроке можно использовать любой тип контента, кроме упражнений.",
      "theory_exercise": "Урок данного типа может содержать любой контент, и хотя бы одно упражнение для закрепления материала.",
      "video": "Урок данного типа может состоять только из видео на конкретную тему. Видео может быть как одно так и несколько.",
      "video_lesson": "Урок данного типа может содержать любой контент, но обязательно должны присутствовать хотя бы одно видео и одно упражнение.",
      "vocabulary": "Словарный тренажер поможет легко запоминать иностранные слова и фразы."
    },
    "close": "Закрыть",
    "create": "Создать",
    "crop": "Сохранить",
    "drafts": "Черновики",
    "dropzone": {
      "audio-placeholder1": "Перетащите подкаст",
      "image-placeholder1": "Перетащите изображение",
      "placeholder2": "или нажмите здесь для выбора",
      "please-wait": "Пожалуйста, подождите, пока файл загрузится...",
      "size-file-message": "Максимальный размер файла - 100 мб.",
      "size-file-title": "Ваш файл слишком большой"
    },
    "edit": "Редактировать",
    "errors": {
      "select-correct": "Укажите правильный вариант ответа"
    },
    "filter": {
      "all": "Все",
      "content-type": "Виды контента",
      "sort-order": "Сортировка",
      "title": "Фильтр"
    },
    "form-description-placeholder": "Описание",
    "form-language-label": "Язык урока",
    "form-language-description": "Выберите язык урока, который вы использовали для создания слайдов в первом шаге",
    "form-language-placeholder": "Выбрать",
    "form-language-search-placeholder": "Поиск...",
    "form-other-level-placeholder": "Введите уровень",
    "form-other-subject-placeholder": "Название темы",
    "form-sub-title-placeholder": "Дополнительное название",
    "form-subject-level-label": "Уровень",
    "form-subject-level-placeholder": "Выбрать",
    "form-subject-placeholder": "Subject",
    "form-title-placeholder": "Название",
    "generate": "Сгенерировать",
    "incomplete-alert": {
      "cancel": "Позже",
      "edit": "Редактировать",
      "title": "Предупреждение",
      "body": "Некоторые поля отсутствуют или есть ошибки. Внесите необходимые исправления и повторите попытку."
    },
    "incomplete-item-prompt-body": "Перед публикацией завершите оформление материалов",
    "incomplete-item-prompt-cancel": "Позже",
    "incomplete-item-prompt-edit": "В редактор",
    "incomplete-item-prompt-title": "Публикация",
    "mass_delete": "Удалить все",
    "mass_publish": "Опубликовать все",
    "mass_unpublish": "Отменить публикацию всех",
    "page-header-title": "Редактор контента",
    "preview": "Предпросмотр",
    "publish": "Опубликовать",
    "published": "Опубликованные",
    "published-modal": {
      "copied": "Скопировано",
      "copy": "Копировать ссылку",
      "description": "После модерации он появится в указанной секции, а пока вы можете поделитьcя уроком с друзьями",
      "title": "Урок опубликован!"
    },
    "private": "Приватный",
    "remote-item-prompt-body": "Публикация {{ title }} будет удалена. Восстановить её будет невозможно. Вы уверены, что хотите окончательно удалить?",
    "remote-item-prompt-cancel": "Отмена",
    "remote-item-prompt-confirm": "Удалить",
    "remote-item-prompt-title": "Удалить навсегда",
    "remove": "Удалить",
    "remove2": "Удалить навсегда",
    "restore": "Восстановить",
    "save": "Сохранить",
    "section": {
      "example": "Пример",
      "explanation": "Пояснение",
      "fill-the-gaps-tip": "Введите свой текст, выберите слово и выберите тип задачи:",
      "gap-drop": "Бросить",
      "gap-input": "Ввод",
      "gap-select": "Выбрать",
      "hint": "Подсказка",
      "remove": "Удалить",
      "video-label": "Вставьте ссылку на видеоролик с вашим уроком в поле ниже",
      "video-placeholder": "https://www.youtube.com/"
    },
    "section-types": {
      "audio": "Аудио",
      "check-list": "Несколько правильных вариантов",
      "fill-gaps": "Текстовое упражнение",
      "image": "Галерея",
      "match-pairs": "Сопоставить варианты ответов",
      "radio-list": "Один правильный вариант",
      "reading": "Чтение",
      "table": "Выбрать правильный вариант",
      "theory": "Теория",
      "video": "Видео",
      "vocabulary": "Словарь"
    },
    "sections-select": {
      "content": "Блоки для контента",
      "exercises": "Блоки для упражнений",
      "vocabulary": "Словарь"
    },
    "sections-toolbar": {
      "bottom": "Переместить вниз",
      "duplicate": "Дублировать блок",
      "remove": "Удалить блок",
      "top": "Переместить вверх"
    },
    "settings-form": {
      "alert": {
        "cancel": "Отмена",
        "delete": "Удалить",
        "text": "Вы уверены, что хотите удалить урок? <br/>Вы всегда можете найти удаленные уроки в разделе \"Удаленные\"",
        "title": "Предупреждение"
      },
      "answers": {
        "exercise": "Упражнение",
        "exercise-description": "Задания проверяются в каждом слайде, по нажатию кнопки “Проверить”",
        "not-limited": "Не ограничено",
        "testing": "Тестирование",
        "testing-description": "Все упражнения проверяются в самом конце урока, независимо от количества и типа слайдов в нем.",
        "time-limit": "Лимит по времени (минут)",
        "title": "Способы проверки заданий внутри урока"
      },
      "delete-button": "Удалить урок",
      "main": {
        "sub-title": "Ваш урок относится к категории \"{{type}}\". По желанию можно изменить категорию на любую из предложенных.",
        "title": "Выберите подходящую категорию урока"
      },
      "navigation": {
        "answers": "Ответы в уроке",
        "main": "Главная",
        "privacy": "Настройки приватности"
      },
      "privacy": {
        "private-description": "Приватный урок отправится в раздел “Моя библиотека” > “Приватные” и будет доступен вам и вашим ученикам на доске во время занятий.",
        "private-label": "Приватный урок",
        "public-description": "Публичный урок отправится на модерацию, а после одобрения отобразится в разделе “Моя библиотека” > “Публичные” и будет доступен всем репетиторам и студентам. ",
        "public-label": "Публичный урок (в общем доступе)",
        "title": "Кто будет видеть мой урок"
      }
    },
    "slide": {
      "add-more": "Добавить контент",
      "has-duplicate": "Есть совпадающие варианты ответов",
      "empty-gaps": "Выберите слово и выберите тип задачи",
      "empty-field": "Есть поля, обязательные для заполнения",
      "no-correct": "Необходимо выбрать правильные ответы",
      "few-variants": "Нельзя оставлять пустые варианты ответов.",
      "incomplete-pairs": "Пары незавершенные",
      "incomplete-media": "Медиа не завершена",
      "add-section": "Добавить блок",
      "empty-text": "Добавьте на слайд любой тип контента: текст, видео, изображение, подскаст или интерактивное упражнение"
    },
    "placeholder": {
      "question": "Например: Чему равна площадь прямоугольника",
      "gaps-question": "Например: Подставьте правильные варианты ответов",
      "variant": "Вариант ответа",
      "write-something": "Напишите что-нибудь",
      "cell-placeholder": "Напишите вариант ответа или перетащите файл",
      "gaps-content": "Введите сюда..."
    },
    "slide-image": {
      "label": "Загрузите картинку"
    },
    "slide-question": {
      "label": "Задание"
    },
    "slide-fill-gaps": {
      "title": "Варианты ответов",
      "save_action": "Сохранить",
      "add_options": "Добавить вариант ответа"
    },
    "slides-toolbar": {
      "slide": "Слайд",
      "add-slide": "Добавить слайд",
      "backward": "Переместить слайд назад",
      "duplicate": "Дублировать слайд",
      "forward": "Переместить слайд вперед",
      "remove": "Удалить слайд"
    },
    "sort-order.newest": "Новое",
    "sort-order.oldest": "Старое",
    "sort-order.popular": "Популярное",
    "sort-order.rated": "Лучшее",
    "steps": {
      "description": "Добавьте необходимое количество блоков или слайдов <br> и выберите тип задания или контента, который подходит под ваши цели. ",
      "edit": "Редактирование:",
      "goto-description": "Далее",
      "goto-settings": "Далее",
      "step": "Шаг {{ index }}.",
      "step-1": {
        "description": "Создайте урок с помощью конструктора",
        "label": "Конструктор"
      },
      "step-2": {
        "description": "Создайте описание урока",
        "label": "Описание"
      },
      "step-3": {
        "description": "Настройки",
        "label": "Настройки"
      }
    },
    "stub": {
      "advice": "Для создания контента воспользуйтесь, пожалуйста, ПК или ноутбуком",
      "text": "На смартфоне и планшете конструктор уроков не поддерживается.",
      "unsupported-type": "Вы не можете редактировать {{ type }} в конструкторе."
    },
    "templates": "Шаблоны",
    "unpublish": "Снять с публикации",
    "upload": "Загрузить",
    "warnings": {
      "empty-cover-image": "Отсутствует обложка",
      "empty-language": "Язык не определен",
      "empty-discount": "Поле скидки пустое",
      "empty-tiers": "Поле цены пустое",
      "empty-level": "Уровень сложности не определен",
      "empty-subject": "Дисциплина не задана",
      "empty-text": "Отсутствует текст",
      "empty-title": "Отсутствует название",
      "few-slides": "Мало слайдов",
      "few-task-slides": "Недостаточно слайдов с заданиями",
      "few-terms": "Мало слов в словаре",
      "incomplete-media": "Не загружен медиа-файл",
      "incomplete-slides": "Некоторые слайды не заполнены",
      "incomplete-terms": "Некоторые слова в словаре не заполнены",
      "no-description": "Нет описания",
      "no-students": "Не выбраны студенты",
      "has-duplicate-variant": "Некоторые задания на слайдах имеют похожие или одинаковые ответы"
    }
  },
  "auto-save": {
    "saved": "Сохранено",
    "not-saved": "Ошибка сохранения"
  },
  "lesson-constructor": {
    "actions": {
      "save-private-lesson": "Сохранить урок",
      "send-to-moderation": "Отправить на модерацию",
      "save": "Сохранить",
      "dont-save": "Не сохранять",
      "dont-send": "Не отправлять"
    },
    "alerts": {
      "moderate": {
        "title": "Отправить на модерацию?",
        "description": "Урок отправится в раздел “Уроки” -> “На модерации”. После проверки модератором урок перейдет в “Публичные” и станет доступен всем пользователям."
      },
      "private": {
        "title": "Сохранить приватный урок?",
        "description": "Урок попадет в раздел “Приватные” и не будет отображаться в общем каталоге. Его можно использовать во время занятий, добавляя на доску."
      }
    }
  },
  "collections-constructor": {
    "tabs": {
      "archived": "Удалённые",
      "moderation": "На модерации",
      "drafts": "Черновики",
      "constructor": "Конструктор коллекций",
      "private": "Приватный",
      "purchased": "Купленные",
      "published": "Опубликованные",
      "on-moderation": "На модерации"
    },
    "steps": {
      "description": "Чтобы добавить урок в коллекцию перетащите его на область коллекции выше. Добавляйте в коллекцию чтолько уроков сколько необходимо и переходите к описанию",
      "step": "Шаг {{ index }}.",
      "step1": {
        "label": "Добавление материалов",
        "description": "Добавьте материалы в коллекцию"
      },
      "step2": {
        "label": "Описание",
        "description": "Опишите коллекцию"
      },
      "step3": {
        "label": "Настройки",
        "description": "Установите параметры коллекции"
      }
    },
    "settings-subforms-nav": {
      "main": "Тип и стоимость коллекции",
      "set-type-price": "Укажите тип и стоимость коллекций"
    },
    "form": {
      "create-title": "Создайте новую коллекцию",
      "edit-title": "Редактировать коллекцию",
      "subject-label": "Subject",
      "category-label": "Категории",
      "category-placeholder": "Категории",
      "description-placeholder": "Описание",
      "language-label": "На каком языке коллекция?",
      "excerpt-placeholder": "Дополнительное название",
      "title-placeholder": "Название",
      "error-not-chosen": "Не выбрано",
      "error-required-to-fill": "Обязательно для заполнения",
      "error-min-length": "Минимум {{n}} символов",
      "info-block-label": "Опишите коллекцию",
      "access-private-label": "Приватный доступ",
      "access-commercial-label": "Общий доступ",
      "license-personal-label": "Базовый доступ (Личный доступ)",
      "license-professional-label": "Профессиональный доступ (Использование на занятиях)",
      "license-business-label": "Бизнес доступ (Использование в школах)",
      "discount-label": "Скидка",
      "discount-placeholder": "Не выбрано",
      "price-placeholder": "Не активна",
      "open-content-label": "Количество бесплатных (открытых) уроков",
      "error-price-required": "Необходимо указать цену коллекции в одном из трех вариантов доступа"
    },
    "actions": {
      "publish": "Опубликовать",
      "edit": "Редактировать",
      "remove": "Удалить",
      "remove2": "Удалить навсегда",
      "restore": "Восстановить",
      "goto-description": "Далее",
      "goto-settings": "Далее",
      "add-content1": "Добавить урок",
      "remove-content": "Удалить",
      "add-content2": "Добавить урок в коллекцию",
      "remove-collection": "Удалить коллекцию",
      "save": "Сохранить",
      "moderation-button": "Отправить на модерацию",
      "private-lesson": "Приватный урок"
    },
    "incomplete-alert": {
      "title": "Перед публикацией завершите оформление материалов",
      "later": "Позже",
      "edit": "В редактор",
      "continue": "Продолжить"
    },
    "remote-item-prompt": {
      "body": "Коллекция {{title}} будет удалена. Это действие не может быть отменено. Вы уверены, что хотите удалить коллекцию?",
      "cancel": "Отмена",
      "confirm": "Удалить",
      "title": "Удалить навсегда"
    },
    "texts": {
      "add-content1": "Добавьте уроки, которые вы хотите видеть в коллекции",
      "access-private1": "Приватный доступ",
      "access-private2": "Коллекции доступны только вам. Вы можете использовать на своих занятиях с учениками",
      "license-personal1": "Базовый доступ",
      "license-personal2": "Ученик сможет самостоятельно изучать уроки из коллекции",
      "license-professional1": "Профессиональный доступ",
      "license-professional2": "Преподаватель сможет использовать уроки из коллекции для преподавания на платформе Onclass",
      "license-business1": "Бизнес доступ",
      "license-business2": "Уроки из коллекции смогут использовать для преподавания в онлайн-школе на платформе Onclass",
      "license": "Лицензионное соглашение по использованию коллекций",
      "open-content-hint": "Отсчет уроков идет с самого начала",
      "no-free-lessons": "Нет бесплатных уроков",
      "teacher-need-card": "Для получения оплаты вам нужно привязать банковскую карту",
      "teacher-need-card-link": "Привязать карту",
      "teacher-need-card-btn": "Привязать"
    },
    "content-selector": {
      "search-placeholder": "Поиск",
      "add-to-collection": "Добавить урок в коллекцию",
      "add-lesson": "Добавить урок",
      "no-lessons1": "У вас нет уроков для добавления в коллекцию.",
      "no-lessons2": "Создавайте больше уроков с помощью конструктора.",
      "create-lesson": "Создать урок",
      "title": "Выберите урок для добавления в коллекцию",
      "title-lesson": "Выберите урок",
      "invalid-hint": "Выберите урок и нажмите кнопку"
    },
    "meta-title": "Коллекции",
    "meta-title-editor-new": "Новая коллекция",
    "meta-title-purchased": "Приобретенные коллекции",
    "meta-title-published": "Опубликованные коллекции",
    "meta-title-drafts": "Черновики коллекций",
    "meta-title-archived": "Архивные коллекции",
    "meta-title-private": "Приватные коллекции",
    "delete-confirmation": {
      "title": "Вы действительно хотите удалить коллекцию?",
      "text": "В случае отмены коллекция не будет удалена",
      "cancel": "Отмена",
      "delete": "Удалить"
    }
  },
  "collections": {
    "meta-title": "Коллекции",
    "collection": "Коллекция",
    "back-to-collection": "Назад к коллекции",
    "paid-content-informer": {
      "title": "Платный контент",
      "message": "Приобретите коллекцию, чтобы открыть доступ к урокам и упражненям",
      "action": "Купить"
    },
    "private-content-informer": {
      "title": "Частный урок",
      "message": "Приобретите коллекцию, чтобы получить доступ к уроку.",
      "action": "Купить",
      "incollection": "Данный урок входит в коллекцию"
    },
    "text": {
      "license-type-label": "Тип доступа: {{ label }} (Использование на занятиях)",
      "discount": "Скидка: {{ discount }}%",
      "license": "Лицензия",
      "items": "Уроков:",
      "reviews-count": "отзывов",
      "main-tab": {
        "all": "Все"
      },
      "free": "бесплатно"
    },
    "actions": {
      "menu-complain": "Пожаловаться",
      "menu-edit": "Редактировать коллекцию",
      "menu-share": "Поделиться",
      "menu-remove": "Удалить коллекцию",
      "upgrade-license": "Обновить лицензию",
      "choose-license": "Купить",
      "buy": "Купить",
      "details": "Подробнее",
      "edit": "Редактировать",
      "delete": "Удалить",
      "remove-from-publication": "Снять с публикации",
      "license": "Купить"
    },
    "moderation-status": {
      "fail": "Коллекция не прошла модерацию",
      "pending": "Ожидает модерации",
      "on-moderation": "На модерации"
    },
    "drag-scroll": {
      "title": "Коллекции",
      "text": "Тематические сборники уроков",
      "create": "Собрать свою коллекцию"
    },
    "collection-license-selector": {
      "title": "Выберите вариант использования коллекции",
      "discount": "{{ discount }}% скидка",
      "description-personal": "Вы сможете использовать коллекцию только для изучения материала.",
      "description-professional": "Вы сможете использовать коллекцию на занятиях со своими учениками.",
      "description-business": "Вы сможете использовать коллекцию в своих школах на Onclass.",
      "total": "Итого: {{ total }}",
      "cost": "Стоимость",
      "action-pay": "Перейти к оплате",
      "already-use": "Уже используется",
      "cannot-buy": "Вы не можете купить свою же коллекцию"
    },
    "page-header-title": "Коллекции"
  },
  "collection-license": {
    "personal": "Личный доступ",
    "professional": "Профессиональный доступ",
    "business": "Бизнес доступ"
  },
  "collection-license-short": {
    "personal": "Для студентов",
    "professional": "Для учителей",
    "business": "Для школ"
  },
  "content": {
    "cancel": "Отмена",
    "cause1": "Спам",
    "cause10": "Жестокое обращение с животными",
    "cause11": "Введение в заблуждение",
    "cause12": "Мошенничество",
    "cause13": "Экстремизм",
    "cause2": "Оскорбление",
    "cause3": "Материал для взрослых",
    "cause4": "Детская порнография",
    "cause5": "Пропаганда наркотиков",
    "cause6": "Продажа оружия",
    "cause7": "Насилие",
    "cause8": "Призыв к травле",
    "cause9": "Призыв к суициду",
    "comments-label": "Комментарий модератору",
    "comments-placeholder": "Опишите проблему",
    "complain": "Пожаловаться",
    "complain-title": "Жалоба на материал",
    "explanation": "Пожалуйста, сообщите нам причину",
    "page-header-title": "Пользователи",
    "search": "Поиск...",
    "send": "Отправить",
    "send-complaint": "Отправить жалобу",
    "subscribe": "Подписаться",
    "tabs-all": "Все",
    "tabs-subscribed": "Вы подписаны",
    "tabs-subscriptions": "Подписки",
    "to-write": "Написать",
    "unsubscribe": "Отписаться",
    "you-subscribed": "Вы подписаны"
  },
  "content-mini-card": {
    "article": "Статья",
    "author": "Автор:",
    "native": "Родной язык",
    "news": "Новость",
    "theory": "Теория",
    "archived": "Удалённые",
    "declined": "Отклонено модератором",
    "draft": "Черновик",
    "added": "Урок добавлен!",
    "already-added": "Урок уже добавлен!"
  },
  "content-type-title": {
    "all": "Все",
    "article": "Статья",
    "articles": "Статья",
    "audio": "Подкаст",
    "audio-exercises": "Аудио с упражнениями",
    "audio-lesson": "Аудио уроки",
    "document": "Документ",
    "exercise": "Упражнение",
    "exercises": "Упражнения",
    "image": "Галерея",
    "images": "Галерея",
    "lesson": "Упражнения",
    "news": "Новость",
    "podcasts": "Подкаст",
    "posts": "Пост",
    "reading": "Чтение",
    "reading-exercises": "Чтение с упражнением",
    "reading-lesson": "Чтение с упражнением",
    "test": "Тест",
    "theory": "Теория",
    "theory-exercise": "Теория с упражнением",
    "theory-exercises": "Теория с упражнением",
    "video": "Видео",
    "video-exercises": "Видео с упражнениями",
    "video-lesson": "Видеоуроки",
    "vocabulary": "Словарь",
    "vocabulary-exercises": "Словарные упражнения"
  },
  "content-types-title": {
    "article": "Чтение",
    "audio": "Подкасты",
    "audio_lesson": "Аудио с упражнениями",
    "document": "Документы",
    "exercise": "Упражнения",
    "image": "Изображения",
    "lesson": "Уроки",
    "news": "Новость",
    "reading": "Чтение",
    "reading_lesson": "Чтение с упражнением",
    "test": "Тесты",
    "theory": "Теория",
    "theory_exercise": "Теория с упражнением",
    "video": "Видео",
    "video_lesson": "Видео с упражнениями",
    "vocabulary": "Словарь"
  },
  "currency": {
    "CREDITS": "Δ",
    "EUR": "EUR",
    "RUR": "RUB",
    "RUB": "RUB",
    "USD": "USD",
    "GBP": "GBP",
    "UAH": "UAH",
    "BYR": "BYR",
    "BYN": "BYN",
    "KZT": "KZT",
    "AZN": "AZN",
    "CHF": "CHF",
    "CZK": "CZK",
    "CAD": "CAD",
    "PLN": "PLN",
    "SEK": "SEK",
    "TRY": "TRY",
    "CNY": "CNY",
    "INR": "INR",
    "BRC": "BRC",
    "ZAR": "ZAR",
    "UZS": "UZS",
    "BGL": "BGL",
    "JPY": "JPY",
    "NOK": "NOK",
    "DKK": "DKK",
    "ISK": "ISK",
    "HRK": "HRK",
    "RON": "RON",
    "TWD": "TWD",
    "SGD": "SGD",
    "AED": "AED",
    "AMD": "AMD"
  },
  "transaction-history": {
    "history": "История",
    "for-all": "по всем картам и счетам",
    "all-cards": "Все карты и счета",
    "card": "Карта",
    "account": "Счет",
    "tabs": {
      "all": "Все",
      "credit": "Расходы",
      "debit": "Поступления"
    },
    "operations-for": "Операции за",
    "search-placeholder": "Операция, сумма",
    "date": "Дата",
    "operation": "Операция",
    "amount": "Сумма",
    "status": "Статус",
    "types": {
      "PURCHASE": "Вы купили",
      "SELLER_REFUND": "Вы вернули",
      "SELLER_PAYMENT_RECEIVED": "У вас купили",
      "SELLER_PREPAYMENT_RECEIVED": "У вас купили(безопасная сделка)",
      "REFUND_PURCHASE": "Возврат покупки",
      "PAYOUT": "Выплата"
    },
    "statuses": {
      "completed": "исполнен",
      "canceled": "отменен",
      "pending": "в ожидании"
    }
  },
  "dashboard": {
    "accept_required": "Получено приглашение от преподавателя",
    "class-form": {
      "add-more": "Добавить еще",
      "cancel": "Отмена",
      "confirm": "Подтвердить",
      "date": "Дата",
      "not-selected": "Не выбрано",
      "occupation": "Занятие",
      "participant": "Участник",
      "title": "Новое занятие"
    },
    "date": "Дата:",
    "event-card": {
      "cross-hint": "У вас уже запланирована еще одна встреча в <span class=\"hint-text time normal\">{time}</span>. Вы должны отклонить одну из них."
    },
    "event-state": {
      "canceled": "Отмененные занятия",
      "going": "В эфире",
      "past": "Прошедшие занятия",
      "past-message": "Нет прошедших занятий",
      "unconfirmed": "Неподтвержденные классы",
      "upcoming": "Предстоящие"
    },
    "grade": "Оценка:",
    "label": {
      "cost": "Стоимость: "
    },
    "label-btn": {
      "accept": "Принять",
      "accept-and-pay": "Принять и оплатить",
      "awaiting-confirmation": "Ожидание подтверждения ...",
      "cancel": "Отменить конференцию",
      "cancel-invite": "Отказаться от приглашения",
      "cancel-waiting-header": "Ожидание отмены ...",
      "cancel-waiting-text": "Может занять пару минут",
      "close": "Закрыть",
      "confirm": "Подтвердить",
      "decline": "Отклонить",
      "enter-class": "Войти в класс",
      "reject": "Отклонить",
      "reschedule": "Перенести",
      "reschedule-decline": "Отмена",
      "proceed-to-checkout": "Перейти к оплате",
      "waiting": "Ожидание...",
      "withdraw-invitation": "Отменить приглашение",
      "leave": "Покинуть конференцию"
    },
    "leave-message": "Сообщение",
    "meeting-cancel-confirmation": {
      "100-percent-sure": "Подтвердить",
      "error": "Ошибка",
      "error-text": "Конференция не может быть отменена прямо сейчас.",
      "grade": "Автооценка: ",
      "im-pissed-off": "Не подтверждено",
      "ok": "Ок",
      "penalty": "Удержание: ",
      "text": "Могут быть применены дополнительные штрафы и изменения рейтинга. Вы хотите продолжить?",
      "title": "Вы хотите отменить занятие?"
    },
    "meeting_reservation_unpaid": "У вас есть неоплаченный урок",
    "course_reservation_unpaid": "У вас есть неоплаченный курс",
    "meeting_reservation_waiting": "Ожидание оплаты",
    "more-event": "Показать ещё",
    "new-date": "Новая дата:",
    "no-past-classes": "У вас нет прошедших занятий.",
    "old-date": "Пред. дата:",
    "paid_accept_required": "Эта конференция требует оплаты, прежде чем принять",
    "paid_accept_required_simple": "Получено приглашение на занятие",
    "participation-free": "Бесплатно",
    "participation-price": "Участие:",
    "past-events-student-title": "Прошедшие занятия с преподавателями",
    "past-events-teacher-title": "Прошедшие занятия с учениками",
    "popup": {
      "cancel-lesson": "Отменить занятие",
      "change-data-time": "Изменить дату и время",
      "details": "Подробнее",
      "invite-subscriber": "Приглашение",
      "multi-events.description": "Вы можете отменить событие или принять участие во всех событиях",
      "multi-events.header": "Это событие будет в",
      "multi-events.header-school": "На это время запланировано {{count}} события",
      "new-class": "Новое занятие",
      "non-working": "Указать как нерабочее время",
      "reschedule": "Перенести занятие",
      "working": "Указать как рабочее время",
      "join-class": "Записаться в класс"
    },
    "reschedule": {
      "alert-body": "Если вы отклоните изменение, дата занятия останется прежней, но преподаватель сможет его отменить.",
      "alert-title": "Отклонить запрос на изменение даты?",
      "grade": "Рейтинг репетиторов:",
      "held": "Проведено:",
      "meetings": "занятий",
      "teacher": "Преподаватель:",
      "title": "Перенос времени"
    },
    "reschedule_requested": "Запрос на перенос времени был отправлен",
    "reschedule_required": "Был запрошен перенос времени",
    "schedule": {
      "today": "Сегодня"
    },
    "stat": {
      "average-rating": "Рейтинг репетиторов",
      "classes": "Занятий в классе",
      "lesson-points": "Очки за прохождение уроков",
      "lessons-completed": "Пройдено уроков",
      "publications": "Публикации и уроки",
      "status": "Статус",
      "students": "Студенты",
      "subtitle-student": "Статистика",
      "subtitle-teacher": "Повышает доверие учеников к Вам",
      "teachers": "Мои наставники",
      "title": "Учеба",
      "tutor": "Репетитор"
    },
    "student": "Ученик:",
    "student-not-invited": "не приглашен",
    "subtitle1": "Занятия с учениками",
    "subtitle2": "Занятия с преподавателями",
    "subtitle2_explain1": "У вас нет предстоящих занятий. Найдите себе <a href='/teachers'>репетитора</a> или запишитесь на <a href='/classes'>любое занятие</a>.",
    "subtitle2_explain2": "У вас нет прошедших занятий.",
    "tabs": {
      "all-news": "Все новости",
      "dashboard": "Дашборд",
      "events": "Занятия",
      "feed": "Лента",
      "homework": "Домашнее задание",
      "main": "Главная",
      "schedule": "Календарь",
      "teacher": "Кабинет преподавателя",
      "teaching": "Преподаю"
    },
    "teacher": "Репетитор:",
    "time-before-start": "Начало в",
    "time-in-minute": "менее чем через минуту",
    "time-in-minute-countdown": "менее минуты",
    "title": "Дашборд",
    "unconfirmed-causes": {
      "received-invite-student": "Приглашения от учеников",
      "received-invite-teacher": "Получено приглашение от преподавателя",
      "received-lesson-change": "Получен запрос на изменение урока",
      "reservation": "Урок забронирован заранее",
      "sent-invite-student": "Отправлено приглашение ученику",
      "sent-lesson-change": "Отправлен запрос на изменение урока"
    },
    "upcoming-events-student-explain": "У вас нет предстоящих занятий. Найдите себе <a href='/teachers'>репетитора</a> или запишитесь на <a href='/classes'>любое занятие</a>.",
    "upcoming-events-student-title": "Предстоящие занятия с репетиторами",
    "upcoming-events-teacher-explain": "У вас нет предстоящих занятий. Создавайте <a href='/lessons'>уроки</a>, чтобы ученики могли вас найти, или приглашайте учеников на <a href='/classes'>классы</a>.",
    "upcoming-events-teacher-title": "Предстоящие занятия с учениками",
    "wallet": {
      "balance": "Баланс",
      "current": "Текущий баланс",
      "income": "Вы заработали за месяц",
      "passive-income": "Пассивный доход",
      "private-credit": "Кредитов для занятий",
      "replenish": "Пополнить",
      "subtitle": "Информация о Ваших финансах",
      "title": "Кошелек",
      "withdraw": "Вывести деньги",
      "withdraw2": "Вывести со счета"
    },
    "nearest-meet-widget": {
      "tutor": "Репетитор",
      "on_air": "В эфире",
      "enter_class": "Войти в класс",
      "show_more": "Смотреть все",
      "start_in": "Начало в",
      "class-started": "Занятие началось",
      "nearest-class": "Ближайшее занятие..."
    },
    "your-income": "Участие:"
  },
  "dashboard-plan-widget": {
    "action-redeem-coupon": "Активировать купон",
    "action-upgrade": "Обновить тариф",
    "balance-title": "Баланс",
    "next-updates": "Дата след. обновления",
    "plan-title": "Ваш тариф",
    "update": "Частота обновления"
  },
  "datetime-picker": {
    "no-time": "Недоступен",
    "text": "Выберите новое время занятий",
    "timezone": "На основе вашего часового пояса",
    "teacher-busy": "На данный момент у репетитора нет свободного времени.<br>Вы можете связаться с преподавателем и попробовать договориться.",
    "teacher-chat": "Написать учителю"
  },
  "default": {
    "meta-title": "-",
    "page-header-title": ""
  },
  "domain-selector": {
    "confirm": "Подтвердить",
    "text": "Язык Вашего браузера - {{ term }}. Хотите переключить сайт Onclass.com на русский язык?"
  },
  "profile-steps": {
    "personal-information": "Личная информация",
    "teaching": "Преподавание",
    "services": "Доступные занятия",
    "schedule": "Календарь",
    "bank-card": "Добавить банковскую карту",
    "bank-card-world": "Для оплаты по всему миру",
    "bank-card-rus": "Для оплаты по России",
    "test-bank-card": "Тестовая карта",
    "test-provider": "Тестовый провайдер",
    "test-beneficiary": "Тестовый бенефициар",
    "payment-info": "Платежная информация",
    "payin-info": "Информация для оплаты",
    "payout-info": "Информация для выплат",
    "application": "Приложение",
    "acquaintance": "Знакомство",
    "about-you": "О себе",
    "contacts": "Контакты",
    "education": "Образование",
    "work-experience": "Опыт работы"
  },
  "edit-profile": {
    "edit": "Редактировать профиль",
    "tabs-education": "Образование",
    "tabs-personal": "Личная информация",
    "tabs-security": "Контакты",
    "tabs-studying": "Studying"
  },
  "edit-teacher-profile": {
    "page-header-title": "Верификация профиля учителя",
    "tabs-about": "Личная информация",
    "tabs-billing": "Биллинг",
    "tabs-education": "Образование",
    "tabs-experience": "Опыт работы",
    "tabs-security": "Контакты",
    "tabs-teaching": "Преподаю"
  },
  "feed": {
    "meta-title": "Лента",
    "new-lessons-block-title": "Новые уроки",
    "new-tests-block-title": "Новые тесты",
    "new-theory-block-title": "Новая теория",
    "page-header-title": "Добро пожаловать на Onclass",
    "pagination-nothing-more": "Больше ничего нет",
    "post-form": {
      "placeholder": "Что у вас нового?"
    },
    "recommended-lessons-block-title": "Рекомендуемые уроки",
    "show-more": "показать больше",
    "tasks-block-title": "Домашнее задание",
    "teachers-block-title": "Новые репетиторы"
  },
  "finance": {
    "card-create": {
      "title": "Привязка новой дебетовой карты",
      "sub-title": "Привязка кредитной карты невозможна",
      "to-confirm": "Для подтверждения карты с неё спишется и вернётся обратно небольшая сумма"
    },
    "card-page": {
      "add-card": "Добавить карту",
      "card-remove-error-link": "У вас есть неоплаченное занятие",
      "card-remove-error-message": "По данной карте есть незавершенные транзакции. Подробнее можно посмотреть в истории. Удаление возможно только после завершения всех транзакций",
      "card-remove-error-title": "Эту карту временно нельзя удалить",
      "description": "Добавьте банковскую карту, чтобы принимать платежи от учеников.",
      "informer": "Если она не отображается в списке <br> после привязки банковской карты, пожалуйста, напишите в службу поддержки.",
      "no-cards": "Нет привязанных карт",
      "support": "Написать в поддержку",
      "title": "Банковские карты"
    },
    "tabs": {
      "billing": "Формировать",
      "cards": "Банковские карты",
      "history": "История",
      "main": "Главная",
      "payment": "Пополнение счета",
      "payout": "Вывод со счета",
      "plan": "Тарифы",
      "subscriptions": "Мои подписки",
      "settings": "Информация для выставления счетов"
    },
    "title": "Платежный профиль"
  },
  "game": {
    "replay-game-confirmation": {
      "action-replay": "Пройти заново",
      "action-show": "Посмотреть",
      "body": "Вы можете просмотреть последние результаты или повторить этот урок еще раз",
      "title": "Начать этот урок еще раз?"
    }
  },
  "game-review": {
    "comment-placeholder": "Комментарий",
    "review-title": "Пожалуйста, оцените качество урока",
    "success-title": "Спасибо за ваш отзыв!"
  },
  "games-header": {
    "author": "Автор",
    "back-to-lesson": "Назад к уроку",
    "contact": "Написать",
    "contact-teacher": "Вы можете связаться с преподавателем.",
    "create-content": "Создавай контент и привлекай учеников",
    "form": {
      "contact": "Связаться с преподавателем",
      "copied": "Скопировано",
      "copy-link": "Копировать ссылку на урок",
      "date-placeholder-not-ok": "Нет доступных дат",
      "date-placeholder-ok": "Дата и Время",
      "lessons": "Занятий:",
      "lessons-placeholder-not-ok": "Нет доступных занятий",
      "lessons-placeholder-ok": "Выберите доступное занятие",
      "select-date": "Выберите дату и время",
      "signup": "Зарегистрироваться",
      "signup-own-lesson": "Вы не можете записаться к себе на занятие, но можете пригласить учеников"
    },
    "no-lessons": "У преподавателя нет доступных занятий для записи.",
    "sign-up-teacher": "Записаться к преподавателю",
    "start-teaching": "Начни преподавать на Onclass.",
    "subscribe": "Подписаться",
    "teacher-has-lessons": "У преподавателя есть доступные занятия для записи",
    "teacher-has-no-lessons": "У преподавателя нет доступных занятий для записи",
    "unsubscribe": "Занятия",
    "you-have-lessons": "У вас есть доступные занятия",
    "you-have-no-lessons": "У вас нет доступных занятий"
  },
  "greetings": {
    "afternoon": "Добрый день, {{user}}",
    "evening": "Добрый вечер, {{user}}",
    "morning": "Доброе утро, {{user}}",
    "night": "Доброй ночи, {{user}}",
    "welcome": "Добро пожаловать на Onclass"
  },
  "hints": {
    "action-explain": "Подробнее",
    "action-hide": "Скрыть",
    "hint-feed.title": "Как работает лента?",
    "hint-feed.var1": "Публикации в ленте — это простой и удобный способ общения с вашей аудиторией. Полезные и интересные публикации привлекут новых учеников, помогут завести друзей и привлечь новых подписчиков.",
    "hint-feed.var2": "Станьте блогером в образовании. Зарабатывайте на рекламе, делитесь полезной информацией, привлекайте новых учеников, информируйте их о будущих конференциях, новых упражнениях и тестах, или просто делитесь интересными новостями.",
    "hint-feed.var3": "В ленте вы сможете следить за обновлениями других участников, которые будут вам интересны. Контент, созданный в конструкторе уроков, также появится в вашей ленте. Попробуйте!",
    "hint-library-add-to-favorites.title": "В избранном пока ничего нет",
    "hint-library-add-to-favorites.var1": "Добавьте интересные записи в избранное, чтобы быстро найти их здесь",
    "hint-library.action-create": "Создать урок",
    "hint-library.title": "Как работает библиотека?",
    "hint-library.var1": "Создавайте уроки и упражнения, добавляйте видео и другой медиаконтент с помощью нашего конструктора.",
    "hint-library.var2": "Полезные и интересные публикации привлекут новых подписчиков и учеников, которые могут заинтересоваться вашими услугами. Вы можете делиться опубликованными упражнениями с другими пользователями.",
    "hint-library.var3": "Создавайте упражнения и используйте их во время онлайн-конференции на интерактивной доске. Эти упражнения могут быть также выданы в качестве домашнего задания.",
    "hint-meetings.action-create": "Создать конференцию",
    "hint-meetings.action-demo": "Смотреть демо-ролик",
    "hint-meetings.title": "Как проходят занятия на платформе Onclass?",
    "hint-meetings.tooltip1": "Панель инструментов",
    "hint-meetings.tooltip2": "Проводите индивидуальные или групповые уроки",
    "hint-meetings.tooltip3": "Добавляйте на доску уроки, упражнения, видео и другой контент",
    "hint-meetings.tooltip4": "Интерактивная доска",
    "hint-meetings.tooltip5": "Чат для участников",
    "hint-meetings.tooltip6": "Список учеников в классе",
    "hint-meetings.tooltip7": "Камера преподавателя",
    "hint-meetings.tooltip8": "Камеры участников",
    "hint-meetings.tooltip9": "Упражнение на доске",
    "hint-meetings.var1": "Создавайте уроки и упражнения, добавляйте видео и другой медиаконтент с помощью нашего конструктора.",
    "hint-meetings.var2": "Полезные и интересные публикации привлекут новых подписчиков и учеников, которые могут заинтересоваться вашими услугами. Вы можете делиться опубликованными упражнениями с другими пользователями.",
    "hint-meetings.var3": "Создавайте упражнения и используйте их во время онлайн-конференции на интерактивной доске. Эти упражнения могут быть также выданы в качестве домашнего задания.",
    "hint-not-found-mine.title": "Ваших публикаций пока нет",
    "hint-not-found-mine.var1": "<i>Создайте</i> уроки, статьи, видео, подкасты или что-нибудь ещё и <em>Опубликуйте</em> их",
    "hint-teachers.action-teacher": "Станьте репетитором",
    "hint-teachers.title": "Как работает каталог преподавателей?",
    "hint-teachers.var1": "Начните преподавать дистанционно на onclass.com. Заполните анкету преподавателя, и ваш профиль появится в каталоге преподавателей.",
    "hint-teachers.var2": "О вас и ваших услугах узнают ученики и пользователи Onclass, анкета появится в поисковых системах Google и Yandex. Занимайтесь онлайн в удобное время."
  },
  "invite-card": {
    "action-cancel": "Отмена",
    "action-copy-link": "Копировать ссылку",
    "action-invite": "Приглашение",
    "get-link": "Скопировать пригласительную ссылку",
    "invitation-accepted": "Приглашение принято",
    "invitation-declined": "Приглашение принято",
    "invitation-sent": "Приглашение отправлено",
    "link-copied": "Ссылка скопирована",
    "title": "Пригласить в класс"
  },
  "job-landing": {
    "actions": {
      "interested": "Мне интересно!",
      "want-to-learn": "Хочу учиться",
      "want-to-see": "Хочу посмотреть",
      "want-to-teach": "Хочу преподавать"
    },
    "class": {
      "subtitle": "Онлайн-конференция на Onclass.com",
      "text": "Create an online class, invite students,<br> add study material and start the lesson",
      "title": "Подготовьте групповое или<br> индивидуальное занятие за 5 минут"
    },
    "constructor-carousel": {
      "slide": {
        "exercises-text": "Упражнения с текстом",
        "gallery": "Презентации/картинки/PDF",
        "lesson": "Упражнения",
        "media": "Видео и аудио",
        "theory": "Теория",
        "vocabulary": "Словарь"
      },
      "subtitle": "Конструктор интерактивных уроков",
      "text": "И ваши студенты не смогут оторваться от учёбы",
      "title": "Создавайте мультимедийные<br> упражнения или используйте готовые — из библиотеки Onclass"
    },
    "footer": {
      "about": "Личная информация",
      "classes": "Онлайн-занятия",
      "library": "Уроки",
      "offer": "Пользовательское соглашение",
      "privacy": "Политика конфиденциальности",
      "salesrefund": "Продажи и возврат",
      "teachers": "Репетиторы",
      "terms": "Условия использования"
    },
    "header": {
      "text": "Индивидуальные и групповые онлайн-классы, интерактивные конструкторы уроков и ученики, жаждущие знаний, уже ждут вас!",
      "title": "Преподавайте<br> на Onclass"
    },
    "meetings": {
      "online-classes": "Онлайн занятия",
      "subtitle": "Обучайте с нами",
      "text": "У нас студенты сами находят преподавателей.<br> Вам лишь нужно создать контент, который будет им подходить.",
      "title": "Сконцентрируйтесь на обучении,<br>а поиск учеников Onclass берет на себя!"
    },
    "online-meetings-carousel": {
      "slides": {
        "audio": "Аудио",
        "board": "Доска",
        "exercises": "Упражнения",
        "gallery": "Изображения",
        "pdf": "PDF",
        "presentation": "Презентации",
        "theory": "Теория",
        "video": "Видео",
        "vocabulary": "Словарь"
      },
      "subtitle": "Онлайн-конференция на Onclass.com",
      "text": "В прямом эфире вы сможете рисовать и писать на интерактивной доске, <br>общаться с учениками, проверять их знания и добавлять в свои занятия любые материалы.",
      "title": "Use an online conference,<br>created for distance learning"
    },
    "participants": {
      "item1": {
        "text": "In the morning, in the evening, in the afternoon, at night, it doesn't matter.<br> The main thing is that you and the students feel comfortable",
        "title": "Преподавайте в удобное для вас время"
      },
      "item2": {
        "text": "Add any materials to classes: text, audio, video,<br> images, formulas, tests, exercises and graphs.",
        "title": "Создавайте интерактивные уроки"
      },
      "item3": {
        "text": "Use your phone, tablet, laptop<br> or conduct classes from a computer",
        "title": "Никаких ограничений"
      },
      "subtitle": "Обучайте с нами",
      "text": "Сосредоточьтесь на преподавании, а мы поможем найти учеников",
      "title": "Почувствуйте свободу с Onclass"
    },
    "support": {
      "contact": "Написать в поддержку",
      "subtitle": "Центр помощи",
      "text": "Хотите узнать подробнее о заработке и возможностях Onclass? Нажмите на кнопку ниже. Поддержка ответит на любой вопрос <br>о нашей платформе.",
      "title": "Остались вопросы? <span>Onclass</span> поможет"
    },
    "teacher-carousel": {
      "subtitle": "Международная платформа",
      "teachers": "Репетиторы",
      "text": "Onclass объединяет профессионалов из 70 стран мира",
      "title": "C нами уже более миллиона преподавателей!<br> Присоединяйтесь к <span>Onclass</span>"
    },
    "teacher": {
      "title": "Преподавайте онлайн на платформе Onclass",
      "subtitle": "Виртуальная платформа для проведение онлайн занятий и привлечения новых учеников",
      "button-text": "начать преподавать",
      "variant1": "Преподавайте любимые предметы",
      "variant2": "Занимайтесь со своими учениками",
      "variant3": "Создавайте курсы и коллекции",
      "video-link": "Смотреть обзорное видео",
      "subimage-text": "Как проходят занятия"
    },
    "advantages": {
      "text1": "Бесплатный контент и конструктор уроков",
      "text2": "Виртуальный класс для онлайн занятий",
      "text3": "Личная страница репетитора"
    },
    "whoisneed": {
      "title": "Платформа Onclass подходит",
      "more": "Подробнее",
      "subtitle1": "Репетиторы",
      "subtext1": "Занимайтесь со своими учениками или привлекайте новых",
      "subtitle2": "Методистам",
      "subtext2": "Создавайте методические пособия для учеников и преподавателей",
      "subtitle3": "Онлайн школам",
      "subtext3": "Создайте свою школу и получайте гарантированный доход",
      "imagetext": "Onclass имеет все необходимое для проведения интерактивных занятий, которые нравятся ученикам"
    },
    "opportunities": {
      "title": "Откройте новые возможности. Развивайтесь вместе с Onclass",
      "step1title": "Шаг 1",
      "step1text": "Занимайтесь со своими учениками",
      "step2title": "Шаг 2",
      "step2text": "Привлекайте новых учеников на onclass",
      "step3title": "Шаг 3",
      "step3text": "Создавайте уроки, платные коллекции или курсы или учебные планы",
      "step4title": "Шаг 4",
      "step4text": "Проводите групповые занятия вебинары или стримы",
      "step5title": "Шаг 5",
      "step5text": "Создайте свою онлайн школу",
      "opporttext1": "Вы создаете анкету (школу/услугу/занятие/курс)",
      "opporttext2": "Мы вас продвигаем и привлекаем учеников",
      "opporttext3": "Вы зарабатываeте деньги"
    },
    "whybetter": {
      "title": "Почему Onclass лучше?",
      "tabtitle1": "Репетиторы",
      "tabtitle2": "Методистам",
      "tabtitle3": "Школы",
      "slide1title1": "Преподавайте любимые предметы",
      "slide1title2": "Занимайтесь со своими учениками",
      "slide1title3": "Привлекайте новых учеников на Onclass",
      "slide1text1": "Преподавайте любые предметы, по своей программе",
      "slide1text2": "Со своими учениками можно расчитываться вне платформы",
      "slide1text3": "Выплата после каждого занятия на вашу карту",
      "slide2title1": "Создавайте учебные программы",
      "slide2title2": "Создавайте уроки",
      "slide2title3": "Привлекайте преподавателей",
      "slide2text1": "Создавайте коллекции уроков по учебным дисциплинам",
      "slide2text2": "Создавайте уроки любого типа в редакторе контента",
      "slide2text3": "Привлекайте преподавателей и продавайте им свои программы",
      "slide3title1": "Возможности для роста",
      "slide3title2": "Свой штат преподавателей",
      "slide3title3": "Дополнительный заработок",
      "slide3text1": "Любой преподаватель который уперся в “потолок” может создать свою школу и удовлетворит свои скрытые амбиции",
      "slide3text2": "Привлекайте своих коллег преподавателей и делайте одно общее дело вместе",
      "slide3text3": "Создание своей школы — это отличный шанс для преподавателей увеличить свой доход",
      "imagetext": "Onclass отлично подходит репетиторам, методистам и тем кто хочет создать свою онлайн- школу."
    },
    "instruments": {
      "title": "Ваши главные инструменты, в достижении ваших целей",
      "col1title": "Конструктор уроков",
      "col1text": "Создавайте уроки и другой контент необходимый для ваших занятий",
      "more": "Подробнее",
      "col2title": "Виртуальный класс",
      "col2text": "Проводите онлайн занятия, используя созданный Вами обучающий контент",
      "col1icon1": "Интерактивный контент",
      "col1icon2": "Совместное прохождение уроков",
      "col1icon3": "Вебинары и стримы",
      "col1icon4": "Домашнее задание",
      "col2icon1": "Отслеживание прогресса",
      "col2icon2": "История занятий",
      "col2icon3": "Групповые занятия",
      "col2icon4": "Автоматичeская проверка заданий"
    },
    "howgetnew": {
      "title": "Как привлекать новых учеников?",
      "imgtext": "На Onclass можно не только заниматься со своими учениками, но и привлекать новых. Пройдя верификацию, вы попадаете в каталог преподавателей. И это бесплатно",
      "step1title": "Расскажите о себе",
      "step1text": "Заполните профиль, добавьте видео и доступные занятия, чтоб ваша личная страница выглядела привлекательно",
      "step2title": "Выберите желаемую ставку",
      "step2text": "На Onclass преподаватели сами выбирают стоимость своих занятий и услуг",
      "step3title": "Составьте график работы",
      "step3text": "Преподавайте в удобное для Вас время, легко совмещайте график с основной работой.",
      "step4title": "Привяжите банковскую карту",
      "step4text": "Получайте выплаты сразу после каждого проведенного занятия прямо на Вашу карту"
    },
    "promoblue": {
      "title": "Преподавайте на Onclass с любимых устройств"
    },
    "digitalcontent": {
      "title": "Создавайте цифровой контент, монетизируйте свои знания",
      "onlinetitle": "Онлайн занятия",
      "onlinetext1": "Популярные занятия",
      "onlinetext2": "Групповые занятия",
      "onlinetext3": "Вебинары",
      "onlinetext4": "Стримы",
      "lessonstitle": "Уроки и контент",
      "lessonstext1": "Курсы",
      "lessonstext2": "Коллекции",
      "lessonstext3": "Учебные планы"
    },
    "mobileapp": {
      "title": "Onclass Messenger",
      "text": "Общайтесь с учениками и получайте необходимые уведомления"
    },
    "reviews": {
      "title": "Станьте частью сообщества Onclass",
      "name1": "Марина А.",
      "lang1": "Английский",
      "text1": "Я недавно присоединился к Onclass и сразу влился в команду! Я всегда получаю необходимую поддержку. Общая атмосфера очень приятная.",
      "name2": "Александр Л.",
      "lang2": "Латынь",
      "text2": "Отличная концепция! Я могу проводить занятия с учениками тогда, когда мне удобно :) Onclass во всем помогает репетиторам.",
      "name3": "Максим Е.",
      "lang3": "Английский",
      "text3": "Я работаю в Onclass репетитором уже 1,5 месяца и мне здесь очень нравится, потому что можно заниматься любимым делом и выстраивать удобный график."
    },
    "promofooter": {
      "title": "Начните преподавать бесплатно",
      "text": "Зарегистрируйтесь на Onclass и начните преподавать на новом уровне",
      "actionbtn": "начать преподавать"
    }
  },
  "landings": {
    "home": {
      "action-student": "Начать как ученик",
      "action-teacher": "Начать как преподаватель",
      "part1-subtitle": "Education world wide – <br/>Your Online School for the 21st Century",
      "part1-title": "Onclass.com - Join millions of learners from around the world",
      "part2-title": "Как это работает?",
      "part2-tooltip1": "Панель инструментов",
      "part2-tooltip2": "Индивидуальные или групповые уроки",
      "part2-tooltip3": "Добавляйте уроки, упражнения, видео и другой контент на интерактивную доску",
      "part2-tooltip4": "Интерактивная доска",
      "part2-tooltip5": "Камера преподавателя",
      "part2-tooltip6": "Список учеников в классе",
      "part2-tooltip7": "Чат для участников",
      "part2-tooltip8": "Камеры участников",
      "part2-var1": "Преподавайте дистанционно в удобное время",
      "part2-var2": "Вы сами решаете, когда вам работать",
      "part3-subtitle": "Вы можете создать свои уроки и упражнения или использовать готовый учебный материал, созданный другими преподавателями",
      "part3-title": "Уроки",
      "part4-card1-title": "Каталог преподавателей",
      "part4-card2-title": "Современные технологии для дистанционного обучения",
      "part4-card3-title": "Создайте блог, привлекайте подписчиков",
      "part4-title": "Возможности",
      "part5-step1": "1) Зарегистрируйтесь на <span>onclass.com</span>",
      "part5-step2": "2) <span>Создайте</span> видео конференцию",
      "part5-step3": "3) Приглашайте учеников и <span>преподавайте!</span>",
      "part5-steps-title": "3 простых шага",
      "part5-var1": "Остались вопросы?",
      "top-promo-title": "Study online on the Onclass platform",
      "top-promo-text": "Виртуальное пространство для онлайн-обучения и поиска репетиторов",
      "top-promo-btn": "Начать учиться",
      "top-promo-list1": "Виртуальный онлайн-класс",
      "top-promo-list2": "Уроки и упражнения",
      "top-promo-list3": "Каталог репетиторов",
      "top-promo-videolink": "Посмотрите видео о платформе",
      "advantages-text1": "Курсы, коллекции и бесплатные уроки",
      "advantages-text2": "Каталог репетиторов",
      "advantages-text3": "Онлайн-занятия в виртуальном классе",
      "who-can-title": "Курсы, коллекции и бесплатные уроки",
      "who-can-slidetitle1": "Студенты",
      "who-can-slidetitle2": "Репетиторы",
      "who-can-slidetitle3": "Методисты",
      "who-can-slidetitle4": "Школы",
      "who-can-slidetext1": "Study any subjects. There are many tutors, courses and online schools at your service.",
      "who-can-slidetext2": "Преподавайте любые предметы, по своей программе. К вашим услугам конструктор уроков, виртуальный класс и многое другое",
      "who-can-slidetext3": "Создавайте методические пособия для учеников и преподавателей. К вашим услугам конструктор уроков, виртуальный класс и многое другое",
      "who-can-slidetext4": "Создайте свою школу и получайте гарантированный доход",
      "who-can-detail": "Подробнее",
      "how-study-title": "Как проходят занятия в виртуальном классе",
      "how-study-list1": "Ученики находят репетиторов по своей дисциплине и записываются к ним на занятия",
      "how-study-list2": "В назначенное время входят в виртуальный класс по ссылке или приглашению",
      "how-study-list3": "Выполняют задания и упражнения, которые репетитор добавляет на доски",
      "how-study-list4": "Просматривают прошедшие занятия, чтобы повторить пройденный материал",
      "how-study-slide1": "Автоматичeская проверка заданий",
      "how-study-slide2": "Домашняя и самостоятельная работа",
      "how-study-slide3": "История занятий в классе",
      "onclass-it-title": "Onclass — это",
      "onclass-it-slide1-title": "Проверенные репетиторы",
      "onclass-it-slide1-text": "Каждый репетитор проходит верификацию и проверку нашими модераторами.",
      "onclass-it-slide1-link": "СМОТРЕТЬ КАТАЛОГ РЕПЕТИТОРОВ",
      "onclass-it-slide2-title": "Различные типы контента",
      "onclass-it-slide2-text": "Интерактивные упражнения, тесты, видео- и аудиоуроки, в том числе собранные в коллекции и курсы.",
      "onclass-it-slide2-link": "СМОТРЕТЬ УРОКИ",
      "onclass-it-slide3-title": "Множество типов занятий",
      "onclass-it-slide3-text": "Групповые и индивидуальные занятия, курсы, вебинары и стримы. Выбирайте то, что вам по душе.",
      "onclass-it-slide3-link": "СМОТРЕТЬ ЗАНЯТИЯ",
      "teach-with-title": "Учитесь на Onclass с любых устройств",
      "teach-with-subtitle": "ПК, планшет или смартфон",
      "teach-with-text": "Сервис Onclass адаптирован под все современные устройства, поэтому вам не придется быть привязанным к одному месту — учитесь в поездках, в очередях и там, где вам удобно.",
      "promo-footer-title": "Начните учиться бесплатно",
      "promo-footer-text": "Зарегистрируйтесь на Onclass и получите доступ к бесплатным материалам",
      "promo-footer-link": "Начать учиться"
    },
    "meet": {
      "var1": "Организуйте свой класс, проводите занятия и конференции онлайн! Обучайте или учитесь, не выходя из дома.",
      "var2": "Создать конференцию"
    }
  },
  "landing-me": {
    "title": "Платформа <br/><span class=\"highlight\">для современного преподавания</span><br/> популярных предметов",
    "sub-title": "Обучение в интерактивном формате",
    "actions": {
      "log-in": "Войти в аккаунт",
      "try-free": "Попробовать бесплатно",
      "period": "30 дней бесплатно"
    },
    "goals": {
      "title": "Главные инструменты для достижения ваших целей",
      "goal-1": {
        "title": "Конструктор уроков",
        "description": "Создавайте уроки и другой контент, необходимый для ваших занятий"
      },
      "goal-2": {
        "title": "Виртуальный класс",
        "description": "Проводите онлайн-занятия, используя созданный вами обучающий контент"
      },
      "list": {
        "title": "А также",
        "goal-1": {
          "title": "Интерактивный контент",
          "description": "Действия учеников на доске и их ответы видны вам в реальном времени"
        },
        "goal-2": {
          "title": "История занятий",
          "description": "Хранится история каждого занятия. Всегда можно вернуться назад."
        },
        "goal-3": {
          "title": "Учебные классы",
          "description": "Возможность объединять учеников в группы для удобства"
        },
        "goal-4": {
          "title": "Групповые занятия",
          "description": "На доске могут одновременно заниматься до 55 человек."
        },
        "goal-5": {
          "title": "Домашняя работа",
          "description": "Возможность выдавать ученикам домашние задания, которые они будут выполнять вне занятия"
        },
        "goal-6": {
          "title": "Автопроверка заданий",
          "description": "Платформа может самостоятельно проверить ответы учеников, чтобы вы не тратили время на проверку."
        }
      }
    },
    "lessons": {
      "title": "Огромная библиотека готовых уроков",
      "description": "Сотни обучающих материалов уже на платформе для ваших уроков. Библиотека регулярно пополняется"
    },
    "constructor": {
      "title": "Конструктор обучающих материалов",
      "description": "Конструктор уроков и упражнений, необходимых для ваших занятий. Комбинируйте различные типы упражнений, создавая интересные уроки.",
      "exercises": "Примеры уроков и упражнений"
    },
    "dictionary": {
      "title": "Личный словарь",
      "description": "Подойдет для преподавателей иностранных языков. Ведите свой словарь или помогайте вести его вашим ученикам. Полная свобода"
    },
    "dashboard": {
      "title": "Обзор",
      "description": "Все самое актуальное всегда под рукой. Ближайшие занятия, ученики, учебные классы и домашние задания"
    },
    "timetable": {
      "title": "Расписание занятий",
      "description": "Планируйте свое рабочее время и следите за расписанием занятий. Вы будете видеть все прошедшие и предстоящие занятия"
    },
    "devices": {
      "title": "Платформа доступна на всех устройствах",
      "description": "Сервис Onclass адаптирован под все современные устройства, поэтому вам не придется быть привязанным к одному месту – учитесь в поездках, очередях и там, где вам удобно"
    },
    "reviews": {
      "title": "Отзывы преподавателей, которые уже познакомились с платформой Onclass",
      "experience": "года стажа"
    },
    "plans": {
      "title": "Тарифы"
    }
  },
  "language-levels": {
    "basic": "Основы",
    "fluent": "Свободно",
    "native": "Родной язык",
    "read": "Только чтение",
    "read-write": "Чтение и письмо",
    "speak": "Говорю"
  },
  "language-selector": {
    "choose-language": "Выберите язык"
  },
  "currency-selector": {
    "choose-currency": "Выберите валюту"
  },
  "lesson-complain": {
    "cause": "Причина",
    "cause1": "Рассылка спама",
    "cause10": "Жестокое обращение с животными",
    "cause11": "Введение в заблуждение",
    "cause12": "Мошенничество",
    "cause13": "Экстремизм",
    "cause2": "Оскорбление",
    "cause3": "Материал для взрослых",
    "cause4": "Детская порнография",
    "cause5": "Пропаганда наркотиков",
    "cause6": "Продажа оружия",
    "cause7": "Насилие",
    "cause8": "Призыв к травле",
    "cause9": "Призыв к суициду",
    "comments-label": "Комментарий",
    "comments-label-options": "Необязательно",
    "comments-placeholder": "Опишите проблему",
    "complain-title": "Жалоба на урок",
    "send": "Отправить",
    "slide": "слайд"
  },
  "lessons": {
    "meta-favorites-title": "Избранные",
    "meta-last-viewed-title": "Последние просмотренные",
    "meta-mine-title": "Мои",
    "meta-popular-title": "Популярные",
    "meta-recently-created-title": "Недавно добавленные",
    "meta-title": "Уроки",
    "page-header-title": "Уроки"
  },
  "library": {
    "meetings-stack": {
      "title": "Занятия",
      "text": "Вебинары, стримы, групповые и индивидуальные занятия",
      "action": "Запланировать занятие"
    },
    "user-services-stack": {
      "title": "Индивидуальные занятия",
      "text": "Индивидуальные занятия c преподавателями",
      "action": "Добавить занятие",
      "exception": "Только для верифицированных преподавателей",
      "details": "Подробнее о верификации"
    },
    "base-pages": {
      "lessons": "Уроки",
      "classes": "Занятия",
      "tutors": "Репетиторы",
      "collections": "Коллекции",
      "schools": "Школы",
      "courses": "Курсы"
    },
    "breadcrumbs": {
      "content-type": "Категория:",
      "home": "Главная",
      "language": "Язык контента:",
      "subject": "Дисциплина:",
      "classroom_for": "Класс для:"
    },
    "cancel-action": "Отмена",
    "card-action-start-lesson": "Начать урок",
    "card-media-audio-not-ready": "Подкаст ещё не готов",
    "card-tabs-lesson-about": "Личная информация",
    "card-tabs-lesson-content": "Содержание",
    "card-tabs-lesson-exercises": "Упражнения",
    "categories": "Категории",
    "content-filter": {
      "create": "Создать урок",
      "sort": "Сортировать",
      "choose-sorting": "Выберите сортировку",
      "order": {
        "date_asc": "Сначала старые",
        "date_desc": "Сначала новые",
        "popularity": "Популярные",
        "popularity_week": "Популярные за неделю",
        "popularity_year": "Популярные за все время"
      },
      "see-all": "Показать ещё",
      "stub-subject-text": "По данной дисциплине еще нет уроков",
      "tab-all": "Все",
      "tab-favorites": "Избранные",
      "tab-foreign": "Иностранные языки",
      "tab-native": "Родные языки",
      "tab-new": "Новые",
      "tab-others": "Другие дисциплины",
      "tab-popular": "Популярные",
      "title": "Дисциплины",
      "total": "Уроков:",
      "total-individual-lessons": "Занятий:",
      "total-teachers": "Репетиторов:"
    },
    "exercises": "Упражнения",
    "favorites-title": "Избранные",
    "filter-cleaning": {
      "clean-all": "Сбросить фильтр",
      "subtitle": "Кажется, мы не можем найти то, что Вы ищете. Перейдите в другой раздел или сбросьте фильтр.",
      "title": "Результатов не найдено"
    },
    "filter-widget": {
      "add-filter": "Добавить фильтр",
      "apply": "Применить фильтр",
      "category": "Категории",
      "clear": "Сбросить фильтр",
      "foreign": "Иностранные языки",
      "header-text": "Выбрано фильтров:",
      "header-title": "Фильтр",
      "language": "Язык контента",
      "level": "Уровень",
      "native": "Родные языки",
      "non_categorized": "Другие дисциплины",
      "not-found": "Не найдено",
      "placeholders": {
        "category": "Поиск категории",
        "language": "Поиск языка",
        "level": "Поиск по уровню",
        "subject": "Поиск дисциплины"
      },
      "popularity": "Популярность",
      "subject": "Subject"
    },
    "form-term-placeholder": "Поиск...",
    "form-term-placeholder-new": "Что вы хотите найти?",
    "free-date-banner": {
      "action": "Создать класс",
      "action2": "Посмотреть репетиторов",
      "text-part1": "Нет запланированных уроков",
      "text-part2": "создать свое",
      "text-part3": "и оно будет первым в списке",
      "text2": "Однако, Вы можете записаться к понравившемуся преподавателю на индивидуальное занятие",
      "title": "Свободная дата",
      "title2": "Публичных мероприятий не запланировано"
    },
    "lesson-of-the-day": "Упражнение дня",
    "meeting-card": {
      "date": "Дата:",
      "detailed": "Читать далее",
      "free": "Бесплатно",
      "participation": "Участие:",
      "types": {
        "group": "Групповой урок:",
        "single": "Приватный урок:",
        "stream": "Трансляция:",
        "webinar": "Вебинар:"
      }
    },
    "meeting-drag-scroll": {
      "today": "Сегодня",
      "tomorrow": "Завтра"
    },
    "menu-complain": "Пожаловаться",
    "menu-edit": "Редактировать",
    "menu-remove": "Удалить",
    "menu-share": "Поделиться",
    "menu-more": "Подробнее",
    "menu-bookmark": "Закладка",
    "menu-unsubscribe": "Отписаться",
    "meta-favorites-title": "Избранные",
    "meta-last-viewed-title": "Последние просмотренные",
    "meta-mine-title": "Мои",
    "meta-popular-title": "Популярные",
    "meta-recently-created-title": "Недавно добавленные",
    "meta-title": "Уроки",
    "meta-vocabulary-terms": "Learn",
    "no-image-text": "Нет изображения",
    "not-found.meta-title": "Страница не найдена",
    "page-header-title": "Уроки",
    "pagination-more": "Загрузить еще",
    "publication-removed-text": "Публикация удалена.",
    "publication-restore-action": "Восстановить?",
    "read-article": "Читать статью",
    "read-hide": "свернуть",
    "read-more": "читать далее",
    "related-items-title": "Другое",
    "share-link-widget": {
      "link": "Копировать ссылку",
      "text1": "Поделись с друзьями",
      "text2": "Поделитесь уроком в соц сетях",
      "title1": "Нравится? Поделись ссылкой!",
      "title2": "Поздравляем! Ваш урок опубликован."
    },
    "show-more": "Показать ещё",
    "subtitles": {
      "podcasts": "Подкасты",
      "popular": "Популярные упражнения за неделю",
      "video": "Видео",
      "what-to-read": "Чтение"
    },
    "tabs-all": "Все",
    "tabs-favorites": "Избранные",
    "tabs-mine": "Мои",
    "teacher-drag-scroll": {
      "best-of-week": "Лучшие за неделю",
      "take-up-teach": "Начать преподавать",
      "title": "Репетиторы"
    },
    "titles": {
      "categories": "Типы контента",
      "exercises": "Уроки",
      "gallery": "Изображения",
      "goback": "Назад",
      "lesson-of-the-day": "Упражнение дня",
      "lessons": "Уроки",
      "collections": "Коллекции",
      "services": "Доступные занятия",
      "lessons-from-teacher": "Другие уроки репетитора",
      "media": "Медиа",
      "meetings": "Онлайн занятия",
      "most-popular": "Популярное",
      "popular": "Популярные уроки",
      "recent-teachers": "Новые репетиторы",
      "recently": "Недавно добавленные",
      "recently-created": "Недавно добавленные",
      "recently-viewed": "Последние просмотренные",
      "similar": "Похожие уроки",
      "teacher-of-the-day": "Репетитор дня",
      "teachers": "Репетиторы",
      "types-of-study": "Типы занятий",
      "what-to-listen": "Что послушать?",
      "what-to-read": "Что почитать?",
      "what-to-see": "Что посмотреть?",
      "popular-classes": "Популярные занятия",
      "best-teachers": "Лучшие преподаватели",
      "popular-teachers": "Популярные репетиторы",
      "other-lessons": "Другие уроки",
      "popular-collections": "Популярные коллекции",
      "popular-schools": "Популярные школы",
      "best-collections": "Лучшие коллекции",
      "best-schools": "Best schools",
      "other-collections": "Другие коллекции",
      "other-schools": "Другие школы",
      "other-tutors": "Другие репетиторы",
      "other-classes": "Другие занятия",
      "other2-collections": "Другие коллекции",
      "other2-tutors": "Другие репетиторы",
      "other2-classes": "Другие занятия",
      "other2-lessons": "Другие уроки",
      "lessons-other-subjects": "Уроки по другим предметам",
      "collections-other-subjects": "Коллекции по другим предметам",
      "schools-other-subjects": "Школы по другим предметам",
      "tutors-other-subjects": "Репетиторы по другим предметам",
      "classes-other-subjects": "Занятия по другим предметам"
    },
    "update-action": "Update",
    "user-card": {
      "from": "от",
      "highest-mark": "Высшая оценка",
      "lesson": "занятий",
      "no-free-time": "Недоступен",
      "price": "Цена за час:",
      "send": "Связаться с репетитором",
      "sign": "Записаться",
      "spoken": "Говорит:",
      "teach": "Преподает:",
      "title-action": "Как стать преподавателем дня?"
    },
    "user-mini-card": {
      "duration": "на Onclass.com",
      "from": "от",
      "lessons": "уроков",
      "meetings": "занятий",
      "on-request": "по запросу",
      "price": "за занятие",
      "teaching": "Преподает:",
      "speak-on": "Говорю на:",
      "rating": "Рейтинг репетитора:"
    },
    "user-service-card": {
      "discount": "Скидка",
      "hour": "час",
      "lessons-count": "Занятий:",
      "native": "Родной язык",
      "reviews": "отзывов",
      "no-reviews": "Отзывов пока нет.",
      "unavailable": "Недоступен",
      "sign-up": "Записаться",
      "sign-up-for-a-lesson": "Записаться",
      "subscribe": "Подписаться",
      "cancel-subscription": "Отменить подписку",
      "renew_subscription": "Возобновить подписку",
      "access_to_lesson_will_open": "Доступ к занятию откроется",
      "type": {
        "single": "Одно занятие",
        "regular": "Регулярные занятия",
        "mini": "Мини - курс",
        "of": "из",
        "course": "Курс"
      },
      "one-on-one": "1 на 1",
      "group": "Групповое",
      "entry-level": "Начальный уровень",
      "min": "мин",
      "label-period": {
        "single": "за занятие",
        "regular": "в неделю",
        "mini": "за 1 занятие",
        "course": "за 1 занятие"
      },
      "status": {
        "on-moderation": "На модерации",
        "archived": "Удалённые",
        "published": "Опубликованные",
        "declined": "Услуга не прошла модерацию"
      },
      "stats": {
        "tutor": "Репетитор",
        "rating": "Рейтинг",
        "classes": "Онлайн-занятия"
      }
    },
    "header.actions": {
      "lessons": "Создать урок",
      "teachers": "Стать преподавателем",
      "study": "Запланировать занятие",
      "collections": "Собрать свою коллекцию"
    }
  },
  "loading-line.text": "Загрузка занимает чуть больше времени, чем обычно. <br> Подождите ещё немного, пожалуйста.",
  "main.meta-title": "Главная",
  "main.page-header-title": "Главная",
  "media-gallery": {
    "input-search-placeholder": "Поиск в интернете",
    "online-search-placeholder": "Напишите что-нибудь в строке поиска и нажмите enter",
    "tab-favorites": "Избранные",
    "tab-from-web": "Онлайн",
    "tab-recent": "Недавние",
    "tab-upload": "Загрузить",
    "warning-button": "Закрыть",
    "warning-title": "Предупреждение"
  },
  "meeting-review-form": {
    "comment-placeholder": "Пожалуйста, оставьте отзыв о занятии",
    "fail": "Сообщение менее 50 символов",
    "increased": "Рейтинг преподавателя будет увеличен.",
    "label-rating": "Оцените занятие",
    "post-review": "Отправить отзыв",
    "complain": "Пожаловаться",
    "question": "Пожалуйста, оцените ваш класс вместе с репетитором и оставьте комментарий",
    "recommend": "Рекомендовать преподавателя",
    "sign-again": "Записаться еще раз",
    "thanks": "Спасибо за ваш отзыв!"
  },
  "meeting-complaint-form": {
    "title": "Жалоба на преподавателя",
    "text": "Пожалуйста, сообщите нам причину, по которой вы остались недовольны нашим сервисом.",
    "comment-moderator": "Комментарий модератору",
    "send-complaint": "Отправить жалобу",
    "sent-text": "Мы рассмотрим Вашу жалобу в течение 3 дней, и свяжемся с Вами по указанной в анкете электронной почте",
    "sent-text-check": "Проверить, указана ли почта, можно по этой ссылке",
    "error-text": "Что-то пошло не так!",
    "again": "Попробовать еще раз"
  },
  "meeting-type": {
    "group": "Групповое занятие:",
    "single": "Индивидуальное занятие:",
    "stream": "Стрим",
    "webinar": "Вебинар"
  },
  "meetings": {
    "additionally": "дополнительно",
    "archived": "Удалённые",
    "buy-credits-action": "Купить кредиты",
    "calendar": "Календарь",
    "group_meeting": "Групповое занятие",
    "student": "Ученик:",
    "tutor": "Репетитор:",
    "write": "Написать",
    "write-to-chat": "Написать в чат",
    "chrome-text": "Для бесперебойной работы класса рекомендуем установить браузер Chrome.",
    "invite": {
      "emails-error": "Некоторые электронные адреса некорректны"
    },
    "complaint": {
      "rejected": "Complaint was rejected",
      "resolved": "Complaint was resolved"
    },
    "drafts": "Черновики",
    "dz-placeholder-link1": "выбрать",
    "dz-placeholder-link2": "для выбора",
    "dz-placeholder-text": "Перетащите обложку или нажмите кнопку",
    "edit": "Редактировать",
    "edit-cover": "Редактировать обложку",
    "form-access-label": "Доступ",
    "form-access-private": "Приватный",
    "form-access-public": "Публичный",
    "form-balance-label": "Баланс",
    "form-balance-note": "Ваш баланс:",
    "form-carrying-possible": "Проведение занятия возможно",
    "form-change-title": "Changing a classroom",
    "form-charging-label": "Стоимость участия",
    "form-chrome-text": "Для стабильной работы класса установите Chrome браузер.",
    "form-create-action": "Создать",
    "form-credits": "кредитов",
    "form-description-placeholder": "Описание",
    "form-download": "Скачать",
    "form-fee-note": "Безопасная сделка, студент оплачивает занятие через платформу Onclass.com",
    "form-level-label": "Уровень",
    "form-level-placeholder": "Выбери уровень",
    "form-not-credits": "Проведение занятия невозможно",
    "form-number-participants-label": "Количество учеников",
    "form-participants-limit-warn": "Не выбрано",
    "form-participants_limit-label": "Количество учеников",
    "form-participation-free": "Бесплатно",
    "form-participation-label": "Стоимость участия",
    "form-participation-paid": "Платно",
    "form-price-label": "Стоимость участия",
    "form-price-placeholder": "стоимость",
    "form-price-tier-warn": "Не выбрано",
    "form-private-access-note": "Приглашение учеников в класс по ссылке",
    "form-public-access-note": "Публикуется в каталоге классов или приглашение по ссылке",
    "form-required": "Требуется:",
    "form-save-action": "Сохранить",
    "form-subject-warn": "Выбор дисциплины обязателен",
    "form-term-placeholder": "Поиск...",
    "form-time-label": "Дата и Время",
    "form-timezone-label": "Часовой пояс",
    "form-timezone-message": "В вашем часовом поясе",
    "form-title": "Создание конференции",
    "form-title-placeholder": "Название класса",
    "form-title-warn": "Поле обязательно для заполнения",
    "form-to-calendar": "Добавить в календарь",
    "form-type-calc-label": "Тип расчета",
    "form-valid-message-access": "Выберите доступ",
    "form-valid-message-charging": "Стоимость участия",
    "form-valid-message-time-busy": "This time is busy",
    "form-valid-message-wrong": "Укажите другое время.",
    "form-valid-message1": "Вы не выбрали дисциплину",
    "form-valid-message2": "Поле должно быть заполнено",
    "hint": {
      "buttons": {
        "check": "Проверить камеру",
        "create": "Создать класс",
        "schedule": "Запланировать занятие"
      },
      "faq": "Основные вопросы",
      "questions": {
        "add-lesson": "Как добавить урок на доску?",
        "add-participant": "Как пригласить участника?",
        "add-whiteboard": "Как добавить новую доску?",
        "chat": "Где находится чат?",
        "settings": "Где находятся настройки?",
        "start-class": "Как начать занятие?",
        "tools": "Где найти панель инструментов?"
      },
      "tabs": {
        "exercise": "Упражнение",
        "img-pres": "Картинки, презентации",
        "podcasts": "Подкасты",
        "theory": "Теория",
        "video": "Видео",
        "vocabulary": "Словарь",
        "whiteboard": "Доска"
      },
      "tooltip": "Создавайте и добавляйте свои уроки или используйте готовый контент из библиотеки: упражнения, видео/подкасты, теория и многое другое.",
      "watch-video": "Смотрите видео по работе в конференции"
    },
    "join": {
      "accept-meeting": "Принять приглашение",
      "accept-paid-meeting": "Оплатить и принять",
      "auth_required": "Авторизация...",
      "canceled": "Занятие было отменено",
      "canceled-meeting-title": "Занятие отменено",
      "book-again-title": "Время истекло, попробуйте записаться еще раз",
      "checkout-paid-meting-title": "Записаться на платное занятие",
      "error": "Произошла техническая ошибка, пожалуйста, закройте окно и повторите попытку",
      "finished-meeting-title": "Занятие завершено",
      "joining": "Подключение к конференции...",
      "loading": "Загрузка конференции...",
      "meeting-title": "Вас пригласили на занятие",
      "no-more-places-to-join": "No more places to join",
      "not_found": "Невозможно подключиться. Конференция не найдена. Проверьте ссылку, возможно там опечатка.",
      "open": "Открыть класс",
      "min": "мин",
      "free": "Бесплатно",
      "per_meet": "за занятие",
      "copy_link": "скопировать",
      "invite_link": "Пригласительная ссылка",
      "opening": "Конференция открывается...",
      "opening-timeout-title": "Нажми тут если окно занятия не открылось",
      "payment-in-process": "Платеж обрабатывается...",
      "paid-meeting-title": "Вас пригласили на платное занятие",
      "participants_limit-meeting-title": "Impossible to join. The limit of the participants is reached.",
      "qr-code-note": "Отсканируйте этот QR-код, чтобы войти на занятие с помощью смартфона или планшета"
    },
    "join-action": "Записаться в класс",
    "lesson": "занятий",
    "meeting-not-found": "Конференция не найдена",
    "menu-complain": "Пожаловаться",
    "menu-edit": "Редактировать",
    "menu-invite": "Приглашение",
    "menu-reschedule": "Изменить дату и время",
    "menu-share": "Поделиться",
    "menu-cancel-meeting": "Отмена",
    "menu-leave-meeting": "Покинуть конференцию",
    "menu-break-contract": "Отмена",
    "menu-unsubscribe": "Отписаться",
    "meta-create-title": "Создать учебный класс",
    "meta-favorites-title": "Избранные",
    "meta-form-title": "Редактор",
    "meta-mine-title": "Мои",
    "meta-title": "Учеба",
    "no-image-text": "Нет изображения",
    "open-action": "Открыть класс",
    "or": "or",
    "other": "Другое",
    "page-favorites-title": "Избранные",
    "page-header-title": "Учеба",
    "page-mine-title": "Мои",
    "paid-join-action": "Оплатить и принять",
    "participants-count": "Участников:",
    "participants-left": "(осталось {{participantsLeft}} места)",
    "participants-limit-placeholder": "Выбрать",
    "participants_limit-1": "1",
    "participants_limit-10": "2-12",
    "participants_limit-100": "до 100",
    "participants_limit-1000": "до 1000",
    "participants_limit-2": "2",
    "participation-free": "Бесплатно",
    "participation-price": "Стоимость участия:",
    "plan-upgrade-action": "обновить тариф",
    "published": "Опубликованные",
    "read-hide": "свернуть",
    "read-more": "читать далее",
    "redeem-coupon-action": "активировать купон",
    "reschedule_date": "Новая дата:",
    "reservation_time_left": "Осталось времени:",
    "select-area": "Выбрать область",
    "skills-title": "Пройдя это курс, вы узнаете:",
    "status": {
      "accept_required": "- Получено приглашение от преподавателя",
      "accept_required.any-role.accepted": "Ожидание подтверждения",
      "accept_required.any-role.declined": "Приглашение принято",
      "accept_required.any-role.not_required": "Ожидание подтверждения",
      "accept_required.owner.accept_required": "Получено приглашение от студента",
      "accept_required.owner.accepted": "Ожидает подтверждения ученика",
      "accept_required.participant.accept_required": "Получено приглашение от преподавателя",
      "accept_required.participant.accepted": "Ожидание подтверждения",
      "accept_required.participant.paid_accept_required": "Эта конференция требует оплаты, прежде чем принять",
      "accept_required.reservation.accept_required": "Получено приглашение от преподавателя",
      "accept_required.reservation.accepted": "Ожидает подтверждения учителем",
      "accept_required.reservation.paid_accept_required": "Эта конференция требует оплаты, прежде чем принять",
      "accepted.owner.expired": "Срок действия приглашения истек",
      "accepted.owner.reserved": "Ожидание оплаты",
      "accepted.participant.expired": "Срок действия приглашения истек",
      "accepted.reservation.expired": "Срок действия приглашения истек",
      "accepted.reservation.reserved": "Оплатите участие в ближайшие",
      "cancel_required": "This meeting is canceling",
      "canceled": "Занятие отменено",
      "finished": "Общение",
      "paid_accept_required": "- This meeting require payment before accept",
      "reschedule_requested": "- A time reschedule request has been sent",
      "reschedule_required": "- Time reschedule was requested",
      "started.participant.accept_required": "Получено приглашение от преподавателя",
      "started.participant.paid_accept_required": "Эта конференция требует оплаты, прежде чем принять",
      "started.reservation.paid_accept_required": "Эта конференция требует оплаты, прежде чем принять"
    },
    "tabs-all": "Все",
    "tabs-favorites": "Избранные",
    "tabs-mine": "Мои",
    "teacher": "Преподаватель:",
    "titles": {
      "for_all": "Для всех",
      "exam_training": "Подготовка к экзамену",
      "for_children": "Для детей",
      "other": "Другие занятия",
      "personal": "Индивидуальные занятия",
      "speaking": "Разговорная практика",
      "master_class": "Мастер класс",
      "discussion": "Дискуссия"
    },
    "types": {
      "single": "Индивидуальное",
      "group": "Групповое",
      "webinar": "Вебинар",
      "stream": "Стрим",
      "talk": "Общение"
    },
    "to-write": "Сообщение",
    "to-write-link": "Написать сообщение",
    "entry-level": "Начальный уровень",
    "classes": "Онлайн-занятия",
    "per_class": "за занятие",
    "value": "Стоимость:",
    "wizard": {
      "average-rating": "Рейтинг учителя:",
      "cant-pay": "Преподаватель не может принимать оплату, {{ term }} для решения данного вопроса.",
      "cant-pay-term": "свяжитесь с преподавателем",
      "no-payment-methods": "Добавьте способ оплаты, чтобы оплатить это занятие",
      "classes": "занятий",
      "complete": "Завершить",
      "delivered": "Проведено:",
      "next": "Далее",
      "pay": "Оплатить",
      "pay-fail": "Оплата не прошла!",
      "pay-success": "Оплата прошла успешно!",
      "add-card-success": "Банковская карта привязана!",
      "select-payment": "Выберите способ оплаты",
      "teacher": "Репетитор:",
      "try-again": "Попытаться еще",
      "no-services": "Учитель еще не добавил доступные в рамках этой дисциплины занятия",
      "value": "Стоимость",
      "choose-teacher": "Выберите преподавателя",
      "course": {
        "choose-group": "Выберите группу",
        "free-places": "Свободных мест",
        "see-all": "См. все",
        "choose": "Выбрать",
        "sign-in": "Перейти в класс",
        "no-places": "Мест нет",
        "purchase-incomplete": "Покупка не завершена",
        "proceed-purchase": "Перейти к покупке",
        "proceed-to-checkout": "Перейти к оплате"
      }
    },
    "subscription_suspended": "Подписка на это занятие была приостановлена",
    "next_meeting": "Следующее занятие:",
    "renew_subscription": "Возобновить подписку"
  },
  "alerts": {
    "reschedule": {
      "title": "Перенести занятие",
      "description": "Новое время занятия согласовано с учениками?",
      "close": "Не согласовано",
      "confirm": "Согласовано"
    }
  },
  "menu": {
    "landing-footer": {
      "about": "Личная информация",
      "app-full-name": "Onclass | 2020",
      "classes": "Conferences",
      "library": "Уроки",
      "privacy": "Privacy Policy",
      "public": "Пользовательское соглашение",
      "sales": "Sales and Refunds",
      "teachers": "Репетиторы",
      "terms": "Условия использования"
    },
    "landing-top": {
      "classes": "Учеба",
      "library": "Уроки",
      "signin": "Вход / Регистрация",
      "teachers": "Репетиторы"
    },
    "left-sidebar": {
      "all-subjects": "Все предметы",
      "auth": "Вход / Регистрация",
      "classrooms": "Учебные классы",
      "collections": "My content",
      "community": "Friends/groups",
      "constructor": "Lesson editor",
      "content-manager": "Менеджер контента",
      "create": "Создать урок",
      "create-content-link": "Создать урок",
      "dashboard": "Дашборд",
      "events": "Занятия",
      "faq": "Основные вопросы",
      "favorites": "Избранные",
      "foreign": "Иностранные языки",
      "messages": "Чат",
      "news": "Все новости",
      "non-categorized": "Другие дисциплины",
      "not-found-text": "No lessons have been found for this discipline. Create the first lesson in this discipline!",
      "notifications": "Уведомления",
      "profile": "Профиль",
      "request": "Request",
      "schedule": "Календарь",
      "schools": "Школы",
      "search-placeholder": "Search subject",
      "settings": "Настройки",
      "signout": "Выйти",
      "study-create": "Конференция",
      "support": "Поддержка",
      "tasks": "Домашнее задание",
      "teachers-room": "Tutor's office",
      "users": "Пользователи",
      "vocabulary": "Словарь",
      "my-feed": "Моя лента",
      "my-classes": "Мои занятия",
      "my-library": "Моя библиотека",
      "my-vocabulary": "Мой словарь",
      "schedule-class-catalog": "Создать занятие в каталоге",
      "create-classroom": "Создать учебный класс",
      "create-lesson": "Создать урок",
      "create-collection": "Создать коллекцию",
      "create-school": "Создать школу",
      "classes-submenu": {
        "conversation-clubs": "Conversation clubs",
        "courses": "Курсы",
        "group-lessons": "Групповые занятия",
        "groups": "Группы",
        "lesson-collections": "Lesson Collections",
        "marathons": "Марафоны",
        "private-lessons": "Private lessons",
        "schools": "Школы",
        "streams": "Стримы",
        "webinars": "Вебинары"
      }
    },
    "top": {
      "classes": "Занятия",
      "dashboard": "Дашборд",
      "feed": "Лента",
      "library": "Уроки",
      "main": "Главная",
      "teachers": "Репетиторы",
      "chat": "Чат"
    },
    "content-tabs": {
      "main": "Главная",
      "lessons": "Уроки",
      "collections": "Коллекции",
      "courses": "Курсы"
    }
  },
  "my-vocabulary": {
    "page-header-title": "Мой словарь",
    "tabs": {
      "main": "Главная",
      "lists": "Списки",
      "learned": "Выучено",
      "students": "Ученики",
      "requires-study": "Требует изучения",
      "added-words": "Добавлено слов"
    },
    "word": "Слово",
    "words": "Слов",
    "selected-words": "Выбрано слов",
    "translation": "Перевод",
    "dictionaries": "Словарей",
    "action": {
      "add-word": "Добавить слово",
      "select-all": "Выбрать всё",
      "clear-all": "Очистить всё",
      "select-all-students": "Выбрать всех",
      "delete": "Удалить",
      "move": "Переместить",
      "add-word-to-list": "Добавить слова в список",
      "new-list": "Новый список",
      "edit": "Редактировать",
      "create-lesson": "Создать урок из слов",
      "duplicate": "Дублировать",
      "save": "Сохранить",
      "ready": "Готово"
    },
    "create-list": "Создать список",
    "edit-list": "Редактировать список",
    "move-words": "Перенести слова",
    "to-your-list": "В свой список",
    "to-student-dictionary": "В словарь ученика",
    "title": "Название",
    "all-dictionaries": "Все словари",
    "choose-dictionary": "Из какого словаря создать урок?",
    "empty-vocabulary": {
      "title": "Словарь пуст",
      "info": "Добавьте слово или фразу"
    },
    "empty-list": {
      "title": "Списков нет",
      "info": "Создайте новый список"
    },
    "empty-students": "У вас нет учеников, для управления их словарями"
  },
  "new-subject-banner": {
    "action": "Создать урок",
    "text1": "Не нашли дисциплину, которую преподаете?",
    "text2": "Создать",
    "text3": "новую дисциплину и упражнение или другой контент",
    "text4": "в конструкторе уроков"
  },
  "news": {
    "get-started": {
      "informer1": {
        "link": "Открыть конструктор",
        "text": "Создавайте уроки и другой обучающий контент для Ваших онлайн занятий.",
        "title": "Редактор уроков"
      },
      "informer2": {
        "link": "Открыть каталог",
        "text": "Заполните анкету преподавателя, расскажите о себе и Ваших занятиях",
        "title": "Каталог преподавателей"
      },
      "informer3": {
        "link": "Открыть класс",
        "text": "Проводите онлайн - занятия в классе с учениками на интерактивной доске",
        "title": "Конференция"
      },
      "informer4": {
        "link": "Открыть урок",
        "text": "Бесплатные уроки и другой контент чтобы подтянуть знания по дисциплинам",
        "title": "Библиотека уроков"
      },
      "informer5": {
        "link": "Открыть каталог",
        "text": "Ищите репетиторов, чтобы подготовится к экзаменам или сделать домашнюю работу",
        "title": "Найдите своего репетитора"
      },
      "informer6": {
        "link": "Отправить ссылку",
        "text": "Есть свой преподаватель? Пригласите его на платформу Onclass и занимайтесь здесь.",
        "title": "Конференция"
      },
      "title": "Как начать преподавать на Onclass?",
      "title-student": "Интересная учеба на Onclass"
    },
    "page-header-title": "Главная",
    "teacher-card": {
      "from": "От",
      "price": "за занятие",
      "teaches": "Преподает:"
    },
    "teacher-drag-scroll": {
      "title": "Новые репетиторы на Onclass",
      "title-right-link": "Начать преподавать"
    },
    "welcome": {
      "text": "Это раздел новостей. Здесь будут публиковаться рекомендации по работе с Onclass, обновления и анонсы различных мероприятий. Скоро все будет выглядеть гораздо интереснее!",
      "title": "Привет,"
    }
  },
  "no-content-stub": {
    "library": {
      "favorites": "У вас нет избранных уроков. Добавьте урок в избранное нажав иконку на карточке урока",
      "favorites-link": "Смотреть уроки",
      "my": "Во вкладке “Мои” отображаются уроки, созданные лично вами и прошедшие модерацию. Создайте свой первый урок.",
      "my-link": "Создать урок"
    },
    "meetings": {
      "favorites": "У вас нет избранных занятий. Добавьте занятие в избранное нажав иконку на карточке",
      "favorites-link": "Смотреть каталог",
      "my": "В этой вкладке будут отображаться занятия в которых вы принимали участие или созданные лично вами.",
      "my-link": "Создать конференцию"
    },
    "teachers": {
      "favorites": "У вас нет избранных преподавателей. Добавьте преподавателя в избранное нажав иконку на карточке преподавателя",
      "favorites-link": "Смотреть каталог",
      "my": "Во вкладке Мои будут отображаться преподаватели, с которыми у вас проходили занятия."
    },
    "users": {
      "students": "У тебя еще нет своих учеников. На вкладке \"Мои ученики\" будут отображены все, кого вы когда либо приглашали на свои онлайн-занятия",
      "subscribers": "Будьте активны, создавайте увлекательные уроки и проводите онлайн-занятия, чтобы привлечь своих первых подписчиков",
      "subscriptions": "Следите за новостями своих друзей и учителей. В будущем на этой вкладке вы сможете видеть всех, на кого подписаны",
      "subscribers-student": "У вас нет подписчиков",
      "subscriptions-student": "У вас нет никаких подписок"
    }
  },
  "not-found": {
    "action-go-main": "На главную",
    "balloon": "Кажется, в нашем классе что-то пошло не так...",
    "description": "Страница не найдена или еще не создана",
    "meta-title": "Страница не найдена",
    "page-title": "Страница не найдена",
    "title": "Ошибка"
  },
  "not-verified-widget": {
    "about-subscription": "Подробнее о подписке",
    "description-1": "Не проверено",
    "description-2": "Профиль подойдет вам, если вы планируете заниматься только со своими учениками и не хотите публичности.",
    "description-3": "Проверено",
    "description-4": "Преподавателям доступен весь функционал нашей образовательной платформы.",
    "text-1": "Вы можете проводить занятия в конференции со своими учениками;",
    "text-2": "Доступ к вашим занятиям только по пригласительной ссылке;",
    "text-3": "Доступно проведение 5 занятий в месяц;",
    "text-4": "Ваш профиль преподавателя не публикуется в каталоге;",
    "title-1": "Ваш профиль",
    "title-2": "не подтвержден"
  },
  "notifications": {
    "content_comment": {
      "text": "{{user}} оставил комментарий к вашей публикации {{title}}",
      "text_post": "{{user}} commented your {{title}}",
      "post": "post",
      "title": "Новый комментарий"
    },
    "content_like": {
      "text": "Ваша публикация {{title}} понравилась пользователю {{user}}",
      "text_post": "{{user}} liked your {{title}}",
      "post": "post",
      "title": "Новый лайк"
    },
    "meeting_invite": {
      "accept": "Принять",
      "decline": "Отклонить",
      "action": "Войти в класс",
      "text": "Преподаватель {{teacher}} приглашает вас на занятие {{title}}",
      "title": "Приглашение на занятие"
    },
    "meeting_purchase_was_canceled": {
      "text": "Вы не оплатили занятие {{title}}. Пожалуйста, попробуйте записаться на него повторно",
      "title": "Время оплаты истекло"
    },
    "meeting_reservation": {
      "text": "У вас есть неоплаченное занятие {{title}}",
      "title": "Ожидание оплаты",
      "action": "Перейти к оплате"
    },
    "teacher_rescheduled_meeting": {
      "text": "Преподаватель {{teacher}} перенес занятие {{title}} с {{old}} на новое время: {{new}}",
      "title": "Перенос занятия"
    },
    "meeting_started": {
      "action": "Войти в класс",
      "text": "Началось индивидуальное занятие с преподавателем {{teacher}}",
      "text_group": "Групповое занятие {{title}} с преподавателем {{teacher}} началось",
      "title": "Начало занятия"
    },
    "my_meeting_started": {
      "action": "Войти в класс",
      "text": "Началось индивидуальное занятие с учеником {{participant}}",
      "text_group": "Началось групповое занятие {{title}}",
      "title": "Начало занятия"
    },
    "profile_declined": {
      "action": "Перейти в профиль",
      "text": "Ваш профиль преподавателя не подтвержден",
      "title": "Верификация"
    },
    "profile_verified": {
      "action": "Доступны новые функции",
      "text": "Ваш профиль учителя успешно подтвержден",
      "title": "Верификация"
    },
    "subscriber": {
      "text": "Пользователь {{user}} подписался на ваш профиль",
      "title": "Новый подписчик"
    },
    "classroom-invite": {
      "text": "{{teacher}} приглашает вас в класс {{title}}",
      "title": "Приглашение в класс",
      "action": "Перейти в учебный класс"
    },
    "classroom_new_invitation_2": {
      "text": "Преподаватель {{user_fullname}} приглашает вас в учебный класс {{classroom_title}}",
      "title": "Приглашение в класс",
      "action": "Перейти в учебный класс"
    },
    "classroom-leave": {
      "text": "Ученик {{student}} покинул учебный класс {{title}}",
      "title": "Выход из учебного класса",
      "action": "Перейти в учебный класс"
    },
    "classroom_new_participant_2": {
      "text": "Пользователь {{user_fullname}} присоединился к учебному классу {{classroom_title}}",
      "title": "Новый ученик в учебном классе",
      "action": "Перейти в учебный класс"
    },
    "meeting_participant_joined": {
      "title": "Ученик принял приглашение",
      "text": "Ученик {{student}} принял приглашение на занятие {{title}}",
      "action": "Перейти в учебный класс"
    },
    "classroom-removed": {
      "text": "Преподаватель удалил учебный класс {{title}}",
      "title": "Учебный класс удален"
    },
    "school-status-changed": {
      "text": "Школа {{title}} {{status}}",
      "title": "Статус школы изменился",
      "action": "Перейти в школу",
      "status": {
        "school-status": "Статус школы",
        "send-for-moderation": "Отправить на модерацию",
        "removed": "удалена",
        "draft": "создана",
        "verification_declined": "отклонена",
        "under_verification": "на модерации",
        "verified": "промодерирована",
        "published": "Опубликованные",
        "working": "опубликована и работает",
        "not-published": "Не опубликована",
        "on-moderation": "На модерации",
        "changed": "Изменено. Требуется повторная модерация"
      }
    },
    "contract-signed-up": {
      "text_lesson": "Новая запись на занятие",
      "text_course": "Новая запись на курс",
      "text_regular": "Новая запись на регулярное занятие",
      "title": "Новая запись на занятие",
      "date_time": "Дата и время",
      "student": "Ученик",
      "every-week": "Еженедельно",
      "number_of_classes": "Количество занятий",
      "first_class": "Первое занятие"
    },
    "contract_signed_up_student": {
      "text_lesson": "Новая запись на занятие",
      "text_course": "Новая запись на курс",
      "text_regular": "Новая запись на регулярное занятие",
      "title": "Новая запись на занятие",
      "info_onetime_date": "Дата и время: {{ begin_at_local }}",
      "info_course_number": "Количество занятий: {{ count }}",
      "info_course_date": "Первое занятие: {{ begin_at_local }}",
      "info_regular_weekly": "Еженедельно, по следующим дням: {{ weekdays }}",
      "info_regular_date": "Дата и время: {{ begin_at_local }}",
      "teacher": "Преподаватель: {{ teacher_fullname }}"
    },
    "contract-prolong-order-unpaid": {
      "text": "Пожалуйста, попробуйте еще раз позднее или используйте другую карту",
      "title": "Не получилось оплатить",
      "action": "Привяжите карту"
    },
    "billing-card": {
      "card": "Карта",
      "bank-card": "Банковская карта",
      "bank-cards": "Банковские карты",
      "bank-card-description": "Добавьте банковскую карту, чтоб принимать платежи от учеников",
      "bank-card-support": "Если после привязки банковской карты, она не отображается в списке, пожалуйста, напишите в поддержку.",
      "support": "Написать в поддержку",
      "process-delete": "Your bank card is in the process of being deleted. This may take some time",
      "success-delete": "Your bank card has been successfully deleted!",
      "error-delete": "Ваша банковская карта не может быть удалена. Возможно у вас есть платные коллекции или карта занята незавершенными операциями"
    },
    "title": "Уведомления",
    "today": "Сегодня",
    "yesterday": "Вчера",
    "notifications-stub": "Уведомлений пока нет",
    "modal.title": "уведомления",
    "billing_plan_was_deactivated": {
      "title": "Отмена тарифа",
      "text": "Ваш тариф отменен. {{ reason_for_deactivation }}"
    },
    "billing_plan_will_be_prolong_soon": {
      "title": "Оплата тарифа",
      "text": "Через {{ number_of_days_for_upcoming_payment }} дней тарифный план будет продлен. Убедитесь, что на вашей карте достаточно средств для оплаты подписки"
    },
    "vacation_reminder": {
      "title": "Отпуск",
      "text": "У вас запланирован отпуск с {{begin_at}} по {{end_at}}. Если в этот период у вас запланированы занятия, не забудьте их перенести или отменить.",
      "action": "Перейти в календарь"
    },
    "meeting_complaint_reported": {
      "title": "Жалоба от ученика",
      "text": "На ваше занятие {{title}} поступила жалоба от ученика {{student}}. Ожидайте решения."
    },
    "meeting_complaint_resolved_teacher": {
      "title": "Решение по жалобе",
      "text": "По жалобе ученика {{student}} на ваше занятие {{title}} принято решение. Подробности на вашей электронной почте."
    },
    "meeting_complaint_resolved_reporter": {
      "title": "Решение по жалобе",
      "text": "По вашей жалобе на занятие {{title}} принято решение. Подробности на вашей электронной почте."
    },
    "meeting_with_teacher_will_start": {
      "title": "Приближается занятие",
      "text": "Через {{delay_to_begin_min}} минут начнется занятие {{meeting_title}} с преподавателем {{teacher_fullname}}",
      "action": "Перейти в учебный класс"
    },
    "meeting_with_student_will_start": {
      "title": "Приближается занятие",
      "text": "Через {{delay_to_begin_min}} минут начнется занятие {{meeting_title}} с учеником {{participant_fullname}}",
      "action": "Перейти в учебный класс"
    },
    "meeting_will_start": {
      "title": "Приближается занятие",
      "text": "Через {{delay_to_begin_min}} минут начнется занятие {{meeting_title}}",
      "action": "Перейти в учебный класс"
    },
    "collection-approved": {
      "text": "Коллекция {{title}} размещена в разделе \"Коллекции\"",
      "title": "Коллекция опубликована"
    },
    "collection-softDeclined": {
      "text": "Публикация коллекции {{title}} отклонена. Посмотрите комментарии модератора.",
      "title": "Коллекция не опубликована"
    },
    "collection-hardDeclined": {
      "text": "Публикация коллекции {{title}} отклонена. Посмотрите комментарии модератора.",
      "title": "Коллекция отклонена"
    },
    "you_successfully_enrolled_in_meeting": {
      "title": "Новая запись на занятие",
      "text": "<p>Новая запись на занятие {{meeting_title}}</p>Дата и время: {{meeting_begin_at}}<br>Преподаватель: {{teacher_fullname}}"
    },
    "student_successfully_enrolled_in_meeting": {
      "title": "Новая запись на занятие",
      "text": "<p>Новая запись на занятие {{meeting_title}}</p>Дата и время: {{meeting_begin_at}}<br>Ученик: {{participant_fullname}}"
    }
  },
  "onboarding": {
    "access-knowledge": "Авторизируйтесь и получите полный доступ к Onclass",
    "badges": {
      "billing": "Принимайте платежи",
      "mobile-app": "Установите мобильное приложение",
      "schedule": "Укажите рабочее время",
      "services": "Добавьте доступные занятия",
      "verification": "Пройдите верификацию"
    },
    "checklist": {
      "name": "Укажите полное имя",
      "photo": "Добавить фото",
      "sign-in": "Войти",
      "verify": "Пройдите верификацию"
    },
    "classes-hosted": "Проведено занятий",
    "classes-hosted-per-month": "Проведено занятий в месяц",
    "lessons-created": "Создано уроков",
    "lessons-created-per-month": "Создано уроков в месяц",
    "now-available": "Сейчас вам доступно",
    "panel": {
      "chat": "Чат и мобильный мессенджер",
      "chat-teachers": "Общайтесь с преподавателями через мессенджер Onclass",
      "create-content": "Создание контента в конструкторе уроков и упражнений",
      "feature-profile": "Публикация вашего профиля в каталоге преподавателей",
      "free-classes": "10 бесплатных занятий в классе",
      "get-free-support": "Бесплатная поддержка и обучение",
      "get-payments": "Расчеты через Onclass.com с помощью безопасной сделки",
      "host-classes": "Проведение онлайн занятий",
      "host-group-classes": "Возможность проводить публичные занятия",
      "host-private-classes": "Проведение индивидуальных занятий с учеником",
      "invite-students": "Занятия с учениками по пригласительной ссылке",
      "safe-deal": "Комиссия по безопасной сделке 15% (вместо 30%)",
      "take-classes": "Занятия с преподавателями по пригласительной ссылке",
      "top-spot": "Топ рейтинг в каталоге преподавателей"
    },
    "rank-panel": {
      "find-students": "Возможность находить и привлекать новых учеников",
      "grades": "Оценка за занятие",
      "homeworks": "Выдавать домашнюю работу",
      "host-webinars": "Возможность преподавать занятия публично",
      "income": "Возможность получать дополнительный доход",
      "payments": "Возможность расчета по безопасной сделке",
      "private-lessons": "Приватные занятия с приглашением по ссылке",
      "publishing": "Публикация вашего профиля в каталоге преподавателей",
      "respond": "Возможность быстро реагировать на события Onclass",
      "support": "Бесплатная поддержка и обучение",
      "tools": "Множество других функций и возможностей"
    },
    "sign-in": "Войти",
    "sign-in-student": "Войти как ученик",
    "sign-in-teacher": "Хочу преподавать",
    "start-teaching": "Начните преподавать, <br> выполнив 4 простых шага",
    "start-teaching-and-earn": "Откройте возможность зарабатывать на Onclass и преподавать публично",
    "statuses": {
      "auth-required": "Требуется авторизация",
      "limited-access": "Ограниченный доступ",
      "not-verified": "Не проверено",
      "on-moderation": "На модерации",
      "rank": "Уровень",
      "status": "Статус",
      "student": "Студент",
      "teacher": "Репетитор",
      "tutor": "Репетитор",
      "upgrade": "Повысьте свой уровень"
    },
    "stay-active": "Поддерживайте вашу активность в течение <span>месяца</span>, чтобы сохранить статус преподавателя",
    "teacher-get": "Сейчас вам доступно",
    "time-left": "Осталось",
    "upgrade-profile": "Вырастите до уровня “преподаватель”",
    "want-to-learn": "Хочу учиться",
    "want-to-teach": "Хочу преподавать",
    "what-authorized-can": "Что могут делать авторизованные репетиторы?",
    "what-teacher-rank-provide": "Что дает статус преподавателя?",
    "what-verified-can": "Что дает верификация?",
    "steps-widget": {
      "profile": "Профиль",
      "title": "Анкета преподавателя",
      "description": "Чтобы начать преподавать, заполните анкету преподавателя и пройдите верификацию.",
      "more-details": "Подробнее о преподавании",
      "button-1": "Заполнить анкету",
      "button-2": "Продолжить",
      "steps-checked": "Шаг {{ checked-steps }} из {{ all_steps }}",
      "percent-checked": "{{ percent }}% завершено"
    }
  },
  "pagination": {
    "nothing-found": "Ничего не найдено",
    "nothing-more": "Нет подписчиков"
  },
  "payment-form": {
    "summary": {
      "text1": "Цены отображены в",
      "text2": "Расчет в",
      "text3": "Курс на сегодня",
      "text4": "за",
      "currency1": "Доллары США (USD)",
      "currency2": "Virtual credits (CREDITS)",
      "currency3": "Russian ruble (RUB)",
      "currency4": "Евро (EUR)",
      "currency5": "Фунт стерлингов (GBP)",
      "currency6": "Украинская гривна (UAH)",
      "currency7": "Белорусский рубль (BYN)",
      "currency8": "Белорусский рубль (BYN)",
      "currency9": "Казахский тенге (KZT)",
      "currency10": "Азербайджанский манат (AZN)",
      "currency11": "Швейцарский франк (CHF)",
      "currency12": "Чешская крона (CZK)",
      "currency13": "Канадский доллар (CAD)",
      "currency14": "Польский злотый (PLN)",
      "currency15": "Шведская крона (SEK)",
      "currency16": "Турецкая лира (TRY)",
      "currency17": "Китайский юань (CNY)",
      "currency18": "Индийская рупия (INR)",
      "currency19": "Бразильский реал (BRL)",
      "currency20": "Южноафриканский рэнд (ZAR)",
      "currency21": "Узбекский сум (UZS)",
      "currency22": "Болгарский лев (BGN)",
      "currency23": "Japanese Yen (JPY)",
      "currency-code1": "USD",
      "currency-code2": "CREDITS",
      "currency-code3": "RUB",
      "currency-code4": "EUR",
      "currency-code5": "GBP",
      "currency-code6": "UAH",
      "currency-code7": "BYN",
      "currency-code8": "BYN",
      "currency-code9": "KZT",
      "currency-code10": "AZN",
      "currency-code11": "CHF",
      "currency-code12": "CZK",
      "currency-code13": "CAD",
      "currency-code14": "PLN",
      "currency-code15": "SEK",
      "currency-code16": "TRY",
      "currency-code17": "CNY",
      "currency-code18": "INR",
      "currency-code19": "BRL",
      "currency-code20": "ZAR",
      "currency-code21": "UZS",
      "currency-code22": "BGN",
      "currency-code23": "JPY"
    },
    "summary-item": {
      "label1": "Сумма:",
      "label2": "Купон:",
      "label3": "Сервисный сбор:",
      "label4": "Итого:"
    },
    "payment-in-progress": "Идет оплата...",
    "in-progress": "В процессе...",
    "agreement1": "Я согласен с",
    "terms-and-conditions": "условиями и положениями",
    "agreement2": "и с",
    "privacy-policy": "политикой конфиденциальности",
    "payment-method": "Добавить счет",
    "payment-method-not-selected": "Способ оплаты не выбран. Чтоб оплатить занятие, пожалуйста, выберите способ оплаты.",
    "order": {
      "price": "Стоимость",
      "processing_fee": "Комиссия",
      "total": "Итого",
      "approximate": "Приблизительно",
      "additional_text1": "Для конвертации валют Onclass использует сервис Open Exchange Rates. Фактические курсы обмена зависят от вашего банка и могут отличаться.",
      "additional_text2": "Ваш окончательный платеж будет произведен в"
    }
  },
  "profile-form": {
    "about": {
      "birth_date": "День рождения",
      "country": "Страна",
      "first_name": "Имя",
      "company_name": "Название компании",
      "type_of_employment": "Тип занятости",
      "business_types": {
        "individual": "Индивидуальный (частное лицо)",
        "company": "Компания"
      },
      "enter_bank_details": "Введите банковские реквизиты",
      "full_name": "Полное имя",
      "last_name": "Фамилия",
      "meta-title": "Личная информация",
      "speak": "Язык:",
      "tell-about-yourself": "Расскажите о себе",
      "upload": "Загрузить",
      "your-photo": "Ваше фото",
      "your-name": "Имя Фамилия",
      "your-name-hint": "Пожалуйста, введите свое полное имя и фамилию",
      "nickname": "Псевдоним",
      "nickname-hint": "Псевдоним, используемый как ссылка на страницу вашего учителя",
      "address-question": "Где вы живете?",
      "address-hint": "Укажите страну, в которой вы живете",
      "upload-photo": "Загрузите свое фото",
      "upload-photo-cover": "Загрузите свое фото и обложку",
      "photo-hint": "Больше внимания уделяют профилю с фотографией",
      "dob": "Дата рождения",
      "language-spoken": "На каком языке вы говорите?",
      "about-yourself": "Расскажите немного о себе"
    },
    "action-complete": "Завершить",
    "action-next": "Далее",
    "add-more": "Добавить еще +",
    "add-more-language": "Добавить язык +",
    "add-subject": "Добавить дисциплину",
    "native-level-hint": "Родной язык - это язык, на котором вы готовы преподавать и создавать обучающий контент",
    "billing": {
      "account-number": "Номер счета",
      "address": "Адрес",
      "agreement1": "С",
      "agreement2": "условиями и положениями",
      "agreement3": "ознакомлен",
      "bank": "Наименование банка",
      "bank-address": "Адрес банка",
      "bank_account_number": "Корреспонденский счет",
      "beneficiary": "Бенефициар",
      "cancel": "Отмена",
      "city": "Город",
      "edit": "Редактировать",
      "full-name": "Фамилия Имя Отчество",
      "index": "Индекс",
      "meta-title": "Биллинг",
      "not-specified": "Не указано",
      "region": "Регион / Штат / Провинция",
      "residence_country": "Страна проживания",
      "save": "Сохранить",
      "send-invoice": "E-mail для счет-фактур",
      "specify-tin": "Укажите ИНН",
      "tax-entrepreneur": "Индивидуальный предприниматель / ООО",
      "tax-individual": "Физическое лицо / самозанятый",
      "tax-number": "Налоговый номер",
      "tax-status": "Налоговый статус"
    },
    "complete": {
      "next": "Далее",
      "title": "Спасибо за заполнение профиля"
    },
    "duplicate-text": "Выбранные языки не должны совпадать",
    "education": {
      "title": "Где вы учились?",
      "certificate": "Сертификаты",
      "faculty": "Факультет",
      "graduation-year": "Выпуск",
      "institution": "Образование",
      "institution-name": "Название учреждения",
      "meta-title": "Образование",
      "certificates": "Сертификаты",
      "certificates-hint": "Добавьте сертификаты подтверждающие вашу квалификацию (не обязательно)",
      "format-hint": "Формат файлов: jpg, png, pdf, не более 5 Мб",
      "delete": "Удалить"
    },
    "experience": {
      "title": "Опыт работы",
      "from": "C",
      "job": "Место работы",
      "meta-title": "Опыт работы",
      "organization": "Название учреждения",
      "position": "Должность",
      "to": "По",
      "until-now": "по настоящее время",
      "no-working-experience": "Ранее не работал"
    },
    "max-length-text": "Текст должен быть не более 2000 символов",
    "min-length-text": "Текст должен быть не менее 150 символов",
    "microphone": {
      "setting-audio-video": "Проверьте и настройте ваши видео/аудио устройства",
      "possible-solution": "Возможные способы решения проблемы если у вас не работает аудио/видео/микрофон:",
      "reload": "Перезагрузите браузер",
      "check-your-setting": "Проверьте настройки доступа камеры и микрофона в браузере. Нажмите на одну их этих иконок в адресной строке и разрешите доступ к медиа-устройствам",
      "safari-instruction": "(Инструкция для Safari)",
      "another-browser": "Попробуйте использовать другой браузер.",
      "recommended": "(рекомендуется)",
      "install-chrome": "Установите браузер Chrome",
      "camera": "Камера",
      "microphone": "Микрофон",
      "camera-error": "Камера не найдена или нет доступа, проверьте настройки"
    },
    "remove": "Удалить",
    "required-native": "Select at least one native language",
    "save": "Сохранить",
    "saved": "Сохранено",
    "saving": "Сохранение",
    "next": "Далее",
    "back": "Назад",
    "skip": "Пропустить",
    "finish": "Завершить",
    "to-main": "На главную",
    "continue": "Продолжить",
    "send-for-moderation": "Отправить на модерацию",
    "security": {
      "accounts": "Аккаунты",
      "email": "Электронная почта",
      "get-code": "Получить код",
      "meta-title": "Контакты",
      "password": "Пароль",
      "phone": "Телефон",
      "skype": "Skype",
      "telegram": "Telegram",
      "contact-information": "Контактная информация",
      "contact-information-hint": "Номер телефона и электронная почта нужны для входа на сайт и восстановления пароля",
      "sms-code": "SMS код",
      "verify": "Проверить",
      "your-id": "Ваш ID",
      "confirm": "Подтвердить",
      "change": "Изменить",
      "cancel": "Отмена",
      "alert-info-title": {
        "days-14": "Нужно подождать",
        "minutes-5": "Нужно немного подождать",
        "same-number": "Номер телефона не изменился"
      },
      "alert-info-message": {
        "days-14": "Вы можете изменить номер телефона только 1 раз в 14 дней",
        "minutes-5": "Вы можете подтвердить номер телефона 1 раз в 5 минут",
        "same-number": "Этот номер телефона уже был подтвержден ранее"
      }
    },
    "select": "Выбрать",
    "select-language": "Выберите язык",
    "select-country": "Выберите страну",
    "studying": {
      "level": "уровень",
      "meta-title": "Studying"
    },
    "subject": "Subject",
    "teaching": {
      "title": "Какие предметы Вы преподаете?",
      "subtitle": "Если вы являетесь репетитором иностранных языков, то важно выбирать язык, который имеет пометку \"Иностранный\".",
      "about-teaching": "Расскажите о преподавании данной дисциплины более подробно (минимум 400 символов)",
      "meta-title": "Преподаю",
      "video": "Видео",
      "safari-holder-text": "Запись видео с помощью веб-камеры в Safari не поддерживается. Используйте другой браузер, например Google Chrome.",
      "what-exactly": "Что именно?",
      "record-video": "Запишите свое видео",
      "introduce-yourself": "Теперь представьтесь студентам и расскажите о своем преподавании",
      "start-record": "Начать запись",
      "stop-record": "Остановить запись",
      "option-link-text": "Вставьте ссылку на свое видео (Youtube, Vimeo)",
      "option-video-text": "Запишите свое вступление на веб-камеру",
      "option-upload-video-text": "Загрузите свое видео",
      "option-upload-video-description": "Только браузер Safari. Если вы хотите записать видео из браузера, используйте Chrome",
      "allow-video": "Чтобы записать видео, пожалуйста, разрешите",
      "camera-access": "доступ к камере",
      "loading-video": "Видео загружается",
      "short-video": "Упс! Ваше видео слишком короткое. Чтобы продолжить, убедитесь, что продолжительность вашего видео превышает 30 секунд.",
      "long-video": "О-оу! Ваше видео слишком длинное. Запишите видео продолжительностью не более двух минут.",
      "same-video": "Упс! Ваше видео используется раньше. Чтобы продолжить, убедитесь, что ваше видео уникально.",
      "incorrect-format": "Неправильный формат ссылки",
      "tips-for-video": "Советы как сделать хорошее видео",
      "record-orientation": "Запишите горизонтальное видео",
      "video-duration": "Длительность видео должна быть не менее 30 секунд и не более 2 минут",
      "background": "Используйте нейтральный фон",
      "face-visible": "Ваше лицо и глаза должны быть полностью видны",
      "presentation": "Не используйте презентации",
      "violating": "Отправляя видео, убедитесь, что вы не нарушаете чьи-либо авторские права.",
      "file-size": "Размер файла не может превышать 500 МБ",
      "video-format": "Для достижения наилучших результатов формат видео должен быть 16:9",
      "delete": "Удалить",
      "overwrite": "Перезаписать",
      "example": "Пример",
      "already-selected": "Этот предмет уже выбран",
      "paste-as-link": "Вставьте ссылку на видео",
      "no-camera-alert": {
        "title": "Камера не подключена",
        "description": "Видеозапись невозможна, так как к вашему устройству не подключена камера"
      }
    },
    "services": {
      "title": "Добавьте доступные ученикам занятия",
      "hint": "После добавления доступного занятия оно появится в вашем календаре и ученики смогут на него записаться",
      "action": "Добавить занятие в каталог"
    },
    "card": {
      "title": "Привязка банковской карты",
      "hint": "Привязка карты позволит вам принимать оплату за свои услуги и получать гарантированные выплаты",
      "pending": "Card activation",
      "active": "Card active",
      "canceled": "Карта удалена",
      "expired": "Срок действия карты истек",
      "deleting": "Удаление карты"
    },
    "app": {
      "title": "Мобильное приложение"
    },
    "user-pic": {
      "take-photo": "Take a photo",
      "see-example": "See example",
      "use-background": "Используйте однородный фон",
      "smile": "Улыбайтесь",
      "look-camera": "Смотрите в камеру",
      "tips-for-photo": "Советы для создания хорошего фото",
      "used-as-link": "Used as a link to your personal page",
      "upload-photo": "Загрузить фотографию",
      "close-photo": "Отмена",
      "put-face-in-circle": "Put your face in the circle and press the button"
    },
    "confirm-modal": {
      "title": "Введите код",
      "subtitle": "Код для подтверждения отправлен на Ваш электронный ящик.",
      "subtitle2": "Мы отправили СМС с кодом на номер +{{ term }}",
      "text": "Если код не приходит, убедитесь, что он не в папке спам. Так же проверьте правильность введенного вами e-mail или запросите код повторно через {{ term }} секунд.",
      "text2": "Если код не приходит, проверьте правильность введеного вами телефона или запросите код повторно через {{ term }} сек.",
      "again": "Прислать код заново"
    },
    "filled-all": {
      "title": "Спасибо!",
      "description-1": "Поздравяем! Ваша анкета на рассмотрении и будет рассматриваться в течении 5 дней. Если у нас возникнут какие-то вопросы мы с вами свяжемся.",
      "description-2": "Теперь вы можете заниматься со своими учениками и пользоваться платфомрой онкласс!"
    },
    "on-moderation": {
      "title": "Анкета отправлена на модерацию",
      "description": "Как только изменения будут приняты, вы получите уведомление."
    }
  },
  "profile-sidebar-widget-20210625": {
    "hide-reviews": "Скрыть отзывы",
    "hr": "час",
    "price-title": "Индивидуальные занятия от:",
    "rating-label": "Рейтинг репетиторов",
    "show-reviews": "Показать отзывы",
    "sign-up": "Записаться",
    "popover-text": "Добавьте доступные занятия <br> и разместите их в <a href=\"/schedule/calendar\" class=\"link\">своем <br> календаре</a>, чтобы <br> другие смогли <br> на них записаться.",
    "popover-link": "Добавить услугу"
  },
  "profile-verification-widget": {
    "action-change": "Изменить",
    "action-verify": "Проверить",
    "profile-verified": "Профиль верифицирован",
    "teacher-status": "Профиль преподавателя:",
    "teacher-status-not_teacher": "не подтвержден",
    "teacher-status-not_verified": "на модерации",
    "teacher-status-verified": "проверено",
    "verification-details": "Подробнее",
    "verification-help": "Что дает верификация?",
    "verification-in-progress": "Проверка аккаунта может занимать от 1 до 7 дней"
  },
  "help-widget": {
    "title": "Нужна помощь?",
    "description": "Свяжитесь с нашей службой поддержки",
    "action": "Начать диалог"
  },
  "regular-schedule-form": {
    "before-start-widget-text": "Нужно для того, чтоб у Вас было время подготовиться к занятию",
    "before-start-widget-title": "За какое время до начала к вам можно записаться на занятие?",
    "signup-time-limits-title": "Время записи на занятия",
    "signup-time-min-limit": "Не ранее",
    "signup-time-max-limit": "Не позже",
    "signup-time.1 week": "1 неделя",
    "signup-time.2 weeks": "2 недели",
    "signup-time.3 weeks": "3 недели",
    "signup-time.4 weeks": "4 недели",
    "signup-time.1 hour": "1 час",
    "signup-time.2 hours": "2 часа",
    "signup-time.3 hours": "3 часа",
    "signup-time.4 hours": "4 часа",
    "signup-time.5 hours": "5 часов",
    "signup-time.6 hours": "6 часов",
    "signup-time.12 hours": "12 часов",
    "signup-time.24 hours": "24 часа",
    "signup-time.48 hours": "48 часов",
    "signup-time.72 hours": "72 часа",
    "hide": "Скрыть",
    "recommendation-text": "Если у вас есть занятия, длительность которых 2 часа и более, рекомендуем указывать рабочие периоды не менее 2 часов подряд.",
    "recommendation-title": "Рекомендация",
    "save": "Сохранить",
    "saved": "Сохранено",
    "show": "Открыть",
    "text": "Правильный часовой пояс поможет синхронизировать время занятий с учениками из других часовых поясов или стран",
    "timezone-placeholder": "Выберите свой часовой пояс",
    "title": "Расписание занятий (только регулярные и разовые)",
    "title-select": "Выберите свой часовой пояс",
    "to": "до",
    "subtitle": "Автоматизируйте запись регулярных и одиночных занятий - посещайте дни, которые вам удобно проводить. Для групповых и курсовых обязательств начало событий в ручную!"
  },
  "settings-page": {
    "notifications": {
      "feedback": "Отзывы",
      "new-message": "Новое сообщение",
      "like-dislike": "Нравится / Не нравится",
      "shared": "Поделились",
      "comments": "Комментарии",
      "mentions": "Упоминания",
      "events": "Занятия",
      "new-subscriber": "Новый подписчик",
      "group-invitation": "Приглашение в группу",
      "birthdays": "Дни рождения",
      "upcoming-events": "Ближайшие мероприятие",
      "waiting-for-payment": "Ожидание оплаты",
      "rating": "Рейтинг",
      "reviews": "Отзывы",
      "new-teacher-selected-disciplines": "Новый преподаватель по избранным дисциплинам",
      "invitation-lesson": "Приглашение на урок",
      "pay": "Оплатить",
      "new-homework": "Новая домашняя работа",
      "new-post-subscriber": "Новый пост от подписчика",
      "email-notifications": "Уведомления по электронной почте",
      "email-for-notifications": "Email для уведомлений",
      "notifications-frequency": "Частота уведомлений",
      "change": "Изменить",
      "never-notify": "Никогда не уведомлять"
    },
    "password": {
      "change": "Изменить",
      "fields": {
        "label1": "Старый пароль",
        "label2": "Новый пароль",
        "label3": "Подтвердить пароль"
      },
      "label": "Пароль",
      "submit-form": "Сохранить новый пароль",
      "title": "Смена пароля"
    },
    "timezone": {
      "title": "Выберите свой часовой пояс",
      "description": "Правильный выбор часового пояса поможет синхронизировать время занятий с преподавателями"
    },
    "tabs": {
      "common": "Common",
      "finance": "Платежный профиль",
      "notifications": "Уведомления",
      "password": "Пароль",
      "security": "Контакты",
      "timezone": "Часовой пояс"
    }
  },
  "sidebar-options": {
    "balance": "Баланс",
    "class-calendar": "Календарь занятий",
    "edit-profile": "Редактировать профиль",
    "individual-sessions": "Индивидуальные занятия",
    "on-the-account": "Баланс",
    "profile": "Профиль",
    "questionnaire": "Профиль",
    "replenish": "Пополнить",
    "settings": "Настройки",
    "sign-up-lesson": "Sign up for a lesson",
    "teacher-profile": "Анкета преподавателя"
  },
  "statistic-informer": {
    "create-content": "Создать урок",
    "lesson": "Уроков",
    "lesson-student": "Бесплатные <br> классы:",
    "subject": "Предметов",
    "subtitle": "Создавайте интерактивные уроки и упражнения, проводите занятия в виртуальном классе Onclass, привлекайте новых учеников, или занимайтесь со своими учениками по пригласительной ссылке",
    "subtitle-student": "Бесплатные уроки и другой полезный контент, занимайтесь с преподавателями на платформе Onclass",
    "teachers": "Репетиторов",
    "title": "Проводите занятия на платформе Onclass",
    "title-student": "Занимайтесь с преподавателями Onclass"
  },
  "classes": {
    "meta-create-title": "Создать учебный класс",
    "meta-form-title": "Редактор",
    "meta-title": "Онлайн-занятия",
    "page-header-title": "Занятия"
  },
  "subject-filter": {
    "disabled": "выключено",
    "enabled": "включено"
  },
  "subject-filters": {
    "apply": "Применить фильтр",
    "clear": "Сбросить фильтр",
    "current-filter": "Выбрано",
    "current-filter-all": "Все",
    "foreign-language": "Иностранный",
    "label-current": "Выбрано:",
    "native-language": "Родной язык",
    "not-found": "Такой дисциплины не найдено",
    "recent": "Недавние",
    "search-input-placeholder": "Поиск",
    "tab-all": "Все",
    "tab-favorites": "Избранные",
    "title": "Дисциплины",
    "title-foreign": "Иностранные языки",
    "title-native": "Родные языки",
    "title-non_categorized": "Другие дисциплины"
  },
  "subject-levels": {
    "1-class": "1-ый класс",
    "10-class": "10-ый класс",
    "11-class": "11-ый класс",
    "2-class": "2-ой класс",
    "3-class": "3-ий класс",
    "4-class": "4-ый класс",
    "5-class": "5-ый класс",
    "6-class": "6-ой класс",
    "7-class": "7-ой класс",
    "8-class": "8-ой класс",
    "9-class": "9-ый класс",
    "advanced": "Опытный",
    "any-level": "Любой уровень",
    "beginner": "Начинающий",
    "elementary": "Начальный",
    "intermediate": "Intermediate",
    "pre-intermediate": "Слабый средний",
    "proficiency": "Профессиональный",
    "upper-intermediate": "Выше среднего"
  },
  "subject-selector": {
    "create": "Создать новую",
    "foreign": "Иностранный",
    "lvl1": {
      "foreign": "Иностранные языки",
      "native": "Родные языки",
      "non_categorized": "Другие дисциплины",
      "other": "Новая дисциплина"
    },
    "native": "Родной язык",
    "not-subject": "Такой дисциплины не найдено",
    "recent-title": "Недавние",
    "search-placeholder": "Поиск дисциплины",
    "select-placeholder": "Subject"
  },
  "subject-selector-in-header": {
    "category": {
      "foreign": "Иностранные языки",
      "native": "Родные языки",
      "others": "Другие дисциплины"
    },
    "default-placeholder": "Выберите предмет",
    "link": "Станьте репетитором",
    "recent": "Недавние",
    "tabs.all": "Все",
    "tabs.favorites": "Избранные",
    "text": "Не нашли Ваш предмет?"
  },
  "subscription-plan-periods": {
    "day": "every day",
    "month1": "каждый месяц",
    "month3": "каждые три месяца",
    "month6": "каждые шесть месяцев",
    "year": "annual"
  },
  "subscription-plans": {
    "action-activate": "Активировать тариф",
    "action-activate2": "Активировать тариф",
    "action-active": "Active plan",
    "action-current": "Текущий",
    "period": "в месяц",
    "free-for-students": "Для учеников бесплатно",
    "bank-cards": {
      "add": "Добавить карту",
      "expires-flag": "Срок истекает",
      "expires-label": "Срок действия карты:",
      "label": "Ваши карты",
      "link": "Редактировать",
      "no-card-title": "Нет привязанных карт",
      "text": "Привязанные карты"
    },
    "benefit-1": "You don't need to pay the <span>onclass.com</span> fee for your classes",
    "benefit-2": "You might provide free lessons to attract students",
    "benefit-3": "You might accept payments using bank card",
    "benefits-title": "Subscription benefits",
    "calculator": {
      "action": "Активировать тариф",
      "action2": "Купить кредиты",
      "credits": "кредитов",
      "credits-label": "С каким количеством учеников вы планируете заниматься?",
      "credits-label2": "Сколько раз в месяц вы планируете проводить занятия?",
      "credits-label3": "Количество кредитов которое вам потребуется в месяц:",
      "expand-action": "Рассчитать кол-во кредитов?",
      "info-label": "Ваш тариф:",
      "info-label2": "Рекомендуемый тариф:",
      "title": "Количество кредитов, которое вам потребуется в месяц"
    },
    "cancel-subscription": "Отменить подписку",
    "cancel-subscription-switch": "Отмениться после обновления",
    "commission-action": "Снизить комиссию?",
    "credit-value-label": "Осталось кредитов:",
    "every-month": "Every month",
    "forms": {
      "action-activate-plan": "Активировать тариф",
      "action-apply": "Применить",
      "action-buy-credits": "Оплатить",
      "action-cancel": "Отписаться",
      "action-payment": "Пополнение счета",
      "activate-text": "Активация подписки",
      "agree": "Я согласен c условиями и положениями, а так же с",
      "alert-text": "Внимание! Oтмена подписки повлечет сгорание ваших кредитов по подписке и купленных кредитов. Бонусные кредиты будут сохранены.",
      "amount-credits": "Количество кредитов:",
      "bonus": "Бонусные:",
      "buying-credits": "Покупка кредитов",
      "by-subscription": "По подписке:",
      "cancel-mode-text": "Вы хотите отменить подписку? \u2028Пожалуйста, напишите ниже, что вас не устроило и почему вы собираетесь это сделать:",
      "cancel-text": "Отмена подписки",
      "cancellation-label": "Ваш тариф после отмены:",
      "cancellation-label2": "Ваши кредиты после отмены подписки:",
      "header-label": "Тариф",
      "info-label": "Ежемесячная подписка на Onclass",
      "next-payment": "Дата следующего платежа:",
      "next-payment-text1": "Оплата будет списана автоматически.",
      "next-payment-text2": "Вы сможете отменить подписку в любое время.",
      "payment": "Перейти к оплате",
      "privacy-policy": "политикой конфиденциальности",
      "promo-placeholder": "Промокод",
      "purchased": "Купленные:",
      "recommended-plan": "Рекомендуемый тариф:",
      "total-credit": "Итого останется кредитов:",
      "total-pay": "Итого:",
      "your-plan": "Ваш тариф:"
    },
    "history-mini": {
      "link": "Смотреть всю историю",
      "subtitle": "Последние транзакции",
      "title": "История"
    },
    "improve-plan": "Улучшить тариф",
    "change-plan": "Изменить тариф",
    "resume": "Возобновить",
    "label-hit": "Hit",
    "next-update-label": "Следующее обновление:",
    "pay-action": "Купить кредиты",
    "periods": {
      "day": "день",
      "month1": "месяц",
      "month3": "3 месяца",
      "month6": "6 месяцев",
      "year": "год"
    },
    "plan-advanced": {
      "bonus-hint": "+40 bonus",
      "hint": "До 100 занятий в месяц",
      "nameplate": "САМЫЙ ПОПУЛЯРНЫЙ",
      "subtitle": "Оптимальная комиссия",
      "title": "Опытный"
    },
    "plan-beginners": {
      "hint": "До 20 занятий в месяц",
      "subtitle": "20 credits",
      "title": "Beginners"
    },
    "plan-free": {
      "hint": "До 5 занятий в месяц",
      "subtitle": "5 credits",
      "title": "Бесплатный"
    },
    "plan-mini-card": {
      "credits": "Кредитов:",
      "improve": "Улучшить тариф",
      "label": "Ваш тариф",
      "replenish": "Пополнить"
    },
    "plan-professional": {
      "nameplate": "ЛУЧШЕЕ ПРЕДЛОЖЕНИЕ",
      "subtitle": "Минимальная комиссия",
      "title": "Профессиональный"
    },
    "plan-standard": {
      "bonus-hint": "+10 bonus",
      "hint": "До 50 занятий в месяц",
      "subtitle": "40 credits",
      "title": "Standard"
    },
    "plan-starter": {
      "subtitle": "Большая комиссия",
      "title": "Стартовый"
    },
    "plan-trial": {
      "hint": "Попробуйте 30 дней бесплатно, далее {{ price }} в месяц",
      "nameplate": "Специальное предложение",
      "subtitle": "30 дней бесплатно",
      "title": "Пробный период"
    },
    "plan-not-selected": "Тариф не выбран",
    "choose-plan": "Выберите тариф",
    "subscription-description": "Подписка на Onclass",
    "terms": {
      "term1": "Кредитов в месяц* {{ receive_credits_per_month }}",
      "term2": "Ваша выплата** {{ safe_trade_commission }} %",
      "term3": "Скидка на покупку кредитов*** {{ discount_the_purchase_of_credits }} %",
      "term4": "Комиссия за проведение занятий при расчете через Onclass {{ conference_commission_discount }}",
      "term5": "{{ credits_per_month }} кредитов в месяц",
      "term6": "Скидка на покупку дополнительных кредитов {{ discount_on_purchase_of_additional_credits }}"
    },
    "terms-title": "Terms and Conditions",
    "title": "Choose your subscription plan",
    "titles": {
      "current": "Ваш тариф",
      "payment": "Купить кредиты",
      "plans": "Другие тарифы"
    },
    "your-plan": "Ваш тариф",
    "lessons-per-month": "занятий в месяц*",
    "text-paywall-wrapper": "1 кредит *1 занятие с 1 учеником. Подписку можно отменить можно в любое время.",
    "title-paywall": "Выберите тариф",
    "next-plan-text": "Вы автоматически перейдете на тариф {{plan}} {{date}} Стоимость тарифа {{plan}} составит {{price}}",
    "next-plan-text-onclass-me": "Ваша подписка будет отменена {{date}}",
    "cancel": "Отмена",
    "stub-billing-history": "У вас нет платежной транзакции",
    "stub-billing-subscribe": "У вас нет подписок",
    "plan-inactive": "Тариф неактивен.",
    "reason-for-deactivation": {
      "payment_method_is_not_selected": "Метод оплаты не выбран",
      "billing-error": "Ошибка в данных оплаты",
      "bank-declined-payment": "Оплата отклонена банком"
    }
  },
  "teacher-badges-widget": {
    "bank-card": {
      "item1": "Возможность ученикам оплачивать ваши индивидуальные занятия",
      "item2": "Расчеты с учениками при помощи безопасной сделки через Onclass",
      "link": "Привязать карту",
      "title": "Привязка карты"
    },
    "calendar": {
      "item1": "Укажите рабочее время",
      "item2": "Возможность планировать новые занятия",
      "link": "Перейти в календарь",
      "title": "Календарь"
    },
    "mobile-app": {
      "item1": "Возможность получать мгновенные сообщения о событиях",
      "item2": "Проведение занятий в любом удобном месте",
      "link": "Установить приложение",
      "title": "Мобильное приложение"
    },
    "services": {
      "item1": "Проведение индивидуальных занятий с учеником",
      "item2": "Возможность записи к вам через профиль",
      "item3": "Быстрый поиск в каталоге преподавателей",
      "link": "Добавить доступные занятия",
      "title": "Доступные занятия"
    },
    "verification": {
      "item1": "Публикация в каталоге преподавателей",
      "item2": "Публикация занятий в каталоге классов",
      "item3": "Получение оценок от учеников для повышения рейтинга",
      "item4": "Бесплатная поддержка и обучение",
      "item5": "Расчеты через Onclass.com с помощью безопасной сделки",
      "link": "Пройти верификацию",
      "title": "Верификация"
    }
  },
  "teacher-card": {
    "actions": {
      "more-details": "Подробнее",
      "next-reviews": "Показать следующие отзывы",
      "no-available-hours": "Недоступен",
      "sign-up": "Записаться"
    },
    "ago": "ago",
    "awaiting-approval": "Ожидает подтверждения преподавателем",
    "before-start": "до начала",
    "from": "От",
    "hr": "час",
    "last-visit": "Был(а)",
    "leave-message": "Отправить сообщение",
    "lessons-quantity": "Занятий:",
    "menu-complain": "Пожаловаться",
    "menu-unsubscribe": "Отписаться",
    "native": "Родной язык",
    "native-lang": "Говорит:",
    "no-content1": "Преподаватель",
    "no-content2": "не добавил видео",
    "no-info": "Пользователь не указал информацию.",
    "no-reviews": "Отзывов пока нет",
    "one-hour": "(1 час)",
    "read-more": "Читать далее",
    "reviews-quantity": "Отзывы:",
    "roll-up": "свернуть",
    "scheduled": "Запланировано на",
    "select-date-time": "Выберите дату и время занятия",
    "selected": "Выбрано:",
    "send-message": "Сообщение",
    "show-more": "показать больше",
    "sign-up-lesson": "Sign up for a lesson",
    "speak-on": "Говорит:",
    "stats": {
      "classes": "Занятий:",
      "rating": "Оценка:",
      "reviews": "отзывов",
      "reviews-count": "Отзывы:",
      "students": "Ученики:"
    },
    "subscribe": "Подписаться",
    "subscribe-to": "Подписаться",
    "subscribed": "Вы подписаны",
    "tab-about": "О преподавателе",
    "tab-reviews": "Отзывы",
    "tab-schedule": "Уроки и расписание",
    "tabs": {
      "about": "Личная информация",
      "lessons": "Популярные занятия",
      "reviews": "Отзывы",
      "schedule": "Календарь",
      "teaching": "Преподаю"
    },
    "teaches": "Преподает:",
    "titles": {
      "add-favorites": "Добавить в избранные",
      "remove-favorites": "Удалить из избранных",
      "subscribe": "Подписаться",
      "teacher-page": "Страница преподавателя",
      "unsubscribe": "Отписаться",
      "video-presentation": "Смотреть видео-презентацию",
      "write-message": "Написать сообщение",
      "write-message-sub": "Обычно отвечает в течение 4 часов"
    },
    "total": "Итого",
    "per-class": "за занятие",
    "share-link-text": "Делитесь ссылкой на профиль с вашими учениками, чтобы они могли узнать вас получше"
  },
  "teacher-filter-popup": {
    "button-1": "Имя",
    "button-2": "Дата добавления",
    "button-3": "Популярность",
    "title": "Сортировать по"
  },
  "teacher-page": {
    "user-service-form": {
      "label1": "Subject",
      "label2": "Имя",
      "label3": "Короткое описание",
      "label4": "Цена за час",
      "placeholder1": "Subject",
      "placeholder2": "Название курса",
      "placeholder3": "Короткое описание курса",
      "placeholder4": "Подробное описание курса",
      "remove": "Удалить",
      "save": "Сохранить"
    }
  },
  "teacher-profile": {
    "action-add-individual-lesson": "Добавить занятие в каталог",
    "edit-service": "Редактировать индивидуальное занятие",
    "individual-lessons-title": "Индивидуальные занятия",
    "meta-title": "Анкета преподавателя",
    "new-service": "Новое индивидуальное занятие",
    "title": "Анкета преподавателя"
  },
  "teacher-steps": {
    "alternative-titles": {
      "bank_card": "Ваши банковские карты",
      "schedule": "Ваши рабочие дни",
      "services": "Доступные занятия",
      "use_mobile_app": "Мобильное приложение",
      "verification": "Анкета преподавателя"
    },
    "placeholder": {
      "bank_card": {
        "label": "Для чего мне привязывать карту?",
        "link": "Привяжите карту",
        "text": "Привязка карты позволит вам принимать оплату за свои услуги и получать гарантированные выплаты."
      },
      "schedule": {
        "label": "Для чего это нужно?",
        "link": "Укажите рабочее время",
        "text": "Ученики смогут записываться к вам на индивидуальные занятия, если у вас будет достаточно рабочих дней и свободного для записи времени."
      },
      "services": {
        "label": "Для чего это нужно?",
        "link": "Добавить занятие в каталог",
        "text": "Ученики смогут записываться к вам на индивидуальные занятия, если опишите, чем можете быть полезны."
      },
      "use-mobile-app": {
        "label": "Для чего мне приложение?",
        "text": "С помощью мобильного приложения Onclass вы сможете быстро получать уведомления обо всех событиях, а также переписываться в чате с другими участниками или преподавателями.",
        "text2": "Отсканируйте QR-код с помощью камеры вашего смартфона и перейдите по ссылке",
        "text-done": "С помощью приложение Onclass вы сможете оперативно получать уведомления обо всех событиях, а также осуществялять переписку в чате."
      },
      "verification": {
        "label": "Что дает верификация?",
        "text": "Ваш профиль появится в каталоге преподавателей и будет доступен всем пользователям Onclass. Вы сможете создавать публичные занятия на которые сможет записаться любой желающий."
      }
    },
    "title": "Начните преподавать на Onclass",
    "text": "Чтобы начать пользоваться всеми преимуществами платформы Onclass в качестве преподавателя, пожалуйста, выполните 5 следующих шагов. Если у вас возникнут сложности, вы всегда можете обратиться в поддержку и <a class=\"link\">получить консультацию.</a>",
    "titles": {
      "bank_card": "Шаг 4. Заполните финансовый профиль и привяжите банковскую карту",
      "schedule": "Шаг 3. Установите расписание",
      "services": "Шаг 2. Добавьте свои индивидуальные занятия",
      "use_mobile_app": "Шаг 5. Скачайте и установите мобильное приложение Onclass",
      "verification": "Шаг 1. Пройдите верификацию",
      "bank_card_done": "Банковская карта",
      "schedule_done": "Календарь",
      "services_done": "Популярные занятия",
      "use_mobile_app_done": "Мобильное приложение Onclass",
      "verification_done": "Профиль"
    },
    "tooltip-text": "Заполните профиль и отправьте его на модерацию. После этого вы сможете добавить ваши доступные занятия",
    "working-days": "Рабочих дней:"
  },
  "tutors": {
    "filter": "Фильтр",
    "meta-favorites-title": "Favorite tutors",
    "meta-mine-title": "Мои наставники",
    "meta-title": "Репетиторы",
    "page-header-title": "Репетиторы",
    "search": "Поиск...",
    "tabs-all": "Все",
    "tabs-favorites": "Избранные",
    "tabs-mine": "Мои"
  },
  "user-menu": {
    "add-avatar": "Добавить фото",
    "items": {
      "edit-profile": "Редактировать",
      "payments": "Платежи и выплаты",
      "personal-profile": "Профиль",
      "settings": "Настройки",
      "signout": "Выйти",
      "signin": "Войти",
      "rules": "Правила для репетиторов",
      "support": "Поддержка",
      "how-onclass-work": "Как устроен Onclass?",
      "take-up-teaching": "Начать преподавать",
      "teacher-office": "Кабинет преподавателя",
      "introduction": "Введение"
    },
    "question": "Как ваше имя?"
  },
  "rules": {
    "widget": {
      "text": "Правила посещения занятий для репетиторов",
      "hide": "Скрыть",
      "more": "Подробнее"
    },
    "page": {
      "title1": "Правила посещения занятий для репетиторов",
      "text1": "Onclass ожидает от репетиторов профессионализма и пунктуальности.",
      "text2": "Это особенно важно для уроков с новыми учениками: если репетитор не придет на первый урок, ученик расстроится и разочаруется. Пропуск урока негативно отражается не только на конкретном репетиторе, но и на репутации всего сообщества репетиторов Onclass.",
      "text3": "Если репетитор пропустит или отменит урок менее чем за 24 часа до его начала, он получит штраф в виде:",
      "option1-1": "Негативного автоотзыва",
      "option1-2": "Штрафа в размере 50% стоимости занятия",
      "title2": "Как репетиторам избежать пропуска уроков",
      "option2-1": "Правильно настройте время, доступное для бронирования, чтобы избежать неожиданных бронирований.",
      "option2-2": "Следите за актуальностью времени занятий в календаре Onclass.",
      "option2-3": "Регулярно проверяйте календарь Onclass и сообщения. Скачайте приложение Onclass Messanger для iOS и Android, чтобы получать уведомления о новых бронированиях или планировать изменения.",
      "option2-4": "Если неожиданные обстоятельства не позволяют провести урок в назначенное время, согласуйте с учеником другое время и перенесите урок.",
      "accept": "Я ознакомился с правилами и принимаю их",
      "informed": "Ознакомлен"
    }
  },
  "review": {
    "edit-review": "Изменить отзыв",
    "private-class": "Приватное занятие",
    "class": "Занятие"
  },
  "user-profile": {
    "accounts": "Аккаунты",
    "billing": {
      "balance_type": {
        "block": "Blocked",
        "fine": "Fine",
        "plan_grants": "Plan grants",
        "paid": "Платно",
        "onclass_fee": "Комиссия Onclass",
        "primary": "Primary",
        "transaction_fee": "Transaction fee"
      },
      "cards": {
        "remove": "Удалить"
      },
      "history": {
        "amount": "Сумма",
        "from_account": "From account",
        "from_balance": "From balance",
        "operation_name": "Operation",
        "to_account": "To account",
        "to_balance": "To balance"
      },
      "operation": {
        "bonus": "Bonus",
        "bonus_credits_subscription": "Bonus credits",
        "coupon": "Coupon",
        "gift": "Gift",
        "meeting_providing": "Meeting providing",
        "paid_meeting_deposit": "Paid meeting deposit",
        "paid_meeting_deposit_undo": "Withdraw meeting deposit",
        "payment": "Пополнение счета",
        "payment_processing_fee": "Payment processing fee",
        "payout": "Вывод со счета",
        "payout_processing_fee": "Payout processing fee",
        "platform_fee": "Platform fee",
        "public_meeting_fine": "Public meeting fee",
        "purchase_content": "Purchase content",
        "purchase_credits": "Purchase credits",
        "purchase_credits_subscription": "Purchase subscription",
        "purchase_group_access": "Purchase group access",
        "purchase_meeting": "Purchase meeting",
        "refund_content": "Content refund",
        "refund_group_access": "Refund group access",
        "refund_meeting": "Purchase meeeting refund",
        "reward_content": "Reward for content",
        "reward_content_succeed": "reward_content_succeed",
        "reward_meeting": "Reward for meeting",
        "reward_meeting_succeed": "reward_meeting_succeed",
        "reward_referral": "Reward for referral",
        "reward_referral_purchase_succeed": "reward_referral_purchase_succeed",
        "reward_referral_subscription_succeed": "reward_referral_subscription_succeed",
        "reward_teacher_verified": "reward_teacher_verified",
        "reward_user_profile_done": "reward_user_profile_done",
        "reward_user_signed_up": "reward_user_signed_up",
        "safe_card_attach": "Card attach",
        "purchase_collection": "Покупка коллекции",
        "purchase_course": "Покупка курса",
        "meeting_checkout": "Выплата за занятие",
        "purchase_meeting_by_safe_deal": "Оплата занятия по безопасной сделке",
        "switch_to_billing_plan": "Активация тарифного плана",
        "admin_correction": "Пополнение кредитного счета",
        "renew_billing_plan": "Возобновление подписки"
      },
      "status": {
        "canceled": "canceled",
        "failed": "failed",
        "pending": "pending",
        "processing": "processing",
        "succeed": "succeed"
      }
    },
    "certifications": "Сертификаты",
    "create-lesson": "Создать урок",
    "create-publication": "Создать публикацию",
    "edit": "Редактировать",
    "i-speak": "Язык:",
    "i-study": "Изучаю разные предметы:",
    "i-teach": "Изучаю разные предметы:",
    "individual-sessions-create": "Добавить индивидуальное занятие",
    "individual-sessions-title": "Популярные занятия",
    "leave-message": "Отправить сообщение",
    "meeting-reviews-sort-latest": "Недавно",
    "meeting-reviews-sort-rating-asc": "Положительные",
    "meeting-reviews-sort-rating-desc": "Отрицательные",
    "menu-complain": "Пожаловаться",
    "menu-unsubscribe": "Отписаться",
    "meta-teacher-title": "Изучайте {{subjects}} онлайн с {{name}}",
    "meta-teacher-title2": "Изучайте {{subjects}} онлайн вместе с {{name}}",
    "no-info": "Пользователь не указал информацию.",
    "no-lessons": "Нет публикаций",
    "no-publications": "Нет публикаций",
    "promo-info": {
      "answer1": "Создавай уроки для самостоятельного изучения",
      "answer2": "Проводи онлайн-уроки",
      "question": "Как получить отзывы и повысить свой рейтинг преподавателя?",
      "sign-lesson": "Sign up for a lesson"
    },
    "provide-info": "Редактировать",
    "reviews": "Отзывы",
    "reviews-see-all": "Показать все",
    "reviews-see-more": "Показать ещё",
    "roll-up": "свернуть",
    "send": "Сообщение",
    "service-card": {
      "alert": {
        "close": "Закрыть",
        "remove": "Удалить",
        "text": "В случае отказа список индивидуальных занятий не будет изменен.",
        "title": "Удалить индивидуальное занятие?",
        "have-collection-title": "Карта не может быть удалена",
        "have-collection-text": "У Вас есть опубликованные коллекции. Для удаления карты, Вам необходимо удалить все платные коллекции"
      },
      "edit": "Редактировать",
      "no-free-time": "Недоступен",
      "price": "Стоимость участия:",
      "remove": "Удалить",
      "signup": "Записаться"
    },
    "teaching-block": {
      "alert": {
        "ok": "Ок",
        "title": "Поля не заполнены",
        "text": "Заполните поля в уже созданной дисциплине, прежде чем добавлять следующую. "
      }
    },
    "show-more": "показать больше",
    "sorter": {
      "date": "По дате",
      "rating": "По рейтингу"
    },
    "speak-on": "Говорит:",
    "stat-title-1": "Подписчиков",
    "stat-title-2": "Подписан",
    "stat-title-3": "Уроки",
    "stat-title-4": "Очков",
    "stat-title-5": "Уроков пройдено",
    "stat-title-6": "Средний балл",
    "stat-title-7": "Учеников",
    "stat-title-8": "Отзывы",
    "stat-title-9": "Что-то еще",
    "stats": {
      "publications": "Публикации",
      "subscribers": "Подписчики",
      "subscriptions": "Подписки"
    },
    "subscribe": "Подписаться",
    "subscribe-user": "Подписаться",
    "subscribed": "Подписан",
    "tab-main": "Главная",
    "tab-classes": "Онлайн-занятия",
    "tab-lessons": "Уроки",
    "tab-schools": "Школы",
    "tab-feedback": "Отзывы",
    "tab-about": "Личная информация",
    "tab-about-student": "Об ученике",
    "tab-accounts": "Аккаунты:",
    "tab-certifications": "Сертификаты",
    "tab-education": "Образование",
    "tab-experience": "Опыт работы",
    "tab-learning": "Изучаю",
    "tab-publications": "Публикации",
    "tab-collections": "Коллекции",
    "tab-teaching": "Преподаю",
    "tab-about-tutor": "О репетиторе",
    "teach": "Преподает:",
    "teacher": "Преподаватель",
    "guarantee-text": "3-дневная гарантия возврата денег",
    "subjects-taught": "О занятии",
    "placeholder": {
      "teaching": {
        "text": "Укажите предметы, которые вы преподаете"
      },
      "about": {
        "text": "Укажите информацию о себе",
        "link": "Перейти в профиль"
      },
      "Classes": {
        "text": "Ученики смогут записываться к вам на индивидуальные занятия, если опишите, чем можете быть полезны",
        "link": "Создать класс"
      },
      "collection": {
        "text": "Создавайте коллекции из ваших уроков, выставляйте их на продажу или используйте на занятиях",
        "link": "Создайте коллекцию"
      },
      "feedback": {
        "text": "Создавайте и проводите занятия, чтоб получать отзывы",
        "link": "Запланировать занятие"
      },
      "lesson": {
        "text": "Создавайте уроки и другой обучающий контент для Ваших онлайн занятий",
        "link": "Создать урок"
      },
      "school": {
        "text": "Создайте свою школу и получайте гарантированный доход",
        "link": "Создать школу"
      }
    },
    "teacher-stat": {
      "title-stat": "Статистика репетитора",
      "class": "Онлайн-занятия",
      "country": "Страна",
      "date": "Дата регистрации",
      "geo": "Гео",
      "last-activity-time": "Последняя активность",
      "not_verified": "На модерации",
      "publications": "Публикации",
      "rating": "Рейтинг учителя",
      "response-time": "Среднее время ответа",
      "status": "Статус",
      "students": "Студенты",
      "teacher": "Преподаватель",
      "title": "О преподавателе",
      "title2": "Другое",
      "tutor": "Репетитор",
      "average-rating": "Рейтинг",
      "total-publications": "Публикаций",
      "created-lessons": "Создано уроков"
    },
    "teaching": "Преподаю",
    "title-1": "Учебный материал",
    "title-2": "Пройденные уроки",
    "title-about": "Личная информация",
    "title-about-me": "О себе",
    "title-collections": "Коллекции",
    "title-certification": "Certification",
    "title-education": "Образование",
    "title-experience": "Опыт работы",
    "warning-no-teaching-subjects": "Укажите преподаваемые дисциплины, пожалуйста",
    "was": "был(а)",
    "online-now": "Сейчас в сети",
    "what-is-your-name": "Имя Фамилия",
    "you-no-info": "Пользователь не указал эту информацию.",
    "you-subscribed": "Вы подписаны"
  },
  "user-profile-filter": {
    "button-1": "Все",
    "button-2": "Уроки",
    "button-3": "Теория",
    "button-4": "Упражнения",
    "button-5": "Тесты",
    "button-6": "Видео",
    "button-7": "Аудио",
    "button-8": "Картинки",
    "button-9": "Словарный запас",
    "edu-material": "Учебный материал",
    "filter": "Фильтр",
    "show": "Показывать"
  },
  "user-service-card-full": {
    "all": "Все",
    "at-the-rate": "По оценке",
    "by-date": "По дате",
    "tabs": {
      "main": "Главная",
      "other-lessons": "Занятия",
      "reviews": "Отзывы",
      "schedule": "Расписание преподавателя",
      "video": "Видео о преподавателе"
    },
    "titles": {
      "about": "О занятии",
      "attach": "Вложения",
      "latest-reviews": "Последние отзывы",
      "other-user-services": "Другие занятия",
      "reviews": "Отзывы",
      "schedule": "Расписание преподавателя",
      "tags": "Теги",
      "video": "Видео о преподавателе",
      "school-video": "Видеопрезентация",
      "skills": "Чему вы научитесь?",
      "requirements": "Требования",
      "registered-class": "Вы записаны на это занятие",
      "accept-required": "Вы записались на это занятие {{date}}, но не оплатили его"
    }
  },
  "collection-checkout-wizard": {
    "collection-author": "Автор коллекции:",
    "average-rating": "Средняя оценка:",
    "lessons-done": "Уроков проведено:",
    "select-payment-method": "Выберите способ оплаты"
  },
  "payment-checkout-wizard": {
    "steps": {
      "meeting": {
        "teacher": "Репетиторы",
        "detail": "Подробнее",
        "date-and-time": "Дата и Время",
        "payment": "Пополнение счета"
      },
      "user-service": {
        "teacher": "Репетиторы",
        "detail": "Выбор урока",
        "date-and-time": "Дата и Время",
        "payment": "Пополнение счета"
      },
      "collection": {
        "teacher": "Репетиторы",
        "detail": "Тип лицензии",
        "date-and-time": "Дата и Время",
        "payment": "Пополнение счета"
      },
      "tariff-plan": {
        "teacher": "Репетиторы",
        "detail": "Тарифный план",
        "date-and-time": "Дата и Время",
        "payment": "Пополнение счета"
      },
      "paywall": {
        "teacher": "Репетиторы",
        "detail": "Тарифный план",
        "date-and-time": "Дата и Время",
        "payment": "Пополнение счета"
      },
      "credits": {
        "teacher": "Репетиторы",
        "detail": "Выбор кредитов",
        "date-and-time": "Дата и Время",
        "payment": "Пополнение счета"
      }
    }
  },
  "user-service-checkout-wizard": {
    "average-rating": "Оценка:",
    "carried-out": "Занятий:",
    "classes": "Онлайн-занятия",
    "cost": "Стоимость участия",
    "hour": "час",
    "lesson-cost": "Стоимость урока",
    "is-my-lesson-text": "Вы не можете записаться на собственное занятие",
    "is-my-collection-text": "Вы не можете купить свою коллекцию",
    "meetings": "занятий",
    "next": "Далее",
    "own-service-error": "Вы не можете записаться на собственное занятие",
    "pay": "Оплатить",
    "pay-failed": "Оплата не прошла!",
    "pay-success": "Оплата прошла успешно!",
    "unsub-success": "Ваша подписка отменена!",
    "unsub-failed": "Ошибка отписки!",
    "select": "Выбрать",
    "selected-activities": "Выбранное время:",
    "steps": {
      "date-and-time": "Дата и Время",
      "lesson-selection": "Выбор урока",
      "payment": "Пополнение счета",
      "teacher-selection": "Репетиторы"
    },
    "teacher": "Репетитор:",
    "to-complete": "To complete",
    "close": "Закрыть",
    "total": "Итого",
    "try-again": "Попытаться еще",
    "subscribe": {
      "completetext": "Ваш текущий тариф {{plan}}"
    },
    "unsubscribe": {
      "completetext": "Вы автоматически перейдете на тариф Free {{date}} по окончанию текущей подписки. Стоимость тарифа \nFree составит $0"
    },
    "subscribe-badge-message": "Подпишитесь на регулярные занятия с преподавателем. Отменить подписку можно в любой момент в разделе",
    "payments-payouts": "Платежи и выплаты",
    "purchasing-tariff": "Покупка тарифного плана",
    "purchasing-credits": "Покупка кредитов",
    "my-subscriptions": "Мои подписки",
    "newsubscribe": "Подписаться за {{ amount }} в неделю",
    "newpay": "Оплатить {{ amount }}",
    "no-selected-time": "Не выбрано ни одного занятия",
    "classes-per-week": "{{ times }} занятий в неделю",
    "per-week": "в неделю",
    "from": "C",
    "every": "по следующим дням:",
    "in": "в",
    "see-all": "См. все"
  },
  "user-service-form": {
    "label1": "Имя",
    "label2": "Короткое описание",
    "label3": "Полное описание",
    "label4": "Цена занятий",
    "placeholder1": "Название курса",
    "placeholder2": "Короткое описание курса",
    "placeholder3": "Подробное описание курса",
    "remove": "Удалить",
    "save": "Сохранить"
  },
  "user-service-form-2": {
    "150-characters": "Минимум 150 символов",
    "category-label": "Категории",
    "category-placeholder": "Не выбрано",
    "create-action": "Создать",
    "create-title": "Новое индивидуальное занятие",
    "description-placeholder": "Описание",
    "discount-label": "Скидка",
    "discount-placeholder": "Не выбрано",
    "duration-label": "Длительность занятия",
    "duration-placeholder": "Не выбрано",
    "edit-title": "Приватный урок",
    "error-not-chosen": "Не выбрано",
    "error-required-to-fill": "Обязательно для заполнения",
    "excerpt-placeholder": "Подзаголовок",
    "language-label": "На каком языке проводится занятие?",
    "name-placeholder": "Название",
    "preview-action": "Предпросмотр",
    "price-tier-label": "Стоимость участия",
    "price-tier-placeholder": "Не выбрано",
    "save-action": "Сохранить",
    "service-label": "Информацию о занятии",
    "subject-label": "Subject",
    "subject-placeholder": "Не выбрано",
    "text1": "Вложения",
    "text2": "(Не обязательно)",
    "text3": "Вы можете прикрепить к занятию изображения, презентацию о вашем занятии или скриншоты ранее проведенных занятий. Это поможет вашим потенциальным ученикам узнать о Вас больше."
  },
  "user-service-sidebar-widget": {
    "hourly-rate": "Часовая ставка от",
    "no-free-time": "Недоступен",
    "signup": "Sign up for a lesson"
  },
  "user-widget": {
    "send-message": "Связаться с репетитором",
    "subscribe": "Подписаться"
  },
  "user-widget-20210721": {
    "price-title": "Индивидуальные занятия от:",
    "roll-up": "Развернуть",
    "unsubscribe": "Отписаться"
  },
  "users": {
    "cancel": "Отмена",
    "cause1": "Порнография",
    "cause2": "Рассылка спама",
    "cause3": "Оскорбительное поведение",
    "cause4": "Мошенничество",
    "cause5": "Рекламная страница, засоряющая поиск",
    "cause6": "Клон моей страницы (или моя старая страница)",
    "comments-label": "Комментарий модератору",
    "comments-placeholder": "Опишите проблему",
    "complain": "Пожаловаться",
    "complain-subtitle": "Пожалуйста, сообщите нам причину жалобы:",
    "complain-title": "Жалоба на пользователя",
    "leave-message": "Сообщение",
    "meta-my-students-title": "Мои ученики",
    "meta-subscribers-title": "Подписаны на меня",
    "meta-subscriptions-title": "Мои подписки",
    "meta-title": "Пользователи",
    "page-header-title": "Пользователи",
    "search": "Поиск...",
    "send": "Отправить",
    "subscribe": "Подписаться",
    "tabs-all": "Все",
    "tabs-students": "Мои студенты",
    "tabs-subscribers": "Подписчики",
    "tabs-subscriptions": "Пользователи",
    "tabs-other-users": "Другие пользователи",
    "unsubscribe": "Отписаться",
    "you-subscribed": "Вы подписаны",
    "write": "Написать сообщение",
    "its-you": "Это вы",
    "exclude-from-class": "Exclude from class",
    "kick": "Удалить"
  },
  "vacation-form": {
    "label": "Режим отпуска включен",
    "label-off": "Режим отпуска выключен",
    "save": "Сохранить",
    "saved": "Сохранено. Расписание будет обновлено через некоторое время.",
    "text": "Включите режим отпуска, если собираетесь отдохнуть или возникли непредвиденные обстоятельства. Если данный режим включен, установленное рабочее время будет скрыто из расписания для учеников, но сохранится в настройках.",
    "title": "Режим отпуска",
    "from": "C",
    "to": "до"
  },
  "vacation-informer": {
    "vacation-dates": "У вас отпуск с {{begin_at}} по {{end_at}}, к вам на это время никто не запишется.",
    "events-count": "Не забудьте про {{count}} занятия в этот период, их можно перенести или отменить.",
    "go-to-calendar": "To the calendar"
  },
  "verification-widget": {
    "actions": {
      "cancel": "Отозвать заявку на модерацию",
      "edit": "Редактировать профиль",
      "fill": "Заполнить профиль",
      "send": "Отправить на модерацию"
    },
    "description": {
      "about": "Подробнее о верификации",
      "changed": "Профиль изменен, требуется повторная проверка.",
      "declined-1": "Запрос на изменение данных профиля",
      "declined-2": "отклонен",
      "incomplete": "Пожалуйста заполните профиль преподавателя, чтобы пройти верификацию и начать полноценно пользоваться платформой.",
      "resent": "Отправлен запрос на изменение данных в профиле."
    },
    "edit-link": "Редактировать",
    "no-name": "Укажите имя",
    "profile": "Профиль",
    "status": {
      "approved": "Проверено",
      "changed": "Профиль изменен",
      "declined": "Не проверено",
      "incomplete": "Профиль не заполнен",
      "not_verified": "Не проверено",
      "redeclined": "Не проверено",
      "resent": "На модерации",
      "sent": "На модерации"
    },
    "teacher-status": "Профиль преподавателя:"
  },
  "webinar-widget": {
    "action": "Выберите дату и время занятия",
    "text": "Мы проведем для вас <span class=\"highlight\">бесплатный</span> обучающий вебинар, на котором продемонстрируем все возможности онлайн-платформы Onclass. Чтобы записаться, выберите удобную дату и время 🙂",
    "title": "Обучающий вебинар по платформе Onclass"
  },
  "header-titles": {
    "news": "Новость",
    "profile": "Профиль",
    "classrooms": "Учебные классы",
    "events": "Занятия",
    "teachers-room": "Tutor's office",
    "teachers-room-plan": "Платежный профиль",
    "schedule": "Календарь",
    "constructor": "My content",
    "users": "Пользователи",
    "schools": "Школы"
  },
  "schools": {
    "stub": "У вас нет собственных школ",
    "meta-title": "Школы",
    "page-header-title": "Школы"
  },
  "school-header": {
    "title": "Школы",
    "description": "Каталог школ, созданных преподавателем",
    "actions": {
      "create": "Создать школу",
      "schedule": "Запланировать занятие",
      "chat": "Чат",
      "join": "Вступить"
    },
    "status": {
      "tutor": "Вы преподаватель",
      "owner": "Вы основатель",
      "student": "Вы студент"
    },
    "leave": {
      "alert-leave": "Покинуть школу",
      "alert-cancel": "Отмена",
      "alert-title": "Вы действительно хотите покинуть эту школу?",
      "alert-body": "Если вы откажетесь, вы останетесь участником этой школы"
    }
  },
  "school-card": {
    "teachers": "Преподаватели школы:",
    "subjects": "Дисциплины, которые преподают в школе:",
    "actions": {
      "share": "Поделиться",
      "edit": "Редактировать школу",
      "delete": "Удалить школу",
      "complain": "Пожаловаться",
      "join": "Вступить",
      "enter": "Войти",
      "add-favorites": "Добавить в избранные",
      "delete-favorites": "Удалить из избранных",
      "details": "Подробнее",
      "open": "Открыть"
    },
    "status": {
      "owner": "Вы основатель",
      "tutor": "Вы преподаватель",
      "student": "Вы студент"
    },
    "rating": "Рейтинг школы:",
    "students": "Ученики:",
    "classes": "Занятий:",
    "from": "от"
  },
  "school-form": {
    "header": "Новая школа",
    "action": "Создать школу",
    "message": "Не выбрано",
    "message1": "Поле обязательно для заполнения",
    "message2": "Выберите язык",
    "step-title": {
      "subjects": "Дисциплины",
      "school-info": "Информация о школе",
      "language": "На каких языках проводятся занятия?"
    }
  },
  "invite-to-school": {
    "header1": "Назначение преподавателя",
    "header2": "Назначение владельца",
    "email": "E-mail адреса через запятую",
    "option1": "Пригласите пользователей по электронной почте",
    "option2": "Или воспользуйтесь пригласительной ссылкой:",
    "action": "Приглашение"
  },
  "school-user-card": {
    "status": "Назначен",
    "action": "Назначить"
  },
  "delete-school": {
    "header": "Удалить школу?",
    "text": "Школа будет удалена из списка Мои школы.",
    "close": "Закрыть",
    "delete": "Удалить"
  },
  "school-main-tabs": {
    "main": "Главная",
    "tutors": "Репетиторы",
    "classes": "Онлайн-занятия",
    "feed": "Лента",
    "educational-material": "Уроки",
    "add-educational-material": "Добавить учебный материал",
    "classrooms": "Учебные классы",
    "feedback": "Отзывы"
  },
  "school-tabs": {
    "main": "Информация о школе",
    "calendar": "Календарь",
    "calculations": "Расчеты",
    "participants": "Участники",
    "owners": "Владельцы",
    "set-tutors": "Назначение репетиторов",
    "classes": "Занятия школы",
    "card": "Банковская карта",
    "accounts": "Счета для выплат",
    "status": "Статус школы"
  },
  "school-stats": {
    "title-stat": "Статистика школы",
    "students": "Учеников",
    "classes": "Занятий",
    "rating": "Рейтинг"
  },
  "school-main": {
    "my": "Мои",
    "all": "Все"
  },
  "school-steps": {
    "about": "О школе",
    "step1": "Шаг 1. Заполните информацию о школе",
    "step2": {
      "title": "Назначьте преподавателей",
      "text": "Вы еще не пригласили ни одного преподавателя. Также вы можете назначить себя преподавателем.",
      "action": "Назначить преподавателя"
    },
    "step3": {
      "title": "Шаг 3. Добавьте доступные занятия",
      "text": "Укажите услуги, доступные в школе: подготовка к экзаменам, тренинги и т.д. Ученики смогут выбрать интересующие их услуги и записаться к вам на занятие",
      "action": "Добавить занятие в каталог"
    },
    "step4": {
      "title": "Шаг 4. Привяжите карту",
      "text": "Важно! Карты, привязанные к школе и к профилю преподавателя-основателя, должны быть разными",
      "action": "Привязать карту"
    },
    "step5": "Школа еще не опубликована в каталоге.",
    "step6": "Ваша школа верифицирована и опубликована в каталоге",
    "action": "Опубликовать"
  },
  "school-state-widget": {
    "state": "Статус школы:",
    "title": "Новая школа",
    "action": "Редактировать"
  },
  "school-state": {
    "published": "Опубликованные",
    "unpublished": "Не опубликована"
  },
  "school-management": {
    "settings": "Управление школой"
  },
  "school-onboarding": {
    "status": "Статус школы:",
    "instruction": "Пройдите верификацию, выполнив 4 простых шага",
    "step1": "Заполните описание",
    "step2": "Добавьте преподавателей",
    "step3": "Добавить доступные занятия",
    "step4": "Привяжите карту",
    "step5": "Опубликуйте школу"
  },
  "school-owners": {
    "header": "Владельцы",
    "message": "Сообщение"
  },
  "school-participants-widget": {
    "header": "Участники",
    "text": "Расскажите о вашей школе, пригласив участников по ссылке",
    "action": "Копировать ссылку",
    "action-done": "Скопировано"
  },
  "school-participants": {
    "owner": "Владельцы",
    "tutor": "Репетиторы",
    "students": "Студенты",
    "action-owner": "Назначить владельца",
    "action-tutor": "Назначить преподавателя",
    "status": "Это вы"
  },
  "school-schedule": {
    "header": "Календарь",
    "text": "В календаре будут отображаться рабочие дни нанятых преподавателей. Можно посмотреть расписание всех сразу или каждого по отдельности. Преподаватели указывают рабочее время в своем профиле.",
    "tab": "Все"
  },
  "school-calculation-banner": {
    "header": "Как распределяется доход?",
    "item1": "Ученик вносит 100% оплаты за проведенное занятие",
    "item2": "Onclass получает фиксированный процент (15%)",
    "item3": "Распределить процент между школой и преподавателями вы можете самостоятельно. Каждому преподавателю можно установить свой процент"
  },
  "school-calculation": {
    "tutor": "Репетиторы",
    "tutorPer": "Репетитор, %",
    "school": "Школа, %",
    "onclass": "Onclass, %",
    "income-distribution": "Распределение дохода",
    "history-calculation": "История расчетов"
  },
  "school-participants-card": {
    "message": "Сообщение",
    "complain": "Пожаловаться",
    "delete-owner": "Убрать из владельцев",
    "delete-tutor": "Убрать из репетиторов",
    "add-student": "Перевести в студенты?",
    "student-message": "В случае отказа пользователь останется в роли учителя",
    "add-tutor": "Перевести в преподаватели?",
    "tutor-message": "В случае отказа пользователь останется в роли владельца школы",
    "close": "Закрыть",
    "apply": "Применить"
  },
  "school-placeholder": {
    "tutors": {
      "text": "Вы еще не наняли на работе ни одного преподавателя. Так же можно указать преподавателем себя",
      "link": "Нанять преподавателя"
    },
    "classes": {
      "text": "Ученики смогут записываться к вам на индивидуальные занятия, если опишите, чем можете быть полезны",
      "link": "Добавить занятие"
    },
    "feed": {
      "text": "Публикации нужны для поддержания активности и привлечения внимания аудитории",
      "link": "Создать публикацию"
    },
    "lessons": {
      "text": "Добавляйте учебные материалы, созданные в конструкторе",
      "link": "Добавить учебный материал"
    },
    "classrooms": {
      "text": "Классная комнтата — это группа учеников с которыми вы регулярно занимаетесь и храните историю занятий",
      "link": "Создать учебный класс"
    },
    "feedback": {
      "text": "Отзывов о вашей школе пока никто не оставлял"
    }
  },
  "error": {
    "not-chosen": "Не выбрано",
    "required": "Поле обязательно для заполнения",
    "min": "Минимум {{n}} символов",
    "max": "Максимум {{n}} символов",
    "min-length": "Не менее {{n}} символов",
    "max-length": "Не более {{n}} символов",
    "whitespace": "Текст содержит только пробелы",
    "confirm": "Обязательно для подтверждения",
    "pattern": "Шаблон не совпадает"
  },
  "user-service-form-3": {
    "main-title": "Новое занятие в каталоге",
    "steps": {
      "title1": "Тип занятия",
      "title2": "Настройки занятия",
      "title3": "Информация о занятии",
      "title4": "Репетиторы"
    },
    "group-label": "Групповое",
    "single-label": "Индивидуальное",
    "upload-media-text": "Перетащите файл или нажмите кнопку выбрать",
    "types": {
      "label1": "Разовое занятие",
      "text1": "Размещается в каталоге автоматически, если указано рабочее время",
      "type-text1": "Разовое занятие с учеником, в рамках которого ученик гарантированно должен изучить одну тему. Данный тип занятия также подходит для пробных уроков и привлечение учеников на Ваши другие занятия и курсы.",
      "label2": "Регулярные занятия",
      "text2": "Размещается в каталоге автоматически, если указано рабочее время",
      "type-text2": "Проводятся по вашему рабочему графику. Ученик может записаться, выбрав свободное время и купив подписку. Оплата будет списываться с карты ученика каждые 7 дней с момента покупки и поступать на вашу банковскую карту.",
      "label3": "Мини - курс",
      "text3": "Требуется ручное размещение в календаре",
      "type-text3": "Это серия до 5 занятий, которые проводятся регулярно несколько раз в неделю. В рамках мини-курса занятия объединены одной общей темой, которую изучит ученик за этот курс.",
      "label4": "Курс",
      "text4": "Требуется ручное размещение в календаре",
      "type-text4": "Это серия от 5 занятий, которые проводятся регулярно несколько раз в неделю. В рамках мини-курса занятия объединены одной общей темой, которую изучит ученик за этот курс."
    },
    "titles": {
      "title1": "Видеопрезентация",
      "title2": "Требования",
      "title3": "Чему научатся ученики",
      "title4": "Вложения",
      "title5": "На каком языке проводится занятие?"
    },
    "fields": {
      "label1": "Дни проведения занятий:",
      "label2": "Количество занятий:",
      "label3": "Продолжительность:",
      "label4": "Стоимость участия:",
      "label5": "Скидка:",
      "label6": "Предмет",
      "label7": "Категории",
      "label8": "Количество участников:",
      "label9": "Возраст:",
      "label10": "Уровень:",
      "label11": "Не обязательно",
      "placeholder1": "Не выбрано",
      "placeholder2": "Название",
      "placeholder3": "Описание",
      "placeholder4": "Требования",
      "placeholder5": "Навыки",
      "placeholder6": "Без скидки"
    },
    "bullet": {
      "title": "Советы как сделать хорошее видео",
      "item1": "Запишите горизонтальное видео",
      "item2": "Длительность видео должна быть не менее 30 секунд и не более 2 минут",
      "item3": "Используйте нейтральный фон",
      "item4": "Ваше лицо и глаза должны быть полностью видны",
      "item5": "Не используйте презентации"
    },
    "attached-text": "Вы можете прикрепить к занятию изображения, презентацию о вашем занятии или скриншоты ранее проведенных занятий. Это поможет вашим потенциальным ученикам узнать о Вас больше",
    "actions": {
      "save": "Сохранить",
      "create": "Создать",
      "previews": "Предпросмотр"
    },
    "errors": {
      "error1": "Обязательно к выбору",
      "error2": "Выберите нужный вариант",
      "error3": "Поле обязательно для заполнения",
      "error4": "Введите хотя бы 100 символов"
    },
    "weekdays-empty": {
      "text": "Согласно вашему",
      "link": "рабочему расписанию"
    },
    "teacher-selector": {
      "placeholder": "Выбрать репетитора",
      "rating": "Рейтинг репетитора:",
      "no-teacher": "Вы еще не назначили репетитора по выбранному предмету"
    },
    "video-placeholder": "Преподаватель не добавил видеопрезентацию",
    "time-duration": {
      "minutes": "минут"
    },
    "subject-popover": {
      "text": "Укажите предметы, которые вы преподаете.",
      "link": "Перейти в анкету"
    },
    "show-more": "Дополнительно",
    "period": {
      "per-class": "за занятие",
      "per-week": "в неделю",
      "for-class": "за 1 занятие"
    }
  },
  "days-of-week": {
    "su": "Вс",
    "mo": "Пн",
    "tu": "Вт",
    "we": "Ср",
    "th": "Чт",
    "fr": "Пт",
    "sa": "Сб"
  },
  "event-calendar": {
    "context-menu": {
      "schedule-event": "Запланировать событие",
      "assign-class": "Назначить занятие",
      "available-for-booking": "Доступен для записи ",
      "not-available-for-booking": "Не доступен для записи"
    },
    "status": {
      "finished": "Прошедшее занятие",
      "scheduled": "Запланированное занятие",
      "accept-required": "Неподтвержденное занятие",
      "started": "Текущее занятие",
      "upcoming": "Предстоящее занятие",
      "failed": "Несостоявшееся занятие",
      "past-date": "Прошедшая дата",
      "available": "Рабочее время",
      "day-off": "Нерабочее время",
      "vacation-days": "Дни отпуска"
    },
    "multi-selector": {
      "hint-text": "Для выбора доступны занятия, которые могут быть проведены в {{day}}",
      "intersection-text": "Выбрано одно или несколько занятий, которые при публикации пересекутся с занятиями, на которые уже записаны ученики.",
      "overlap-text": "{{date}} пересечение с занятием {{title}}",
      "add": "Добавить в календарь",
      "remove": "Удалить из календаря",
      "change": "Изменить календарь",
      "other": "Другие занятия",
      "placeholder-text": "У вас еще нет доступных занятий",
      "placeholder-link": "Создать услугу"
    },
    "empty-text": "Подготавливаем ваше <br> расписание",
    "event-popover": {
      "cancel-event": "Отменить событие"
    },
    "types": {
      "single": "Индивидуальное",
      "regular": "Регулярные занятия",
      "mini": "Мини-курс",
      "course": "Курс",
      "individual": "Индивидуальное",
      "group": "Групповое",
      "webinar": "Вебинар",
      "stream": "Стрим",
      "talk": "Общение"
    },
    "ongoing": "регулярное",
    "participants-limit": {
      "private": "1 на 1",
      "group": "Групповое"
    }
  },
  "first-contact": {
    "widget": {
      "title": "Первое знакомство",
      "text": "Доступно расскажем об основных возможностях платформы Onclass",
      "action-label": "Начать",
      "action-close": "Закрыть"
    },
    "first-contact-selector": {
      "role": {
        "student": "Для студентов",
        "tutor": "Репетиторы",
        "methodist": "Методистам",
        "school": "Школы"
      },
      "titles": {
        "student": "Удобная платформа для <br> онлайн-обучения!",
        "tutor": "Новые возможности для <br> онлайн-обучения!",
        "methodist": "Монетизируйте свои знания <br> на платформе Onclass!",
        "school": "Откройте свою <br> собственную онлайн-школу <br> на платформе Onclass!"
      },
      "texts": {
        "student": "Onclass это новые возможности для онлайн-обучения по любым дисциплинам. Вам не нужно покупать учебники и записывать упражнения в тетрадь — преподаватели уже подготовили все материалы для вас.",
        "tutor": "Onclass — это удобная онлайн-платформа занятий по любым дисциплинам. Занимайтесь индивидуально или с группой учеников из любой точки мира по комфортному для себя графику.",
        "methodist": "Onclass - это удобная платформа для создания ваших авторских уроков, коллекций и курсов. Публикуйте материалы, устанавливайте цену и получайте выплаты на свою карту.",
        "school": "Onclass - это удобная и простая платформа для запуска своей онлайн школы."
      },
      "list": {
        "student": {
          "item1": "Коллекция бесплатных уроков",
          "item2": "Онлайн-класс с интерактивной доской",
          "item3": "Преподаватели со всего мира по разным дисциплинам",
          "item4": "Гарантия безопасной сделки"
        },
        "tutor": {
          "item1": "Конструктор уроков",
          "item2": "Виртуальный класс с интерактивной доской",
          "item3": "Гарантия безопасной сделки"
        },
        "methodist": {
          "item1": "Создавайте учебные пособия с помощью встроенного конструктора",
          "item2": "Объединяйте созданные материалы в коллекции",
          "item3": "Продавайте готовые планы занятий на платформе",
          "item4": "Получайте деньги на свою карту по безопасной сделке"
        },
        "school": {
          "item1": "Создавайте уроки с помощью встроенного конструктора",
          "item2": "Объединяйте созданные материалы в коллекции и курсы",
          "item3": "Добавляйте сотрудников и настраивайте разграничение доступа",
          "item4": "Получайте деньги на свою карту по безопасной сделке"
        }
      }
    },
    "first-contact-tools": {
      "title": "Все необходимые инструменты на одной платформе",
      "action": "Создать урок",
      "texts": {
        "student": "Занимайтесь по готовым программам индивидуально или в группе с <br> другими учениками. Поставьте цель в обучении и достигните ее при <br> поддержке преподавателей Onclass.",
        "tutor": "Пригласите одного или группу учеников в класс для проведения <br> интерактивного онлайн-занятия. Используйте видео- и аудиосвязь, чат для <br> общения и доску с возможностью рисования.",
        "methodist": "Создайте свое первое методическое пособие с помощью встроенного <br> конструктора прямо на платформе. Добавьте необходимое количество блоков или <br> слайдов и выберите тип задания или контента, который подходит под ваши цели.",
        "school": "Создайте свою собственную онлайн-школу, используя возможности <br> Onclass. Мы возьмем фиксированный процент 15%, а остальное вы <br> сможете разделить между школой и преподавателями самостоятельно."
      }
    },
    "first-contact-additional-tools": {
      "titles": {
        "tutor": {
          "title1": "Автоматическая <br> проверка заданий",
          "title2": "Домашняя <br> и самостоятельная <br> работа",
          "title3": "История занятий <br> в классе"
        },
        "student": {
          "title1": "Автоматическая <br> проверка заданий",
          "title2": "Домашняя <br> и самостоятельная <br> работа",
          "title3": "Контроль <br> успеваемости"
        },
        "methodist": {
          "title1": "Автоматическая <br> проверка заданий",
          "title2": "Домашняя <br> и самостоятельная <br> работа",
          "title3": "Контроль <br> успеваемости"
        },
        "school": {
          "title1": "Автоматическая <br> проверка заданий",
          "title2": "Домашняя <br> и самостоятельная <br> работа",
          "title3": "Контроль <br> успеваемости"
        }
      },
      "text": "Пройдите несколько бесплатных уроков у разных преподавателей, чтобы определиться с дальнейшим планом обучения",
      "text2": "Другие пользователи смогут пользоваться вашим <br> контентом, а вы получите деньги прямо на вашу карту.",
      "action1": "Создать урок",
      "action2": "Создать школу",
      "action3": "Создать урок"
    },
    "first-contact-free-lesson": {
      "title": "Вы можете познакомиться с функционалом платформы <br> на бесплатном уроке у понравившегося преподавателя.",
      "text": "В разделе \"Уроки\" вы найдете множество <br> бесплатного обучающего контента",
      "list": {
        "title1": "Популярные уроки",
        "text1": "Другие пользователи уже прошли некоторые занятия на платформе. Доверьтесь их отзывам и оценкам и приступайте к обучению!",
        "title2": "Что посмотреть",
        "text2": "На платформе собраны интересные видеоуроки от разных преподавателей. Выберите интересующую тему и наслаждайтесь просмотром.",
        "title3": "Что послушать",
        "text3": "Обучающие подкасты или истории на иностранном языке можно найти в разделе аудио.",
        "title4": "Упражнение дня",
        "text4": "Система платформы будет всегда показывать вам интересные упражнения по вашим предпочтениям."
      }
    },
    "choose-tutor": {
      "title": "А еще вы можете выбрать понравившегося репетитора и <br> записаться самостоятельно к нему на занятие",
      "list": {
        "title1": "Дополнительные материалы",
        "text1": "На платформе Onclass вы можете найти множество коллекций и сборников упражнений по определенной тематике. Некоторые материалы могут быть платными, так как идут дополнением к базовым материалам преподавателей.",
        "title2": "Проверенные онлайн-школы",
        "text2": "Вы можете выбрать преподавателя самостоятельно или обратиться в онлайн-школу, где вам подберут удобный график, программу обучения и лучшего преподавателя, который поможет достичь желаемого результата.",
        "title3": "Безопасность и надежность",
        "text3": "Рассчитывайтесь с преподавателем так, как вам удобно. Оплачивайте занятия по системе безопасной сделки Onclass или лично, если между вами уже выстроены доверительные отношения.",
        "title4": "Комфотрные условия",
        "text4": "Занимайтесь где и когда вам удобно. Вы сами выбираете, как часто и как долго будете заниматься."
      }
    },
    "meeting-types": {
      "titles": {
        "student": "Выберите удобный формат обучения",
        "school": "Используйте все виды контента"
      },
      "tutor-list": {
        "title1": "Одно занятие",
        "text1": "Разовое занятие в конкретный день и время.",
        "title2": "Регулярные <br> занятия",
        "text2": "Повторяющиеся занятия в определенные дни.",
        "title3": "Курс",
        "text3": "Серия занятий рассчитанная на длительное время (от 5 дней)",
        "title4": "Мини-курс",
        "text4": "Серия занятий рассчитанная на короткое время ( от 2-х до 5 дней)",
        "title5": "Вебинар",
        "text5": "Формат вещания на большую аудиторию с возможностью обратной связи",
        "title6": "Стрим",
        "text6": "Формат вещания на большую аудиторию без возможности обратной связи"
      },
      "student-list": {
        "title1": "Групповые <br> занятия",
        "text1": "Занимайтесь в группе единомышленников, чтобы не терять мотивацию и добиться больших результатов.",
        "title2": "Единоразовое <br> занятие",
        "text2": "Выберете разовое занятие, если возникли вопросы по определенной теме или правилам.",
        "title3": "Курс",
        "text3": "Пройдите полноценный курс, чтобы углубиться в изучение интересующего предмета. Обучение длится от 5 дней",
        "title4": "Индивидуальные <br> занятия",
        "text4": "Обозначьте индивидуальные цели в обучении и достигайте их в удобном для себя темпе.",
        "title5": "Регулярные <br> занятия",
        "text5": "Договоритесь с преподавателем на определнные дни и время занятий, чтобы не сбавлять темп в обучении.",
        "title6": "Мини-курс",
        "text6": "Изучите основные моменты по желаемой дисциплине всего за несколько занятий. Обучение длится от 2 до 5 дней"
      },
      "methodist-list": {
        "title1": "Привяжите <br> банковскую карту",
        "text1": "Привяжите свою банковскую карту в личном кабинете, чтобы принимать платежи от пользователей со всего мира.",
        "title2": "Гарантия <br> безопасной сделки",
        "text2": "Воспользуйтесь системой безопасной сделки на платформе Onclass, чтобы не переживать о поступлении денег.",
        "title3": "Маркетинг <br> продвижение",
        "text3": "Ваши материалы увидят тысячи пользователей на нашей платфоме. Вы точно найдете своего покупателя."
      },
      "school-list": {
        "title1": "Уроки",
        "text1": "Создавайте неограниченное количество уроков с помощью встроенного конструктора прямо на платформе.",
        "title2": "Платные коллекции",
        "text2": "Объединяйте уроки в коллекции и продавайте их на платформе. Получите деньги прямо на вашу карту по системе безопасной сделки.",
        "title3": "Вебинары",
        "text3": "Заявите о себе и своей школе!Проводите полезные эфиры для пользователей платформы.",
        "title4": "Интерактивные <br> задания",
        "text4": "Тесты, вопросы с выбором правилного ответа, сопоставление вариантов и другие возможности сделать уроки интересными для учеников.",
        "title5": "Домашние <br> задания",
        "text5": "Установите верные значения в тестах и упражнениях при их создании, чтобы не тратить время на проверку уроков.",
        "title6": "Курсы",
        "text6": "Создавайте серию занятий по определенной теме для более подробного изучения. Длительность курса от 5 дней."
      }
    },
    "steps": {
      "title": "6 простых шагов, чтобы начать занятие",
      "text": "Готово! Вам придет напоминание о занятии, чтобы <br> вы точно его не пропустили!",
      "steps-list": {
        "text1": "Перейдите в раздел Занятия в верхней части экрана",
        "text2": "Выберите интересующую вас дисциплину",
        "text3": "Найдите подходящее занятие и нажмите кнопку “Записаться”",
        "text4": "Выберите удобную дату и время занятия",
        "text5": "Оплатите занятие банковской картой, чтобы забронировать его",
        "text6": "Установите приложение Onclass на свой смартфон, чтобы видеть уведомления о новых занятиях и общаться с преподавателями."
      }
    },
    "capabilities": {
      "titles": {
        "tutor": "Расширяй свои возможности <br> вместе с Onclass!",
        "methodist": "Почему работать с нами легко и удобно?",
        "school": "Расширяй свои возможности <br> вместе с Onclass!"
      },
      "tutor-list": {
        "title1": "Выбор любой дисциплины",
        "text1": "Вы можете преподавать любой предмет, в котором вы являетесь экспертом.",
        "title2": "Удобный график работы",
        "text2": "Самостоятельно устанавливаете свое расписание и продолжительность уроков. Совмещайте с основной работой или преподавайте full time.",
        "title3": "Быстрый поиск учеников",
        "text3": "Добавляйте интересный и полезный контент на платформу, чтобы новые ученики выбрали именно вас. Работая на платформе Onclass, вы получаете доступ к постоянно растущей базе учеников.",
        "title4": "Выплаты после каждого занятия",
        "text4": "Привяжите свою банковскую карту в личном кабинете, чтобы принимать платежи от учеников со всего мира.",
        "title5": "Маркетинг и продвижение",
        "text5": "Ваши уроки и курсы будут размещены в каталоге на нашей платформе, куда ежедневно заходят тысячи пользователей. Поэтому вы можете не беспокоиться о дополнительном продвижении.",
        "title6": "Гарантия безопасной сделки",
        "text6": "Воспользуйтесь системой безопасной сделки на платформе Onclass, если вы только начинаете занятие с новым учеником.",
        "title7": "Работайте из любой точки мира",
        "text7": "Проводите занятия онлайн в удобное время из любого места, где есть интернет.",
        "title8": "Развивайте свой личный бренд",
        "text8": "Заявите о себе на платформе, создавая уроки и курсы по авторским методикам.",
        "title9": "Оставайтесь на связи",
        "text9": "Установите приложение Onсlass на свой смартфон, чтобы видеть уведомления о новых занятиях и общаться с учениками.",
        "title10": "Откройте свою школу",
        "text10": "Создайте собственную онлайн школу на платформе Onсlass. Нанимайте сотрудников и увеличивайте свой заработок."
      },
      "methodist-list": {
        "title1": "Выбор любой дисциплины",
        "text1": "Вы можете преподавать любой предмет, в котором вы являетесь экспертом.",
        "title2": "Работайте из любой точки мира",
        "text2": "Download materials and conduct useful broadcasts at a convenient time for you from any place where there is Internet.",
        "title3": "Quick search for buyers",
        "text3": "Добавляйте интересный и полезный контент на платформу, чтобы новые ученики выбрали именно вас. Работая на платформе Onclass, вы получаете доступ к постоянно растущей базе учеников.",
        "title4": "Payments after each sale",
        "text4": "Привяжите свою банковскую карту в личном кабинете, чтобы принимать платежи от пользователей со всего мира.",
        "title5": "Маркетинг и продвижение",
        "text5": "Your materials will be placed in the catalog on our platform, where thousands of users visit every day. Therefore, you don't have to worry about additional promotion.",
        "title6": "Гарантия безопасной сделки",
        "text6": "Use the secure transaction system on the Onclass platform when selling training materials.",
        "title7": "Оставайтесь на связи",
        "text7": "Установите приложение Onclass на вашем смартфоне, чтобы получать уведомления о новых занятиях и общаться с другими пользователями.",
        "title8": "Развивайте свой личный бренд",
        "text8": "Declare yourself on the platform by creating lessons and courses based on the author's methods. Expand your audience and open an online school."
      },
      "school-list": {
        "title1": "Неограниченный выбор",
        "text1": "Ваша школа может работать сразу по нескольким направлениям. Чем больше преподаватлей по различным дисциплинам, тем больше учеников на занятиях.",
        "title2": "Удобный график работы",
        "text2": "Устанавливаете удобное расписание и продолжительность уроков. Ваши сотрудники смогут совмещать с основной работой или работать с вами full time.",
        "title3": "Распределение ролей",
        "text3": "Вы можете нанять сотрудников, которые будут проводить занятия в вашей школе, а вы будете выполнять роль управляющего. При желании вы тоже можете быть преподавателем.",
        "title4": "Развивайте свой личный бренд",
        "text4": "Заявите о себе на платформе, создавая уроки и курсы по авторским методикам. Расширяйте свою аудиторию и привлекайте самых успешных преподавателей.",
        "title5": "Маркетинг и продвижение",
        "text5": "Ваша школа будет размещена в каталоге на нашей платформе, куда ежедневно заходят тысячи пользователей. Поэтому вы можете не беспокоиться о дополнительном продвижении.",
        "title6": "Гарантия безопасной сделки",
        "text6": "Воспользуйтесь системой безопасной сделки на платформе Onclass, чтобы расчитываться со своими сотрудниками и получать оплату от учеников.",
        "title7": "Работайте из любой точки мира",
        "text7": "Платформа Onсlass позволяет объединять учеников и преподавателей со всего мира.",
        "title8": "Оставайтесь на связи",
        "text8": "Установите приложение Onсlass на свой смартфон, чтобы видеть уведомления о новых занятиях и общаться с учениками и сотрудниками школы."
      }
    },
    "constructor": {
      "titles": {
        "tutor": "Конструктор уроков",
        "student": "Выбирайте различные типы контента",
        "methodist": "Удобный конструктор",
        "school": "Просто начать"
      },
      "subtitles": {
        "tutor": "Добавьте необходимое количество блоков или слайдов и выберите <br> тип задания или контента, который подходит под ваши цели.",
        "methodist": "Добавьте необходимое количество блоков или слайдов и выберите <br> тип задания или контента, который подходит под ваши цели."
      },
      "list-tutors": {
        "title1": "Теория",
        "text1": "Добавляйте теоретическую информацию к урокам, чтобы помочь ученикам разобраться в новых правилах.",
        "title2": "Упражнения",
        "text2": "Установите один или несколько правильных вариантов ответов в созданных упражнениях. Добавьте возможность заполнять пропуски, чтобы сделать обучение еще интереснее.",
        "title3": "Аудио, фото видео",
        "text3": "Используйте удобный интерфейс загрузки медиафайлов на платформу. Все нужные материалы всегда будут под рукой.",
        "title4": "Изучение слов",
        "text4": "Учить новые слова с помощью карточек — просто и эффективно! Создавайте карточки для ваших учеников, чтобы быстрее добиться результата."
      },
      "list-student": {
        "title1": "Теория",
        "text1": "Внимательно изучите теоретическую часть перед тем, как выполнять упражнения. Особенно важно вникнуть в теорию, если вы начинаете изучать новую тему.",
        "title2": "Упражнения",
        "text2": "На платформе собраны разные виды упражнений: выбор одного или нескольких правильных вариантов ответов, возможность заполнять пропуски, сопоставление вариантов и многое другое.",
        "title3": "Аудио, фото видео",
        "text3": "Для отработки изученных правил вы можете выполнять упражнения в аудио- или видеоформате. Тренируйте правильное произношение, лексику, способность воспринимать речь на слух.",
        "title4": "Изучение слов",
        "text4": "Учить новые слова с помощью карточек — просто и эффективно! Выберите этот тип контента, чтобы быстрее добиться результата в изучении иностранных слов."
      },
      "list-school": {
        "title1": "Создавайте онлайн-продукты",
        "text1": "Создавайте уроки, платные коллекции и онлайн курсы с помощью удобного конструтора на платформе",
        "title2": "Зарегистрируйте название школы",
        "text2": "Пройдите этап регистрации онлайн-школы на платформе, чтобы пользоваться всеми возможностями Onсlass",
        "title3": "Наймите сотрудников",
        "text3": "Создайте крутую команду, чтобы добиться новых вершин. Объединяйтесь с преподавателями по разным предметам и охватите новую аудиторию",
        "title4": "Установите удобный график работы",
        "text4": "В календаре будут отображаться рабочие дни нанятых преподавателей. Можно посмотреть расписание всех сразу или каждого по отдельности. Преподаватели указывают рабочее время в своем профиле.",
        "title5": "Распределяйте прибыль между сотрудниками",
        "text5": "Распределить процент между школой и преподавателями вы можете самостоятельно. Каждому преподавателю можно установить свой процент.",
        "title6": "Контролируйте все процессы",
        "text6": "На платформе есть функция записи уроков, поэтому вы всегда сможете проконтролировать, как работают ваши сотрудники."
      }
    },
    "user-services": {
      "title": "Онлайн-занятия",
      "text": "Занятия - это онлайн-уроки с вашими учениками которые проходят на нашей интерактивной платформе. На Onclass есть шесть типов занятий, предназначенные под конкретные цели."
    },
    "processes": {
      "title": "Зарабатывайте (больше) с Onсlass",
      "subtitle": "Мы представляем множество возможностей для дополнительного <br> заработка преподавателям",
      "methodist-title": "Выплаты сразу вам на карту после каждой продажи!",
      "methodist-action": "Привязать карту",
      "list-tutor": {
        "title1": "Размещайте платные материалы <br> на платформе",
        "text1": "Создавайте платные коллекции и сборники уроков по определенной теме. Выставляйте материалы на платформе за фиксированную цену. Другие пользователи смогут приобрести готовые пособия для самостоятельного использования. Вы получите выплаты за продажу сразу на вашу карту.",
        "link1": "Создайте коллекцию",
        "title2": "Проводите различные виды занятий",
        "text2": "Создавайте приватные или публичные занятия, курсы, вебинары, стримы по своей дисциплине. Высталяйте за них свою цену и привлекайте заинтересованную аудиторию. Рассчет за занятия происходит напрямую с учениками или через безопасную сделку, гарантом которой выступает Onclass.",
        "link2": "Создать индивидуальное занятие",
        "title3": "Создайте свою онлайн школу",
        "text3": "Устали быть рядовым преподавателем? Станьте директором собственной онлайн-школы на Onclass и наймите в неё своих коллег. Все доходы от преподавания в вашей школе будут распределяться согласно установленному вами проценту, а выплаты будут происходить автоматически вм на карту.",
        "link3": "Создать свою школу"
      },
      "list-methodist": {
        "title1": "Размещайте платные материалы <br> на платформе",
        "text1": "Создавайте платные коллекции и сборники уроков по определенной теме. Выставляйте материалы на платформе за фиксированную цену. Другие пользователи смогут приобрести готовые пособия для самостоятельного использования. Вы получите выплаты за продажу сразу на вашу карту.",
        "link1": "Создайте коллекцию",
        "title2": "Проводите полезные эфиры и марафоны",
        "text2": "Проводите вебинары, стримы и марафоны по выбранной дисциплине. Делитесь полезными знаниями и привлекайте заинтересованную аудиторию. Общение с пользователями поможет привлечь новых покупателей вашего контента.",
        "link2": "Создать эфир",
        "title3": "Создайте свою онлайн школу",
        "text3": "Устали быть рядовым методистом? Станьте директором собственной онлайн-школы на Onclass и наймите в неё своих коллег. Все доходы от преподавания в вашей школе будут распределяться согласно установленному вами проценту, а выплаты будут происходить автоматически вам на карту.",
        "link3": "Создать свою школу"
      }
    },
    "school-advantages": {
      "list": {
        "title1": "Честные и понятные условия",
        "text1": "Ученик вносит 100% оплаты за проведенное занятие. Плтаформа возьмет фиксированную комисиию 15% от. Вы сможете самостоятельно установить объем выплат вашим сотрудникам за работу.",
        "title2": "Безопасность и надежность",
        "text2": "Воспользуйтесь системой безопасной сделки на платформе Onclass, чтобы рассчитываться с вашими сотрудниками. Получайте выплаты за работу школы прямо на вашу карту.",
        "title3": "Маркетинг и продвижение",
        "text3": "С нами вы сможете освоить новые вершины в сфере обучения. Ежедневно команда IT специалистов привлекает трафик на нашу платформу, чтобы новые ученики увидели именно вашу школу."
      }
    },
    "faq": {
      "list": {
        "question1": "Что дает прохождение верификации на платформе?",
        "answer1": "После заполнения профиля и прохождения верификации вы сможете: 1. проводить индивидуальные занятия с учениками 2. ваш профиль будет публиковаться в каталоге преподавателей 3. сможете получить бесплатную поддержку и обучение по использованию платформы 4. расчеты через Onclass.com с помощью безопасной сделки",
        "question2": "Какой функционал есть на платформе?",
        "answer2": "Платформа Onclass открывает новые возможности для онлайн-обучения. \n1. Виртуальный класс, для проведения интерактивных онлайн-занятий. Видео- и аудиосвязь, чат для общения и доска с возможностью рисования.\n2. Удобный конструктор для создания уроков, упражнений и другого обучающего контента. \n3. Личная страница преподавателя, которая позволит вам продвигать ваши онлайн-занятия и другие услуги. \n4. Каталог онлайн-школ, созданных вами или другими преподавателями. Онлайн-школа — это отличная возможность реализовать ваши профессиональные амбиции, используя платформу Onclass.",
        "question3": "Как выстроить расписание занятий?",
        "answer3": "Занимайтесь в удобное для вас время, совмещайте с основной работой или преподавайте full time. Когда вы отмечаете свои рабочие дни в календаре, студенты могут самостоятельно записаться на занятия с вами.",
        "question4": "Как подготовиться к уроку?",
        "answer4": "Договоритесь с учениками об удобном времени занятия.Чтобы начать занятие, откройте виртуальный класс и подготовьтесь к уроку. Добавьте нужное количество досок, а затем выберете материалы для урока из имеющихся на платформе или загрузите новые материалы самостоятельно. Пригласите ученика в класс по ссылке, отправив ее в любой удобный мессенджер или в чат на платформе Onclass. Либо через кнопку Пригласить студента.",
        "question5": "У меня совсем нет преподавательского опыта. Я могу вести у вас курсы?",
        "answer5": "Нам важно, чтобы преподаватели на платформе Onclass имели достаточный опыт в работе с учениками, умели доступно объяснять материал и обладали хорошими ораторскими качествами. Но также мы бы хотели, чтобы наша платформа была полезна и для молодых специалистов, у которых есть интересные завершенные проекты и большое желание преподавать. Поэтому платформа Onclass объединяет всех, кто готов делиться своими знаниями с другими людьми.\n\nПлатформа Onclass открывает новые возможности для преподавателей с любым уровнем опыта работы! Если вы имеете достаточный опыт в работе с учениками, умеете доступно объяснять материал и обладаете хорошими ораторскими качествами, то вы наверняка сможете оценить весь функционал платформы. Для тех, кто только начинает свой путь в преподавательской деятельности, платформа Onclass станет отличной площадкой для старта карьеры.",
        "question6": "Какие предметы можно преподавать на платформе?",
        "answer6": "Сейчас вы уже можете найти множество уроков по английскому и другим иностранным языкам, например итальянский, французский и др. Помимо иностранных языков на платформе представлены уроки по предметам школьной или университетской программы. Вы можете добавить материалы по любому желаемому предмету и начать обучение со своими учениками, а также найти новых с помощью платформы Onclass.",
        "question7": "Как найти новых учеников?",
        "answer7": "Создавайте полезный контент и делитесь им с другими пользователями на платформе Onclass. Коллекции уроков, интерактивные задания и дополнительные материалы - вот, что обязательно привлечет новых учеников. Рассказывая о своих услугах, вы можете привлечь больше учеников и, как следствие, заработать больше. Не забудьте пройти верификацию, чтобы отображаться в каталоге преподавателей.",
        "question8": "Как пригласить учеников на занятие?",
        "answer8": "Пригласите ученика в класс по ссылке, отправив ее в любой удобный мессенджер или в чат на платформе Onclass. Либо через кнопку Пригласить студента.",
        "question9": "Какие материалы я могу добавить для занятие с учениками?",
        "answer9": "Вы можете загрузить все необходимые для занятия материалы. Добавляйте подкасты или аудиоуроки.Упражнения, в которых нужно заполнить пропуски, вписать текст или выбрать правильный вариант ответа. Можно печатать текст, делать пометки маркером, вставлять графики и геометрически фигуры или добавлять необходимые изображения.",
        "question10": "Как добавить урок?",
        "answer10": "Перейдите в раздел Мой контент, чтобы добавить необходимые материалы на платформу.  Добавьте необходимое количество блоков или слайдов и выберите тип задания или контента, который подходит под ваши цели. Введите подробное и понятное  описание материалов, чтобы не потерять нужное упражнение. Перейдите в Настройки и установите все необходимые параметры. Воспользуйтесь функцией Предпросмотр, чтобы проверить урок перед публикацией. Если все отображается верно, то опубликуйте урок. Не забывайте, что перед публикацией урок обязательно пройдет модерацию, поэтому отображаться в профиле он может не сразу.",
        "question11": "Как проверить домашнее задание?",
        "answer11": "Добавляя тестовые задания или проверочные упражнения на платформу обязательно укажите верные варианты ответа, чтобы процесс проверки выполнения домашнего задания происходил автоматически на платформе.",
        "question12": "Кто может просматривать мои материалы на платформе?",
        "answer12": "При создании урока на платформе, вы можете выбрать настройки приватности к каждому материалу. Выберете Общий доступ, если хотите делиться своими уроками с другими пользователями на платформе и Приватный доступ только для личного использования.",
        "question13": "Какие гарантии предоставляет платформа?",
        "answer13": "Принимайте оплату за занятия на платформе по безопасной сделке. Безопасная транзакция — это тип расчетов, при котором платформа Onсlass выступает гарантом транзакции. Вы обязательно получите заработанные деньги сразу после урока. При выборе этого типа ученик оплачивает урок заранее. В течение 3 дней после урока Onсlass оплачивает обучение преподавателя за вычетом комиссии. Размер комиссии зависит от тарифа. Добавьте банковскую карту, чтобы принимать платежи от учеников.",
        "question14": "Какие гарантии предоставляет платформа?",
        "answer14": "Мы гарантируем возврат денежных средств в случае, если услуга была оказана некачественно. После проведенного занятия вы можете поделиться обратной связью об уроке, заполнив небольшую анкету. Если преподаватель вел себя непрофессионально, то обязательно сообщите нам об этом и наша служба поддержки разберется в ситуации.",
        "question15": "Как найти преподавателей с помощью платформы Onclass?",
        "answer15": "Всего четыре простых шага помогут найти вам нужного преподавателя:\n1. Заполните свой профиль. Расскажите о себе, чтобы преподаватели могли больше узнать о вас.\n2. Выберите нужные фильтры в каталоге преподавателей. Например, нужные вам предметы, язык контента и стоимость занятия.\n3. Напишите преподавателю или запишитесь к нему на занятие в удобное для вас время.\n4. Рассчитывайтесь с преподавателем, как вам удобно. Оплачивайте занятия по системе безопасной сделки или рассчитывайтесь лично с преподавателем, если между вам уже выстроены доверительные отношения.",
        "question16": "Какие предметы уже доступны на платформе?",
        "answer16": "Вы можете найти множество уроков по английскому и другим иностранным языкам, например итальянскому, французскому и другим. Помимо изучения иностранных языков, вы можете найти уроки по предметам школьной или университетской программы.",
        "question17": "Как выбрать преподавателя?",
        "answer17": "Перейдите в раздел Репетиторы, чтобы ознакомиться со списком преподавателей на платформе Onclass. Учителя в списке отобразятся по популярности, согласно их рейтингу по количеству и качеству проведенных занятий. Чтобы найти преподавателя по определенному предмету, нажмите кнопку \"Выберите предмет\" в левом верхнем углу.",
        "question18": "Где найти домашнее задание?",
        "answer18": "После проведенного занятия, преподаватель может задать домашнее задание своим ученикам. Выполняйте домашнее задание непосредственно на платформе в удобное для вас время. Проверка домашнего задания происходит автоматически.",
        "question19": "Могу ли я вернуться к уже пройденному материалу?",
        "answer19": "Да, все пройденные материалы хранятся на платформе. Вы всегда можете просмотреть прошедшие занятия, чтобы повторить пройденные уроки.  Их можно найти в разделе События."
      }
    },
    "school-tools": {
      "title": "Все инструменты на одной платформе",
      "text": "Создайте свою собственную школу <br> и получайте прибыль",
      "action": "Создать школу",
      "list": {
        "title1": "Чат с комментариями",
        "text1": "Общайтесь с учениками и другими пользавателями на плафторме в специальном чате.",
        "title2": "Мобильное приложение",
        "text2": "Установите приложение Onсlass на свой смартфон, чтобы видеть уведомления о новых занятиях и общаться с сотрудниками вашей школы.",
        "title3": "Анализ и статистика",
        "text3": "Вы всегда сможете отследить прогресс в работе вашей школы или конкретного сотрудника в личном кабинете.",
        "title4": "Прием платежей",
        "text4": "Прибыль от работы вашей школы поступает сразу на вашу карту. Воспользуйтесь системой безопасной сделки на платформе Onclass при расчетах с сотрудниками."
      }
    }
  },
  "payments-history": {
    "success": "Успешно",
    "pending": "В обработке",
    "canceled": "Отмененные",
    "yesterday": "Вчера",
    "today": "Сегодня",
    "written-off": "Списано",
    "received": "Получено",
    "per-lesson": "за занятие",
    "per-class": "за занятие",
    "per-tariff-plan": "за тарифный план",
    "per-collection": "за коллекцию",
    "participants": "Количество участников:",
    "total": "Итого",
    "no-data-available": "Данные недоступны"
  },
  "my-classes/dashboard.page-header-title": "Дашборд",
  "my-classes/events.page-header-title": "Занятия",
  "my-classes/tutors.page-header-title": "Репетиторы",
  "my-classes/classrooms.page-header-title": "Учебные классы",
  "my-classes/homework.page-header-title": "Домашнее задание",
  "my-classes/students.page-header-title": "Ученики",
  "my-classes/schools.page-header-title": "Школы",
  "my-classes/dashboard.meta-title": "Дашборд",
  "my-classes/events.meta-title": "Занятия",
  "my-classes/tutors.meta-title": "Репетиторы",
  "my-classes/classrooms.meta-title": "Учебные классы",
  "my-classes/homework.meta-title": "Домашнее задание",
  "my-classes/students.meta-title": "Студенты",
  "my-classes/schools.meta-title": "Школы",
  "my-library/activity.page-header-title": "Активность",
  "my-library/services.page-header-title": "Занятия",
  "my-library/courses.page-header-title": "Курсы",
  "my-library/collections.page-header-title": "Коллекции",
  "my-library/collections.page-header-title.test": "Коллекции",
  "my-library/lessons.page-header-title": "Уроки",
  "my-library/activity.meta-title": "Активность",
  "my-library/lessons.meta-title": "Уроки",
  "my-library/services.meta-title": "Доступные занятия",
  "my-library/courses.meta-title": "Курсы",
  "my-library/collections.meta-title": "Коллекции",
  "my-feed/feed.page-header-title": "Лента",
  "my-feed/publications.page-header-title": "Публикации",
  "my-feed/lessons.page-header-title": "Уроки",
  "my-feed/collections.page-header-title": "Коллекции",
  "my-feed/classes.page-header-title": "Занятия",
  "my-feed/reviews.page-header-title": "Отзывы",
  "my-feed/comments.page-header-title": "Комментарии",
  "schedule/calendar.meta-title": "Календарь",
  "schedule/working-hours.meta-title": "Рабочее время",
  "schedule/vacation.meta-title": "Отпуск",
  "my-vocabulary/main.meta-title": "Мой словарь",
  "my-vocabulary/lists.meta-title": "Списки",
  "my-vocabulary/students.meta-title": "Студенты",
  "publish-status": {
    "published": "Опубликованные",
    "private": "Приватные",
    "draft": "Черновики",
    "purchased": "Купленные",
    "moderation": "На модерации",
    "archive": "Архив"
  },
  "my-library": {
    "stub": {
      "stub1_all_tutor": "Нет коллекций. Создайте свою <br> первую коллекцию.",
      "stub1_all_student": "Коллекции нет. <br> Вы можете приобрести любую коллекцию в <br> <a href\"/collections\" class=\"stub-link\">каталоге.</a>",
      "stub1_all_guest": "<span class=\"link\">Войдите</span>, чтобы просмотреть <br>коллекции, которые вы <br> приобрели здесь.",
      "stub1_published_tutor": "Нет опубликованных коллекций <br> Опубликуйте Вашу первую коллекцию",
      "stub1_published_student": "У вас нет коллекций.",
      "stub1_published_guest": "<span class=\"link\">Войдите</span>, чтобы просмотреть <br>коллекции, которые вы <br> приобрели здесь.",
      "stub1_private_tutor": "Нет личный коллекций <br> Создайте Вашу первую коллекцию",
      "stub1_private_student": "Нет личный коллекций <br> Создайте Вашу первую коллекцию",
      "stub1_private_guest": "<span class=\"link\">Войдите</span>, чтобы просмотреть <br>коллекции, которые вы <br> приобрели здесь.",
      "stub1_draft_tutor": "Нет коллекций. Создайте свою <br> первую коллекцию.",
      "stub1_draft_student": "Нет коллекций. Создайте свою <br> первую коллекцию.",
      "stub1_draft_guest": "<span class=\"link\">Войдите</span>, чтобы просмотреть <br>коллекции, которые вы <br> приобрели здесь.",
      "stub1_moderation_tutor": "Нет коллекций на модерации",
      "stub1_moderation_student": "Нет коллекций на модерации",
      "stub1_moderation_guest": "<span class=\"link\">Войдите</span>, чтобы просмотреть <br>коллекции, которые вы <br> приобрели здесь.",
      "stub1_purchased_tutor": "У Вас нет купленных коллекций.",
      "stub1_purchased_student": "У Вас нет купленных коллекций.",
      "stub1_purchased_guest": "<span class=\"link\">Войдите</span>, чтобы просмотреть <br>коллекции, которые вы <br> приобрели здесь.",
      "stub1_archive_tutor": "У Вас нет архивных коллекций.",
      "stub1_archive_student": "У Вас нет архивных коллекций.",
      "stub1_archive_guest": "<span class=\"link\">Войдите</span>, чтобы просмотреть <br>коллекции, которые вы <br> приобрели здесь.",
      "stub2": "У Вас нет уроков. Создайте свой<br>первый урок.",
      "stub3": "У Вас нет курсов. Создайте свой<br>первый курс.",
      "stub4": "У вас нет занятий в каталоге <br>Создайте новое занятие"
    },
    "no-published-lesson": "Нет опубликованных уроков",
    "no-private-lesson": "Нет приватных уроков",
    "no-draft-lesson": "У Вас нет уроков. Создайте свой<br>первый урок.",
    "no-moderation-lesson": "Нет уроков на модерации",
    "no-archive-lesson": "Нет архивных уроков",
    "need-authorization": "<span class=\"link\">Войдите</span>, чтобы увидеть здесь <br>уроки, которые вы <br> изучаете.",
    "meta-title": "Моя библиотека",
    "page-header-title": "Моя библиотека",
    "actions": {
      "create-lesson": "Создать урок",
      "create-service": "Создать услугу",
      "create-collection": "Создать коллекцию",
      "schedule-class": "Запланировать занятие",
      "create-classroom": "Создать учебный класс",
      "new-homework": "Новая домашняя работа"
    },
    "cards-actions": {
      "view": "Открыть",
      "unpublish": "Снять с публикации",
      "publish": "Опубликовать",
      "edit": "Редактировать",
      "archive": "Архив",
      "restore": "Восстановить",
      "show-stats": "Показать статистику",
      "hide-stats": "Скрыть статистику"
    },
    "tabs": {
      "last-viewed": "Последние просмотренные",
      "in-progress": "В ходе выполнения",
      "finished": "Завершение"
    },
    "alerts": {
      "actions": {
        "unpublish": "Снять с публикации",
        "dont-unpublish": "Отмена",
        "send-to-archive": "Отправить в Архив",
        "dont-send": "Не отправлять",
        "restore": "Восстановить",
        "dont-restore": "Не восстанавливать"
      },
      "unpublish": {
        "title": "Снять общедоступный урок с публикации?",
        "text": "При снятии урока с публикации он будет перемещен в раздел “Черновики”. "
      },
      "archive-public": {
        "title": "Отправить общедоступный урок в Архив?",
        "text": "Урок будет отправлен в Архив, он не будет доступным для публики и во время занятий. При восстановлении из Архива публичные уроки проходят повторную модерацию. "
      },
      "archive-private": {
        "title": "Отправить приватный урок в архив?",
        "text": "Приватный урок отправится в Архив. Приватные уроки могут быть восстановлены из Архива без модерации. "
      },
      "archive-draft": {
        "title": "Отправить черновик урока в Архив?",
        "text": "Черновик отправится в Архив"
      },
      "restore-archive": {
        "title": "Восстановить урок?",
        "text": "Урок попадет в раздел “Черновики”. Там вы сможете отредактировать и опубликовать урок в общем доступе или как приватный. "
      }
    }
  },
  "my-classes": {
    "stub": {
      "stub1": "Здесь еще нет занятий. <br> <span class=\"stub-link\">Запланируйте</span> новое занятие.",
      "stub1_1": "Нет предстоящих или прошедших<br>занятий.",
      "stub2": "Нет учителей, у которых вы <br> учились. Найдите подходящего <br> учителя в <a href\"/tutors\" class=\"stub-link\">каталоге</a>",
      "stub2_1": "There are no teachers with whom you <br> studied.",
      "stub3": "У вас нет учеников. Начните проводить занятия, <br> чтобы увидеть список ваших<br>учащихся.",
      "stub4": "Нет домашнего задания <br> Дайте первую домашнюю работу своим ученикам",
      "stub4_1": "Нет домашнего задания.",
      "stub5": "Учебных классов пока нет. Создайте <br> первый класс для <br> учеников",
      "stub5_1": "Нет уроков на модерации.",
      "stub6": "У вас нет школ. Создайте <br> свою школу или <br> присоеденитесь",
      "stub6_1": "У вас нет школ."
    },
    "no-events": {
      "title": "Ваши занятия",
      "text": "Здесь будет отображаться расписание ваших групповых и индивидуальных занятий с преподавателями",
      "text_teacher": "Вы еще не запланировали ни одного занятия",
      "link_label": "Каталог классов",
      "link_label_teacher": "Запланировать занятие",
      "filter": {
        "All": "Все",
        "Upcoming": "Предстоящие",
        "Unconfirmed": "Неподтверждённые",
        "Past": "Прошедшие",
        "Canceled": "Отмененные"
      }
    },
    "no-tutors": {
      "title": "Ваши репетиторы",
      "text": "Здесь будут отображаться учителя, с которыми у вас есть занятия",
      "link_label": "Каталог репетиторов"
    },
    "no-classes": {
      "title": "Ваши учебные классы",
      "text": "Здесь будут отображаться ваши учебные классы, в которых вы состоите и где проводятся ваши онлайн-занятия.",
      "text_teacher": "Учебных классов пока нет",
      "link_label": "Каталог занятий",
      "link_label_teacher": "Создать учебный класс",
      "filter": {
        "Active": "Активные",
        "Archive": "Архив"
      }
    },
    "no-schools": {
      "title": "Нет школ",
      "text": "Здесь будут отображаться ваши школы, в которых вы состоите и владельцем которых вы являетесь.",
      "link_label": "Каталог школ",
      "filter": {
        "owner": "Я владелец",
        "student": "Я студент",
        "teacher": "Я учитель"
      }
    },
    "no-students": {
      "title": "Ваши ученики",
      "text": "Здесь будут отображаться ваши ученики с которыми вы занимаетесь",
      "text_teacher": "У вас пока нет учеников с которыми вы занимаетесь индивидуально"
    },
    "no-homeworks": {
      "title": "Ваше домашнее задание",
      "text": "Здесь будут отображаться домашние задания, данные вам вашими учителями",
      "text_teacher": "Вы еще не выдавали домашние задания ученикам",
      "filter": {
        "All": "Все",
        "Verified": "Проверено",
        "Unverified": "Непроверенные"
      }
    },
    "meta-title": "Мои занятия",
    "page-header-title": "Мои занятия",
    "actions": {
      "create-lesson": "Создать урок",
      "create-service": "Создать услугу",
      "create-collection": "Создайте коллекцию",
      "schedule-class": "Запланировать занятие",
      "create-classroom": "Создать учебный класс",
      "create-school": "Создать школу",
      "new-homework": "Новая домашняя работа"
    }
  },
  "activity-lesson": {
    "tabs": {
      "completed": "Завершенные",
      "recently-watched": "Недавно просмотренные",
      "purchased": "Купленные"
    }
  },
  "favorites": {
    "page-header-title": "Избранные",
    "meta-title": "Избранные",
    "stub": {
      "lessons": "У вас нет избранных уроков <br> <a href=\"/lessons\" class=\"stub-link\">Каталог уроков</a>",
      "classes": "У вас нет избранных услуг <br> <a href=\"/classes\" class=\"stub-link\">Каталог услуг</a>",
      "tutors": "У вас нет избранных репетиторов <br> <a href=\"/tutors\" class=\"stub-link\">Каталог репетиторов</a>",
      "collections": "У вас нет избранных сборников <br> <a href=\"/collections\" class=\"stub-link\">Каталог сборников</a>",
      "users": "У вас нет избранных пользователей <br><a href=\"/users\" class=\"stub-link\">Каталог пользователей</a>",
      "schools": "У вас нет избранных школ <br> <a href=\"/schools\" class=\"stub-link\">Смотреть каталог</a>"
    },
    "empty-message": "Вы еще ничего не добавили в избранное"
  },
  "favorites/lessons": {
    "page-header-title": "Уроки",
    "meta-title": "Уроки",
    "empty": "Каталог уроков"
  },
  "favorites/services": {
    "page-header-title": "Занятия",
    "meta-title": "Доступные занятия",
    "empty": "Каталог занятий"
  },
  "favorites/tutors": {
    "page-header-title": "Репетиторы",
    "meta-title": "Репетиторы",
    "empty": "Каталог репетиторов"
  },
  "favorites/users": {
    "page-header-title": "Пользователи",
    "meta-title": "Пользователи",
    "empty": "Каталог пользователей"
  },
  "favorites/schools": {
    "page-header-title": "Школы",
    "meta-title": "Школы",
    "empty": "Каталог школ"
  },
  "favorites/collections": {
    "page-header-title": "Коллекции",
    "meta-title": "Коллекции",
    "empty": "Каталог коллекций"
  },
  "schedule-page": {
    "tabs": {
      "working-hours": "Рабочее время",
      "calendar": "Календарь",
      "vacation": "Отпуск"
    }
  },
  "banner-plan": {
    "title": "Ваш тариф",
    "credit-label": "Доступно кредитов:",
    "expired-at": "Срок действия:",
    "action": "Улучшить тариф"
  },
  "plan-warning-message": {
    "lesson": "Возможность создания уроков с приватным доступом доступна только на платных тарифах.",
    "collection": "Возможность создания приватных коллекций доступна только на платных тарифах.",
    "choose-plan": "Выберите тариф"
  },
  "classroom-news": {
    "stub": "Отслеживайте активность в классах, куда вас пригласили",
    "page-header-title": "Учебные классы",
    "meta-title": "Учебные классы"
  },
  "school-news": {
    "stub": "Отслеживайте активность в школах, в которых вы состоите",
    "page-header-title": "Школы",
    "meta-title": "Школы"
  },
  "menu-widget": {
    "title": "Новость",
    "list": {
      "lessons": "Уроки",
      "publications": "Публикации",
      "classrooms": "Учебные классы",
      "homework": "Домашнее задание",
      "schools": "Школы",
      "comments": "Комментарии"
    }
  },
  "suggest-add-card": {
    "text": "Для получения выплат, пожалуйста, заполните платежную информацию",
    "action": "Добавьте способ выплаты"
  },
  "people-news": {
    "page-header-title": "Люди",
    "meta-title": "Люди",
    "stub": "Узнавайте новости тех, на кого вы подписаны"
  },
  "timezone-notifier": {
    "title": "Настройки часового пояса",
    "question": "Измените часовой пояс на [{{timezone}} {{offset}}]",
    "btn-skip": "Не менять",
    "btn-save": "Изменить"
  },
  "currency-notifier": {
    "title": "Изменение валюты",
    "question": "Хотите изменить валюту с {{from}} на {{to}}?",
    "btn-skip": "Не менять",
    "btn-save": "Изменить"
  },
  "payout": {
    "status": {
      "error": "Реквизиты не указаны",
      "completed": "Принят",
      "confirmed": "Принят",
      "pending": "В обработке",
      "restricted": "Ограниченный"
    },
    "text": {
      "error": "Для того чтобы можно было принимать оплату за занятия, необходимо указать реквизиты для приема оплаты. В меню карточки выберите пункт \"Редактировать\" и укажите реквизиты карты или счета.",
      "restricted": "Вы можете разместить информацию о своих услугах, но оплата будет производиться оператором вручную, что занимает больше времени, чем автоматические платежи. Чтобы включить автоматические платежи, пожалуйста, убедитесь, что введенная вами информация верна и точна"
    },
    "set-default": "Сделать основным счетом",
    "get-money": "Вывести",
    "bank-account": "Номер счета",
    "card-number": "Карта",
    "swift": "SWIFT",
    "bank": "Банк",
    "edit": "Редактировать",
    "remove": "Удалить",
    "balance": "Баланс",
    "upcoming": "Предстоящие",
    "advances": "Ожидаются",
    "wait-balance-text": "Обработка очереди поступающих платежей занимает до 10 дней. После этого очередная обработанная сумма будет зачислена на Ваш баланс и станет доступна для вывода.",
    "rate-balance-text": "Приблизительная сумма, которую вы получите при выводе средств. При этом учитывается комиссия и текущий обменный курс",
    "auto-balance-text": "Сумма предстоящей выплаты. Выплачивается постепенно за проведенные занятия.",
    "convertation-balance-text": "Приблизительная сумма, которую вы получите при выводе средств. При этом учитывается комиссия и текущий обменный курс",
    "default": "Основной счет",
    "inactive": "Неактивные получатели.",
    "inactive_accounts": "Неактивные счета.",
    "all_world": "Весь мир",
    "russia": "Россия",
    "add_method": "Добавить счет для выплат",
    "add_payment_method": "Добавить счет",
    "support-text": "Нужна помощь? Свяжитесь с нашей службой поддержки",
    "support-link": "Написать в поддержку",
    "enter-tax-info": "Введите платежную информацию",
    "beneficiary": "Получатель",
    "autopayout": "Автовыплата",
    "auto-payout-alert-title": "Выплаты происходят автоматически",
    "auto-payout-alert-text": "Деньги выводятся автоматически на вашу банковскую карту через 3 дня после завершения занятия, если на него не поступало жалоб",
    "auto-payout-alert-btn": "Понятно",
    "delete-supplier-title": "Удалить метод выплаты пока нельзя",
    "delete-supplier-text": "У вас есть запланированные занятия, либо ожидается выплата.",
    "delete-supplier-btn": "Закрыть",
    "auto-payout-help": "Деньги выводятся автоматически на вашу банковскую карту через 3 дня после завершения занятия, если на него не поступало жалоб",
    "change-alert": {
      "title": "Вы уверены, что хотите изменить вашу платежную информацию?",
      "description": "Ранее введенная информация корректна и верифицирована, поэтому мы рекомендуем ее не менять.",
      "cancel": "Не изменять",
      "change": "Изменить"
    },
    "profile_page": {
      "payout_info_title": "Информация для выплат",
      "payout_add_world_text": "Добавьте счет для выплат, чтобы получать платежи от студентов со всего мира",
      "payout_add_russia_text": "Добавьте счет для выплат для получения платежей из России",
      "payment_info_title": "Информация для оплаты",
      "payment_add_world_text": "Привязка банковских карт по всему миру",
      "payment_add_russia_text": "Привязка карт российских банков"
    },
    "add-alert-title": "Добавить новый счет для выплат?",
    "add-alert-text": "У каждого пользователя может быть только один действующий счет получателя. Если вы добавите еще один счет, то предыдущий деактивируется и будет перенесен в категорию \"Неактивные получатели\".",
    "dontadd": "Не добавлять",
    "add": "Добавить"
  },
  "finance-page": {
    "title": {
      "main": "Главная",
      "payout": "Выплата",
      "payment": "Оплата",
      "history": "История операций",
      "subscriptions": "Подписки",
      "plan": "Ваш тариф"
    },
    "card": {
      "expiry-date": "Срок действия карты",
      "expiry-text": "Срок действия карты истек",
      "main-card": "Основная карта",
      "set-as-main": "Назначит основной картой",
      "remove": "Удалить"
    }
  },
  "vocabulary-page": {
    "title": {
      "main": "Мой словарь",
      "students": "Ученики"
    }
  },
  "schedule-calendar": {
    "title": {
      "calendar": "Календарь",
      "working-hours": "Рабочее время"
    }
  },
  "user-page": {
    "title": {
      "subscriptions": "Подписки",
      "subscribers": "Подписчики",
      "students": "Мои ученики",
      "all": "Другие пользователи"
    }
  },
  "withdraw": {
    "available-for-payout": "Available for payout",
    "minimum-amount": "Минимальная сумма снятия",
    "where-withdrawal": "Choose where you want to withdraw money",
    "processing-fee": "Processing fee",
    "will-be-credited": "Will be credited to your account",
    "will-be-credited-to": "Money will be credited to",
    "get": "Получить {{amount}}",
    "success-text": "Money sent successfully. They will arrive to your personal account or card within 7 days",
    "fail-text": "Money sent fail",
    "account-selection": "Account selection",
    "amount": "Сумма"
  },
  "no-internet": {
    "title": "Режим офлайн",
    "text": "Потеряно подключение к Интернету. Чтобы продолжить работу, дождитесь восстановления соединения."
  },
  "loading": "Загрузка",
  "titles": {
    "main": {
      "lessons": "Уроки",
      "tutors": "Репетиторы",
      "classes": "Занятия",
      "collections": "Коллекции",
      "schools": "Школы"
    }
  },
  "error-notificator": {
    "LOAD-USER": "Не удалось загрузить данные о пользователе",
    "LOAD-SOMEDATA": "Не удалось загрузить данные",
    "LOAD-FILE": "Не удалось загрузить файл",
    "CANT-LOGIN": "Не удалось авторизоваться",
    "SOCKET-ERROR": "Ошибка обновления",
    "PROFILE-PROBLEM": "Ошибка профиля",
    "PAYMENT-PROBLEM": "Что-то не так с покупкой",
    "SAVE-PROBLEM": "Проблема с сохранением",
    "OTHER-PROBLEM": "Что-то пошло не так",
    "report-error": "Написать в поддержку",
    "error": "error #"
  },
  "user-service-category": {
    "individual": "Популярные занятия",
    "speaking": "Разговорная практика",
    "exam_training": "Подготовка к экзамену",
    "for_children": "Для детей",
    "discussion": "Дискуссия",
    "master_class": "Мастер класс"
  },
  "languages": {
    "ru": "Русский",
    "en": "Английский",
    "fr": "Французский",
    "de": "Немецкий",
    "es": "Испанский",
    "pt": "Португальский",
    "it": "Итальянский",
    "zh-tw": "Китайский",
    "ja": "Японский",
    "en-us": "Американский английский",
    "tr": "Турецкий",
    "ar": "Арабский",
    "ko": "Корейский",
    "vi": "Вьетнамский",
    "sk": "Словацкий",
    "cs": "Чешский",
    "pl": "Польский",
    "ro": "Румынский",
    "hu": "Венгерский",
    "no": "Норвежский",
    "da": "Датский",
    "nl": "Голландский",
    "sv": "Шведский",
    "fi": "Финский",
    "el": "Греческий",
    "he": "Иврит",
    "hi": "Хинди",
    "id": "Индонезийский",
    "th": "Тайский",
    "es-mx": "Мексиканский испанский",
    "ar-eg": "Египетский арабский",
    "pt-br": "Бразильский португальский",
    "zh": "Традиционный китайский",
    "zh-hk": "Chinese(Hong Kong)",
    "bg": "Bulgarian",
    "ca": "Catalan",
    "ms": "Malay",
    "sr": "Serbian",
    "uk": "Ukrainian",
    "tl": "Tagalog",
    "fl": "Philippine",
    "fr-ca": "French, Canada",
    "af": "Afrikaans",
    "sq": "Albanian",
    "am": "Amharic",
    "hy": "Armenian",
    "az": "Azerbaijani",
    "be": "Belarusian",
    "bn": "Bengal",
    "bs": "Bosnian",
    "my": "Burmese",
    "hr": "Croatian",
    "fa-af": "Dari",
    "et": "Estonian",
    "ka": "Georgian",
    "kk": "Kazakh",
    "ku": "Kurdish",
    "lo": "Lao",
    "lv": "Latvian",
    "lt": "Lithuanian",
    "mk": "Macedonian",
    "mg": "Malagasy",
    "mn": "Mongolian",
    "ne": "Nepali",
    "ps": "Pashto",
    "fa": "Persian",
    "si": "Sinhala",
    "sl": "Slovenian",
    "so": "Somali",
    "sw": "Swahili",
    "ta": "Tamil",
    "ti": "Tigrinya",
    "ur": "Urdu"
  },
  "common-components": {
    "rating-v2": {
      "rating": "Рейтинг",
      "reviews": "отзывов"
    },
    "user-services": {
      "no-services": "Учитель еще не добавил доступные в рамках этой дисциплины занятия"
    }
  },
  "lingo2-forms": {
    "meetings": {
      "buy-credits-action": "Купить кредиты",
      "choose-plan-action": "Выберите тариф",
      "classrooms": {
        "all": "Все",
        "continue-class": "Продолжить занятие",
        "groups": "Группы",
        "no-classes": "Классных комнат пока нет",
        "no-classes-description": "Классные комнаты создаются для группировки студентов в онлайн-занятиях. Новая классная комната формируется при создании нового занятия, в котором все участники становятся ее членами. Выберите “Новая классная комната”.<br/>Вы так же можете при планировании занятия выбирать ранее.",
        "panel-classes": "Занятий:",
        "panel-students": "Ученики:",
        "students": "Студенты",
        "empty": "Пусто"
      },
      "create-action": "Создать классную комнату",
      "dz-placeholder-link1": "выбрать",
      "dz-placeholder-link2": "для выбора",
      "dz-placeholder-text": "Перетащите обложку или нажмите кнопку",
      "form-access-label": "Доступ",
      "form-access-private": "Приватный",
      "form-access-public": "Публичный",
      "form-balance-label": "Баланс",
      "form-balance-note": "Ваш баланс:",
      "form-buy-credits-header": "Купить кредиты",
      "form-carrying-possible": "Проведение занятия возможно",
      "form-change-title": "Запланировать занятие",
      "form-charging-label": "Стоимость участия",
      "form-create-action": "Создать",
      "form-credits": "кредитов",
      "form-credits-available": "Доступно кредитов:",
      "form-download": "Скачать",
      "form-keywords-hint-label": "Рекомендуемые навыки",
      "form-keywords-label": "Навыки, которые получит ученик",
      "form-keywords-placeholder": "Начните вводить здесь",
      "form-language-label": "Язык урока",
      "form-language-placeholder": "Выберите язык",
      "form-level-label": "Уровень",
      "form-level-other-name-placeholder": "Введите уровень",
      "form-level-placeholder": "Выбери уровень",
      "form-not-credits": "Проведение занятия невозможно",
      "form-number-participants-label": "Количество учеников",
      "form-participants-limit-warn": "Не выбрано",
      "form-participants_limit-label": "Количество учеников",
      "form-participation-free": "Бесплатно",
      "form-participation-label": "Стоимость участия",
      "form-participation-paid": "Платно",
      "form-price-label": "Стоимость участия",
      "form-price-placeholder": "стоимость",
      "form-price-tier-warn": "Не выбрано",
      "form-error-choose-option": "Выберите правильный вариант",
      "form-required": "Требуется:",
      "form-save-action": "Сохранить",
      "form-search-placeholder": "Поиск...",
      "form-subject-other-placeholder": "Название дисциплины",
      "form-subject-placeholder": "Выбери дисциплину",
      "form-subject-warn": "Выбор дисциплины обязателен",
      "form-term-placeholder": "Поиск...",
      "form-time-label": "Дата и Время",
      "form-timezone-label": "Часовой пояс",
      "form-timezone-message": "В вашем часовом поясе",
      "form-title": "Запланировать занятие",
      "form-title-placeholder": "Название класса",
      "form-title-warn": "Поле обязательно для заполнения",
      "form-to-calendar": "Добавить в календарь",
      "form-type-calc-label": "Тип платежа",
      "form-bank-cards": "Для создания конференции c этим типом расчета необходимо привязать <span class=\"link\">банковскую карту</span>",
      "hints": {
        "credit": "Занятия за кредиты, позволяют вам проводить бесплатные занятия или рассчитываться с вашими учениками вне платформы.",
        "private": "Приватная конференция для проведения занятий с учениками. Приглашайте учеников на ваш урок с помощью пригласительной ссылки",
        "public": "Ваша конференция будет опубликована в каталоге классов, любой желающий сможет записаться на ваш онлайн-урок.",
        "secure": "Портал onclass.com выступает гарантом сделки. Ученики оплачивают занятие через платформу Onclass. Комиссия Onclass составляет {{ term1 }}% от стоимости занятия, оставшиеся {{ term2 }}% получает преподаватель.",
        "verification-for-fee": "Для создания конференций с этим типом доступа необходимо пройти верификацию профиля преподавателя.",
        "verification-for-beneficiary": "To create conferences with this type of calculation, you must have payout information",
        "verification-for-public": "Для создания публичной конференции необходимо пройти проверку профиля преподавателя.",
        "verification-link": "Проверить",
        "beneficiary-link": "Добавьте платежную информацию",
        "paywall-action": "снизить комиссию"
      },
      "inputs-label": {
        "choose-time": "Выбрать время",
        "create-now": "Создать сейчас",
        "credits": "Оплата кредитами",
        "free": "Бесплатно/рассчитываться с учениками лично",
        "private": "Приватные занятия с приглашением по ссылке",
        "public": "Публичный. Публикуется в каталоге классов",
        "safe": "Безопасная сделка"
      },
      "meeting-type": {
        "group": "Групповой (2 - 24)",
        "single": "Индивидуальное (1)",
        "stream": "Стрим (1 - 1000)",
        "talk": "Общение (1 - 6)",
        "webinar": "Вебинар (25 - 50)"
      },
      "meeting-type-short": {
        "group": "Групповое занятие",
        "single": "Индивидуальное занятие",
        "stream": "Стрим",
        "talk": "Общение",
        "webinar": "Вебинар"
      },
      "not-available-time-informer": {
        "cancel-class": "Отменить занятие",
        "info-text": "Перенос и отмена занятий при необходимости возможны, но не рекомендованы, если до их начала осталось менее 24 часов.",
        "postpone-class": "Перенести занятие",
        "send-message": "Написать",
        "student": "Ученик:",
        "title-part1": "Время",
        "title-part2": "занято занятием:"
      },
      "participants-limit-placeholder": "Выбрать",
      "placeholders": {
        "category": "Не выбрано",
        "classroom": "Не выбрано",
        "detailed": {
          "description": "Запланированные занятия со своими или новыми учениками",
          "title": "Настроить занятие"
        },
        "meeting-type": "Не выбрано",
        "quick": {
          "description": "Мгновенная настройка и приглашение студентов по ссылке",
          "title": "Быстрое занятие"
        },
        "tab": "Новая классная комната"
      },
      "plan-upgrade-action": "обновить тариф",
      "redeem-coupon-action": "активировать купон",
      "steps": {
        "classroom": "Выберите класс:",
        "setup": "Описание и оформление класса:",
        "template": "Выберите шаблон:",
        "type": "Тип обучения:",
        "tutor": "Репетитор"
      },
      "titles": {
        "exam_training": "Подготовка к экзамену",
        "for_children": "Для детей",
        "other": "Другие занятия",
        "personal": "Индивидуальные занятия",
        "speaking": "Разговорная практика"
      },
      "meeting-select-author": {
        "reviews-count": "отзывов",
        "select-tutor": "Выберите преподавателя",
        "no-tutor-message": "В вашей школе пока еще нет преподавателей"
      }
    },
    "subject-selector": {
      "create": "Создать новую",
      "foreign": "Иностранный",
      "lvl1": {
        "foreign": "Иностранные языки",
        "native": "Родные языки",
        "non_categorized": "Другие дисциплины",
        "other": "Новая дисциплина"
      },
      "native": "Родной язык",
      "not-subject": "Такой дисциплины не найдено",
      "recent-title": "Недавние",
      "search-placeholder": "Поиск дисциплины",
      "select-placeholder": "Subject"
    },
    "subscription-plans": {
      "forms": {
        "action-activate-plan": "Активировать тариф",
        "action-apply": "Применить",
        "why-plan-text": "Вы сэкономите {{ discount }}% и получите на {{ credits }} кредитов больше",
        "action-buy-credits": "Оплатить",
        "action-cancel": "Отписаться",
        "action-payment": "Пополнение счета",
        "activate-text": "Активация подписки",
        "agreements": "Я согласен с {{ terms1 }}, а так же с {{ terms2 }}",
        "agreements-terms1": "условиями и положениями",
        "agreements-terms2": "политикой конфиденциальности",
        "alert-text": "Внимание! Oтмена подписки повлечет сгорание ваших кредитов по подписке и купленных кредитов. Бонусные кредиты будут сохранены.",
        "amount-credits": "Количество кредитов:",
        "bonus": "Бонусные:",
        "close": "Закрыть",
        "buying-credits": "Покупка кредитов",
        "by-subscription": "По подписке:",
        "cancel-mode-text": "вы хотите отменить подписку? \u2028Пожалуйста напишите ниже, что вас не устроило и почему вы собираетесь это сделать:",
        "cancel-mode-text2": "Если вы не успеете использовать кредиты подписки до конца периода, они будут аннулированы. Однако подарочные и приобретенные кредиты останутся на вашем счету.",
        "cancel-text": "Отмена подписки",
        "cancellation-label": "Ваш тариф после отмены:",
        "cancellation-label2": "Ваши кредиты после отмены подписки:",
        "header-label": "Тариф",
        "info-label": "Ежемесячная подписка на Onclass",
        "next-payment": "Дата следующего платежа:",
        "next-payment-text1": "Оплата будет списана автоматически.",
        "next-payment-text2": "Вы сможете отменить подписку в любое время.",
        "payment": "Перейти к оплате",
        "promo-placeholder": "Промокод",
        "purchased": "Купленные:",
        "recommended-plan": "Рекомендуемый тариф:",
        "total-credit": "Итого останется кредитов:",
        "total-pay": "Итого:",
        "your-plan": "Ваш тариф:",
        "pay": "Оплатить",
        "current-plan": "Текущий тариф",
        "subscription-will-canceled": "Подписка будет отменена",
        "total-credits-amount": "Общее количество кредитов",
        "cancelled": "аннулируются",
        "persist": "сохранятся",
        "activated": "Активируется",
        "cancel-subscription": "Отменить подписку"
      },
      "periods": {
        "day": "день",
        "month1": "месяц",
        "month3": "3 месяца",
        "month6": "6 месяцев",
        "year": "год"
      },
      "plan-advanced": {
        "bonus-hint": "+40 bonus",
        "hint": "Up to 100 lesson per month",
        "nameplate": "САМЫЙ ПОПУЛЯРНЫЙ",
        "subtitle": "Оптимальная комиссия",
        "title": "Опытный"
      },
      "plan-beginners": {
        "hint": "Up to 20 lesson per month",
        "subtitle": "20 credits",
        "title": "Beginners"
      },
      "plan-free": {
        "hint": "Up to 5 lesson per month",
        "subtitle": "5 credits",
        "title": "Бесплатно"
      },
      "plan-professional": {
        "nameplate": "ЛУЧШЕЕ ПРЕДЛОЖЕНИЕ",
        "subtitle": "Минимальная комиссия",
        "title": "Профессиональный"
      },
      "plan-standard": {
        "bonus-hint": "+10 bonus",
        "hint": "Up to 50 lesson per month",
        "subtitle": "40 credits",
        "title": "Standard"
      },
      "plan-starter": {
        "subtitle": "Большая комиссия",
        "title": "Стартовый"
      },
      "plan-trial": {
        "hint": "Попробуйте 30 дней бесплатно, далее {{ price }} в месяц",
        "nameplate": "Специальное предложение",
        "subtitle": "30 дней бесплатно",
        "title": "Пробный период"
      },
      "terms": {
        "term1": "Получаете кредитов в месяц* {{ receive_credits_per_month }}",
        "term2": "Комиссия по безопасной сделке: {{ safe_trade_commission }} %",
        "term3": "Скидка на покупку кредитов: {{ discount_the_purchase_of_credits }} %",
        "term4": "Комиссия за проведение занятий при расчете через Onclass {{ conference_commission_discount }} %",
        "term5": "{{ credits_per_month }} кредитов в месяц",
        "term6": "Скидка на покупку дополнительных кредитов {{ discount_on_purchase_of_additional_credits }} %"
      }
    },
    "pretected-payment": "Защита платежей Onclass"
  },
  "plans": {
    "special_offer": "Специальное предложение",
    "30_days_for_free": "30 дней бесплатно",
    "30_days_for_free_description": "30 дней бесплатно, затем {{price}} в месяц",
    "trial_subscription_activation": "Активация пробной подписки",
    "first_month": "первый месяц",
    "first_payment_date": "Дата первого платежа:",
    "text_per_month": "{{date}} - {{price}} в месяц",
    "activate_30_days_free": "Активировать 30 дней бесплатно",
    "30_days_for_free_Onclass": "30 дней бесплатной подписки в Onclass",
    "receive_credits_per_month": "Получите кредитов в месяц: {{credits}}",
    "payment_charged_automatically": "Оплата будет списана автоматически.",
    "can_cancel_any_time": "Вы сможете отменить подписку в любое время."
  }
}
