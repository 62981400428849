import { ChangeDetectionStrategy, Component } from '@angular/core';
import { EContentPanelRoute } from 'lingo2-models';

interface IMenuItem {
  id: string;
  label: string;
  route: string;
}

const menuItems: IMenuItem[] = [
  {
    id: 'library',
    label: 'job-landing.footer.library',
    route: '/lessons',
  },
  {
    id: 'teachers',
    label: 'job-landing.footer.teachers',
    route: '/teachers',
  },
  {
    id: 'classes',
    label: 'job-landing.footer.classes',
    route: '/' + EContentPanelRoute.classes,
  },
];

const linkItems1: IMenuItem[] = [
  {
    id: 'privacy',
    label: 'job-landing.footer.privacy',
    route: '/privacy',
  },
  {
    id: 'offer',
    label: 'job-landing.footer.offer',
    route: '/offer',
  },
  {
    id: 'terms',
    label: 'job-landing.footer.terms',
    route: '/terms',
  },
];

@Component({
  selector: 'app-footer-common',
  templateUrl: './footer-common.component.html',
  styleUrls: ['./footer-common.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FooterCommonComponent {
  public linkItems1 = linkItems1;

  public get copyYear(): number {
    return new Date().getFullYear();
  }

  public trackByFn(index) {
    return index;
  }
}
