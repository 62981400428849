<section #fixedWidget *ngIf="user" class="user-widget fixedWidget">
  <ng-template [ngIf]="!withoutCover">
    <div class="top-wrap">
      <app-subscribe [separate]="true" [user_id]="user.id"></app-subscribe>
    </div>
    <div class="avatar-wrap">
      <on-ui-cover [cover]="avatar"></on-ui-cover>
      <div *ngIf="!isMeVersion()" [class.offline]="!statusOnline" [class.online]="statusOnline" class="status"></div>
    </div>

    <div class="name-wrap">
      <a [routerLink]="profileUrl" class="teacher-name">{{ fullName }}</a>
      <ng-template [ngIf]="!isMeVersion()">
        <ng-template [ngIfElse]="noVerified" [ngIf]="profileVerified">
          <app-icon class="verify-icon verify" file="status" icon="teacher-verify"></app-icon>
        </ng-template>

        <ng-template #noVerified>
          <app-icon class="verify-icon no-verify" file="status" icon="teacher-no-verify"></app-icon>
        </ng-template>
      </ng-template>
    </div>
  </ng-template>

  <ng-template [ngIf]="!isMeVersion()">
    <div class="country-wrap" *ngIf="country">
      <div class="flag-wrap">
        <img *ngIf="country?.flag_url" [alt]="country?.title_native" [src]="country?.flag_url" class="flag">
      </div>
      <span class="country">({{country.title_native}})</span>
    </div>
    <div class="speak-wrap">
      <span class="speak-label">{{ 'teacher-card.speak-on' | translate }}</span>
      <span class="speak-value">{{ speakLanguages[0]?.title }}</span>
      <ng-template [ngIf]="speakLanguages?.length > 1">
        <div class="speak-action">+{{ speakLanguages?.length - 1 }}</div>
      </ng-template>
    </div>

    <div class="stats-list">
      <div *ngFor="let stat of statsList; let first = first; trackBy: trackByFn" class="stats-item">
        <span class="stats-label">{{ stat.title | translate }}</span>
        <span class="stats-label">{{ stat.value }}</span>
        <i *ngIf="first" class="point"></i>
      </div>
      <app-rating [label]="true" [stats]="stats"></app-rating>
    </div>

    <div class="teaching-list">
      <div class="list">
        <div *ngFor="let teach of profile?.teaching_subjects; let first = first; let last = last; trackBy: trackByFn" class="teaching-item">
          <p *ngIf="first" class="teach-title">{{ 'library.user-card.teach' | translate }}</p>
          <span>{{ findSubjectById(teach)?.title }}</span><span *ngIf="!last">,&nbsp;</span>
        </div>
      </div>
    </div>

    <div *ngIf="stats?.min_price_tier" class="price-wrap">
      <div class="price-block">
        <span class="price-label">{{ 'teacher-card.from' | translate }}</span>
        <span class="price-value">{{ stats?.min_price_tier ? (getFormattedPrice() | async) : 0 }}</span>
        <span class="price-label">{{ 'teacher-card.hr' | translate }}</span>
      </div>
    </div>

    <div class="stats-actions">
      <ng-template [ngIfElse]="cantSignupRef" [ngIf]="canSignup$ | async">
        <on-ui-button (click)="goSignup()"
                      class="secondary"
                      label="teacher-card.actions.sign-up">
        </on-ui-button>
      </ng-template>
      <ng-template #cantSignupRef>
        <on-ui-button class="status"
                      label="teacher-card.actions.no-available-hours">
        </on-ui-button>
      </ng-template>

      <on-ui-button (click)="teacherModalOpen = !teacherModalOpen"
                    [attr.data-test]="'details'"
                    class="transparent"
                    label="teacher-card.actions.more-details">
      </on-ui-button>
    </div>
  </ng-template>

  <ng-template [ngIf]="isMeVersion()">
    <div [class.with-padding]="withoutCover" class="teacher-hint">
      <p>{{ 'teacher-card.share-link-text' | translate }}</p>
      <a [routerLink]="profileUrl" class="link">{{profileLink}}</a>
      <div class="action">
        <span [cbContent]="profileLink" [ngxClipboard]>
          <on-ui-button
            (onclick)="copy()"
            [iconPosition]="copied ? 'before' : null"
            [label]="copied ? 'invite-card.link-copied' : 'classrooms.invite.copy-link'" class="primary">
            <app-icon *ngIf="copied" class="copy-icon" icon="checkbox-arrow"></app-icon>
          </on-ui-button>
        </span>
      </div>
    </div>
  </ng-template>
</section>

<app-modal (closed)="teacherModalOpen = !teacherModalOpen"
           *ngIf="deviceService.isDesktop() && teacherModalOpen"
           [additionalHeight]="80">
  <app-modal-body>
    <app-user-profile-page
      [isModal]="true"
      [selectedSubjectId]="getSubjectId"
      [userName]="user.slug">
    </app-user-profile-page>
  </app-modal-body>
  <app-modal-ad>
    <app-teacher-booking-surface-block [teacher]="user"></app-teacher-booking-surface-block>
  </app-modal-ad>
</app-modal>

<app-mobile-modal (closed)="teacherModalOpen = !teacherModalOpen"
                  *ngIf="!deviceService.isDesktop() && teacherModalOpen">
  <app-mobile-modal-body>
    <app-user-profile-page
      [isModal]="true"
      [selectedSubjectId]="getSubjectId"
      [userName]="user.slug">
    </app-user-profile-page>
  </app-mobile-modal-body>
</app-mobile-modal>
