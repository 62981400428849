import { ChangeDetectorRef, Directive, Injector, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { EventActionEnum, EventCategoryEnum } from '@app/core/services/analytics';
import { RemoteConfigType } from '@app/core/services/remote-config/constants';
import { RemoteConfigService } from '@app/core/services/remote-config/remote-config.service';
import { CustomDateFormatter } from '@app/events-calendar/providers/custom-date-formatter.provider';
import { IShiftDais } from '@app/events-calendar/scroll-selector/scroll-selector.component';
import { logger } from '@core/helpers/logger';
import {
  AccountService,
  AnalyticsService,
  AuthService,
  BillingService,
  ContextService,
  FeaturesService,
  FilesService,
  LanguageService,
  MeetingsService,
  PlatformService,
  ScheduleService,
  SingleEventExtenderService,
  UserServicesService,
} from '@core/services';
import { WebsocketService } from '@core/websocket';
import { environment } from '@env/environment';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { formatDateByTimeZone, normalizeZonedDate } from '@shared/utils';
import { markHours } from '@store/actions/profile.actions';
import { getMe, getMyScheduleCurrentDay } from '@store/reducers/profile.reducer';
import { CalendarDateFormatter, CalendarView } from 'angular-calendar';
import { DAYS_OF_WEEK, EventColor, WeekViewHourSegment } from 'calendar-utils';
import { addDays, addMinutes, endOfWeek, startOfWeek, subSeconds } from 'date-fns';
import {
  AnyType,
  ConfirmationOptions,
  EUserServiceType,
  EventStatusEnum,
  GenerationStatusEnum,
  IEntityUpdate,
  IUserScheduleFilter,
  Meeting,
  SingleEvent,
  TenantEnum,
  User,
  UserRoleEnum,
  UserService,
} from 'lingo2-models';
import { DateFnsConfigurationService } from 'lingo2-ngx-date-fns';
import { omit } from 'lodash-es';
import { DeviceDetectorService } from 'ngx-device-detector';
import { finalize, Observable, of, Subject } from 'rxjs';
import { filter, mergeAll, switchMap, takeUntil, tap, throttleTime } from 'rxjs/operators';
import {
  CalendarEvent,
  CalendarEventTimesChangedEvent,
  ConfirmationActionType,
  ICalendarConfig,
  localeWeekFirstDayToCalendar,
  roundTime,
  WeekViewTimeEvent,
  eventGeneratedId,
  valueHash,
} from '../models';

export interface IScheduleProps {
  date?: Date;
  weekShift?: number;
  monthShift?: number;
  school_id?: string;
  account_id?: string | string[];
  force?: boolean;
}

const RULES_WARNING_KEY = 'rules_warning';

@Directive({
  providers: [{ provide: CalendarDateFormatter, useClass: CustomDateFormatter }],
})
export abstract class BaseCalendarComponent extends ChangableComponent implements OnInit, OnDestroy {
  public deviceService: DeviceDetectorService;
  public debug = false;
  public currentWeekEvents: SingleEvent[] = [];
  public viewDate: Date = new Date();
  public meetingFormOpened = false;
  public userServicesDialogOpened = false;
  public closedPopover = false;
  public currentWeekViewHourSegment: WeekViewHourSegment;
  public currentWeekEvent: WeekViewTimeEvent;
  public currentDayOfWeek: Date;
  public currentSegmentOrEvent: WeekViewTimeEvent | WeekViewHourSegment;
  public userServices: UserService[] = [];
  public form: UntypedFormGroup;
  public isScheduleLoading = false;
  public isConfirmationWaiting = false;
  public editMeetingId: string;
  public inviteMeeting: Meeting;
  public inviteModal = false;
  public isRulesModalOpened: boolean;

  public mwlCalendarRefresh$ = new Subject<void>();
  public mwlCalendarEvents: CalendarEvent[] = [];
  public mwlCalendarConfig: ICalendarConfig = {
    view: CalendarView.Week,
    locale: 'en',
    weekStartsOn: DAYS_OF_WEEK.SUNDAY,
    minCellHeight: 18,
  };

  public confirmationAlert = false;
  public confirmationMeetingId: string;
  public confirmationId: string;
  public confirmationAction: ConfirmationActionType;
  public confirmationOptions: ConfirmationOptions;

  protected _me: User;
  protected remoteConfig: RemoteConfigType;
  protected watchingUserIds = [];
  protected reloadSchedule$ = this.register(new Subject<IScheduleProps>());

  protected authService: AuthService;
  protected billingService: BillingService;
  protected store: Store;
  protected meetingsService: MeetingsService;
  protected userServicesService: UserServicesService;
  protected scheduleService: ScheduleService;
  protected contextService: ContextService;
  protected eventExtenderService: SingleEventExtenderService;
  protected dateConfig: DateFnsConfigurationService;
  protected languageService: LanguageService;
  protected remoteConfigService: RemoteConfigService;
  protected analytics: AnalyticsService;
  protected ws: WebsocketService;

  public constructor(
    protected inject: Injector,
    public features: FeaturesService,
    public errorNotificationService?: ErrorNotificationService,
  ) {
    super(inject.get(ChangeDetectorRef), inject.get(PlatformService));
    this.form = new UntypedFormGroup({
      date: new UntypedFormControl(null, [Validators.required]),
    });

    this.deviceService = inject.get(DeviceDetectorService);
    this.authService = inject.get(AuthService);
    this.billingService = inject.get(BillingService);
    this.store = inject.get(Store);
    this.meetingsService = inject.get(MeetingsService);
    this.userServicesService = inject.get(UserServicesService);
    this.scheduleService = inject.get(ScheduleService);
    this.contextService = inject.get(ContextService);
    this.eventExtenderService = inject.get(SingleEventExtenderService);
    this.dateConfig = inject.get(DateFnsConfigurationService);
    this.languageService = inject.get(LanguageService);
    this.remoteConfigService = inject.get(RemoteConfigService);
    this.analytics = inject.get(AnalyticsService);
    this.ws = inject.get(WebsocketService);
  }

  public ngOnInit() {
    if (!this.isBrowser) {
      return;
    }

    this.mwlCalendarConfig.view = this.deviceService.isMobile() ? CalendarView.Day : CalendarView.Week;
    this.mwlCalendarConfig.minCellHeight = this.deviceService.isMobile() ? 22 : 18;

    this.languageService.language$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (language) => {
        this.mwlCalendarConfig.locale = language.code;
        // вычислить из Intl.Locale, недоступно в Firefox
        // @see https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/Locale/getWeekInfo
        // @see https://caniuse.com/mdn-javascript_builtins_intl_locale_getweekinfo
        this.mwlCalendarConfig.weekStartsOn = null;
        try {
          const locale: AnyType = new Intl.Locale(language.code);
          this.mwlCalendarConfig.weekStartsOn = localeWeekFirstDayToCalendar(locale?.weekInfo?.firstDay);
        } catch (e) {}
        if (this.mwlCalendarConfig.weekStartsOn === null) {
          switch (language.code) {
            case 'ru':
              this.mwlCalendarConfig.weekStartsOn = DAYS_OF_WEEK.MONDAY;
              break;
            default:
              this.mwlCalendarConfig.weekStartsOn = DAYS_OF_WEEK.SUNDAY;
              break;
          }
        }
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
      },
    });

    of(
      ...[
        this.watchRemoteConfig$,
        this.watchDebug$,
        this.watchReloadSchedule$,
        this.watchMe$,
        this.watchWsStatus$,
        this.watchWsScheduleUpdate$,
      ],
    )
      .pipe(mergeAll(), tap(), takeUntil(this.destroyed$))
      .subscribe({
        next: () => this.detectChanges(),
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });

    // обновление календарика
    this.setInterval(() => this.detectChanges(), 60 * 1000);
  }

  public ngOnDestroy() {
    super.ngOnDestroy();

    (this.watchingUserIds || []).map((userId) => this.stopWatchingSchedule(userId));
  }

  public handleEvent(action: string, event: CalendarEvent) {
    this.log('handleEvent', action, event);
  }

  public onWeekShift() {
    this.reloadSchedule();
  }

  public eventTimesChanged({ event, newStart, newEnd }: CalendarEventTimesChangedEvent) {
    if (event.meta?.meeting_id) {
      const changedMeeting = {
        ...event?.meta?.meeting,
        begin_at: newStart,
        end_at: newEnd,
      };
      this.meetingsService
        .updateMeeting(event?.meta?.meeting?.id, changedMeeting)
        .pipe(
          finalize(() => this.detectChanges()),
          takeUntil(this.destroyed$),
        )
        .subscribe({
          next: (meeting) => {
            this.log(this.constructor.name + '.eventTimesChanged -> updateMeeting -> updated', meeting);
          },
          error: (error) => {
            this.errorNotificationService.captureError(error, 'SAVE-PROBLEM');
          },
        });
    }
  }

  public isPastTime(date: Date): boolean {
    return formatDateByTimeZone(new Date(), this._me?.timezone) > new Date(date);
  }

  public onScheduleMeeting() {
    this.closePopover();

    const options = {
      caller: 'app-events-calendar',
      reason: 'schedule meeting',
    };

    this.analytics.event(EventActionEnum.meeting_scheduling, EventCategoryEnum.service, options.caller);

    const _continue = () => {
      if (this.remoteConfig?.scheduleMeeting__paywall) {
        // показать выбор тарифа, а если тариф есть, то отправить на целевую страницу
        this.billingService.showPaywall(() => this.openMeetingForm(), options);
      } else {
        // сразу отправить на целевую страницу
        this.openMeetingForm();
      }
    };

    // показать диалог авторизации, а после авторизации продолжить
    if (!this.authService.isAuthenticated) {
      this.authService.showAuthModal(() => {
        this.reloadSchedule();
        this.loadUserServices();
        _continue();
      }, options);
    } else {
      _continue();
    }
  }

  public openMeetingForm() {
    this.editMeetingId = null;
    this.meetingFormOpened = true;
    this.detectChanges();
  }

  public closeMeetingForm() {
    this.editMeetingId = null;
    this.meetingFormOpened = false;
    this.detectChanges();
  }

  public openUserServicesDialog() {
    this.userServicesDialogOpened = true;
    this.currentSegmentOrEvent = this.currentWeekViewHourSegment || this.currentWeekEvent;
    this.closePopover();
  }

  public closeUserServicesDialog() {
    this.userServicesDialogOpened = false;
    this.detectChanges();
  }

  public onUserServicesSaved() {
    this.closeUserServicesDialog();
    this.waitForConfirmation();
  }

  public onSetCurrentSegment(popupState: boolean, segment: WeekViewHourSegment) {
    if (popupState) {
      if (this.currentDayOfWeek && this.deviceService.isMobile()) {
        this.closePopover();
      }
      this.currentWeekEvent = null;
      this.currentDayOfWeek = segment.date;
      this.closedPopover = false;
      this.detectChanges();
    }
    this.currentWeekViewHourSegment = popupState ? segment : null;
    // this.log('onSetCurrentSegment', this.closedPopover, this.currentWeekViewHourSegment);
  }

  public get canScheduleMeeting(): boolean {
    return !!this._me;
  }

  public get canScheduleServices(): boolean {
    return !!this._me && this.isTeacher && !this.isMeVersion();
  }

  public get canMarkAvailableHour(): boolean {
    return !!this._me && this.isTeacher && !this.isMeVersion();
  }

  public get scheduleServiceTeacher(): Partial<User> {
    return null;
  }

  public onMarkAvailableHour() {
    if (!this.canMarkAvailableHour) {
      this.closePopover();
      return;
    }

    const event = this.currentWeekEvent?.event?.meta;
    const day = this.currentWeekViewHourSegment?.date;
    const newDate = normalizeZonedDate(day, this._me.timezone);
    if (event) {
      this.store.dispatch(
        markHours({
          status: EventStatusEnum.available,
          start: event.begin_at,
          end: event.end_at,
        }),
      );
    } else if (newDate) {
      this.store.dispatch(
        markHours({
          status: EventStatusEnum.available,
          start: newDate,
          end: addMinutes(newDate, 30),
        }),
      );
    }

    this.closePopover();
  }

  public onMarkOffHour() {
    if (!this.canMarkAvailableHour) {
      this.closePopover();
      return;
    }

    const event = this.currentWeekEvent.event.meta;
    this.store.dispatch(
      markHours({
        status: EventStatusEnum.day_off,
        start: event.begin_at,
        end: event.end_at,
      }),
    );
    this.closePopover();
  }

  public closePopover() {
    this.closedPopover = true;
    this.detectChanges();
  }

  public onSetCurrentEvent(popupState: boolean, weekEvent: WeekViewTimeEvent) {
    if (popupState) {
      if (this.currentWeekEvent?.event?.id === weekEvent.event.id) {
        this.currentWeekEvent = weekEvent;
      } else {
        this.currentWeekEvent = popupState ? weekEvent : null;
      }
      this.currentDayOfWeek = weekEvent.event.start;
      this.closedPopover = false;

      if (!this.deviceService.isDesktop() && this.isSchedulableServiceEvent(this.currentWeekEvent?.event)) {
        this.openUserServicesDialog();
      }
    }
    // logger.debug('onSetCurrentEvent', popupState, this.closedPopover);
  }

  public isStatusAvailable(event: SingleEvent): boolean {
    return [EventStatusEnum.available, EventStatusEnum.regular].includes(event?.status);
  }

  public statusName(event: SingleEvent): string {
    return event?.status ? EventStatusEnum[event?.status] || '?' + event?.status.toString() : '';
  }

  public isMeetingEvent(event: SingleEvent): boolean {
    return !!event?.meeting_id;
  }

  public isOngoingReservationEvent(event: SingleEvent): boolean {
    return event?.status === EventStatusEnum.ongoing_reservations;
  }

  public isServiceEvent(event: SingleEvent): boolean {
    return !!event?.user_service_id;
  }

  public hourSegmentType(event: WeekViewTimeEvent): string {
    if (this.isMeetingEvent(event?.event?.meta)) {
      return 'meeting';
    }
    if (this.isOngoingReservationEvent(event?.event?.meta)) {
      return 'meeting';
    }
    if (this.isServiceEvent(event?.event?.meta)) {
      return 'service';
    }
    return '';
  }

  public dateWithoutTime(date: Date): Date {
    const day = new Date(date);
    day.setHours(0, 0, 0, 0);
    return addDays(day, 0);
  }

  public minutes(date): number {
    return date.getHours() * 60 + date.getMinutes();
  }

  public isSelectedHourSegment(date1: Date, date2: Date): boolean {
    return new Date(date1).getTime() === new Date(date2).getTime();
  }

  public weekEventCoverUrl(weekEvent: WeekViewTimeEvent): string {
    let cover_id: string;
    let cover_url: string;
    if (this.isMeetingEvent(weekEvent?.event?.meta)) {
      cover_url = weekEvent?.event?.meta.meeting?.cover?.md?.url;
      cover_id = weekEvent?.event?.meta.meeting?.cover_id;
    } else if (this.isServiceEvent(weekEvent?.event?.meta)) {
      cover_url = weekEvent?.event?.meta.user_service?.cover?.md?.url;
      cover_id = weekEvent?.event?.meta.user_service?.cover_id;
    }
    return cover_url || FilesService.getFileUrlBySize(cover_id);
  }

  public eventFullTitle(weekEvent: WeekViewTimeEvent): string {
    const sequence_total = weekEvent.event?.meta?.details?.sequence_total || 1;
    const sequence_number = weekEvent.event?.meta?.details?.sequence_number || 1;
    const sequence_index = sequence_total > 1 ? `[#${sequence_number} of ${sequence_total}]` : '';
    const account = weekEvent.event?.meta?.account;
    const id = weekEvent.event.id; // weekEvent.event?.meta?.id;
    const start = weekEvent.event?.start;
    const end = weekEvent.event?.end;
    const generation = weekEvent.event?.meta?.generation;

    return [
      '#' + id,
      generation ? 'G ' + generation : '',
      'UTC ' + start.toISOString() + ' - ' + end.toISOString(),
      'LOCAL ' + start.toString() + ' - ' + end.toString(),
      this.statusName(weekEvent.event?.meta),
      sequence_index,
      AccountService.getUserFullName(account) || '',
      weekEvent.event?.title || '',
    ]
      .filter((v) => v.toString().length > 0)
      .join('\n');
  }

  public eventStatus(weekEvent: WeekViewTimeEvent): string {
    return EventStatusEnum[weekEvent?.event?.meta?.status];
  }

  /**
   * Хак, чтобы меню поповера нижних ивентов не скрывалось концом страницы
   *
   * @SEE https://app.clickup.com/t/8694xv8c3
   */
  public calcEventPosition(weekEvent: WeekViewTimeEvent) {
    const eventTime = weekEvent.event.end.getHours();
    if (eventTime < 23) {
      return 'bottom';
    } else {
      return 'top';
    }
  }

  public onShiftDais(e: IShiftDais) {
    // logger.debug('onShiftDais:: ', e);
    this.viewDate = new Date(e.date);
    this.reloadScheduleOnDateChange(e.date);
  }

  public onChangedDate(e: Date) {
    this.viewDate = new Date(e);
    this.reloadScheduleOnDateChange(this.viewDate);
  }

  public isSchedulableServiceEvent(calendarEvent: CalendarEvent): boolean {
    const user_service = calendarEvent?.meta?.user_service;
    return user_service?.is?.schedulable;
  }

  public onEditMeeting(meetingId: string) {
    this.editMeetingId = meetingId;
    this.meetingFormOpened = true;
    this.onScheduleMeeting();
  }

  public onInviteMeeting(meeting: Meeting) {
    this.inviteMeeting = meeting;
    this.openInviteModal();
    this.closePopover();
  }

  public openInviteModal() {
    this.inviteModal = true;
    this.detectChanges();
  }

  public closeInviteModal() {
    this.inviteModal = false;
    this.detectChanges();
  }

  public onLeaveMeeting(meeting: Meeting) {
    this.closePopover();

    this.confirmationMeetingId = meeting.id;
    this.meetingsService
      .requestMeetingLeave(this.confirmationMeetingId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          if (response.can) {
            this.confirmationAction = 'leave';
            this.confirmationId = response.confirmation_id;
            this.confirmationOptions = response.confirmation;
            this.openConfirmationAlert();
          } else {
            logger.warn("Can't leave meeting for now");
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public onCancelMeeting(meeting: Meeting) {
    this.closePopover();

    this.confirmationMeetingId = meeting.id;
    this.meetingsService
      .requestMeetingCancel(this.confirmationMeetingId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response) => {
          if (response.can) {
            this.confirmationAction = 'cancel';
            this.confirmationId = response.confirmation_id;
            this.confirmationOptions = response.confirmation;
            this.openConfirmationAlert();
          } else {
            logger.warn("Can't cancel meeting for now");
          }
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  public openConfirmationAlert() {
    this.confirmationAlert = true;
    this.detectChanges();
  }

  public closeConfirmationAlert() {
    this.confirmationAlert = false;
    this.detectChanges();
  }

  public confirm() {
    switch (this.confirmationAction) {
      case 'leave':
        return this.leaveMeetingConfirm();

      case 'cancel':
        return this.cancelMeetingConfirm();
    }
  }

  public leaveMeetingConfirm() {
    this.meetingsService
      .confirmMeetingLeave(this.confirmationMeetingId, this.confirmationId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.closeConfirmationAlert();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.closeConfirmationAlert();
          this.detectChanges();
        },
      });
  }

  public cancelMeetingConfirm() {
    this.meetingsService
      .confirmMeetingCancel(this.confirmationMeetingId, this.confirmationId)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.closeConfirmationAlert();
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  // ***************

  public get showRulesWarning(): boolean {
    const accepted = false; // TODO localStorage.getItem(RULES_WARNING_KEY) !== 'accepted';
    return this.isTeacher && !this.isMeVersion() && !accepted;
  }

  public openRulesModal() {
    this.isRulesModalOpened = true;
    this.detectChanges();
  }

  public closeRulesModal() {
    this.isRulesModalOpened = false;
    this.detectChanges();
  }

  public acceptRules() {
    localStorage.setItem(RULES_WARNING_KEY, 'accepted');
    this.closeRulesModal();
  }

  // ***************

  public infoEvent(event: SingleEvent) {
    logger.info('EventsCalendar::InfoEvent', event);
    this.closePopover();
  }

  public get isTeacher(): boolean {
    return AccountService.isAsIfTeacher(this._me);
  }

  public get isAuth(): boolean {
    return this.authService.isAuthenticated;
  }

  public isMeVersion() {
    return this.features?.tenant === TenantEnum.onclass_me;
  }

  // **************************
  // абстрактные внутренние методы, требуется определить в наследниках

  protected abstract findUserServices$(): Observable<UserService[]>;

  // **************************

  protected waitForConfirmation() {
    this.isConfirmationWaiting = true;
    this.detectChanges();

    // TODO [https://app.clickup.com/t/86933uec3] механизм отложенной передачи результата длительной операции
    this.setTimeout(() => {
      this.isConfirmationWaiting = false;
      this.detectChanges();
    }, 1500);
  }

  protected endAt(event: SingleEvent): Date {
    switch (event?.user_service?.duration) {
      case 20:
        return addMinutes(event.begin_at, 30);
      case 40:
        return addMinutes(event.begin_at, 60);
      case 80:
        return addMinutes(event.begin_at, 90);
      default:
        return event.end_at;
    }
  }

  /** для отладки: порядковый номер "поколения" генерации карточек событий */
  protected mwlCalendarGeneration = 1;

  /**
   * НЕРАЗРУШИТЕЛЬНОЕ преобразование списка событий в список карточек для
   * календаря: карточки по возможности ОБНОВЛЯТЬ без замены
   * */
  protected transformEventsForMwlCalendar(events: SingleEvent[]) {
    let generationIncremented = false;
    const nextGeneration = () => {
      if (!generationIncremented) {
        generationIncremented = true;
        this.mwlCalendarGeneration++;
      }
      return this.mwlCalendarGeneration;
    };

    // (!) mwlEvent.meta.generation игнорировать при вычислении разницы между значениями
    const omittedProps = [
      // 'actions',
      'meta.generation',
    ];

    const eventIds = {};
    events.map((event) => {
      const id = event.generation_status === GenerationStatusEnum.done ? eventGeneratedId(event) : event.id;

      eventIds[id] = true;
      const mwlEvent: CalendarEvent = {
        id,
        start: formatDateByTimeZone(roundTime(event.begin_at, 30), this._me?.timezone),
        end: formatDateByTimeZone(subSeconds(roundTime(this.endAt(event), 30), 1), this._me?.timezone),
        title: event?.meeting?.title || event?.user_service?.title,
        color: this.eventStatusColor(event.status),
        // actions: this.mwlCalendarActions,
        allDay: false,
        resizable: {
          beforeStart: false, // new Date(_event.begin_at) > new Date(),
          afterEnd: false, // new Date(_event.begin_at) > new Date(),
        },
        draggable: false, // new Date(_event.begin_at) > new Date(),
        meta: {
          ...event,
        },
      };

      // если нашлась такая же карточка по id - то карточка заменяется, иначе добавляется
      const index = this.mwlCalendarEvents.findIndex((e) => e.id === id);
      if (index >= 0) {
        const oldHash = valueHash(omit(this.mwlCalendarEvents[index], omittedProps));
        const newHash = valueHash(omit(mwlEvent, omittedProps));
        if (oldHash !== newHash) {
          // карточка заменять, если у неё поменялось содержимое
          mwlEvent.meta.generation = nextGeneration();
          this.mwlCalendarEvents[index] = mwlEvent;
        }
      } else {
        mwlEvent.meta.generation = nextGeneration();
        this.mwlCalendarEvents.push(mwlEvent);
      }
    });

    // удалить карточки, которые не соответствуют ни одному событию
    this.mwlCalendarEvents = this.mwlCalendarEvents.filter((e) => eventIds[e.id]);
  }

  protected eventStatusColor(status: EventStatusEnum): EventColor {
    switch (status) {
      case EventStatusEnum.available:
      case EventStatusEnum.regular:
        return { primary: '#CAF0C5', secondary: '#CAF0C5' };
      case EventStatusEnum.accepted:
        return { primary: '#87D37C', secondary: '#87D37C' };
      case EventStatusEnum.finished:
        return { primary: '#A4B0C3', secondary: '#A4B0C3' };
      case EventStatusEnum.started:
        return { primary: '#5AB3E4', secondary: '#5AB3E4' };
      case EventStatusEnum.day_off:
        return { primary: '#F5F8FA', secondary: '#F5F8FA' };
      case EventStatusEnum.vacation:
        return { primary: '#B1A0F4', secondary: '#B1A0F4' };
      default: // в любой непонятной ситуации покрасить оранжевым
        return { primary: '#FF9C00', secondary: '#FF9C00' };
    }
  }

  protected loadUserServices() {
    this.findUserServices$()
      .pipe(
        finalize(() => this.detectChanges()),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (services) => {
          this.userServices = services.filter((_us) => _us.type !== EUserServiceType.regular);
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        },
      });
  }

  // ******************

  protected get watchRemoteConfig$() {
    return this.remoteConfigService.config$.pipe(
      tap((config) => {
        this.remoteConfig = config;
        this.detectChanges();
      }),
    );
  }

  protected get watchDebug$() {
    return this.contextService.debug$.pipe(tap((debug) => (this.debug = debug)));
  }

  protected get watchMe$() {
    return this.store.select(getMe).pipe(tap((me) => (this._me = me)));
  }

  // ********** СЛЕЖЕНИЕ ЗА ВНЕШНИМИ СОБЫТИЯМИ ОБНОВЛЕНИЯ РАСПИСАНИЯ

  protected startWatchingSchedule(userId: string) {
    if (userId && !this.watchingUserIds.includes(userId)) {
      this.ws.startWatching('schedule', userId);
      this.watchingUserIds.push(userId);
    }
  }

  protected stopWatchingSchedule(userId: string) {
    if (userId && this.watchingUserIds.includes(userId)) {
      this.ws.stopWatching('schedule', userId);
      this.watchingUserIds = this.watchingUserIds.filter((id) => id !== userId);
    }
  }

  protected get watchWsStatus$() {
    return this.ws.status$.pipe(
      filter((isOnline) => isOnline),
      tap(() => this.reloadSchedule()),
    );
  }

  protected get watchWsScheduleUpdate$() {
    return this.ws.on<IEntityUpdate>('update').pipe(
      filter((update) => update.entity === 'schedule' && this.watchingUserIds.includes(update.id)),
      tap(() => this.reloadSchedule()),
    );
  }

  protected reloadScheduleOnDateChange(date: Date): void {
    this.viewDate = date;
    this.reloadSchedule();
  }

  protected reloadSchedule(): void {
    this.markScheduleForReload({ date: this.viewDate });
    // ? this.store.dispatch(loadMySchedule({ date: this.viewDate }));
  }

  protected markScheduleForReload(_filter: IScheduleProps) {
    this.reloadSchedule$.next(_filter);
    /** @see watchReloadSchedule$ */
  }

  protected get watchReloadSchedule$() {
    return this.reloadSchedule$.pipe(
      throttleTime(1000, null, { leading: true, trailing: true }),
      switchMap((props) => this.loadSchedule$(props)),
      tap((events: SingleEvent[]) => {
        this.log(this.constructor.name + '.watchReloadMeeting$ -> loaded');
        this.isScheduleLoading = false;
        this.onLoadScheduleDone(events);
        this.mwlCalendarRefresh$.next(null);
        this.detectChanges();
      }),
    );
  }

  protected loadSchedule$(props: IScheduleProps): Observable<SingleEvent[]> {
    return this.store.select(getMyScheduleCurrentDay).pipe(
      switchMap((currentDay) => {
        const date = props.date || currentDay || new Date();
        const _filter: Partial<IUserScheduleFilter> = {
          date_from: startOfWeek(date, { locale: this.dateConfig.locale() }),
          date_to: endOfWeek(date, { locale: this.dateConfig.locale() }),
        };
        // this.log(this.constructor.name + '.loadSchedule$ ', JSON.stringify(props), ' -> ' + JSON.stringify(_filter));

        return this.scheduleService
          .getSchedule(_filter)
          .pipe(switchMap((events) => this.eventExtenderService.extend$(events, ['meeting', 'user_service'])));
      }),
    );
  }

  protected onLoadScheduleDone(events: SingleEvent[]) {
    // this.log(this.constructor.name + '.onLoadScheduleDone');

    const now = Date.now();
    this.currentWeekEvents = events;
    const filteredEvents = events.filter((event) => {
      if (event.end_at.getTime() < now) {
        return event.status !== EventStatusEnum.regular;
      }
      return true;
    });
    // this.mwlCalendarEvents =
    this.transformEventsForMwlCalendar(filteredEvents);
    // this.log(this.constructor.name + 'onLoadScheduleDone() -> *', this.currentWeekEvents, this.events);
  }

  protected log(...message) {
    if (environment.env === 'dev' || AccountService.hasRole(this._me, UserRoleEnum.developer) || this.debug) {
      logger.debug(...message);
    }
  }

  public trackByFn(index) {
    return index;
  }
}
