<div class="school-calc-teacher-card" [class.mobile]="deviceService.isMobile()">
  <div class="col-name">
    <div class="teacher-wrap">
      <div class="avatar-wrap">
        <on-ui-cover [cover]="cover"></on-ui-cover>
      </div>
      <span class="full-name">{{ fullName }}</span>
    </div>
  </div>
  <div class="col-percentages">
    <div class="calculation">
      <span class="value">{{ ownProfit }}%</span>
      <div class="input-wrap"
           [class.saved]="saved"
           [class.save-success]="saveSuccess">
        <div class="loader-wrap">
          <div class="loader item1"></div>
          <div class="loader item2"></div>
          <div class="loader item3"></div>
        </div>

        <div class="save-success">
          <span class="save-success-text">{{ 'auto-save.saved' | translate}}</span>
        </div>

        <input type="range"
               [min]="schoolOptions?.tutorPercentMin"
               [max]="schoolOptions?.tutorPercentMax"
               [step]="schoolOptions?.tutorPercentStep"
               class="input-range"
               [(ngModel)]="ownProfit"
               (change)="updateParticipant()">
      </div>
      <span class="value">{{ schoolProfit }}%</span>
    </div>
  </div>
</div>
