import { Location } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ComponentRef,
  HostListener,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { AccessTokenService } from '@app/core/services/access-token.service';
import { loadFilteredLanguages } from '@app/store/actions/data.actions';
import { HeaderComponent } from '@core/header/header.component';
import {
  ConfigService,
  ContextService,
  CrispChatService,
  FeaturesService,
  FilesService,
  LanguageService,
  SchoolsService,
  ScreenService,
} from '@core/services';
import { environment } from '@env/environment';
import { ChangableComponent } from '@models/changable.component';
import { Store } from '@ngrx/store';
import { ErrorNotificationService } from '@shared/error-notification/error-notification.service';
import { loadMe } from '@store/actions/profile.actions';
import { getMe } from '@store/reducers/profile.reducer';
import { CSchool, ESchoolParticipantRole, FeatureEnum, Language, User, UserStatusEnum } from 'lingo2-models';
import { DeviceDetectorService } from 'ngx-device-detector';
import { of } from 'rxjs';
import { map, mergeMap, takeUntil } from 'rxjs/operators';
import { SchoolStepsEnum } from './sidebar/school-settings-left-sidebar.component';
import { ChatService } from '@app/core/services/chat.service';

@Component({
  selector: 'app-school-settings-page',
  templateUrl: './school-settings-page.component.html',
  styleUrls: ['./school-settings-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SchoolSettingsPageComponent extends ChangableComponent implements OnInit, AfterViewInit, OnDestroy {
  @HostListener('window:message', ['$event'])
  onMessage(event) {
    this.receiveMessage(event);
  }
  public headCover: string;
  public step: SchoolStepsEnum | string;
  public steps = SchoolStepsEnum;
  public pageStyle = '';
  public isChatVisible = false;
  public isNotificationsVisible = false;
  public alternativeChatState = false;
  public unreadMessageCount: number;
  public isFooterVisible = true;
  public fileFromChat = null;
  public safeChatUrl: SafeResourceUrl;
  public currentLanguage: Language;
  public iframe: any;

  private readonly _slug: string;
  private _school: CSchool;
  private _me: User;
  @ViewChild('app_header', { read: ViewContainerRef }) private headerContainerRef: ViewContainerRef;
  private headerComponentRef: ComponentRef<HeaderComponent>;

  public constructor(
    public errorNotificationService: ErrorNotificationService,
    public contextService: ContextService,
    private features: FeaturesService,
    private screenService: ScreenService,
    public crispChat: CrispChatService,
    public chatService: ChatService,
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private accessTokenService: AccessTokenService,
    private languageService: LanguageService,
    private store: Store,
    private location: Location,
    private schoolService: SchoolsService,
    private configService: ConfigService,
    private filesService: FilesService,
    protected cdr: ChangeDetectorRef,
    public deviceService: DeviceDetectorService,
  ) {
    super(cdr);
    this._slug = SchoolsService.getSchoolPostfix(route.snapshot.params['slug']);
    this.configService.load(['countries$']);
  }

  public ngOnInit(): void {
    this.route.paramMap.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (query) => {
        this.step = query.get('step');
        this.loadSchool();
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.loadSchool();
      },
    });

    this.store
      .select(getMe)
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (me) => {
          this._me = me;
          if (!this.safeChatUrl && this.safeChatUrl !== '') {
            this.generateChatLink();
          }
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.store.dispatch(loadMe());
        },
      });

    this.screenService.width$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (width) => {
        this.isFooterVisible = width < 870;
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.isFooterVisible = false;
      },
    });

    this.route.url.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (url) => {
        const data = this.route.snapshot.data;
        this.pageStyle = data.page_style || '';
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'OTHER-PROBLEM');
        this.pageStyle = '';
      },
    });

    if (this.deviceService.isDesktop()) {
      this.contextService.me$.pipe(takeUntil(this.destroyed$)).subscribe({
        next: (me) => {
          this.isChatVisible = me && me.status !== UserStatusEnum.guest;
          this.isNotificationsVisible = me && me.status !== UserStatusEnum.guest;
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
          this.isChatVisible = false;
          this.isNotificationsVisible = false;
        },
      });
    }

    this.languageService.language$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (language) => {
        this.currentLanguage = language;
        this.detectChanges();
      },
      error: (error) => {
        this.errorNotificationService.captureError(error, 'LOAD-SOMEDATA');
        this.store.dispatch(loadFilteredLanguages({ locale: this.me.ui_language }));
      },
    });
  }

  ngAfterViewInit() {
    void this.loadEmptyComponent().catch();
  }

  ngOnDestroy() {
    super.ngOnDestroy();
    this.schoolService.setCurrentSchool(null);
  }

  public setActiveMenu(e: string) {
    this.step = e;
    const url = this.routeLinkSchool + '/settings/' + e;
    const currentUrl = this.location.path(false);
    if (currentUrl !== url) {
      this.location.replaceState(url);
    }
    this.detectChanges();
  }

  public get school(): CSchool {
    return this._school;
  }

  public get me(): User {
    return this._me;
  }

  public get isOwnerSchool(): boolean {
    return !!this._school?.participants
      .filter((_p) => _p.role === ESchoolParticipantRole.owner)
      .find((_part) => _part.account_id === this._me?.id);
  }

  public get routeLinkSchool() {
    return SchoolsService.schoolRoute(this._school, false).join('/');
  }

  public get isRouteParticipant(): boolean {
    return this.router.url === SchoolsService.schoolRoute(this._school, false).join('/') + '/settings/participants';
  }

  private loadSchool() {
    this.schoolService
      .getSchoolByIdOrSlug(this._slug, ['participants'], 'LOAD_TIME')
      .pipe(
        map((school) => {
          this._school = school;
          this.schoolService.setCurrentSchool(school);
          if (!this.isOwnerSchool) {
            this.onBrowserOnly(() => {
              this.router.navigate([this.routeLinkSchool], { replaceUrl: true }).then(() => true);
            });
            this.onServerOnly(() => {
              this.router.navigateByUrl(this.routeLinkSchool);
            });
          }
          return school;
        }),
        mergeMap((school: CSchool) =>
          school.head_cover_id ? this.filesService.getFileById(school.head_cover_id) : of(null),
        ),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: (file) => {
          this.headCover = file?.images?.find((image) => image.size === 'wide')?.url;
          this.detectChanges();
        },
        error: (error) => {
          this.errorNotificationService.captureError(error, 'LOAD-FILE');
        },
      });
  }

  public get isAvailableChat(): boolean {
    return this.features.isAvailable(FeatureEnum.chat) && this.isBrowser;
  }

  public onChatWindowStatusChanged(status: boolean) {
    this.alternativeChatState = status;
    this.screenService.setBodyFixed(status);
  }

  public openChatWindow() {
    this.iframe.contentWindow.postMessage(`chatapp::open::true`, '*');
    this.detectChanges();
  }

  public openProfile(e) {
    this.router.navigateByUrl('/u/' + e.slug).then(() => true);
    if (!this.deviceService.isDesktop()) {
      this.setTimeout(() => {
        this.onChatWindowStatusChanged(false);
        this.iframe.contentWindow.postMessage(`chatapp::close::true`, '*');
      }, 200);
    }
  }

  public onMouseenter() {
    this.screenService.setBodyFixed(true);
  }

  public onMouseleave() {
    this.screenService.setBodyFixed(false);
  }

  public save() {
    this.router.navigate([this.routeLinkSchool + '/settings/owners']);
  }

  // TODO ленивая загрузка компонента
  private async loadEmptyComponent(): Promise<void> {
    if (!this.headerContainerRef) {
      return;
    }
    const { HeaderComponent } = await import('../core/header/header.component');
    this.headerContainerRef?.clear();
    this.headerComponentRef = this.headerContainerRef?.createComponent(HeaderComponent);
    this.headerComponentRef.changeDetectorRef.detectChanges();
  }

  public set unreadCount(count) {
    this.unreadMessageCount = count;
    this.detectChanges();
  }

  public get unreadCount() {
    return this.unreadMessageCount;
  }

  public openFileFromChat(event) {
    this.iframe.contentWindow.postMessage(`chatapp::close::true`, '*');
    this.fileFromChat = event;
  }

  public closeFileFromChat() {
    this.iframe.contentWindow.postMessage(`chatapp::open::true`, '*');
    this.fileFromChat = null;
  }

  public generateChatLink() {
    if (!this.me?.id.length) {
      return;
    }
    let url = '';
    const hostUrl = environment.chat_app_host;
    const frameUrl = `${hostUrl}/`;
    const token = this.accessTokenService.getAccessToken();
    const host = environment.chat_app_api_host.includes('local') ? 'onclass.tech' : environment.chat_app_api_host;
    const lang = this.currentLanguage?.code || 'en';
    const myId = this.me?.id;
    url = `${frameUrl}?token=${token}&userId=${myId}&host=${host}&lang=${lang}`;
    this.safeChatUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  copyMessage(text: string) {
    if (navigator.clipboard && window.isSecureContext) {
      navigator.clipboard.writeText(text);
    } else {
      // Use the 'out of viewport hidden text area' trick
      const textArea = document.createElement('textarea');
      textArea.value = text;

      // Move textarea out of the viewport, so it's not visible
      textArea.style.position = 'absolute';
      textArea.style.left = '-999999px';

      document.body.prepend(textArea);
      textArea.select();

      try {
        document.execCommand('copy');
      } catch (error) {
        console.error(error);
      } finally {
        textArea.remove();
      }
    }
  }

  public receiveMessage(event: MessageEvent) {
    const message = event?.data;
    if (typeof message === 'string' || message instanceof String) {
      if (message.includes('chatapp')) {
        const messageArray = message.split('::');
        const action = messageArray[1];
        const value = messageArray[2];
        if (action && action === 'windowstatus') {
          this.onChatWindowStatusChanged(value === 'true');
        } else if (action && action === 'togglewindow') {
          // Нажали кнопку "закрыть/свернуть чат"
        } else if (action && action === 'unreadcount') {
          // Кол-во непрочитанных сообщений
          this.iframe = document.getElementById('chatFrame');
          this.chatService.setChatFrame(this.iframe);
          this.unreadCount = Number(value);
        } else if (action && action === 'openprofile') {
          // Перейти в профиль
          if (!!value.length) {
            this.openProfile(value);
          }
        } else if (action && action === 'openfile') {
          // Открыть файл из чата
          if (!!value.length) {
            this.openFileFromChat(value);
          }
        } else if (action && action === 'opensupport') {
          // Чат суппорта
          this.crispChat.openChat();
        } else if (action && action === 'copymessage') {
          // копирование сообщения
          this.copyMessage(value);
        }
      }
    }
  }
}
