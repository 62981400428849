export const reviewers = [
  {
    name: 'Darina',
    experience: 3,
  },
  {
    name: 'Maria',
    experience: 4,
  },
  {
    name: 'Anna',
    experience: 5,
  },
  {
    name: 'Alena',
    experience: 3,
  },
];
