<section class="instruments">
  <div class="instruments-container">
    <div class="instruments-title">
      {{'job-landing.instruments.title' | translate}}
    </div>
    <div class="instruments-promoblock">
      <div class="instruments-block-f">
        <img src="{{assetsUrl('/assets/images/landings/job/instrument-first-img.png')}}" alt=""/>
      </div>
      <div class="instruments-block-s">
        <img src="{{assetsUrl('/assets/images/landings/job/instrument-video-holder.png')}}" alt=""/>
      </div>
      <div class="instruments-block-arrow mobile">
        <img src="{{assetsUrl('/assets/images/landings/job/instrument-arrow-mob.svg')}}" alt=""/>
      </div>
      <div class="instruments-block-arrow desktop">
        <img src="{{assetsUrl('/assets/images/landings/job/instrument-arrow-desk.svg')}}" alt=""/>
      </div>
    </div>
    <div class="instruments-promolink">
      <div class="instruments-promolink-f">
        <div class="instruments-promolink-title">{{'job-landing.instruments.col1title' | translate}}</div>
        <div class="instruments-promolink-desc">{{'job-landing.instruments.col1text' | translate}}</div>
        <div class="instruments-promolink-link">
          <a href="#">{{'job-landing.instruments.more' | translate}}</a>
        </div>
      </div>
      <div class="instruments-promolink-s">
        <div class="instruments-promolink-title">{{'job-landing.instruments.col2title' | translate}}</div>
        <div class="instruments-promolink-desc">{{'job-landing.instruments.col2text' | translate}}</div>
        <div class="instruments-promolink-link">
          <a href="#">{{'job-landing.instruments.more' | translate}}</a>
        </div>
      </div>
    </div>
    <div class="instruments-list">
      <div class="instruments-list-f">
        <div class="instruments-list-item">
          <div class="instruments-list-item-img">
            <img src="{{assetsUrl('/assets/images/landings/job/instrument-icon-1.png')}}" alt=""/>
          </div>
          <div class="instruments-list-item-text">
            {{'job-landing.instruments.col1icon1' | translate}}
          </div>
        </div>
        <div class="instruments-list-item">
          <div class="instruments-list-item-img">
            <img src="{{assetsUrl('/assets/images/landings/job/instrument-icon-2.png')}}" alt=""/>
          </div>
          <div class="instruments-list-item-text">
            {{'job-landing.instruments.col1icon2' | translate}}
          </div>
        </div>
        <div class="instruments-list-item">
          <div class="instruments-list-item-img">
            <img src="{{assetsUrl('/assets/images/landings/job/instrument-icon-3.png')}}" alt=""/>
          </div>
          <div class="instruments-list-item-text">
            {{'job-landing.instruments.col1icon3' | translate}}
          </div>
        </div>
        <div class="instruments-list-item">
          <div class="instruments-list-item-img">
            <img src="{{assetsUrl('/assets/images/landings/job/instrument-icon-4.png')}}" alt=""/>
          </div>
          <div class="instruments-list-item-text">
            {{'job-landing.instruments.col1icon4' | translate}}
          </div>
        </div>
      </div>
      <div class="instruments-list-s">
        <div class="instruments-list-item">
          <div class="instruments-list-item-img">
            <img src="{{assetsUrl('/assets/images/landings/job/instrument-icon-5.png')}}" alt=""/>
          </div>
          <div class="instruments-list-item-text">
            {{'job-landing.instruments.col2icon1' | translate}}
          </div>
        </div>
        <div class="instruments-list-item">
          <div class="instruments-list-item-img">
            <img src="{{assetsUrl('/assets/images/landings/job/instrument-icon-6.png')}}" alt=""/>
          </div>
          <div class="instruments-list-item-text">
            {{'job-landing.instruments.col2icon2' | translate}}
          </div>
        </div>
        <div class="instruments-list-item">
          <div class="instruments-list-item-img">
            <img src="{{assetsUrl('/assets/images/landings/job/instrument-icon-7.png')}}" alt=""/>
          </div>
          <div class="instruments-list-item-text">
            {{'job-landing.instruments.col2icon3' | translate}}
          </div>
        </div>
        <div class="instruments-list-item">
          <div class="instruments-list-item-img">
            <img src="{{assetsUrl('/assets/images/landings/job/instrument-icon-8.png')}}" alt=""/>
          </div>
          <div class="instruments-list-item-text">
            {{'job-landing.instruments.col2icon4' | translate}}
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
